import arrowDown from '../../../common/images/ArrowDown.svg';
import { Box, Switch, Typography } from '@mui/material';
import {
	AgreementContainer,
	AgreementHeadingContainer,
	AgreementTextContainer,
	ExpandableContainer,
	Link,
	MarginTop20,
} from './Agreement.css';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
	changeConsents,
	handleAgreementSectionExpand,
} from '../../../store/slices/agreementsSlice/AgreementsSlice';
import { RootState } from '../../../store/State';
import { AgreementModel, MediaConsent } from '../AgreementsModels';
import { customAggreementSettings } from '../helpers';

interface AgreementProps {
	agreement: AgreementModel;
	hideExpand?: boolean;
	mediaIndex?: number;
	agreementIndex: number;
	sectionIndex: number;
	fullWidth?: boolean;
}

export default function Agreement(props: AgreementProps): JSX.Element {
	const dispatch = useDispatch();
	const agreementsState = useSelector(
		(state: RootState): AgreementModel[] =>
			state.agreements.agreements[props.sectionIndex].consentGroup
	);

	const [mainAgreement] = useState(
		agreementsState.find(
			(x: AgreementModel): boolean => x.symbol === props.agreement.symbol
		)
	);

	const handleCheckedChange = (
		event: React.ChangeEvent<HTMLInputElement>
	): void => {
		dispatch(
			changeConsents({
				sectionIndex: props.sectionIndex,
				agreementIndex: props.agreementIndex,
				isSelected: event.target.checked,
				mediaIndex: props.mediaIndex,
				mainConsent: !!mainAgreement,
			})
		);
	};

	const handleMediaConsent = (
		event: React.ChangeEvent<HTMLInputElement>,
		index?: number
	): void => {
		dispatch(
			changeConsents({
				sectionIndex: props.sectionIndex,
				agreementIndex: props.agreementIndex,
				isSelected: event.target.checked,
				mediaIndex: index,
				mainConsent: false,
			})
		);
	};

	return (
		<AgreementContainer
			sx={{
				marginBottom: '25px',
				paddingLeft: { xs: 0, md: props.fullWidth ? '0' : '45px' },
			}}
		>
			<AgreementTextContainer>
				<AgreementHeadingContainer>
					<Typography
						variant='p1'
						sx={{
							display: 'inline-block',
							maxWidth: props.fullWidth ? '100%' : '485px',
						}}
						dangerouslySetInnerHTML={{
							__html: props.agreement.abbreviatedContent ?? '',
						}}
					/>
					{props.agreement.isError && (
						<Typography
							variant='h9'
							sx={{ color: 'error.main', margin: '7px 0 12px 0' }}
						>
							Ta zgoda jest wymagana
						</Typography>
					)}
					{!props.agreement.hideExpand &&
					(props.agreement.content || props.agreement.mediaConsents?.length) ? (
						<Link
							onClick={(): void => {
								dispatch(
									handleAgreementSectionExpand({
										isExpanded: !props.agreement.isExpand,
										sectionIndex: props.sectionIndex,
										consentGroupId: props.agreementIndex,
									})
								);
							}}
						>
							{props.agreement.isExpand ? 'Zwiń' : 'Rozwiń'}{' '}
							<img
								src={arrowDown}
								style={{
									transform: props.agreement.isExpand ? 'rotate(180deg)' : '',
								}}
							/>{' '}
						</Link>
					) : (
						<></>
					)}
				</AgreementHeadingContainer>
				{!customAggreementSettings.find(
					(el): boolean => el.symbol === mainAgreement?.symbol
				)?.hideSwitch ? (
					<Switch
						sx={{ marginLeft: { md: '40px', xs: '15px' } }}
						checked={props.agreement.isSelected}
						onChange={(x: React.ChangeEvent<HTMLInputElement>): void =>
							handleCheckedChange(x)
						}
					/>
				): (
					<Box sx={{marginLeft: { md: '93px', xs: '68px' }}}></Box>
				)}
			</AgreementTextContainer>
			{props.agreement.content || props.agreement.mediaConsents?.length ? (
				<ExpandableContainer
					className={props.agreement.isExpand ? 'active' : ''}
				>
					<MarginTop20>
						<Typography
							variant='p1'
							sx={{
								display: 'inline-block',
								maxWidth: '485px',
								marginBottom: '20px',
								paddingLeft: { xs: '0', md: props.fullWidth ? '0' : '45px' },
								paddingRight: { md: '100px', xs: '70px' },
							}}
							dangerouslySetInnerHTML={{
								__html: props.agreement.content ?? '',
							}}
						/>
						{!!props.agreement.mediaConsents?.length &&
							props.agreement.mediaConsents.map(
								(x: MediaConsent, index: number): JSX.Element => (
									<AgreementTextContainer
										key={x.symbol}
										sx={{
											margin: '0 0 24px 0',
											paddingLeft: {
												xs: '0',
												md: props.fullWidth ? '0' : '45px',
											},
										}}
									>
										<AgreementHeadingContainer>
											<Typography
												variant='p1'
												sx={{
													display: 'inline-block',
													maxWidth: props.fullWidth ? '100%' : '485px',
												}}
												dangerouslySetInnerHTML={{
													__html: x.content ?? '',
												}}
											/>
											{!props.agreement.mediaConsents ||
												(props.agreement.mediaConsents[index].isError && (
													<Typography
														variant='h9'
														sx={{ color: 'error.main', margin: '7px 0 0 0' }}
													>
														Ta zgoda jest wymagana
													</Typography>
												))}
										</AgreementHeadingContainer>
										<Switch
											sx={{ marginLeft: { md: '40px', xs: '15px' } }}
											checked={x.isSelected}
											onChange={(
												event: React.ChangeEvent<HTMLInputElement>
											): void => handleMediaConsent(event, index)}
										/>
									</AgreementTextContainer>
								)
							)}
						{props.agreement.clauseFooter && (
							<Typography
								variant='p1'
								sx={{
									display: 'inline-block',
									paddingRight: { md: '93px', xs: '68px' },
								}}
								dangerouslySetInnerHTML={{
									__html: props.agreement.clauseFooter ?? '',
								}}
							></Typography>
						)}
					</MarginTop20>
				</ExpandableContainer>
			) : (
				<></>
			)}
		</AgreementContainer>
	);
}
