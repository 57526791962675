import BaseFetch from '../../../common/api/BaseFetch';
import { ApiConfig } from '../../../common/api/FetchTypes';
import Agreement from '../../../models/Agreement';

export interface NipVerificationProp {
	nip: string;
}

const getNipVerificationKey = 'getNipVerificationKey';
const createApplicaitonKey = 'createApplication';

function getNipVerification(props: NipVerificationProp): Promise<never> {
	return BaseFetch({
		url: `ClientContactData/verifyNip/${props.nip}`,
		method: 'GET',
	});
}

export const getNipVerificationConfig: ApiConfig<never, NipVerificationProp> = {
	key: getNipVerificationKey,
	fn: getNipVerification,
};

export interface CreateApplicationResult {
	applicationId: string;
	applicationNumber: string;
	isRejected: string;
	canProceed: boolean;
	token: string;
	marketingPartnerId: string;
	userId: string;
}

function createApplication(props: CreateApplicationPayload): Promise<CreateApplicationResult> {
	return BaseFetch({
		url: 'LandingPage/CreateApplication',
		method: 'POST',
		body: props,
	});
}

export const CreateApplicationConfig: ApiConfig<CreateApplicationResult, CreateApplicationPayload> = {
	key: createApplicaitonKey,
	fn: createApplication,
};

export interface CreateApplicationPayload {
	clientId: string;
	period: number;
	agreements: Agreement[];
	magicWords?: string;
	nip: string;
	processId: string;
}
