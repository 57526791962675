import { Button, Grid, Typography } from '@mui/material';
import NestModal from '../../NestModal/NestModal';
import { ModalName } from '../HeaderConstants';
import { textColorPrimary } from '../../../theme/palette/palette';

export interface SendDataModalProps {
	open: ModalName;
	closeModal: () => void;
	toggleModal: (modalName: ModalName) => void;
}

const SendDataModal = (props: SendDataModalProps): JSX.Element => {
	const title = (
		<Typography component={'span'} variant='h4'>
			Czy chcesz zapisać wniosek?
		</Typography>
	);

	const content = (
		<Typography
			component={'span'}
			variant='p2'
			sx={{ color: textColorPrimary }}
		>
			Wniosek zostanie zapisany w naszym systemie, a Ty będziesz mógł wrócić do
			niego w każdym momencie. Na wskazanego przez Ciebie maila wyślemy link,
			dzięki któremu wrócisz do wypełniania wniosku.
		</Typography>
	);

	const footer = (
		<Grid
			container
			justifyContent='center'
			alignItems='center'
			direction='column'
			sx={{ marginTop: '32px' }}
		>
			<Button
				onClick={props.closeModal}
				variant='outlined'
				sx={{ marginBottom: '24px' }}
			>
				Anuluj
			</Button>
			<Button
				onClick={(): void =>
					props.toggleModal(ModalName.ConfirmationOfSendingModal)
				}
				variant='contained'
			>
				Zapisz i wyślij
			</Button>
		</Grid>
	);

	return (
		<NestModal
			open={props.open === ModalName.SendDataModal}
			closeModal={props.closeModal}
			title={title}
			content={content}
			footer={footer}
		/>
	);
};

export default SendDataModal;
