/* eslint-disable @typescript-eslint/no-misused-promises */
import Typography from '@mui/material/Typography';
import React, { useContext, useEffect, useState } from 'react';
import { Control, FieldErrors } from 'react-hook-form';
import { firstNameAndLastNameMaskFunc, phoneNumberMask, simpleDigitsMask } from '../../../../../../common/helpers/mask-helper';
import { emailValidator } from '../../../../../../common/validators/emailValidator';
import { phoneNumberValidator } from '../../../../../../common/validators/phoneNumberValidator';
import CustomButton from '../../../../../../components/CustomButton/CustomButton';
import NestInput from '../../../../../../components/FormItems/NestInput/NestInput';
import { LightIndexLandingPageContext } from '../../../state/LightIndexLandingPageContext';
import { FormSectionWrapper, FieldsWrapper, ButtonTextContainer, InputContainer } from './LightIndexContactDataForm.css';
import Stack from '@mui/material/Stack';
import { nipValidator } from '../../../../../../common/validators/nipValidator';
import { nameValidator, onlyWordsAndNumbers } from 'src/common/validators/polishWordsValidator';
import { mainTheme } from '../../../../../../theme/mainTheme';
import Loader from 'src/components/Loader/Loader';
import MediaAgreementCollection from 'src/components/AgreementCollection/MediaAgreementCollection';
import { NestFormInfo } from 'src/components/FormItems/NestFormInfo/NestFormInfo';
import { IAgreementsStateSlice } from 'src/store/slices/agreementsSlice/AgreementSliceModels';
import { useDispatch } from 'react-redux';
import { checkErrors } from 'src/store/slices/agreementsSlice/AgreementsSlice';
import { ILightIndexContactDataProperties } from '../../LightIndexCreditInfoAndContactDataFormModels';

const LightIndexContactDataForm = (): JSX.Element => {
	const lightIndexLandingPageContext = useContext(LightIndexLandingPageContext);
	const dispatch = useDispatch();
	const [validAgreements, setValidAgreements] = useState<boolean>(true);
	const [clickedSubmit, setClickedSubmit] = useState<boolean>(false);
	const agreementState = lightIndexLandingPageContext.agreementState as IAgreementsStateSlice;

	const checkInputsValidation = (): boolean =>
		Object.keys(lightIndexLandingPageContext.methods ? lightIndexLandingPageContext.methods.formState.errors : {}).length === 0;

	const checkAgreements = (): boolean =>
		agreementState.agreements.every((agreement): boolean | undefined => agreement.isSelected) || !agreementState.isValid;

	const updateAgreementsStatus = (): void => {
		if (checkInputsValidation() && checkAgreements()) {
			dispatch(checkErrors());
			setValidAgreements(agreementState.isValid);
		}
	};

	useEffect((): void => {
		updateAgreementsStatus();
	}, [
		JSON.stringify((lightIndexLandingPageContext.agreementState as IAgreementsStateSlice).agreements),
		(lightIndexLandingPageContext.agreementState as IAgreementsStateSlice).isValid,
	]);

	const scrollToErrors = (errors: FieldErrors<ILightIndexContactDataProperties>): void => {
		const firstErrorField = Object.keys(errors)[0];

		if (clickedSubmit && firstErrorField) {
			document.querySelector(`[name='${firstErrorField}']`)?.scrollIntoView({ block: 'center', behavior: 'smooth' });
			setClickedSubmit(false);
		}
	};

	useEffect((): void => {
		if (lightIndexLandingPageContext.methods) scrollToErrors(lightIndexLandingPageContext.methods.formState.errors);
	}, [JSON.stringify(lightIndexLandingPageContext.methods?.formState.errors), clickedSubmit]);

	return (
		<>
			<FormSectionWrapper sx={{ flexDirection: 'column' }}>
				<FieldsWrapper
					sx={{
						alignItems: { md: 'flex-end', xs: 'center' },
						flexDirection: { xs: 'column', md: 'row' },
					}}
				>
					<InputContainer>
						<NestInput
							label='NIP'
							control={lightIndexLandingPageContext.methods?.control as unknown as Control}
							name='nip'
							rules={{
								validate: nipValidator,
								required: 'Pole wymagane',
							}}
							mask='### ### ## ##'
							maskFunc={simpleDigitsMask}
							wrapperSx={{ marginBottom: '24px' }}
						/>
					</InputContainer>
				</FieldsWrapper>

				<FieldsWrapper
					sx={{
						alignItems: { md: 'flex-end', xs: 'center' },
						flexDirection: { xs: 'column', md: 'row' },
					}}
				>
					<InputContainer>
						<NestInput
							label='Imię'
							control={lightIndexLandingPageContext.methods?.control as unknown as Control}
							name='firstName'
							rules={{
								minLength: { value: 2, message: 'Niepoprawna wartość' },
								required: 'Pole wymagane',
								pattern: {
									value: nameValidator,
									message: 'Niepoprawna wartość',
								},
							}}
							maskFunc={firstNameAndLastNameMaskFunc}
							useMaskAsValue
							mask='######'
							wrapperSx={{ marginBottom: '24px' }}
						/>
					</InputContainer>
					<InputContainer>
						<NestInput
							label='Nazwisko'
							control={lightIndexLandingPageContext.methods?.control as unknown as Control}
							name='lastName'
							rules={{
								minLength: { value: 2, message: 'Niepoprawna wartość' },
								required: 'Pole wymagane',
								pattern: {
									value: nameValidator,
									message: 'Niepoprawna wartość',
								},
							}}
							maskFunc={firstNameAndLastNameMaskFunc}
							useMaskAsValue
							mask='######'
							wrapperSx={{ marginBottom: '24px' }}
						/>
					</InputContainer>
				</FieldsWrapper>

				<FieldsWrapper
					sx={{
						alignItems: { md: 'flex-end', xs: 'center' },
						flexDirection: { xs: 'column', md: 'row' },
					}}
				>
					<InputContainer>
						<NestInput
							label='Numer telefonu komórkowego'
							control={lightIndexLandingPageContext.methods?.control as unknown as Control}
							rules={phoneNumberValidator}
							name='phoneNumber'
							wrapperSx={{ marginBottom: '22px' }}
							maskFunc={phoneNumberMask}
							mask=''
							prefix='+48&nbsp;'
						/>
					</InputContainer>
					<InputContainer>
						<NestInput
							label='Adres e-mail'
							control={lightIndexLandingPageContext.methods?.control as unknown as Control}
							name='email'
							rules={emailValidator}
							wrapperSx={{ marginBottom: '24px' }}
						/>
					</InputContainer>
				</FieldsWrapper>
				{lightIndexLandingPageContext.initialData?.enableBrokerIdField && (
					<FieldsWrapper sx={{ justifyContent: { xs: 'center', md: 'flex-start' } }}>
						<InputContainer>
							<NestInput
								label='Kod polecenia (jeśli posiadasz)'
								control={lightIndexLandingPageContext.methods?.control as unknown as Control}
								name='brokerId'
								rules={{
									minLength: { value: 2, message: 'Niepoprawna wartość' },
									maxLength: { value: 50, message: 'Niepoprawna wartość' },
									pattern: {
										value: onlyWordsAndNumbers,
										message: 'Niepoprawna wartość',
									},
								}}
								wrapperSx={{ marginBottom: '24px' }}
							/>
						</InputContainer>
					</FieldsWrapper>
				)}
			</FormSectionWrapper>
			<Stack
				sx={{
					width: '100%',
					margin: '42px 0 0 0',
				}}
			>
				<>
					<MediaAgreementCollection />
					<Stack
						sx={{
							marginBottom: '32px',
							maxWidth: { xs: '100%', md: '70%' },
						}}
					>
						Zgoda ta może być odwołana w każdej chwili bez podania przyczyny, bez wpływu na zgodność z prawem działań, które wykonano na podstawie
						zgody przed jej odwołaniem.
					</Stack>
				</>

				<Loader />
			</Stack>
			<Typography
				variant='p1'
				sx={{
					maxWidth: '516px',
					marginBottom: '48px',
					fontWeight: '400',
					lineHeight: '140%',
				}}
			>
				Administratorem danych osobowych jest Nest Bank S.A. z siedzibą w 02-675 Warszawa ul. Wołoska 24. Dane będą przetwarzane m.in. w celach
				zawarcia umowy oraz celach marketingowych w tym w celu przedstawienia oferty.
				<Typography
					onClick={(): void => {}}
					sx={{
						display: 'block',
						color: mainTheme.palette.secondary.main,
						fontWeight: '700',
						cursor: 'pointer',
						fontFamily: 'Athletics, sans-serif',
					}}
					variant='p2'
				>
					Zobacz więcej
				</Typography>
			</Typography>

			<ButtonTextContainer
				sx={{
					alignItems: { md: 'flex-end', xs: 'center' },
					margin: { xs: '0 0 46px 0', md: '0 0 80px 0' },
				}}
			>
				<Stack
					sx={{
						flexDirection: 'column',
					}}
				>
					{(Object.keys(lightIndexLandingPageContext.methods ? lightIndexLandingPageContext.methods.formState.errors : {}).length > 0 ||
						!validAgreements) && <NestFormInfo />}
					<CustomButton variant='contained' type='submit' isLoading={true} onClick={(): void => setClickedSubmit(true)}>
						Złóż wniosek
					</CustomButton>
				</Stack>
			</ButtonTextContainer>
		</>
	);
};

export default LightIndexContactDataForm;
