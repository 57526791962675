import { Routing, WebPage } from '../../routing/Routing';

export interface PageRendererProps {
	page: WebPage;
	close: () => void;
}
export default function PageRenderer(props: PageRendererProps): JSX.Element {
	const Component = (): JSX.Element => {
		const element = Routing.find((x): boolean => x.page == props.page)?.element;
		if (!element) {
			throw new Error('Component not found');
		}
		return element({
			ommitRouting: true,
			close: props.close,
			submitText: 'Zapisz zmiany',
			cancelText: 'Anuluj',
		});
	};

	return <>{props.page && <Component />}</>;
}
