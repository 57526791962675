export const phoneNumberValidator = {
	maxLength: { value: 9, message: 'Niepoprawny numer telefonu' },
	minLength: { value: 9, message: 'Niepoprawny numer telefonu' },
	required: 'Pole wymagane',
	pattern: {
		value: /^[4-9][0-9]*$/,
		message: 'Niepoprawny numer telefonu',
	},
};

export const optionalPhoneNumberValidator = {
	maxLength: { value: 9, message: 'Niepoprawny numer telefonu' },
	minLength: { value: 9, message: 'Niepoprawny numer telefonu' },
	required: undefined,
	pattern: {
		value: /^[4-9][0-9]*$/,
		message: 'Niepoprawny numer telefonu',
	},
};

export const phoneNumberValidatorOnlyLength = {
	maxLength: { value: 9, message: 'Niepoprawny numer telefonu' },
	minLength: { value: 9, message: 'Niepoprawny numer telefonu' },
	required: 'Pole wymagane',
};
