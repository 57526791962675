export const polishWordsAndDashValidator =
	/^([a-zA-ZąĄęĘóÓłŁćĆńŃśŚżŻźŹ-]+\s)*[a-zA-ZąĄęĘóÓłŁćĆńŃśŚżŻŹź-]+$/;

export const onlyPolishWordsAndDigits = {
	pattern: {
		message: 'Niepoprawna wartość',
		value:
			/^([a-zA-Z0-9ąĄęĘóÓłŁ/ćĆńŃśŚżŻźŹ0-9-]+\s)*[a-zA-Z0-9ąĄęĘóÓłŁćĆ/ńŃśŚżŻŹź0-9-]+$/,
	},
};

export const streetValidator = {
	pattern: {
		message: 'Niepoprawna wartość',
		value:
			/^([a-zA-Z0-9ąĄęĘóÓłŁ/.ćĆńŃśŚżŻźŹ0-9-]+\s)*[a-zA-Z0-9ąĄęĘóÓłŁćĆ/.ńŃśŚżŻŹź0-9-]+$/,
	},
};

export const nameValidator =
	/^[a-zA-Zó-żÓ-Ż]([a-zA-Zó-żÓ-Ż]+[ -]?)*[a-zA-Zó-żÓ-Ż]$/;

export const onlyWordsAndNumbers = /^[A-Za-z0-9]*$/;

export const companyNameValidator = /^([a-zA-Zó-żÓ-Ż0-9]+[ \\\\-]{1})+([a-zA-Zó-żÓ-Ż0-9]+)$|^([a-zA-Zó-żÓ-Ż0-9]+)$/;
