import { InitialData } from '../rbf-new-application/NewApplicationApi';
import CreditGoodToKnow from './credit-good-to-know/CreditGoodToKnow';
import { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { TechnicalErrorRoute } from '../../../routing/Routing';
import { Container } from '../../../common/styles/Layout.css';
import Loader from '../../../components/Loader/Loader';
import CreditInfoSection from './credit-info-section/CreditInfoSection';
import CreditDescription from '../../../components/CreditDescription/CreditDescription';

export default function LandingPage(): JSX.Element {
	const initialData = useLocation().state as InitialData | undefined;
	const state = useLocation().state as { internalClientId: string } | undefined;
	const navigate = useNavigate();

	useEffect((): void => {
		if (!initialData || !state) {
			navigate(TechnicalErrorRoute.path);
		}
	}, []);

	if (!initialData || !state) {
		return (
			<Container>
				<Loader />
			</Container>
		);
	}

	return (
		<div>
			<CreditInfoSection
				initialData={initialData}
				internalClientId={state.internalClientId}
			/>
			<CreditDescription />
			<CreditGoodToKnow />
		</div>
	);
}
