import React, { useContext } from 'react';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { gridFullWidth, gridHalfWidth } from 'src/theme/grid/GridConstants';
import NestInput from 'src/components/FormItems/NestInput/NestInput';
import { Control } from 'react-hook-form';
import { peselValidator } from 'src/common/validators/peselValidator';
import { LightCompanyDataContext } from '../state/LightCompanyDataContext';

const LightPersonalDataInfoSection = (): JSX.Element => {
	const lightCompanyDataContext = useContext(LightCompanyDataContext);
	return (
		<Grid container rowSpacing={3} columnSpacing={9}>
			<Grid item xs={gridFullWidth} sx={{ marginTop: '24px' }}>
				<Typography variant='h5'>Dane osobowe</Typography>
			</Grid>
			<Grid item xs={gridFullWidth} md={gridHalfWidth}>
				<NestInput disabled label='Imię' control={lightCompanyDataContext.methods.control as unknown as Control} name='firstName' />
			</Grid>
			<Grid item xs={gridFullWidth} md={gridHalfWidth}>
				<NestInput disabled label='Nazwisko' control={lightCompanyDataContext.methods.control as unknown as Control} name='lastName' />
			</Grid>
			<Grid item xs={gridFullWidth} md={gridHalfWidth}>
				<NestInput
					label='PESEL'
					control={lightCompanyDataContext.methods.control as unknown as Control}
					name='socialSecurityNumber'
					autoComplete='off'
					rules={peselValidator}
				/>
			</Grid>
			<Grid item xs={gridFullWidth} sx={{ marginTop: '24px', marginBottom: '24px' }}>
				<Typography variant='h5'>Dane firmy</Typography>
			</Grid>
		</Grid>
	);
};

export default LightPersonalDataInfoSection;
