import { AcceptAllContainer, Container, HeaderContainer, ScrollAndAccept } from './AgreementCollection.css';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../store/State';
import { AgreementModel, AgreementSectionModel } from './AgreementsModels';
import { AgreementContainer, AgreementHeadingContainer, MarginTop20 } from './Agreement/Agreement.css';
import FlatAgreement from './Agreement/FlatAgreement';
import { Switch, Typography } from '@mui/material';
import { useRef } from 'react';
import { handleAllAgreementsChecked } from 'src/store/slices/agreementsSlice/AgreementsSlice';

type FlatAgreementCollectionProps = {
	agreementsTitle?: string;
	customBackground?: boolean;
};

export default function FlatAgreementCollection(props: Readonly<FlatAgreementCollectionProps>): JSX.Element {
	const scrollRef = useRef<HTMLInputElement | null>(null);
	const dispatch = useDispatch();
	const { agreementsTitle, customBackground } = props;
	const agreements = useSelector((state: RootState): AgreementSectionModel[] => state.agreements.agreements);

	const handleCheckedChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
		dispatch(
			handleAllAgreementsChecked({
				isSelected: event.target.checked,
			})
		);
	};

	return (
		<Container customBackground={customBackground}>
			{customBackground && (
				<HeaderContainer>
					<Typography variant='h5' sx={{ fontSize: '24px' }}>
						{agreementsTitle}
					</Typography>
					<ScrollAndAccept onClick={(): void => scrollRef.current?.scrollIntoView({ block: 'center', behavior: 'smooth' })}>
						Przewiń i zaakceptuj wszystkie
					</ScrollAndAccept>
				</HeaderContainer>
			)}
			{agreements.map(
				(x: AgreementSectionModel, sectionId: number): JSX.Element => (
					<AgreementContainer sx={{ marginBottom: '25px', marginLeft: customBackground ? '40px' : '0px' }} key={x.section}>
						<MarginTop20>
							{x.consentGroup.map(
								(i: AgreementModel, idGroup: number): JSX.Element => (
									<FlatAgreement sectionIndex={sectionId} agreementIndex={idGroup} agreement={i} key={i.symbol} />
								)
							)}
						</MarginTop20>
					</AgreementContainer>
				)
			)}
			{customBackground && (
				<AcceptAllContainer ref={scrollRef}>
					<AgreementHeadingContainer>
						<Typography
							variant='p1'
							sx={{
								display: 'inline-block',
								fontWeight: '600',
								marginLeft: '12px',
							}}
						>
							Akceptuje wszystkie powyższe zgody i oświadczenia niezbędne do złożenia wniosku
						</Typography>
					</AgreementHeadingContainer>
					<Switch
						sx={{ marginLeft: { md: '40px', xs: '15px' } }}
						checked={!agreements.some((agreement): boolean => agreement.isSelected === false)}
						onChange={(event: React.ChangeEvent<HTMLInputElement>): void => handleCheckedChange(event)}
					/>
				</AcceptAllContainer>
			)}
		</Container>
	);
}
