import { formatAmount, formatAmountWithDecimalValues } from './number-helper';

export interface maskFuncResult {
	maskedValue: string;
	rawValue: string;
	cursorPos: number | null;
}

export type maskFuncType = (currentValue: string, value: string, mask: string, cursorPos: number | null) => maskFuncResult;

// only for usage with disabled inputs, with any mask
export const staticMask: maskFuncType = (_currentValue, value, mask, _cursorPos): maskFuncResult => {
	let maskValue = '';
	let index = 0;
	for (const val of mask.split('')) {
		if (val == '#') {
			if (isNaN(value[index] as unknown as number)) {
				break;
			}
			maskValue += value[index];
			index++;
		} else if (val == '*') {
			maskValue += value[index];
			index++;
		} else {
			maskValue += val;
		}
	}

	return { maskedValue: maskValue, rawValue: value, cursorPos: null };
};

// only for usage with masks containing digits and static characters (can't be next to each other like '##  ##' or #-##--#), example: nip, iban, postalCode
export const simpleDigitsMask: maskFuncType = (currentValue, value, mask, cursorPos): maskFuncResult => {
	const rawValue = value.replace(/\D/g, '').substring(0, (mask.match(new RegExp('#', 'g')) ?? []).length);

	const isCursorAtEnd = cursorPos == value.length;
	const wasDeleted = value.length + 1 == currentValue.length;
	let maskValue = '';
	let index = 0;
	for (const val of mask.split('')) {
		if (val === '#') {
			if (rawValue.length <= index) {
				break;
			}
			maskValue += rawValue[index];
			index++;
		} else if (val !== '#') {
			if (currentValue == `${maskValue}${val}` && maskValue == value) {
				maskValue = maskValue.slice(0, maskValue.length);
				break;
			}
			maskValue += val;
		}
	}

	if (cursorPos) {
		if (isCursorAtEnd) {
			cursorPos = maskValue.length;
		} else if ((mask[cursorPos] != '#' || mask[cursorPos - 1] != '#') && !wasDeleted) {
			cursorPos += 1;
		}
	}

	return { maskedValue: maskValue, rawValue: rawValue, cursorPos: cursorPos };
};

export const firstNameAndLastNameMaskFunc: maskFuncType = (currentValue, value, _mask, cursorPos): maskFuncResult => {
	const names = value.split(' ');
	for (let i = 0; i < names.length; i++) {
		if (names[i].includes('-')) {
			const subNames = names[i].split('-');
			for (let j = 0; j < subNames.length; j++) {
				subNames[j] = subNames[j].charAt(0).toUpperCase() + subNames[j].substring(1);
			}
			names[i] = subNames.join('-');
		} else {
			names[i] = names[i].charAt(0).toUpperCase() + names[i].substring(1);
		}
	}
	const maskedValue = names.join(' ');

	return { maskedValue: maskedValue, rawValue: value, cursorPos: cursorPos };
}

// only for usage with phone numbers, mask is ignored
export const phoneNumberMask: maskFuncType = (currentValue, value, _mask, cursorPos): maskFuncResult => {
	const isCursorAtEnd = cursorPos == value.length;
	const wasDeleted = value.length + 1 == currentValue.length;
	value = value.replace(/\D/g, '').substring(0, 9);
	const parts = value.match(/[\d]{1,3}/g) ?? [];
	const result = parts.join(' ');
	if (cursorPos) {
		if (isCursorAtEnd) {
			cursorPos = result.length;
		} else if ((result[cursorPos] == ' ' || result[cursorPos - 1] == ' ') && !wasDeleted) {
			cursorPos += 1;
		}
	}
	return { maskedValue: result, rawValue: value, cursorPos: cursorPos };
};

// only for usage with static amount fields
export const amountMask: maskFuncType = (_currentValue, value, _mask, _cursorPos): maskFuncResult => {
	const result = formatAmount(+value);
	return { maskedValue: result, rawValue: value, cursorPos: null };
};

export const amountDynamicMask: maskFuncType = (_currentValue, value, _mask, _cursorPos): maskFuncResult => {
	value = value.toString().replace(/[^\d,]/g, '');
	value = formatAmountWithDecimalValues(value);

	return { maskedValue: value, rawValue: value, cursorPos: null };
};

export const setCaretPosition = (ctrl: HTMLInputElement, pos: number): void => {
	setTimeout((): void => {
		ctrl.focus();
		ctrl.setSelectionRange(pos, pos);
	}, 0);
};

export const smsCodeMaskHelper: maskFuncType = (_currentValue, value, _mask, _cursorPos): maskFuncResult => {
	const onlyNumbersRegex = new RegExp('^[0-9]*$');
	if(!onlyNumbersRegex.test(value) || value.length > 4){
		value = value.split("").slice(0, -1).join("")
	}
	return { maskedValue: value, rawValue: value, cursorPos: null };
}

export const upperCaseMask: maskFuncType = (_currentValue, value, _mask, _cursorPos): maskFuncResult => {
	value = value.toUpperCase();
	return { maskedValue: value, rawValue: value, cursorPos: null };
}
