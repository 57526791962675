
export interface ILeadToLightClDataState {
	personalData: ILeadToLightClDataProperties;
}

export interface ILeadToLightClDataProperties {
	phoneNumber: string;
	nip: string;
	firstName: string;
	lastName: string;
}

export const initialState: ILeadToLightClDataState = {
	personalData: {
		phoneNumber: '',
		nip: '',
		firstName: '',
		lastName: '',
	},
};
