export function formatDate(date: Date | undefined): string {
	return new Date(date ?? '').toLocaleDateString('pl-PL', {
		day: '2-digit',
		month: '2-digit',
		year: 'numeric',
	});
}

export function formatDateToSend(
	date: Date | undefined | string
): string | undefined {
	if (!date) {
		return;
	}

	return new Intl.DateTimeFormat('zh-CN', {
		year: 'numeric',
		month: '2-digit',
		day: '2-digit',
	})
		.format(date as Date)
		.replaceAll('/', '-');
}

export const processDate = (date?: Date | string): Date | undefined => date ? new Date(date) : undefined
