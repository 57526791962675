import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import React, { useContext, useEffect, useState } from 'react';
import { FieldErrors, FieldErrorsImpl, FormProvider, useForm, UseFormReturn } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { groupAllAgreements } from 'src/common/helpers/group-all-agreements';
import { IAgreementsStateSlice } from 'src/store/slices/agreementsSlice/AgreementSliceModels';
import { RootState } from 'src/store/State';
import { GetCountriesConfig } from '../../../../common/api/DictionaryApi';
import useFetch from '../../../../common/api/UseFetch';
import {
	convertAgreementsToAgreementModelToSend,
	convertedClientWealthDataToSend,
	parseClientWealthObj,
} from '../../../../common/helpers/client-wealth-and-agreements-req-data-helpers';
import { formatDateToSend, processDate } from '../../../../common/helpers/date-helper';
import { ScrollToTopOnMount } from '../../../../common/helpers/scroll-to-top-onmount';
import AddressForm from '../../../../components/AddressForm/AddressForm';
import AgreementCollection from '../../../../components/AgreementCollection/AggreementCollection';
import { AgreementModel } from '../../../../components/AgreementCollection/AgreementsModels';
import CustomButton from '../../../../components/CustomButton/CustomButton';
import { ContextSaveApplicationData } from '../../../../context/ContextSaveApplicationData';
import { OverrideProps } from '../../../../routing/RouteSpec';
import {
	LightCompanyDataVerifiedRoute,
	LightDataConsistencyRoute,
	LightOvdCompanyDataVerifiedRoute,
	LightOvdDataConsistencyRoute,
	WebPage,
} from '../../../../routing/Routing';
import { checkErrors, checkIsAllAgreementsChecked, saveAgreements } from '../../../../store/slices/agreementsSlice/AgreementsSlice';
import { gridFullWidth } from '../../../../theme/grid/GridConstants';
import { navigateToRoute } from '../../../common/helpers/navigateToRoute';
import ClientWealthForm from '../../../rbf/rbf-personal-data/components/ClientWealthForm/ClientWealthForm';
import { IClientWealth, IClientWealthSendData } from '../../../rbf/rbf-personal-data/PersonalDataModels';
import { GetCompanyDataAgreementsConfig } from '../light-company-data/api/LightCompanyDataApi';
import { DraftLightPersonalDataConfig, GetLightPersonalDataConfig, PostLightPersonalDataConfig } from './api/LightPersonalDataApi';
import CreditOrLeasingLiabilities from './components/CreditOrLeasingLiabilities/CreditOrLeasingLiabilities';
import EconomicLiabilities from './components/EconomicLiabilities/EconomicLiabilities';
import LightPersonalDataInfoAboutYou from './components/LightPersonalDataInfoAboutYou/LightPersonalDataInfoAboutYou';
import {
	getErrorsLightAddressForm,
	getErrorsLightCorrespondenceAddressForm,
	lightPersonalAddressFormControlNames,
	lightPersonalCorrespondenceAddressFormControlNames,
	LightPersonalDataDefaultValues,
} from './consts/LightPersonalDataConstants';
import {
	getAppriopriatelyLiabilitiesObjToSendData,
	mapEconomicAndCreditOrLeasingLiabilitiesToFormValues,
} from './mappers/light-personal-data-mappers';
import { EconomicCreditAndLeasingLiabilities, LightPersonalDataProperties } from './models/LightPersonalDataModels';
import { convertNumbersToString } from 'src/common/helpers/number-helper';
import { pushLightEventVirtualpvPersonalData } from 'src/common/googleAnalyticsEvents/googleAnalyticsEvents';
import { NestFormInfo } from 'src/components/FormItems/NestFormInfo/NestFormInfo';

const LightPersonalData = (props?: OverrideProps): JSX.Element => {
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const agreementState = useSelector((state: RootState): IAgreementsStateSlice => state.agreements);

	useEffect((): void => {
		pushLightEventVirtualpvPersonalData();
	}, []);

	const methods = useForm({
		defaultValues: LightPersonalDataDefaultValues,
		mode: 'onSubmit',
		reValidateMode: 'onChange',
	});

	const [isSaveApplicationData] = useContext(ContextSaveApplicationData);

	const watchClientWealth = methods.watch('clientWealth');
	const watchIsCorrespondenceAddressSameAsAddress = methods.watch('isCorrespondenceAddressSameAsAddress');

	const [isOpenClientWealthForm, setIsOpenClientWealthForm] = useState<boolean>(false);
	const [agreementsValid, setAgreementsValid] = useState<boolean>(true);
	const [submitClicked, setSubmitClicked] = useState<boolean>(false);

	const getLightPersonalData = useFetch(GetLightPersonalDataConfig, true);
	const getCountries = useFetch(GetCountriesConfig, true);

	const companyAgreementFetch = useFetch(GetCompanyDataAgreementsConfig(WebPage.LightClPersonalData), true, (data: AgreementModel[]): void => {
		dispatch(
			saveAgreements(
				groupAllAgreements(data, {
					hideExpandGroup: true,
					hideExpandElement: true,
					isExpandGroup: true,
					isExpandElement: true,
					switchDisplayContent: true,
					agreementFilter: 'L1_KONTAKT',
					headers: {
						reguiredSectionHeader: 'Zgody i oświadczenia niezbędne do złożenia wniosku',
						additionalSectionHeader: 'Dodatkowe zgody na kontakt',
					},
				})
			)
		);
	});

	const draftLightPersonalData = useFetch(DraftLightPersonalDataConfig, false, (): void => {
		if (props?.close) {
			props.close();
		}
	});

	const postLightPersonalData = useFetch(PostLightPersonalDataConfig, false, (): void => {
		if (!props?.ommitRouting) {
			navigate(navigateToRoute(LightDataConsistencyRoute.path, LightOvdDataConsistencyRoute.path) as string);
		}
		if (props?.close) {
			props.close();
		}
	});

	useEffect((): void => {
		if (!getLightPersonalData.data) {
			return;
		}
		if (!getCountries.data) {
			return;
		}

		methods.reset({
			...getLightPersonalData.data,
			monthlyLivingCosts: convertNumbersToString(getLightPersonalData.data.monthlyLivingCosts),
			otherMonthlyCosts: convertNumbersToString(getLightPersonalData.data.otherMonthlyCosts),
			unemployedDependantPeopleAmount: convertNumbersToString(getLightPersonalData.data.unemployedDependantPeopleAmount),
			idCardExpirationDate: processDate(getLightPersonalData.data.idCardExpirationDate),
			idCardIssuedDate: processDate(getLightPersonalData.data.idCardIssuedDate),
			clientWealth: parseClientWealthObj(getLightPersonalData.data.clientWealth as IClientWealthSendData[]),
			countryOfBirth: getCountries.data.some((country): boolean => country.countryName === getLightPersonalData.data.countryOfBirth)
				? getLightPersonalData.data.countryOfBirth
				: 'Polska',
			isCorrespondenceAddressSameAsAddress:
				getLightPersonalData.data.isCorrespondenceAddressSameAsAddress === null || getLightPersonalData.data.isCorrespondenceAddressSameAsAddress,
			idCard: getLightPersonalData.data.idCardNumber,
			...mapEconomicAndCreditOrLeasingLiabilitiesToFormValues(getLightPersonalData.data),
		});
		setIsOpenClientWealthForm(!!getLightPersonalData.data.clientWealth.length);
	}, [getLightPersonalData.data, getCountries.data]);

	useEffect((): void => {
		dispatch(checkIsAllAgreementsChecked());
	}, [companyAgreementFetch.data]);

	useEffect((): void => {
		if (isSaveApplicationData) {
			saveApplicationData();
		}
	}, [isSaveApplicationData]);

	useEffect((): void => {
		if (submitClicked) {
			dispatch(checkErrors());
			setAgreementsValid(agreementState.isValid);
		}
	}, [agreementState.agreements[0]?.isSelected, agreementState.isValid]);

	const saveApplicationData = (): void => {
		const formValues = methods.watch();
		const dataToSend: LightPersonalDataProperties = {
			...formValues,
			clientWealth: convertedClientWealthDataToSend(isOpenClientWealthForm, watchClientWealth),
			...converterCompanyAverageAndMonthlyCostsValues(),
			agreements: convertAgreementsToAgreementModelToSend(agreementState.agreements),
			idCardIssuedDate: formatDateToSend(methods.watch('idCardIssuedDate')),
			idCardExpirationDate: formatDateToSend(methods.watch('idCardExpirationDate')),
			unemployedDependantPeopleAmount: methods.watch('unemployedDependantPeopleAmount')
				? Number(methods.watch('unemployedDependantPeopleAmount'))
				: '',
			idCardNumber: methods.watch('idCard'),
			...(getAppriopriatelyLiabilitiesObjToSendData(formValues) as unknown as EconomicCreditAndLeasingLiabilities),
		};
		delete dataToSend.idCard;
		draftLightPersonalData.mutate(dataToSend);
	};

	const saveAndBackToPreviousPage = (): void => {
		methods.clearErrors();
		props?.close && props.close();
		if (!props?.ommitRouting) {
			navigate(navigateToRoute(LightCompanyDataVerifiedRoute.path, LightOvdCompanyDataVerifiedRoute.path) as string);
			saveApplicationData();
		} else {
			return;
		}
	};

	const handleChecked = (event: React.ChangeEvent<HTMLInputElement>): void => {
		methods.setValue('isCorrespondenceAddressSameAsAddress', event.target.checked);
	};

	const converterCompanyAverageAndMonthlyCostsValues = (): {
		monthlyLivingCosts: number | string;
		otherMonthlyCosts: number | string;
	} => {
		const companyAverageMonthlyIncomeConvertedToSendModel = (methods.watch('monthlyLivingCosts') as string)
			?.split(' ')
			?.join('')
			?.replace(',', '.')
			?.replace(/\s+/g, '');
		const companyOtherMonthlyCostsConvertedToSendModel = (methods.watch('otherMonthlyCosts') as string)
			?.split(' ')
			?.join('')
			?.replace(',', '.')
			?.replace(/\s+/g, '');

		return {
			monthlyLivingCosts: companyAverageMonthlyIncomeConvertedToSendModel === '' ? '' : Number(companyAverageMonthlyIncomeConvertedToSendModel),
			otherMonthlyCosts: companyOtherMonthlyCostsConvertedToSendModel === '' ? '' : Number(companyOtherMonthlyCostsConvertedToSendModel),
		};
	};

	const onSubmit = (data: LightPersonalDataProperties): void => {
		if (agreementState.agreements.length && !agreementState.isValid) {
			return;
		}
		if (watchIsCorrespondenceAddressSameAsAddress) {
			data.correspondenceAddress = { ...data.address };
		}
		const dataToSend: LightPersonalDataProperties = {
			...data,
			idCardIssuedDate: formatDateToSend(data.idCardIssuedDate),
			idCardExpirationDate: formatDateToSend(data.idCardExpirationDate),
			...converterCompanyAverageAndMonthlyCostsValues(),
			agreements: convertAgreementsToAgreementModelToSend(agreementState.agreements),
			clientWealth: convertedClientWealthDataToSend(isOpenClientWealthForm, watchClientWealth) as unknown as IClientWealthSendData[],
			unemployedDependantPeopleAmount: Number(data.unemployedDependantPeopleAmount),
			idCardNumber: data.idCard,
			...(getAppriopriatelyLiabilitiesObjToSendData(data) as unknown as EconomicCreditAndLeasingLiabilities),
		};
		delete dataToSend.idCard;
		postLightPersonalData.mutate(dataToSend as never);
	};

	const scrollToErrors = (errors: FieldErrors<LightPersonalDataProperties>): void => {
		const firstErrorField = Object.keys(errors)[0];
		const isFieldComplex = !(errors as Record<string, FieldErrors>)[firstErrorField]?.ref;

		if (submitClicked && firstErrorField) {
			if (isFieldComplex) {
				document
					.querySelector(`[name='${firstErrorField}.${Object.keys((errors as Record<string, FieldErrors>)[firstErrorField] as object)[0]}']`)
					?.scrollIntoView({ block: 'center', behavior: 'smooth' });
			} else {
				document.querySelector(`[name='${firstErrorField}']`)?.scrollIntoView({ block: 'center', behavior: 'smooth' });
			}
			setSubmitClicked(false);
		}
	};

	useEffect((): void => {
		scrollToErrors(methods.formState.errors);
	}, [JSON.stringify(methods.formState.errors), submitClicked]);

	const preSubmitHandler = (): void => {
		dispatch(checkErrors());
		setSubmitClicked(true);
		methods.handleSubmit(onSubmit);
	};

	return (
		<Stack sx={{ padding: '16px', margin: 'auto', maxWidth: '880px' }}>
			<Stack sx={{ width: '100%' }}>
				<ScrollToTopOnMount />
				<FormProvider {...methods}>
					<Stack component='form' onSubmit={methods.handleSubmit(onSubmit)}>
						<LightPersonalDataInfoAboutYou
							methods={methods as unknown as UseFormReturn<Record<string, unknown>>}
							countries={getCountries.data ?? []}
						/>

						<ClientWealthForm
							methods={methods as unknown as UseFormReturn<Record<string, unknown>>}
							watch={watchClientWealth as unknown as IClientWealth[]}
							setIsOpenClientWealthForm={setIsOpenClientWealthForm}
							isOpenClientWealthForm={isOpenClientWealthForm}
						/>
						<>
							<EconomicLiabilities methods={methods as unknown as UseFormReturn<Record<string, unknown>>} />
							<CreditOrLeasingLiabilities methods={methods as unknown as UseFormReturn<Record<string, unknown>>} />
						</>

						<Grid container rowSpacing={3} columnSpacing={9} sx={{ marginTop: '-5px' }}>
							<Grid item xs={gridFullWidth}>
								<Typography variant='h5'>Adres zamieszkania</Typography>
							</Grid>
							<AddressForm
								methods={methods}
								formControlNames={lightPersonalAddressFormControlNames}
								checkboxLabel='Taki sam jak adres zamieszkania'
								checked={watchIsCorrespondenceAddressSameAsAddress}
								handleChecked={handleChecked}
								errors={getErrorsLightAddressForm(methods.formState.errors) as Partial<FieldErrorsImpl<Record<string, unknown>>>}
							/>
						</Grid>
						{!watchIsCorrespondenceAddressSameAsAddress && (
							<Grid sx={{ marginBottom: '24px' }} container rowSpacing={3} columnSpacing={9}>
								<AddressForm
									methods={methods}
									formControlNames={lightPersonalCorrespondenceAddressFormControlNames}
									errors={getErrorsLightCorrespondenceAddressForm(methods.formState.errors) as Partial<FieldErrorsImpl<Record<string, unknown>>>}
								/>
							</Grid>
						)}

						{/*AGREEMENTS */}
						<Stack
							sx={{
								width: '100%',
								borderTop: '2px solid #656572',
								margin: '8px 0 0 0',
							}}
						>
							<Stack
								sx={{
									padding: '32px 0',
									maxWidth: { xs: '100%', md: '70%' },
									flexDirection: 'row',
									justifyContent: 'space-between',
									alignItems: 'center',
								}}
							>
								<Typography variant='h2'>Zgody i oświadczenia</Typography>
							</Stack>
							{Object.keys(agreementState.agreements).length > 0 && <AgreementCollection disableAcceptAll fullWidth customBackground />}
						</Stack>

						{/* BUTTONS */}
						<Stack
							sx={{
								padding: '32px 0 60px 0',
								flexDirection: { md: 'row', sx: 'column' },
								justifyContent: 'space-between',
								alignItems: { md: 'flex-end', xs: 'center' },
							}}
						>
							<Stack>
								<CustomButton variant='outlined' onClick={saveAndBackToPreviousPage}>
									{props?.cancelText ?? 'Cofnij'}
								</CustomButton>
							</Stack>
							<Stack
								sx={{
									alignItems: 'center',
									marginTop: { xs: '32px', md: '0' },
								}}
							>
								<Stack
									sx={{
										flexDirection: 'column',
									}}
								>
									{(Object.keys(methods.formState.errors).length > 0 || !agreementsValid) && submitClicked && <NestFormInfo />}
									<CustomButton variant='contained' type='submit' onClick={preSubmitHandler}>
										{props?.submitText ?? 'Dalej'}
									</CustomButton>
								</Stack>
							</Stack>
						</Stack>
					</Stack>
				</FormProvider>
			</Stack>
		</Stack>
	);
};

export default LightPersonalData;
