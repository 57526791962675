import { useEffect, useState } from 'react';
import { useForm, UseFormReturn } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { Location, useNavigate } from 'react-router-dom';
import { GetDownloadFileConfig } from '../../../../../common/api/FileApi';
import useFetch from '../../../../../common/api/UseFetch';
import { convertMediaAgreementsToAgreementModelToSend } from '../../../../../common/helpers/client-wealth-and-agreements-req-data-helpers';
import { groupAgreementsBySection } from '../../../../../common/helpers/group-agreements-by-section-helper';
import {
	applicationIdKey,
	applicationNumberKey,
	authTokenKey,
	getSessionStorageObj,
	locationKey,
	marketingPartnerIdKey,
	setSessionStorageValue,
	userIdKey,
} from '../../../../../common/sessionStorage/SessionStorageService';
import { CreateComponentContext, notImplementedFunction } from '../../../../../context/CreateComponentContext';
import { FileType } from '../../../../../enums/FileType';
import { ApplicationInProgressRoute, LightCompanyDataRoute, RejectionReasonRoute, TechnicalErrorRoute } from '../../../../../routing/Routing';
import { IAgreementsStateSlice } from '../../../../../store/slices/agreementsSlice/AgreementSliceModels';
import { saveAgreements } from '../../../../../store/slices/agreementsSlice/AgreementsSlice';
import { saveWrittenValues } from '../../../../../store/slices/leadToLightClDataSlice/LeadToLightClDataSlice';
import {
	ILeadToLightClDataProperties
} from '../../../../../store/slices/leadToLightClDataSlice/LeadToLightClDataSliceConstants';
import { RootState } from '../../../../../store/State';
import { InitialLightData } from '../../cl-new-application/api/LightNewApplicationApi';
import { defaultValues } from '../light-credit-info-and-contact-data-form/components/LightContactDataForm/LightContactDataFormConstants';
import { ILightContactDataProperties } from '../light-credit-info-and-contact-data-form/LightCreditInfoAndContactDataFormModels';
import { calculateLoanInfoConfig, GetAgreement, LightCreateApplicationConfig } from '../LightLandingPageApi';

const defaultLightLandingPageContext: LightLandingPageState = {
	installment: 0,
	creditPeriod: 0,
	onCreditPeriodChange: notImplementedFunction,
	handleClickShowMore: notImplementedFunction,
	handleSubmit: notImplementedFunction,
	agreementState: [],
	calculateLoanIsLoading: false,
	createApplicationIsLoading: false,
	initialData: undefined,
	getAgreementsIsLoading: false,
};

interface LightLandingPageState {
	creditPeriod: number;
	installment: number;
	onCreditPeriodChange: (value: number) => void;
	handleClickShowMore: () => void;
	handleSubmit: (data: ILightContactDataProperties) => void;
	agreementState: IAgreementsStateSlice | [];
	calculateLoanIsLoading: boolean;
	createApplicationIsLoading: boolean;
	methods?: UseFormReturn<ILightContactDataProperties> | undefined;
	initialData: InitialLightData | undefined;
	getAgreementsIsLoading: boolean;
}

const lightLandingPageContextHandler = (): LightLandingPageState => {
	const [creditPeriod, setCreditPeriod] = useState(0);
	const [installment, setInstallment] = useState(0);

	const dispatch = useDispatch();
	const initialData = getSessionStorageObj<Location>(locationKey)?.state as InitialLightData | undefined;

	const navigate = useNavigate();

	const methods = useForm<ILightContactDataProperties>({
		mode: 'all',
		defaultValues,
	});

	const agreementState = useSelector((state: RootState): IAgreementsStateSlice => state.agreements);
	
	const personalDataFromLead = useSelector((state: RootState): ILeadToLightClDataProperties => state.personalData.personalData)
	
	const getAgreements = useFetch(GetAgreement, false, (result): void => {
		dispatch(saveAgreements(groupAgreementsBySection(result)));
	});

	const getInformationClauseMutation = useFetch(GetDownloadFileConfig(FileType.InformationClause, true), false);

	const createApplication = useFetch(
		LightCreateApplicationConfig,
		false,
		(result): void => {
			dispatch(saveWrittenValues(
				{
					firstName: '',
					lastName: '',
					nip: '',
					phoneNumber: ''
				})
			)
			if (!result.canProceed) {
				navigate(ApplicationInProgressRoute.path);
				return;
			}
			setSessionStorageValue(authTokenKey, result.token);
			setSessionStorageValue(applicationIdKey, result.applicationId);
			setSessionStorageValue(applicationNumberKey, result.applicationNumber);
			setSessionStorageValue(marketingPartnerIdKey, result.marketingPartnerId);
			setSessionStorageValue(userIdKey, result.userId);
			if (result.isRejected) {
				navigate(RejectionReasonRoute.path);
				return;
			}
			navigate(LightCompanyDataRoute.path);
		},
		undefined,
		undefined,
		(): void => {
			navigate(TechnicalErrorRoute.path, {
				state: { clientId: initialData?.processId },
			});
		},
		false
	);

	const calculateLoan = useFetch(
		calculateLoanInfoConfig,
		false,
		(data): void => {
			setInstallment(data.installment);
		},
		undefined,
		undefined,
		(): void => {
			navigate(TechnicalErrorRoute.path, {
				state: { clientId: initialData?.processId },
			});
		},
		false
	);

	useEffect((): void => {
		const initParams = [
			{ paramValue: personalDataFromLead.firstName, name: 'firstName' },
			{ paramValue: personalDataFromLead.phoneNumber, name: 'phoneNumber' },
			{ paramValue: personalDataFromLead.nip, name: 'nip' },
			{ paramValue: personalDataFromLead.lastName, name: 'lastName' },
		]
		
		initParams.forEach((i): void => {
			if(!i.paramValue){
				return;
			}
			methods.reset({
				...methods.watch(),
				[i.name]: i.paramValue
			})
		})
	}, [personalDataFromLead]);

	useEffect((): void => {
		// eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
		if (initialData?.processId) {
			getAgreements.mutate(initialData.processId);
		}
	}, [initialData?.processId]);

	useEffect((): void => {
		if (initialData?.selectedPeriod) {
			onCreditPeriodChange(initialData.selectedPeriod);
		}
	}, [initialData?.selectedPeriod]);

	const onCreditPeriodChange = (value: number): void => {
		if (!initialData?.periods.includes(value) || creditPeriod === value) {
			return;
		}
		setCreditPeriod(value);
		handleCreditPeriod(value);
		calculateLoan.mutate({
			value: initialData.maxCreditAmount,
			period: value,
			processId: initialData.processId,
		});
	};

	const handleCreditPeriod = (data: number): void => {
		setCreditPeriod(data);
	};

	const handleSubmit = (data: ILightContactDataProperties): void => {
		if (initialData) {
			createApplication.mutate({
				period: creditPeriod,
				magicWords: data.magicWords,
				agreements: convertMediaAgreementsToAgreementModelToSend(agreementState.agreements),
				process: initialData.process,
				processId: initialData.processId,
				nip: data.nip,
				email: data.email,
				firstName: data.firstName,
				lastName: data.lastName,
				phoneNumber: data.phoneNumber,
				query: initialData.query,
				brokerId: data.brokerId,
			});
		}
	};

	const handleClickShowMore = (): void => {
		getInformationClauseMutation.mutate({} as never);
	};

	return {
		installment,
		creditPeriod,
		onCreditPeriodChange: (value: number): void => onCreditPeriodChange(value),
		handleClickShowMore: (): void => handleClickShowMore(),
		handleSubmit: (data: ILightContactDataProperties): void => handleSubmit(data),
		agreementState,
		calculateLoanIsLoading: calculateLoan.isPending,
		createApplicationIsLoading: createApplication.isPending,
		methods: methods,
		initialData,
		getAgreementsIsLoading: getAgreements.isPending,
	};
};

export const [LightLandingPageContext, LightLandingPageContextProvider] = CreateComponentContext<LightLandingPageState>(
	defaultLightLandingPageContext,
	lightLandingPageContextHandler
);
