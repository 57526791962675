import { styled } from '@mui/system';
import { mainTheme } from '../../../../../theme/mainTheme';
import { textColorPrimary } from '../../../../../theme/palette/palette';

export const CreditGoodToKnowBackground = styled('div')({
	background: textColorPrimary,
	padding: '60px 0',
});

export const CreditGoodToKnowSection = styled('div')({
	border: '2px solid ' + mainTheme.palette.denary.main,
	borderRadius: '16px',
	padding: '35px 27px',
});

export const CreditGoodToKnowContainer = styled('div')({
	display: 'grid',
	gridTemplateColumns: 'repeat(1, 1fr)',
	gridTemplateRows: '1fr',
	gridRowGap: '24px',

	[mainTheme.breakpoints.up('md')]: {
		gridTemplateColumns: 'repeat(2, 1fr)',
		gridColumnGap: '24px',
		gridRowGap: 0,
	},
});

export const HexListItem = styled('div')({
	display: 'flex',
	margin: '12px 0',
	minHeight: '35px',
	alignItems: 'center',
});

export const HexListItemNumberContainer = styled('div')({
	display: 'flex',
	alignItems: 'center',
	width: 'fit-content',
	height: 'fit-content',
	marginRight: '30px',
	position: 'relative',
	justifyContent: 'center'
});

export const HexListItemNumber = styled('div')({
	position: 'absolute',
	fontWeight: 'bold',
});

export const HexListDescContainer = styled('div')({
	display: 'flex',
	alignItems: 'center',
	minHeight: '35px',
});

export const DocumentDownload = styled('span')({
	color: mainTheme.palette.denary.main,
	cursor: 'pointer',
});
