import { AcceptAllContainer, Container as AgreementsContainer, HeaderContainer, ScrollAndAccept } from './AgreementCollection.css';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../store/State';
import Agreement from './Agreement/Agreement';
import { AgreementModel, AgreementSectionModel } from './AgreementsModels';
import { AgreementContainer, AgreementHeadingContainer, Container, ExpandableContainer, Link, MarginTop20 } from './Agreement/Agreement.css';
import { Switch, Typography } from '@mui/material';
import { changeAgreementSection, handleAgreementSectionExpand, handleAllAgreementsChecked } from '../../store/slices/agreementsSlice/AgreementsSlice';
import arrowDown from '../../common/images/ArrowDown.svg';
import { RefObject, createRef, useMemo } from 'react';

interface AgreementCollectionProps {
	disableAcceptAll?: boolean;
	fullWidth?: boolean;
	customBackground?: boolean;
}

export default function AgreementCollection(props: Readonly<AgreementCollectionProps>): JSX.Element {
	const agreements = useSelector((state: RootState): AgreementSectionModel[] => state.agreements.agreements);
	const scrollRefs = useMemo(
		(): RefObject<HTMLDivElement>[] => {
			return Array.from({ length: agreements.length }).map((): RefObject<HTMLDivElement> => createRef<HTMLDivElement>())},
		[JSON.stringify(agreements.length)]
	);
	const allAgreementsChecked = useSelector((state: RootState): boolean => state.agreements.allAgreementsChecked);
	const dispatch = useDispatch();

	const handleCheckedChange = (event: React.ChangeEvent<HTMLInputElement>, sectionId: number): void => {
		dispatch(
			changeAgreementSection({
				sectionIndex: sectionId,
				isSelected: event.target.checked,
			})
		);
	};

	const handleAllCheckedChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
		dispatch(
			handleAllAgreementsChecked({
				isSelected: event.target.checked,
			})
		);
	};

	return (
		<Container sx={{ maxWidth: { xs: '100%', md: props.fullWidth ? '100%' : '70%' } }}>
			{!props.disableAcceptAll && (
				<AgreementContainer sx={{ marginBottom: '25px' }}>
					<div
						style={{
							display: 'flex',
							justifyContent: 'space-between',
							alignItems: 'top',
						}}
					>
						<Typography
							variant='p1'
							sx={{
								display: 'inline-block',
								maxWidth: '485px',
								marginBottom: '20px',
								textAlign: 'justify',
							}}
						>
							Akceptuje wszystkie zgody i oświadczenia
						</Typography>
						<Switch sx={{ marginLeft: { md: '40px', xs: '15px' } }} checked={allAgreementsChecked} onChange={handleAllCheckedChange} />
					</div>
				</AgreementContainer>
			)}
			{agreements.map(
				(x: AgreementSectionModel, sectionId: number): JSX.Element => (
					<AgreementsContainer sx={{ marginBottom: '25px' }} key={x.section} customBackground={props.customBackground}>
						{x.groupHeader && (
							<HeaderContainer>
								<Typography variant='h5' sx={{ fontSize: '22px' }}>
									{x.groupHeader}
								</Typography>
								<ScrollAndAccept
									onClick={(): void => scrollRefs && scrollRefs[sectionId].current?.scrollIntoView({ block: 'center', behavior: 'smooth' })}
								>
									Przewiń i zaakceptuj wszystkie
								</ScrollAndAccept>
							</HeaderContainer>
						)}
						{!x.hideExpand && (
							<div
								style={{
									display: 'flex',
									justifyContent: 'space-between',
									alignItems: 'top',
								}}
							>
								<div style={{ display: 'flex', flexDirection: 'column' }}>
									<Typography
										variant='p1'
										sx={{
											display: 'inline-block',
											maxWidth: '485px',
										}}
									>
										{x.section}
									</Typography>
									{!!x.hideExpand && (
										<Link
											onClick={(): void => {
												dispatch(
													handleAgreementSectionExpand({
														isExpanded: !x.isExpand,
														sectionIndex: sectionId,
														consentGroupId: undefined,
													})
												);
											}}
										>
											{x.isExpand ? 'Zwiń' : 'Rozwiń'}{' '}
											<img src={arrowDown} style={{ transform: x.isExpand ? 'rotate(180deg)' : '' }} alt='arrow down' />{' '}
										</Link>
									)}
								</div>

								<Switch
									sx={{ marginLeft: { md: '40px', xs: '15px' } }}
									checked={x.isSelected}
									onChange={(event: React.ChangeEvent<HTMLInputElement>): void => handleCheckedChange(event, sectionId)}
								/>
							</div>
						)}
						<ExpandableContainer className={x.isExpand ? 'active' : ''} sx={{ marginLeft: props.customBackground ? '12px' : '0px' }}>
							<MarginTop20 sx={{ paddingLeft: '24px' }}>
								{x.consentGroup.map(
									(i: AgreementModel, idGroup: number): JSX.Element => (
										<Agreement
											sectionIndex={sectionId}
											agreementIndex={idGroup}
											agreement={i}
											key={i.symbol}
											hideExpand={i.hideExpand}
											fullWidth={props.fullWidth}
										/>
									)
								)}
							</MarginTop20>
						</ExpandableContainer>
						{props.customBackground && (
							<AcceptAllContainer ref={scrollRefs[sectionId]}>
								<AgreementHeadingContainer>
									<Typography
										variant='p1'
										sx={{
											display: 'inline-block',
											fontWeight: '600',
											marginLeft: '12px',
										}}
									>
										{x.consentGroup.some((consent): boolean => consent.isRequired === true)
											? 'Akceptuje wszystkie powyższe zgody i oświadczenia niezbędne do złożenia wniosku'
											: 'Akceptuje wszystkie powyższe zgody i oświadczenia'}
									</Typography>
								</AgreementHeadingContainer>
								<Switch
									sx={{ marginLeft: { md: '40px', xs: '15px' } }}
									checked={!x.consentGroup.some((consent): boolean => consent.isSelected === false)}
									onChange={(event: React.ChangeEvent<HTMLInputElement>): void => handleCheckedChange(event, sectionId)}
								/>
							</AcceptAllContainer>
						)}
					</AgreementsContainer>
				)
			)}
		</Container>
	);
}
