import BaseFetch from './BaseFetch';
import { ApiConfig } from './FetchTypes';

const saveApplicationDraftConfigKey = 'saveApplicationDraftConfigKey';

function saveApplicationDraft(): Promise<never> {
    return BaseFetch({
        url: 'Application/SaveDraft',
        method: 'POST',
    });
}

export const SaveApplicationDraftConfig: ApiConfig<never, never> =
{
    key: saveApplicationDraftConfigKey,
    fn: saveApplicationDraft,
};