export const lightCollapseContent = `<div>
	<b>Książka przychodów i rozchodów</b>
	<ol style="padding-left: 0">
		<ul>
			<li>PIT-36/36L z załącznikami za ostatnie 2 lata wraz z nadrukowanym numerem UPO/pieczęcią Urzędu Skarbowego</li>
		</ul>
	</ol><br/>	
	<b>Ryczałt ewidencjonowany</b>
	<ol style="padding-left: 0">
		<ul>
			<li>PIT-28 za ostatnie 2 lata</li>
		</ul>
	</ol><br/>	
	<b>Karta podatkowa</b>
	<ol style="padding-left: 0">
		<ul>
			<li>Decyzja wydana przez Urząd Skarbowy dotycząca ustalenia stawki karty podatkowej na rok bieżący i ubiegły</li>
		</ul>
	</ol>	
</div>`;

export const socialSecurityCollapseContent = `
<div>
	<b>Zaświadczenie o niezaleganiu w ZUS lub potwierdzenie danych z ZUS</b><br/><br/>
	Dokument powinien zawierać:<br/>
	<ol style="padding-left: 0">
		<ul>
			<li>dane umożliwiające jednoznaczną identyfikację klienta</li>
			<li>dla zaświadczenia - pieczęć ZUS oraz podpis osoby upoważnionej</li>
			<li>dla potwierdzenia - informację o saldzie płatnika oraz kwalifikowaną pieczęć elektroniczną ZUS</li>
		</ul>
	</ol><br/>
	<b>Zaświadczenie o niezaleganiu w US</b><br/><br/>
	Dokument powinien zawierać:<br/>
	<ol style="padding-left: 0">
		<ul>
			<li>dane umożliwiające jednoznaczną identyfikację klienta</li>
			<li>datę wydania zaświadczenia</li>
			<li>uzupełnioną część I-V w zakresie informacji o braku zaległości, układów ratalnych, postępowań</li>
			<li>podpis osoby wystawiającej zaświadczenia i pieczęć US</li>
		</ul>
	</ol><br/><br/>
</div>`;

export const requiredDocumentsList: IRequiredDocumentItem[] = [
	{
		content: 'Polski dowód osobisty',
	},
	{
		content:
			'Dokumenty finansowe zależne od sposobu rozliczania się z Urzędem Skarbowym',
		collapse: true,
		collapseContent: lightCollapseContent,
		collapseTitle: 'Sprawdź',
	},
	{
		content: 'Zaświadczenie o niezaleganiu w ZUS i US',
		collapse: true,
		collapseContent: socialSecurityCollapseContent,
		collapseTitle: 'Sprawdź',
	},
];

interface IRequiredDocumentItem {
	content: string;
	collapse?: boolean;
	collapseContent?: string;
	collapseTitle?: string;
}
