import {  TypeOfApp } from '../../common/index/IndexTokenResolver';

export const CLLegalNote1 =
	'<div style="font-weight: normal; text-align: justify;"><p>Przedstawiona propozycja kredytu ma charakter wstępny i nie ma charakteru' +
	' oferty w' +
	' rozumieniu' +
	' art. 66 Kodeksu cywilnego. Wstępna propozycja  obejmuje następujące kredyty: BIZnest Kredyt w Ratach – Przedsiębiorca oraz BIZnest' +
	' Kredyt w Ratach - Profesjonalista tj. kredyty oferowane przez Nest Bank SA („Nest Bank” lub „Bank”) przedsiębiorcom' +
	' jednoosobowym, na cele związane z prowadzoną przez nich działalnością gospodarczą. Maksymalna kwota kredytu to kwota' +
	' uwzględniająca kredytowane opłaty i prowizje, o które zostanie pomniejszona udostępniana klientowi kwota kredytu.' +
	' Oprocentowanie kredytu jest zmienne. W ofercie istnieje możliwość udzielenia kredytu z zabezpieczeniem spłaty w' +
	' postaci gwarancji PLD de minimis. <br><b>Dane wskazane w kalkulacji</b> mają charakter szacunkowy. Podana w kalkulacji' +
	' maksymalna kwota kredytu to kwota uwzględniająca kredytowane opłaty i prowizje, o które zostanie pomniejszona' +
	' udostępniona Klientowi kwota kredytu. Oprocentowanie kredytu jest zmienne. Zasady ustalania i zmiany oprocentowania' +
	' kredytu określa umowa i regulamin. Przedstawiona w kalkulacji wysokość <b>szacunkowej raty miesięcznej</b> obliczana' +
	' jest na dzień jej publikacji na dedykowanej stronie internetowej i może się różnić od ostatecznej wysokości raty miesięcznej. Szacunkowa rata miesięczna przedstawia miesięczną ratę kapitałowo-odsetkową (i) wyliczoną od całkowitej kwoty kredytu (z uwzględnieniem kredytowanych kosztów), (ii) ma wartość zaokrągloną do dwóch miejsc po przecinku, (iii) została obliczona na podstawie parametrów wskazanych przez przedsiębiorcę na dedykowanej stronie internetowej przedstawiającej kalkulację. Udzielenie kredytu oraz jego wysokość uzależnione są od pozytywnej oceny zdolności kredytowej przedsiębiorcy.</p>' +
	'<p>Szczegóły oferty, w tym „Regulamin kredytowania działalności gospodarczej Nest Banku, linia mikro i małych przedsiębiorstw”, „Tabela Opłat i Prowizji dla Klienta biznesowego – oferta kredytowa" oraz „Tabela Oprocentowania dla Klienta biznesowego – oferta kredytowa” dostępne są na <a style="color: #BCA8FF" href="nestbank.pl">www.nestbank.pl</a>, w placówkach własnych i u partnerów finansowych banku.</p></div>';

export const CLLegalNote2 = '<div style="font-weight: normal; text-align: justify;">' +
	'<p>' +
    'Przedstawiona propozycja kredytu ma charakter wstępny i nie ma charakteru oferty w rozumieniu art. 66 Kodeksu cywilnego. ' +
	'Wstępna propozycja obejmuje następujące kredyty: BIZnest Kredyt w Ratach – Przedsiębiorca oraz BIZnest Kredyt w Ratach - Profesjonalista tj. kredyty oferowane ' +
	'przez Nest Bank SA („Nest Bank” lub „Bank”) przedsiębiorcom jednoosobowym, na cele związane z prowadzoną przez nich działalnością gospodarczą. ' +
	'Maksymalna kwota kredytu to kwota uwzględniająca kredytowane opłaty i prowizje, o które zostanie pomniejszona udostępniana klientowi kwota kredytu. ' +
	'Oprocentowanie kredytu jest zmienne. ' +
	'Zasady ustalania i zmiany oprocentowania kredytu określa umowa i regulamin. ' +
	'Udzielenie kredytu oraz jego wysokość uzależnione są od pozytywnej oceny zdolności kredytowej przedsiębiorcy.' +
	' W ofercie istnieje możliwość udzielenia kredytu z zabezpieczeniem spłaty w postaci gwarancji PLD de minimis.' +
	'</p>' +
    '<p>' +
	'Szczegóły oferty, w tym „Regulamin kredytowania działalności gospodarczej Nest Banku, linia mikro i małych przedsiębiorstw”, „Tabela Opłat i Prowizji dla Klienta biznesowego – oferta kredytowa" oraz „Tabela Oprocentowania dla Klienta biznesowego – oferta kredytowa” dostępne są na <a style="color: #BCA8FF" href="nestbank.pl">www.nestbank.pl</a>, w placówkach własnych i u partnerów finansowych banku.' +
	'</p></div>';

export const CLLegalNote4 = '<div style="font-weight: normal; text-align: justify;">' +
	'<p>' +
	'BIZnest Kredyt w Ratach – Profesjonalista jest kredytem oferowanym przez Nest Bank SA przedsiębiorcom ' +
	'wykonującym wolny zawód, na cele związane z prowadzoną przez nich działalnością gospodarczą. ' +
	'Maksymalna kwota kredytu to kwota uwzględniająca kredytowane opłaty i prowizje, o które zostanie ' +
	'pomniejszona udostępniana klientowi kwota kredytu. Udzielenie oraz wysokość kredytu uzależnione są między ' +
	'innymi od pozytywnej oceny zdolności kredytowej oraz długości stażu osoby wnioskującej. ' +
	'Oprocentowanie kredytu jest zmienne. W ofercie istnieje możliwość udzielenia kredytu z zabezpieczeniem ' +
	'spłaty w postaci gwarancji de minimis (PLD). O Kredyt wnioskować może osoba wykonująca wolny zawód ' +
	'spełniająca aktualne wymagania Banku w zakresie okresu prowadzenia działalności gospodarczej i – ' +
	'jeżeli ma zastosowanie – tytułu uzyskiwania dochodu w ramach wolnego zawodu w wskazanym przez Bank ' +
	'okresie poprzedzającym złożenie wniosku. W przypadku lekarza i lekarza stomatologa o kredyt może ' +
	'wnioskować osoba wykonująca zawód przez okres co najmniej 24 miesięcy poprzedzających dzień złożenia ' +
	'wniosku o udzielenie kredytu, przy czym w okresie minimum 12 miesięcy przed datą złożenia wniosku, ' +
	'niezbędne jest uzyskiwanie dochodu w ramach indywidualnej działalności gospodarczej.' +
	'</p>' +
	'<p>' +
		'Promocja „Gwarancja niższej marży” (dalej: „Promocja”) trwa od 11.09.2023 r. do 30.09.2024 r. ' +
	'i polega na zaproponowaniu przez Nest Bank S.A. (dalej: „Bank”) kredytu „BIZnest Kredyt w Ratach – ' +
	'Przedsiębiorca” lub „BIZnest Kredyt w Ratach – Profesjonalista” zabezpieczonego gwarancją de ' +
	'minimis PLD ze stawką marży niższą niż stawka marży wynikająca z przedstawionej przez klienta ' +
	'oferty udzielenia kredytu gotówkowego/gotówkowo – konsolidacyjnego z innego banku krajowego lub ' +
	'umowy kredytu gotówkowego/gotówkowo – konsolidacyjnego z innego banku krajowego, spełniającej warunki' +
	' określone w regulaminie Promocji (dalej: „Regulamin”). Uczestnikiem Promocji może być osoba fizyczna ' +
	'prowadząca jednoosobową działalność gospodarczą, która posiada siedzibę oraz rezydencję podatkową w ' +
	'Polsce i prowadzi księgowość w oparciu o książkę przychodów i rozchodów lub ryczałt ewidencjonowany ' +
	'lub kartę podatkową. Warunki Promocji, w tym przystąpienia do niej, reguluje Regulamin Promocji. ' +
	'Bank uzależnia decyzję o przyznaniu kredytu od oceny wiarygodności i zdolności kredytowej firmy. ' +
	'W pozostałym niż promocyjna wysokość marży kredytu zakresie Bank proponuje kredyt na warunkach zgodnych' +
	' z obowiązującą ofertą Banku. Niniejszy materiał nie stanowi oferty w rozumieniu art. 66 Kodeksu cywilnego.' +
	'</p>' +
	'<p>' +
	'Szczegóły oferty, w tym „Regulamin kredytowania działalności gospodarczej Nest Banku, ' +
	'linia mikro i małych przedsiębiorstw”, „Tabela Opłat i Prowizji dla Klienta biznesowego – ' +
	'oferta kredytowa" oraz „Tabela Oprocentowania dla Klienta biznesowego – oferta kredytowa” ' +
	'dostępne są na www.nestbank.pl, w placówkach własnych i u partnerów finansowych banku.' +
	'</p>' +
	'</div>';

export const CLLegalNote3 = '<div style="font-weight: normal; text-align: justify;">' +
	'<p>' +
	'Promocja „Gwarancja niższej marży” (dalej: „Promocja”) trwa od 11.09.2023 r. do 30.09.2024 r. i polega na zaproponowaniu przez Nest Bank S.A. (dalej: „Bank”) kredytu „BIZnest Kredyt w Ratach – Przedsiębiorca” lub „BIZnest Kredyt w Ratach – Profesjonalista” zabezpieczonego gwarancją de minimis PLD ze stawką marży niższą niż stawka marży wynikająca z przedstawionej przez klienta oferty udzielenia kredytu gotówkowego/gotówkowo – konsolidacyjnego z innego banku krajowego, spełniającej warunki określone w regulaminie Promocji (dalej: „Regulamin”). Uczestnikiem Promocji może być osoba fizyczna prowadząca jednoosobową działalność gospodarczą, która posiada siedzibę oraz rezydencję podatkową w Polsce i prowadzi księgowość w oparciu o książkę przychodów i rozchodów lub ryczałt ewidencjonowany lub kartę podatkową. Warunki Promocji, w tym przystąpienia do niej, reguluje Regulamin Promocji. Bank uzależnia decyzję o przyznaniu kredytu od oceny wiarygodności i zdolności kredytowej firmy. W pozostałym niż promocyjna wysokość marży kredytu zakresie Bank proponuje kredyt na warunkach zgodnych z obowiązującą ofertą Banku. Niniejszy materiał nie stanowi oferty w rozumieniu art. 66 Kodeksu cywilnego.' +
	'</p>' +
	'<p>' +
	'Przedstawiona propozycja kredytu ma charakter wstępny i nie ma charakteru oferty w rozumieniu art. 66 Kodeksu cywilnego. ' +
	'Wstępna propozycja obejmuje następujące kredyty: ' +
	'BIZnest Kredyt w Ratach – Przedsiębiorca oraz BIZnest Kredyt w Ratach - Profesjonalista tj. kredyty oferowane przez Nest Bank SA („Nest Bank” lub „Bank”) przedsiębiorcom jednoosobowym, na cele związane z prowadzoną przez nich działalnością gospodarczą. ' +
	'Maksymalna kwota kredytu to kwota uwzględniająca kredytowane opłaty i prowizje, o które zostanie pomniejszona udostępniana klientowi kwota kredytu.' +
	' Zasady ustalania i zmiany oprocentowania kredytu określa umowa i regulamin. Udzielenie kredytu oraz jego wysokość uzależnione są' +
	' od pozytywnej oceny zdolności kredytowej przedsiębiorcy.' +
	' Oprocentowanie kredytu jest zmienne. W ofercie istnieje możliwość udzielenia kredytu z zabezpieczeniem spłaty w postaci gwarancji PLD de minimis.' +
	'</p>' +
	'<p>' +
	'Szczegóły oferty, w tym „Regulamin kredytowania działalności gospodarczej Nest Banku, linia mikro i małych przedsiębiorstw”, „Tabela Opłat i Prowizji dla Klienta biznesowego – oferta kredytowa" oraz „Tabela Oprocentowania dla Klienta biznesowego – oferta kredytowa” dostępne są na <a style="color: #BCA8FF" href="nestbank.pl">www.nestbank.pl</a>, w placówkach własnych i u partnerów finansowych banku.' +
	'</p></div>';

export const CLLegalNoteWithoutCalculator = `
	<div style="font-weight: normal; text-align: justify;">
		<p>
			Przedstawiona propozycja kredytu ma charakter wstępny i nie ma charakteru oferty w rozumieniu art. 66 Kodeksu cywilnego. 
			Wstępna propozycja obejmuje następujące kredyty: BIZnest Kredyt w Ratach – Przedsiębiorca oraz BIZnest Kredyt w Ratach - 
			Profesjonalista tj. kredyty oferowane przez Nest Bank SA („Nest Bank” lub „Bank”) przedsiębiorcom jednoosobowym, na cele związane z prowadzoną przez nich działalnością gospodarczą. 
			Maksymalna kwota kredytu to kwota uwzględniająca kredytowane opłaty i prowizje, o które zostanie pomniejszona udostępniana klientowi kwota kredytu. Oprocentowanie kredytu jest zmienne. 
			Zasady ustalania i zmiany oprocentowania kredytu określa umowa i regulamin. Udzielenie kredytu oraz jego wysokość uzależnione są od pozytywnej oceny zdolności kredytowej przedsiębiorcy.
			W ofercie istnieje możliwość udzielenia kredytu z zabezpieczeniem spłaty w postaci gwarancji PLD de minimis.
		</p>
		<p>
			Szczegóły oferty, w tym „Regulamin kredytowania działalności gospodarczej Nest Banku, linia mikro i małych przedsiębiorstw”, „Tabela Opłat i Prowizji dla Klienta biznesowego – oferta kredytowa”
			oraz „Tabela Oprocentowania dla Klienta biznesowego – oferta kredytowa” dostępne są na <a style="color: #BCA8FF" href="nestbank.pl">www.nestbank.pl</a>, w placówkach własnych i u partnerów finansowych banku.
		</p>
	</div>
`

export const collapseContentLandingPageOVD = `<div style='font-size: 12px; text-align: justify;'>
    <p>
        Przedstawiona oferta limitu ma charakter wstępny i nie ma charakteru oferty w rozumieniu art. 66 Kodeksu cywilnego. 
        O BIZnest Limit w Koncie oferowany przez Nest Bank S.A. („Bank”), wnioskować może:
    </p>

    <p>
        - przedsiębiorca, będący osobą fizyczną prowadzącą indywidualną działalność gospodarczą (który prowadzi swoją działalność 
        przez minimum 24 miesiące poprzedzające dzień złożenia wniosku o udzielenie limitu) lub
    </p>

    <p>
        - profesjonalista, będący osobą wykonującą wolny zawód taki jak: lekarz, stomatolog, lekarz weterynarii, adwokat, radca 
        prawny, notariusz lub fizjoterapeuta (aby móc starać się o BIZNest Limit w Koncie, profesjonalista powinien wykonywać wolny 
        zawód przez okres co najmniej 24 miesięcy poprzedzający dzień złożenia wniosku o udzielenie Limitu, przy czym w okresie 
        minimum 12 miesięcy przed datą złożenia wniosku, niezbędnym jest uzyskiwanie dochodu w ramach indywidualnej działalności 
        gospodarczej).
    </p>

    <p>
        Udzielenie limitu oraz jego wysokość uzależnione są od pozytywnej oceny zdolności kredytowej przedsiębiorcy. Maksymalna 
        kwota limitu to 50 tys. PLN, która to kwota uwzględnia kredytowane opłaty i prowizje, o które zostaną pomniejszone środki 
        udostępnione klientowi w ramach rachunku bieżącego (firmowego) w wykonaniu umowy o BIZnest Limit w Koncie. Do udzielenia 
        limitu wymagane jest posiadanie przez klienta rachunku firmowego w Nest Bank S.A.
    </p>

    <p>
        Szczegóły oferty, w tym „Regulamin kredytowania działalności gospodarczej Nest Banku, linia mikro i małych przedsiębiorstw”, 
        „Tabela Opłat i Prowizji dla Klienta biznesowego – oferta kredytowa” oraz „Tabela Oprocentowania dla Klienta biznesowego 
        – oferta kredytowa” dostępne są na <a href="https://www.nestbank.pl" target="_blank">www.nestbank.pl</a>.
    </p>
    
</div>`;

export const LightClCollapseContent = `<div style='font-size: 12px; text-align: justify;'>
    <p>
        Przedstawiona propozycja kredytu ma charakter wstępny i nie stanowi oferty w rozumieniu art. 66 Kodeksu cywilnego. <b>„BIZnest Kredyt w Ratach – Przedsiębiorca” oraz „BIZnest Kredyt w Ratach – Profesjonalista”</b> 
        to kredyty oferowane przez Nest Bank S.A. („Nest Bank” lub „Bank”) przedsiębiorcom jednoosobowym, na cele związane z prowadzoną przez nich działalnością gospodarczą. 
        Maksymalna kwota kredytu to kwota uwzględniająca kredytowane opłaty i prowizje, o które zostanie pomniejszona udostępniana klientowi kwota kredytu. Oprocentowanie kredytu jest zmienne, 
        zasady ustalania i zmiany oprocentowania kredytu określa umowa i regulamin.
    </p>

    <p>
        Przedstawiona w kalkulacji wysokość <b>szacunkowej raty miesięcznej</b> obliczana jest na dzień jej publikacji na dedykowanej stronie internetowej i może się różnić od ostatecznej wysokości raty miesięcznej. 
        Szacunkowa rata miesięczna przedstawia miesięczną ratę kapitałowo-odsetkową (i) wyliczoną od całkowitej kwoty kredytu <b>tj. 50 000 złotych</b> (z uwzględnieniem kredytowanych kosztów), 
        (ii) ma wartość zaokrągloną do dwóch miejsc po przecinku, (iii) została obliczona na podstawie parametrów wskazanych przez przedsiębiorcę na dedykowanej stronie internetowej przedstawiającej kalkulację. 
        Udzielenie kredytu oraz jego wysokość uzależnione są od pozytywnej oceny zdolności kredytowej przedsiębiorcy.
    </p>

    <p>
        W przypadku, gdy po złożeniu wniosku przedsiębiorca otrzyma pozytywną decyzję kredytową, do udzielenia kredytu konieczne jest przedłożenie dokumentacji wymaganej przez Bank i przeprowadzenie pozytywnej weryfikacji tej dokumentacji przez Bank. 
        Umowa o kredyt podpisywana jest w postaci elektronicznej w sposób określony w tej umowie.
    </p>

    <p>
        Możliwość korzystania ze zmiany daty płatności raty, zawieszenia spłaty rat kapitałowych na 3 miesiące oraz wydłużenia czasu kredytowania do 6 miesięcy jest dostępna 
        – przy zawieraniu kredytu elektronicznie poprzez dedykowaną stronę internetową - bez dodatkowych kosztów.
    </p>

    <p>
        Szczegóły oferty, w tym „Regulamin kredytowania działalności gospodarczej Nest Banku, linia mikro i małych przedsiębiorstw”, „Tabela Opłat i Prowizji dla Klienta biznesowego – oferta kredytowa” 
        oraz „Tabela Oprocentowania dla Klienta biznesowego – oferta kredytowa” dostępne są na <a href="https://www.nestbank.pl" target="_blank">www.nestbank.pl</a>, w placówkach własnych i u partnerów finansowych banku.
    </p>
</div>`

export const LeadVariantsOfNote = [
	{note: CLLegalNote1, type: TypeOfApp.LEAD_CL, token: window.CONFIG.LEAD_CL_TOKEN},
	{note: CLLegalNote3, type: TypeOfApp.LEAD_CL, token: window.CONFIG.LEAD_CL_TOKEN_1},
	{note: CLLegalNote2, type: TypeOfApp.LEAD_CL, token: window.CONFIG.LEAD_CL_TOKEN_2},
	{note: CLLegalNote3, type: TypeOfApp.LEAD_CL, token: window.CONFIG.LEAD_CL_TOKEN_3},
	{note: CLLegalNote4, type: TypeOfApp.LEAD_CL, token: window.CONFIG.LEAD_CL_TOKEN_4},
	{note: CLLegalNote4, type: TypeOfApp.LEAD_CL, token: window.CONFIG.LEAD_CL_TOKEN_5},
	{note: CLLegalNoteWithoutCalculator, type: TypeOfApp.LEAD_OVD, token: window.CONFIG.LEAD_OVD_TOKEN_1},
	{note: collapseContentLandingPageOVD, type: TypeOfApp.LEAD_OVD, token: window.CONFIG.LOV_TOKEN}, //lightOvd
	{note: LightClCollapseContent, type: TypeOfApp.LIGHT_OVD, token: window.CONFIG.LCL_TOKEN}, //lightCl
]
