import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ILeadToLightClDataProperties, ILeadToLightClDataState, initialState } from './LeadToLightClDataSliceConstants';

export const leadToLightClDataSlice = createSlice({
	name: 'leadToLightClDataSlice',
	initialState,
	reducers: {
		saveWrittenValues: (
			state: ILeadToLightClDataState,
			action: PayloadAction<ILeadToLightClDataProperties>
		): void => {
			state.personalData = action.payload;
		},
	},
});

export const { saveWrittenValues } = leadToLightClDataSlice.actions;

export default leadToLightClDataSlice.reducer;
