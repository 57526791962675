import { Stack } from '@mui/material';
import { useEffect, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { interpolatePath } from 'src/routing/RoutingService';
import useFetch from '../../../common/api/UseFetch';
import { ApplicationProcess } from '../../../common/constants/application-process';
import { getPartnerLogo } from '../../../common/helpers/get-partner-logo-helper';
import {
    applicationIdKey,
    applicationProcessKey,
    authTokenKey,
    guidKey, locationKey,
    partnerLogoKey,
    partnerNameKey,
    processIdKey,
    removeSessionStorageValue, setSessionStorageObj,
    setSessionStorageValue,
    tokenExpirationKey,
} from '../../../common/sessionStorage/SessionStorageService';
import {
    LeadTechnicalErrorRoute, NotFoundRoute,
    TechnicalErrorRoute,
} from '../../../routing/Routing';
import { resolveIndexTokenByType, TypeOfApp } from '../../common/index/IndexTokenResolver';
import { TechnicalErrorState } from '../../common/technical-error/TechnicalErrorTypes';
import LeadCL from '../../lead/lead-cl/LeadCL';
import { GetInitialDataConfig, LeadProductType } from '../../lead/lead-new-application/LeadNewApplicationApi';
import LeadOVD from '../../lead/lead-ovd/LeadOVD';
import LightLandingPage from '../../light/cl/cl-landing-page/LightLandingPage';
import { GetLightInitialDataConfig } from '../../light/cl/cl-new-application/api/LightNewApplicationApi';
import LightOvdLandingPage from '../../light/ovd/ovd-landing-page/LightOvdLandingPage';
import LightIndexLandingPage from '../cl-index-light-landing-page/LightIndexLandingPage';
import LeadIndexForm from '../LeadForm/LeadIndexForm';
import { LeadIndexStart } from '../LeadStart/LeadIndexStart';

const tokenAndType: {token: string; type: TypeOfApp} | undefined = resolveIndexTokenByType();

const waitingLayout = (): JSX.Element | undefined => {
    if(tokenAndType?.type === TypeOfApp.LEAD_CL){
        return <Stack sx={{ width: '100%', flexDirection: { xs: 'column', md: 'row' } }}><LeadIndexStart /> <LeadIndexForm /></Stack>;
    }
    if(tokenAndType?.type === TypeOfApp.LEAD_OVD){
        return <Stack sx={{ width: '100%', flexDirection: { xs: 'column', md: 'row' } }}><LeadIndexStart /> <LeadIndexForm /></Stack>;
    }
    if(tokenAndType?.type === TypeOfApp.LIGHT_CL){
        return <Stack sx={{ width: '100%', flexDirection: 'column' }}><LightIndexLandingPage/></Stack>;
    }
    if(tokenAndType?.type === TypeOfApp.LIGHT_OVD){
        return <Stack sx={{ width: '100%', flexDirection: 'column' }}><LightIndexLandingPage/></Stack>;
    }
}

export default function LeadNewApplication(): JSX.Element {
  const [component, setComponent] = useState(waitingLayout());
  const [token, _setToken] = useState<{token: string; type: TypeOfApp} | undefined >(tokenAndType);
  const [searchParams, _] = useSearchParams();
  
  const navigateToTechnicalError = (): void => {
    const state: TechnicalErrorState = {
      tryAgainPath: window.location.pathname,
    };
    navigate(TechnicalErrorRoute.path, { state });
  };
  
  const initialDataMutationLead = useFetch(
        GetInitialDataConfig,
        false,
        (result): void => {
            setSessionStorageValue(applicationIdKey, '');
            setSessionStorageValue(authTokenKey, '');
            setSessionStorageValue(
                applicationProcessKey,
                result.process?.toString() ?? ApplicationProcess.Unknown.toString()
            );
            setSessionStorageValue(guidKey, token?.token ?? '');
            if (result.leadProduct === LeadProductType.CashLoan) {
                setComponent(<LeadCL initialData={result} />);
            } else if (result.leadProduct === LeadProductType.Overdraft) {
                setComponent(<LeadOVD initialData={result} />);
            } else {
                navigate(interpolatePath(LeadTechnicalErrorRoute.path, { guid: token?.token }));
            }
        },
        undefined,
        (): void => {
            navigate(interpolatePath(LeadTechnicalErrorRoute.path, { guid: token?.token }));
        },
        (): void => {
            navigate(interpolatePath(LeadTechnicalErrorRoute.path, { guid: token?.token }));
        },
        false
    );
  
  const initialDataMutationLight = useFetch(
      GetLightInitialDataConfig,
      false,
      (result): void => {
          setSessionStorageValue(applicationIdKey, '');
          setSessionStorageValue(authTokenKey, '');
          if (result.partnerLogoName) {
              setSessionStorageValue(
                  partnerLogoKey,
                  getPartnerLogo(result.partnerLogoName)
              );
          }
          setSessionStorageObj(locationKey, {  
              state: {
                  ...result,
                  processId: token?.token ?? '',
                  query: searchParams.toString(),
              }
          })
          setSessionStorageValue(partnerNameKey, result.partnerName);
          setSessionStorageValue(processIdKey, token?.token ?? '');
          setSessionStorageValue(applicationProcessKey, result.process.toString());
          switch (result.process) {
              case ApplicationProcess.LightCl:
                  setComponent(<LightLandingPage/>)
                  break;
              case ApplicationProcess.LightOvd:
                  setComponent(<LightOvdLandingPage/>)
                  break;
              default:
                  navigate(TechnicalErrorRoute.path)
          }
      },
      undefined,
      undefined,
      navigateToTechnicalError,
      false
  );

  const navigate = useNavigate();

    useEffect((): void => {
      if (typeof token === 'undefined' && !TypeOfApp.LIGHT_OVD) {
        navigate(NotFoundRoute.path);
      } else if (typeof token === 'undefined' && TypeOfApp.LIGHT_OVD) {
        _setToken({type: TypeOfApp.LIGHT_OVD, token: window.CONFIG.LOV_TOKEN[0]})
        initialDataMutationLight.mutate(window.CONFIG.LOV_TOKEN[0])
      }
    }, []);

  useEffect((): void => {
    if (token != undefined) {
      setSessionStorageValue(guidKey, token?.token);
      removeSessionStorageValue(tokenExpirationKey);
      if(token?.type === TypeOfApp.LEAD_CL || token?.type === TypeOfApp.LEAD_OVD){
          initialDataMutationLead.mutate(token?.token)
      }
      if(token?.type === TypeOfApp.LIGHT_CL || token?.type === TypeOfApp.LIGHT_OVD) {
          initialDataMutationLight.mutate(token?.token)
      }
    }
  }, [token]);

  return (
    <>
      {component}
    </>
  );
}
