import { styled } from '@mui/system';
import { buttonsBreakpoint } from '../../theme/breakpoints/breakpoints';
import { mainTheme } from '../../theme/mainTheme';

export const Container = styled('div')({
	display: 'flex',
	minHeight: 'inherit',
	flexDirection: 'column',
	width: '833px',
	margin: 'auto',
	[mainTheme.breakpoints.down('md')]: {
		width: 'unset',
		margin: '0 24px',
	},
});

export const WiderContainer = styled('div')({
	display: 'flex',
	minHeight: 'inherit',
	flexDirection: 'column',
	width: '1200px',
	margin: 'auto',

	[mainTheme.breakpoints.down('lg')]: {
		width: '833px',
	},
	[mainTheme.breakpoints.down('md')]: {
		width: 'unset',
		margin: '0 24px',
	},
});

export const ButtonContainer = styled('div')({
	width: '100%',
	marginTop: '48px',
	marginBottom: '80px',
	display: 'flex',
	justifyContent: 'space-between',
	[mainTheme.breakpoints.down(buttonsBreakpoint as number)]: {
		flexDirection: 'column',
		alignItems: 'center',
		height: '128px',
	},
});
