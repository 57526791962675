import BaseFetch from '../../../../../common/api/BaseFetch';
import { ApiConfig, ApiVersion } from '../../../../../common/api/FetchTypes';
import Agreement from '../../../../../models/Agreement';
import { BankAccountNumber } from '../models/OvdSmsContractSigningModels';

const postSendSms = 'postSendSmsDataKey';
const getVerifySms = 'postVerifySmsCode';
const getAgreementKey = 'getAgreement';
const getBankAccountNumberKey = 'getBankAccountNumber';

function sendSms(agreements: Agreement[]): Promise<never> {
	return BaseFetch({
		url: 'SigningContract/sendSmsCode',
		method: 'POST',
		body: { agreements },
	});
}

function getVerifySmsCode(smsCode: string): Promise<string> {
	return BaseFetch({
		url: 'SigningContract/verifySmsCode/' + smsCode,
		method: 'GET',
	});
}

function getAgreement(): Promise<Agreement[]> {
	const page = 'SmsContractSigning';
	return BaseFetch({
		url: 'Agreement/ForApplication/' + page,
		method: 'GET',
	});
}

function getBankAccountNumber(): Promise<BankAccountNumber>{
	return BaseFetch({
		url:'SigningContract/BankAccountNumber',
		method: 'GET',
		version: ApiVersion.LightOvd
	});
}

export const PostSendSms: ApiConfig<never, Agreement[]> = {
	key: postSendSms,
	fn: sendSms,
};

export const GetVerifySmsCode: ApiConfig<string, string> = {
	key: getVerifySms,
	fn: getVerifySmsCode,
};

export const GetAgreement: ApiConfig<Agreement[], never> = {
	key: getAgreementKey,
	fn: getAgreement,
};

export const GetBankAccountNumber: ApiConfig<BankAccountNumber, never> = {
	key: getBankAccountNumberKey,
	fn: getBankAccountNumber,
};
