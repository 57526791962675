import {
	applicationIdKey,
	authTokenKey,
	getSessionStorageValue,
} from '../sessionStorage/SessionStorageService';
import { allClientFormCodes } from './ErrorCodes';
import FetchError from './FetchError';
import {
	addAuthHeaders,
	AppIdTemplate,
	applyHeaders,
	defaultApiHeaders,
	defaultMode,
	getQueryParams,
	handleFile,
	handleFileDownload,
} from './FetchHelper';
import { ApiVersion, ClientFormErrorModel, DefaultErrorModel, QueryParamsType } from './FetchTypes';

export interface BaseFetchProps {
	url: string;
	method: 'GET' | 'POST' | 'PUT' | 'PATCH' | 'DELETE';
	queryParams?: QueryParamsType;
	body?: unknown;
	customHeaders?: HeadersInit;
	version?: ApiVersion;
	downloadFile?: boolean;
	apiRoot?: string;
}

const processableStatuses = [400, 404, 415, 422];
const fileContentTypes = ['application/pdf'];

export default function BaseFetch<TResult>(
	props: BaseFetchProps
): Promise<TResult> {
	let headers = applyHeaders(
		props.body instanceof FormData ? {} : defaultApiHeaders,
		props.customHeaders
	);
	const applicationId = getSessionStorageValue(applicationIdKey);
	headers = addAuthHeaders(
		headers,
		applicationId ?? '',
		getSessionStorageValue(authTokenKey) ?? ''
	);

	let url =
		(props?.apiRoot ?? window.CONFIG.API_ROOT) +
		(props.version ?? ApiVersion.Default) +
		props.url +
		getQueryParams(props.queryParams);

	if (applicationId) {
		url = url.replace(AppIdTemplate, applicationId);
	}

	return fetch(url, {
		method: props.method,
		mode: defaultMode,
		headers: headers,
		body: props.body
			? props.body instanceof FormData
				? props.body
				: JSON.stringify(props.body)
			: undefined,
	})
		.then(async (response): Promise<string> => {
			if (!response.ok) {
				if (processableStatuses.includes(response.status)) {
					let error = {} as DefaultErrorModel;
					try {
						error = (await response.json()) as DefaultErrorModel;
					} catch {
						error = {};
					}
					if (error.ErrorCode) {
						throw new FetchError(error.ErrorCode, {
							status: response.status,
							params: error.Params,
						});
					}
          if(allClientFormCodes.includes((error as ClientFormErrorModel).extensions.ErrorCode)) {
						//TODO: Refactor error handling for client-forms
            //Still waiting for refactor :p
						throw new FetchError((error as ClientFormErrorModel).extensions.ErrorCode);
					}
					if (response.status === 401 || response.status === 403) {
						throw new FetchError(undefined, { status: response.status });
					}
				}
				throw new Error(`An error occured: ${response.status}`);
			} else {
				if (
					fileContentTypes.includes(response.headers.get('content-type') ?? '')
				) {
					if (props.downloadFile) {
						await handleFileDownload(response);
					} else {
						await handleFile(response);
					}
					return Promise.resolve('');
				}
				return response.text();
			}
		})
		.then(async (text): Promise<TResult> => {
			if (!text) {
				return Promise.resolve({} as TResult);
			}
			return Promise.resolve(JSON.parse(text) as TResult);
		})
		.catch((err): Promise<TResult> => {
			localStorage.setItem('test4', (err as Error).message);
			throw err;
		});
}
