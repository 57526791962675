import React, { useEffect, useRef, useState } from 'react';
import { TextFieldProps } from '@mui/material/TextField/TextField';
import { Typography } from '@mui/material';
import { UseFormReturn } from 'react-hook-form';
import { IClientWealth } from '../../PersonalDataModels';
import { TypeOfProperty } from '../ClientWealthForm/ClientWealthFormConstants';
import {
	MultiSelect,
	MultiSelectContainer,
	MultiSelectItem,
	MultiSelectList,
	Triangle,
	TriangleAndElsContainer,
	DropdownTextContainer,
} from './ClientWealthMultiSelect.css';
import selectIcon from '../../../../../common/images/selectIcon.svg';

type NestSelectProps = Omit<TextFieldProps, 'name'> & {
	options: IClientWealth[];
	value: IClientWealth[];
	methods: UseFormReturn<Record<string, unknown>>;
	handleChange: (type: TypeOfProperty) => void;
	label: string;
};
const ClientWealthMultiSelect = (props: NestSelectProps): JSX.Element => {
	const [open, setOpen] = useState(false);
	const multiSelectRef = useRef<HTMLDivElement>(null);

	useEffect((): (() => void) => {
		document.addEventListener('click', handleClickOutside, false);
		return (): void => {
			document.removeEventListener('click', handleClickOutside, false);
		};
	}, []);

	const handleClickOutside = (event: Event): void => {
		if (
			multiSelectRef.current &&
			!multiSelectRef.current.contains(event.target as Node)
		) {
			setOpen(false);
		}
	};

	return (
		<MultiSelectContainer>
			<Typography
				variant='h9'
				sx={{ color: 'text.secondary', marginBottom: '8px' }}
			>
				{props.label}
			</Typography>
			<MultiSelect
				onClick={(): void => setOpen(!open)}
				ref={multiSelectRef}
				error={false}
				id='multi-select'
				sx={{ whiteSpace: 'break-spaces' }}
			>
				<TriangleAndElsContainer>
					<DropdownTextContainer>
						{props.value.map(
							(item: IClientWealth, index: number): JSX.Element => (
								<Typography variant='p1' component={'div'} key={item.type}>
									{(index === 0 && props.value.length === 1) ||
									index === props.options.length - 1 ||
									index === props.value.length - 1
										? `${String(item.placeholder)}`
										: String(item.placeholder) + ', '}
								</Typography>
							)
						)}
					</DropdownTextContainer>
					<Triangle src={selectIcon} open={open} />
				</TriangleAndElsContainer>
			</MultiSelect>
			<MultiSelectList show={open}>
				{props.options.map(
					(item: IClientWealth): JSX.Element => (
						<MultiSelectItem
							onClick={(): void => props.handleChange(item.type)}
							key={item.type}
							active={item.active}
						>
							<Typography variant='p1'>{item.placeholder}</Typography>
						</MultiSelectItem>
					)
				)}
			</MultiSelectList>
		</MultiSelectContainer>
	);
};
export default ClientWealthMultiSelect;
