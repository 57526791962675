import { useEffect } from 'react';
import { ScrollToTopOnMount } from '../../../common/helpers/scroll-to-top-onmount';
import OnePageRejection from '../../../components/OnePageRejection/OnePageRejection';
import { pushLeadEventVirtualpvOnError } from '../../../common/googleAnalyticsEvents/googleAnalyticsEvents';
import { useLocation } from 'react-router-dom';
import { IGoogleAnalyticsData } from '../../../common/models/googleAnalyticsDataModels';

const LeadEndRejection = (): JSX.Element => {
	const location = useLocation();
	const locationState = location.state as IGoogleAnalyticsData;

	useEffect((): void => {
		pushLeadEventVirtualpvOnError(locationState);
	}, []);
	return (
		<>
			<ScrollToTopOnMount/>
			<OnePageRejection
				descriptionText='Nie odnaleźliśmy Twoich danych w bazie CEiDG. Brak możliwości zamówienia kontaktu.'
				descriptionMaxWidth={557}
				buttonText='Zakończ'
				onClickHandler={(): void => {
					window.location.href = 'https://nestbank.pl';
				}}
			/>
		</>
		
	);
};

export default LeadEndRejection;
