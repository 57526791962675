/* eslint-disable @typescript-eslint/no-unsafe-member-access */
import styled from '@mui/system/styled';
import { septenaryMainColor } from '../../../theme/palette/palette';
import { FieldProps, getFieldStyle } from '../FormItems.css';
import { CSSProperties } from '@mui/styles';
import { mainTheme } from 'src/theme/mainTheme';
import { SxProps } from '@mui/material/styles';

export const Input = styled(
	'input',
	{}
)<FieldProps>(
	(props: FieldProps): CSSProperties => ({
		...getFieldStyle(props),
	})
);

export const Prefix = styled('div')<{ error: boolean }>(
	(props: { error: boolean }): CSSProperties => ({
		height: '56px',
		borderTopLeftRadius: '4px',
		borderBottomLeftRadius: '4px',
		padding: '0 0 0 16px',
		border: props.error
			? `1px solid ${mainTheme.palette.error.main}`
			: `1px solid ${mainTheme.palette.senary.main}`,
		'&:focus-visible': {
			outline: 'unset',
		},
		borderRight: 'none',
		fontSize: '16px',
		lineHeight: '22.4px',
		fontFamily: 'Inter, sans-serif',
		display: 'flex',
		alignItems: 'center',
		background: 'white',
	})
);

export const InputContainer = styled('div')({
	display: 'flex',
	flexDirection: 'row',
});

export const InputWithPrefixOverride: SxProps = {
	borderLeft: 'none',
	borderTopLeftRadius: 0,
	borderBottomLeftRadius: 0,
	paddingLeft: 0,
	width: 'calc(100% - 16px)',
};

export const CurrencyTextOrSomethingElseContainer = styled('div')({
	position: 'absolute',
	bottom: '32px',
	right: '32px',
	fontWeight: 'bold',
	color: septenaryMainColor,
	transform: 'translate(50%, 50%)',
});
