import { styled } from '@mui/system';

export const CollapseContainer = styled('div')({
	background: '#2D333F',
	border: '1px solid #656572',
	borderRadius: '8px',
	color: '#FFFFFF',
	padding: '24px',
	overflowY: 'auto',
	marginBottom: '16px',
	position: 'relative',
});

export const CheckIcon = styled('img')({
	margin: '6px 12px 12px 0',
	width:'16px',
	height:'16px',
});

export const CheckLogoItem = styled('div')({
	display: 'flex',
	alignItems: 'start',
	color: '#FFFFFF',
});

export const CloseIcon = styled('img')({
	position: 'absolute',
	right: '16px',
	top: '16px',
	cursor: 'pointer',
});
