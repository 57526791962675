import {
	getRegenerateApplicationSessionConfig,
	getVerificationResultConfig,
	RegenerateApplicationSessionResult,
} from '../../../components/IdentityVerificationInProgress/api/IdentityVerificationInProgressApi';
import OnePageRejection from '../../../components/OnePageRejection/OnePageRejection';
import { useLocation, useNavigate } from 'react-router-dom';
import { useEffect, useState } from 'react';
import useFetch from '../../../common/api/UseFetch';
import {
	applicationIdKey,
	authTokenKey,
	setSessionStorageValue,
} from '../../../common/sessionStorage/SessionStorageService';
import {
	SmsContractSigningRoute,
	VerificationFailedRoute,
} from '../../../routing/Routing';
import Loader from '../../../components/Loader/Loader';
import { getLocationState } from './helpers/VerificationFailedHelpers';

export interface CustomizedState {
	message: string;
	buttonText: string;
	path: string;
	messageMaxWidth: number;
}

export default function Verification(): JSX.Element {
	const [verificationData, setVerificationData] =
		useState<CustomizedState | null>(null);

	const navigate = useNavigate();
	const location = useLocation();

	const orderUuid = new URLSearchParams(location.search).get('orderUuid') ?? '';
	const state = location.state as boolean | null;

	const regenerateApplicationSessionResult = useFetch(
		getRegenerateApplicationSessionConfig,
		false,
		(resp): void => {
			setSessionStorageData(resp);
			verificationResult.mutate({} as never);
		}
	);

	const verificationResult = useFetch(
		getVerificationResultConfig,
		false,
		({ isPositivelyVerified, areAttemptsLeft }): void => {
			isPositivelyVerified
				? navigate(SmsContractSigningRoute.path)
				: navigate(VerificationFailedRoute.path, {
						state: areAttemptsLeft,
				  });
		}
	);

	useEffect((): void => {
		if (orderUuid) {
			regenerateApplicationSessionResult.mutate({
				orderUuid: orderUuid,
				page: VerificationFailedRoute.page,
			});
		} else if (state) {
			setVerificationData(getLocationState(state));
		}
	}, [orderUuid, state]);

	const setSessionStorageData = (
		data: RegenerateApplicationSessionResult
	): void => {
		setSessionStorageValue(`${applicationIdKey}`, `${data.applicationId}`);
		setSessionStorageValue(`${authTokenKey}`, `${data.token}`);
	};

	return (
		<>
			{verificationData ? (
				<OnePageRejection
					descriptionText={verificationData.message}
					onClickHandler={(): void => {
						verificationData.path.split('/')[0] === 'https:'
							? (window.location.href = verificationData.path)
							: verificationData.path && navigate(verificationData.path);
					}}
					buttonText={verificationData.buttonText}
					descriptionMaxWidth={verificationData.messageMaxWidth}
				/>
			) : (
				<Loader />
			)}
		</>
	);
}
