import { styled } from '@mui/system';
import { mainTheme } from '../../../theme/mainTheme';
import { buttonsBreakpoint } from '../../../theme/breakpoints/breakpoints';

export const InputContainer = styled('div')({
	maxWidth: '350px',
});

export const ButtonContainer = styled('div')({
	width: '100%',
	display: 'flex',
	justifyContent: 'flex-end',
	marginTop: '50px',
	marginBottom: '50px',

	[mainTheme.breakpoints.down(buttonsBreakpoint as number)]: {
		justifyContent: 'center',
	},
});
