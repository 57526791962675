import { styled } from '@mui/system';
import { mainTheme } from '../../../theme/mainTheme';

export const Container = styled('div')``;

export const AgreementTextContainer = styled('div')`
	display: flex;
	flex-direction: row;
	align-items: top;
	justify-content: space-between;
`;

export const CenteredAgreementTextContainer = styled('div')`
	display: flex;
	flex-direction: row;
	align-items: start;
	justify-content: space-between;
`;

export const AgreementHeadingContainer = styled('div')`
	display: flex;
	flex-direction: column;
	justify-content: center;
`;

export const AgreementContainer = styled('div')`
	a {
		font-weight: 400;
    	line-height: 19px;
    	text-decoration: none;
		color: ${mainTheme.palette.secondary.main};
	}
`;

export const Link = styled('a')`
	font-style: normal;
	font-weight: 700;
	font-size: 14px;
	line-height: 120%;
	text-decoration: none;
	margin-top: 16px;
	color: ${mainTheme.palette.secondary.main};
	cursor: pointer;
`;

export const ExpandableContainer = styled('div')`
	max-height: 0;
	overflow: hidden;
	transition: all 0.3s ease-in-out;

	&.active {
		max-height: 7000px;
	}
`;

export const MarginTop20 = styled('div')`
	margin-top: 20px;
`;
