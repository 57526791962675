import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Container } from '../../../../common/styles/Layout.css';
import CustomButton from '../../../../components/CustomButton/CustomButton';
import AddressDescription from '../../../../components/DataConsistency/AddressDescription';
import ContactDataInfo from '../../../../components/DataConsistency/ContactDataInfo';
import Loader from '../../../../components/Loader/Loader';
import PageRenderer from '../../../../components/PageRenderer/PageRenderer';
import { LightOvdVerificationRoute, LightVerificationRoute, WebPage } from '../../../../routing/Routing';
import { gridFullWidth } from '../../../../theme/grid/GridConstants';
import { navigateToRoute } from '../../../common/helpers/navigateToRoute';
import LightInfoAboutCompany from './LightInfoAboutCompany';
import LightInfoAboutYou from './components/LightInfoAboutYou';
import useFetch from '../../../../common/api/UseFetch';
import { ScrollToTopOnMount } from '../../../../common/helpers/scroll-to-top-onmount';
import { ButtonContainer } from 'src/pages/rbf/rbf-data-consistency/DataConsistency.css';
import { useMediaQuery } from '@mui/material';
import { mainTheme } from 'src/theme/mainTheme';
import { GetClientDataConfig, SubmitDataConfig } from './LightDataConsistencyApi';
import { pushLightEventVirtualpvDataConsistency } from 'src/common/googleAnalyticsEvents/googleAnalyticsEvents';

export default function LightDataConsistency(): JSX.Element {
	const [renderPage, setRenderPage] = useState<WebPage | undefined>(undefined);
	const navigate = useNavigate();
	const result = useFetch(GetClientDataConfig, true);
	const submitApplication = useFetch(SubmitDataConfig, false, (): void => {
		navigate(navigateToRoute(LightVerificationRoute.path, LightOvdVerificationRoute.path) as string);
	});
	const isMobileMediaQuery = useMediaQuery(mainTheme.breakpoints.down('sm'));

	useEffect((): void => {
		pushLightEventVirtualpvDataConsistency();
	}, []);

	return (
		<>
			{renderPage && !result.isPending && (
				<PageRenderer
					page={renderPage}
					close={(): void => {
						setRenderPage(undefined);
						result.mutate({} as never);
					}}
				/>
			)}
			{result.data && !renderPage && (
				<Container>
					<ScrollToTopOnMount />
					<Typography variant={isMobileMediaQuery ? 'h3' : 'h2'} sx={{ width: '100%', marginBottom: '24px' }}>
						Upewnij się, czy poniższe dane są prawidłowe
					</Typography>
					<Grid container rowSpacing={4}>
						<LightInfoAboutYou
							title='Informacje o Tobie'
							clientData={result.data.clientData}
							clientWealth={result.data.clientWealth}
							editPage={WebPage.LightClPersonalData}
							setRenderPage={setRenderPage}
						/>

						<AddressDescription
							title='Adres zamieszkania'
							address={result.data.clientAddress}
							editPage={WebPage.LightClPersonalData}
							setRenderPage={setRenderPage}
						/>

						<AddressDescription
							title='Adres korespondencyjny'
							address={result.data.clientCorrespondenceAddress}
							editPage={WebPage.LightClPersonalData}
							setRenderPage={setRenderPage}
						/>

						<LightInfoAboutCompany
							title='Informacje o Twojej firmie'
							companyData={result.data.companyData}
							editPage={WebPage.LightClCompanyDataVerified}
							setRenderPage={setRenderPage}
						/>

						<AddressDescription
							title='Adres firmy'
							address={result.data.companyAddress}
							editPage={WebPage.LightClCompanyDataVerified}
							setRenderPage={setRenderPage}
						/>

						<AddressDescription
							title='Adres korespondencyjny firmy'
							address={result.data.companyCorrespondenceAddress}
							editPage={WebPage.LightClCompanyDataVerified}
							setRenderPage={setRenderPage}
						/>

						<ContactDataInfo
							title='Dane kontaktowe'
							contactData={result.data.contactData}
							editPage={WebPage.LightClCompanyDataVerified}
							setRenderPage={setRenderPage}
						/>

						<Grid item xs={gridFullWidth}>
							<ButtonContainer
								sx={{
									height: 'auto !important',
									marginTop: { xs: '16px', md: '48px' },
								}}
							>
								<CustomButton isLoading={submitApplication.isPending} onClick={(): void => submitApplication.mutate({} as never)} variant='contained'>
									Składam wniosek
								</CustomButton>
							</ButtonContainer>
						</Grid>
					</Grid>
				</Container>
			)}
			{result.isPending && (
				<Container>
					<Loader />
				</Container>
			)}
		</>
	);
}
