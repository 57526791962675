import styled from '@mui/system/styled';

export const LogoHeader = styled('img')({
	width: '63px',
	height: '25px',
});

export const LogoFooter = styled('img')({
	
});
