import { useState } from 'react';
import Typography from '@mui/material/Typography';
import Stack from '@mui/system/Stack';
import useFetch from '../../../../common/api/UseFetch';
import { OverrideProps } from '../../../../routing/RouteSpec';
import { useNavigate } from 'react-router-dom';
import { LightEndRoute, LightOvdEndRoute } from '../../../../routing/Routing';
import CustomButton from '../../../../components/CustomButton/CustomButton';
import { grayMainColor, primaryMainColor } from '../../../../theme/palette/palette';
import { ScrollToTopOnMount } from '../../../../common/helpers/scroll-to-top-onmount';
import { IDocuments } from '../../../../models/AttachDocumentsModels';
import LightAttachDocumentsDescMoreTime from '../../components/light-company-data/components/LightAttachDocumentsDescMoreTime';
import LightAttachDocumentsItem from './LightAttachDocumentsItem';
import { ApplicationFileTypeNumber, ApplicationFileTypeString } from '../../enums/ApplicationFileType';
import { TypeOfTax } from 'src/enums/TaxationForm';
import {
	AddFileToBlobConfig,
	DeleteDocumentConfig,
	GetDocumentsConfig,
	UploadInfoDocumentsConfig,
	VerifyDocumentsAndSendEmailToAnalystsConfig,
} from './LightAttachDocumentsApi';

const getSubTitleAccDeclarationType = (declarationType: TypeOfTax | undefined): string => {
	switch (declarationType) {
		case TypeOfTax['PIT-36']:
			return 'PIT-36/36L za ostatnie 2 lata';
		case TypeOfTax['PIT-36L']:
			return 'PIT-36/36L za ostatnie 2 lata';
		case TypeOfTax['PIT-28']:
			return 'PIT-28 za ostatnie 2 lata';
		case TypeOfTax['PIT-16A']:
			return 'Decyzja wydana przez Urząd Skarbowy dotycząca ustalenia stawki karty podatkowej na rok bieżący i ubiegły';
		default:
			return '';
	}
};

export default function LightAttachDocuments(props?: OverrideProps): JSX.Element {
	const [filesPit, setFilesPit] = useState<IDocuments[]>([]);
	const [filesZus, setFilesZus] = useState<IDocuments[]>([]);
	const [filesUs, setFilesUs] = useState<IDocuments[]>([]);
	const [isUploading, setIsUploading] = useState<ApplicationFileTypeString | undefined>();

	const navigate = useNavigate();

	const allAddedDocuments = useFetch(GetDocumentsConfig, true, (data: IDocuments[]): void => {
		setAllAddedDocuments(data);
	});

	const addFileToBlob = useFetch(
		AddFileToBlobConfig,
		false,
		(): void => {
			allAddedDocuments.mutate({} as never);
			setIsUploading(undefined);
		},
		true,
		(): void => {
			allAddedDocuments.mutate({} as never);
			setIsUploading(undefined);
		}
	);
	const deleteDocument = useFetch(DeleteDocumentConfig, false, (): void => {
		allAddedDocuments.mutate({} as never);
	});

	const uploadInfoDocuments = useFetch(UploadInfoDocumentsConfig, true);

	const verifyDocumentsAndSendEmailToAnalysts = useFetch(VerifyDocumentsAndSendEmailToAnalystsConfig, false, (data): void => {
		if (!props?.ommitRouting) {
			sessionStorage.setItem('bankAccountNumber', data.bankAccountNumber);
			window.location.href.includes('OVD') ? navigate(LightOvdEndRoute.path) : navigate(LightEndRoute.path);
		}
		props?.close && props.close();
	});

	const setAllAddedDocuments = (data: IDocuments[] | undefined): void => {
		if (Array.isArray(data)) {
			const convertedToIDocumentsFilesWithSection = data.map((file: IDocuments): IDocuments => {
				return { ...file, isEnter: false } as IDocuments;
			});
			setFilesPit(convertedToIDocumentsFilesWithSection.filter((file): boolean => file.type === ApplicationFileTypeNumber.PitAttachment));
			setFilesZus(convertedToIDocumentsFilesWithSection.filter((file): boolean => file.type === ApplicationFileTypeNumber.ZusAttachment));
			setFilesUs(convertedToIDocumentsFilesWithSection.filter((file): boolean => file.type === ApplicationFileTypeNumber.UsAttachment));
		}
	};

	const addFiles = (files: FormData, fileType: ApplicationFileTypeString): void => {
		setIsUploading(fileType);
		addFileToBlob.mutate({ files, fileType });
	};

	const deleteDocumentById = (id: string): void => {
		deleteDocument.mutate(id);
	};

	return (
		<Stack
			sx={{
				display: 'flex',
				minHeight: 'inherit',
				flexDirection: 'column',
				width: { xs: 'unset', md: '833px' },
				margin: { xs: '0 16px', md: 'auto' },
			}}
		>
			<ScrollToTopOnMount />
			<Typography sx={{ color: '#000000', fontSize: { md: '34px', xs: '28px' }, fontWeight: 'bold' }}>
				Gratulacje! Umowa została przez Ciebie podpisana
			</Typography>
			<Typography variant='p1' sx={{ color: grayMainColor, padding: '8px 0 32px 0' }}>
				Pozostał tylko 1 krok. Załącz dokumenty zgodnie z poniższą listą.
			</Typography>

			<Stack
				sx={{
					margin: '0 0 32px 0',
					padding: { xs: '0 0 32px 0', md: '32px' },
					border: `${primaryMainColor} solid`,
					borderRadius: { xs: '0', md: '8px' },
					borderWidth: { xs: '0 0 1px 0', md: '1px' },
				}}
			>
				<LightAttachDocumentsItem
					title='Lista dokumentów finansowych'
					subTitle={getSubTitleAccDeclarationType(uploadInfoDocuments.data?.declarationType)}
					docsTitle={
						uploadInfoDocuments.data?.declarationType !== TypeOfTax['PIT-16A']
							? 'aby dokumenty zawierały:'
							: `<span style="font-weight: 500;">aby dokument zawierał</span> podpis oraz wbitą pieczęć Urzędu Skarbowego.`
					}
					docsDescriptions={
						uploadInfoDocuments.data?.declarationType !== TypeOfTax['PIT-16A']
							? [
									'wszystkie załączniki',
									'posiadały nadrukowany numer UPO lub wbitą pieczęć Urzędu Skarbowego',
									'w przypadku wysłania PITu pocztą, pamietaj o załączeniu potwierdzenia nadania',
							  ]
							: []
					}
					showInstruction={false}
					files={filesPit}
					setFiles={setFilesPit}
					addFiles={(formData): void => addFiles(formData, ApplicationFileTypeString.PitAttachment)}
					deleteDocumentById={deleteDocumentById}
					isUploading={isUploading === ApplicationFileTypeString.PitAttachment}
				/>
			</Stack>

			<Stack
				sx={{
					padding: { xs: '0', md: '8px' },
					borderRadius: '8px',
					border: { xs: 'none', md: `1px ${primaryMainColor} solid` },
				}}
			>
				<Stack sx={{ padding: { xs: '0 0 16px 0', md: '24px' } }}>
					<Typography variant='h5' sx={{ padding: '0 0 4px 0' }}>
						Lista dokumentów potwierdzających brak zaległości w ZUS i US
					</Typography>

					<Typography variant='p1'>
						Ważność dokumentów: 30 dni od daty złożenia wniosku kredytowego, chyba, że z jego treści wynika inaczej.
					</Typography>
				</Stack>

				<Stack
					sx={{
						margin: { xs: '0 -16px', md: '0' },
						padding: { xs: '16px', md: '24px' },
						background: '#F8F9FF',
						borderRadius: { xs: '0', md: '8px' },
					}}
				>
					<LightAttachDocumentsItem
						title='Dokumenty ZUS'
						subTitle='Zaświadczenie o niezaleganiu w ZUS lub potwierdzenie danych z ZUS'
						docsTitle='aby dokumenty zawierały:'
						docsDescriptions={[
							'dane umożliwiające jednoznaczną identyfikację klienta',
							'dla zaświadczenia - pieczęć ZUS oraz podpis osoby upoważnionej',
							'dla potwierdzenia - informację o saldzie płatnika oraz kwalifikowaną pieczęć elektroniczną ZUS',
						]}
						showInstruction={true}
						files={filesZus}
						setFiles={setFilesZus}
						addFiles={(formData): void => addFiles(formData, ApplicationFileTypeString.ZusAttachment)}
						deleteDocumentById={deleteDocumentById}
						isUploading={isUploading === ApplicationFileTypeString.ZusAttachment}
					/>
				</Stack>

				<Stack
					sx={{
						margin: { xs: '16px -16px 0 -16px', md: '16px 0 0 0' },
						padding: { xs: '16px', md: '24px' },
						background: '#F8F9FF',
						borderRadius: { xs: '0', md: '8px' },
					}}
				>
					<LightAttachDocumentsItem
						title='Dokumenty US'
						subTitle='Zaświadczenie o niezaleganiu w US'
						docsTitle='aby dokumenty zawierały:'
						docsDescriptions={[
							'dane umożliwiające jednoznaczną identyfikację klienta',
							'datę wydania zaświadczenia',
							'uzupełnioną część I-V w zakresie informacji o braku zaległości, układów ratalnych, postępowań',
							'podpis osoby wystawiającej zaświadczenia i pieczęć US',
						]}
						showInstruction={false}
						files={filesUs}
						setFiles={setFilesUs}
						addFiles={(formData): void => addFiles(formData, ApplicationFileTypeString.UsAttachment)}
						deleteDocumentById={deleteDocumentById}
						isUploading={isUploading === ApplicationFileTypeString.UsAttachment}
					/>
				</Stack>
			</Stack>

			<LightAttachDocumentsDescMoreTime />

			<Stack
				sx={{
					justifyContent: { xs: 'center', md: 'flex-end' },
					width: '100%',
					marginTop: { md: '32px', xs: '48px' },
					marginBottom: { md: '80px', xs: '40px' },
					flexDirection: 'row',
				}}
			>
				<CustomButton
					isLoading={verifyDocumentsAndSendEmailToAnalysts.isPending}
					disabled={!filesPit.length || !filesZus.length || !filesUs.length}
					onClick={(): void => verifyDocumentsAndSendEmailToAnalysts.mutate({} as never)}
					variant='contained'
				>
					Wyślij
				</CustomButton>
			</Stack>
		</Stack>
	);
}
