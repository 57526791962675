import { IGoogleAnalyticsData } from '../models/googleAnalyticsDataModels';
import { applicationIdKey, marketingPartnerIdKey, userIdKey } from '../sessionStorage/SessionStorageService';

export const pushLeadEventGenerateNew = (locationState: IGoogleAnalyticsData): void => {
	window.dataLayer.push({
		event: 'generate_lead',
		domainName: window.location.host,
		pathName: window.location.pathname,
		leadType: 'short',
		leadId: locationState.applicationId,
		userType: 'B2B',
	});
};

export const pushLeadEventVirtualpvOnSuccess = (locationState: IGoogleAnalyticsData): void => {
	window.dataLayer.push({
		event: 'vm_virtualpv',
		step: 'lead-wyslany',
		url: window.location.href,
		userid: locationState.userId,
		partner_ID: locationState.partnerId,
	});
};

export const pushLeadEventVirtualpvOnError = (locationState: IGoogleAnalyticsData): void => {
	window.dataLayer.push({
		event: 'vm_virtualpv',
		userType: 'B2B',
		step: 'lead-odrzucony',
		pathName: window.location.pathname,
		domainName: window.location.host,
		url: window.location.href,
		leadId: locationState.applicationId,
		userid: locationState.userId,
		partner_ID: locationState.partnerId,
	});
};

const lightObjPush = (desc: string): void => {
	const lightTypeName = window.location.pathname.includes('/CL/') ? 'LCLA-' : window.location.pathname.includes('/OVD/') ? 'LOVD-' : '';
	window.dataLayer.push({
		event: 'vm_virtualpv',
		step: `${lightTypeName}${desc}`,
		url: window.location.href,
		pathName: window.location.pathname,
		domainName: window.location.host,
		userType: 'B2B',
		leadType: 'long',
		leadId: sessionStorage.getItem(applicationIdKey),
		partner_ID: sessionStorage.getItem(marketingPartnerIdKey),
		userid: sessionStorage.getItem(userIdKey),
	});
};

export const pushLightEventVirtualpvCompanyData = (): void => lightObjPush('dane-firmy');

export const pushLightEventVirtualpvCompanyDataVerified = (): void => lightObjPush('dane-firmy-zweryfikowane');

export const pushLightEventVirtualpvPersonalData = (): void => lightObjPush('dane-osobowe');

export const pushLightEventVirtualpvDataConsistency = (): void => lightObjPush('zgodnosc-danych');

export const pushLightEventVirtualpvVerificationFailed = (): void => lightObjPush('weryfikacja-tozsamosci-nieudana');

export const pushLightEventVirtualpvVerification = (): void => lightObjPush('weryfikacja-pozytywna');

export const pushEventVirtualpvTechnicalError = (): void => lightObjPush('blad-techniczny');

export const pushEventVirtualpvRejectionReason = (): void => lightObjPush('odmowa');
