import { TextFieldProps, Typography } from '@mui/material';
import {
	Control,
	Controller,
	FieldValues,
	UseControllerReturn,
	UseFormSetValue,
} from 'react-hook-form';
import { InputProps } from '@ryuuji3/react-mask-hook';
import { FieldWrapper } from '../FormItems.css';
import React, { useState } from 'react';
import { DatePickerField } from './NestDatePicker.css';
import 'react-datepicker/dist/react-datepicker.css';
import { pl } from 'date-fns/locale';
import {
	ReactDatePickerCustomHeaderProps,
	registerLocale,
} from 'react-datepicker';
import NestDatePickerHeader from './components/NestDatePickerHeader/NestDatePickerHeader';
import { daysOfWeekShort } from './NestDatePickerConstants';

registerLocale('pl', pl);

type NestDatePickerProps = Omit<TextFieldProps, 'name'> & {
	control: Control | undefined;
	setValue: UseFormSetValue<Record<string, unknown>>;
	name: string;
	rules?: Omit<string | boolean, string>;
	disabled?: boolean;
	maskProps?: InputProps;
	type?: 'text' | 'number' | 'select';
	minDate?: Date;
	maxDate?: Date;
	errors?: { type: string; message: string };
	zIndex?: number;
};

const NestDatePicker = (props: NestDatePickerProps): JSX.Element => {
	const [selectedMonth, setSelectedMonth] = useState<number | null>(null);
	const [selectedYear, setSelectedYear] = useState<number | null>(null);

	const shortWeekOfDays = (day: string): string | undefined => {
		const singleDayToReplace = daysOfWeekShort.find(
			(x: { fullDayName: string; shortDayName: string }): boolean =>
				x.fullDayName === day
		);
		if (!singleDayToReplace) {
			return undefined;
		}
		return singleDayToReplace.shortDayName;
	};

	const clearValueWhenExceedLimitDate = (value: string): void => {
		if (!props.minDate || !props.maxDate) {
			return;
		}
		const chosenDate = new Date(value).getTime();
		if (
			props.minDate.getTime() > chosenDate ||
			props.maxDate.getTime() < chosenDate
		) {
			props.setValue(props.name, null);
		}
	};

	return (
		<FieldWrapper>
			<Typography
				variant='h9'
				sx={{ color: 'text.secondary', marginBottom: '8px' }}
			>
				{props.label}
			</Typography>

			<Controller
				control={props.control}
				name={props.name}
				rules={props.rules}
				render={({
					field,
				}: UseControllerReturn<FieldValues, string>): JSX.Element => (
					<div style={{zIndex: props.zIndex ?? 2}}>
						<DatePickerField
							error={!!props.errors}
							{...field}
							minDate={props.minDate}
							maxDate={props.maxDate}
							placeholderText='DD-MM-RRRR'
							dateFormat='dd-MM-yyyy'
							selected={field.value as Date}
							disabled={props.disabled}
							onKeyDown={(event: React.KeyboardEvent): void =>
								clearValueWhenExceedLimitDate(
									(event.target as unknown as Record<string, string>).value
								)
							}
							locale='pl'
							formatWeekDay={(nameOfDay: string): string | undefined =>
								shortWeekOfDays(nameOfDay)
							}
							ref={(): void => {
								field.ref({
									focus:(): void => {},
								});
							}}
							renderCustomHeader={({
								date,
								changeYear,
								decreaseMonth,
								increaseMonth,
							}: ReactDatePickerCustomHeaderProps): JSX.Element => (
								<NestDatePickerHeader
									date={date}
									changeYear={changeYear}
									decreaseMonth={decreaseMonth}
									increaseMonth={increaseMonth}
									selectedMonth={selectedMonth}
									setSelectedMonth={setSelectedMonth}
									selectedYear={selectedYear}
									setSelectedYear={setSelectedYear}
								/>
							)}
						/>
					</div>
				)}
			/>
			{props.errors && (
				<Typography
					variant='h9'
					sx={{
						color: 'error.main',
						position: 'absolute',
						marginTop: '84px',
					}}
				>
					{props.errors.message}
				</Typography>
			)}
		</FieldWrapper>
	);
};

export default NestDatePicker;
