import { Control, UseFormReturn } from 'react-hook-form';
import { useEffect } from 'react';
import { upperCaseMask } from '../../../../../common/helpers/mask-helper';
import NestInput from '../../../../../components/FormItems/NestInput/NestInput';
import NestSelect from '../../../../../components/FormItems/NestSelect/NestSelect';
import Grid from '@mui/material/Grid';
import {
	gridFullWidth,
	gridHalfWidth,
} from '../../../../../theme/grid/GridConstants';
import NestDatePicker from '../../../../../components/FormItems/NestDatePicker/NestDatePicker';
import { peselValidator } from '../../../../../common/validators/peselValidator';
import { isIdCardNumberValidator } from '../../../../../common/validators/idCardNumberValidator';
import { nameValidator } from '../../../../../common/validators/polishWordsValidator';
import { firstAndLastNameValidator } from '../../../../../common/validators/firstAndLastNameValidator';
import { CountryDto } from '../../../../../common/api/DictionaryApi';
import { sortAndMapCountries } from '../../../../../common/helpers/sort-helper';
import {
	idCardIssuedDateValidator,
	tenYearsAgoMinusOneDay,
} from '../../../../../common/validators/idCardIssuedDateValidator';

interface InfoAboutYouFormProps {
	methods: UseFormReturn<Record<string, unknown>>;
	countries: CountryDto[];
}

const InfoAboutYouForm = (props: InfoAboutYouFormProps): JSX.Element => {
	const watchIdCardIssuedDate = props.methods.watch('idCardIssuedDate') as
		| Date
		| undefined;

	const uniqueDateRangeAugust = new Date(2021, 7, 3).getTime();
	const uniqueDateRangeNovember = new Date(2021, 10, 7).getTime();

	const setExpirationDateBasedOnIssuedDate = (): void => {
		props.methods.setValue('idCardExpirationDate', null);
		if (!watchIdCardIssuedDate) {
			return;
		}
		if (
			watchIdCardIssuedDate.getTime() <= uniqueDateRangeNovember &&
			watchIdCardIssuedDate.getTime() >= uniqueDateRangeAugust
		) {
			props.methods.setValue('idCardExpirationDate', new Date(2031, 7, 3));
			return;
		}
		const exactYearDifferenceInIssuedAndExpirationDates = 10;
		const issuedDate = new Date(watchIdCardIssuedDate);
		const maxExpirationDateBasedOnIssuedDate = new Date(
			issuedDate.getFullYear() + exactYearDifferenceInIssuedAndExpirationDates,
			issuedDate.getMonth(),
			issuedDate.getDate()
		);
		if (maxExpirationDateBasedOnIssuedDate.getTime() < new Date().getTime()) {
			return;
		}
		props.methods.setValue(
			'idCardExpirationDate',
			maxExpirationDateBasedOnIssuedDate
		);
	};

	useEffect((): void => {
		setExpirationDateBasedOnIssuedDate();
	}, [watchIdCardIssuedDate]);

	return (
		<Grid container rowSpacing={4} columnSpacing={9}>
			<Grid item xs={gridFullWidth} md={gridHalfWidth}>
				<NestInput
					label='Imię'
					control={props.methods.control as unknown as Control}
					name='firstName'
					rules={{
						minLength: { value: 2, message: 'Niepoprawna wartość' },
						required: 'Pole wymagane',
						pattern: {
							value: nameValidator,
							message: 'Niepoprawna wartość',
						},
					}}
				/>
			</Grid>
			<Grid item xs={gridFullWidth} md={gridHalfWidth}>
				<NestInput
					label='Nazwisko'
					control={props.methods.control as unknown as Control}
					name='lastName'
					rules={{
						minLength: { value: 2, message: 'Niepoprawna wartość' },
						required: 'Pole wymagane',
						pattern: {
							value: nameValidator,
							message: 'Niepoprawna wartość',
						},
					}}
				/>
			</Grid>
			<Grid item xs={gridFullWidth} md={gridHalfWidth}>
				<NestInput
					label='PESEL'
					control={props.methods.control as unknown as Control}
					name='socialSecurityNumber'
					rules={peselValidator}
				/>
			</Grid>
			<Grid item xs={gridFullWidth} md={gridHalfWidth}>
				<NestInput
					label='Seria i nr dowodu osobistego'
					control={props.methods.control as unknown as Control}
					name='idCard'
					rules={{
						validate: isIdCardNumberValidator,
						required: 'Pole wymagane',
					}}
					mask=""
					maskFunc={upperCaseMask}
					useMaskAsValue={true}
				/>
			</Grid>
			<Grid item xs={gridFullWidth} md={gridHalfWidth}>
				<NestDatePicker
					name='idCardIssuedDate'
					label='Data wydania dowodu osobistego'
					control={props.methods.control}
					maxDate={new Date()}
					minDate={tenYearsAgoMinusOneDay()}
					setValue={props.methods.setValue}
					rules={{
						required: 'Pole wymagane',
						validate: idCardIssuedDateValidator,
					}}
					errors={
						props.methods.formState.errors.idCardIssuedDate as
							| { type: string; message: string }
							| undefined
					}
				/>
			</Grid>
			<Grid item xs={gridFullWidth} md={gridHalfWidth}>
				<NestDatePicker
					name='idCardExpirationDate'
					label='Data ważności dowodu osobistego'
					control={props.methods.control}
					setValue={props.methods.setValue}
					disabled
				/>
			</Grid>
			<Grid item xs={gridFullWidth} md={gridHalfWidth}>
				<NestSelect
					control={props.methods.control as unknown as Control}
					name='countryOfBirth'
					label='Kraj urodzenia'
					options={sortAndMapCountries(props.countries)}
					placeholderOption={['Wybierz kraj urodzenia', '']}
					defaultValue='Polska'
					setValue={props.methods.setValue}
					errors={
						props.methods.formState.errors.countryOfBirth as
							| { type: string; message: string }
							| undefined
					}
					rules={{
						required: 'Pole wymagane',
					}}
				/>
			</Grid>
			<Grid item xs={gridFullWidth} md={gridHalfWidth}>
				<NestInput
					label='Nazwisko panieńskie matki'
					control={props.methods.control as unknown as Control}
					name='mothersMaidenName'
					rules={{
						minLength: { value: 2, message: 'Niepoprawna wartość' },
						required: 'Pole wymagane',
						pattern: {
							value: firstAndLastNameValidator,
							message: 'Niepoprawna wartość',
						},
					}}
				/>
			</Grid>
		</Grid>
	);
};

export default InfoAboutYouForm;
