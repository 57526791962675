import { styled } from '@mui/system';
import { mainTheme } from '../../theme/mainTheme';
import { SxProps } from '@mui/system/styleFunctionSx';

export const CreditDescriptionBackground = styled('div')({
	width: '100%,',
	background: mainTheme.palette.primary.main,
	padding: '60px 0',
});

export const CreditDescriptionItem: SxProps = {
	display: 'flex',
	flexDirection: 'column',
	marginBottom: '60px',
	'&:last-child': {
		marginBottom: 0,
	},
	[mainTheme.breakpoints.up('sm')]: {
		marginBottom: 0,
	},
};

export const CreditDescriptionItemsContainer: SxProps = {
	justifyContent: 'space-evenly',
	[mainTheme.breakpoints.up('md')]: {
		justifyContent: 'space-between',
	},
};

export const CreditDescriptionItemImg = styled('img')({
	marginBottom: '36px',
	width: '96px',
	height: '96px',
});
