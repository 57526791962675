import Grid from '@mui/material/Grid';
import SectionHeader from '../../../../components/DataConsistency/SectionHeader';
import { WebPage } from '../../../../routing/Routing';
import ValueDescriptor from '../../../../components/ValueDescriptor/ValueDescriptor';
import {
	gridFullWidth,
	gridHalfWidth,
} from '../../../../theme/grid/GridConstants';
import { TaxationFormDescription, TypeOfTax } from '../../../../enums/TaxationForm';
import { staticMask } from '../../../../common/helpers/mask-helper';
import { formatAmount } from '../../../../common/helpers/number-helper';
import { HorizontalLine } from '../../../rbf/rbf-data-consistency/DataConsistency.css';
import { CompanyDataLight } from "../../../../models/CompanyData";

export interface LightInfoAboutCompanyProps {
	title: string;
	companyData: CompanyDataLight;
	editPage: WebPage;
	setRenderPage: React.Dispatch<React.SetStateAction<WebPage | undefined>>;
}

export default function LightInfoAboutCompany(
	props: LightInfoAboutCompanyProps
): JSX.Element {
	return (
		<>
			<Grid
				item
				xs={gridFullWidth}
				sx={{ marginTop: '16px', marginBottom: '-8px' }}
			>
				<SectionHeader
					title={props.title}
					page={props.editPage}
					setRenderPage={props.setRenderPage}
				/>
				<HorizontalLine />
			</Grid>
			<Grid item xs={gridFullWidth} md={gridHalfWidth}>
				<ValueDescriptor
					title='Forma opodatkowania'
					value={TaxationFormDescription(props.companyData.taxationForm)}
				/>
			</Grid>
			<Grid item xs={gridFullWidth} md={gridHalfWidth}>
				<ValueDescriptor
					title='Rodzaj deklaracji'
					value={TypeOfTax[props.companyData.declarationType]}
				/>
			</Grid>
			<Grid item xs={gridFullWidth} md={gridHalfWidth}>
				<ValueDescriptor
					title='Liczba pracowników wraz z właścicielem'
					value={props.companyData.numberOfEmployees}
				/>
			</Grid>
			<Grid item xs={gridFullWidth} md={gridHalfWidth}>
				<ValueDescriptor
					title='NIP'
					value={
						staticMask('', props.companyData.nip, '### ### ## ##', null)
							.maskedValue
					}
				/>
			</Grid>
			<Grid item xs={gridFullWidth} md={gridHalfWidth}>
				<ValueDescriptor
					title='Średniomiesięczny przychód firmy'
					value={`${formatAmount(props.companyData.companyAverageMonthlyIncome)} PLN`}
				/>
			</Grid>
			<Grid item xs={gridFullWidth} md={gridHalfWidth}>
				<ValueDescriptor
					title='Numer rachunku bankowego Twojej firmy'
					value={
						staticMask(
							'',
							props.companyData.bankAccountNumber,
							'## #### #### #### #### #### ####',
							null
						).maskedValue
					}
				/>
			</Grid>
			<Grid item xs={gridFullWidth} md={gridHalfWidth}>
				<ValueDescriptor title='Twój bank' value={props.companyData.bankName} />
			</Grid>
		</>
	);
}
