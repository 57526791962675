import Checkbox from '@mui/material/Checkbox';
import checkbox from '../../common/images/checkbox.svg';
import FormControlLabel from '@mui/material/FormControlLabel';
import React, { ChangeEvent, useState } from 'react';
import { TextFieldProps } from '@mui/material/TextField';
import { SxProps } from '@mui/system/styleFunctionSx';
import Agreement from '../../models/Agreement';
import { AgreementsContainer } from './AgreementCheckbox.css';
import { textColorPrimary } from '../../theme/palette/palette';
import Typography from '@mui/material/Typography';

type AgreementCheckboxProps = Omit<TextFieldProps, 'name'> & {
	agreement: Agreement;
	onCheckboxChange?: (checked: boolean, isRequired: boolean) => void;
	agreementContentSx?: SxProps;
};

export default function AgreementCheckbox(
	props: AgreementCheckboxProps
): JSX.Element {
	const [agreementChecked, setAgreementChecked] = useState<boolean>(false);

	return (
		<AgreementsContainer>
			<FormControlLabel
				control={
					<Checkbox
						checked={agreementChecked}
						onChange={(event: ChangeEvent<HTMLInputElement>): void => {
							setAgreementChecked(event.target.checked);
							props.onCheckboxChange?.(
								event.target.checked,
								props.agreement.isRequired
							);
							props.agreement.isSelected = event.target.checked;
						}}
						required={props.agreement.isRequired}
						disableRipple
						checkedIcon={
							<img src={checkbox} alt='checkbox' style={{ padding: '2px' }} />
						}
						sx={{
							'& .MuiSvgIcon-root': { fontSize: 28 },
							'&.MuiCheckbox-root': { paddingTop: 0 },
						}}
					/>
				}
				label={
					<Typography
						variant='p2'
						sx={{
							fontWeight: 400,
							color: textColorPrimary,
						}}
					>
						{props.agreement.content}
					</Typography>
				}
				sx={{
					marginBottom: '16px',
					'&.MuiFormControlLabel-root': { alignItems: 'flex-start' },
					...props.agreementContentSx,
				}}
			/>
		</AgreementsContainer>
	);
}
