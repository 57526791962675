import {
	CreditDescriptionBackground,
	CreditDescriptionItem,
	CreditDescriptionItemImg,
	CreditDescriptionItemsContainer,
} from './LightOvdCreditDescription.css';

import simpleRepayment from '../../../../../common/images/simple_repayment.svg';
import interest from '../../../../../common/images/interest.svg';
import limitRules from '../../../../../common/images/limit_rules.svg';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import { Container } from '@mui/system';

export default function LightOvdCreditDescription(): JSX.Element {
	return (
		<CreditDescriptionBackground sx={{
				paddingTop:{xs:'58px',md:'80px'}, 
				paddingBottom:{xs:'81px', md:'80px'},
		}}>
			<Container sx={{maxWidth: '1200px !important'}}>
				<Typography variant='h3' sx={{ marginBottom: {xs:'63px',md:'48px'} , paddingLeft:{xs:'0',md:'100px'}}}>
					Dodatkowe pieniądze na Twoim koncie.
					<br />Nie korzystasz{' '}
					<span style={{ color: '#FFFFFF' }}>
						nie płacisz.
					</span>
				</Typography>
				<Grid
					container
					display='flex'
					alignItems='start'
					sx={CreditDescriptionItemsContainer}
				>
					<Grid xs={12} md={4} item sx={CreditDescriptionItem}>
						<CreditDescriptionItemImg src={simpleRepayment} alt='icon' sx={{paddingLeft:{xs:'0',md:'100px'}}}/>
						<Typography variant='h4' sx={{ maxWidth: '284px', paddingLeft:{xs:'0',md:'100px'}}}>
							Wygodny sposób spłaty - każdy wpływ na rachunek automatycznie zmniejsza zadłużenie
						</Typography>
					</Grid>

					<Grid xs={12} md={4} item sx={CreditDescriptionItem}>
						<CreditDescriptionItemImg src={interest} alt='icon' sx={{paddingLeft:{xs:'0',md:'100px'}}}/>
						<Typography variant='h4' sx={{ maxWidth: '284px', paddingLeft:{xs:'0',md:'100px'}}}>
							Nie musisz co miesiąc spłacać kapitału. Miesięcznie płatne są tylko odsetki
						</Typography>
					</Grid>
					<Grid xs={12} md={3.8} item sx={CreditDescriptionItem}>
						<CreditDescriptionItemImg src={limitRules} alt='icon' sx={{paddingLeft:{xs:'0',md:'100px'}}}/>
						<Typography variant='h4' sx={{ maxWidth: '284px', paddingLeft:{xs:'0',md:'100px'} }}>
							Proste zasady utrzymania limitu
						</Typography>
					</Grid>
				</Grid>
			</Container>
		</CreditDescriptionBackground>
	);
}
