import React, { useContext } from 'react';
import LightCreditGoodToKnow from './light-credit-good-to-know/LightCreditGoodToKnow';
import CreditDescription from '../../../../components/CreditDescription/CreditDescription';
import { Container } from 'src/common/styles/Layout.css';
import Loader from 'src/components/Loader/Loader';
import LightCreditInfoAndContactDataForm from './light-credit-info-and-contact-data-form/LightCreditInfoAndContactDataForm';
import { LightLandingPageContext, LightLandingPageContextProvider } from './state/LightLandingPageContext';

const LightLandingPage = (): JSX.Element => {
	const lightLandingPageContext = useContext(LightLandingPageContext)

	if (!lightLandingPageContext) {
		return (
			<Container>
				<Loader />
			</Container>
		);
	}

	return (
		<LightLandingPageContextProvider>
			<LightCreditInfoAndContactDataForm />	
			<CreditDescription />
			<LightCreditGoodToKnow />
		</LightLandingPageContextProvider>
	);
};

export default LightLandingPage;
