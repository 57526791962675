import React from 'react';
import LightOvdCreditGoodToKnow from './light-ovd-credit-good-to-know/LightOvdCreditGoodToKnow';
import LightOvdCreditDescription from './light-ovd-credit-description/LightOvdCreditDescription';
import LightOvdCreditInfoAndContactDataForm from './light-ovd-credit-info-and-contact-data-form/LightOvdCreditInfoAndContactDataForm';
import { LightOvdLandingPageContextProvider } from './state/LightOvdLandingPageContext';

const LightOvdLandingPage = (): JSX.Element => {

	return (
		<LightOvdLandingPageContextProvider>
			<LightOvdCreditInfoAndContactDataForm />
			<LightOvdCreditDescription />
			<LightOvdCreditGoodToKnow />
		</LightOvdLandingPageContextProvider>
	);
};

export default LightOvdLandingPage;
