import BaseFetch from '../../../common/api/BaseFetch';
import { ApiConfig } from '../../../common/api/FetchTypes';
import { ApplicationProcess } from '../../../common/constants/application-process';

export interface InitialData {
	initCreditAmount: number;
	partnerLogoName: string;
	initInstallment: number;
	clientId: string;
	periods: number[];
	selectedPeriod: number;
	partnerName: string;
	process?: ApplicationProcess;
	processId: string;
}

const getInitialDataKey = 'getInitialData';

function getInitialData(props: string): Promise<InitialData> {
	return BaseFetch({ url: `LandingPage/InitialData/${props}`, method: 'GET' });
}

export const GetInitialDataConfig: ApiConfig<InitialData, string> = {
	key: getInitialDataKey,
	fn: getInitialData,
};
