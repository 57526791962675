import { useLocation, useNavigate } from 'react-router-dom';
import { ApplicationProcess } from 'src/common/constants/application-process';
import {
	applicationIdKey,
	applicationProcessKey,
	clientIdKey,
	getSessionStorageObj,
	getSessionStorageValue,
	processIdKey,
} from 'src/common/sessionStorage/SessionStorageService';
import OnePageInfo from 'src/components/OnePageInfo/OnePageInfo';
import { LightNewApplictionRoute, NewApplicationRoute, ReturnProposalRoute, TechnicalErrorRoute } from 'src/routing/Routing';
import { interpolatePath } from 'src/routing/RoutingService';
import { RouteMap, TechnicalErrorState } from './TechnicalErrorTypes';
import { pushEventVirtualpvTechnicalError } from 'src/common/googleAnalyticsEvents/googleAnalyticsEvents';
import { useEffect } from 'react';

export default function TechnicalError(): JSX.Element {
	const navigate = useNavigate();
	const state = useLocation().state as TechnicalErrorState | undefined;

	useEffect((): void => {
		pushEventVirtualpvTechnicalError();
	}, []);

	const tryAgainButtonAction = (): void => {
		if (state) {
			navigate(state.tryAgainPath);
			return;
		}

		const isActiveApplication = !!getSessionStorageValue(applicationIdKey);
		const process = getSessionStorageObj<ApplicationProcess>(applicationProcessKey) ?? ApplicationProcess.Unknown;

		const path = isActiveApplication ? getReturnProposalRouteMap()[process] : getNewApplicationRouteMap()[process];

		navigate(path);
	};

	/** Returns a map of routes for each Application Process for a new application*/
	const getNewApplicationRouteMap = (): RouteMap => {
		const clientId = getSessionStorageValue(clientIdKey) ?? '';
		const processId = getSessionStorageValue(processIdKey) ?? '';
		// prettier-ignore
		return {
			[ApplicationProcess.RbfCl]: NewApplicationRoute.path.replace('*', `#${clientId}`),
			[ApplicationProcess.RbfOvd]: NewApplicationRoute.path.replace('*', `#${clientId}`), //TODO: Adjust when Rbf OVD is ready
			[ApplicationProcess.LightCl]: interpolatePath(LightNewApplictionRoute.path, { processId }),
			[ApplicationProcess.LightOvd]: interpolatePath(LightNewApplictionRoute.path, { processId }), //TODO: Adjust when Light OVD is ready
			[ApplicationProcess.Unknown]: TechnicalErrorRoute.path,
		}
	};

	/** Return a map of routes for each Application Process for returning*/
	const getReturnProposalRouteMap = (): RouteMap => {
		return {
			[ApplicationProcess.RbfCl]: ReturnProposalRoute.path,
			[ApplicationProcess.RbfOvd]: ReturnProposalRoute.path, //TODO: Adjust when Rbf OVD is ready
			[ApplicationProcess.LightCl]: ReturnProposalRoute.path,
			[ApplicationProcess.LightOvd]: ReturnProposalRoute.path, //TODO: Adjust when Light OVD is ready
			[ApplicationProcess.Unknown]: TechnicalErrorRoute.path,
		};
	};

	return (
		<OnePageInfo
			titleText='Serwis jest niedostępny'
			descriptionText='Prosimy, spróbuj ponownie za chwilę.'
			onClickHandler={tryAgainButtonAction}
			buttonDisabled={false}
			buttonText='Spróbuj ponownie'
		/>
	);
}
