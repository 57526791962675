import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { ApplicationProcess } from '../../../common/constants/application-process';
import {
	applicationIdKey,
	applicationProcessKey,
	authTokenKey,
	guidKey,
	removeSessionStorageValue,
	setSessionStorageValue,
	tokenExpirationKey,
} from '../../../common/sessionStorage/SessionStorageService';
import Loader from '../../../components/Loader/Loader';
import {
	LeadTechnicalErrorRoute,
} from '../../../routing/Routing';
import LeadCL from '../lead-cl/LeadCL';
import LeadOVD from '../lead-ovd/LeadOVD';
import { GetInitialDataConfig, LeadProductType } from './LeadNewApplicationApi';
import useFetch from '../../../common/api/UseFetch';
import { interpolatePath } from 'src/routing/RoutingService';

export default function LeadNewApplication(): JSX.Element {
	const [component, setComponent] = useState(<Loader/>);
	
	const navigate = useNavigate();
	const { guid } = useParams();

	const initialDataMutation = useFetch(
		GetInitialDataConfig,
		false,
		(result): void => {
			console.log(result)
			setSessionStorageValue(applicationIdKey, '');
			setSessionStorageValue(authTokenKey, '');
			setSessionStorageValue(
				applicationProcessKey,
				result.process?.toString() ?? ApplicationProcess.Unknown.toString()
			);
			setSessionStorageValue(guidKey, guid ?? '');
			if (result.leadProduct === LeadProductType.CashLoan) {
				setComponent(<LeadCL initialData={result}/>);
			} else if (result.leadProduct === LeadProductType.Overdraft) {
				setComponent(<LeadOVD initialData={result}/>);
			} else {
				navigate(interpolatePath(LeadTechnicalErrorRoute.path, { guid: guid }));
			}
		},
		undefined,
		(): void => {
			navigate(interpolatePath(LeadTechnicalErrorRoute.path, { guid: guid }));
		},
		(): void => {
			navigate(interpolatePath(LeadTechnicalErrorRoute.path, { guid: guid }));
		},
		false
	);

	useEffect((): void => {
		setSessionStorageValue(guidKey, guid ?? '');
		removeSessionStorageValue(tokenExpirationKey);
		guid
			? initialDataMutation.mutate(guid)
			: navigate(interpolatePath(LeadTechnicalErrorRoute.path, { guid: guid }));
	}, []);

	return (
		<>
			{component}
		</>
	);
}
