import { useEffect } from 'react';
import { useNavigate } from 'react-router';
import { useParams, useSearchParams } from 'react-router-dom';
import useFetch from '../../../../../common/api/UseFetch';
import { ApplicationProcess } from '../../../../../common/constants/application-process';
import { getPartnerLogo } from '../../../../../common/helpers/get-partner-logo-helper';
import {
    applicationIdKey, applicationProcessKey, authTokenKey, partnerLogoKey, partnerNameKey, processIdKey,
    removeSessionStorageValue, setSessionStorageValue,
    tokenExpirationKey
} from '../../../../../common/sessionStorage/SessionStorageService';
import { CreateComponentContext } from '../../../../../context/CreateComponentContext';
import { LightLandingPageRoute, LightOvdLandingPageRoute, TechnicalErrorRoute } from '../../../../../routing/Routing';
import { TechnicalErrorState } from '../../../../common/technical-error/TechnicalErrorTypes';
import { GetLightInitialDataConfig } from '../api/LightNewApplicationApi';

const lightNewApplicationContextHandler = (): null => {
    const navigate = useNavigate();
    const params = useParams();
    const [searchParams, _] = useSearchParams();

    const navigateToTechnicalError = (): void => {
        const state: TechnicalErrorState = {
            tryAgainPath: window.location.pathname,
        };
        navigate(TechnicalErrorRoute.path, { state });
    };

    const initialDataMutation = useFetch(
        GetLightInitialDataConfig,
        false,
        (result): void => {
            setSessionStorageValue(applicationIdKey, '');
            setSessionStorageValue(authTokenKey, '');
            if (result.partnerLogoName) {
                setSessionStorageValue(
                    partnerLogoKey,
                    getPartnerLogo(result.partnerLogoName)
                );
            }

            setSessionStorageValue(partnerNameKey, result.partnerName);
            setSessionStorageValue(processIdKey, params.processId ?? '');
            setSessionStorageValue(applicationProcessKey, result.process.toString());
            let path = '';
            switch (result.process) {
                case ApplicationProcess.LightCl:
                    path = LightLandingPageRoute.path;
                    break;
                case ApplicationProcess.LightOvd:
                    path = LightOvdLandingPageRoute.path;
                    break;
                default:
                    path = TechnicalErrorRoute.path;
                    break;
            }
            navigate(path, {
                state: {
                    ...result,
                    processId: params.processId ?? '',
                    query: searchParams.toString(),
                },
            });
        },
        undefined,
        undefined,
        navigateToTechnicalError,
        false
    );

    useEffect((): void => {
        removeSessionStorageValue(tokenExpirationKey);
        removeSessionStorageValue(applicationIdKey);
        removeSessionStorageValue(authTokenKey);
        removeSessionStorageValue(processIdKey);
        params.processId
            ? initialDataMutation.mutate(params.processId)
            : navigateToTechnicalError();
    }, []);

    return null;
};

export const [_LightNewApplicationContext, LightNewApplicationContextProvider] =
    CreateComponentContext<null>(
     null,
     lightNewApplicationContextHandler
);
