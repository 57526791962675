import { ThemeProvider } from '@mui/material';
import { QueryClientProvider } from '@tanstack/react-query';
import { Provider } from 'react-redux';
import queryClient from './common/api/QueryClient';
import NestSnackbar from './components/NestSnackbar/NestSnackbar';
import { SnackbarContextProvider } from './context/SnackbarContext';
import AppRouterOutlet from './routing/AppRouterOutlet';
import { store } from './store/State';
import { InitGlobalStyles } from './theme/InitGlobalStyles';
import { mainTheme } from './theme/mainTheme';
import { ContextSaveApplicationDataProvider } from './context/ContextSaveApplicationData';
import { SessionContextProvider } from './context/SessionContext';

function App(): JSX.Element {
    return (
        <ThemeProvider theme={mainTheme}>
            <Provider store={store}>
                <InitGlobalStyles/>
                <QueryClientProvider client={queryClient}>
                    <ContextSaveApplicationDataProvider>
                        <SnackbarContextProvider>
                            <SessionContextProvider>
                                <AppRouterOutlet/>
                                <NestSnackbar/>
                            </SessionContextProvider>
                        </SnackbarContextProvider>
                    </ContextSaveApplicationDataProvider>
                </QueryClientProvider>
            </Provider>
        </ThemeProvider>
    );
}

export default App;

if (window.Cypress) {
    window.store = store;
}
