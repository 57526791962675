import Button, { ButtonProps } from '@mui/material/Button';
import React from 'react';
import Loader from '../Loader/Loader';

interface IButtonProps extends ButtonProps {
	isLoading?: boolean;
}

const CustomButton = (props: IButtonProps): JSX.Element => {
	const propsToButton = { ...props };
	delete propsToButton.isLoading;
	return (
		<Button
			{...propsToButton}
			disabled={props.isLoading ? true : props.disabled}
		>
			{props.isLoading ? <Loader /> : props.children}
		</Button>
	);
};

export default React.memo(CustomButton);
