import { Typography } from '@mui/material';
import { useEffect, useRef } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { getPartnerLogo } from 'src/common/helpers/get-partner-logo-helper';
import useFetch from '../../common/api/UseFetch';
import { ApplicationProcess } from '../../common/constants/application-process';
import {
	applicationIdKey,
	applicationNumberKey,
	applicationProcessKey,
	authTokenKey,
	getSessionStorageObj,
	marketingPartnerIdKey,
	partnerLogoKey,
	partnerNameKey,
	processIdKey,
	setSessionStorageValue,
	userIdKey,
} from '../../common/sessionStorage/SessionStorageService';
import Loader from '../../components/Loader/Loader';
import { navigateToRoute } from '../../pages/common/helpers/navigateToRoute';
import {
	LightCompanyDataVerifiedRoute,
	LightIdentityVerificationInProgressRoute,
	LightOvdCompanyDataVerifiedRoute,
	LightOvdIdentityVerificationInProgressRoute,
	LightOvdVerificationFailedRoute,
	LightVerificationFailedRoute,
	SmsContractSigningRoute,
	VerificationFailedRoute,
} from '../../routing/Routing';
import {
	getLightVerificationResultConfig,
	getRegenerateApplicationSessionConfig,
	getVerificationResultConfig,
	RegenerateApplicationSessionResult,
	VerificationResult,
} from './api/IdentityVerificationInProgressApi';
import { TIME_OUT_MILLISECONDS } from './consts/IdentityVerificationInProgressConstans';
import { MainContainer, VerificationMainContainer } from './IdentityVerificationInProgress.css';

export default function IdentityVerificationInProgress(): JSX.Element {
	const redirectionsArr = [
		{
			process: ApplicationProcess.LightCl,
			positiveRedirect: LightCompanyDataVerifiedRoute.path,
			negativeRedirect: LightVerificationFailedRoute.path,
			endpoint: useFetch(getLightVerificationResultConfig, false, ({ isPositivelyVerified, areAttemptsLeft }: VerificationResult): void => {
				if (isPositivelyVerified === null) {
					setTimeoutForGetVerificationResult();
					return;
				}
				redirectToAppropriatelyPage(isPositivelyVerified, areAttemptsLeft);
			}),
		},
		{
			process: ApplicationProcess.LightOvd,
			positiveRedirect: LightOvdCompanyDataVerifiedRoute.path,
			negativeRedirect: LightOvdVerificationFailedRoute.path,
			endpoint: useFetch(getLightVerificationResultConfig, false, ({ isPositivelyVerified, areAttemptsLeft }: VerificationResult): void => {
				if (isPositivelyVerified === null) {
					setTimeoutForGetVerificationResult();
					return;
				}
				redirectToAppropriatelyPage(isPositivelyVerified, areAttemptsLeft);
			}),
		},
		{
			process: ApplicationProcess.RbfCl,
			positiveRedirect: SmsContractSigningRoute.path,
			negativeRedirect: VerificationFailedRoute.path,
			endpoint: useFetch(getVerificationResultConfig, false, ({ isPositivelyVerified, areAttemptsLeft }: VerificationResult): void => {
				if (isPositivelyVerified === null) {
					setTimeoutForGetVerificationResult();
					return;
				}
				redirectToAppropriatelyPage(isPositivelyVerified, areAttemptsLeft);
			}),
		},
	];

	const navigate = useNavigate();
	const location = useLocation();

	const isCalledRegenerateApplicationSessionResultRef = useRef(false);
	const timerRef = useRef(null as unknown as ReturnType<typeof setTimeout>);

	const regenerateApplicationSessionResult = useFetch(getRegenerateApplicationSessionConfig, false, (resp): void => {
		setSessionStorageData(resp);
		setTimeoutForGetVerificationResult();
	});

	useEffect((): (() => void) => {
		if (!isCalledRegenerateApplicationSessionResultRef.current) {
			isCalledRegenerateApplicationSessionResultRef.current = true;
			getRegenerateApplicationSession();
		}
		return (): void => clearTimeout(timerRef.current);
	}, []);

	const setTimeoutForGetVerificationResult = (): void => {
		timerRef.current = setTimeout((): void => getVerificationResult(), TIME_OUT_MILLISECONDS);
	};

	const getVerificationResult = (): void => {
		const appProcess = getSessionStorageObj<ApplicationProcess>(applicationProcessKey);
		const redirectionObj = redirectionsArr.find((x): boolean => x.process === appProcess);
		if (redirectionObj?.endpoint) {
			redirectionObj.endpoint.mutate({} as never);
		}
	};
	const getRegenerateApplicationSession = (): void => {
		const orderUuid: string = new URLSearchParams(location.state as { orderUuid: string }).get('orderUuid') ?? '';
		regenerateApplicationSessionResult.mutate({
			orderUuid: orderUuid,
			page: navigateToRoute(LightIdentityVerificationInProgressRoute.page, LightOvdIdentityVerificationInProgressRoute.page) as number,
		});
	};
	const setSessionStorageData = (data: RegenerateApplicationSessionResult): void => {
		setSessionStorageValue(applicationIdKey, data.applicationId);
		setSessionStorageValue(authTokenKey, data.token);
		setSessionStorageValue(applicationNumberKey, data.applicationNumber);
		setSessionStorageValue(marketingPartnerIdKey, data.marketingPartnerId);
		setSessionStorageValue(userIdKey, data.userId);
		data.partnerLogoName && setSessionStorageValue(partnerLogoKey, getPartnerLogo(data.partnerLogoName));
		setSessionStorageValue(partnerNameKey, data.partnerName);
		setSessionStorageValue(processIdKey, data.processId);
		setSessionStorageValue(applicationProcessKey, data.process.toString());
	};

	const redirectToAppropriatelyPage = (isPositivelyVerified: boolean, areAttemptsLeft: boolean): void => {
		const process = getSessionStorageObj<ApplicationProcess>(applicationProcessKey);
		const redirectionObj = redirectionsArr.find((x): boolean => x.process === process);
		if (redirectionObj) {
			isPositivelyVerified
				? navigate(redirectionObj.positiveRedirect)
				: navigate(redirectionObj.negativeRedirect, {
						state: areAttemptsLeft,
				  });
		}
	};

	return (
		<VerificationMainContainer>
			<MainContainer>
				<Loader style={{ margin: '0 0 48px 0' }} />
				<Typography
					variant='h3'
					sx={{
						fontSize: { md: '28px', xs: '24px' },
						lineHeight: { md: '33.6px', xs: '28.8px' },
						padding: '0 12px',
					}}
				>
					Potwierdzanie przychodów firmy
				</Typography>
				<Typography variant='p1' sx={{ marginTop: '30px' }}>
					To może potrwać do kilku minut
				</Typography>
			</MainContainer>
		</VerificationMainContainer>
	);
}
