export function idCardIssuedDateValidator(
	date: Date | undefined
): boolean | string {
	const now = new Date();

	if (date && date.getTime() > now.getTime()) return 'Niepoprawna wartość';

	if (date && date.getTime() < tenYearsAgoMinusOneDay().getTime())
		return 'Niepoprawna wartość';

	return true;
}

export const tenYearsAgoMinusOneDay = (): Date => {
	const tenYearInMillisecond = 10 * 365 * 24 * 60 * 60 * 1000;
	const oneDayInMillisecond = 24 * 60 * 60 * 1000;
	const currentDate = new Date();
	const zeroedTime = new Date(
		currentDate.getFullYear(),
		currentDate.getMonth(),
		currentDate.getDate(),
		0,
		0,
		0,
		0
	);
	const differenceInMilliseconds = currentDate.getTime() - zeroedTime.getTime();
	return new Date(
		new Date().getTime() -
			tenYearInMillisecond -
			oneDayInMillisecond -
			differenceInMilliseconds
	);
};
