import { styled } from '@mui/system';
import { senaryMainColor } from '../../../theme/palette/palette';
import { mainTheme } from '../../../theme/mainTheme';

export const Image = styled('img')`
	align-self: center;
	margin-right: 16px;
	height: 49px;
`;

export const LogoWithTitle = styled('div')`
	display: flex;
	align-items: center;
	margin-bottom: 36px;
`;

export const DocumentSection = styled('div')`
	margin: 48px 0;
`;

export const DocumentSectionElement = styled('div')`
	cursor: pointer;
	display: flex;
	align-items: center;
`;

export const SmsSigningSecition = styled('div')({
	margin: '48px 0',
	display: 'flex',
	flexDirection: 'column',
	justifyContent: 'center',
	[mainTheme.breakpoints.up('md')]: {
		justifyContent: 'space-between',
		flexDirection: 'row',
	},
});

export const SmsInputContainer = styled('div')({
	maxWidth: '312px',
	height: '125px',
});

export const SendButtonContainer = styled('div')({
	display: 'flex',
	width: '100%',
	flexDirection: 'column',
	textAlign: 'center',
	alignItems: 'center',
	height: '112px',
	justifyContent: 'space-between',
	[mainTheme.breakpoints.up('md')]: {
		width: '306px',
	},
});

export const SectionSeparation = styled('hr')({
	borderTop: '1px solid ' + senaryMainColor,
	width: '100%',
});

export const SectionSeparationContaier = styled('div')({
	width: '100%',
	height: '5px',
});
