import BaseFetch from '../../common/api/BaseFetch';
import { ApiConfig } from '../../common/api/FetchTypes';
import { SessionExpirationResponse } from '../../routing/RoutingService';

const refreshSessionKey = 'refreshSession';

function refreshSession(): Promise<SessionExpirationResponse> {
	return BaseFetch({ url: 'Routing/RefreshSession', method: 'POST' });
}

export const refreshSessionConfig: ApiConfig<SessionExpirationResponse, never> =
	{
		key: refreshSessionKey,
		fn: refreshSession,
	};
