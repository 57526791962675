/* eslint-disable @typescript-eslint/no-misused-promises */
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';
import { Container } from '../../../../common/styles/Layout.css';
import arrowDownUnderscore from '../../../../common/images/ArrowDownUnderscore.svg';
import { AgreementModelSend } from '../../../../components/AgreementCollection/AgreementsModels';
import NewTabFileHandler from '../../../../components/NewTabFileHandler/NewTabFileHandler';
import {
	DocumentSection,
	SmsSigningSecition,
	SmsInputContainer,
	SendButtonContainer,
	DocumentSectionElement,
	SectionSeparation,
	SectionSeparationContaier,
} from './LightSmsContractSigning.css';
import { ReactNode, useState } from 'react';
import { secondaryMainColor, textColorSecondary } from '../../../../theme/palette/palette';
import NestInput from '../../../../components/FormItems/NestInput/NestInput';
import { Control, useForm, FormProvider } from 'react-hook-form';
import { defaultValues, documents, submitButtonFileStyles } from './LightSmsContractSigningConstants';
import { GetAgreement, GetVerifySmsCode, PostSendSms } from './LightSmsContractSigningApi';
import useFetch from '../../../../common/api/UseFetch';
import AgreementCheckbox from '../../../../components/AgreementCheckbox/AgreementCheckbox';
import Agreement from '../../../../models/Agreement';
import { LightAttachDocumentsRoute, LightRejectionRoute } from '../../../../routing/Routing';
import { useNavigate } from 'react-router-dom';
import CustomButton from '../../../../components/CustomButton/CustomButton';
import { ScrollToTopOnMount } from '../../../../common/helpers/scroll-to-top-onmount';
import { IDocuments, SmsContractDataModel } from '../../../rbf/rbf-sms-contract-signing/SmsContractSigningDataModels';
import FetchError from 'src/common/api/FetchError';
import { rbfErrorCodes } from 'src/common/api/ErrorCodes';
import { Input } from 'src/components/FormItems/NestInput/NestInput.css';

import { SMS_CODE_TIMEOUT } from 'src/common/constants/timeouts';

export default function LightSmsContractSigning(): JSX.Element {
	const navigate = useNavigate();
	const [agreementChecked, setAgreementChecked] = useState(false);
	const [checkboxControl, setCheckboxControl] = useState<boolean[]>([]);
	const [smsSent, setSmsSent] = useState(false);
	const [smsCountdown, setSmsCountdown] = useState(0);

	const postSendSms = useFetch(PostSendSms, false, (): void => {
		setSmsSent(true);
		setSmsCountdown(60);
		const timeout = setInterval((): void => {
			setSmsCountdown((prevState: number): number => prevState - 1);
		}, 1000);

		setTimeout((): void => {
			clearInterval(timeout);
		}, SMS_CODE_TIMEOUT);
	});

	const getVerifySmsCode = useFetch(
		GetVerifySmsCode,
		false,
		(): void => {
			navigate(LightAttachDocumentsRoute.path);
		},
		true,
		(error: FetchError): void => {
			error.message === rbfErrorCodes.allSmsAttemptsHaveBeenUsed && navigate(LightRejectionRoute.path);
		}
	);

	const agreements = useFetch(GetAgreement, true, (data): void => {
		setCheckboxControl(data.map((): boolean => false));
	});

	const agreementsChange = (checked: boolean, index: number): void => {
		const checkboxControlTemp = [...checkboxControl];
		if (checkboxControlTemp.length) {
			checkboxControlTemp[index] = checked;
			setAgreementChecked(checkboxControlTemp.every((element: boolean): boolean => element));
		}
		setCheckboxControl(checkboxControlTemp);
	};

	const methods = useForm<SmsContractDataModel>({
		mode: 'onSubmit',
		reValidateMode: 'onChange',
		defaultValues,
	});
	
	const sendSms = (): void => {
		methods.reset();
		postSendSms.mutate(
			agreements.data?.map((item: Agreement): AgreementModelSend => {
				return {
					symbol: item.symbol,
					isSelected: item.isSelected
				}
			}) as AgreementModelSend[])
	};

	const onSubmit = (data: SmsContractDataModel): void => {
		getVerifySmsCode.mutate(data.smsConfirmationCode);
	};

	return (
		<Container>
			<ScrollToTopOnMount />
			<Typography
				variant='h2'
				sx={{
					fontSize: { xs: '28px', sm: '34px' },
					lineHeight: { xs: '34px', sm: '40px' },
					maxWidth: '675px',
				}}
			>
				Zapoznaj się z treścią umowy kredytowej i podpisz ją kodem SMS
			</Typography>
			<DocumentSection>
				{documents.map(
					(element: IDocuments): ReactNode => (
						<NewTabFileHandler
							fileName={element.fileName}
							fileType={element.fileType}
							buttonStyles={submitButtonFileStyles}
							buttonSubmitText={element.fileName}
						>
							<DocumentSectionElement>
								<img src={arrowDownUnderscore} style={{ marginRight: '9px' }} alt='icon' />
							</DocumentSectionElement>
						</NewTabFileHandler>
					)
				)}
			</DocumentSection>
			{agreements.data?.map(
				(agreement: Agreement, index: number): ReactNode => (
					<AgreementCheckbox
						key={agreement.symbol}
						agreement={agreement}
						onCheckboxChange={(checked: boolean): void => {
							agreementsChange(checked, index);
						}}
						disabled={postSendSms.isPending || getVerifySmsCode.isPending}
					/>
				)
			)}
			<SectionSeparationContaier>{agreementChecked && <SectionSeparation />}</SectionSeparationContaier>
			<form onSubmit={methods.handleSubmit(onSubmit)}>
				<SmsSigningSecition>
					{!smsSent && (
						<>
							<div></div>
							<SendButtonContainer sentCode={smsSent}>
								<Typography variant='p3' component={'div'} sx={{ color: textColorSecondary }}>
									Po kliknięciu na przycisk otrzymasz kod SMS, za pomocą którego podpiszesz umowę.
								</Typography>
								<CustomButton
									isLoading={postSendSms.isPending}
									sx={{ marginTop: '24px', width: '280px', cursor: 'pointer' }}
									variant='contained'
									onClick={sendSms}
									disabled={!agreementChecked}
								>
									Wyślij kod
								</CustomButton>
							</SendButtonContainer>
						</>
					)}
					{smsSent && (
						<>
							<SmsInputContainer>
								<div>
									<FormProvider {...methods}>
										{!postSendSms.isPending ? (
											<NestInput
												type={'text'}
												label='Kod SMS'
												control={methods.control as unknown as Control}
												name='smsConfirmationCode'
												rules={{
													required: 'Pole wymagane',
												}}
												style={{ width: '280px', maxWidth: '312px' }}
												disabled={getVerifySmsCode.isPending}
											/>
										) : (
											<Stack>
												<Typography
													variant='h9'
													sx={{
														color: 'text.secondary',
														marginBottom: '8px',
														fontWeight: '600',
													}}
												>
													Kod SMS
												</Typography>
												<Input type='text' error={false} disabled={true} />
											</Stack>
										)}
									</FormProvider>
									<Typography
										component={'div'}
										sx={{
											color: smsCountdown > 0 ? textColorSecondary : secondaryMainColor,
											marginTop: '24px',
											cursor: smsCountdown > 0 ? '' : 'pointer',
											fontFamily: 'Athletics, sans-serif',
										}}
										variant='h7'
										onClick={!postSendSms.isPending && smsCountdown === 0 ? sendSms : undefined}
									>
										Wyślij kod ponownie {smsCountdown ? 'za ' + smsCountdown + 's' : null}
									</Typography>
								</div>
							</SmsInputContainer>
							<SendButtonContainer sentCode={smsSent}>
								<div></div>
								<CustomButton
									isLoading={getVerifySmsCode.isPending}
									sx={{ marginTop: '24px', width: '280px' }}
									variant='contained'
									type='submit'
									disabled={methods.watch('smsConfirmationCode').length < 4 || !agreementChecked}
								>
									Zawieram umowę
								</CustomButton>
							</SendButtonContainer>
						</>
					)}
				</SmsSigningSecition>
			</form>
		</Container>
	);
}
