import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Stack from '@mui/system/Stack/Stack';
import { useState, useContext, useEffect } from 'react';
import useFetch from '../../../../common/api/UseFetch';
import { formatAmount } from '../../../../common/helpers/number-helper';
import { Container } from '../../../../common/styles/Layout.css';
import Loader from '../../../../components/Loader/Loader';
import { mainTheme } from '../../../../theme/mainTheme';
import {
	ButtonTextContainer,
	CreditInfo,
	DownloadIconContainer,
	HorizontalLine,
} from './LightCalculatorPage.css';
import {
	CalculatedLoanInfo,
	calculateLoanInfoConfig,
	saveCalculationConfig,
	GetLoanConfig,
	saveCalculationDraftConfig,
} from './LightCalculatorApi';
import CalculatorCreditInfo from './LightCalculatorCreditInfo';
import Calculator from './LightCalculator';
import { gridFullWidth } from '../../../../theme/grid/GridConstants';
import NestTooltip from '../../../../components/NestTooltip/NestTooltip';
import { useNavigate } from 'react-router-dom';
import { LightSmsContractSigningRoute } from '../../../../routing/Routing';
import { ContextSaveApplicationData } from '../../../../context/ContextSaveApplicationData';
import arrowDownUnderscore from '../../../../common/images/ArrowDownUnderscore.svg';
import CustomButton from '../../../../components/CustomButton/CustomButton';
import { FileType } from '../../../../enums/FileType';
import { ScrollToTopOnMount } from '../../../../common/helpers/scroll-to-top-onmount';
import CalculatorBottomInfo from '../../../../components/CalculatorBottomInfo/CalculatorBottomInfo';
import { submitButtonFileStyles } from '../cl-sms-contract-signing/LightSmsContractSigningConstants';
import NewTabFileHandler from 'src/components/NewTabFileHandler/NewTabFileHandler';

export default function LightCalculatorPage(): JSX.Element {
	const [calcConfig, setCalcConfig] = useState<{
		selectedAmount: number;
		selectedPeriod: number;
	}>({
		selectedAmount: 0,
		selectedPeriod: 0,
	});
	const [calculatedLoan, setCalculatedLoan] = useState<
		CalculatedLoanInfo | undefined
	>(undefined);

	const navigate = useNavigate();

	const calculateLoan = useFetch(
		calculateLoanInfoConfig,
		false,
		(result): void => {
			setCalculatedLoan(result);
		}
	);

	const getLoanConfig = useFetch(GetLoanConfig, true, (result): void => {
		setCalcConfig({
			selectedAmount: result.selectedAmount,
			selectedPeriod: result.selectedPeriod,
		});
		calculateLoan.mutate({
			value: result.selectedAmount,
			period: result.selectedPeriod,
		});
	});

	const saveLoanConfig = useFetch(saveCalculationConfig, false, (): void => {
		navigate(LightSmsContractSigningRoute.path);
	});
	const saveLoanConfigDraft = useFetch(saveCalculationDraftConfig, false);

	const [isSaveApplicationData] = useContext(ContextSaveApplicationData);

	useEffect((): void => {
		if (isSaveApplicationData) {
			saveLoanConfigDraft.mutate({
				value: calcConfig.selectedAmount,
				period: calcConfig.selectedPeriod,
			});
		}
	}, [isSaveApplicationData]);

	const onCalculatorChange = (
		creditAmount: number,
		creditPeriod: number
	): void => {
		setCalcConfig({
			selectedAmount: creditAmount,
			selectedPeriod: creditPeriod,
		});
		calculateLoan.mutate({ value: creditAmount, period: creditPeriod });
	};

	if (!calculatedLoan || !getLoanConfig.data) {
		return (
			<Container>
				<Loader />
			</Container>
		);
	}

	return (
		<>
			<Container>
				<ScrollToTopOnMount />
				<Typography
					variant='h2'
					sx={{
						color: mainTheme.palette.text.primary,
						marginBottom: { xs: '20px', md: '48px' },
						fontSize: { md: '34px', xs: '28px' },
					}}
				>
					Dopasuj ofertę do swoich potrzeb
				</Typography>
				<Typography
					variant='h8'
					sx={{
						color: mainTheme.palette.text.secondary,
						fontWeight: 600,
					}}
				>
					Po analizie Twojej historii kredytowej możemy zaproponować Ci:
				</Typography>
				<Typography
					variant='h4'
					sx={{ color: mainTheme.palette.text.primary, marginTop: '9px' }}
				>
					{formatAmount(getLoanConfig.data.maxLoanAmount)}{' '}
					<Typography variant='h7'>
						PLN <CreditInfo>brutto kredytu</CreditInfo>{' '}
						<NestTooltip text='Kwota uwzględnia kredytowaną prowizję za udzielenie kredytu.' />
					</Typography>
				</Typography>
				<HorizontalLine />
				<Grid container>
					<Grid
						item
						xs={gridFullWidth}
						md={8}
						sx={{ marginTop: { sx: '0', md: '79px' } }}
					>
						<Calculator
							isLoading={
								calculateLoan.isPending ||
								saveLoanConfig.isPending ||
								saveLoanConfigDraft.isPending
							}
							min={getLoanConfig.data.minLoanAmount}
							max={getLoanConfig.data.maxLoanAmount}
							defaultAmount={getLoanConfig.data.selectedAmount}
							defaultPeriod={getLoanConfig.data.selectedPeriod}
							periods={getLoanConfig.data.periods}
							onChange={([amount, period]): void =>
								onCalculatorChange(amount, period)
							}
							text='Wpisz lub ustaw suwakiem kwotę kredytu'
						/>
						<Stack
							component='div'
							sx={{ marginTop: '44px', display: { xs: 'none', md: 'block' } }}
						>
							<NewTabFileHandler
								fileName={'Umowa kredytowa - wzór.pdf'}
								fileType={FileType.ContractTemplate}
								buttonStyles={submitButtonFileStyles}
								buttonSubmitText={'Pobierz wzór umowy'}
							>
								<DownloadIconContainer>
									<img
										src={arrowDownUnderscore}
										style={{ marginRight: '9px' }}
										alt='icon'
									/>
								</DownloadIconContainer>
							</NewTabFileHandler>
						</Stack>
					</Grid>
					<Grid item xs={gridFullWidth} md={4}>
						<CalculatorCreditInfo
							data={calculatedLoan}
							firstInstallmentPaymentDate={
								getLoanConfig.data.firstInstallmentPaymentDate
							}
						/>
					</Grid>
				</Grid>
				<Grid
					item
					xs={gridFullWidth}
					sx={{
						marginTop: { xs: '11px' },
						display: { md: 'none', xs: 'block' },
					}}
				>
					<NewTabFileHandler
						fileName={'Umowa kredytowa - wzór.pdf'}
						fileType={FileType.ContractTemplate}
						buttonStyles={submitButtonFileStyles}
						buttonSubmitText={'Pobierz wzór umowy'}
					>
						<DownloadIconContainer>
							<img
								src={arrowDownUnderscore}
								style={{ marginRight: '9px' }}
								alt='icon'
							/>
						</DownloadIconContainer>
					</NewTabFileHandler>
				</Grid>
				<ButtonTextContainer
					sx={{
						alignItems: { md: 'flex-end', xs: 'center' },
						margin: { xs: '104px 0 32px 0', md: '55px 0 76px 0' },
					}}
				>
					<CustomButton
						isLoading={saveLoanConfig.isPending}
						variant='contained'
						onClick={(): void =>
							saveLoanConfig.mutate({
								value: calcConfig.selectedAmount,
								period: calcConfig.selectedPeriod,
							})
						}
					>
						Dalej
					</CustomButton>
				</ButtonTextContainer>
			</Container>
			<CalculatorBottomInfo />
		</>
	);
}
