export const customAggreementSettings = [
	{
		symbol: 'ZGO_KOM_MARK_FAKT_NEW',
		hideSwitch: true,
	},
	{
		symbol: 'ZGO_KOM_MARK_PART_BIZ',
		hideSwitch: true,
	},
	{
		symbol: 'ZGO_KOM_MARK_UD_PART_BIZ',
		hideSwitch: true,
	},
];
