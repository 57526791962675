import { AgreementModel, AgreementModelSend, AgreementSectionModel, MediaConsent } from '../../components/AgreementCollection/AgreementsModels';
import { typeOfPropertyOptions } from '../../pages/rbf/rbf-personal-data/components/ClientWealthForm/ClientWealthFormConstants';
import {
	IClientWealth,
	IClientWealthMachine,
	IClientWealthOther,
	IClientWealthRealEstate,
	IClientWealthSendData,
	IClientWealthVehicle,
} from '../../pages/rbf/rbf-personal-data/PersonalDataModels';

export const convertAgreementsToAgreementModelToSend = (data: AgreementSectionModel[]): AgreementModelSend[] => {
	const mediaConsents: AgreementModelSend[] = [];
	const mainAgreements: AgreementModelSend[] = [];
	data.forEach((agreementSection: AgreementSectionModel): void => {
		agreementSection.consentGroup.forEach((agreement: AgreementModel): void => {
			agreement.mediaConsents?.forEach((mediaConsent: MediaConsent): void => {
				mediaConsents.push({
					symbol: mediaConsent.symbol,
					isSelected: mediaConsent.isSelected,
				});
			});
			mainAgreements.push({
				symbol: agreement.symbol,
				isSelected: agreement.isSelected,
			});
		});
	});

	return [...mainAgreements, ...mediaConsents];
};

export const convertMediaAgreementsToAgreementModelToSend = (data: AgreementSectionModel[]): AgreementModelSend[] => {
	return data.flatMap((section: AgreementSectionModel): AgreementModelSend[] =>
		section.consentGroup.flatMap(
			(group: AgreementModel): AgreementModelSend[] =>
				group.mediaConsents?.map(
					(mc: MediaConsent): AgreementModelSend => ({
						symbol: mc.symbol,
						isSelected: mc.isSelected,
					})
				) ?? []
		)
	);
};

export const convertedClientWealthDataToSend = (
	isOpenClientWealthForm: boolean,
	watchClientWealth: IClientWealthSendData[] | IClientWealth[]
): IClientWealthSendData[] | [] => {
	if (!isOpenClientWealthForm) {
		return [];
	}
	if (watchClientWealth.length > 0) {
		return watchClientWealth.map((item: IClientWealthSendData | IClientWealth): IClientWealthSendData => {
			let worth = 0;
			(item.name as IClientWealthRealEstate[] | IClientWealthVehicle[] | IClientWealthMachine[] | IClientWealthOther[]).forEach(
				(name: IClientWealthRealEstate | IClientWealthVehicle | IClientWealthMachine | IClientWealthOther): number => (worth += Number(name.worth.split(" ").join("").replace(',', '.')))
			);
			
			return {
				type: item.type,
				name: JSON.stringify(item.name),
				worth: worth,
			};
		});
	} else {
		return [];
	}
};

export const parseClientWealthObj = (obj: IClientWealthSendData[]): IClientWealth[] | [] => {
	if (!obj.length) {
		typeOfPropertyOptions.forEach((item: IClientWealth): void => {
			item.active = false;
		});
		return [];
	}

	return obj.map((item: IClientWealthSendData): IClientWealth => {
		const placeholder = typeOfPropertyOptions.find((x: IClientWealth): boolean => x.type === item.type);
		const objectOfSendData = typeOfPropertyOptions[item.type - 1];
		objectOfSendData.active = true;

		return {
			type: item.type,
			name: JSON.parse(item.name as unknown as string) as
				| IClientWealthOther[]
				| IClientWealthMachine[]
				| IClientWealthRealEstate[]
				| IClientWealthVehicle[],
			worth: item.worth,
			placeholder: placeholder ? placeholder.placeholder : '',
			active: item.active ?? (false as boolean),
		};
	});
};
