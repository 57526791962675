import { useContext } from 'react';
import { formatAmount } from '../../../../../../../common/helpers/number-helper';
import NestCollapse from '../../../../../../../components/NestCollapse/NestCollapse';
import NestTooltip from '../../../../../../../components/NestTooltip/NestTooltip';
import { gridFullWidth } from '../../../../../../../theme/grid/GridConstants';
import { mainTheme } from '../../../../../../../theme/mainTheme';
import { SpanPln } from '../../../../../ovd/ovd-landing-page/light-ovd-credit-info-and-contact-data-form/components/lightOvdCreditInfoSection/LightOvdCreditInfoSection.css';
import { LightLandingPageContext } from '../../../state/LightLandingPageContext';
import {
	CreditInfoSectionBackgroundImage,
	CreditInfoSectionContainer,
	CreditInfoSectionContainerBackground,
	CreditPeriodsContainer,
	PersonBackground,
} from './LightCreditInfoSection.css';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import { collapseContent } from './LightCreditInfoSectionConstats';
import PersonBackgroundImgCut from '../../../../../../../common/images/LPPersonBackground.svg';
import { primaryMainColor } from 'src/theme/palette/palette';
import { WiderContainer } from '../../../../../../../common/styles/Layout.css';

const LightCreditInfoSection = (): JSX.Element => {
	const lightLandingPageContext = useContext(LightLandingPageContext);

	return (
		<CreditInfoSectionContainerBackground>
			<CreditInfoSectionContainer>
				<CreditInfoSectionBackgroundImage>
					<PersonBackground src={PersonBackgroundImgCut} alt='person image' />
				</CreditInfoSectionBackgroundImage>
				<WiderContainer>
					<Typography
						variant='h2'
						sx={{
							fontSize: '14px',
							fontWeight: 600,
							color: primaryMainColor,
							marginBottom: { xs: '7px', md: '20px' },
							lineHeight: '19px',
							marginTop: { xs: '0', md: '15px' },
						}}
					>
						Kredyt dla jednoosobowej działalności gospodarczej
					</Typography>
					<Typography
						variant='h1'
						sx={{
							color: '#FFFFFF',
							maxWidth: '600px',
							fontSize: { xs: '34px', sm: '48px' },
							lineHeight: { xs: '48px', sm: '57px' },
						}}
					>
						Rozwiń swój biznes!
						<br /> Nawet{' '}
						<span style={{ whiteSpace: 'nowrap' }}>
							<span
								style={{
									color: '#FC6A46',
								}}
							>
								{' '}
								{formatAmount(lightLandingPageContext.initialData?.maxCreditAmount)}{' '}
								<SpanPln sx={{ fontSize: '30px', lineHeight: '28px' }}>PLN</SpanPln>
							</span>
							<NestTooltip
								text={'Maksymalna kwota kredytu. Wysokość finansowania zależy od indywidualnej oceny zdolności kredytowej wnioskodawcy.'}
								sx={{
									marginLeft: '8px',
									marginBottom: '10px',
									zIndex: '100',
									width: '24px',
									height: '24px',
								}}
								sxTooltipContent={{
									'& .MuiTooltip-tooltip': {
										maxWidth: '396px',
										color: 'black',
										textAlign: 'center',
									},
								}}
							/>
						</span>
						<br /> bez wychodzenia z domu.
					</Typography>
					<Grid item xs={gridFullWidth} sx={{ marginTop: { xs: '32px', md: '40px' }, marginBottom: '12px' }}>
						<Typography variant='p3' sx={{ color: '#FFFFFF', fontWeight: '600' }}>
							Wybierz okres spłaty:
						</Typography>
					</Grid>
					<CreditPeriodsContainer>
						<Grid
							sx={{
								width: '100%',
								maxWidth: '368px',
								display: 'flex',
								flexDirection: 'row',
							}}
							key='period_container'
							item
							xs={gridFullWidth}
						>
							{lightLandingPageContext.initialData?.periods
								?.sort((a, b): number => a - b)
								.map(
									(period, index): JSX.Element => (
										<Button
											key={`period_${period}`}
											variant='primaryButton'
											onClick={(): void => lightLandingPageContext.onCreditPeriodChange(period)}
											disabled={lightLandingPageContext.calculateLoanIsLoading}
											sx={{
												width: '100%',
												minWidth: '36px',
												borderRadius: '4px',
												marginRight:
													lightLandingPageContext.initialData?.periods.length && index < lightLandingPageContext.initialData?.periods.length - 1
														? '24px'
														: '0',
												height: '53px',
												...(period !== lightLandingPageContext.creditPeriod
													? {
															backgroundColor: 'rgba(172, 211, 247, 0.15)' + ' !important',
															color: mainTheme.palette.primary.main,
													  }
													: {}),
											}}
										>
											{period}
										</Button>
									)
								)}
						</Grid>
					</CreditPeriodsContainer>

					<Grid
						item
						xs={gridFullWidth}
						sx={{
							marginTop: '32px',
							marginBottom: '32px',
							display: 'flex',
							flexWrap: 'wrap',
							alignItems: 'center',
						}}
					>
						<Typography
							variant='p1'
							sx={{
								color: '#FFFFFF',
								margin: '0 8px 0 0',
								whiteSpace: 'nowrap',
								fontFamily: 'Athletics, sans-serif',
							}}
						>
							Szacowana rata miesięczna:
						</Typography>
						<Typography
							variant='p1'
							sx={{
								color: mainTheme.palette.primary.main,
								fontSize: '24px',
								whiteSpace: 'nowrap',
								margin: '0 0 4px 0',
								fontFamily: 'Athletics, sans-serif',
							}}
						>
							{lightLandingPageContext.installment.toString().replace('.', ',')} PLN
						</Typography>
					</Grid>
					<NestCollapse
						sx={{ marginBottom: '30px' }}
						title={'Nota prawna'}
						content={collapseContent}
						contentTitle={'Nota prawna'}
						sxContent={{ maxWidth: '550px' }}
					/>
				</WiderContainer>
			</CreditInfoSectionContainer>
		</CreditInfoSectionContainerBackground>
	);
};

export default LightCreditInfoSection;
