import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import React, { useEffect, useState } from 'react';
import { Control, UseFormReturn } from 'react-hook-form';
import { CountryDto } from '../../../../../../common/api/DictionaryApi';
import { blockWritingCurrencyMoreThanOneBilliard, onlyNumbersMaxFour } from '../../../../../../common/helpers/block-writing-keys-helper';
import { amountDynamicMask, upperCaseMask } from '../../../../../../common/helpers/mask-helper';
import { sortAndMapCountries } from '../../../../../../common/helpers/sort-helper';
import { firstAndLastNameValidator } from '../../../../../../common/validators/firstAndLastNameValidator';
import { idCardIssuedDateValidator, tenYearsAgoMinusOneDay } from '../../../../../../common/validators/idCardIssuedDateValidator';
import { isIdCardNumberValidator } from '../../../../../../common/validators/idCardNumberValidator';
import { polishWordsAndDashValidator } from '../../../../../../common/validators/polishWordsValidator';
import NestDatePicker from '../../../../../../components/FormItems/NestDatePicker/NestDatePicker';
import NestInput from '../../../../../../components/FormItems/NestInput/NestInput';
import NestSelect from '../../../../../../components/FormItems/NestSelect/NestSelect';
import { gridFullWidth, gridHalfWidth } from '../../../../../../theme/grid/GridConstants';
import {
	maritalStatusOptions,
	unemployedDependantPeopleLabel,
	unemployedDependantPeopleWithoutSpouseLabel,
} from '../../consts/LightPersonalDataConstants';

interface ILightPersonalDataInfoAboutYou {
	methods: UseFormReturn<Record<string, unknown>>;
	countries: CountryDto[];
}

const LightPersonalDataInfoAboutYou = (props: ILightPersonalDataInfoAboutYou): JSX.Element => {
	const [unemployedDependantPeopleAmountLabel, setUnemployedDependantPeopleAmountLabel] = useState(unemployedDependantPeopleLabel);

	const watchIdCardIssuedDate = props.methods.watch('idCardIssuedDate') as Date | undefined;
	const watchMartialStatus = props.methods.watch('maritalStatus');
	const uniqueDateRangeAugust = new Date(2021, 7, 3).getTime();
	const uniqueDateRangeNovember = new Date(2021, 10, 6).getTime();

	useEffect((): void => {
		if(watchMartialStatus === 2){
			setUnemployedDependantPeopleAmountLabel(unemployedDependantPeopleWithoutSpouseLabel);
		}
		else {
			setUnemployedDependantPeopleAmountLabel(unemployedDependantPeopleLabel);
		}
	}, [watchMartialStatus]);

	const setExpirationDateBasedOnIssuedDate = (): void => {
		props.methods.setValue('idCardExpirationDate', null);
		if (!watchIdCardIssuedDate) {
			return;
		}
		if (watchIdCardIssuedDate.getTime() <= uniqueDateRangeNovember && watchIdCardIssuedDate.getTime() >= uniqueDateRangeAugust) {
			props.methods.setValue('idCardExpirationDate', new Date(2031, 7, 3));
			return;
		}
		const exactYearDifferenceInIssuedAndExpirationDates = 10;
		const issuedDate = new Date(watchIdCardIssuedDate);
		const maxExpirationDateBasedOnIssuedDate = new Date(
			issuedDate.getFullYear() + exactYearDifferenceInIssuedAndExpirationDates,
			issuedDate.getMonth(),
			issuedDate.getDate()
		);
		if (maxExpirationDateBasedOnIssuedDate.getTime() < new Date().getTime()) {
			return;
		}
		props.methods.setValue('idCardExpirationDate', maxExpirationDateBasedOnIssuedDate);
	};

	useEffect((): void => {
		setExpirationDateBasedOnIssuedDate();
	}, [watchIdCardIssuedDate]);

	return (
		<>
			<Typography
				variant='h2'
				sx={{
					padding: { xs: '0 0 32px 0', md: '0 0 43px 0' },
					fontSize: { xs: '28px', md: '34px' },
					lineHeight: { xs: '33px', md: '40px' },
				}}
			>
				Informacje o Tobie
			</Typography>
			<Grid container rowSpacing={3} columnSpacing={9} sx={{ marginBottom: { md: '80px', xs: '0' } }}>
				<Grid item xs={gridFullWidth} md={gridHalfWidth}>
					<NestInput disabled={true} label='Imię' control={props.methods.control as unknown as Control} name='firstName' />
				</Grid>
				<Grid item xs={gridFullWidth} md={gridHalfWidth}>
					<NestInput disabled={true} label='Nazwisko' control={props.methods.control as unknown as Control} name='lastName' />
				</Grid>
				<Grid item xs={gridFullWidth} md={gridHalfWidth}>
					<NestInput label='PESEL' control={props.methods.control as unknown as Control} name='socialSecurityNumber' disabled={true} />
				</Grid>
				<Grid item xs={gridFullWidth} md={gridHalfWidth}>
					<NestInput
						label='Seria i nr dowodu osobistego'
						control={props.methods.control as unknown as Control}
						name='idCard'
						rules={{
							validate: isIdCardNumberValidator,
							required: 'Pole wymagane',
						}}
						mask=""
						maskFunc={upperCaseMask}
						useMaskAsValue={true}
					/>
				</Grid>
				<Grid item xs={gridFullWidth} md={gridHalfWidth}>
					<NestDatePicker
						name='idCardIssuedDate'
						label='Data wydania dowodu osobistego'
						control={props.methods.control}
						maxDate={new Date()}
						minDate={tenYearsAgoMinusOneDay()}
						setValue={props.methods.setValue}
						zIndex={3}
						rules={{
							required: 'Pole wymagane',
							validate: idCardIssuedDateValidator,
						}}
						errors={props.methods.formState.errors.idCardIssuedDate as { type: string; message: string } | undefined}
					/>
				</Grid>
				<Grid item xs={gridFullWidth} md={gridHalfWidth}>
					<NestDatePicker
						name='idCardExpirationDate'
						label='Data ważności dowodu osobistego'
						control={props.methods.control}
						setValue={props.methods.setValue}
						disabled
					/>
				</Grid>
				<Grid item xs={gridFullWidth} md={gridHalfWidth}>
					<NestInput
						label='Miejscowość urodzenia'
						control={props.methods.control as unknown as Control}
						name='cityOfBirth'
						rules={{
							minLength: { value: 2, message: 'Niepoprawna wartość' },
							required: 'Pole wymagane',
							pattern: {
								value: polishWordsAndDashValidator,
								message: 'Niepoprawna wartość',
							},
						}}
					/>
				</Grid>
				<Grid item xs={gridFullWidth} md={gridHalfWidth}>
					<NestSelect
						control={props.methods.control as unknown as Control}
						name='countryOfBirth'
						label='Kraj urodzenia'
						options={sortAndMapCountries(props.countries)}
						placeholderOption={['Wybierz kraj urodzenia', '']}
						defaultValue='Polska'
						setValue={props.methods.setValue}
						errors={props.methods.formState.errors.countryOfBirth as { type: string; message: string } | undefined}
						rules={{
							required: 'Pole wymagane',
						}}
						zIndex={2}
					/>
				</Grid>
				<Grid item xs={gridFullWidth} md={gridHalfWidth}>
					<NestInput
						label='Nazwisko panieńskie matki'
						control={props.methods.control as unknown as Control}
						name='mothersMaidenName'
						rules={{
							minLength: { value: 2, message: 'Niepoprawna wartość' },
							required: 'Pole wymagane',
							pattern: {
								value: firstAndLastNameValidator,
								message: 'Niepoprawna wartość',
							},
						}}
					/>
				</Grid>
				<Grid item xs={gridFullWidth} md={gridHalfWidth}>
					<NestSelect
						rules={{
							required: 'Pole wymagane',
						}}
						control={props.methods.control as unknown as Control}
						name='maritalStatus'
						label='Stan cywilny'
						options={maritalStatusOptions}
						errors={props.methods.formState.errors.maritalStatus as { type: string; message: string } | undefined}
						defaultValue={0}
						placeholderOption={['Wybierz stan cywilny', 0] as [string, string | number]}
					/>
				</Grid>
				<Grid item xs={gridFullWidth} md={gridHalfWidth}>
					<NestInput
						label='Miesięczne koszty utrzymania (np. czynsz, media)'
						control={props.methods.control as unknown as Control}
						maskFunc={amountDynamicMask}
						mask=''
						name='monthlyLivingCosts'
						rules={{
							required: 'Pole wymagane',
						}}
						currencyTextOrPlainText='PLN'
						blockWritingSomeValueFunc={blockWritingCurrencyMoreThanOneBilliard}
					/>
				</Grid>
				<Grid item xs={gridFullWidth} md={gridHalfWidth}>
					<NestInput
						label={unemployedDependantPeopleAmountLabel}
						control={props.methods.control as unknown as Control}
						name='unemployedDependantPeopleAmount'
						rules={{
							required: 'Pole wymagane',
						}}
						blockWritingSomeValueFunc={onlyNumbersMaxFour}
						blockPasteEvent
					/>
				</Grid>
				<Grid item xs={gridFullWidth} md={gridHalfWidth}>
					<NestInput
						label='Miesięczna wysokość innych obciążeń (np. alimenty, raty pożyczek preferencyjnych [Tarcza antykryzysowa/PFR/zaliczki zwrotne])'
						control={props.methods.control as unknown as Control}
						name='otherMonthlyCosts'
						maskFunc={amountDynamicMask}
						mask=''
						rules={{
							required: 'Pole wymagane',
						}}
						blockWritingSomeValueFunc={blockWritingCurrencyMoreThanOneBilliard}
						currencyTextOrPlainText='PLN'
					/>
				</Grid>
			</Grid>
		</>
	);
};

export default LightPersonalDataInfoAboutYou;
