import { useState } from 'react';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { typography } from '../../../theme/typography/typography';
import { ButtonContainer } from '../rbf-data-consistency/DataConsistency.css';
import {
	GetPsd2BanksConfig,
	getInitiateConfig,
} from './IdentityVerificationApi';
import useFetch from '../../../common/api/UseFetch';
import Loader from '../../../components/Loader/Loader';
import { useNavigate } from 'react-router-dom';
import { CalculatorRoute } from '../../../routing/Routing';
import CustomButton from '../../../components/CustomButton/CustomButton';
import { ScrollToTopOnMount } from '../../../common/helpers/scroll-to-top-onmount';
import IdentityVerificationBanksList from 'src/components/IdentityVerificationBanksList/IdentityVerificationBanksList';

export default function IdentityVerification(): JSX.Element {
	const [bankId, setBankId] = useState<string>('');

	const navigate = useNavigate();

	const banksList = useFetch(GetPsd2BanksConfig, true);
	const initiateResult = useFetch(getInitiateConfig, false, (resp): void => {
		if (resp.redirectUrl) {
			window.location.href = resp.redirectUrl;
		}
	});

	return (
		<Stack sx={{ alignItems: 'center', padding: '0 16px' }}>
			{banksList.isPending || banksList.isIdle ? (
				<Loader />
			) : (
				<>
					<ScrollToTopOnMount />
					<Stack sx={{ minHeight: 'inherit', maxWidth: '880px' }}>
						<Typography
							sx={{
								fontSize: { xs: '28px', md: '34px' },
								lineHeight: { xs: '34px', md: '40px' },
							}}
							variant='h2'
						>
							Weryfikacja tożsamości
						</Typography>
						<ol
							style={{
								padding: '0',
								margin: '16px 0 16px 18px',
								...typography.p4,
								fontFamily: 'Inter, sans-serif',
								lineHeight: '22px',
							}}
						>
							<li>Wybierz bank, w którym masz konto osobiste.</li>
							<li>
								Po kliknięciu "Dalej" zostaniesz bezpiecznie przekierowany do
								weryfikacji tożsamości.
							</li>
						</ol>

						<IdentityVerificationBanksList
							banksList={banksList.data ?? []}
							setBankId={setBankId}
						/>

						<ButtonContainer sx={{ justifyContent: 'space-between' }}>
							<Button
								variant='outlined'
								onClick={(): void => navigate(CalculatorRoute.path)}
							>
								Cofnij
							</Button>
							<CustomButton
								isLoading={initiateResult.isPending}
								variant='contained'
								disabled={!bankId}
								onClick={(): void => initiateResult.mutate({ bankId: bankId })}
							>
								Dalej
							</CustomButton>
						</ButtonContainer>
					</Stack>
				</>
			)}
		</Stack>
	);
}
