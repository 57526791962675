import { ILightIndexContactDataProperties } from '../../LightIndexCreditInfoAndContactDataFormModels';

export const defaultValues: ILightIndexContactDataProperties = {
	nip: '',
	email: '',
	phoneNumber: '',
	firstName: '',
	lastName: '',
	magicWords: '',
	brokerId: null
};
