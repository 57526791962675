import { styled } from '@mui/system';
import { buttonsBreakpoint } from '../../../theme/breakpoints/breakpoints';
import { mainTheme } from '../../../theme/mainTheme';
import Button from '@mui/material/Button';

export const PersonalDataMainContainer = styled('div')({
	maxWidth: '830px',
	minHeight: 'inherit',
	margin: '0 auto',
	padding: '0 24px',
});

export const Form = styled('form')({
	width: '100%',
	justifyContent: 'space-between',
	display: 'flex',
	flexWrap: 'wrap',
	alignItems: 'center',
});

export const ButtonsContainer = styled('div')({
	width: '100%',
	margin: '30px 0 80px 0',
	display: 'flex',
	justifyContent: 'space-between',
	alignItems: 'flex-end',

	[mainTheme.breakpoints.down(buttonsBreakpoint as number)]: {
		flexDirection: 'column',
		alignItems: 'center',
	},
});

export const ButtonSubmitContainer = styled('div')({
	display: 'flex',
	flexDirection: 'column',
	textAlign: 'center',
	alignItems: 'center',
});

export const ButtonSubmitTextContainer = styled('div')({
	display: 'flex',
	width: '100%',
	justifyContent: 'flex-end',
	[mainTheme.breakpoints.down(buttonsBreakpoint as number)]: {
		justifyContent: 'center',
	},
});

export const PrevButton = styled(Button)({
	marginBottom: 0,
	[mainTheme.breakpoints.down(buttonsBreakpoint as number)]: {
		marginBottom: '33px',
	},
});
