import { useEffect } from 'react';
import useFetch from '../../common/api/UseFetch';
import { featureFlags, getSessionStorageObj, setSessionStorageObj } from '../../common/sessionStorage/SessionStorageService';
import { CreateComponentContext } from '../CreateComponentContext';
import { GetFeatureFlagConfig } from './api/FeatureFlagContextApi';
import { FeatureFlagProperties, FeatureFlagsEnum, IFeatureFlagContextState } from './models/FeatureFlagContextModels';

const featureFlagContextHandler = (): IFeatureFlagContextState => {
	const featureFlagsData = useFetch(GetFeatureFlagConfig, false, (data: FeatureFlagProperties): void => {
		setSessionStorageObj(featureFlags, data);
	});
	useEffect((): void => {
		if (getSessionStorageObj(featureFlags)) {
			return;
		}
		featureFlagsData.mutate({} as never);
	}, []);

	const getFeatureFlag = (name: FeatureFlagsEnum): boolean | undefined => {
		const featureFlag: FeatureFlagProperties | undefined = getSessionStorageObj(featureFlags);
		return featureFlag?.[name] as boolean;
	};

	return {
		getFeatureFlag: (name: FeatureFlagsEnum): boolean | undefined => getFeatureFlag(name),
	};
};

export const [FeatureFlagContext, FeatureFlagContextProvider] = CreateComponentContext<IFeatureFlagContextState>(
	{} as IFeatureFlagContextState,
	featureFlagContextHandler
);
