import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Stack from '@mui/system/Stack/Stack';
import infoIconWithCyrcle from '../../../../common/images/infoIconWithCyrcle.svg';
import onePageInfoIcon from '../../../../common/images/onePageInfoIcon.svg';
import { textColorPrimary } from '../../../../theme/palette/palette';

const LightOvdSummaryPage = (): JSX.Element => {
	const agreeButtonAction = (): void => {
		window.location.href = 'https://nestbank.pl';
	};

	return (
		<Stack sx={{ alignItems: 'center', minHeight: 'inherit', padding: '0 16px', justifyContent: { xs: 'space-around', md: 'center' } }}>
			<Stack sx={{ alignItems: 'center', justifyContent: 'center' }}>
				<Stack sx={{ width: '149px', height: '147px', marginBottom: { xs: '32px', md: '80px' } }}>
					<img src={onePageInfoIcon ?? infoIconWithCyrcle} alt='Info icon' />
				</Stack>
				<Typography
					variant='h3'
					sx={{
						justifyContent: 'space-between',
						fontSize: { xs: '24px', md: '28px' },
						lineHeight: { xs: '28.8px', md: '33.6px' },
						fontWeight: '700',
						maxWidth: '680px',
						textAlign: 'center',
						color: textColorPrimary,
					}}
				>
					Po aktywacji konta wróć do wniosku poprzez
				</Typography>
				<Typography
					component='a'
					href='https://online.nestbank.pl/powrot'
					variant='h3'
					sx={{
						fontSize: { xs: '24px', md: '28px' },
						maxWidth: '557px',
						fontWeight: '400',
						textAlign: 'center',
						textDecoration: 'none',
						color: 'inherit',
						cursor: 'pointer',
					}}
				>
					online.nestbank.pl/powrot
				</Typography>
				<Typography
					variant='h3'
					sx={{
						marginBottom: { md: '48px' },
						fontSize: { xs: '24px', md: '28px' },
						lineHeight: { xs: '28.8px', sm: '33.6px' },
						fontWeight: '700',
						maxWidth: '680px',
						textAlign: 'center',
						color: textColorPrimary,
					}}
				>
					i dokończ wniosek
				</Typography>
			</Stack>
			<Box>
				<Button variant='contained' sx={{ marginBottom: '60px' }} onClick={agreeButtonAction} disabled={false}>
					{'Rozumiem'}
				</Button>
			</Box>
		</Stack>
	);
};

export default LightOvdSummaryPage;
