import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Stack from '@mui/system/Stack/Stack';
import envelopeImage from '../../../common/images/envelope.svg';
import { useEffect } from 'react';
import { pushLightEventVirtualpvVerification } from 'src/common/googleAnalyticsEvents/googleAnalyticsEvents';

export default function Verification(): JSX.Element {
	useEffect((): void => {
		pushLightEventVirtualpvVerification();
	}, []);

	return (
		<Stack
			sx={{
				justifyContent: 'center',
				minHeight: 'inherit',
				alignItems: 'center',
				padding: '0 16px',
				margin: 'auto',
				maxWidth: '640px',
			}}
		>
			<Stack
				sx={{
					flex: { xs: '1', sm: '0' },
					display: 'flex',
					flexDirection: 'column',
					justifyContent: 'center',
					alignItems: 'center',
				}}
			>
				<Stack sx={{ width: '96px', height: '96px' }}>
					<img src={envelopeImage} alt='envelope Image' />
				</Stack>

				<Typography
					variant='h2'
					sx={{
						margin: '64px 0 48px 0',
						textAlign: 'center',
						fontSize: { md: '28px', xs: '24px' },
						lineHeight: { md: '34px', xs: '29px' },
					}}
				>
					Dziękujemy za złożenie wniosku
				</Typography>

				<Typography
					variant='p1'
					sx={{
						textAlign: 'center',
					}}
				>
					Możesz wrócić do swoich spraw, a my zajmiemy się Twoim wnioskiem. <br />O decyzji poinformujemy Cię mailowo oraz SMSem.{' '}
					<Stack component={'div'} sx={{ display: { sm: 'block', xs: 'none' } }}></Stack>
					Odpowiedź otrzymasz w ciągu 2 dni roboczych. <br />
					Jesteśmy w kontakcie!
				</Typography>
			</Stack>

			<Stack sx={{ flex: '0', margin: '48px 0 32px 0' }}>
				<Button
					variant='contained'
					onClick={(event): void => {
						event.preventDefault();
						(window.location.href = 'https://nestbank.pl'), true;
					}}
					type='button'
				>
					Rozumiem
				</Button>
			</Stack>
		</Stack>
	);
}
