import { styled } from '@mui/system';
import { mainTheme } from '../../../../../../../theme/mainTheme';
import { textColorPrimary } from '../../../../../../../theme/palette/palette';
import creditInfoSectionBg from '../../../../../../../common/images/creditInfoSectionBg.svg';

export const CreditInfoSectionContainerBackground = styled('div')({
	width: '100%',
	position: 'relative',
	minHeight: '330px',
	marginTop: '-75px',
	paddingTop: '75px',
	backgroundImage: `url(${creditInfoSectionBg})`,
	backgroundSize: 'cover',
	backgroundPosition: 'left',
	backgroundPositionX: '-280px',
	backgroundRepeat: 'no-repeat',

	[mainTheme.breakpoints.down('lg')]: {
		background: textColorPrimary,
		marginTop: '-110px',
		paddingTop: '110px',
	},

	[mainTheme.breakpoints.down('md')]: {
		background: textColorPrimary,
		marginTop: '-124px',
		paddingTop: '110px',
	},

	[mainTheme.breakpoints.up('lg')]: {
		backgroundPositionX: '-250px',
	},

	[mainTheme.breakpoints.up(1600)]: {
		backgroundPositionX: '-360px',
	},
});

export const CreditInfoSectionContainer = styled('div')({
	width: '100%',
});

export const CreditInfoSectionBackgroundImage = styled('div')({
	display: 'none',
	position: 'absolute',
	zIndex: '1',

	[mainTheme.breakpoints.up('lg')]: {
		display: 'block',
		right: '-2%',
		bottom: '0',
	},
	[mainTheme.breakpoints.up(1300)]: {
		right: '0%',
	},
	[mainTheme.breakpoints.up(1500)]: {
		right: '3%',
	},
	[mainTheme.breakpoints.up(1600)]: {
		right: '9%',
	},
	[mainTheme.breakpoints.up('xl')]: {
		right: '10%',
	},
	[mainTheme.breakpoints.up(2200)]: {
		right: '11%',
	},
	[mainTheme.breakpoints.up(2400)]: {
		right: '12%',
	},
});

export const ButtonContainer = styled('div')({
	display: 'flex',
	justifyContent: 'center',
	marginBottom: '60px',
	[mainTheme.breakpoints.up('sm')]: {
		justifyContent: 'flex-start',
	},
});

export const PersonBackground = styled('img')({
	height: '415px'
})

export const SpanPln = styled('span')({})
