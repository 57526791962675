import { Typography, useMediaQuery } from '@mui/material';
import { useTheme } from '@mui/system';
import { BreadcrumbStep } from '../../../routing/RouteSpec';
import {
	septenaryMainColor,
	textColorPrimary,
} from '../../../theme/palette/palette';
import {
	Breadcrumb,
	BreadcrumbContainer,
	BreadcrumbsContainer,
} from './Breadcrumbs.css';
import { breadcrumbsData } from './BreadcrumbsConstants';

interface BreadcrumbsProps {
	breadcrumbStep: BreadcrumbStep;
}

export interface IBreadcrumbData {
	name: BreadcrumbStep;
	step: number;
}

const Breadcrumbs = (props: BreadcrumbsProps): JSX.Element => {
	const theme = useTheme();
	const showTextOnMobile = useMediaQuery(theme.breakpoints.down('md'));
	const showTextOnDesktop = useMediaQuery(theme.breakpoints.up('md'));

	const checkThatIsPreviousStep = (
		breadcrumb: IBreadcrumbData,
		propBreadcrumbStepName: BreadcrumbStep
	): boolean | undefined => {
		const getCurrentStepByName: IBreadcrumbData | undefined =
			breadcrumbsData.find(
				(i: IBreadcrumbData): boolean => i.name === propBreadcrumbStepName
			);
		if (getCurrentStepByName?.step) {
			return breadcrumb.step < getCurrentStepByName.step;
		}
	};

	return (
		<>
			{props.breadcrumbStep !== BreadcrumbStep.DOESNT_EXIST && (
				<BreadcrumbsContainer
					theme={theme}
					sx={{
						margin: { xs: '0 auto 32px auto', md: '20px auto 50px auto' },
						padding: { xs: '0 7px', sm: '0' },
					}}
				>
					{breadcrumbsData.map((breadcrumb: IBreadcrumbData): JSX.Element => {
						const checkThatIsCurrentStep: boolean =
							breadcrumb.name === props.breadcrumbStep;
						const breadcrumbNameColor: string =
							checkThatIsPreviousStep(breadcrumb, props.breadcrumbStep) ||
							checkThatIsCurrentStep
								? textColorPrimary
								: septenaryMainColor;

						return (
							<div key={breadcrumb.name}>
								{breadcrumb.name !== BreadcrumbStep.COMPLETED && (
									<BreadcrumbContainer
										key={breadcrumb.step}
										theme={theme}
										sx={{
											flexDirection: { xs: 'row', md: 'column' },
										}}
									>
										<Breadcrumb
											theme={theme}
											sx={{
												width: {
													md: checkThatIsCurrentStep ? '138px' : '142px',
													xs: checkThatIsCurrentStep ? '20px' : '24px',
												},
												height: {
													md: checkThatIsCurrentStep ? '4px' : '8px',
													xs: checkThatIsCurrentStep ? '20px' : '24px',
												},
												borderRadius: { md: '8px', xs: '50%' },
											}}
											step={breadcrumb.step}
											current={checkThatIsCurrentStep}
											previous={checkThatIsPreviousStep(
												breadcrumb,
												props.breadcrumbStep
											)}
										/>

										{showTextOnMobile && (
											<Typography
												variant='h9'
												sx={{
													color: breadcrumbNameColor,
													margin: { sm: '0 0 0 7px', xs: '0 0 0 2px' },
													fontWeight: 600,
												}}
											>
												{breadcrumb.step ===
													breadcrumbsData[breadcrumbsData.length - 2].step &&
												props.breadcrumbStep === BreadcrumbStep.COMPLETED
													? breadcrumbsData[breadcrumbsData.length - 2].name
													: checkThatIsCurrentStep
													? breadcrumb.name
													: null}
											</Typography>
										)}

										{showTextOnDesktop && (
											<Typography
												variant='h9'
												sx={{
													color: breadcrumbNameColor,
													margin: { sm: '7px 0 0 0' },
													fontWeight: 600,
												}}
											>
												{breadcrumb.name}
											</Typography>
										)}
									</BreadcrumbContainer>
								)}
							</div>
						);
					})}
				</BreadcrumbsContainer>
			)}
		</>
	);
};

export default Breadcrumbs;
