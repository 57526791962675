import { Switch, Typography } from '@mui/material';
import {
	AgreementContainer,
	AgreementHeadingContainer,
	AgreementTextContainer,
} from './Agreement.css';
import { useDispatch } from 'react-redux';
import { changeConsents } from '../../../store/slices/agreementsSlice/AgreementsSlice';
import { AgreementModel, MediaConsent } from '../AgreementsModels';

interface AgreementProps {
	agreement: AgreementModel;
	mediaIndex?: number;
	agreementIndex: number;
	sectionIndex: number;
}

export default function MediaAgreement(props: Readonly<AgreementProps>): JSX.Element {
	const dispatch = useDispatch();

	const handleMediaConsent = (
		event: React.ChangeEvent<HTMLInputElement>,
		index?: number
	): void => {
		dispatch(
			changeConsents({
				sectionIndex: props.sectionIndex,
				agreementIndex: props.agreementIndex,
				isSelected: event.target.checked,
				mediaIndex: index,
				mainConsent: false,
			})
		);
	};

	return (
		<AgreementContainer
			sx={{
				marginBottom: '25px',
				paddingLeft: { xs: 0, md: 0 },
			}}
		>
			{props.agreement.mediaConsents?.length ? (
				<>
					<Typography
						variant='p1'
						sx={{
							display: 'inline-block',
							maxWidth: { md: '525px', xs: '485px' },
							marginBottom: '20px',
							paddingLeft: { xs: '0', md: '0' },
							paddingRight: { md: '50px', xs: '70px' },
						}}
					>
						{props.agreement.content}
					</Typography>
					{!!props.agreement.mediaConsents.length &&
						props.agreement.mediaConsents.map(
							(x: MediaConsent, index: number): JSX.Element => (
								<AgreementTextContainer
									key={x.symbol}
									sx={{
										margin: '0 0 32px 0',
										paddingLeft: { xs: '0', md: '0' },
									}}
								>
									<AgreementHeadingContainer>
										<Typography
											variant='p1'
											sx={{
												display: 'inline-block',
												maxWidth: '485px',
											}}
										>
											{x.content}
										</Typography>
										{!props.agreement.mediaConsents ||
											(props.agreement.mediaConsents[index].isError && (
												<Typography
													variant='h9'
													sx={{ color: 'error.main', margin: '7px 0 0 0' }}
												>
													Ta zgoda jest wymagana
												</Typography>
											))}
									</AgreementHeadingContainer>
									<Switch
										sx={{ marginLeft: '40px' }}
										// eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
										checked={x.isSelected ?? false}
										onChange={(
											event: React.ChangeEvent<HTMLInputElement>
										): void => handleMediaConsent(event, index)}
									/>
								</AgreementTextContainer>
							)
						)}
				</>
			) : (
				<></>
			)}
		</AgreementContainer>
	);
}
