import { Container } from './AgreementCollection.css';
import { useSelector } from 'react-redux';
import { RootState } from '../../store/State';
import { AgreementModel, AgreementSectionModel } from './AgreementsModels';
import { AgreementContainer } from './Agreement/Agreement.css';
import MediaAgreement from './Agreement/MediaAgreement';

export default function MediaAgreementCollection(): JSX.Element {
	const agreements = useSelector(
		(state: RootState): AgreementSectionModel[] => state.agreements.agreements
	);
	return (
		<Container sx={{ maxWidth: { xs: '100%', md: '70%' } }}>
			{agreements.map(
				(x: AgreementSectionModel, sectionId: number): JSX.Element => (
					<AgreementContainer sx={{ marginBottom: '25px' }} key={x.section}>
						{x.consentGroup.map(
							(i: AgreementModel, idGroup: number): JSX.Element => (
								<MediaAgreement
									sectionIndex={sectionId}
									agreementIndex={idGroup}
									agreement={i}
									key={i.symbol}
								/>
							)
						)}
					</AgreementContainer>
				)
			)}
		</Container>
	);
}
