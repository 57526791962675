import Typography from '@mui/material/Typography';
import { Container } from '../../../common/styles/Layout.css';
import {
	ButtonContainer,
	HexListItem,
	HexListItemNumber,
	InputContainer,
	HexIconContainer,
	ContentContainer,
	InfoSmsSectionContainer,
	InfoIcon,
	InfoTextSpan,
} from './ReturnProposal.css';
import NestInput from '../../../components/FormItems/NestInput/NestInput';
import { Control, FormProvider, useForm } from 'react-hook-form';
import { defaultValues, ReturnProposalProperties, SmsVerificationResponse } from './ReturnProposalContants';
import { nipValidator } from '../../../common/validators/nipValidator';
import useFetch from '../../../common/api/UseFetch';
import { VerifyNipAndSendSms, VerifySms } from './ReturnProposalApi';
import { useEffect, useState } from 'react';
import { mainTheme } from '../../../theme/mainTheme';
import {
	applicationIdKey,
	applicationNumberKey,
	applicationProcessKey,
	authTokenKey,
	marketingPartnerIdKey,
	partnerLogoKey,
	partnerNameKey,
	processIdKey,
	removeSessionStorageValue,
	setSessionStorageValue,
	tokenExpirationKey,
	userIdKey,
} from '../../../common/sessionStorage/SessionStorageService';
import { Routing, TechnicalErrorRoute } from '../../../routing/Routing';
import { useNavigate } from 'react-router-dom';
import CustomButton from '../../../components/CustomButton/CustomButton';
import { simpleDigitsMask, smsCodeMaskHelper } from '../../../common/helpers/mask-helper';
import { getPartnerLogo } from '../../../common/helpers/get-partner-logo-helper';
import { ScrollToTopOnMount } from '../../../common/helpers/scroll-to-top-onmount';
import infoIcon from '../../../common/images/infoIcon.svg';

import { SMS_CODE_TIMEOUT } from 'src/common/constants/timeouts';
import { textColorSecondary } from 'src/theme/palette/palette';

export default function ReturnProposal(): JSX.Element {
	const navigate = useNavigate();
	const [smsButtonDisabled, setSmsButtonDisabled] = useState(false);
	const [showSmsSection, setShowSmsSection] = useState(false);
	const [showInfoSmsSection, setShowInfoSmsSection] = useState(false);
	const verifyNipAndSendSms = useFetch(
		VerifyNipAndSendSms,
		false,
		(): void => {
			setShowSmsSection(true);
		},
		true
	);
	const verifySms = useFetch(
		VerifySms,
		false,
		(data: SmsVerificationResponse): void => {
			setSessionStorageValue(applicationNumberKey, data.applicationNumber);
			setSessionStorageValue(marketingPartnerIdKey, data.marketingPartnerId);
			setSessionStorageValue(userIdKey, data.userId);
			data.partnerLogoName && setSessionStorageValue(partnerLogoKey, getPartnerLogo(data.partnerLogoName));
			setSessionStorageValue(authTokenKey, data.token);
			setSessionStorageValue(applicationIdKey, data.applicationId);
			setSessionStorageValue(partnerNameKey, data.partnerName);
			setSessionStorageValue(processIdKey, data.processId);
			setSessionStorageValue(applicationProcessKey, data.process.toString());
			navigate(Routing.find((route): boolean => route.page === data.webPageToReturn)?.path ?? TechnicalErrorRoute.path);
		},
		false
	);
	const methods = useForm<ReturnProposalProperties>({
		mode: 'onSubmit',
		reValidateMode: 'onChange',
		defaultValues,
	});
	const watchNip = methods.watch('nip');
	const watchSmsCode = methods.watch('smsCode');

	const sendSmsAgain = (): void => {
		setSmsButtonDisabled(true);
		verifyNipAndSendSms.mutate(watchNip);
	};

	const onSubmit = (data: ReturnProposalProperties): void => {
		if (showSmsSection) {
			verifySms.mutate(data);
		} else {
			verifyNipAndSendSms.mutate(data.nip);
			setSmsButtonDisabled(true);
		}
	};

	useEffect((): void => {
		removeSessionStorageValue(tokenExpirationKey);
		removeSessionStorageValue(applicationIdKey);
		removeSessionStorageValue(authTokenKey);
		removeSessionStorageValue(processIdKey);
	}, []);

	useEffect((): (() => void) => {
		if (smsButtonDisabled) {
			const timeoutId = setTimeout((): void => {
				setSmsButtonDisabled(false);
			}, SMS_CODE_TIMEOUT);

			return (): void => {
				clearTimeout(timeoutId);
			};
		}
		return (): void => {};
	}, [smsButtonDisabled]);

	return (
		<Container>
			<ScrollToTopOnMount />
			<Typography
				sx={{
					margin: '64px 0 0 0',
					fontSize: { xs: '28px', md: '34px' },
					lineHeight: { xs: '33px', md: '40px' },
				}}
				variant='h2'
			>
				Powrót do wniosku
			</Typography>
			<FormProvider {...methods}>
				<form onSubmit={methods.handleSubmit(onSubmit)}>
					<ContentContainer>
						<HexListItem sx={{ marginTop: '28px' }}>
							<HexIconContainer>
								<HexListItemNumber>1</HexListItemNumber>
							</HexIconContainer>
							<Typography color='black' variant='p1' sx={{ paddingLeft: '10px' }}>
								Wpisz NIP
							</Typography>
						</HexListItem>
						<InputContainer>
							<NestInput
								disabled={showSmsSection}
								label='NIP'
								control={methods.control as unknown as Control}
								name='nip'
								rules={{
									validate: nipValidator,
									required: 'Pole wymagane',
								}}
								mask='### ### ## ##'
								maskFunc={simpleDigitsMask}
							/>
						</InputContainer>
						{showSmsSection && (
							<>
								<HexListItem sx={{ marginTop: '36px', alignItems: 'start' }}>
									<HexIconContainer sx={{ marginTop: '-4px' }}>
										<HexListItemNumber>2</HexListItemNumber>
									</HexIconContainer>
									<Typography color='black' variant='p1' sx={{ paddingLeft: '10px' }}>
										Wysłaliśmy do Ciebie wiadomość SMS ze specjalnym kodem autoryzacyjnym.
										<br /> Wpisz go i kontynuuj wypełnianie wniosku.
									</Typography>
								</HexListItem>
								<InputContainer>
									<NestInput
										label='Kod SMS'
										control={methods.control as unknown as Control}
										name='smsCode'
										maskFunc={smsCodeMaskHelper}
										mask=''
										rules={{
											required: 'Pole wymagane',
											maxLength: { value: 4, message: 'Niepoprawna wartość' },
											minLength: { value: 4, message: 'Niepoprawna wartość' },
										}}
										blockPasteEvent
									/>
									<Typography
										color={smsButtonDisabled ? textColorSecondary : mainTheme.palette.secondary.main}
										variant='h7'
										sx={{
											marginTop: '23px',
											cursor: smsButtonDisabled ? '' : 'pointer',
											fontFamily: 'Athletics, sans-serif',
										}}
										component={'div'}
										onClick={(): void => {
											!smsButtonDisabled && sendSmsAgain();
										}}
									>
										Wyślij kod ponownie
										{smsButtonDisabled}
									</Typography>
									<Typography
										color={mainTheme.palette.secondary.main}
										variant='h7'
										sx={{
											marginTop: '23px',
											cursor: 'pointer',
											fontFamily: 'Athletics, sans-serif',
										}}
										component={'div'}
										onClick={(): void => setShowInfoSmsSection(true)}
									>
										Nie otrzymałem kodu SMS
									</Typography>
								</InputContainer>
								{showInfoSmsSection && (
									<InfoSmsSectionContainer>
										<InfoIcon src={infoIcon} alt='Info Icon' />
										<Typography color='black' variant='p1'>
											<InfoTextSpan>Sprawdź czy wpisany NIP jest prawidłowy.</InfoTextSpan>
											<InfoTextSpan>Sprawdź czy masz włączony telefon z numerem, który podałeś we wniosku.</InfoTextSpan>
											<InfoTextSpan>Sprawdź czy jesteś w zasięgu sieci komórkowej.</InfoTextSpan>
											<InfoTextSpan>Sprawdź ustawienia swojego telefonu.</InfoTextSpan>
											<InfoTextSpan>Wyślij kod ponownie.</InfoTextSpan>
											<InfoTextSpan>Skontaktuj się z infolinią Banku.</InfoTextSpan>
										</Typography>
									</InfoSmsSectionContainer>
								)}
							</>
						)}
					</ContentContainer>
					<ButtonContainer>
						<CustomButton
							type='submit'
							variant='contained'
							isLoading={verifySms.isPending || verifyNipAndSendSms.isPending}
							disabled={showSmsSection && watchSmsCode === ''}
						>
							Dalej
						</CustomButton>
					</ButtonContainer>
				</form>
			</FormProvider>
		</Container>
	);
}
