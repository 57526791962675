export function nipValidator(nip: string): boolean | string {
	if (!nip) {
		return 'Pole wymagane';
	}

	const nipWithoutDashes = nip.replace(/-/g, '');
	const reg = /^[0-9]{10}$/;

	if (!reg.test(nipWithoutDashes)) {
		return 'Niepoprawny NIP';
	} else {
		const dig = ('' + nipWithoutDashes).split('');
		const controlSum =
			(6 * parseInt(dig[0], 10) +
				5 * parseInt(dig[1], 10) +
				7 * parseInt(dig[2], 10) +
				2 * parseInt(dig[3], 10) +
				3 * parseInt(dig[4], 10) +
				4 * parseInt(dig[5], 10) +
				5 * parseInt(dig[6], 10) +
				6 * parseInt(dig[7], 10) +
				7 * parseInt(dig[8], 10)) %
			11;
		if (parseInt(dig[9], 10) === controlSum) {
			return true;
		} else {
			return 'Niepoprawny NIP';
		}
	}
}
