import { styled } from '@mui/system';

export const VerificationMainContainer = styled('div')`
	display: flex;
	min-height: inherit;
	flex-direction: column;
`;

export const MainContainer = styled('div')`
	display: flex;
	flex-direction: column;
	min-height: inherit;
	justify-content: center;
	align-items: center;
	text-align: center;
`;
