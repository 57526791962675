import { Switch, Typography } from '@mui/material';
import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';
import React from 'react';
import { Control, UseFormReturn } from 'react-hook-form';
import { simpleDigitsMask } from '../../../../../../common/helpers/mask-helper';
import { nipValidator } from '../../../../../../common/validators/nipValidator';
import { peselValidator } from '../../../../../../common/validators/peselValidator';
import { companyNameValidator } from '../../../../../../common/validators/polishWordsValidator';
import NestInput from '../../../../../../components/FormItems/NestInput/NestInput';
import NestSelect from '../../../../../../components/FormItems/NestSelect/NestSelect';
import NestTooltip from '../../../../../../components/NestTooltip/NestTooltip';
import { gridFullWidth } from '../../../../../../theme/grid/GridConstants';
import { primaryMainColor, secondaryMainColor } from '../../../../../../theme/palette/palette';
import { economicLiabilityTypeOptions, nipOrPeselOptions, TypeOfIdentification } from '../../consts/LightPersonalDataConstants';
import plusViolet from '../../../../../../common/images/plus-violet.svg';
import closeIcon from '../../../../../../common/images/ic16-close.svg';
import minusViolet from '../../../../../../common/images/minus-violet.svg';
import { PlusIconContainer } from './EconomicLiablities.css';
import { EconomicLiabilitiesData, EconomicLiabilitiesErrors } from './models/EconomicLiabilitiesModels';

interface CreditAndEntitiesInfoProps {
	methods: UseFormReturn<Record<string, unknown>>;
}

const EconomicLiabilities = (props: CreditAndEntitiesInfoProps): JSX.Element => {
	const watchEconomicLiabilitiesData = props.methods.watch('economicLiabilities.data') as EconomicLiabilitiesData[];
	const watchEconomicLiabilitiesIsGiven = props.methods.watch('economicLiabilities.isGiven') as boolean;
	const economicLiabilitiesErrors = props.methods.formState.errors?.economicLiabilities as EconomicLiabilitiesErrors;

	const switchHandleChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
		props.methods.setValue('economicLiabilities.isGiven', event.target.checked as never);
	};

	const deleteRow = (index: string): void => {
		const filteredData = watchEconomicLiabilitiesData.filter((x: EconomicLiabilitiesData): boolean => {
			return x.id !== index;
		});
		props.methods.setValue('economicLiabilities.data', filteredData as never);
	};

	const addRow = (): void => {
		props.methods.setValue('economicLiabilities.data', [
			...watchEconomicLiabilitiesData,
			{
				id: self.crypto.randomUUID(),
				name: '',
				socialSecurityNumber: '',
				nipOrSocialSecurityNumber: '',
				nip: '',
				type: 'None',
			},
		] as never);
	};

	return (
		<>
			<Grid container sx={{ width: { md: '83%', xs: '100%' }, marginBottom: { xs: watchEconomicLiabilitiesIsGiven ? '0' : '32px', md: 0 } }}>
				<Grid
					item
					xs={gridFullWidth}
					sx={{
						paddingTop: '0',
						justifyContent: 'space-between',
					}}
					display='flex'
					alignItems='center'
				>
					<Stack sx={{ display: 'flex', alignItems: 'center', flexDirection: 'row', justifyContent: 'space-between', width: '100%' }}>
						<Typography variant='p1'>
							Podaję informację o posiadanych powiązaniach gospodarczych, ekonomicznych/kapitałowych lub osobowych{' '}
						</Typography>
						<NestTooltip
							sxTooltipContent={{ width: { xs: '300px', md: '500px' } }}
							sx={{ marginRight: '20px', marginLeft: '10px' }}
							text={
								<div>
									<b>Powiązania gospodarcze</b> - identyfikujemy, gdy zaprzestanie lub pogorszenie Twojej współpracy z inną firmą ma istotny wpływ na
									sytuację finansową Twojej firmy (dotyczy powiązań handlowych, świadczenia usług o udziale powyżej 50%).
									<br />
									<br />
									<b>Powiązania ekonomiczne/kapitałowe</b> - identyfikujemy, gdy inna firma bezpośrednio lub pośrednio wywiera znaczący wpływ na
									możliwość podejmowania decyzji przez Ciebie w zakresie wyznaczania kierunków działań finansowych i operacyjnych Twojej firmy, w tym
									również dotyczących podziału zysku z prowadzonej przez Ciebie działalności lub konieczności pokrycia straty finansowej innej firmy.
									<br />
									<br />
									<b>Powiązania osobowe</b> - identyfikujemy, gdy udział w kontroli lub zarządzaniu Twoją firmą posiadają inne, niż Ty, osoby (osoby z
									rodziny, w tym osoby pozostające w związku małżeńskim) lub kiedy osoba zatrudniona w Twojej firmie pełni funkcje zarządcze w innej
									firmie, z którą np. zawierana jest transakcja lub jakaś inna forma współpracy.
								</div>
							}
						/>
					</Stack>
					<Switch sx={{ margin: { md: '28px 0', xs: '0' } }} checked={watchEconomicLiabilitiesIsGiven} onChange={switchHandleChange} />
				</Grid>
			</Grid>
			{watchEconomicLiabilitiesIsGiven && (
				<>
					{watchEconomicLiabilitiesData?.map((item: EconomicLiabilitiesData, index: number): JSX.Element => {
						return (
							<Grid key={item.id} container rowSpacing={4} columnSpacing={3} sx={{ marginTop: { xs: 'unset', md: '-32px' } }}>
								<Grid item xs={gridFullWidth} md={4.5}>
									<NestInput
										label='Nazwa'
										control={props.methods.control as unknown as Control}
										name={`economicLiabilities.data[${index}].name`}
										wrapperSx={{ marginBottom: { xs: 0, md: '20px' } }}
										errors={
											economicLiabilitiesErrors?.data?.[index]?.name as {
												type: string;
												message: string;
											}
										}
										rules={{
											minLength: {
												value: 2,
												message: 'Niepoprawna wartość',
											},
											maxLength: {
												value: 128,
												message: 'Przekroczono maksymalną ilość znaków',
											},
											required: 'Pole wymagane',
											pattern: {
												value: companyNameValidator,
												message: 'Niepoprawna wartość',
											},
										}}
									/>
								</Grid>
								<Grid item xs={5} md={2}>
									<NestSelect
										rules={{ required: 'Pole wymagane' }}
										control={props.methods.control as unknown as Control}
										name={`economicLiabilities.data[${index}].nipOrSocialSecurityNumber`}
										label='NIP/PESEL'
										options={nipOrPeselOptions as [string, string | number][]}
										errors={
											economicLiabilitiesErrors?.data?.[index]?.nipOrSocialSecurityNumber as {
												type: string;
												message: string;
											}
										}
										placeholderOption={['Wybierz wartość', 0]}
									/>
								</Grid>
								{(props.methods.watch(`economicLiabilities.data[${index}].nipOrSocialSecurityNumber`) as TypeOfIdentification) ===
									TypeOfIdentification.NIP && (
									<Grid item xs={7} md={2.5}>
										<NestInput
											label='Numer NIP'
											control={props.methods.control as unknown as Control}
											name={`economicLiabilities.data[${index}].nip`}
											wrapperSx={{ marginBottom: { xs: 0, md: '20px' } }}
											maskFunc={simpleDigitsMask}
											mask='### ### ## ##'
											rules={{
												validate: nipValidator,
												required: 'Pole wymagane',
											}}
											errors={
												economicLiabilitiesErrors?.data?.[index]?.nip as {
													type: string;
													message: string;
												}
											}
										/>
									</Grid>
								)}
								{(props.methods.watch(`economicLiabilities.data[${index}].nipOrSocialSecurityNumber`) as TypeOfIdentification) ===
									TypeOfIdentification.PESEL && (
									<Grid item xs={7} md={2.5}>
										<NestInput
											label='PESEL'
											control={props.methods.control as unknown as Control}
											name={`economicLiabilities.data[${index}].socialSecurityNumber`}
											rules={peselValidator}
											errors={
												economicLiabilitiesErrors?.data?.[index]?.socialSecurityNumber as {
													type: string;
													message: string;
												}
											}
										/>
									</Grid>
								)}
								<Grid item xs={props.methods.watch(`economicLiabilities.data[${index}].nipOrSocialSecurityNumber`) ? gridFullWidth : 7} md={3}>
									<NestSelect
										rules={{ required: 'Pole wymagane' }}
										control={props.methods.control as unknown as Control}
										name={`economicLiabilities.data[${index}].type`}
										label='Rodzaj powiązania'
										options={economicLiabilityTypeOptions as [string, string | number][]}
										placeholderOption={['Wybierz rodzaj powiązania', 0]}
										errors={
											economicLiabilitiesErrors?.data?.[index]?.type as {
												type: string;
												message: string;
											}
										}
									/>
								</Grid>
								{watchEconomicLiabilitiesData.length > 1 && (
									<>
										<Grid
											item
											xs={gridFullWidth}
											md={2.5}
											sx={{
												display: { xs: 'flex', md: 'none' },
												paddingTop: '20px' + ' !important',
												justifyContent: 'center',
												flexDirection: 'column',
												color: secondaryMainColor,
											}}
										>
											<Stack
												sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', cursor: 'pointer', marginBottom: '28px' }}
												onClick={(): void => deleteRow(item.id as string)}
											>
												<img
													style={{
														width: '32px',
														height: '32px',
													}}
													src={minusViolet}
												/>
												<Typography variant='p4' sx={{ fontWeight: '700', marginLeft: '7px' }}>
													usuń podmiot
												</Typography>
											</Stack>

											<Stack
												sx={{
													marginBottom: watchEconomicLiabilitiesData.length === 10 ? '28px' : '0',
													borderBottom: '1px' + ' solid',
													color: primaryMainColor,
												}}
											></Stack>
										</Grid>
										<Stack sx={{ display: { md: 'block', xs: 'none' }, cursor: 'pointer' }}>
											<img
												style={{
													width: '16px',
													height: '16px',
													position: 'absolute',
													marginTop: '77px',
													marginLeft: '11px',
												}}
												src={closeIcon}
												onClick={(): void => deleteRow(item.id as string)}
											/>
										</Stack>
									</>
								)}
							</Grid>
						);
					})}
					{watchEconomicLiabilitiesData?.length < 10 && (
						<PlusIconContainer onClick={addRow} sx={{ marginTop: { xs: '15px', md: 0 }, marginBottom: { xs: '32px', md: 0 } }}>
							<img style={{ width: '32px', height: '32px' }} src={plusViolet} alt='plus-icon' />
							<Typography
								variant='p1'
								sx={{
									fontFamily: 'Athletics, sans-serif',
									fontWeight: 700,
									marginLeft: '7px',
									color: secondaryMainColor,
								}}
							>
								dodaj kolejny podmiot
							</Typography>
						</PlusIconContainer>
					)}
				</>
			)}
		</>
	);
};

export default EconomicLiabilities;
