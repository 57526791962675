import { styled } from '@mui/system';

const flexDirectionColumn = {
	display: 'flex',
	flexDirection: 'column',
};

export const Form = styled('form')({
	...(flexDirectionColumn as Record<string, string>),
	width: '100%',
});

export const ButtonTextContainer = styled('div')({
	width: '100%',
	...(flexDirectionColumn as Record<string, string>),
	justifyContent: 'center',
});
