import { styled } from '@mui/system';
import { mainTheme } from '../../../../theme/mainTheme';
import { SxProps } from '@mui/system/styleFunctionSx';
import creditInfoSectionBg from '../../../../common/images/creditInfoSectionBg.svg';
import { textColorPrimary } from '../../../../theme/palette/palette';

export const CreditInfoSectionContainerBackground = styled('div')({
	width: '100%',
	position: 'relative',
	minHeight: '900px',
	marginTop: '-75px',
	paddingTop: '75px',

	backgroundImage: `url(${creditInfoSectionBg})`,
	backgroundSize: 'cover',
	backgroundPosition: 'left',
	backgroundPositionX: '-350px',
	backgroundRepeat: 'no-repeat',

	[mainTheme.breakpoints.down('lg')]: {
		background: textColorPrimary,
		marginTop: '-110px',
		paddingTop: '110px',
	},
});

export const CreditInfoSectionContainer = styled('div')({
	width: '100%',
});

export const CreditInfoSectionBackgroundImage = styled('div')({
	display: 'none',
	position: 'absolute',

	[mainTheme.breakpoints.up('lg')]: {
		display: 'block',
		right: '2%',
		top: '75px',
	},
	[mainTheme.breakpoints.up(1300)]: {
		right: '4%',
	},
	[mainTheme.breakpoints.up(1500)]: {
		right: '6%',
	},
	[mainTheme.breakpoints.up(1600)]: {
		right: '10%',
	},
	[mainTheme.breakpoints.up(1700)]: {
		right: '12%',
	},
	[mainTheme.breakpoints.up('xl')]: {
		right: '21%',
	},
});

export const CreditPeriodsContainer = styled('div')({
	overflowX: 'auto',
});

export const ButtonContainer = styled('div')({
	display: 'flex',
	justifyContent: 'center',
	marginBottom: '60px',
	[mainTheme.breakpoints.up('sm')]: {
		justifyContent: 'flex-start',
	},
});

export const backgroundImageText: SxProps = {
	bottom: '15px',
	left: '50px',
	color: '#FFFFFF',
	position: 'absolute',
};

export const BackgroundImageSpecialSign = styled('div')({
	fontSize: '250px',
	color: mainTheme.palette.primary.main,
	fontFamily: 'Arial',
	position: 'absolute',
	bottom: '-60px',
	left: '40px',
});

export const SemiStrongBold = styled('span')({
	fontWeight: '600',
});

export const MagicWordsContainer = styled('div')({
	maxWidth: '390px',
});
