import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';
import { orangeMainColor, textColorPrimary } from 'src/theme/palette/palette';

const infoItems = [
	{ sum: 0, desc: 'Prowadzenie pierwszego konta firmowego' },
	{ sum: 0, desc: 'Wszystkie internetowe przelewy krajowe Elixir, w tym do ZUS i US' },
	{ sum: 0, desc: 'Wpłaty i wypłaty BLIKIEM z bankomatów' },
	{ sum: 0, desc: 'Wpłaty i wypłaty z bankomatów Euronet w Polsce' },
	{ sum: 0, desc: 'Pierwsza karta debetowa Visa' },
	{ sum: 0, desc: 'Kody SMS do autoryzacji przelewów w bankowości internetowej' },
];

const OvdCalculatorBottomInfoNOClient = (): JSX.Element => {
	return (
		<Stack
			sx={{
				width: '100%',
				backgroundColor: textColorPrimary,
			}}
		>
			<Stack
				sx={{
					minHeight: 'inherit',
					flexDirection: 'column',
					width: { xs: 'unset', md: '778px' },
					margin: { xs: '0', md: 'auto' },
					padding: { xs: '0 0 12px 0', md: '0 0 62px 0' },
				}}
			>
				<Typography variant='h2' sx={{ padding: { xs: '32px 24px 4px 24px', md: '96px 24px 32px 24px' }, color: orangeMainColor }}>
					Za darmo, bezterminowo i bezwarunkowo
				</Typography>
				<Stack sx={{ color: 'white', display: 'grid', gridTemplateColumns: { xs: '1fr 1fr', md: '1fr 1fr 1fr' } }}>
					{infoItems.map(
						(item): JSX.Element => (
							<Stack key={item.desc} sx={{ padding: { xs: '20px 24px', md: '35px 24px' } }}>
								<Typography variant='h1'>{item.sum} zł</Typography>
								<Typography variant='p4' sx={{ fontWeight: '700' }}>
									{item.desc}
								</Typography>
							</Stack>
						)
					)}
				</Stack>
			</Stack>
		</Stack>
	);
};

export default OvdCalculatorBottomInfoNOClient;
