import Alert, { AlertColor } from '@mui/material/Alert/Alert';
import Box from '@mui/material/Box';
import Fade from '@mui/material/Fade/Fade';
import Snackbar from '@mui/material/Snackbar/Snackbar';
import { useContext } from 'react';
import { SnackbarContext, SnackbarType } from '../../context/SnackbarContext';
import AlertErrorIcon from '../../common/images/AlertError.svg';
import AlertInfoIcon from '../../common/images/AlertInfo.svg';
import AlertSuccessIcon from '../../common/images/AlertSuccess.svg';
import AlertWarningIcon from '../../common/images/AlertWarning.svg';
import AlertInfoExitIcon from '../../common/images/AlertInfoExit.svg';
import AlertSuccessExitIcon from '../../common/images/AlertSuccessExit.svg';
import AlertWarningExitIcon from '../../common/images/AlertWarningExit.svg';
import AlertErrorExitIcon from '../../common/images/AlertErrorExit.svg';

export default function NestSnackbar(): JSX.Element {
	const snackbarContext = useContext(SnackbarContext);

	const getSeverity = (type: SnackbarType): AlertColor => {
		switch (type) {
			case SnackbarType.Info: {
				return 'info';
			}
			case SnackbarType.Success: {
				return 'success';
			}
			case SnackbarType.Warning: {
				return 'warning';
			}
			case SnackbarType.Error: {
				return 'error';
			}
		}
	};

	const getIcon = (type: SnackbarType): JSX.Element => {
		let iconSrc: string;
		switch (type) {
			case SnackbarType.Info: {
				iconSrc = AlertInfoIcon;
				break;
			}
			case SnackbarType.Success: {
				iconSrc = AlertSuccessIcon;
				break;
			}
			case SnackbarType.Warning: {
				iconSrc = AlertWarningIcon;
				break;
			}
			case SnackbarType.Error: {
				iconSrc = AlertErrorIcon;
				break;
			}
		}

		return <img width='16px' height='16px' src={iconSrc} alt='!' />;
	};

	const getExitIcon = (type: SnackbarType, id: number): JSX.Element => {
		let iconSrc: string;
		switch (type) {
			case SnackbarType.Info: {
				iconSrc = AlertInfoExitIcon;
				break;
			}
			case SnackbarType.Success: {
				iconSrc = AlertSuccessExitIcon;
				break;
			}
			case SnackbarType.Warning: {
				iconSrc = AlertWarningExitIcon;
				break;
			}
			case SnackbarType.Error: {
				iconSrc = AlertErrorExitIcon;
				break;
			}
		}

		return (
			<button
				style={{
					borderRadius: '50%',
					height: '20px',
					width: '20px',
					padding: '4px 0 0 0',
					border: '0',
					cursor: 'pointer',
					background: 'transparent',
				}}
				onClick={(): void => snackbarContext.close(id)}
			>
				<img width='12px' height='12px' src={iconSrc} alt='!' />
			</button>
		);
	};

	return (
		<Box
			sx={{
				position: 'fixed',
				bottom: 0,
				right: 0,
				width: '100%',
				display: 'flex',
				justifyContent: 'center',
				flexDirection: 'column',
				alignItems: 'center',
				zIndex: '1000'
			}}
		>
			{snackbarContext.messages.map(
				(x): JSX.Element => (
					<Snackbar
						sx={{
							position: 'relative',
							marginBottom: '12px',
							right: 0,
							left: 0,
							bottom: 0,
							textAlign: 'justify',
							width: snackbarContext.width,
						}}
						open={x.isOpen}
						key={x.id}
						TransitionComponent={Fade}
					>
						<Alert
							severity={getSeverity(x.type)}
							icon={getIcon(x.type)}
							action={getExitIcon(x.type, x.id)}
							sx={{width: 'inherit'}}
						>
							{x.message}
						</Alert>
					</Snackbar>
				)
			)}
		</Box>
	);
}
