import EndComponent from '../components/End/EndComponent';
import IdentityVerificationInProgress from '../components/IdentityVerificationInProgress/IdentityVerificationInProgress';
import LightCompanyData from '../pages/light/components/light-company-data/LightCompanyData';
import LightCompanyDataVerified from '../pages/light/components/light-company-data/LightCompanyDataVerified';
import LightPersonalData from '../pages/light/components/light-personal-data/LightPersonalData';
import LightOvdLandingPage from '../pages/light/ovd/ovd-landing-page/LightOvdLandingPage';
import OvdSmsContractSigning from '../pages/light/ovd/ovd-sms-contract-signing/OvdSmsContractSigning';
import LightOvdSummaryPage from '../pages/light/ovd/ovd-summary-page/OvdSummaryPage';
import RouteSpec, { BreadcrumbStep } from './RouteSpec';

// Common
import NotFound from '../pages/common/not-found/NotFound';
import ReturnProposal from '../pages/common/return-proposal/ReturnProposal';
import TechnicalError from 'src/pages/common/technical-error/TechnicalError';
import IndexNewApplication from 'src/pages/components/index-new-application/IndexNewApplication';
import LightIdentityVerificationInProgressNav from '../pages/light/components/light-identity-verification-in-progress-nav/LightIdentityVerificationInProgressNav';
import LightIdentityVerificationFailedNav from 'src/pages/light/components/light-identity-verification-failed-nav/LightIdentityVerificationFailedNav';

// RBF
import VerificationFailed from '../pages/rbf/rbf-verification-failed/VerificationFailed';
import Verification from '../pages/rbf/rbf-verification/Verification';
import DataConsistency from '../pages/rbf/rbf-data-consistency/DataConsistency';
import IdentityVerification from '../pages/rbf/rbf-identity-verification/IdentityVerification';
import NewApplication from '../pages/rbf/rbf-new-application/NewApplication';
import CalculatorPage from '../pages/rbf/rbf-calculator/CalculatorPage';
import SmsContractSigning from '../pages/rbf/rbf-sms-contract-signing/SmsContractSigning';
import RejectionReason from '../pages/rbf/rbf-rejection-reason/RejectionReason';
import NipVerification from '../pages/rbf/rbf-nip-verification/NipVerification';
import SessionExpired from '../pages/rbf/rbf-session-expired/SessionExpired';
import ApplicationInProgress from '../pages/rbf/rbf-application-in-progress/ApplicationInProgress';
import AttachDocuments from '../pages/rbf/rbf-attach-documents/AttachDocuments';
import LandingPage from '../pages/rbf/rbf-landing-page/LandingPage';
import ContactData from '../pages/rbf/rbf-contact-data/ContactData';
import PersonalData from '../pages/rbf//rbf-personal-data/PersonalData';
import CompanyData from '../pages/rbf/rbf-company-data/CompanyData';

// LEAD
import LeadNewApplication from '../pages/lead/lead-new-application/LeadNewApplication';
import LeadEnd from 'src/pages/lead/lead-end/LeadEnd';
import LeadEndRejection from '../pages/lead/lead-end-rejection/LeadEndRejection';
import TechnicalErrorLead from '../pages/lead/lead-technical-error/TechnicalError';

// LIGHT - CL
import LightNewApplication from '../pages/light/cl/cl-new-application/LightNewApplication';
import LightLandingPage from '../pages/light/cl/cl-landing-page/LightLandingPage';
import LightDataConsistency from '../pages/light/cl/cl-data-consistency/LightDataConsistency';
import LightAttachDocuments from '../pages/light/cl/cl-attach-documents/LightAttachDocuments';
import LightAttachDocumentsCorrection from '../pages/light/cl/cl-attach-documents-correction/LightAttachDocumentsCorrection';
import LightCalculatorPage from '../pages/light/cl/cl-calculator/LightCalculatorPage';
import LightSmsContractSigning from '../pages/light/cl/cl-sms-contract-signing/LightSmsContractSigning';
import LightVerificationFailed from '../pages/light/cl/cl-verification-failed/LightVerificationFailed';
import LightRejection from '../pages/light/cl/cl-rejection/LightRejection';

// LIGHT - OVD
import OvdEnd from '../pages/light/ovd/ovd-end/OvdEnd';
import OvdCalculatorPage from '../pages/light/ovd/ovd-calculator/OvdCalculatorPage';
import OvdContactData from '../pages/light/ovd/ovd-contact-data/OvdContactData';
import OvdAdditionalData from '../pages/light/ovd/ovd-additional-data/OvdAdditionalData';

export enum WebPage {
	NotFound = 0,

	LandingPage = 1,
	ContactData = 2,
	TechnicalError = 3,
	SessionExpired = 4,
	Verification = 5,
	VerificationFailed = 6,
	DataConsistency = 7,
	IdentityVerification = 8,
	IdentityVerificationInProgress = 9,
	PersonalData = 10,
	CompanyData = 11,
	NewApplication = 12,
	End = 13,
	Calculator = 14,
	AttachDocuments = 15,
	SmsContractSigning = 16,
	ReturnProposal = 17,
	RejectionReason = 18,
	NipVerification = 19,
	ApplicationInProgress = 20,

	OvdEnd = 101,
	OvdContactData = 103,

	LeadNew = 200,
	LeadCL = 201,
	LeadOVD = 202,
	LeadEnd = 203,
	LeadEndRejection = 204,
	LeadClTechnicalError = 205,
	LeadOvdTechnicalError = 206,

	LightClLandingPage = 300,
	LightClPersonalData = 301,
	LightClEnd = 303,
	LightContactData = 304,
	LightClCompanyData = 305,
	LightClCompanyDataVerified = 306,
	LightClDataConsistency = 307,
	LightClCalculator = 308,
	LightClAttachDocuments = 309,
	LightNew = 310,
	LightIdentityVerificationInProgress = 312,
	LightIdentityVerificationFailed = 313,
	LightVerificationFailed = 314,
	LightClSmsContractSigning = 315,
	LightClAttachDocumentsCorrection = 316,
	LightRejection = 317,

	LightOvdCompanyAdditionalData = 400,
	LightOvdEnd = 401,
	LightOvdCompanyDataVerified = 402,
	LightOvdSmsContractSigning = 403,
	LightOvdCalculator = 404,
	LightOvdLandingPage = 405,
	LightOvdNoClientSummaryPage = 406,
	LightOvdCompanyData = 407,
	LightOvdPersonalData = 408,
	LightOvdDataConsistency = 409,
	LightOvdAttachDocuments = 410,
	LightOvdAttachDocumentsCorrection = 411,
	LightOvdIncomeVerificationInProgress = 412,
	LightOvdIncomeVerificationFailed = 413,
}

export const NotFoundRoute: RouteSpec = {
	path: '*',
	element: NotFound,
	doAfterManualPageChangeAction: false,
	page: WebPage.NotFound,
	showSaveStateButton: false,
	breadcrumbStepName: BreadcrumbStep.DOESNT_EXIST,
	showApplicationNumber: false,
	showPartnerLogo: false,
	clearSession: true,
};

export const Test: RouteSpec = {
	path: '',
	element: LightOvdLandingPage,
	doAfterManualPageChangeAction: false,
	page: WebPage.LightOvdLandingPage,
	showSaveStateButton: false,
	breadcrumbStepName: BreadcrumbStep.DOESNT_EXIST,
	showApplicationNumber: false,
	showPartnerLogo: true,
	clearSession: true,
};

export const ReturnProposalRoute: RouteSpec = {
	path: '/powrot',
	element: ReturnProposal,
	doAfterManualPageChangeAction: false,
	page: WebPage.ReturnProposal,
	showSaveStateButton: false,
	breadcrumbStepName: BreadcrumbStep.DOESNT_EXIST,
	showApplicationNumber: false,
	showPartnerLogo: false,
	clearSession: true,
};

export const RejectionReasonRoute: RouteSpec = {
	path: '/odmowa',
	element: RejectionReason,
	doAfterManualPageChangeAction: false,
	page: WebPage.RejectionReason,
	showSaveStateButton: false,
	breadcrumbStepName: BreadcrumbStep.DOESNT_EXIST,
	showApplicationNumber: false,
	showPartnerLogo: false,
	clearSession: true,
};

export const TechnicalErrorRoute: RouteSpec = {
	path: '/blad-techniczny',
	element: TechnicalError,
	doAfterManualPageChangeAction: false,
	page: WebPage.TechnicalError,
	showSaveStateButton: false,
	breadcrumbStepName: BreadcrumbStep.DOESNT_EXIST,
	showApplicationNumber: false,
	showPartnerLogo: false,
	clearSession: true,
};

// -------------------- RBF => path for RBF: /rbf/CL/ --------------------

export const VerificationRoute: RouteSpec = {
	path: '/rbf/CL/weryfikacja',
	element: Verification,
	doAfterManualPageChangeAction: true,
	page: WebPage.Verification,
	showSaveStateButton: false,
	breadcrumbStepName: BreadcrumbStep.DOESNT_EXIST,
	showApplicationNumber: true,
	showPartnerLogo: true,
	clearSession: true,
	allowGetInToPageWithoutSession: true,
};

export const VerificationFailedRoute: RouteSpec = {
	path: '/rbf/CL/weryfikacja-tozsamosci/nieudana',
	element: VerificationFailed,
	doAfterManualPageChangeAction: false,
	page: WebPage.VerificationFailed,
	showSaveStateButton: false,
	breadcrumbStepName: BreadcrumbStep.DOESNT_EXIST,
	showApplicationNumber: false,
	showPartnerLogo: true,
	clearSession: false,
};

export const DataConsistencyRoute: RouteSpec = {
	path: '/rbf/CL/zgodnosc-danych',
	element: DataConsistency,
	doAfterManualPageChangeAction: true,
	page: WebPage.DataConsistency,
	showSaveStateButton: true,
	breadcrumbStepName: BreadcrumbStep.LETS_GET_TO_KNOW,
	showApplicationNumber: true,
	showPartnerLogo: true,
	clearSession: false,
};

export const ContactDataRoute: RouteSpec = {
	path: '/rbf/CL/dane-kontaktowe',
	element: ContactData,
	doAfterManualPageChangeAction: true,
	page: WebPage.ContactData,
	showSaveStateButton: false,
	breadcrumbStepName: BreadcrumbStep.LETS_GET_TO_KNOW,
	showApplicationNumber: true,
	showPartnerLogo: true,
	clearSession: false,
};

export const IdentityVerificationRoute: RouteSpec = {
	path: '/rbf/CL/weryfikacja-tozsamosci',
	element: IdentityVerification,
	doAfterManualPageChangeAction: true,
	page: WebPage.IdentityVerification,
	showSaveStateButton: true,
	breadcrumbStepName: BreadcrumbStep.CONFIRM_DATA,
	showApplicationNumber: true,
	showPartnerLogo: true,
	clearSession: false,
};

export const PersonalDataRoute: RouteSpec = {
	path: '/rbf/CL/dane-osobowe',
	element: PersonalData,
	doAfterManualPageChangeAction: true,
	page: WebPage.PersonalData,
	showSaveStateButton: true,
	breadcrumbStepName: BreadcrumbStep.LETS_GET_TO_KNOW,
	showApplicationNumber: true,
	showPartnerLogo: true,
	clearSession: false,
};

export const CompanyDataRoute: RouteSpec = {
	path: '/rbf/CL/dane-firmy',
	element: CompanyData,
	doAfterManualPageChangeAction: true,
	page: WebPage.CompanyData,
	showSaveStateButton: true,
	breadcrumbStepName: BreadcrumbStep.LETS_GET_TO_KNOW,
	showApplicationNumber: true,
	showPartnerLogo: true,
	clearSession: false,
};

export const SmsContractSigningRoute: RouteSpec = {
	path: '/rbf/CL/umowa-sms',
	element: SmsContractSigning,
	doAfterManualPageChangeAction: true,
	page: WebPage.SmsContractSigning,
	showSaveStateButton: true,
	breadcrumbStepName: BreadcrumbStep.ASSIGN_AGREEMENT,
	showApplicationNumber: true,
	showPartnerLogo: true,
	clearSession: false,
};

export const LandingPageRoute: RouteSpec = {
	path: '/rbf/CL',
	element: LandingPage,
	doAfterManualPageChangeAction: false,
	page: WebPage.LandingPage,
	showSaveStateButton: false,
	breadcrumbStepName: BreadcrumbStep.DOESNT_EXIST,
	showApplicationNumber: false,
	showPartnerLogo: true,
	clearSession: true,
};

export const EndRoute: RouteSpec = {
	path: '/rbf/CL/zakonczenie',
	element: EndComponent,
	doAfterManualPageChangeAction: true,
	page: WebPage.End,
	showSaveStateButton: false,
	breadcrumbStepName: BreadcrumbStep.COMPLETED,
	showApplicationNumber: true,
	showPartnerLogo: true,
	clearSession: true,
	allowGetInToPageWithoutSession: true,
};

export const CalculatorRoute: RouteSpec = {
	path: '/rbf/CL/kalkulator',
	element: CalculatorPage,
	doAfterManualPageChangeAction: true,
	page: WebPage.Calculator,
	showSaveStateButton: true,
	breadcrumbStepName: BreadcrumbStep.MATCH_THE_OFFER,
	showApplicationNumber: true,
	showPartnerLogo: true,
	clearSession: false,
};

export const AttachDocumentsRoute: RouteSpec = {
	path: '/rbf/CL/zalacz-dokumenty',
	element: AttachDocuments,
	doAfterManualPageChangeAction: true,
	page: WebPage.AttachDocuments,
	showSaveStateButton: true,
	breadcrumbStepName: BreadcrumbStep.ATTACH_DOCUMENTS,
	showApplicationNumber: true,
	showPartnerLogo: true,
	clearSession: false,
};

export const ApplicationInProgressRoute: RouteSpec = {
	path: '/kredyt/wniosek-w-toku',
	element: ApplicationInProgress,
	doAfterManualPageChangeAction: false,
	page: WebPage.ApplicationInProgress,
	showSaveStateButton: false,
	breadcrumbStepName: BreadcrumbStep.DOESNT_EXIST,
	showApplicationNumber: false,
	showPartnerLogo: false,
	clearSession: true,
};

export const NipVerificationRoute: RouteSpec = {
	path: '/rbf/CL/nip-weryfikacja',
	element: NipVerification,
	doAfterManualPageChangeAction: false,
	page: WebPage.NipVerification,
	showSaveStateButton: false,
	breadcrumbStepName: BreadcrumbStep.LETS_GET_TO_KNOW,
	showApplicationNumber: false,
	showPartnerLogo: true,
	clearSession: false,
};

export const NewApplicationRoute: RouteSpec = {
	path: '/rbf/CL/nowy-wniosek/*',
	element: NewApplication,
	doAfterManualPageChangeAction: false,
	page: WebPage.NewApplication,
	showSaveStateButton: false,
	breadcrumbStepName: BreadcrumbStep.DOESNT_EXIST,
	showApplicationNumber: false,
	showPartnerLogo: false,
	clearSession: true,
};

// -------------------- INDEX -------------------

export const IndexNewApplicationRoute: RouteSpec = {
	path: '/',
	element: IndexNewApplication,
	doAfterManualPageChangeAction: false,
	page: WebPage.LeadNew,
	showSaveStateButton: false,
	breadcrumbStepName: BreadcrumbStep.DOESNT_EXIST,
	showApplicationNumber: false,
	showPartnerLogo: true,
	clearSession: true,
	showHeader: false,
	showFooter: false
};

// -------------------- OVD => path for OVD: /rbf/OVD/ --------------------

export const OvdContactDataRoute: RouteSpec = {
	path: '/rbf/OVD/dane-kontaktowe',
	element: OvdContactData,
	doAfterManualPageChangeAction: false,
	page: WebPage.ContactData,
	showSaveStateButton: false,
	breadcrumbStepName: BreadcrumbStep.LETS_GET_TO_KNOW,
	showApplicationNumber: true,
	showPartnerLogo: true,
	clearSession: false,
};

// -------------------- LEAD => path for LEAD: /formularz-kontaktowy/ --------------------

export const LeadNewRoute: RouteSpec = {
	path: '/formularz-kontaktowy/:guid',
	element: LeadNewApplication,
	doAfterManualPageChangeAction: false,
	page: WebPage.LeadNew,
	showSaveStateButton: false,
	breadcrumbStepName: BreadcrumbStep.DOESNT_EXIST,
	showApplicationNumber: false,
	showPartnerLogo: false,
	clearSession: true,
	showHeader: false,
	showFooter: false,
};

export const LeadEndRoute: RouteSpec = {
	path: '/formularz-kontaktowy/koniec/:guid',
	element: LeadEnd,
	doAfterManualPageChangeAction: false,
	page: WebPage.LeadEnd,
	showSaveStateButton: false,
	breadcrumbStepName: BreadcrumbStep.DOESNT_EXIST,
	showApplicationNumber: false,
	showPartnerLogo: false,
	clearSession: true,
	allowGetInToPageWithoutSession: true,
	showFooter: false,
};

export const LeadEndRejectionRoute: RouteSpec = {
	path: '/formularz-kontaktowy/odrzut/:guid',
	element: LeadEndRejection,
	doAfterManualPageChangeAction: false,
	page: WebPage.LeadEnd,
	showSaveStateButton: false,
	breadcrumbStepName: BreadcrumbStep.DOESNT_EXIST,
	showApplicationNumber: false,
	showPartnerLogo: false,
	clearSession: true,
	allowGetInToPageWithoutSession: true,
};

export const LeadTechnicalErrorRoute: RouteSpec = {
	path: '/formularz-kontaktowy/blad-techniczny/:guid',
	element: TechnicalErrorLead,
	doAfterManualPageChangeAction: false,
	page: WebPage.LeadClTechnicalError,
	showSaveStateButton: false,
	breadcrumbStepName: BreadcrumbStep.DOESNT_EXIST,
	showApplicationNumber: false,
	showPartnerLogo: false,
	clearSession: true,
};

// -------------------- LIGHT - CL => path for LIGHT - CL: /kredyt/CL --------------------

export const LightNewApplictionRoute: RouteSpec = {
	path: '/kredyt/nowy-wniosek/:processId',
	element: LightNewApplication,
	doAfterManualPageChangeAction: false,
	page: WebPage.LightNew,
	showSaveStateButton: false,
	breadcrumbStepName: BreadcrumbStep.DOESNT_EXIST,
	showApplicationNumber: false,
	showPartnerLogo: false,
	clearSession: true,
};

export const LightLandingPageRoute: RouteSpec = {
	path: '/kredyt/CL',
	element: LightLandingPage,
	doAfterManualPageChangeAction: false,
	page: WebPage.LightClLandingPage,
	showSaveStateButton: false,
	breadcrumbStepName: BreadcrumbStep.DOESNT_EXIST,
	showApplicationNumber: false,
	showPartnerLogo: true,
	clearSession: true,
};

export const LightCompanyDataRoute: RouteSpec = {
	path: '/kredyt/CL/dane-firmy',
	element: LightCompanyData,
	doAfterManualPageChangeAction: true,
	page: WebPage.LightClCompanyData,
	showSaveStateButton: true,
	breadcrumbStepName: BreadcrumbStep.LETS_GET_TO_KNOW,
	showApplicationNumber: true,
	showPartnerLogo: true,
	clearSession: false,
};

export const LightCompanyDataVerifiedRoute: RouteSpec = {
	path: '/kredyt/CL/dane-firmy-zweryfikowane',
	element: LightCompanyDataVerified,
	doAfterManualPageChangeAction: true,
	page: WebPage.LightClCompanyDataVerified,
	showSaveStateButton: true,
	breadcrumbStepName: BreadcrumbStep.LETS_GET_TO_KNOW,
	showApplicationNumber: true,
	showPartnerLogo: true,
	clearSession: false,
};

export const LightPersonalDataRoute: RouteSpec = {
	path: '/kredyt/CL/dane-osobowe',
	element: LightPersonalData,
	doAfterManualPageChangeAction: true,
	page: WebPage.LightClPersonalData,
	showSaveStateButton: true,
	breadcrumbStepName: BreadcrumbStep.LETS_GET_TO_KNOW,
	showApplicationNumber: true,
	showPartnerLogo: true,
	clearSession: false,
};

export const LightDataConsistencyRoute: RouteSpec = {
	path: '/kredyt/CL/zgodnosc-danych',
	element: LightDataConsistency,
	doAfterManualPageChangeAction: true,
	page: WebPage.LightClDataConsistency,
	showSaveStateButton: true,
	breadcrumbStepName: BreadcrumbStep.CONFIRM_DATA,
	showApplicationNumber: true,
	showPartnerLogo: true,
	clearSession: false,
};

export const LightVerificationRoute: RouteSpec = {
	path: '/kredyt/CL/weryfikacja',
	element: Verification,
	doAfterManualPageChangeAction: true,
	page: WebPage.Verification,
	showSaveStateButton: false,
	breadcrumbStepName: BreadcrumbStep.DOESNT_EXIST,
	showApplicationNumber: true,
	showPartnerLogo: true,
	clearSession: true,
	allowGetInToPageWithoutSession: true,
};

export const LightCalculatorRoute: RouteSpec = {
	path: '/kredyt/CL/kalkulator',
	element: LightCalculatorPage,
	doAfterManualPageChangeAction: true,
	page: WebPage.LightClCalculator,
	showSaveStateButton: true,
	breadcrumbStepName: BreadcrumbStep.MATCH_THE_OFFER,
	showApplicationNumber: true,
	showPartnerLogo: true,
	clearSession: false,
};

export const LightSmsContractSigningRoute: RouteSpec = {
	path: '/kredyt/CL/umowa-sms',
	element: LightSmsContractSigning,
	doAfterManualPageChangeAction: true,
	page: WebPage.LightClSmsContractSigning,
	showSaveStateButton: true,
	breadcrumbStepName: BreadcrumbStep.ASSIGN_AGREEMENT,
	showApplicationNumber: true,
	showPartnerLogo: true,
	clearSession: false,
};

export const LightAttachDocumentsRoute: RouteSpec = {
	path: '/kredyt/CL/zalacz-dokumenty',
	element: LightAttachDocuments,
	doAfterManualPageChangeAction: true,
	page: WebPage.LightClAttachDocuments,
	showSaveStateButton: true,
	breadcrumbStepName: BreadcrumbStep.ATTACH_DOCUMENTS,
	showApplicationNumber: true,
	showPartnerLogo: true,
	clearSession: false,
};

export const LightAttachDocumentsCorrectionRoute: RouteSpec = {
	path: '/kredyt/CL/zalacz-dokumenty-poprawa',
	element: LightAttachDocumentsCorrection,
	doAfterManualPageChangeAction: true,
	page: WebPage.LightClAttachDocumentsCorrection,
	showSaveStateButton: true,
	breadcrumbStepName: BreadcrumbStep.ATTACH_DOCUMENTS,
	showApplicationNumber: true,
	showPartnerLogo: true,
	clearSession: false,
};

export const LightEndRoute: RouteSpec = {
	path: '/kredyt/CL/zakonczenie',
	element: EndComponent,
	doAfterManualPageChangeAction: true,
	page: WebPage.LightClEnd,
	showSaveStateButton: false,
	breadcrumbStepName: BreadcrumbStep.COMPLETED,
	showApplicationNumber: true,
	showPartnerLogo: true,
	clearSession: true,
	allowGetInToPageWithoutSession: true,
};

export const LightIdentityVerificationInProgressNavRoute: RouteSpec = {
	path: '/kredyt/weryfikacja-tozsamosci/w-trakcie',
	element: LightIdentityVerificationInProgressNav,
	doAfterManualPageChangeAction: false,
	page: WebPage.LightIdentityVerificationInProgress,
	showSaveStateButton: false,
	breadcrumbStepName: BreadcrumbStep.DOESNT_EXIST,
	showApplicationNumber: true,
	showPartnerLogo: true,
	clearSession: false,
};

export const LightIdentityVerificationFailedNavRoute: RouteSpec = {
	path: '/kredyt/weryfikacja-tozsamosci/nieudana',
	element: LightIdentityVerificationFailedNav,
	doAfterManualPageChangeAction: false,
	page: WebPage.LightIdentityVerificationFailed,
	showSaveStateButton: false,
	breadcrumbStepName: BreadcrumbStep.DOESNT_EXIST,
	showApplicationNumber: true,
	showPartnerLogo: true,
	clearSession: false,
};

export const LightIdentityVerificationInProgressRoute: RouteSpec = {
	path: '/kredyt/CL/weryfikacja-tozsamosci/w-trakcie',
	element: IdentityVerificationInProgress,
	doAfterManualPageChangeAction: false,
	page: WebPage.LightIdentityVerificationInProgress,
	showSaveStateButton: false,
	breadcrumbStepName: BreadcrumbStep.DOESNT_EXIST,
	showApplicationNumber: true,
	showPartnerLogo: true,
	clearSession: false,
};

export const LightVerificationFailedRoute: RouteSpec = {
	path: '/kredyt/CL/weryfikacja-tozsamosci/nieudana',
	element: LightVerificationFailed,
	doAfterManualPageChangeAction: false,
	page: WebPage.LightVerificationFailed,
	showSaveStateButton: false,
	breadcrumbStepName: BreadcrumbStep.DOESNT_EXIST,
	showApplicationNumber: true,
	showPartnerLogo: true,
	clearSession: false,
};

export const LightRejectionRoute: RouteSpec = {
	path: '/kredyt/CL/odrzut',
	element: LightRejection,
	doAfterManualPageChangeAction: false,
	page: WebPage.LightRejection,
	showSaveStateButton: false,
	breadcrumbStepName: BreadcrumbStep.DOESNT_EXIST,
	showApplicationNumber: false,
	showPartnerLogo: false,
	clearSession: true,
	allowGetInToPageWithoutSession: true,
};

export const SessionExpiredRoute: RouteSpec = {
	path: '/kredyt/CL/sesja-wygasla',
	element: SessionExpired,
	doAfterManualPageChangeAction: false,
	page: WebPage.SessionExpired,
	showSaveStateButton: false,
	breadcrumbStepName: BreadcrumbStep.DOESNT_EXIST,
	showApplicationNumber: false,
	showPartnerLogo: false,
	clearSession: true,
};

// -------------------- LIGHT-OVD => path for LIGHT-OVD: /kredyt/OVD --------------------

export const LightOvdSummaryPageRoute: RouteSpec = {
	path: '/kredyt/OVD/podsumowanie',
	element: LightOvdSummaryPage,
	doAfterManualPageChangeAction: false,
	page: WebPage.LightOvdNoClientSummaryPage,
	showSaveStateButton: false,
	breadcrumbStepName: BreadcrumbStep.DOESNT_EXIST,
	showApplicationNumber: false,
	showPartnerLogo: true,
	clearSession: true,
	allowGetInToPageWithoutSession: true
};

export const LightOvdLandingPageRoute: RouteSpec = {
	path: '/kredyt/OVD',
	element: LightOvdLandingPage,
	doAfterManualPageChangeAction: false,
	page: WebPage.LightOvdLandingPage,
	showSaveStateButton: false,
	breadcrumbStepName: BreadcrumbStep.DOESNT_EXIST,
	showApplicationNumber: false,
	showPartnerLogo: true,
	clearSession: true,
};

export const LightOvdCompanyDataRoute: RouteSpec = {
	path: '/kredyt/OVD/dane-firmy',
	element: LightCompanyData,
	doAfterManualPageChangeAction: true,
	page: WebPage.LightOvdCompanyData,
	showSaveStateButton: true,
	breadcrumbStepName: BreadcrumbStep.LETS_GET_TO_KNOW,
	showApplicationNumber: true,
	showPartnerLogo: true,
	clearSession: false,
};

export const LightOvdPersonalDataRoute: RouteSpec = {
	path: '/kredyt/OVD/dane-osobowe',
	element: LightPersonalData,
	doAfterManualPageChangeAction: true,
	page: WebPage.LightOvdPersonalData,
	showSaveStateButton: true,
	breadcrumbStepName: BreadcrumbStep.LETS_GET_TO_KNOW,
	showApplicationNumber: true,
	showPartnerLogo: true,
	clearSession: false,
};

export const LightOvdDataConsistencyRoute: RouteSpec = {
	path: '/kredyt/OVD/zgodnosc-danych',
	element: LightDataConsistency,
	doAfterManualPageChangeAction: true,
	page: WebPage.LightOvdDataConsistency,
	showSaveStateButton: true,
	breadcrumbStepName: BreadcrumbStep.CONFIRM_DATA,
	showApplicationNumber: true,
	showPartnerLogo: true,
	clearSession: false,
};

export const LightOvdAdditionalDataRoute: RouteSpec = {
	path: '/kredyt/OVD/informacje-dodatkowe',
	element: OvdAdditionalData,
	doAfterManualPageChangeAction: false,
	page: WebPage.LightOvdCompanyAdditionalData,
	showSaveStateButton: false,
	breadcrumbStepName: BreadcrumbStep.DOESNT_EXIST,
	showApplicationNumber: true,
	showPartnerLogo: false,
	clearSession: true,
};

export const LightOvdEndRoute: RouteSpec = {
	path: '/kredyt/OVD/zakonczenie',
	element: OvdEnd,
	doAfterManualPageChangeAction: true,
	page: WebPage.LightOvdEnd,
	showSaveStateButton: false,
	breadcrumbStepName: BreadcrumbStep.COMPLETED,
	showApplicationNumber: true,
	showPartnerLogo: true,
	clearSession: true,
	allowGetInToPageWithoutSession: true,
};

export const LightOvdCompanyDataVerifiedRoute: RouteSpec = {
	path: '/kredyt/OVD/dane-firmy-zweryfikowane',
	element: LightCompanyDataVerified,
	doAfterManualPageChangeAction: true,
	page: WebPage.LightOvdCompanyDataVerified,
	showSaveStateButton: true,
	breadcrumbStepName: BreadcrumbStep.LETS_GET_TO_KNOW,
	showApplicationNumber: true,
	showPartnerLogo: true,
	clearSession: false,
};

export const LightOvdIdentityVerificationInProgressRoute: RouteSpec = {
	path: '/kredyt/OVD/weryfikacja-tozsamosci/w-trakcie',
	element: IdentityVerificationInProgress,
	doAfterManualPageChangeAction: false,
	page: WebPage.LightOvdIncomeVerificationInProgress,
	showSaveStateButton: false,
	breadcrumbStepName: BreadcrumbStep.DOESNT_EXIST,
	showApplicationNumber: true,
	showPartnerLogo: true,
	clearSession: false,
};

export const LightOvdVerificationRoute: RouteSpec = {
	path: '/kredyt/OVD/weryfikacja',
	element: Verification,
	doAfterManualPageChangeAction: true,
	page: WebPage.Verification,
	showSaveStateButton: false,
	breadcrumbStepName: BreadcrumbStep.DOESNT_EXIST,
	showApplicationNumber: true,
	showPartnerLogo: true,
	clearSession: true,
	allowGetInToPageWithoutSession: true,
};

export const LightOvdVerificationFailedRoute: RouteSpec = {
	path: '/kredyt/OVD/weryfikacja-tozsamosci/nieudana',
	element: LightVerificationFailed,
	doAfterManualPageChangeAction: false,
	page: WebPage.LightOvdIncomeVerificationFailed,
	showSaveStateButton: false,
	breadcrumbStepName: BreadcrumbStep.DOESNT_EXIST,
	showApplicationNumber: true,
	showPartnerLogo: true,
	clearSession: false,
};

export const LightOvdCalculatorRoute: RouteSpec = {
	path: '/kredyt/OVD/kalkulator',
	element: OvdCalculatorPage,
	doAfterManualPageChangeAction: true,
	page: WebPage.LightOvdCalculator,
	showSaveStateButton: true,
	breadcrumbStepName: BreadcrumbStep.MATCH_THE_OFFER,
	showApplicationNumber: true,
	showPartnerLogo: true,
	clearSession: false,
};

export const LightOvdSmsContractSigningRoute: RouteSpec = {
	path: '/kredyt/OVD/umowa-sms',
	element: OvdSmsContractSigning,
	doAfterManualPageChangeAction: true,
	page: WebPage.LightOvdSmsContractSigning,
	showSaveStateButton: true,
	breadcrumbStepName: BreadcrumbStep.ASSIGN_AGREEMENT,
	showApplicationNumber: true,
	showPartnerLogo: true,
	clearSession: false,
};

export const LightOvdAttachDocumentsRoute: RouteSpec = {
	path: '/kredyt/OVD/zalacz-dokumenty',
	element: LightAttachDocuments,
	doAfterManualPageChangeAction: true,
	page: WebPage.LightOvdAttachDocuments,
	showSaveStateButton: true,
	breadcrumbStepName: BreadcrumbStep.ATTACH_DOCUMENTS,
	showApplicationNumber: true,
	showPartnerLogo: true,
	clearSession: false,
};

export const LightOvdAttachDocumentsCorrectionRoute: RouteSpec = {
	path: '/kredyt/OVD/zalacz-dokumenty-poprawa',
	element: LightAttachDocumentsCorrection,
	doAfterManualPageChangeAction: true,
	page: WebPage.LightOvdAttachDocumentsCorrection,
	showSaveStateButton: true,
	breadcrumbStepName: BreadcrumbStep.ATTACH_DOCUMENTS,
	showApplicationNumber: true,
	showPartnerLogo: true,
	clearSession: false,
};

export const LightOvdSessionExpiredRoute: RouteSpec = {
	path: '/kredyt/OVD/sesja-wygasla',
	element: SessionExpired,
	doAfterManualPageChangeAction: false,
	page: WebPage.SessionExpired,
	showSaveStateButton: false,
	breadcrumbStepName: BreadcrumbStep.DOESNT_EXIST,
	showApplicationNumber: false,
	showPartnerLogo: false,
	clearSession: true,
};

export const Routing: RouteSpec[] = [
	NotFoundRoute,

	NewApplicationRoute,
	LandingPageRoute,
	TechnicalErrorRoute,
	VerificationRoute,
	VerificationFailedRoute,
	DataConsistencyRoute,
	ContactDataRoute,
	IdentityVerificationRoute,
	PersonalDataRoute,
	CompanyDataRoute,
	EndRoute,
	CalculatorRoute,
	AttachDocumentsRoute,
	SmsContractSigningRoute,
	ReturnProposalRoute,
	RejectionReasonRoute,
	NipVerificationRoute,
	SessionExpiredRoute,
	ApplicationInProgressRoute,

	OvdContactDataRoute,

	IndexNewApplicationRoute,
	LeadNewRoute,
	LeadEndRoute,
	LeadEndRejectionRoute,
	LeadTechnicalErrorRoute,

	LightLandingPageRoute,
	LightCompanyDataRoute,
	LightPersonalDataRoute,
	LightEndRoute,
	LightCompanyDataVerifiedRoute,
	LightDataConsistencyRoute,
	LightVerificationRoute,
	LightCalculatorRoute,
	LightAttachDocumentsRoute,
	LightAttachDocumentsCorrectionRoute,
	LightNewApplictionRoute,
	LightIdentityVerificationInProgressRoute,
	LightIdentityVerificationInProgressNavRoute,
	LightIdentityVerificationFailedNavRoute,
	LightVerificationFailedRoute,
	LightSmsContractSigningRoute,
	LightRejectionRoute,

	LightOvdLandingPageRoute,
	LightOvdCompanyDataRoute,
	LightOvdPersonalDataRoute,
	LightDataConsistencyRoute,
	LightOvdDataConsistencyRoute,
	LightOvdAdditionalDataRoute,
	LightOvdEndRoute,
	LightOvdCompanyDataVerifiedRoute,
	LightOvdIdentityVerificationInProgressRoute,
	LightOvdVerificationRoute,
	LightOvdVerificationFailedRoute,
	LightOvdSmsContractSigningRoute,
	LightOvdCalculatorRoute,
	LightOvdSummaryPageRoute,
	LightOvdAttachDocumentsRoute,
	LightOvdAttachDocumentsCorrectionRoute,
	LightOvdSessionExpiredRoute,
];
