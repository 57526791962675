import { BrowserRouter } from 'react-router-dom';
import SessionExpiration from '../components/SessionExpiration/SessionExpiration';
import { AppRouter } from './AppRouter';
import { Routing } from './Routing';

export default function AppRouterOutlet(): JSX.Element {
	return (
		<BrowserRouter>
			<AppRouter routes={Routing} />
			<SessionExpiration />
		</BrowserRouter>
	);
}
