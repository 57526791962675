export const ErrorCodeMap: Record<
	string,
	Record<string, string | undefined> | undefined
> = {
	// File errors section - 01, RBF_01_{message_code}
	'01': {
		// At least one file should be present
		'001': 'Należy dodać co najmniej jeden plik',
		// File media type is invalid
		'002': 'Przesłano plik w niedozwolonym formacie',
		'003': 'Nie można pobrać pliku',
		'004': 'Nie znaleziono pliku',
		'005':
			'Plik potencjalnie niebezpieczny. W wyniku weryfikacji antywirusowej został odrzucony, spróbuj ponownie.',
	},
	// Agreement errors section - 02, RBF_02_{message_code}
	'02': {
		// Agreement is required - params: agreement_symbol
		'001': '{0} zgoda jest wymagana',
	},
	// Sms errors section - 03, RBF_03_{message_code}
	'03': {
		// No more attempts available
		'001': 'Wszystkie próby zostały już wykorzystane',
		// The given code is expired
		'002': 'Wprowadzony kod wygasł.',
		// The given code is invalid - params: remaining_attempts, total_attempts
		'003':
			'Wprowadzony kod jest niepoprawny. Pozostało ci {0} z {1} prób wpisania kodu.',
		// The given code is invalid
		'004': 'Wprowadzony kod jest niepoprawny.',
	},
	// Validation errors section - 04, RBF_04_{message_code}
	// All error codes params: field_name, {ComparisonValue?: number, MaxLength?: number, MinLength?: number}
	'04': {
		// Field is null or empty
		'001': 'Pole {0} jest wymagane',
		// string: Field is too long
		'002': 'Pole {0} nie powinno przekraczać {MaxLength} znaków',
		// string: Field is too short
		'003': 'Pole {0} powinno mieć więcej niż {MinLength} znaków',
		// Field has an invalid value
		'004': 'Pole {0} posiada niepoprawną wartość',
		// number: Field should be greater or equal than
		'005': 'Pole {0} powinno być większe lub równe {ComparisonValue}',
	},
	// Application errors section - 05, RBF_05_{message_code}
	'05': {
		'001': 'Brak możliwości powrotu do wniosku',
		'002': 'Niepoprawny NIP',
	},
};

export const ValidationErrorCode = '04';

export const rbfErrorCodes = {
	allSmsAttemptsHaveBeenUsed: 'RBF_03_001'
}

export const clientFormErrorCodes: Record<string, string> = {
	rejectionReasonCode: 'ODM_101',
	rejectErrorCode: 'CF_ERR_001',
  notFoundErrorCode: 'NOT_FOUND',
}

export const allClientFormCodes = Object.keys(clientFormErrorCodes).map((key: string): string => clientFormErrorCodes[key]);
