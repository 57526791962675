/* eslint-disable @typescript-eslint/no-misused-promises */
import Grid from '@mui/material/Grid';
import { Control, FieldErrors, FieldErrorsImpl, FormProvider, useForm } from 'react-hook-form';
import { convertAgreementsToAgreementModelToSend } from '../../../common/helpers/client-wealth-and-agreements-req-data-helpers';
import { ButtonContainer, Container } from '../../../common/styles/Layout.css';
import AddressForm from '../../../components/AddressForm/AddressForm';
import NestInput from '../../../components/FormItems/NestInput/NestInput';
import NestSelect from '../../../components/FormItems/NestSelect/NestSelect';
import { TaxationForm, TaxationFormDescription } from '../../../enums/TaxationForm';
import { gridFullWidth, gridHalfWidth } from '../../../theme/grid/GridConstants';
import {
	defaultValues,
	getErrorsCompanyAddressForm,
	getErrorsCorrespondenceAddressForm,
	addressFormControlNames,
	correspondenceAddressFormControlNames,
} from './CompanyDataConstants';
import { CompanyDataModel } from './CompanyDataModels';
import { useState, useContext, useEffect } from 'react';
import Typography from '@mui/material/Typography';
import useFetch from '../../../common/api/UseFetch';
import { AddCompanyDataConfig, DraftCompanyDataConfig, GetCompanyAgreements, GetCompanyDataConfig } from './CompanyDataApi';
import { useNavigate } from 'react-router-dom';
import { DataConsistencyRoute, PersonalDataRoute } from '../../../routing/Routing';
import Button from '@mui/material/Button';
import { OverrideProps } from '../../../routing/RouteSpec';
import { ContextSaveApplicationData } from '../../../context/ContextSaveApplicationData';
import AgreementCollection from '../../../components/AgreementCollection/AggreementCollection';
import { AgreementContainer, AgreementsHeaderSectionContainer } from './CompanyData.css';
import { useDispatch, useSelector } from 'react-redux';
import {
	checkErrors,
	checkIsAllAgreementsChecked,
	handleExpandAllAgreements,
	saveAgreements,
} from '../../../store/slices/agreementsSlice/AgreementsSlice';
import { AgreementModel } from '../../../components/AgreementCollection/AgreementsModels';
import { RootState } from '../../../store/State';
import { IAgreementsStateSlice } from '../../../store/slices/agreementsSlice/AgreementSliceModels';
import Loader from '../../../components/Loader/Loader';
import NestTooltip from '../../../components/NestTooltip/NestTooltip';
import { amountMask } from '../../../common/helpers/mask-helper';
import CustomButton from '../../../components/CustomButton/CustomButton';
import { getSessionStorageValue, partnerNameKey } from '../../../common/sessionStorage/SessionStorageService';
import { blockDashAndZero } from '../../../common/helpers/block-writing-keys-helper';
import { ScrollToTopOnMount } from '../../../common/helpers/scroll-to-top-onmount';
import { secondaryMainColor } from '../../../theme/palette/palette';
import { groupAgreementsBySection } from '../../../common/helpers/group-agreements-by-section-helper';
import { NestFormInfo } from 'src/components/FormItems/NestFormInfo/NestFormInfo';
import { Stack } from '@mui/system';

export default function CompanyData(props?: OverrideProps): JSX.Element {
	const [checkedAddress, setCheckedAddress] = useState(true);
	const [agreementsValid, setAgreementsValid] = useState<boolean>(true);
	const dispatch = useDispatch();
	const agreementState = useSelector((state: RootState): IAgreementsStateSlice => state.agreements);
	const [clickedSubmit, setClickedSubmit] = useState<boolean>(false);

	const methods = useForm<CompanyDataModel>({
		mode: 'onSubmit',
		reValidateMode: 'onChange',
		defaultValues,
	});

	const navigate = useNavigate();

	const companyDataFetch = useFetch(GetCompanyDataConfig, true, (data): void => {
		methods.reset({ ...data });
	});

	const companyAgreementFetch = useFetch(GetCompanyAgreements, true, (data: AgreementModel[]): void => {
		dispatch(saveAgreements(groupAgreementsBySection(data)));
	});

	const draftCompanyData = useFetch(DraftCompanyDataConfig, false, (): void => {
		if (props?.close) {
			props.close();
		}
	});

	const companyDataPost = useFetch(AddCompanyDataConfig, false, (): void => {
		if (!props?.ommitRouting) {
			navigate(DataConsistencyRoute.path);
		}
		props?.close && props.close();
	});

	const [isSaveApplicationData] = useContext(ContextSaveApplicationData);

	useEffect((): void => {
		if (isSaveApplicationData) {
			saveDraftCompanyData();
		}
	}, [isSaveApplicationData]);

	useEffect((): void => {
		setCheckedAddress(companyDataFetch.data?.isCompanyCorrespondenceAddressSameAsCompanyAddress ?? true);
	}, [companyDataFetch.data]);

	useEffect((): void => {
		dispatch(checkErrors());
		dispatch(checkIsAllAgreementsChecked());
	}, [companyAgreementFetch.data]);

	const saveDraftCompanyData = (): void => {
		methods.clearErrors();
		const dataToSend: CompanyDataModel = {
			...methods.watch(),
			agreements: convertAgreementsToAgreementModelToSend(agreementState.agreements),
			isCompanyCorrespondenceAddressSameAsCompanyAddress: checkedAddress,
		};
		if (dataToSend.isCompanyCorrespondenceAddressSameAsCompanyAddress) {
			dataToSend.companyCorrespondenceAddress = {
				...dataToSend.companyAddress,
			};
		}
		draftCompanyData.mutate(dataToSend);
	};

	const onSubmit = (data: CompanyDataModel): void => {
		if (agreementState.agreements.length && !agreementState.isValid) {
			return;
		}
		if (checkedAddress) {
			data.companyCorrespondenceAddress = { ...data.companyAddress };
		}
		const dataToSend: CompanyDataModel = {
			...data,
			agreements: convertAgreementsToAgreementModelToSend(agreementState.agreements),
			isCompanyCorrespondenceAddressSameAsCompanyAddress: checkedAddress,
		};
		companyDataPost.mutate(dataToSend);
	};

	const handleChecked = (event: React.ChangeEvent<HTMLInputElement>): void => {
		setCheckedAddress(event.target.checked);
		methods.setValue('isCompanyCorrespondenceAddressSameAsCompanyAddress', event.target.checked);
	};

	useEffect((): void => {
		if (clickedSubmit) {
			dispatch(checkErrors());
			setAgreementsValid(agreementState.isValid);
		}
	}, [agreementState.agreements[0]?.isSelected, agreementState.isValid]);

	useEffect((): void => {
		scrollToErrors(methods.formState.errors);
	}, [JSON.stringify(methods.formState.errors), clickedSubmit]);

	if (companyDataFetch.isPending) {
		return (
			<Container>
				<Loader />
			</Container>
		);
	}

	const scrollToErrors = (errors: FieldErrors<CompanyDataModel>): void => {
		const firstErrorField = Object.keys(errors)[0];
		const isFieldComplex = !(errors as Record<string, FieldErrors>)[firstErrorField]?.ref;

		if (clickedSubmit && firstErrorField) {
			if (isFieldComplex) {
				document
					.querySelector(`[name='${firstErrorField}.${Object.keys((errors as Record<string, FieldErrors>)[firstErrorField] as object)[0]}']`)
					?.scrollIntoView({ block: 'center', behavior: 'smooth' });
			} else {
				document.querySelector(`[name='${firstErrorField}']`)?.scrollIntoView({ block: 'center', behavior: 'smooth' });
			}
			setClickedSubmit(false);
		}
	};

	const preSubmitHandler = (): void => {
		dispatch(checkErrors());
		setClickedSubmit(true);
		methods.handleSubmit(onSubmit);
	};

	return (
		<Container>
			<ScrollToTopOnMount />
			<FormProvider {...methods}>
				<form onSubmit={methods.handleSubmit(onSubmit)}>
					<Grid container rowSpacing={4} columnSpacing={9}>
						<Grid item xs={gridFullWidth} sx={{ marginBottom: '32px' }}>
							<Typography
								variant='h2'
								sx={{
									fontSize: { xs: '28px', md: '34px' },
									lineHeight: { xs: '33px', md: '40px' },
								}}
							>
								Informacje o Twojej firmie
							</Typography>
						</Grid>
						<Grid item xs={gridFullWidth} md={gridHalfWidth}>
							<NestSelect
								rules={{
									min: { value: 1, message: 'Pole wymagane' },
								}}
								control={methods.control as unknown as Control}
								name='taxationForm'
								label='Forma opodatkowania'
								options={[
									[TaxationFormDescription(TaxationForm.IncomeAndExpenseBook), TaxationForm.IncomeAndExpenseBook],
									[TaxationFormDescription(TaxationForm.LumpSum), TaxationForm.LumpSum],
									[TaxationFormDescription(TaxationForm.TaxCard), TaxationForm.TaxCard],
									[TaxationFormDescription(TaxationForm.FullAccountancy), TaxationForm.FullAccountancy],
								]}
								errors={methods.formState.errors.taxationForm as { type: string; message: string } | undefined}
								placeholderOption={['Wybierz formę opodatkowania', TaxationForm.None]}
								disabled={companyDataPost.isPending}
							/>
						</Grid>
						<Grid item xs={gridFullWidth} md={gridHalfWidth}>
							<NestInput
								label='Liczba pracowników wraz z właścicielem'
								control={methods.control as unknown as Control}
								name='numberOfEmployees'
								rules={{
									pattern: {
										value: /^[^.]*$/u,
										message: 'Niepoprawna wartość',
									},
									required: 'Pole wymagane',
									min: { value: 1, message: 'Niepoprawna wartość' },
								}}
								blockWritingSomeValueFunc={blockDashAndZero}
								blockPasteEvent
								disabled={companyDataPost.isPending}
							/>
						</Grid>
						<Grid item xs={gridFullWidth} md={gridHalfWidth} sx={{ position: 'relative' }}>
							<NestInput
								type={'text'}
								label='Przychód firmy'
								disabled={true}
								control={methods.control as unknown as Control}
								name='companyIncome'
								mask=''
								maskFunc={amountMask}
							/>
							<Typography
								variant='p2'
								sx={{
									position: 'absolute',
									bottom: '30px',
									right: '32px',
									transform: 'translate(50%, 50%)',
								}}
							>
								PLN
							</Typography>
							<NestTooltip
								text={`Średniomiesięczny przychód z ostatnich 12 miesięcy kalendarzowych, wyliczony na bazie obrotów udostępnionych z ${
									getSessionStorageValue(partnerNameKey) as unknown as string
								}.`}
								sx={{
									position: 'absolute',
									bottom: '28px',
									right: { xs: '-12px', md: '-20px' },
									transform: 'translate(50%, 50%)',
								}}
							/>
						</Grid>
						<Grid item xs={gridFullWidth}>
							<Typography variant='h5' sx={{ marginBottom: '24px' }}>
								Adres firmy
							</Typography>
							<Grid container rowSpacing={3} columnSpacing={9}>
								<AddressForm
									methods={methods}
									formControlNames={addressFormControlNames}
									checkboxLabel='Taki sam jak adres firmy'
									checked={checkedAddress}
									handleChecked={handleChecked}
									disabled={companyDataPost.isPending}
									errors={getErrorsCompanyAddressForm(methods.formState.errors) as Partial<FieldErrorsImpl<Record<string, unknown>>>}
								/>
							</Grid>
							{!checkedAddress && (
								<Grid container rowSpacing={3} columnSpacing={9}>
									<AddressForm
										methods={methods}
										formControlNames={correspondenceAddressFormControlNames}
										disabled={companyDataPost.isPending}
										errors={getErrorsCorrespondenceAddressForm(methods.formState.errors) as Partial<FieldErrorsImpl<Record<string, unknown>>>}
									/>
								</Grid>
							)}
						</Grid>
						<Grid item xs={gridFullWidth}>
							<AgreementContainer>
								<AgreementsHeaderSectionContainer sx={{ maxWidth: { xs: '100%', md: '70%' } }}>
									<Typography variant='h2' sx={{ marginBottom: '34px' }}>
										Zgody i oświadczenia
									</Typography>
									<Typography
										variant='h8'
										onClick={(): void => {
											dispatch(
												handleExpandAllAgreements({
													isExpanded: !agreementState.allAgreementsExpanded,
												})
											);
										}}
										sx={{
											color: secondaryMainColor,
											fontFamily: 'Athletics, sans-serif',
											cursor: 'pointer',
										}}
									>
										{agreementState.allAgreementsExpanded ? 'Zwiń wszystko' : 'Rozwiń wszystko'}
									</Typography>
								</AgreementsHeaderSectionContainer>

								{Object.keys(agreementState.agreements).length > 0 && <AgreementCollection customBackground />}
							</AgreementContainer>
						</Grid>
						<Grid item xs={gridFullWidth}>
							<ButtonContainer>
								<Button
									variant='outlined'
									onClick={(): void => {
										props?.close && props.close();
										if (!props?.ommitRouting) {
											saveDraftCompanyData();
											navigate(PersonalDataRoute.path);
										} else {
											return;
										}
									}}
								>
									{props?.cancelText ? props.cancelText : 'Cofnij'}
								</Button>
								<Stack
									sx={{
										flexDirection: 'column',
									}}
								>
									{(Object.keys(methods.formState.errors).length > 0 || !agreementsValid) && clickedSubmit && <NestFormInfo />}
									<CustomButton isLoading={companyDataPost.isPending || companyDataPost.isSuccess} variant='contained' type='submit' onClick={preSubmitHandler}>
										{props?.submitText ? props.submitText : 'Dalej'}
									</CustomButton>
								</Stack>
							</ButtonContainer>
						</Grid>
					</Grid>
				</form>
			</FormProvider>
		</Container>
	);
}
