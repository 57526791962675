import { Switch, Typography } from '@mui/material';
import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';
import React from 'react';
import { Control, UseFormReturn } from 'react-hook-form';
import { blockWritingCurrencyMoreThanOneBilliard } from '../../../../../../../../common/helpers/block-writing-keys-helper';
import { amountDynamicMask } from '../../../../../../../../common/helpers/mask-helper';
import closeIcon from '../../../../../../../../common/images/ic16-close.svg';
import minusViolet from '../../../../../../../../common/images/minus-violet.svg';
import plusViolet from '../../../../../../../../common/images/plus-violet.svg';
import NestInput from '../../../../../../../../components/FormItems/NestInput/NestInput';
import NestSelect from '../../../../../../../../components/FormItems/NestSelect/NestSelect';
import NestTooltip from '../../../../../../../../components/NestTooltip/NestTooltip';
import { gridFullWidth } from '../../../../../../../../theme/grid/GridConstants';
import { primaryMainColor, secondaryMainColor } from '../../../../../../../../theme/palette/palette';
import { currencyOptions } from '../../../../consts/LightPersonalDataConstants';
import { PlusIconContainer } from '../../../EconomicLiabilities/EconomicLiablities.css';
import { CreditOrLeasingLiabilitiesDataInside, CreditOrLeasingLiabilitiesErrors } from '../../models/CreditOrLeasingLiabilitiesModels';

interface CreditOrLeasingLiabilitiesInputsProps {
	methods: UseFormReturn<Record<string, unknown>>;
	controlNameType: string;
	switchHeader: string;
	tooltipText: string | JSX.Element;
	inputLabelText: string;
}

const CreditOrLeasingLiabilitiesInputs = (props: CreditOrLeasingLiabilitiesInputsProps): JSX.Element => {
	const watchData = props.methods.watch(`creditOrLeasingLiabilities.${props.controlNameType}.data`) as CreditOrLeasingLiabilitiesDataInside[];
	const watchGiven = props.methods.watch(`creditOrLeasingLiabilities.${props.controlNameType}.isGiven`) as boolean;
	const creditOrLeasingLiabilitiesErrors = (props.methods.formState.errors as Record<string, string>).creditOrLeasingLiabilities?.[props.controlNameType as unknown as number];
	const switchHandleChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
		props.methods.setValue(`creditOrLeasingLiabilities.${props.controlNameType}.isGiven`, event.target.checked as never);
	};

	const deleteRow = (index: string): void => {
		const filteredData = watchData.filter((x): boolean => {
			return x.id !== index;
		});

		props.methods.setValue(`creditOrLeasingLiabilities.${props.controlNameType}.data`, filteredData as never);
	};

	const addRow = (): void => {
		props.methods.setValue(`creditOrLeasingLiabilities.${props.controlNameType}.data`, [
			...(watchData as never),
			{
				id: new Date().getTime(),
				installment: '',
				currency: '',
			},
		] as never);
	};

	return (
		<>
			<Grid container sx={{ width: { md: '83%', xs: '100%' }, marginBottom: {xs: watchGiven ? '0' : '32px', md: 0} }}>
				<Grid
					item
					xs={gridFullWidth}
					sx={{
						paddingTop: '0',
						justifyContent: 'space-between',
					}}
					display='flex'
					alignItems='center'
				>
					<Stack sx={{display: 'flex', alignItems: 'center', flexDirection: 'row', width: '100%'}}>
						<Typography variant='h5'>
							{props.switchHeader}
						</Typography>
						<NestTooltip sx={{marginRight: '20px', marginLeft: '10px' }} sxTooltipContent={{ maxWidth: { xs: '300px', md: '500px' } }} text={props.tooltipText} />
					</Stack>
					
					<Switch
						sx={{ margin: { md: '28px 0', xs: '0' } }}
						checked={watchGiven}
						onChange={(event: React.ChangeEvent<HTMLInputElement>): void => switchHandleChange(event)}
					/>
				</Grid>
			</Grid>
			{watchGiven && (
				<>
					{watchData?.map((item: CreditOrLeasingLiabilitiesDataInside, index: number): JSX.Element => {
						return (
							<Grid key={item.id} container rowSpacing={4} columnSpacing={3} sx={{marginTop: {xs: 'unset', md: '-32px'} }}>
								<Grid item xs={gridFullWidth} md={4.5}>
									<NestInput
										control={props.methods.control as unknown as Control}
										name={`creditOrLeasingLiabilities.${props.controlNameType}.data[${index}].installment`}
										label={props.inputLabelText}
										wrapperSx={{ marginBottom: {md: '22px', xs: '0'} }}
										rules={{
											required: 'Pole wymagane',
											min: {value: 1, message: 'Wartość powinna być większa niż 0'}
										}}
										errors={
											(creditOrLeasingLiabilitiesErrors as unknown as CreditOrLeasingLiabilitiesErrors)?.data?.[index]?.installment as {
												type: string;
												message: string;
											}
										}
										maskFunc={amountDynamicMask}
										mask=''
										blockWritingSomeValueFunc={blockWritingCurrencyMoreThanOneBilliard}
									/>
								</Grid>
								<Grid item xs={gridFullWidth} md={2.5}>
									<NestSelect
										control={props.methods.control as unknown as Control}
										rules={{ required: 'Pole wymagane' }}
										name={`creditOrLeasingLiabilities.${props.controlNameType}.data[${index}].currency`}
										errors={
											(creditOrLeasingLiabilitiesErrors as unknown as CreditOrLeasingLiabilitiesErrors)?.data?.[index]?.currency as {
												type: string;
												message: string;
											}
										}
										label='Waluta'
										options={currencyOptions as [string, string | number][]}
										placeholderOption={['Wybierz walutę', 0]}
									/>
								</Grid>
								{watchData.length > 1 && (
									<>
										<Grid item xs={gridFullWidth} md={2.5} sx={{display: {xs: 'flex', md: 'none'}, paddingTop: '20px' +
												' !important', justifyContent: 'center', flexDirection: 'column', color: secondaryMainColor }}>
											<Stack sx={{display: 'flex', flexDirection: 'row', alignItems: 'center', cursor: 'pointer', marginBottom: '28px'}} onClick={(): void => deleteRow(item.id as string)}>
												<img
													style={{
														width: '32px',
														height: '32px',
													}}
													src={minusViolet}
												/>
												<Typography variant="p4" sx={{fontWeight: '700', marginLeft: '7px'}}>usuń zobowiązanie</Typography>
											</Stack>

											<Stack sx={{marginBottom: watchData.length === 10 ? '28px' : '0', borderBottom: '1px solid', color: primaryMainColor}}></Stack>
										</Grid>
										<Stack sx={{display: {md: 'block', xs: 'none'}, cursor: 'pointer'}}>
											<img
												style={{
													width: '16px',
													height: '16px',
													position: 'absolute',
													marginTop: '77px',
													marginLeft: '11px',
												}}
												src={closeIcon}
												onClick={(): void => deleteRow(item.id as string)}
											/>
										</Stack>
									</>
								)}
							</Grid>
						);
					})}
					{watchData.length < 10 && (
						<PlusIconContainer onClick={addRow} sx={{marginTop: {xs: '15px', md: 0}, marginBottom: {xs: '28px', md: 0} }}>
							<img style={{ width: '32px', height: '32px' }} src={plusViolet} alt='plus-icon' />
							<Typography
								variant='p1'
								sx={{
									fontFamily: 'Athletics, sans-serif',
									fontWeight: 700,
									marginLeft: '7px',
									color: secondaryMainColor
								}}
							>
								dodaj kolejne zobowiązanie
							</Typography>
						</PlusIconContainer>
					)}
				</>
			)}
		</>
	);
};

export default CreditOrLeasingLiabilitiesInputs;
