import { useNavigate } from 'react-router-dom';
import {
	getSessionStorageValue,
	guidKey,
} from 'src/common/sessionStorage/SessionStorageService';
import OnePageInfo from 'src/components/OnePageInfo/OnePageInfo';
import { LeadNewRoute } from 'src/routing/Routing';
import { interpolatePath } from 'src/routing/RoutingService';

const TechnicalErrorLead = (): JSX.Element => {
	const navigate = useNavigate();
	const guid = getSessionStorageValue(guidKey);

	const tryAgainButtonAction = (): void => {
		navigate(interpolatePath(LeadNewRoute.path, { guid }));
	};

	return (
		<OnePageInfo
			titleText='Serwis jest niedostępny'
			descriptionText='Prosimy, spróbuj ponownie za chwilę.'
			onClickHandler={tryAgainButtonAction}
			buttonDisabled={false}
			buttonText='Spróbuj ponownie'
		/>
	);
};

export default TechnicalErrorLead;
