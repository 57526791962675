import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { FieldErrors, FormProvider, SubmitHandler } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import CustomButton from 'src/components/CustomButton/CustomButton';
import useFetch from '../../../../common/api/UseFetch';
import { groupAgreementsBySection } from '../../../../common/helpers/group-agreements-by-section-helper';
import { WebPage } from '../../../../routing/Routing';
import LightCompanyDataInfoSection from '../../components/light-company-data/components/LightCompanyDataInfoSection';
import { ScrollToTopOnMount } from '../../../../common/helpers/scroll-to-top-onmount';
import React, { useContext, useEffect, useState } from 'react';
import { checkErrors, saveAgreements } from 'src/store/slices/agreementsSlice/AgreementsSlice';
import { GetCompanyDataAgreementsConfig } from './api/LightCompanyDataApi';
import LightFinancialData from './components/LightFinancialData';
import LightPersonalDataInfoSection from './components/LightPersonalDataInfoSection';
import { LightCompanyDataContext, LightCompanyDataContextProvider } from './state/LightCompanyDataContext';
import { pushLightEventVirtualpvCompanyData } from 'src/common/googleAnalyticsEvents/googleAnalyticsEvents';
import { NestFormInfo } from 'src/components/FormItems/NestFormInfo/NestFormInfo';

const LightCompanyDataComp = (): JSX.Element => {
	const lightCompanyDataContext = useContext(LightCompanyDataContext);
	const dispatch = useDispatch();
	const [validAgreements, setValidAgreements] = useState<boolean>(true);
	const [clickedSubmit, setClickedSubmit] = useState<boolean>(false);

	const checkInputsValidation = (): boolean =>
		Object.keys(lightCompanyDataContext.methods ? lightCompanyDataContext.methods.formState.errors : {}).length === 0;

	const checkAgreements = (): boolean =>
		lightCompanyDataContext.agreementState.agreements.every((agreement): boolean | undefined => agreement.isSelected) ||
		!lightCompanyDataContext.agreementState.isValid;

	const updateAgreementsStatus = (): void => {
		if (checkInputsValidation() && checkAgreements()) {
			dispatch(checkErrors());
			setValidAgreements(lightCompanyDataContext.agreementState.isValid);
		}
	};

	useEffect((): void => {
		updateAgreementsStatus();
	}, [
		JSON.stringify(lightCompanyDataContext.agreementState.agreements),
		lightCompanyDataContext.agreementState.isValid,
		JSON.stringify(lightCompanyDataContext.methods?.formState.errors),
	]);

	useEffect((): void => {
		pushLightEventVirtualpvCompanyData();
	}, []);

	const _getAgreements = useFetch(GetCompanyDataAgreementsConfig(WebPage.LightClCompanyData), true, (data): void => {
		dispatch(saveAgreements(groupAgreementsBySection(data)));
	});

	const scrollToErrors = (errors: FieldErrors<Record<string, unknown>>): void => {
		const firstErrorField = Object.keys(errors)[0];
		const isFieldComplex = !errors[firstErrorField]?.ref;

		if (clickedSubmit && firstErrorField) {
			if (isFieldComplex) {
				document
					.querySelector(`[name='${firstErrorField}.${Object.keys(errors[firstErrorField] as object)[0]}']`)
					?.scrollIntoView({ block: 'center', behavior: 'smooth' });
			} else {
				document.querySelector(`[name='${firstErrorField}']`)?.scrollIntoView({ block: 'center', behavior: 'smooth' });
			}
			setClickedSubmit(false);
		}
	};

	useEffect((): void => {
		scrollToErrors(lightCompanyDataContext.methods.formState.errors);
	}, [JSON.stringify(lightCompanyDataContext.methods.formState.errors), clickedSubmit]);

	const preSubmitHandler = (): void => {
		dispatch(checkErrors());
		setClickedSubmit(true);
		lightCompanyDataContext?.methods?.handleSubmit(lightCompanyDataContext.onSubmit as unknown as SubmitHandler<Record<string, unknown>>);
	};

	return (
		<Stack
			sx={{
				padding: '0 16px 16px 16px',
				margin: 'auto',
				maxWidth: '860px',
				color: '#16161E',
			}}
		>
			<Stack sx={{ width: '100%' }}>
				<ScrollToTopOnMount />
				<FormProvider {...lightCompanyDataContext?.methods}>
					<form
						onSubmit={lightCompanyDataContext?.methods?.handleSubmit(
							lightCompanyDataContext.onSubmit as unknown as SubmitHandler<Record<string, unknown>>
						)}
					>
						<Typography
							variant='h2'
							sx={{
								padding: { xs: '0 0 32px 0', md: '0 0 42px 0' },
								fontSize: { xs: '28px', md: '34px' },
								lineHeight: { xs: '33px', md: '40px' },
							}}
						>
							Informacje o Tobie i Twojej firmie
						</Typography>

						<LightPersonalDataInfoSection />

						<LightCompanyDataInfoSection methods={lightCompanyDataContext.methods} />

						<LightFinancialData />

						<Stack
							sx={{
								padding: { xs: '32px 0 16px 0', md: '32px 0 44px 0' },
								flexDirection: { xs: 'column', md: 'row' },
								justifyContent: 'flex-end',
								alignItems: 'center',
							}}
						>
							<Stack sx={{ alignItems: 'center', padding: { xs: '32px 0 0 0', md: '0 0 0 0' } }}>
								<Typography
									variant='p3'
									sx={{
										padding: '0 0 24px 0',
										textAlign: 'center',
									}}
								>
									Zostaniesz bezpiecznie przekierowany do swojej
									<br />
									bankowości internetowej a następnie wrócisz do wniosku.
								</Typography>
								<Stack
									sx={{
										flexDirection: 'column',
									}}
								>
									{(!checkInputsValidation() || !validAgreements) && <NestFormInfo />}
									<CustomButton
										isLoading={
											lightCompanyDataContext.postLightCompanyDataIsLoading || lightCompanyDataContext.incomeVerificationLightCompanyDataIsLoading || 
											lightCompanyDataContext.postLightCompanyDataLoaded || lightCompanyDataContext. incomeVerificationLightCompanyDataLoaded
										}
										variant='contained'
										type='submit'
										onClick={preSubmitHandler}
									>
										Dalej
									</CustomButton>
								</Stack>
							</Stack>
						</Stack>
					</form>
				</FormProvider>
			</Stack>
		</Stack>
	);
};

const LightCompanyData = (): JSX.Element => {
	return (
		<LightCompanyDataContextProvider>
			<LightCompanyDataComp />
		</LightCompanyDataContextProvider>
	);
};

export default LightCompanyData;
