import { getYearsArray, months } from '../../NestDatePickerConstants';
import { ChangeEvent, useEffect } from 'react';
import rightArrow from '../../../../../common/images/right-arrow.svg';
import leftArrow from '../../../../../common/images/left-arrow.svg';
import {
	DatePickerHeader,
	DatePickerSelect,
	Image,
	Select,
} from './NestDatePickerHeader.css';
import Typography from '@mui/material/Typography';
import { SelectChangeEvent } from '@mui/material/Select';

interface INestDatePickerProps {
	selectedMonth: number | null;
	selectedYear: number | null;
	setSelectedMonth: React.Dispatch<React.SetStateAction<number | null>>;
	setSelectedYear: React.Dispatch<React.SetStateAction<number | null>>;
	date: Date;
	changeYear(year: number): void;
	decreaseMonth(): void;
	increaseMonth(): void;
}

const NestDatePickerHeader = ({
	date,
	changeYear,
	decreaseMonth,
	increaseMonth,
	selectedMonth,
	selectedYear,
	setSelectedMonth,
	setSelectedYear,
}: INestDatePickerProps): JSX.Element => {
	useEffect((): void => {
		setSelectedMonth(date.getMonth());
		setSelectedYear(date.getFullYear());
	}, []);

	const handleYearChange = (
		event: SelectChangeEvent<HTMLSelectElement>
	): void => {
		setSelectedYear(Number(event.target.value));
		changeYear(Number(event.target.value));
	};

	const handleNextMonthClicked = (): void => {
		if (selectedMonth !== null && selectedYear !== null) {
			if (selectedMonth >= 11) {
				setSelectedMonth(0);
				setSelectedYear(selectedYear + 1);
			} else {
				setSelectedMonth(selectedMonth + 1);
			}
			increaseMonth();
		}
	};

	const handlePrevMonthClicked = (): void => {
		if (selectedMonth !== null && selectedYear !== null) {
			if (selectedMonth <= 0) {
				setSelectedMonth(11);
				setSelectedYear(selectedYear - 1);
			} else {
				setSelectedMonth(selectedMonth - 1);
			}
			decreaseMonth();
		}
	};

	return (
		<DatePickerHeader>
			<DatePickerSelect>
				<Typography variant='p1'>{months[date.getMonth()]}</Typography>
				<Select
					value={selectedYear ?? 0}
					onChange={(e: ChangeEvent<HTMLSelectElement>): void =>
						handleYearChange(
							e as unknown as SelectChangeEvent<HTMLSelectElement>
						)
					}
				>
					{getYearsArray().map((year: number): JSX.Element => {
						return (
							<option key={year} value={year}>
								{year}
							</option>
						);
					})}
				</Select>
			</DatePickerSelect>
			<div>
				<Image src={leftArrow} onClick={handlePrevMonthClicked} />
				<Image src={rightArrow} onClick={handleNextMonthClicked} />
			</div>
		</DatePickerHeader>
	);
};

export default NestDatePickerHeader;
