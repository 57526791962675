/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
import Grid from '@mui/material/Grid';
import { blockWritingCurrencyMoreThanOneBilliard } from '../../../../../../common/helpers/block-writing-keys-helper';
import { IAddressPropertiesError } from '../../../../../../common/models/addressAndErrorsModels';
import AddressForm from '../../../../../../components/AddressForm/AddressForm';
import { IAddressFormProperties } from '../../../../../../components/AddressForm/AddressFormModels';
import NestInput from '../../../../../../components/FormItems/NestInput/NestInput';
import {
	gridFullWidth,
	gridHalfWidth,
} from '../../../../../../theme/grid/GridConstants';
import { Control, FieldErrorsImpl, UseFormReturn } from 'react-hook-form';
import React from 'react';
import {
	realEstateInitObj,
	TypeOfProperty,
} from '../ClientWealthFormConstants';
import Typography from '@mui/material/Typography';
import ClientWealthAddDeleteButtons from '../ClientWealthAddDeleteButtons/ClientWealthAddDeleteButtons';
import {
	ClientWealthAppend,
	ClientWealthRemove,
	IClientWealthFormErrors,
	IClientWealthRealEstate,
} from '../../../PersonalDataModels';
import NestSelect from '../../../../../../components/FormItems/NestSelect/NestSelect';
import { amountDynamicMask } from 'src/common/helpers/mask-helper';

interface ClientWealthRealEstateProps {
	methods: UseFormReturn<Record<string, unknown>>;
	index: number;
	append: ClientWealthAppend<IClientWealthRealEstate>;
	remove: ClientWealthRemove<IClientWealthRealEstate>;
}

const ClientWealthRealEstate = (
	props: ClientWealthRealEstateProps
): JSX.Element => {
	const watchClientWealth: IClientWealthRealEstate[] = props.methods.watch(
		`clientWealth[${props.index}].name`
	);

	const clientWealthErrors = (
		props.methods.formState.errors.clientWealth as unknown as
			| IClientWealthFormErrors[]
			| undefined
	)?.[props.index]?.name;

	const getFormControlName = (index: number): IAddressFormProperties => {
		return {
			street: `clientWealth[${props.index}].name[${index}].street`,
			houseNumber: `clientWealth[${props.index}].name[${index}].houseNumber`,
			apartmentNumber: `clientWealth[${props.index}].name[${index}].apartmentNumber`,
			postalCode: `clientWealth[${props.index}].name[${index}].postalCode`,
			city: `clientWealth[${props.index}].name[${index}].city`,
		};
	};

	const getErrors = (index: number): IAddressPropertiesError => {
		return {
			street: clientWealthErrors?.[index]?.street,
			houseNumber: clientWealthErrors?.[index]?.houseNumber,
			apartmentNumber: clientWealthErrors?.[index]?.apartmentNumber,
			postalCode: clientWealthErrors?.[index]?.postalCode,
			city: clientWealthErrors?.[index]?.city,
		};
	};

	return (
		<>
			<Typography variant='h5' sx={{ width: '100%', marginBottom: '24px' }}>
				Nieruchomość
			</Typography>
			{watchClientWealth.map(
				(item: IClientWealthRealEstate, index: number): JSX.Element => {
					return (
						<div style={{ width: '100%', marginBottom: '24px' }} key={index}>
							<Grid container rowSpacing={4} columnSpacing={9}>
								<Grid item xs={gridFullWidth} md={gridHalfWidth}>
									<NestSelect
										rules={{ required: 'Pole wymagane' }}
										control={props.methods.control as unknown as Control}
										name={`clientWealth[${props.index}].name[${index}].typeOfRealEstate`}
										label='Rodzaj nieruchomości'
										options={[
											['Dom jednorodzinny', 1],
											['Dom wielorodzinny', 2],
											['Lokal', 3],
											['Mieszkanie', 4],
										]}
										errors={clientWealthErrors?.[index]?.typeOfRealEstate as {type: string; message: string}}
										placeholderOption={['Wybierz rodzaj nieruchomości', 0]}
									/>
								</Grid>
								<AddressForm
									methods={props.methods}
									formControlNames={getFormControlName(index)}
									errors={
										getErrors(index) as Partial<
											FieldErrorsImpl<Record<string, unknown>>
										>
									}
								/>
								<Grid item xs={gridFullWidth} md={gridHalfWidth}>
									<NestInput
										label='Wartość'
										maskFunc={amountDynamicMask}
										mask=''
										blockWritingSomeValueFunc={blockWritingCurrencyMoreThanOneBilliard}
										type='text'
										control={props.methods.control as unknown as Control}
										name={`clientWealth[${props.index}].name[${index}].worth`}
										errors={clientWealthErrors?.[index]?.worth as {type: string; message: string}}
										rules={{
											required: 'Pole wymagane',
											min: {value: 1, message: 'Wartość powinna być większa niż 0'}
										}}
									/>
								</Grid>
							</Grid>
							<ClientWealthAddDeleteButtons
								append={(): void =>
									props.append(
										`clientWealth[${props.index}].name`,
										realEstateInitObj,
										watchClientWealth
									)
								}
								remove={(): void =>
									props.remove(
										`clientWealth[${props.index}].name`,
										item.id,
										TypeOfProperty.RealEstate,
										watchClientWealth
									)
								}
							/>
						</div>
					);
				}
			)}
		</>
	);
};

export default ClientWealthRealEstate;
