import {
	IClientWealth,
	IClientWealthMachine,
	IClientWealthOther,
	IClientWealthRealEstate,
	IClientWealthVehicle,
} from '../../PersonalDataModels';

export enum TypeOfProperty {
	RealEstate = 1,
	Vehicle = 2,
	Machine = 3,
	Other = 4,
}

export const realEstateInitObj: IClientWealthRealEstate = {
	id: new Date().getTime(),
	typeOfRealEstate: [],
	street: '',
	houseNumber: '',
	apartmentNumber: '',
	postalCode: '',
	city: '',
	worth: '',
};

export const vehicleInitObj: IClientWealthVehicle = {
	id: new Date().getTime(),
	brand: '',
	yearOfProduction: '',
	worth: '',
};

export const machineInitObj: IClientWealthMachine = {
	id: new Date().getTime(),
	brand: '',
	yearOfProduction: '',
	worth: '',
};

export const otherInitObj: IClientWealthOther = {
	id: new Date().getTime(),
	name: '',
	worth: '',
};

export const typeOfPropertyOptions: IClientWealth[] = [
	{
		type: TypeOfProperty.RealEstate,
		placeholder: 'Nieruchomości',
		name: [realEstateInitObj],
		worth: 0,
		active: false,
	},
	{
		type: TypeOfProperty.Vehicle,
		placeholder: 'Samochód',
		name: [vehicleInitObj],
		worth: 0,
		active: false,
	},
	{
		type: TypeOfProperty.Machine,
		placeholder: 'Maszyna',
		name: [machineInitObj],
		worth: 0,
		active: false,
	},
	{
		type: TypeOfProperty.Other,
		placeholder: 'Inne',
		name: [otherInitObj],
		worth: 0,
		active: false,
	},
];
