import BaseFetch from '../../../../common/api/BaseFetch';
import { ApiConfig } from '../../../../common/api/FetchTypes';
import {
	IPersonalDataPropertiesToSend,
	PersonalDataProperties,
} from '../PersonalDataModels';

const getPersonalDataKey = 'getPersonalDataKey';
const postPersonalDataKey = 'postPersonalDataKey';
const draftPersonalDataKey = 'draftPersonalDataKey';

function getPersonalData(_props: never): Promise<PersonalDataProperties> {
	return BaseFetch({ url: 'ClientPersonalData', method: 'GET' });
}

function sendPersonalData(data: PersonalDataProperties): Promise<never> {
	return BaseFetch({ url: 'ClientPersonalData', method: 'POST', body: data });
}

function draftPersonalData(data: PersonalDataProperties): Promise<never> {
	return BaseFetch({
		url: 'ClientPersonalData/draft',
		method: 'POST',
		body: data,
	});
}

export const GetPersonalDataConfig: ApiConfig<
	IPersonalDataPropertiesToSend,
	never
> = {
	key: getPersonalDataKey,
	fn: getPersonalData,
};

export const PostPersonalDataConfig: ApiConfig<never, PersonalDataProperties> =
	{
		key: postPersonalDataKey,
		fn: sendPersonalData,
	};

export const DraftPersonalDataConfig: ApiConfig<never, PersonalDataProperties> =
	{
		key: draftPersonalDataKey,
		fn: draftPersonalData,
	};
