import BaseFetch from '../../../../common/api/BaseFetch';
import { ApiConfig, ApiVersion } from '../../../../common/api/FetchTypes';

export interface LimitConfig {
	commission: number;
	markUp: number;
	minLimitAmount: number;
	maxLimitAmount: number;
	agreementPeriod: number;
	requiredPercentageOfLimitAmount: number;
	standbyCommission: number;
	limitPaymentMinimumDaysNumber: number;
	limitPaymentMaximumDaysNumber: number;
	wibor: number;
	bankAccountNumber: string;
	isNestClient: boolean;
}

const getLimitConfigKey = 'getLimitConfig';

const getLimitConfig = (_prop: never): Promise<LimitConfig> => {
	return BaseFetch({
		url: 'Offer/OverdraftConfig',
		method: 'GET',
		version: ApiVersion.LightOvd,
	});
};

export const getLimitDataConfig: ApiConfig<LimitConfig, never> = {
	fn: getLimitConfig,
	key: getLimitConfigKey,
};

export interface CalculateLimitQuery {
	limitAmount: number;
}

const saveCalculationKey = 'saveCalcualtion';

const saveCalculation = (props: CalculateLimitQuery): Promise<never> => {
	return BaseFetch({
		url: 'Offer/SaveOverdraftInfo',
		method: 'POST',
		body: props,
		version: ApiVersion.LightOvd,
	});
};

export const saveCalculationConfig: ApiConfig<never, CalculateLimitQuery> = {
	fn: saveCalculation,
	key: saveCalculationKey,
};
