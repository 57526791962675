import { FileType } from '../../../../../enums/FileType';
import { secondaryMainColor } from '../../../../../theme/palette/palette';
import { typography } from '../../../../../theme/typography/typography';
import { SmsContractDataModel } from '../../../../rbf/rbf-sms-contract-signing/SmsContractSigningDataModels';

export const defaultValues: SmsContractDataModel = {
	smsConfirmationCode: '',
};

export const documentsRegardingCredit = [
	{
		fileName: 'Umowa limitu.pdf',
		fileType: FileType.ContractDraft,
	},
	{
		fileName: 'Regulamin.pdf',
		fileType: FileType.ContractRegulations,
	},
	{
		fileName: 'Tabela oprocentowania.pdf',
		fileType: FileType.InterestRateTable,
	},
	{
		fileName: 'Tabela opłat i prowizji.pdf',
		fileType: FileType.TableOfFeesAndCommissions,
	},
];


export const submitButtonFileStyles = {
	color: secondaryMainColor,
	...typography.h7,
	fontFamily: 'Athletics, sans-serif',
	border: 0,
	padding: 0,
	background: 'unset',
	width: 'unset',
	height: '100%',
};
