import BaseFetch from '../../../../common/api/BaseFetch';
import { ApiConfig } from '../../../../common/api/FetchTypes';
import { IContactDataProperties } from '../ContactDataModels';

const getContactDataKey = 'getContactData';
const postContactDataKey = 'postContactDataKey';

function getContactData(_props: never): Promise<IContactDataProperties> {
	return BaseFetch({ url: 'ClientContactData', method: 'GET' });
}

function sendContactData(data: IContactDataProperties): Promise<never> {
	return BaseFetch({ url: 'ClientContactData', method: 'POST', body: data });
}

export const GetContactDataConfig: ApiConfig<IContactDataProperties, never> = {
	key: getContactDataKey,
	fn: getContactData,
};

export const PostContactDataConfig: ApiConfig<never, IContactDataProperties> = {
	key: postContactDataKey,
	fn: sendContactData,
};
