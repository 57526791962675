import { styled } from '@mui/material/styles';
import { mainTheme } from '../../../../theme/mainTheme';

export const HorizontalLine = styled('div')({
	width: '100%',
	borderBottom: `2px solid ${mainTheme.palette.senary.main}`,
	marginTop: '32px',
	marginBottom: '16px',
	[mainTheme.breakpoints.up('xs')]: {
		marginTop: '32px',
		marginBottom: '32px',
	},
	[mainTheme.breakpoints.up('md')]: {
		marginTop: '32px',
		marginBottom: '16px',
	},
});
