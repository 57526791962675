import { styled } from '@mui/system';
import Tooltip, { TooltipProps, tooltipClasses } from '@mui/material/Tooltip';
import { CSSProperties, makeStyles } from '@mui/styles';
import { Theme } from '@mui/system/createTheme/createTheme';
import { SxProps } from '@mui/system/styleFunctionSx/styleFunctionSx';
import tooltipImg from '../../common/images/tooltip.svg';
import { Image } from './NestTooltip.css';

export interface NestTooltipProps {
	text: string | JSX.Element;
	width?: string;
	sx?: SxProps<Theme>;
	sxTooltipContent?: SxProps;
}

const useStyles = makeStyles(
	(): Record<string, Record<string, string>> => ({
		tooltip: {
			marginTop: '6px !important',
			textAlign: 'justify',
		},
	})
);

export default function NestTooltip(props: Readonly<NestTooltipProps>): JSX.Element {
	const classes = useStyles();

	const LightTooltip = styled(
		({ className, ...props }: TooltipProps): JSX.Element => (
			<Tooltip {...props} enterTouchDelay={0} leaveTouchDelay={20000} classes={{ popper: className, tooltip: classes.tooltip }} />
		)
	)(
		({ theme }): CSSProperties => ({
			[`& .${tooltipClasses.tooltip}`]: {
				backgroundColor: 'white',
				padding: '4px 8px',
				borderRadius: '4px',
				// eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-member-access
				color: theme.palette.text?.secondary ?? '',
				boxShadow: '5px 5px 12px 0px rgb(31 31 39 / 71%)',
				maxWidth: 'unset',
			},
			[`& .${tooltipClasses.arrow}`]: {
				color: 'white',
			},
		})
	);

	return (
		<LightTooltip title={props.text} arrow placement='bottom-start' sx={props.sxTooltipContent}>
			<Image src={tooltipImg} width={props.width ?? '20px'} height={props.width ?? '20px'} sx={{ ...props.sx }} />
		</LightTooltip>
	);
}
