export enum TypeOfApp {
    LEAD_CL = 1,
    LEAD_OVD  = 2,
    LIGHT_CL  = 3,
    LIGHT_OVD = 4
}

export function resolveIndexTokenByType(queryParamName: string = 't'): { token: string; type: TypeOfApp } {
    const queryParams = new URLSearchParams(window.location.search);
    const type = queryParams.get(queryParamName);

    switch (type) {
        case 'CLA':
            return { token: window.CONFIG.LEAD_CL_TOKEN[0], type: TypeOfApp.LEAD_CL } as { token: string; type: TypeOfApp };
        case 'CLC':
            return { token: window.CONFIG.LEAD_CL_TOKEN_2[0], type: TypeOfApp.LEAD_CL } as { token: string; type: TypeOfApp };
        case 'CLE':
            return { token: window.CONFIG.LEAD_CL_TOKEN_3[0], type: TypeOfApp.LEAD_CL } as { token: string; type: TypeOfApp };
        case 'CLF':
            return { token: window.CONFIG.LEAD_CL_TOKEN_5[0], type: TypeOfApp.LEAD_CL } as { token: string; type: TypeOfApp };
        case 'CLD':
            return { token: window.CONFIG.LEAD_CL_TOKEN_4[0], type: TypeOfApp.LEAD_CL } as { token: string; type: TypeOfApp };
        case 'CLB':
            return { token: window.CONFIG.LEAD_CL_TOKEN_1[0], type: TypeOfApp.LEAD_CL } as { token: string; type: TypeOfApp };
        case 'LCLA':
            return { token: window.CONFIG.LCL_TOKEN[0], type: TypeOfApp.LIGHT_CL } as { token: string; type: TypeOfApp };
        case 'LOVD':
            return { token: window.CONFIG.LOV_TOKEN[0], type: TypeOfApp.LIGHT_OVD } as { token: string; type: TypeOfApp };
        default:
            return { token: window.CONFIG.LEAD_OVD_TOKEN_1[0], type: TypeOfApp.LEAD_OVD } as { token: string; type: TypeOfApp };
    }
}
