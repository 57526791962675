import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { Control, FieldErrors, FormProvider, SubmitHandler, UseFormReturn } from 'react-hook-form';
import CustomButton from 'src/components/CustomButton/CustomButton';
import { ApplicationProcess } from '../../../../common/constants/application-process';
import { blockWritingCurrencyMoreThanOneBilliard } from '../../../../common/helpers/block-writing-keys-helper';
import { ScrollToTopOnMount } from '../../../../common/helpers/scroll-to-top-onmount';
import NestInput from 'src/components/FormItems/NestInput/NestInput';
import { amountDynamicMask, simpleDigitsMask } from 'src/common/helpers/mask-helper';
import NestTooltip from 'src/components/NestTooltip/NestTooltip';
import React, { useContext, useEffect, useState } from 'react';
import { textColorPrimary, textColorSecondary } from 'src/theme/palette/palette';
import { applicationProcessKey, getSessionStorageValue } from '../../../../common/sessionStorage/SessionStorageService';
import NestCollapse from '../../../../components/NestCollapse/NestCollapse';
import LightCompanyDataInfoSection from '../../components/light-company-data/components/LightCompanyDataInfoSection';
import { OverrideProps } from '../../../../routing/RouteSpec';
import { useDispatch } from 'react-redux';
import { checkErrors } from 'src/store/slices/agreementsSlice/AgreementsSlice';
import FlatAgreementCollection from 'src/components/AgreementCollection/FlatAgreementCollection';
import Loader from 'src/components/Loader/Loader';
import { LightCompanyDataVerifiedContext, LightCompanyDataVerifiedContextProvider } from './state/LightCompanyDataVerifiedContext';
import { pushLightEventVirtualpvCompanyDataVerified } from 'src/common/googleAnalyticsEvents/googleAnalyticsEvents';
import { NestFormInfo } from 'src/components/FormItems/NestFormInfo/NestFormInfo';

const LightCompanyDataVerifiedComp = (props?: OverrideProps): JSX.Element => {
	const lightCompanyDataVerifiedContext = useContext(LightCompanyDataVerifiedContext);
	const dispatch = useDispatch();
	const currentAppProcess: ApplicationProcess | undefined = Number(getSessionStorageValue(applicationProcessKey) as ApplicationProcess | undefined);
	const [clickedSubmit, setClickedSubmit] = useState<boolean>(false);

	useEffect((): void => {
		pushLightEventVirtualpvCompanyDataVerified();
	}, []);

	useEffect((): void => {
		lightCompanyDataVerifiedContext?.setOverrideProps(props);
	}, []);

	useEffect((): void => {
		dispatch(checkErrors());
	}, [lightCompanyDataVerifiedContext.agreementState.allAgreementsChecked]);

	const scrollToErrors = (errors: FieldErrors<Record<string, unknown>>): void => {
		const firstErrorField = Object.keys(errors)[0];
		const isFieldComplex = !errors[firstErrorField]?.ref;

		if (clickedSubmit && firstErrorField) {
			if (isFieldComplex) {
				document
					.querySelector(`[name='${firstErrorField}.${Object.keys(errors[firstErrorField] as object)[0]}']`)
					?.scrollIntoView({ block: 'center', behavior: 'smooth' });
			} else {
				document.querySelector(`[name='${firstErrorField}']`)?.scrollIntoView({ block: 'center', behavior: 'smooth' });
			}
			setClickedSubmit(false);
		}
	};

	useEffect((): void => {
		scrollToErrors(lightCompanyDataVerifiedContext.methods.formState.errors);
	}, [JSON.stringify(lightCompanyDataVerifiedContext.methods.formState.errors), clickedSubmit]);

	const preSubmitHandler = (): void => {
		dispatch(checkErrors());
		setClickedSubmit(true);
		lightCompanyDataVerifiedContext.methods.handleSubmit(
			lightCompanyDataVerifiedContext.onSubmit as unknown as SubmitHandler<Record<string, unknown>>
		);
	};

	return (
		<Stack
			sx={{
				padding: '16px',
				margin: 'auto',
				maxWidth: '835px',
				color: textColorPrimary,
			}}
		>
			<Stack sx={{ width: '100%' }}>
				<ScrollToTopOnMount />
				<FormProvider {...lightCompanyDataVerifiedContext.methods}>
					<form
						onSubmit={lightCompanyDataVerifiedContext.methods.handleSubmit(
							lightCompanyDataVerifiedContext.onSubmit as unknown as SubmitHandler<Record<string, unknown>>
						)}
					>
						<Typography
							variant='h2'
							sx={{
								padding: { xs: '0 0 32px 0', md: '0 0 42px 0' },
								fontSize: { xs: '28px', md: '34px' },
								lineHeight: { xs: '33px', md: '40px' },
							}}
						>
							Informacje o Twojej firmie
						</Typography>
						<NestCollapse
							content=''
							isStatic={true}
							hideCloseIcon={true}
							dynamicTitle={['Zwiń', 'Rozwiń']}
							sxContent={{
								width: '100%',
								background: 'inherit',
								border: 'unset',
								padding: '0',
								overflowY: 'unset',
								color: 'inherit',
							}}
							sxTitle={{ fontWeight: '700' }}
						>
							<LightCompanyDataInfoSection methods={lightCompanyDataVerifiedContext.methods as unknown as UseFormReturn<Record<string, unknown>>} />
						</NestCollapse>
						<Typography
							variant='h5'
							sx={{
								width: '100%',
								padding: { xs: '8px 0 24px 0', md: '17px 0 24px 0' },
							}}
						>
							Dane finansowe
						</Typography>
						<Typography
							variant='p1'
							sx={{
								width: '100%',
							}}
						>
							Średniomiesięczny przychód firmy został wyliczony na podstawie obrotów z udostępnionego przez Ciebie rachunku.
						</Typography>

						<Stack sx={{ flexDirection: 'row', marginTop: '24px' }}>
							<Stack sx={{ position: 'relative', maxWidth: '380px', width: '100%' }}>
								<NestInput
									label='Średniomiesięczny przychód firmy'
									maskFunc={amountDynamicMask}
									mask=''
									control={lightCompanyDataVerifiedContext.methods.control as unknown as Control}
									name='companyAverageMonthlyIncome'
									wrapperSx={{ width: '100%' }}
									rules={{
										required: 'Pole wymagane',
									}}
									blockWritingSomeValueFunc={blockWritingCurrencyMoreThanOneBilliard}
									currencyTextOrPlainText='PLN'
								/>
							</Stack>
							<Stack sx={{ alignSelf: 'flex-end', margin: '0 0 22px 16px' }}>
								<NestTooltip text='Jeżeli nie akceptujesz wyliczonej przez nas kwoty, wprowadź swoją wartość' width={'24px'} />
							</Stack>
						</Stack>

						{Object.keys(lightCompanyDataVerifiedContext.agreementState.agreements).length > 0 ? <FlatAgreementCollection /> : <Loader />}
						{currentAppProcess === ApplicationProcess.LightCl && (
							<>
								<NestInput
									label='Numer rachunku bankowego Twojej firmy'
									control={lightCompanyDataVerifiedContext.methods.control as unknown as Control}
									name='bankAccountNumber'
									mask='## #### #### #### #### #### ####'
									maskFunc={simpleDigitsMask}
									wrapperSx={{ maxWidth: '380px' }}
									disabled
								/>
								<Stack sx={{ maxWidth: '290px' }}>
									<Typography variant='p3' sx={{ padding: '12px 0 4px 0' }}>
										{`Twój bank: ${(lightCompanyDataVerifiedContext?.methods?.watch('bankName') as string) ?? 'bank'}. `}
									</Typography>
									<Typography variant='p3'>Na ten numer rachunku bankowego zostaną przelane środki z kredytu.</Typography>
								</Stack>
							</>
						)}
						<Stack
							sx={{
								padding: { xs: '32px 0 16px 0', md: '22px 0 44px 0' },
								flexDirection: { md: 'row', sx: 'column' },
								justifyContent: 'space-between',
								alignItems: { md: 'flex-end', xs: 'center' },
							}}
						>
							<Stack>
								{props?.cancelText && (
									<CustomButton
										variant='outlined'
										onClick={lightCompanyDataVerifiedContext.cancelEditingPage}
										sx={{ marginBottom: { xs: '32px', md: '0' } }}
									>
										{lightCompanyDataVerifiedContext?.overrideProps?.cancelText}
									</CustomButton>
								)}
							</Stack>
							<Stack>
								{!props?.cancelText && (
									<Typography
										variant='p5'
										sx={{
											color: textColorSecondary,
											textAlign: 'center',
											width: '280px',
											marginBottom: '18px',
										}}
									>
										Przygotuj dowód osobisty.
										<br />
										Będzie potrzebny w kolejnym kroku.
									</Typography>
								)}
								<Stack
									sx={{
										flexDirection: 'column',
									}}
								>
									{(Object.keys(lightCompanyDataVerifiedContext.methods.formState.errors).length > 0 ||
										!lightCompanyDataVerifiedContext.agreementState.isValid) && clickedSubmit && <NestFormInfo />}
									<CustomButton variant='contained' type='submit' onClick={preSubmitHandler}>
										{props?.submitText ?? 'Dalej'}
									</CustomButton>
								</Stack>
							</Stack>
						</Stack>
					</form>
				</FormProvider>
			</Stack>
		</Stack>
	);
};

const LightCompanyDataVerified = (props?: OverrideProps): JSX.Element => {
	return (
		<LightCompanyDataVerifiedContextProvider>
			<LightCompanyDataVerifiedComp {...(props as OverrideProps)} />
		</LightCompanyDataVerifiedContextProvider>
	);
};

export default LightCompanyDataVerified;
