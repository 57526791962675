import { Switch, Typography } from '@mui/material';
import Grid from '@mui/material/Grid';
import React from 'react';
import { UseFormReturn } from 'react-hook-form';
import { gridFullWidth } from '../../../../../../theme/grid/GridConstants';
import CreditOrLeasingLiabilitiesInputs from './components/CreditOrLeasingLiabilitiesInputs/CreditOrLeasingLiabilitiesInputs';

interface CreditOrLeasingLiabilitiesProps {
	methods: UseFormReturn<Record<string, unknown>>;
}

const CreditOrLeasingLiabilities = (props: CreditOrLeasingLiabilitiesProps): JSX.Element => {
	const watchCreditOrLeasingLiabilitiesAnyLiabilitiesGiven = props.methods.watch('creditOrLeasingLiabilities.anyLiabilitiesGiven') as boolean;

	const switchHandleChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
		props.methods.setValue('creditOrLeasingLiabilities.anyLiabilitiesGiven', event.target.checked as never);
	};

	return (
		<>
			<Grid container sx={{ width: { md: '83%', xs: '100%' } }}>
				<Grid
					item
					xs={gridFullWidth}
					sx={{
						paddingTop: '0',
						justifyContent: 'space-between',
						width: '70%',
					}}
					display='flex'
					alignItems='center'
				>
					<Typography variant='p1' sx={{ marginRight: '16px', marginBottom: {xs: watchCreditOrLeasingLiabilitiesAnyLiabilitiesGiven ? '32px' : '10px', md: '8px'} }}>
						Podaję informację o zobowiązaniach kredytowych lub leasingowych w innych bankach (poza Nest Bank S.A.) lub instytucjach pożyczkowych
					</Typography>
					<Switch
						sx={{ margin: { md: '28px 0', xs: '0' } }}
						checked={watchCreditOrLeasingLiabilitiesAnyLiabilitiesGiven}
						onChange={switchHandleChange}
					/>
				</Grid>
			</Grid>
			{watchCreditOrLeasingLiabilitiesAnyLiabilitiesGiven && (
				<>
					<CreditOrLeasingLiabilitiesInputs
						methods={props.methods}
						inputLabelText="Kwota miesięcznej raty"
						controlNameType='cashLoanLiabilities'
						switchHeader={'Kredyty spłacane w ratach (np. gotówkowe, mieszkaniowe, inwestycyjne), poza Nest Bank'}
						tooltipText={<div><b>W przypadku zobowiązań firmowych</b>: jeżeli odsetki ujmowane są jako koszty uzyskania przychodów (dotyczy, jeśli rozliczasz się w oparciu o KPIR) – wpisz wysokość każdej z rat kapitałowych, w pozostałych przypadkach – wpisz wysokość każdej z rat kapitałowo-odsetkowych. <br/><br/><b>W przypadku zobowiązań indywidualnych</b>: wpisz wysokość rat kapitałowo – odsetkowych. W przypadku zobowiązań wspólnych podaj wysokość pełnej raty.</div>}
					/>
					<CreditOrLeasingLiabilitiesInputs
						methods={props.methods}
						controlNameType='overdraftLiabilities'
						switchHeader={'Limity odnawialne i karty kredytowe, poza Nest Bank'}
						inputLabelText="Kwota przyznanego limitu"
						tooltipText='Wpisz wysokość kwoty przyznanego limitu/karty kredytowej firmowej lub indywidualnej posiadanej przez Ciebie.'
					/>
					<CreditOrLeasingLiabilitiesInputs
						methods={props.methods}
						controlNameType='leasingLiabilities'
						inputLabelText="Kwota miesięcznej raty"
						switchHeader={'Leasing'}
						tooltipText='Wpisz wysokość każdej miesięcznej raty leasingowej posiadanej przez Ciebie.'
					/>
				</>
			)}
		</>
	);
};

export default CreditOrLeasingLiabilities;
