import { IMultiSelectOptions } from '../../../../../../components/FormItems/NestMultiSelect/NestMultiSelectModels';

export const expectedProceedsTypeOptions: [string, string | number][] = [
    ['Wpływy ze sprzedaży towarów i usług', 1],
    ['Dotacje', 2],
    ['Zasilenia z własnych rachunków', 3],
    ['Zbiórki / składki członkowskie', 4],
];

export const expectedMonthlyProceedsAmountOptions: [string, string | number][] = [
    ['do 50.000 PLN', 1],
    ['do 100.000 PLN', 2],
    ['do 250.000 PLN', 3],
    ['powyżej 250.000 PLN', 4],
];

export const servicesPlannedToUseOptions: IMultiSelectOptions[] = [
    {id: 0, placeholder: 'Konto oszczędnościowe/lokata', active: false},
    {id: 1, placeholder: 'Konto walutowe / wymiana walut', active: false},
    {id: 2, placeholder: 'Produkty inwestycyjny', active: false},
    {id: 3, placeholder: 'Kredyt obrotowy w ratach', active: false},
    {id: 4, placeholder: 'Limit w koncie', active: false},
    {id: 5, placeholder: 'Przelewy natychmiastowe', active: false},
    {id: 6, placeholder: 'Faktoring (finansowanie faktur)', active: false},
    {id: 7, placeholder: 'Żadne z wymienionych', active: false},
    {id: 8, placeholder: 'Wpłaty we wpłatomacie/ w placówce', active: false},
]

export const plannedDepositsOnAccountAmountOptions: [string, string | number][] = [
    ['nie planuję regularnie wpłacać gotówki', 1],
    ['do 10 000 PLN', 2],
    ['do 50 000 PLN', 3],
    ['do 100 000 PLN', 4],
    ['powyżej 100 000 PLN', 5],
];
