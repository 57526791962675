import { Switch, Typography } from '@mui/material';
import { AgreementContainer, AgreementHeadingContainer, CenteredAgreementTextContainer } from './Agreement.css';
import { useDispatch, useSelector } from 'react-redux';
import { changeConsents } from '../../../store/slices/agreementsSlice/AgreementsSlice';
import { RootState } from '../../../store/State';
import { AgreementModel } from '../AgreementsModels';

interface AgreementProps {
	agreement: AgreementModel;
	mediaIndex?: number;
	agreementIndex: number;
	sectionIndex: number;
}

export default function FlatAgreement(props: Readonly<AgreementProps>): JSX.Element {
	const dispatch = useDispatch();
	const agreementsState = useSelector((state: RootState): AgreementModel[] => state.agreements.agreements[props.sectionIndex].consentGroup);

	const mainAgreement = agreementsState.find((x: AgreementModel): boolean => x.symbol === props.agreement.symbol);

	const handleCheckedChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
		dispatch(
			changeConsents({
				sectionIndex: props.sectionIndex,
				agreementIndex: props.agreementIndex,
				isSelected: event.target.checked,
				mediaIndex: props.mediaIndex,
				mainConsent: !!mainAgreement,
			})
		);
	};

	return (
		<AgreementContainer
			sx={{
				marginBottom: '25px',
			}}
		>
			<CenteredAgreementTextContainer>
				<AgreementHeadingContainer>
					{props.agreement.contentHtml ? (
						<Typography
							variant='p1'
							sx={{
								display: 'inline-block',
							}}
							dangerouslySetInnerHTML={{
								__html: props.agreement.contentHtml ? props.agreement.contentHtml : '',
							}}
						></Typography>
					) : (
						<Typography
							variant='p1'
							sx={{
								display: 'inline-block',
							}}
						>
							{props.agreement.content}
						</Typography>
					)}
					{props.agreement.isError && (
						<Typography variant='h9' sx={{ color: 'error.main', margin: '7px 0 12px 0' }}>
							Ta zgoda jest wymagana
						</Typography>
					)}
				</AgreementHeadingContainer>
				<Switch
					sx={{ marginLeft: { md: '40px', xs: '15px' } }}
					checked={props.agreement.isSelected}
					onChange={(x: React.ChangeEvent<HTMLInputElement>): void => handleCheckedChange(x)}
				/>
			</CenteredAgreementTextContainer>
		</AgreementContainer>
	);
}
