export const authTokenKey = 'auth-token';
export const applicationIdKey = 'application-id';
export const partnerLogoKey = 'logo-link';
export const tokenExpirationKey = 'token-expiration';
export const applicationNumberKey = 'application-number';
export const partnerNameKey = 'partner-name';
export const applicationProcessKey = 'application-process';
export const guidKey = 'guid';
export const processIdKey = 'process-id';
export const clientIdKey = 'client-id';
export const featureFlags = 'feature-flags';
export const locationKey = 'location';
export const marketingPartnerIdKey = 'marketing-partner-id';
export const userIdKey = 'user-id';

export const setSessionStorageValue = (key: string, token: string): void => {
	sessionStorage.setItem(key, token);
};

export const setSessionStorageObj = <T>(key: string, obj: T): void => {
	sessionStorage.setItem(key, JSON.stringify(obj));
};

export const getSessionStorageValue = (key: string): string | undefined => {
	return sessionStorage.getItem(key) ?? undefined;
};

export const getSessionStorageObj = <T>(key: string): T | undefined => {
	const value = sessionStorage.getItem(key);
	if (value) {
		try {
			const obj = JSON.parse(value) as unknown as T | undefined;
			return obj;
		} catch {
			return undefined;
		}
	}
	return undefined;
};

export const removeSessionStorageValue = (key: string): void => {
	sessionStorage.removeItem(key);
};
