export type QueryParamsType = Record<string, string | boolean | number>;

export interface DefaultErrorModel {
	ErrorCode?: string;
	Params?: string[];
}

export interface ClientFormErrorModel {
	details: string;
	extensions: Record<string, string>;
	status: number;
	title: string;
	type: string;
}

export interface ApiConfig<TResult, TVariables> {
	key: string;
	fn: (variables: TVariables) => Promise<TResult>;
}

export enum ApiVersion {
	Default = '/',
	Light = '-Light/',
	LightCL = '-LightCl/',
	LightOvd = '-LightOd/',
}
