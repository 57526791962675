import BaseFetch from '../../../common/api/BaseFetch';
import { ApiConfig } from '../../../common/api/FetchTypes';

interface RejectionReasonResult {
	description: string;
}

const getReturnFailKey = 'getReturnFailKey';

function getRejectionReason(): Promise<RejectionReasonResult> {
	return BaseFetch({
		url: 'Application/RejectionReason',
		method: 'GET',
	});
}

export const getRejectionReasonConfig: ApiConfig<RejectionReasonResult, never> =
	{
		key: getReturnFailKey,
		fn: getRejectionReason,
	};
