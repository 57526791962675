export const collapseContent = `<div style='font-size: 12px; text-align: justify;'>
    <p>
        Przedstawiona propozycja kredytu ma charakter wstępny i nie stanowi oferty w rozumieniu art. 66 Kodeksu cywilnego. <b>„BIZnest Kredyt w Ratach – Przedsiębiorca” oraz „BIZnest Kredyt w Ratach – Profesjonalista”</b> 
        to kredyty oferowane przez Nest Bank S.A. („Nest Bank” lub „Bank”) przedsiębiorcom jednoosobowym, na cele związane z prowadzoną przez nich działalnością gospodarczą. 
        Maksymalna kwota kredytu to kwota uwzględniająca kredytowane opłaty i prowizje, o które zostanie pomniejszona udostępniana klientowi kwota kredytu. Oprocentowanie kredytu jest zmienne, 
        zasady ustalania i zmiany oprocentowania kredytu określa umowa i regulamin.
    </p>

    <p>
        Przedstawiona w kalkulacji wysokość <b>szacunkowej raty miesięcznej</b> obliczana jest na dzień jej publikacji na dedykowanej stronie internetowej i może się różnić od ostatecznej wysokości raty miesięcznej. 
        Szacunkowa rata miesięczna przedstawia miesięczną ratę kapitałowo-odsetkową (i) wyliczoną od całkowitej kwoty kredytu <b>tj. 50 000 złotych</b> (z uwzględnieniem kredytowanych kosztów), 
        (ii) ma wartość zaokrągloną do dwóch miejsc po przecinku, (iii) została obliczona na podstawie parametrów wskazanych przez przedsiębiorcę na dedykowanej stronie internetowej przedstawiającej kalkulację. 
        Udzielenie kredytu oraz jego wysokość uzależnione są od pozytywnej oceny zdolności kredytowej przedsiębiorcy.
    </p>

    <p>
        W przypadku, gdy po złożeniu wniosku przedsiębiorca otrzyma pozytywną decyzję kredytową, do udzielenia kredytu konieczne jest przedłożenie dokumentacji wymaganej przez Bank i przeprowadzenie pozytywnej weryfikacji tej dokumentacji przez Bank. 
        Umowa o kredyt podpisywana jest w postaci elektronicznej w sposób określony w tej umowie.
    </p>

    <p>
        Możliwość korzystania ze zmiany daty płatności raty, zawieszenia spłaty rat kapitałowych na 3 miesiące oraz wydłużenia czasu kredytowania do 6 miesięcy jest dostępna 
        – przy zawieraniu kredytu elektronicznie poprzez dedykowaną stronę internetową - bez dodatkowych kosztów.
    </p>

    <p>
        Szczegóły oferty, w tym „Regulamin kredytowania działalności gospodarczej Nest Banku, linia mikro i małych przedsiębiorstw”, „Tabela Opłat i Prowizji dla Klienta biznesowego – oferta kredytowa” 
        oraz „Tabela Oprocentowania dla Klienta biznesowego – oferta kredytowa” dostępne są na <a href="https://www.nestbank.pl" target="_blank">www.nestbank.pl</a>, w placówkach własnych i u partnerów finansowych banku.
    </p>
</div>`

export const requiredDocumentsCollapseContent =
	'<div style="font-size: 12px">Na dokumencie wymagane są następujące dane:<br /><b>Zaświadczenie o niezaleganiu w ZUS</b><br />a) dane umożliwiające jednoznaczną identyfikację klienta,<br />b) pieczęć ZUS oraz podpis osoby upoważnionej.<br />Z treści dokumentu powinien wynikać brak układów ratalnych oraz postępowań egzekucyjnych, naprawczych, reklamacyjnych i odwoławczych.<br /><b>Potwierdzenia salda wygenerowanego z portalu ZUS PUE</b><br />a) dane umożliwiające jednoznaczną identyfikację klienta,<br />b) informacje o saldzie płatnika,<br />c) kwalifikowaną pieczęć elektroniczną Zakładu Ubezpieczeń Społecznych.</div>';
