import BaseFetch from '../../../common/api/BaseFetch';
import { ApiConfig } from '../../../common/api/FetchTypes';
import { IDocuments } from '../../../models/AttachDocumentsModels';

const getDocumentsKey = 'attachDocumentsKey';
const addFileToBlobKey = 'addFileToBlobKey';
const deleteDocumentKey = 'attachDocumentsKey';
const verifyDocumentsAndSendEmailToAnalystsKey =
	'verifyDocumentsAndSendEmailToAnalystsKey';

function verifyDocumentsAndSendEmailToAnalysts(): Promise<{
	bankAccountNumber: string;
}> {
	return BaseFetch({
		url: 'AttachingDocuments/Save',
		method: 'POST',
	});
}

function addFileToBlob(files: FormData): Promise<never> {
	return BaseFetch({
		url: 'AttachingDocuments',
		method: 'POST',
		body: files,
	});
}

function getDocuments(): Promise<never> {
	return BaseFetch({
		url: 'AttachingDocuments',
		method: 'GET',
	});
}

function deleteDocument(id: string): Promise<never> {
	return BaseFetch({
		url: `AttachingDocuments/${id}`,
		method: 'DELETE',
	});
}

export const VerifyDocumentsAndSendEmailToAnalystsConfig: ApiConfig<
	{ bankAccountNumber: string },
	FormData
> = {
	key: verifyDocumentsAndSendEmailToAnalystsKey,
	fn: verifyDocumentsAndSendEmailToAnalysts,
};

export const GetDocumentsConfig: ApiConfig<never, IDocuments[]> = {
	key: getDocumentsKey,
	fn: getDocuments,
};

export const AddFileToBlobConfig: ApiConfig<never, FormData> = {
	key: addFileToBlobKey,
	fn: addFileToBlob,
};

export const DeleteDocumentConfig: ApiConfig<never, string> = {
	key: deleteDocumentKey,
	fn: deleteDocument,
};
