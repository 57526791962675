import Stack from '@mui/material/Stack/Stack';
import Typography from '@mui/material/Typography/Typography';
import React from 'react';
import { FormProvider, useForm, UseFormReturn } from 'react-hook-form';
import { ScrollToTopOnMount } from '../../../../common/helpers/scroll-to-top-onmount';
import { LightPersonalDataDefaultValues } from '../../components/light-personal-data/consts/LightPersonalDataConstants';
import { LightPersonalDataProperties } from '../../components/light-personal-data/models/LightPersonalDataModels';
import LightOvdInfoAboutYourCompanyForm from './components/LightOvdInfoAboutYourCompanyForm/LightOvdInfoAboutYourCompanyForm';
import LightOvdSwitchPartOfForm from './components/LightOvdSwitchPartOfForm/LightOvdSwitchPartOfForm';
import LightOvdTaxResidencePartOfForm from './components/LightOvdTaxResidencePartOfForm/LightOvdTaxResidencePartOfForm';

const OvdAdditionalData = (): JSX.Element => {
	const methods = useForm({
		defaultValues: LightPersonalDataDefaultValues,
		mode: 'onSubmit',
		reValidateMode: 'onChange',
	});

	const onSubmit = (data: LightPersonalDataProperties): void => {
		console.log(data);
	};

	return (
		<Stack sx={{ padding: '16px', margin: 'auto', maxWidth: '835px' }}>
			<Stack sx={{ width: '100%' }}>
				<ScrollToTopOnMount />
				<FormProvider {...methods}>
					<Stack component='form' onSubmit={methods.handleSubmit(onSubmit)}>
						<Typography variant='h2' sx={{ marginBottom: '19px' }}>
							Informacje dodatkowe
						</Typography>
						<Typography variant='h5' sx={{ marginBottom: '19px' }}>
							Informacje o Twojej firmie
						</Typography>

						<Typography variant='p1' sx={{ marginBottom: '49px' }}>
							Te informacje są nam niezbędne do wykonania obowiązków
							wynikających z ustawy z dnia 01.03.2018 r. o przeciwdziałaniu
							praniu pieniędzy oraz finansowaniu terroryzmu.
						</Typography>

						<LightOvdInfoAboutYourCompanyForm
							methods={
								methods as unknown as UseFormReturn<Record<string, unknown>>
							}
						/>
						<LightOvdSwitchPartOfForm />

						<Typography
							variant='h5'
							sx={{ marginBottom: '24px', marginTop: '22px' }}
						>
							Twoja rezydencja podatkowa
						</Typography>
						<LightOvdTaxResidencePartOfForm
							methods={
								methods as unknown as UseFormReturn<Record<string, unknown>>
							}
						/>
					</Stack>
				</FormProvider>
			</Stack>
		</Stack>
	);
};

export default OvdAdditionalData;
