import { CreditOrLeasingLiabilitiesDataInside } from '../components/CreditOrLeasingLiabilities/models/CreditOrLeasingLiabilitiesModels';
import { EconomicLiabilitiesData } from '../components/EconomicLiabilities/models/EconomicLiabilitiesModels';
import { LightPersonalDataDefaultValues, TypeOfIdentification } from '../consts/LightPersonalDataConstants';
import { LightPersonalDataProperties } from '../models/LightPersonalDataModels';

const mapEconomicLiabilitiesDataToSendObj = (obj: EconomicLiabilitiesData): EconomicLiabilitiesData => {
	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	const { nipOrSocialSecurityNumber, id, ...rest } = obj;
	return {
		...rest,
		nip: nipOrSocialSecurityNumber === TypeOfIdentification.NIP ? rest.nip : null,
		socialSecurityNumber: nipOrSocialSecurityNumber === TypeOfIdentification.PESEL ? rest.socialSecurityNumber : null,
	};
};

const mapCreditOrLeasingLiabilitiesDataToSendObj = (obj: CreditOrLeasingLiabilitiesDataInside): CreditOrLeasingLiabilitiesDataInside => {
	return {
		currency: obj.currency,
		installment: Number((obj.installment as string).split(' ').join('').replace(',', '.'))?.toFixed(2),
	};
};

export const getAppriopriatelyLiabilitiesObjToSendData = (
	data: LightPersonalDataProperties
):
	{
		economicLiabilities: {
			data: EconomicLiabilitiesData[] | undefined;
			isGiven: boolean | undefined;
		};
		creditOrLeasingLiabilities: {
			leasingLiabilities: {
				data: CreditOrLeasingLiabilitiesDataInside[] | undefined;
				isGiven: boolean | undefined;
			};
			anyLiabilitiesGiven: boolean | undefined;
			cashLoanLiabilities: {
				data: CreditOrLeasingLiabilitiesDataInside[] | undefined;
				isGiven: boolean | undefined;
			};
			overdraftLiabilities: {
				data: CreditOrLeasingLiabilitiesDataInside[] | undefined;
				isGiven: boolean | undefined;
			};
		} | {
			leasingLiabilities: {
				data: [];
				isGiven: boolean | undefined;
			};
			cashLoanLiabilities: {
				data: [];
				isGiven: boolean | undefined;
			};
			overdraftLiabilities: {
				data: [];
				isGiven: boolean | undefined;
			};
		};
	} | undefined => {
	if (!data) {
		return undefined;
	}
	return {
		economicLiabilities: {
			data: data?.economicLiabilities?.isGiven ? data?.economicLiabilities.data.map(mapEconomicLiabilitiesDataToSendObj) : [],
			isGiven: data?.economicLiabilities?.isGiven,
		},
		creditOrLeasingLiabilities: data.creditOrLeasingLiabilities?.anyLiabilitiesGiven ? {
			cashLoanLiabilities: {
				data: data?.creditOrLeasingLiabilities?.cashLoanLiabilities?.isGiven
					? data?.creditOrLeasingLiabilities.cashLoanLiabilities.data.map(mapCreditOrLeasingLiabilitiesDataToSendObj)
					: [],
				isGiven: data.creditOrLeasingLiabilities?.cashLoanLiabilities?.isGiven,
			},
			overdraftLiabilities: {
				data: data.creditOrLeasingLiabilities?.overdraftLiabilities?.isGiven
					? data.creditOrLeasingLiabilities?.overdraftLiabilities?.data?.map(mapCreditOrLeasingLiabilitiesDataToSendObj)
					: [],
				isGiven: data.creditOrLeasingLiabilities?.cashLoanLiabilities?.isGiven,
			},
			leasingLiabilities: {
				data: data?.creditOrLeasingLiabilities?.leasingLiabilities.isGiven
					? data?.creditOrLeasingLiabilities?.leasingLiabilities?.data?.map(mapCreditOrLeasingLiabilitiesDataToSendObj)
					: [],
				isGiven: data.creditOrLeasingLiabilities?.cashLoanLiabilities.isGiven,
			},
			anyLiabilitiesGiven: data.creditOrLeasingLiabilities?.anyLiabilitiesGiven,
		} : {
			cashLoanLiabilities: {data:[], isGiven: data.creditOrLeasingLiabilities?.cashLoanLiabilities?.isGiven},
			overdraftLiabilities: {data:[], isGiven: data.creditOrLeasingLiabilities?.overdraftLiabilities?.isGiven},
			leasingLiabilities: {data:[], isGiven: data.creditOrLeasingLiabilities?.leasingLiabilities?.isGiven},
			anyLiabilitiesGiven: data.creditOrLeasingLiabilities?.anyLiabilitiesGiven,
		},
	};
};

export const mapEconomicAndCreditOrLeasingLiabilitiesToFormValues = (
	data: LightPersonalDataProperties
):
	| {
			economicLiabilities: { data: EconomicLiabilitiesData[] | [] | undefined; isGiven: boolean | undefined };
			creditOrLeasingLiabilities: {
				leasingLiabilities: { data: CreditOrLeasingLiabilitiesDataInside[] | [] | undefined; isGiven: boolean | undefined };
				anyLiabilitiesGiven: boolean | undefined;
				cashLoanLiabilities: { data: CreditOrLeasingLiabilitiesDataInside[] | [] | undefined; isGiven: boolean | undefined };
				overdraftLiabilities: { data: CreditOrLeasingLiabilitiesDataInside[] | [] | undefined; isGiven: boolean | undefined };
			};
	  }
	| undefined => {
	if (!data) {
		return undefined;
	}
	return {
		creditOrLeasingLiabilities: {
			anyLiabilitiesGiven: data.creditOrLeasingLiabilities?.anyLiabilitiesGiven,
			cashLoanLiabilities: {
				data: data.creditOrLeasingLiabilities?.cashLoanLiabilities?.isGiven
					? data.creditOrLeasingLiabilities?.cashLoanLiabilities?.data?.map(
							(x: CreditOrLeasingLiabilitiesDataInside): CreditOrLeasingLiabilitiesDataInside => {
								return {
									...x,
									installment: String(x.installment),
									id: self.crypto.randomUUID(),
								};
							}
					  )
					: LightPersonalDataDefaultValues.creditOrLeasingLiabilities?.cashLoanLiabilities?.data,
				isGiven: data.creditOrLeasingLiabilities?.cashLoanLiabilities?.isGiven,
			},
			overdraftLiabilities: {
				data: data.creditOrLeasingLiabilities?.overdraftLiabilities?.isGiven
					? data.creditOrLeasingLiabilities.overdraftLiabilities.data?.map(
							(x: CreditOrLeasingLiabilitiesDataInside): CreditOrLeasingLiabilitiesDataInside => {
								return {
									...x,
									installment: String(x.installment),
									id: self.crypto.randomUUID(),
								};
							}
					  )
					: LightPersonalDataDefaultValues.creditOrLeasingLiabilities?.overdraftLiabilities?.data,
				isGiven: data.creditOrLeasingLiabilities?.overdraftLiabilities?.isGiven,
			},
			leasingLiabilities: {
				data: data.creditOrLeasingLiabilities?.leasingLiabilities?.isGiven
					? data.creditOrLeasingLiabilities.leasingLiabilities.data?.map(
							(x: CreditOrLeasingLiabilitiesDataInside): CreditOrLeasingLiabilitiesDataInside => {
								return {
									...x,
									installment: String(x.installment),
									id: self.crypto.randomUUID(),
								};
							}
					  )
					: LightPersonalDataDefaultValues.creditOrLeasingLiabilities?.leasingLiabilities?.data,
				isGiven: data.creditOrLeasingLiabilities?.leasingLiabilities?.isGiven,
			},
		},
		economicLiabilities: {
			isGiven: data?.economicLiabilities?.isGiven,
			data: data?.economicLiabilities?.isGiven
				? data?.economicLiabilities?.data?.map((x: EconomicLiabilitiesData): EconomicLiabilitiesData => {
						return {
							...x,
							nipOrSocialSecurityNumber: x.socialSecurityNumber ? TypeOfIdentification.PESEL : TypeOfIdentification.NIP,
							id: self.crypto.randomUUID(),
						};
				  })
				: LightPersonalDataDefaultValues.economicLiabilities?.data,
		},
	};
};
