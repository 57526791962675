import { ApplicationProcess } from 'src/common/constants/application-process';
import { WebPage } from 'src/routing/Routing';

export const defaultValues = {
	nip: '',
	smsCode: '',
};

export interface ReturnProposalProperties {
	nip: string;
	smsCode: string;
}

export interface SmsVerificationResponse {
	applicationId: string;
	applicationNumber: string;
	partnerLogoName?: string;
	partnerName: string;
	process: ApplicationProcess;
	processId: string;
	token: string;
	webPageToReturn: WebPage;
	marketingPartnerId: string;
	userId: string;
}
