import Typography from '@mui/material/Typography';
import React, { useContext, useState } from 'react';
import FlatAgreementCollection from '../../../../../components/AgreementCollection/FlatAgreementCollection';
import Loader from '../../../../../components/Loader/Loader';
import { LightCompanyDataContext } from '../state/LightCompanyDataContext';
import { Stack } from '@mui/system';

const LightFinancialData = (): JSX.Element => {
	const lightCompanyDataContext = useContext(LightCompanyDataContext);
	const [imgLoading, setImgLoading] = useState<string[]>([]);

	return (
		<Stack>
			<Typography
				variant='h2'
				sx={{
					width: '100%',
					padding: '24px 0 16px 0',
				}}
			>
				Wybierz bank, w którym masz obroty firmowe
			</Typography>
			<Typography
				variant='p1'
				sx={{
					width: '100%',
					padding: '0 0 24px 0',
				}}
			>
				Sprawdzimy historię rachunku i obliczymy średni przychód Twojej firmy z ostatnich 12 miesięcy. Na tej podstawie oszacujemy Twoją zdolność
				kredytową.
			</Typography>

			<Stack sx={{ width: '100%' }}>
				<Stack sx={{ gap: '16px', flexDirection: 'row', flexWrap: 'wrap' }}>
					{lightCompanyDataContext.banksList?.map(
						(bankItem): JSX.Element => (
							<Stack
								onClick={(): void => lightCompanyDataContext.setBankId(bankItem.id)}
								sx={{
									justifyContent: 'center',
									alignItems: 'center',
									width: '137px',
									height: '137px',
									boxShadow: lightCompanyDataContext.bankId === bankItem.id ? 'inset 0px 0px 0px 3px #BCA8FF' : 'inset 0px 0px 0px 1px #BCA8FF',
									borderRadius: '8px',
									cursor: 'pointer',
								}}
								key={bankItem.id}
							>
								{bankItem.iconUrl ? (
									<Stack sx={{ position: 'relative' }}>
										<img
											width={123}
											height={123}
											src={bankItem.iconUrl ?? ''}
											alt={bankItem.name}
											onLoad={(): void => setImgLoading((prevState: string[]): string[] => [...prevState, bankItem.id])}
										/>
										{!imgLoading.includes(bankItem.id) && (
											<Stack
												sx={{ position: 'absolute', top: '0', left: '0', right: '0', bottom: '0', alignItems: 'center', justifyContent: 'center' }}
											>
												<Loader />
											</Stack>
										)}
									</Stack>
								) : (
									<Typography variant='p2' sx={{ textAlign: 'center', padding: '8px' }}>
										{bankItem.name}
									</Typography>
								)}
							</Stack>
						)
					)}
				</Stack>
			</Stack>
			<Typography
				variant='h2'
				sx={{
					width: '100%',
					padding: { xs: '48px 0 4px 0', md: '48px 0 24px 0' },
				}}
			>
				Zaznacz zgody na pozyskanie historii rachunku
			</Typography>

			{Object.keys(lightCompanyDataContext.agreementState.agreements).length > 0 ? <FlatAgreementCollection agreementsTitle='Zgody na pozyskanie historii rachunku' customBackground /> : <Loader />}
		</Stack>
	);
};

export default LightFinancialData;
