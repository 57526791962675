import { Location } from 'react-router-dom';
import BaseFetch from '../common/api/BaseFetch';
import { ApiConfig } from '../common/api/FetchTypes';
import { WebPage } from './Routing';

const locationStorageKey = 'location';

const saveLocation = (current: Location): void => {
	window.sessionStorage.setItem(locationStorageKey, JSON.stringify(current));
};

const loadLocation = (): Location | undefined => {
	const location = window.sessionStorage.getItem(locationStorageKey);
	if (!location) return undefined;
	return JSON.parse(location) as Location;
};

export const routingSessionService = { saveLocation, loadLocation };

export interface DoAfterManualPageChangeRequest {
	applicationId: string;
	page: WebPage;
}

export interface SessionExpirationResponse {
	sessionExpiresAt: string;
}

const doAfterManualPageChangeKey = 'doAfterManualPageChange';

function doAfterManualPageChange(
	props: DoAfterManualPageChangeRequest
): Promise<SessionExpirationResponse> {
	return BaseFetch({
		url: 'Routing/doAfterManualPageChange',
		method: 'POST',
		body: props,
	});
}

export const DoAfterManualPageChangeConfig: ApiConfig<
	SessionExpirationResponse,
	DoAfterManualPageChangeRequest
> = {
	key: doAfterManualPageChangeKey,
	fn: doAfterManualPageChange,
};

export function interpolatePath(
	path: string,
	params: Record<string, string | undefined>
): string {
	return path.replace(
		/:(\w+)/g,
		(_, param: string): string => params[param] ?? ''
	);
}
