export const lightIndexCollapseContent = `<div>
	<b>Książka przychodów i rozchodów</b>
	<ol>
		<ul>
			<li>PIT-36/36L z załącznikami za ostatnie 2 lata wraz z nadrukowanym numerem UPO/pieczęcią Urzędu Skarbowego</li>
		</ul>
	</ol><br/>	
	<b>Ryczałt ewidencjonowany</b>
	<ol>
		<ul>
			<li>PIT-28 za ostatnie 2 lata</li>
		</ul>
	</ol><br/>	
	<b>Karta podatkowa</b>
	<ol>
		<ul>
			<li>Decyzja wydana przez Urząd Skarbowy dotycząca ustalenia stawki karty podatkowej na rok bieżący i ubiegły</li>
		</ul>
	</ol>	
</div>`;

export const socialSecurityCollapseContent = `
<div>
	<b>Zaświadczenie o niezaleganiu w ZUS lub potwierdzenie danych z ZUS</b><br/><br/>
	Dokument powinien zawierać:<br/>
	<ol>
		<ul>
			<li>dane umożliwiające jednoznaczną identyfikację klienta</li>
			<li>dla zaświadczenia - pieczęć ZUS oraz podpis osoby upoważnionej</li>
			<li>dla potwierdzenia - informację o saldzie płatnika oraz kwalifikowaną pieczęć elektroniczną ZUS</li>
		</ul>
	</ol><br/>
	<b>Zaświadczenie o niezaleganiu w US</b><br/><br/>
	Dokument powinien zawierać:<br/>
	<ol>
		<ul>
			<li>dane umożliwiające jednoznaczną identyfikację klienta</li>
			<li>datę wydania zaświadczenia</li>
			<li>uzupełnioną część I-V w zakresie informacji o braku zaległości, układów ratalnych, postępowań</li>
			<li>podpis osoby wystawiającej zaświadczenia i pieczęć US</li>
		</ul>
	</ol><br/><br/>
</div>`;

export const requiredDocumentsList: IRequiredDocumentItem[] = [
	{
		content: 'Polski dowód osobisty',
	},
	{
		content:
			'Dokumenty finansowe zależne od sposobu rozliczania się z Urzędem Skarbowym',
		collapse: true,
		collapseContent: lightIndexCollapseContent,
		collapseTitle: 'Sprawdź',
	},
	{
		content: 'Zaświadczenie o niezaleganiu w ZUS i US',
		collapse: true,
		collapseContent: socialSecurityCollapseContent,
		collapseTitle: 'Sprawdź',
	},
];

interface IRequiredDocumentItem {
	content: string;
	collapse?: boolean;
	collapseContent?: string;
	collapseTitle?: string;
}

export const lightIndexCollapseContentTaxOffice =
	'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aenean fermentum augue sed dui tempor ullamcorper. Maecenas lobortis congue mi, ut viverra eros. In nisi turpis, maximus eget commodo eu, ultricies non eros. Donec pulvinar odio arcu, quis viverra augue convallis et. Pellentesque ac iaculis urna. In ante odio, aliquet at euismod eget, tincidunt et purus. Fusce viverra velit vel efficitur eleifend. Cras nec neque at quam hendrerit gravida sit amet non diam.';
