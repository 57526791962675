import React, { useState, useEffect } from 'react';
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';
import Slider from '@mui/material/Slider';
import { Input } from '../../../../../components/FormItems/NestInput/NestInput.css';
import { formatAmount } from '../../../../../common/helpers/number-helper';
import { mainTheme } from '../../../../../theme/mainTheme';
import { ICalculatedLimit } from '../OvdCalculatorPage';

interface IOvdCalculatorProps {
	isLoading: boolean;
	startLimit: number;
	max: number;
	min: number;
	unit: string[];
	isUnitPercentage?: boolean;
	step: number;
	text: string;
	calculatedLimit?: ICalculatedLimit;
	setCalculatedLimit: React.Dispatch<React.SetStateAction<ICalculatedLimit>>;
	calculatorType: string;
}

const OvdCalculator = ({ isUnitPercentage = false, ...props }: IOvdCalculatorProps): JSX.Element => {
	const [limitAmoutSlider, setLimitAmountSlider] = useState(0);
	const [limitAmoutInput, setLimitAmountInput] = useState(0);

	useEffect((): void => {
		setLimitAmountSlider(props.startLimit);
		setLimitAmountInput(props.startLimit);
	}, [props.startLimit]);

	// only for percentage view
	useEffect((): void => {
		if (isUnitPercentage && props.calculatedLimit?.currencyValue) {
			setLimitAmountSlider((limitAmoutInput * 100) / props.calculatedLimit.currencyValue);
			limitAmoutInput > props.calculatedLimit.currencyValue && setLimitAmountInput(props.calculatedLimit.currencyValue);
		}
	}, [props.calculatedLimit]);

	const onCreditAmountSliderChange = (value: number): void => {
		setLimitAmountSlider(value);
		setLimitAmountInput(isUnitPercentage ? (value * (props.calculatedLimit?.currencyValue ?? 0)) / 100 : value);
		props.setCalculatedLimit(
			(currentCurrencyValue): ICalculatedLimit => ({
				...currentCurrencyValue,
				[props.calculatorType]: isUnitPercentage ? (value * (props.calculatedLimit?.currencyValue ?? 0)) / 100 : value,
			})
		);
	};

	const onCreditAmountInputChange = (value: string | number): void => {
		value = +value.toString().replaceAll(' ', '');
		setLimitAmountInput(value);
	};

	const onBlurInputAmountChanged = (value: string | number): void => {
		value = +value.toString().replaceAll(' ', '');
		const minValue = props.min;
		const maxValue = isUnitPercentage ? props.calculatedLimit?.currencyValue ?? 0 : props.max;
		if (value < minValue) {
			value = minValue;
		}
		if (value > maxValue) {
			value = maxValue;
		}
		value = Math.round(value / props.step) * props.step;

		setLimitAmountInput(value);
		setLimitAmountSlider(isUnitPercentage ? (value * 100) / maxValue : value);

		props.setCalculatedLimit(
			(currentCurrencyValue): ICalculatedLimit => ({
				...currentCurrencyValue,
				[props.calculatorType]: +value,
			})
		);
	};

	return (
		<Stack
			sx={{
				flexDirection: 'column',
			}}
		>
			<Stack
				sx={{
					marginBottom: { xs: '26px', md: '0px' },
				}}
			>
				<Typography
					variant='h9'
					sx={{
						width: { xs: '100%', md: '351px' },
						color: mainTheme.palette.text.secondary,
						fontWeight: 600,
						marginTop: { xs: '24px', md: '32px' },
						marginBottom: { xs: '0', md: '18px' },
					}}
				>
					{props.text}
				</Typography>
			</Stack>
			<Stack sx={{ flexDirection: { sx: 'column', md: 'row' } }}>
				<Slider
					disabled={props.isLoading}
					sx={{
						width: { xs: '100%', md: '351px' },
						marginTop: { xs: '0px', md: '20px' },
						marginRight: { xs: '0', md: '24px' },
						marginBottom: { xs: '41px', md: '36px' },
						paddingTop: '5px !important',
						paddingBottom: '5px !important',
						'.MuiSlider-markLabel': {
							fontFamily: 'Inter, sans-serif',
						},
					}}
					valueLabelDisplay='auto'
					max={props.max}
					min={props.min}
					step={props.step}
					value={isUnitPercentage ? Math.round(limitAmoutSlider) : limitAmoutSlider}
					onChange={(_e, value): void => onCreditAmountSliderChange(value as number)}
					marks={[
						{
							value: props.min,
							label: `${formatAmount(props.min)}${props.unit[0]}`,
						},
						{
							value: props.max,
							label: `${formatAmount(props.max)}${props.unit[1]}`,
						},
					]}
				></Slider>
				<Input
					disabled={props.isLoading}
					type='text'
					error={false}
					value={formatAmount(limitAmoutInput)}
					sx={{
						width: { xs: 'calc(100% - 32px)', md: '140px' },
						marginTop: { xs: '16px', md: '0px' },
					}}
					onChange={(e): void => onCreditAmountInputChange(e.target.value)}
					onBlur={(e): void => onBlurInputAmountChanged(e.target.value)}
				/>
			</Stack>
		</Stack>
	);
};

export default OvdCalculator;
