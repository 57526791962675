import Collapse from '@mui/material/Collapse';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { useState } from 'react';
import { formatAmount } from '../../../common/helpers/number-helper';
import lead_hero_round from '../../../common/images/lead_hero_round.svg';
import Loader from '../../../components/Loader/Loader';
import { resolveIndexTokenByType } from '../../common/index/IndexTokenResolver';
import { LeadVariantsOfNote } from '../../lead/lead-cl/LeadCLConst';

//svg
import lead_Nest_logo from '../../../common/images/lead_Nest_logo.svg';
import closeIcon from '../../../common/images/closeIconPrimaryColor.svg';
import lead_arrow from '../../../common/images/lead_arrow.svg';
import { leadVariantOfHeaderText } from '../../lead/lead-cl/LeadCLVariantComponents';



export const LeadIndexStart = (): JSX.Element => {
	const [showCollapse, setShowCollapse] = useState(false);

	const legalNoteByItsType = (): string | undefined => {
		return LeadVariantsOfNote.find((x):boolean=> x.type === resolveIndexTokenByType()?.type)?.note
	}

	return (
		<Stack
			sx={{
				minHeight: { xs: 'unset', md: '100vh' },
				width: { xs: '100%', md: '50%' },
				background: '#16161E',
				alignItems: 'center',
			}}
		>
			<Stack sx={{ maxWidth: '530px', width: '100%' }}>
				<Stack sx={{ padding: { xs: '32px 16px 13px 16px', md: '32px 16px 40px 16px' }, flexDirection: 'row', alignItems: 'center' }}>
					<Stack sx={{ width: '63px', height: '25px' }}>
						<img src={lead_Nest_logo} alt='NestBank logo' height='100%' />
					</Stack>
					<Stack sx={{ height: '28px', padding: '0 0 0 16px' }}>
						<Loader/>
					</Stack>
				</Stack>

				<Typography
					variant='p2'
					sx={{
						padding: '0 16px 4px 16px',
						color: '#BCA8FF',
						lineHeight: '19.6px',
						fontWeight: '600',
					}}
				>
					Kredyt dla jednoosobowej działalności gospodarczej
				</Typography>
				{leadVariantOfHeaderText(formatAmount(undefined))}
				<Loader/>

				<Stack
					sx={{
						position: 'relative',
						padding: '12px 0 28px 16px',
						cursor: 'pointer',
					}}
					onClick={(): void => setShowCollapse((prevState): boolean => !prevState)}
				>
					<Stack sx={{ flexDirection: 'row', alignItems: 'center' }}>
						<Typography
							sx={{
								fontFamily: 'Athletics, sans-serif',
								fontWeight: '700',
								fontSize: '14px',
								lineHeight: '16.8px',
								color: '#BCA8FF',
							}}
						>
							Nota prawna
						</Typography>
						<Stack
							sx={{
								padding: '8px',
								transform: showCollapse ? 'rotateX(180deg)' : 'rotateX(0deg)',
								transition: 'transform 0.6s ease-in-out ',
							}}
						>
							<img src={lead_arrow} alt='lead arrow' />
						</Stack>
					</Stack>
					<Collapse
						in={showCollapse}
						sx={{
							zIndex: '2',
							width: 'calc(100% - 32px)',
							position: 'absolute',
							top: '48px',
							left: '16px',
						}}
					>
						<Stack
							sx={{
								background: '#2D333F',
								border: '1px solid #656572',
								borderRadius: '8px',
								color: '#FFFFFF',
								padding: '16px',
								position: 'relative',
							}}
						>
							<Stack
								sx={{
									flexDirection: 'row',
									alignItems: 'center',
									justifyContent: 'space-between',
								}}
							>
								<Typography variant='p5'>Nota prawna</Typography>
								<img src={closeIcon} alt='close icon' />
							</Stack>
							<Typography variant='p5' sx={{ textAlign: 'justify' }} dangerouslySetInnerHTML={{ __html: legalNoteByItsType() ?? '' }} />
						</Stack>
					</Collapse>
				</Stack>
				<Stack sx={{width: '100%', display: {md: 'flex', xs: 'none'}, justifyContent: 'center'}}>
					<Stack
						sx={{
							background: '#202131',
							borderRadius: '50%',
							width: '385px',
							height: '385px',
							marginLeft: '70px'
						}}
					>
						<img src={lead_hero_round} alt='lead hero round' />
					</Stack>
				</Stack>
			</Stack>
		</Stack>
	);
};
