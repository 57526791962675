import React, { useContext, useEffect, useState } from 'react';
import { useForm, UseFormReturn } from 'react-hook-form';
import { useSelector } from 'react-redux';
import useFetch from '../../../../../common/api/UseFetch';
import { convertAgreementsToAgreementModelToSend } from '../../../../../common/helpers/client-wealth-and-agreements-req-data-helpers';
import { ContextSaveApplicationData } from '../../../../../context/ContextSaveApplicationData';
import { CreateComponentContext, notImplementedFunction } from '../../../../../context/CreateComponentContext';
import { TaxationForm } from '../../../../../enums/TaxationForm';
import { IAgreementsStateSlice } from '../../../../../store/slices/agreementsSlice/AgreementSliceModels';
import { RootState } from '../../../../../store/State';
import {
	GetPsd2BanksConfig,
	IdentityVerificationBanksListPropsBankInfo,
	InitiateResult,
} from '../../../../rbf/rbf-identity-verification/IdentityVerificationApi';
import { LightCompanyDataDefaultValues } from '../consts/LightCompanyDataConstants';
import { LightCompanyDataBeforeVerificationModel, LightCompanyDataFormFieldsModel } from '../models/LightCompanyData.models';
import {
	GetLightCompanyDataConfig,
	InitiateIncomeVerificationLightCompanyDataConfig,
	PostDraftLightCompanyDataConfig,
	PostLightCompanyDataConfig,
} from '../api/LightCompanyDataApi';

const defaultLightCompanyDataContext = {
	onSubmit: notImplementedFunction,
	bankId: '',
	setBankId: notImplementedFunction,
	agreementState: {},
	postLightCompanyDataIsLoading: false,
	postLightCompanyDataLoaded: false,
	incomeVerificationLightCompanyDataIsLoading: false,
	incomeVerificationLightCompanyDataLoaded: false,
	watchIsCompanyCorrespondenceAddressSameAsCompanyAddress: true,
	watchTaxationForm: TaxationForm.None,
	handleChecked: notImplementedFunction,
};

interface LightCompanyDataState {
	methods: UseFormReturn<Record<string, unknown>>;
	onSubmit: (data: LightCompanyDataFormFieldsModel) => void;
	bankId: string;
	setBankId: (value: ((prevState: string) => string) | string) => void;
	agreementState: IAgreementsStateSlice;
	postLightCompanyDataIsLoading: boolean;
	postLightCompanyDataLoaded: boolean;
	incomeVerificationLightCompanyDataIsLoading: boolean;
	incomeVerificationLightCompanyDataLoaded: boolean;
	banksList: IdentityVerificationBanksListPropsBankInfo[];
	watchIsCompanyCorrespondenceAddressSameAsCompanyAddress: boolean;
	watchTaxationForm: TaxationForm;
	handleChecked: (event: React.ChangeEvent<HTMLInputElement>) => void;
}

export const lightCompanyDataContextHandler = (): LightCompanyDataState => {
	const [isSaveApplicationData] = useContext(ContextSaveApplicationData);
	const [bankId, setBankId] = useState<string>('');

	const methods = useForm({
		defaultValues: LightCompanyDataDefaultValues,
		mode: 'onSubmit',
		reValidateMode: 'onChange',
	});

	const watchTaxationForm = methods.watch('taxationForm') as unknown as TaxationForm;

	const watchIsCompanyCorrespondenceAddressSameAsCompanyAddress = methods.watch('isCompanyCorrespondenceAddressSameAsCompanyAddress');

	const banksList = useFetch(GetPsd2BanksConfig, true);

	const getLightCompanyData = useFetch(GetLightCompanyDataConfig, true);

	const agreementState = useSelector((state: RootState): IAgreementsStateSlice => state.agreements);

	const incomeVerificationLightCompanyData = useFetch(InitiateIncomeVerificationLightCompanyDataConfig, false, (data: InitiateResult): void => {
		window.location.href = data.redirectUrl;
	});

	const postLightCompanyData = useFetch(PostLightCompanyDataConfig, false, (): void => {
		incomeVerificationLightCompanyData.mutate(bankId as never);
	});

	const postDraftLightCompanyData = useFetch(PostDraftLightCompanyDataConfig, false);

	useEffect((): void => {
		if (isSaveApplicationData) {
			saveDraftLightCompanyData();
		}
	}, [isSaveApplicationData]);

	useEffect((): void => {
		methods?.reset(getLightCompanyData.data);
		if ((getLightCompanyData.data?.isCompanyCorrespondenceAddressSameAsCompanyAddress as null | boolean) === null) {
			methods?.setValue('isCompanyCorrespondenceAddressSameAsCompanyAddress', true);
		}
	}, [getLightCompanyData.data]);

	const handleChecked = (event: React.ChangeEvent<HTMLInputElement>): void => {
		methods.setValue('isCompanyCorrespondenceAddressSameAsCompanyAddress', event.target.checked);
	};

	const saveDraftLightCompanyData = (): void => {
		methods?.clearErrors();
		const dataToSend: LightCompanyDataBeforeVerificationModel = convertLightCompanyData({
			...methods?.watch(),
			agreements: [],
		});
		postDraftLightCompanyData.mutate(dataToSend);
	};

	const convertLightCompanyData = (data: LightCompanyDataBeforeVerificationModel): LightCompanyDataBeforeVerificationModel => {
		return {
			firstName: data.firstName,
			lastName: data.lastName,
			socialSecurityNumber: data.socialSecurityNumber,
			numberOfEmployees: data.numberOfEmployees,
			taxationForm: data.taxationForm,
			declarationType: data.declarationType,
			email: data.email,
			phoneNumber: data.phoneNumber,
			isCompanyCorrespondenceAddressSameAsCompanyAddress: data.isCompanyCorrespondenceAddressSameAsCompanyAddress,
			companyAddress: data.companyAddress,
			companyCorrespondenceAddress: data.isCompanyCorrespondenceAddressSameAsCompanyAddress ? data.companyAddress : data.companyCorrespondenceAddress,
			agreements: convertAgreementsToAgreementModelToSend(agreementState.agreements),
		};
	};

	const onSubmit = (data: LightCompanyDataFormFieldsModel): void => {
		if ((agreementState.agreements.length > 0 && !agreementState.isValid) || !bankId) {
			return;
		}
		postLightCompanyData.mutate(convertLightCompanyData({ ...data, agreements: [] }) as never);
	};

	return {
		methods,
		onSubmit: (data: LightCompanyDataFormFieldsModel): void => onSubmit(data),
		bankId,
		setBankId,
		agreementState,
		postLightCompanyDataIsLoading: postLightCompanyData.isPending,
		postLightCompanyDataLoaded: postLightCompanyData.isSuccess,
		incomeVerificationLightCompanyDataIsLoading: incomeVerificationLightCompanyData.isPending,
		incomeVerificationLightCompanyDataLoaded: incomeVerificationLightCompanyData.isSuccess,
		banksList: banksList.data,
		watchIsCompanyCorrespondenceAddressSameAsCompanyAddress,
		watchTaxationForm,
		handleChecked: (event: React.ChangeEvent<HTMLInputElement>): void => handleChecked(event),
	} as unknown as LightCompanyDataState;
};

export const [LightCompanyDataContext, LightCompanyDataContextProvider] = CreateComponentContext<LightCompanyDataState>(
	defaultLightCompanyDataContext as unknown as LightCompanyDataState,
	lightCompanyDataContextHandler
);
