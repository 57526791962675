import styled from '@mui/system/styled';
import { secondaryMainColor } from '../../../theme/palette/palette';

export const SaveApplicationSpan = styled('span')({
	color: secondaryMainColor,
	fontWeight: 'bold',
	fontSize: '16px',
	fontFamily: 'Athletics, sans-serif',
});

export const SectionAttachDocumentBellow = styled('div')({
	display: 'flex',
	maxWidth: '380px',
	justifyContent: 'center',
	alignItems: 'flex-start',
	marginTop: '27px',
});

export const ButtonSubmitSection = styled('div')({
	display: 'flex',
	width: '100%',
	marginTop: '31px',
	marginBottom: '80px',
});

export const FileUploaderAndTextContainer = styled('div')({});
