import { ApplicationProcess } from 'src/common/constants/application-process';
import { AgreementModel, AgreementModelSend } from 'src/components/AgreementCollection/AgreementsModels';
import BaseFetch from '../../../../common/api/BaseFetch';
import { ApiConfig, ApiVersion } from '../../../../common/api/FetchTypes';

export interface LightOvdCreateApplicationResult {
	applicationId: string;
	applicationNumber: string;
	canProceed: boolean;
	isRejected: string;
	token: string;
	marketingPartnerId: string;
	userId: string;
}

const lightOvdCreateApplicaitonKey = 'lightOvdCreateApplication';

function lightOvdCreateApplication(props: LightOvdCreateApplicationPayload): Promise<LightOvdCreateApplicationResult> {
	return BaseFetch({
		url: `LandingPage/CreateApplication${props.query ? '?' + props.query : ''}`,
		method: 'POST',
		body: { ...props, query: undefined },
		version: ApiVersion.Light,
	});
}

export const LightOvdCreateApplicationConfig: ApiConfig<LightOvdCreateApplicationResult, LightOvdCreateApplicationPayload> = {
	key: lightOvdCreateApplicaitonKey,
	fn: lightOvdCreateApplication,
};

export interface LightOvdCreateApplicationPayload {
	agreements: AgreementModelSend[];
	magicWords?: string;
	process: ApplicationProcess;
	processId: string;
	nip: string;
	email: string;
	firstName: string;
	lastName: string;
	phoneNumber: string;
	query: string;
	brokerId: string | null;
}

const getAgreementKey = 'lightOvdGetAgreement';

function getAgreement(processId: string): Promise<AgreementModel[]> {
	return BaseFetch({
		url: 'Agreement/' + processId,
		method: 'GET',
	});
}

export const GetAgreement: ApiConfig<AgreementModel[], string> = {
	key: getAgreementKey,
	fn: getAgreement,
};
