import { createContext, useEffect, useState } from 'react';
import {
	getSessionStorageValue,
	removeSessionStorageValue,
	setSessionStorageValue,
	tokenExpirationKey,
} from '../common/sessionStorage/SessionStorageService';

interface SessionContextType {
	sessionExpiresAt: Date | undefined;
	updateSessionExpiration: (date: Date | undefined) => void;
	allowGetInToPageWithoutSession?: boolean;
	setAllowGetInToPageWithoutSession?: (allowGetIn: boolean) => void;
}
export const SessionContext = createContext<SessionContextType>({
	sessionExpiresAt: undefined,
	updateSessionExpiration: (_date): void => {
		console.error('Not implemented SessionContext');
	},
});

interface SessionContextProviderProps {
	children: JSX.Element | JSX.Element[];
}

export const SessionContextProvider = (
	props: SessionContextProviderProps
): JSX.Element => {
	const [expiresAt, setExpiresAt] = useState<Date | undefined>(undefined);
	const [allowGetInToPageWithoutSession, setAllowGetInToPageWithoutSession] =
		useState<boolean>(false);

	useEffect((): (() => void) => {
		disableBackAction();
		const expiration = getSessionStorageValue(tokenExpirationKey);
		setExpiresAt(expiration ? new Date(expiration) : undefined);

		return (): void => {
			window.onpopstate = null;
		};
	}, []);

	const updateSessionExpiration = (date: Date | undefined): void => {
		setExpiresAt(date);
		const tokenExpiration = getSessionStorageValue(tokenExpirationKey);
		if (date) {
			setSessionStorageValue(tokenExpirationKey, date.toString());
		} else if (tokenExpiration) {
			return;
		} else {
			removeSessionStorageValue(tokenExpirationKey);
		}
	};

	const disableBackAction = (): void => {
		window.history.pushState(null, '', window.location.href);
		window.onpopstate = (): void => {
			window.history.go(1);
		};
	};

	return (
		<SessionContext.Provider
			value={{
				sessionExpiresAt: expiresAt,
				updateSessionExpiration: updateSessionExpiration,
				setAllowGetInToPageWithoutSession: setAllowGetInToPageWithoutSession,
				allowGetInToPageWithoutSession: allowGetInToPageWithoutSession,
			}}
		>
			{props.children}
		</SessionContext.Provider>
	);
};
