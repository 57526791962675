import Grid from '@mui/material/Grid';
import { CompanyData } from '../../models/CompanyData';
import { WebPage } from '../../routing/Routing';
import { HorizontalLine } from './DataConsistency.css';
import ValueDescriptor from '../../components/ValueDescriptor/ValueDescriptor';
import { gridFullWidth, gridHalfWidth } from '../../theme/grid/GridConstants';
import SectionHeader from './SectionHeader';
import { TaxationFormDescription } from '../../enums/TaxationForm';
import { staticMask } from '../../common/helpers/mask-helper';
import { formatAmount } from '../../common/helpers/number-helper';

export interface InfoAboutCompanyProps {
	title: string;
	companyData: CompanyData;
	editPage: WebPage;
	setRenderPage: React.Dispatch<React.SetStateAction<WebPage | undefined>>;
}

export default function InfoAboutCompany(
	props: Readonly<InfoAboutCompanyProps>
): JSX.Element {
	return (
		<>
			<Grid
				item
				xs={gridFullWidth}
				sx={{ marginTop: '16px', marginBottom: '-8px' }}
			>
				<SectionHeader
					title={props.title}
					page={props.editPage}
					setRenderPage={props.setRenderPage}
				/>
				<HorizontalLine />
			</Grid>
			<Grid item xs={gridFullWidth} md={gridHalfWidth}>
				<ValueDescriptor
					title='Forma opodatkowania'
					value={TaxationFormDescription(props.companyData.taxationForm)}
				/>
			</Grid>
			<Grid item xs={gridFullWidth} md={gridHalfWidth}>
				<ValueDescriptor
					title='Liczba pracowników wraz z właścicielem'
					value={props.companyData.numberOfEmployees}
				/>
			</Grid>
			<Grid item xs={gridFullWidth} md={gridHalfWidth}>
				<ValueDescriptor
					title='NIP'
					value={
						staticMask('', props.companyData.nip, '### ### ## ##', null)
							.maskedValue
					}
				/>
			</Grid>
			<Grid item xs={gridFullWidth} md={gridHalfWidth}>
				<ValueDescriptor
					title='Numer rachunku bankowego Twojej firmy'
					value={
						staticMask(
							'',
							props.companyData.bankAccountNumber,
							'## #### #### #### #### #### ####',
							null
						).maskedValue
					}
				/>
			</Grid>
			<Grid item xs={gridFullWidth} md={gridHalfWidth}>
				<ValueDescriptor title='Twój bank' value={props.companyData.bankName} />
			</Grid>
			<Grid item xs={gridFullWidth} md={gridHalfWidth}>
				<ValueDescriptor
					title='Przychód firmy'
					value={formatAmount(props.companyData.income)}
				/>
			</Grid>
		</>
	);
}
