import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { secondaryMainColor } from '../../../../../theme/palette/palette';
import infoIconBlue from '../../../../../common/images/infoIconBlue.svg';

const LightAttachDocumentsDescMoreTime = (): JSX.Element => {
	return (
		<Stack
			sx={{
				flexDirection: 'row',
				justifyContent: 'flex-start',
				alignItems: 'flex-start',
				marginTop: '16px',
			}}
		>
			<img src={infoIconBlue} alt='alertIcon' style={{ marginTop: '-4px' }} />
			<Typography
				variant='p2'
				sx={{ color: '#656572', maxWidth: '345px' }}
			>
				Jeżeli potrzebujesz więcej czasu na wygenerowanie dokumentów{' '}
				<Box
					sx={{
						display: 'inline-block',
						color: secondaryMainColor,
						fontWeight: 'bold',
						fontSize: '16px',
						fontFamily: 'Athletics, sans-serif',
					}}
				>
					zapisz wniosek
				</Box>{' '}
				i wróć do niego w dowolnym momencie.
			</Typography>
		</Stack>
	);
};

export default LightAttachDocumentsDescMoreTime;
