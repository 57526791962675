import React, { useState } from 'react';

type contextType = [
    saveApplication: boolean,
    setSaveApplication: React.Dispatch<React.SetStateAction<boolean>>
]

const defaultContextValue: contextType = [false, (): boolean => false]

export const ContextSaveApplicationData = React.createContext<contextType>(defaultContextValue)

export const ContextSaveApplicationDataProvider = (props: { children: JSX.Element }): JSX.Element => {
    const [isSaveApplicationData, setIsSaveApplicationData] = useState(false)
    return (
        <ContextSaveApplicationData.Provider value={[isSaveApplicationData, setIsSaveApplicationData]}>
            {props.children}
        </ContextSaveApplicationData.Provider>
    )
}