/* eslint-disable no-mixed-spaces-and-tabs */
import Grid from '@mui/material/Grid';
import Slider from '@mui/material/Slider';
import Typography from '@mui/material/Typography';
import { useRef, useState } from 'react';
import { formatAmount } from '../../../../common/helpers/number-helper';
import { Input } from '../../../../components/FormItems/NestInput/NestInput.css';
import MultiCarousel from '../../../../components/MultiCarousel/MultiCarousel';
import { gridFullWidth } from '../../../../theme/grid/GridConstants';
import { mainTheme } from '../../../../theme/mainTheme';
import { CalculatorContainer, TextContainer } from './LightCalculatorPage.css';
import { primaryMainColor, tertiaryMainColor, textColorPrimary } from '../../../../theme/palette/palette';

const step = 1000;

export interface CalculatorProps {
	isLoading: boolean;
	min: number;
	max: number;
	defaultAmount: number;
	periods: number[];
	defaultPeriod: number;
	onChange: (creditData: [creditAmount: number, creditPeriod: number]) => void;
	text: string;
}

export default function LightCalculator(props: CalculatorProps): JSX.Element {
	const [creditAmount, setCreditAmount] = useState(props.defaultAmount);
	const [creditAmountInput, setCreditAmountInput] = useState(
		props.defaultAmount
	);
	const [creditAmountSlider, setCreditAmountSlider] = useState(
		props.defaultAmount
	);
	const [creditPeriod, setCreditPeriod] = useState(props.defaultPeriod);

	const periodContainerRef = useRef<HTMLDivElement>(null);

	const onCreditAmountInputChange = (value: number | string): void => {
		value = parseToNum(value, creditAmount);
		setCreditAmountInput(value);
	};

	const onCreditAmountChange = (value: string | number): void => {
		value = parseToNum(value, creditAmount);
		value = Math.round(value / step) * step;
		if (value < props.min) {
			value = props.min;
		}
		if (value > props.max) {
			value = props.max;
		}
		setCreditAmount(value);
		setCreditAmountInput(value);
		setCreditAmountSlider(value);
		if (creditAmount === value) {
			return;
		}
		props.onChange([value, creditPeriod]);
	};

	const onCreditPeriodChange = (value: number): void => {
		if (!props.periods.includes(value) || creditPeriod === value) {
			return;
		}

		setCreditPeriod(value);
		props.onChange([creditAmount, value]);
	};

	const parseToNum = (value: string | number, defaultVal: number): number => {
		if (typeof value === 'string') {
			value = value.replace(/\D/g, '');
			const tryParse = +value;
			if (!isNaN(tryParse)) {
				value = tryParse;
			} else {
				value = defaultVal;
			}
		}

		return value;
	};

	return (
		<CalculatorContainer>
			<Grid container>
				<Grid item xs={gridFullWidth} sx={{ marginBottom: '12px' }}>
					<TextContainer sx={{ maxWidth: { md: '355px', xs: '100%' } }}>
						<Typography
							variant='h9'
							sx={{
								color: mainTheme.palette.text.secondary,
							}}
						>
							{props.text}
						</Typography>
					</TextContainer>
				</Grid>
				<Grid item xs={gridFullWidth} md={8}>
					<Slider
						disabled={props.isLoading}
						sx={{
							width: { xs: '100%', md: '319px' },
							marginTop: { xs: '0', md: '8px' },
							'.MuiSlider-markLabel': {
								fontFamily: 'Inter, sans-serif',
							},
						}}
						valueLabelDisplay='auto'
						max={props.max}
						min={props.min}
						step={step}
						value={creditAmountSlider}
						onChange={(_e, value): void =>
							setCreditAmountSlider(value as number)
						}
						onChangeCommitted={(_e, value): void =>
							onCreditAmountChange(value as number)
						}
						marks={[
							{ value: props.min, label: `${formatAmount(props.min)} PLN` },
							{ value: props.max, label: `${formatAmount(props.max)} PLN` },
						]}
					/>
				</Grid>
				<Grid item xs={gridFullWidth} md={4}>
					<Input
						disabled={props.isLoading}
						step={step}
						type='text'
						value={formatAmount(creditAmountInput)}
						error={false}
						sx={{
							width: { xs: 'calc(100% - 32px)', md: '138px' },
							marginTop: { xs: '25px', md: '0' },
							color: textColorPrimary,
						}}
						onChange={(e): void => onCreditAmountInputChange(e.target.value)}
						onBlur={(e): void => onCreditAmountChange(e.target.value)}
					/>
				</Grid>
				<Grid
					item
					xs={gridFullWidth}
					sx={{ marginTop: { xs: '32px', md: '57px' }, marginBottom: '12px' }}
				>
					<Typography
						variant='h9'
						sx={{
							color: mainTheme.palette.text.secondary,
							fontWeight: '600',
						}}
					>
						Wybierz okres spłaty
					</Typography>
				</Grid>
				<Grid
					ref={periodContainerRef}
					key='period_container'
					item
					xs={gridFullWidth}
				>
					<MultiCarousel
						carouselColor={primaryMainColor}
						sx={{minWidth:'81px', height:'53px'}}
						carouselColorText={textColorPrimary}
						carouselColorBackground={tertiaryMainColor}
						numberOfItemsOnDesktopView={5}
						numberOfItemsOnTabletView={5}
						numberOfItemsOnMobileView={3}
						startItemIndex={props.periods.length - 1}
						periods={props.periods}
						setSelectedPeriod={(selectedPeriod): void =>
							onCreditPeriodChange(selectedPeriod)
						}
						width100={true}
					/>
				</Grid>
			</Grid>
		</CalculatorContainer>
	);
}
