import { FileType } from '../../../enums/FileType';
import { secondaryMainColor } from '../../../theme/palette/palette';
import { typography } from '../../../theme/typography/typography';
import { SmsContractDataModel } from './SmsContractSigningDataModels';

export const defaultValues: SmsContractDataModel = {
	smsConfirmationCode: '',
};

export const documents = [
	{
		fileName: 'Umowa kredytowa.pdf',
		fileType: FileType.ContractDraft,
	},
	{
		fileName: 'Regulamin.pdf',
		fileType: FileType.ContractRegulations,
	},
	{
		fileName: 'Tabela oprocentowania.pdf',
		fileType: FileType.InterestRateTable,
	},
	{
		fileName: 'Tabela opłat i prowizji.pdf',
		fileType: FileType.TableOfFeesAndCommissions,
	},
];

export const submitButtonFileStyles = {
	color: secondaryMainColor,
	fontFamily: 'Athletics, sans-serif',
	...typography.h7,
	border: 0,
	padding: 0,
	background: 'unset',
	width: 'unset',
};
