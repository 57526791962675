export enum OperatingSystem {
	Unknown = 0,
	android = 1,
	iOS = 2,
}

export const getOperatingSystem = (): OperatingSystem => {
	const userAgent: string = navigator.userAgent;

	if (/android/i.test(userAgent)) {
		return OperatingSystem.android;
	}

	if (/iPhone|iPad|iPod/i.test(userAgent)) {
		return OperatingSystem.iOS;
	}

	return OperatingSystem.Unknown;
};
