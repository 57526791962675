/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-call */
import { Switch, Typography } from '@mui/material';
import React, { Dispatch, SetStateAction } from 'react';
import { UseFormReturn } from 'react-hook-form';
import { TypeOfProperty, typeOfPropertyOptions } from './ClientWealthFormConstants';
import { gridFullWidth, gridHalfWidth } from '../../../../../theme/grid/GridConstants';
import Grid from '@mui/material/Grid';
import ClientWealthOther from './ClientWealthOther/ClientWealthOther';
import ClientWealthRealEstate from './ClientWealthRealEstate/ClientWealthRealEstate';
import ClientWealthMachine from './ClientWealthMachine/ClientWealthMachine';
import ClientWealthVehicle from './ClientWealthVehicle/ClientWealthVehicle';
import {
	IClientWealth,
	IClientWealthMachine,
	IClientWealthOther,
	IClientWealthRealEstate,
	IClientWealthSendData,
	IClientWealthVehicle,
} from '../../PersonalDataModels';
import ClientWealthMultiSelect from '../ClientWealthMultiSelect/ClientWealthMultiSelect';

interface ClientWealthFormProps {
	methods: UseFormReturn<Record<string, unknown>>;
	watch: IClientWealth[];
	setIsOpenClientWealthForm: Dispatch<SetStateAction<boolean>>;
	isOpenClientWealthForm: boolean;
}

const ClientWealthForm = (props: ClientWealthFormProps): JSX.Element => {
	const switchHandleChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
		props.setIsOpenClientWealthForm(event.target.checked);
		if (!event.target.checked) {
			props.methods.setValue('clientWealth', []);
			typeOfPropertyOptions.forEach((x: IClientWealth): boolean => (x.active = false));
		}
	};

	const handleChangeMultiSelect = (type: TypeOfProperty): void => {
		const findTypeObj = typeOfPropertyOptions.find((x: IClientWealth): boolean => x.type === type);
		if (!findTypeObj) {
			return;
		}

		let objToSave: IClientWealth[] = [...props.watch, { ...findTypeObj }];

		findTypeObj.active = !findTypeObj.active;

		if (!findTypeObj.active) {
			objToSave = objToSave.filter((x: IClientWealth): boolean => x.type !== type);
			props.methods.setValue('clientWealth', objToSave);
		}

		if (findTypeObj.active) {
			props.methods.setValue('clientWealth', objToSave);
		}
	};

	const removeClientWealthFormSingleElement = (
		name: string,
		indexToRemove: number,
		type: TypeOfProperty,
		clientWealthTypeObj: IClientWealthRealEstate[] | IClientWealthVehicle[] | IClientWealthMachine[] | IClientWealthOther[]
	): void => {
		const filteredData = [...clientWealthTypeObj].filter(
			(x: IClientWealthRealEstate | IClientWealthVehicle | IClientWealthMachine | IClientWealthOther): boolean => x.id !== indexToRemove
		);

		if (!filteredData.length) {
			props.methods.setValue(
				'clientWealth',
				(props.methods.watch('clientWealth') as IClientWealth[]).filter((x: IClientWealth): boolean => x.type !== type)
			);
			const foundTypeOfPropertyOption = typeOfPropertyOptions.find((x: IClientWealth): boolean => x.type === type);

			if (!foundTypeOfPropertyOption) {
				return;
			}

			foundTypeOfPropertyOption.active = false;

			return;
		}

		props.methods.setValue(name, filteredData);
	};

	const appendClientWealthFormSingleElement = (
		name: string,
		initObj: IClientWealthOther | IClientWealthVehicle | IClientWealthRealEstate | IClientWealthMachine,
		clientWealthTypeObj: IClientWealthOther[] | IClientWealthVehicle[] | IClientWealthRealEstate[] | IClientWealthMachine[]
	): void => {
		props.methods.setValue(name, [
			...clientWealthTypeObj,
			{
				...initObj,
				id: new Date().getTime(),
			},
		]);
	};

	return (
		<>
			<Grid container sx={{ width: { md: '83%', xs: '100%' } }}>
				<Grid
					item
					xs={gridFullWidth}
					sx={{
						paddingTop: '0',
						justifyContent: 'space-between',
					}}
					display='flex'
					alignItems='center'
				>
					<Typography variant='p1' sx={{ marginRight: '16px', marginBottom: '8px' }}>
						Podaję majątek osobisty i firmowy
					</Typography>
					<Switch sx={{ margin: '28px 0' }} checked={props.isOpenClientWealthForm} onChange={switchHandleChange} />
				</Grid>
			</Grid>
			{props.isOpenClientWealthForm && (
				<>
					<Typography variant='h5' sx={{ width: '100%', marginBottom: '24px' }}>
						Majątek firmowy i indywidualny
					</Typography>
					<Grid container rowSpacing={4} columnSpacing={9} sx={{ marginBottom: '24px' }}>
						<Grid item xs={gridFullWidth} md={gridHalfWidth}>
							<ClientWealthMultiSelect
								label='Rodzaj majątku'
								options={typeOfPropertyOptions}
								value={props.watch}
								methods={props.methods}
								handleChange={handleChangeMultiSelect}
							/>
						</Grid>
					</Grid>
					{props.watch.map((item: IClientWealth | IClientWealthSendData, index: number): JSX.Element => {
						return (
							<div key={item.type} style={{ width: '100%' }}>
								{item.type === TypeOfProperty.Other && (
									<ClientWealthOther
										methods={props.methods}
										index={index}
										append={appendClientWealthFormSingleElement}
										remove={removeClientWealthFormSingleElement}
									/>
								)}
								{item.type === TypeOfProperty.RealEstate && (
									<ClientWealthRealEstate
										methods={props.methods}
										index={index}
										append={appendClientWealthFormSingleElement}
										remove={removeClientWealthFormSingleElement}
									/>
								)}
								{item.type === TypeOfProperty.Machine && (
									<ClientWealthMachine
										methods={props.methods}
										index={index}
										append={appendClientWealthFormSingleElement}
										remove={removeClientWealthFormSingleElement}
									/>
								)}
								{item.type === TypeOfProperty.Vehicle && (
									<ClientWealthVehicle
										methods={props.methods}
										index={index}
										append={appendClientWealthFormSingleElement}
										remove={removeClientWealthFormSingleElement}
									/>
								)}
							</div>
						);
					})}
				</>
			)}
		</>
	);
};

export default ClientWealthForm;
