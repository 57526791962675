import React from 'react';

export const blockDashAndZero = (
	event: React.KeyboardEvent,
	state: string
): void => {
	if (event.key === '-' || (event.key === '0' && !state.length)) {
		event.preventDefault();
	}
};

export const blockPasteEvent = (input: HTMLInputElement | null): void => {
	if (!input) {
		return;
	}
	input.addEventListener('paste', (event): void => {
		event.preventDefault();
	});
};

export const blockDashPlusPointAndArrows = (
	event: React.KeyboardEvent,
	state: string
): void => {
	if (
		event.key === '-' ||
		event.key === '+' ||
		event.key === ',' ||
		event.key === '.' ||
		event.key === 'e' ||
		event.key === 'ArrowDown' ||
		event.key === 'ArrowUp' ||
		event.key === 'E' ||
		(state.startsWith('0') && event.key !== 'Backspace')
	) {
		event.preventDefault();
	}
};

export const onlyNumbers = (
	event: React.KeyboardEvent,
	state: string
): void => {
	const maxLengthOfState = 16;
	const onlyNumbersRegex = new RegExp('^[0-9]*$');
	if(event.key === 'Tab'){
		return;
	}
	if (state.length >= maxLengthOfState && event.key !== 'Backspace') {
		event.preventDefault();
	}
	if (event.key !== 'Backspace' && !onlyNumbersRegex.test(event.key)) {
		event.preventDefault();
	}
	if (state.toString().startsWith('0') && event.key !== 'Backspace') {
		event.preventDefault();
	}
	return;
};

export const onlyNumbersMaxFour = (
	event: React.KeyboardEvent,
	state: string
): void => {
	const maxLengthOfState = 4;
	const onlyNumbersRegex = new RegExp('^[0-9]*$');
	if(event.key === 'Tab'){
		return;
	}
	if (state.length >= maxLengthOfState && event.key !== 'Backspace') {
		event.preventDefault();
	}
	if (event.key !== 'Backspace' && !onlyNumbersRegex.test(event.key)) {
		event.preventDefault();
	}
	if (state.toString().startsWith('0') && event.key !== 'Backspace') {
		event.preventDefault();
	}
	return;
};

export const blockWritingCurrencyMoreThanOneBilliard = (
	event: React.KeyboardEvent,
	state: string
): void => {
	const maxLengthOfState = 20;
	const decimalValueSplit = state.split(',');
	const decimalValueLength = decimalValueSplit[1]?.length;
	if(event.key === 'Tab'){
		return;
	}
	if (
		state.length > maxLengthOfState &&
		event.key === ',' &&
		typeof decimalValueLength === 'undefined'
	) {
		return;
	}
	if (
		state.length > maxLengthOfState &&
		event.key === ',' &&
		decimalValueSplit.length > 1
	) {
		event.preventDefault();
		return;
	}
	if (
		(!state.length && event.key === '0') ||
		(state.startsWith('0') && event.key === 'Backspace')
	) {
		return;
	}
	if (state.startsWith('0')) {
		event.preventDefault();
		return;
	}
	if (
		state.length > maxLengthOfState &&
		event.key !== ',' &&
		decimalValueLength <= 2
	) {
		return;
	}
	if (
		(state.length > maxLengthOfState && event.key !== 'Backspace') ||
		(!Number(event.key) && !state.length)
	) {
		event.preventDefault();
		return;
	}
};
