import { styled } from '@mui/system';
import { buttonsBreakpoint } from '../../../theme/breakpoints/breakpoints';
import { mainTheme } from '../../../theme/mainTheme';

export const HorizontalLine = styled('div')({
	width: '100%',
	borderBottom: `2px solid ${mainTheme.palette.text.secondary}`,
	marginTop: '8px',
});

export const ButtonContainer = styled('div')({
	width: '100%',
	marginTop: '48px',
	marginBottom: '80px',
	display: 'flex',
	justifyContent: 'end',
	[mainTheme.breakpoints.down(buttonsBreakpoint as number)]: {
		flexDirection: 'column',
		alignItems: 'center',
		height: '128px',
	},
});
