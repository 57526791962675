import BaseFetch from '../../../common/api/BaseFetch';
import { ApiConfig } from '../../../common/api/FetchTypes';
import { ApplicationProcess } from '../../../common/constants/application-process';

export interface ILeadInitialData {
	brokerId: string;
	brokerDescription: string;
	showCaptcha:boolean;
	sourceId:string;
	logoName: string;
	campaignNumber: string;
	campaignName: string;
	leadProduct: LeadProductType;
	parameters: IParametersCashLoan & IParametersOvd;
	partnerUrl: string;
	logoUrl?: string;
	wibor: number;
	legalNote: string;
	process?: ApplicationProcess;
	displayCalculator?: boolean;
}

export interface IParametersOvd {
	maxAmount: number;
}

export enum LeadProductType {
	None = 0,
	CashLoan = 1,
	Overdraft = 2,
}

export interface IParametersCashLoan {
	minAmount: number;
	maxAmount: number;
	minTenor: number;
	maxTenor: number;
	commission: number;
	markUp: number;
	periods: number[];
}

const getInitialDataKey = 'getInitialData';

function getInitialData(props: string): Promise<ILeadInitialData> {
	return BaseFetch({ url: `Lead/InitialData/${props}`, method: 'GET' });
}

export const GetInitialDataConfig: ApiConfig<ILeadInitialData, string> = {
	key: getInitialDataKey,
	fn: getInitialData,
};
