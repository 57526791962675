import { ApplicationProcess } from 'src/common/constants/application-process';
import Agreement from '../../../models/Agreement';

export interface ICreditInfoDataState {
	creditInfoData: ICreditInfoDataProperties;
}

export interface ICreditInfoDataProperties {
	clientId: string;
	period: number;
	agreements: Agreement[];
	magicWords: string;
	processId: string;
	process: ApplicationProcess;
}

export const initialState: ICreditInfoDataState = {
	creditInfoData: {
		clientId: '',
		period: 0,
		agreements: [],
		magicWords: '',
		processId: '',
		process: ApplicationProcess.Unknown,
	},
};
