import { IAddressPropertiesError } from 'src/common/models/addressAndErrorsModels';
import {
	ILightAddressFormErrors,
	ILightCorrespondenceAddressFormErrors,
	LightCompanyDataFormFieldsModel,
} from '../models/LightCompanyData.models';

export const lightAddressFormControlNames = {
	street: 'companyAddress.street',
	houseNumber: 'companyAddress.houseNumber',
	apartmentNumber: 'companyAddress.apartmentNumber',
	postalCode: 'companyAddress.postalCode',
	city: 'companyAddress.city',
};

export const lightCorrespondenceAddressFormControlNames = {
	street: 'companyCorrespondenceAddress.street',
	houseNumber: 'companyCorrespondenceAddress.houseNumber',
	apartmentNumber: 'companyCorrespondenceAddress.apartmentNumber',
	postalCode: 'companyCorrespondenceAddress.postalCode',
	city: 'companyCorrespondenceAddress.city',
};

export const LightCompanyDataDefaultValues: LightCompanyDataFormFieldsModel = {
	firstName: '',
	lastName: '',
	socialSecurityNumber: '',
	nip: '',
	taxationForm: '',
	numberOfEmployees: null,
	email: '',
	phoneNumber: '',
	declarationType: '',
	bankName: '',
	bankAccountNumber: '',
	companyAverageMonthlyIncome: '',
	companyAddress: {
		street: '',
		houseNumber: '',
		apartmentNumber: '',
		postalCode: '',
		city: '',
	},
	isCompanyCorrespondenceAddressSameAsCompanyAddress: true,
	companyCorrespondenceAddress: {
		street: '',
		houseNumber: '',
		apartmentNumber: '',
		postalCode: '',
		city: '',
	},
};

export const getErrorsLightAddressForm = (
	errors: ILightAddressFormErrors
): IAddressPropertiesError => {
	return {
		street: errors.companyAddress?.street,
		houseNumber: errors.companyAddress?.houseNumber,
		apartmentNumber: errors.companyAddress?.apartmentNumber,
		postalCode: errors.companyAddress?.postalCode,
		city: errors.companyAddress?.city,
	};
};

export const getErrorsLightCorrespondenceAddressForm = (
	errors: ILightCorrespondenceAddressFormErrors
): IAddressPropertiesError => {
	return {
		street: errors.companyCorrespondenceAddress?.street,
		houseNumber: errors.companyCorrespondenceAddress?.houseNumber,
		apartmentNumber: errors.companyCorrespondenceAddress?.apartmentNumber,
		postalCode: errors.companyCorrespondenceAddress?.postalCode,
		city: errors.companyCorrespondenceAddress?.city,
	};
};

//TEMPORARY
export const LightCompanyDataVerifiedTempArgs = [
	{
		isSelected: false,
		mediaConsents: [],
		symbol: 'IDK_YET',
		section: 'TEST',
		sectionName:
			'TEST nazwa sekcji, nie służy chyba w tym przypadku do niczego',
		abbreviatedContent:
			'Oświadczam, że akceptuję Regulamin usługi dostępu do skonsolidowanych informacji o...',
		content:
			'Oświadczam, że akceptuję Regulamin usługi dostępu do skonsolidowanych informacji o rachunku oraz Politykę prywatności Autopay S.A. Zgadzam się na świadczenie przez Autopay S.A. usługi dostępu do skonsolidowanych informacji o rachunku posiadanym przeze mnie w innym banku oraz pobranie historii transakcji z rachunku za okres ostatnich 365 dni.',
		contentHtml:
			'<span style="color: red">MOCK!</span> Oświadczam, że w okresie ostatnich 12 miesięcy kalendarzowych osiągnąłem/am średniomiesięczny przychód w wysokości co najmniej 9 999 999,00 PLN oraz oświadczam, że w całym okresie kredytowania nie prognozuję pogorszenia sytuacji finansowej mojej firmy',
		isRequired: true,
		order: 1,
	},
	{
		isSelected: false,
		mediaConsents: [],
		symbol: 'IDK_YET_2',
		section: 'TEST',
		sectionName:
			'TEST nazwa sekcji, nie służy chyba w tym przypadku do niczego',
		abbreviatedContent:
			'Oświadczam, że akceptuję Regulamin usługi dostępu do skonsolidowanych informacji o...',
		content:
			'Oświadczam, że akceptuję Regulamin usługi dostępu do skonsolidowanych informacji o rachunku oraz Politykę prywatności Autopay S.A. Zgadzam się na świadczenie przez Autopay S.A. usługi dostępu do skonsolidowanych informacji o rachunku posiadanym przeze mnie w innym banku oraz pobranie historii transakcji z rachunku za okres ostatnich 365 dni.',
		contentHtml:
			'<span style="color: red">MOCK!</span> Oświadczam, że:<br />' +
			'W przypadku formy opodatkowania książka przychodów i rozchodów w ostatnim zamkniętym roku podatkowym w deklaracji PIT36/36L nie osiągnąłem/am straty.<br />' +
			'lub<br />' +
			'W przypadku formy opodatkowania ryczałt ewidencjonowany średniomiesięczny przychód w ostatnim zamkniętym roku podatkowym w deklaracji PIT28 jest nie niższy niż połowa średniomiesięcznego przychodu wyliczonego na podstawie obrotów z udostępnionego przeze mnie rachunku.',
		isRequired: true,
		order: 1,
	},
];
export const LightCompanyDataTempArgs = [
	{
		isSelected: false,
		mediaConsents: [],
		symbol: 'OSW_AUTOPAY_REGULAMIN',
		section: 'TEST',
		sectionName:
			'TEST nazwa sekcji, nie służy chyba w tym przypadku do niczego',
		abbreviatedContent:
			'Oświadczam, że akceptuję Regulamin usługi dostępu do skonsolidowanych informacji o...',
		content:
			'Oświadczam, że akceptuję Regulamin usługi dostępu do skonsolidowanych informacji o rachunku oraz Politykę prywatności Autopay S.A. Zgadzam się na świadczenie przez Autopay S.A. usługi dostępu do skonsolidowanych informacji o rachunku posiadanym przeze mnie w innym banku oraz pobranie historii transakcji z rachunku za okres ostatnich 365 dni.',
		contentHtml:
			'<span style="color: red">MOCK!</span> Oświadczam, że akceptuję Regulamin usługi dostępu do skonsolidowanych informacji o rachunku oraz Politykę prywatności Autopay S.A. Zgadzam się na świadczenie przez Autopay S.A. usługi dostępu do skonsolidowanych informacji o rachunku posiadanym przeze mnie w innym banku oraz pobranie historii transakcji z rachunku za okres ostatnich 365 dni.',
		isRequired: true,
		order: 1,
	},
	{
		isSelected: false,
		mediaConsents: [],
		symbol: 'ZGO_AUTOPAY_TOZSAMOSC',
		section: 'TEST',
		sectionName:
			'TEST nazwa sekcji, nie służy chyba w tym przypadku do niczego',
		abbreviatedContent:
			'Wyrażam zgodę na udostępnienie przez Autopay S.A. danych objętych historią...',
		content:
			'Wyrażam zgodę na udostępnienie przez Autopay S.A. danych objętych historią transakcji z rachunku posiadanego przeze mnie w innym banku (w tym danych objętych tajemnicą płatniczą oraz danych osobowych dotyczących ewentualnego współposiadacza rachunku płatniczego, od którego uzyskałem zgodę na udostępnienie) do Nest Bank S.A. z siedzibą w Warszawie (dalej: Bank) w celu przetwarzania ich przez Bank do potwierdzenia mojej tożsamości.',
		contentHtml:
			'<span style="color: red">MOCK!</span> Wyrażam zgodę na udostępnienie przez Autopay S.A. danych objętych historią transakcji z rachunku posiadanego przeze mnie w innym banku (<span style="color: red">w tym danych objętych tajemnicą płatniczą oraz danych osobowych dotyczących ewentualnego współposiadacza rachunku płatniczego, od którego uzyskałem zgodę na udostępnienie</span>) do Nest Bank S.A. z siedzibą w Warszawie (dalej: Bank) w celu przetwarzania ich przez Bank do potwierdzenia mojej tożsamości.',
		isRequired: true,
		order: 1,
	},
	{
		isSelected: false,
		mediaConsents: [],
		symbol: 'ZGO_AUTOPAY_OCENA',
		section: 'TEST',
		sectionName:
			'TEST nazwa sekcji, nie służy chyba w tym przypadku do niczego',
		abbreviatedContent:
			'Wyrażam zgodę na udostępnienie przez Autopay S.A. danych objętych historią transakcji z rachunku posiadanego przeze...',
		content:
			'Wyrażam zgodę na udostępnienie przez Autopay S.A. danych objętych historią transakcji z rachunku posiadanego przeze mnie w innym banku (w tym danych objętych tajemnicą płatniczą oraz danych osobowych dotyczących ewentualnego współposiadacza rachunku płatniczego, od którego uzyskałem zgodę na udostępnienie) do Nest Bank S.A. z siedzibą w Warszawie (dalej: Bank) w celu przetwarzania ich przez Bank oceny przez Bank mojej zdolności kredytowej i ryzyka kredytowego dla produktów Banku.',
		contentHtml:
			'<span style="color: red">MOCK!</span> Wyrażam zgodę na udostępnienie przez Autopay S.A. danych objętych historią transakcji z rachunku posiadanego przeze mnie w innym banku (w tym danych objętych tajemnicą płatniczą oraz danych osobowych dotyczących ewentualnego współposiadacza rachunku płatniczego, od którego uzyskałem zgodę na udostępnienie) do Nest Bank S.A. z siedzibą w Warszawie (dalej: Bank) w celu przetwarzania ich przez Bank oceny przez Bank mojej zdolności kredytowej i ryzyka kredytowego dla produktów Banku.',
		isRequired: true,
		order: 1,
	},
];
//TEMPORARY
