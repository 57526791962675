export enum ApplicationFileTypeString {
    None = 'None',
    ZusAttachment = 'ZusAttachment',
    PitAttachment = 'PitAttachment',
    UsAttachment = 'UsAttachment',
}

export enum ApplicationFileTypeNumber {
    None = 0,
    ZusAttachment = 1,
    PitAttachment = 2,
    UsAttachment = 3,
}

export enum CalculatedLimit {
    CurrencyValue = 'currencyValue',
    PercentValue = 'percentValue',
    DaysValue = 'daysValue'
}