import BaseFetch from '../../../common/api/BaseFetch';
import { ApiConfig } from '../../../common/api/FetchTypes';

export const test = 5;

const calculateLoanKey = 'calculateLoanInfo';

export interface CalculateLoanQuery {
	value: number;
	period: number;
	processId: string;
	merchantId: string;
}

export interface CalculatedLoanInfo {
	nettValue: number;
	installment: number;
	interest: number;
	commission: number;
}

const calculateLoanInfo = (
	props: CalculateLoanQuery
): Promise<CalculatedLoanInfo> => {
	return BaseFetch({
		url: 'Offer/CalculateLoanInfoByProcessId',
		method: 'GET',
		queryParams: { ...props },
	});
};

export const calculateLoanInfoConfig: ApiConfig<
	CalculatedLoanInfo,
	CalculateLoanQuery
> = {
	fn: calculateLoanInfo,
	key: calculateLoanKey,
};
