import { ApplicationProcess } from '../../../common/constants/application-process';
import { applicationProcessKey, getSessionStorageValue } from '../../../common/sessionStorage/SessionStorageService';

export const navigateToRoute = (lightCl: string | number, lightOvd: string | number): string | number | undefined => {
    const currentApplicationProcess = getSessionStorageValue(applicationProcessKey) as unknown as ApplicationProcess;
    if(!currentApplicationProcess){
        return;
    }
    switch (Number(currentApplicationProcess) as ApplicationProcess) {
        case ApplicationProcess.LightCl:
            return lightCl;
        case ApplicationProcess.LightOvd:
            return lightOvd;
        default:
            return lightCl;
    }
}
