import { styled } from '@mui/system';

export const DescriptionItem = styled('p')`
	padding-top: 20px;
	margin: 0;
	line-height: 19px;
	font-family: Inter, sans-serif;
	font-size: 16px;
	font-style: normal;
	font-weight: 400;
`;

