import { styled } from '@mui/material/styles';
import { mainTheme } from '../../theme/mainTheme';

export const BottomInfoContainer = styled('div')({
	width: '100%',
	backgroundColor: '#F7FBFF',
	padding: '60px 0 70px 0',
	[mainTheme.breakpoints.down('md')]: {
		padding: '60px 0 0 0',
	},
});

export const BottomInfo = styled('div')({
	display: 'flex',
	minHeight: 'inherit',
	flexDirection: 'column',
	width: '833px',
	margin: 'auto',
	[mainTheme.breakpoints.down('md')]: {
		width: 'unset',
		margin: '0 24px',
	},
});

export const TileContainer = styled('div')({
	display: 'flex',
	flexDirection: 'row',
	justifyContent: 'space-between',
	[mainTheme.breakpoints.down('md')]: {
		flexDirection: 'column',
	},
});

export const TileImg = styled('img')({
	width: '96px',
	height: '96px',
});
