export enum EconomicLiabilityType {
	None = 0, // invalid in request
	Economic = 1, // Economic liability (Gospodarcza)
	EconomicCapital = 2, // Economic/Capital liability (Ekonomiczna/kapitałowa)
	Personal = 3, // Personal liability (Osobista)
}

export const economicLiabilityType = {
	[EconomicLiabilityType.None]: 'brak',
	[EconomicLiabilityType.Economic]: 'gospodarcze',
	[EconomicLiabilityType.EconomicCapital]: 'ekonomiczno/kapitałowe',
	[EconomicLiabilityType.Personal]: 'osobiste',
};

export enum ClientWealthType {
	RealEstate = 1,
	Vehicle = 2,
	Machine = 3,
	Other = 4,
}

export const clientWealthType = {
	[ClientWealthType.RealEstate]: 'Nieruchomości',
	[ClientWealthType.Vehicle]: 'Samochody',
	[ClientWealthType.Machine]: 'Maszyny',
	[ClientWealthType.Other]: 'Inne',
};
