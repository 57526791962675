export const countriesOutsideOfPolandData = [
    {id: 0, checked: false, content: 'Kraje Unii Europejskiej'},
    {id: 1, checked: false, content: 'USA/Wielka Brytania/Japonia'},
    {id: 2, checked: false, content: 'Chiny/Zjednoczone Emiraty Arabskie/Tajwan/Wietnam/Kazachstan'},
    {id: 3, checked: false, content: 'Ukraina/Turcja/Izrael/ Monako'},
    {id: 4, checked: false, content: 'Rosja/Białoruś'},
    {id: 5, checked: false, content: 'Pakistan/Afganistan/Iran/Jemen/Syria/Jordania'},
    {id: 6, checked: false, content: 'Kambodża/Korea Północna/ Birma (Mjanma)/Filipiny'},
    {id: 7, checked: false, content: 'Panama/Kajmany/Barbados/Burkina Faso/Haiti /Jamajka'},
    {id: 8, checked: false, content: 'Maroko/Mali/Nikaragua/Senegal/Sudan Południowy/Trynidad i Tobago/Uganda/Vanuatu/Zimbabwe'}
];
