import React, { useContext } from 'react';
import CreditDescription from '../../../components/CreditDescription/CreditDescription';
import LightIndexCreditGoodToKnow from './light-index-credit-good-to-know/LightIndexCreditGoodToKnow';
import { Container } from 'src/common/styles/Layout.css';
import Loader from 'src/components/Loader/Loader';
import LightIndexCreditInfoAndContactDataForm from './light-index-credit-info-and-contact-data-form/LightIndexCreditInfoAndContactDataForm';
import {
	LightIndexLandingPageContext,
	LightIndexLandingPageContextProvider,
} from './state/LightIndexLandingPageContext';

const LightIndexLandingPage = (): JSX.Element => {
	const lightIndexLandingPageContext = useContext(LightIndexLandingPageContext)

	if (!lightIndexLandingPageContext) {
		return (
			<Container>
				<Loader />
			</Container>
		);
	}

	return (
		<LightIndexLandingPageContextProvider>
			<LightIndexCreditInfoAndContactDataForm />	
			<CreditDescription />
			<LightIndexCreditGoodToKnow />
		</LightIndexLandingPageContextProvider>
	);
};

export default LightIndexLandingPage;
