import { useContext, useEffect, useState } from 'react';
import { Route, Routes, useLocation } from 'react-router-dom';
import useFetch from '../common/api/UseFetch';
import {
    applicationIdKey,
    getSessionStorageValue,
    removeSessionStorageValue,
    tokenExpirationKey
} from '../common/sessionStorage/SessionStorageService';
import { SessionContext } from '../context/SessionContext';
import PageWrapper from './components/PageWrapper';
import RouteSpec from './RouteSpec';
import { NotFoundRoute } from './Routing';
import { DoAfterManualPageChangeConfig, routingSessionService, SessionExpirationResponse } from './RoutingService';

export interface AppRouterProps {
    routes: RouteSpec[];
}

export function AppRouter(props: AppRouterProps): JSX.Element {
    const [doAfterManualPageChangeReqHasBeenDone, setDoAfterManualPageChangeReqHasBeenDone] = useState(false);
    const [currentLocation, setCurrentLocation] = useState<RouteSpec>(NotFoundRoute);

    const location = useLocation();
    const { updateSessionExpiration, setAllowGetInToPageWithoutSession } = useContext(SessionContext);
    const { mutate: doAfterManualPageChange } = useFetch(DoAfterManualPageChangeConfig, false, (result: SessionExpirationResponse): void => {
        updateSessionExpiration(new Date(result.sessionExpiresAt));
        setDoAfterManualPageChangeReqHasBeenDone(true);
    });

    useEffect((): void => {
        const current = props.routes.find((x): boolean => matchRoute(x.path, location.pathname)) ?? NotFoundRoute;
        setCurrentLocation(current);
        if (location.pathname !== routingSessionService.loadLocation()?.pathname) {
            setDoAfterManualPageChangeReqHasBeenDone(false);
            routingSessionService.saveLocation(location);
            if (current.doAfterManualPageChangeAction) {
                doAfterManualPageChange({
                    applicationId: getSessionStorageValue(applicationIdKey) ?? '',
                    page: current.page,
                });
            }
        }
    }, [location.pathname]);

    useEffect((): void => {
        if (currentLocation.clearSession) {
            updateSessionExpiration(undefined);
            removeSessionStorageValue(tokenExpirationKey);
        }
        if (currentLocation.allowGetInToPageWithoutSession && setAllowGetInToPageWithoutSession) {
            setAllowGetInToPageWithoutSession(currentLocation.allowGetInToPageWithoutSession);
        }
    }, [doAfterManualPageChangeReqHasBeenDone]);

    const matchRoute = (path: string, current: string): boolean => {
        const pathSplit = path.split('/');
        const currentSplit = current.split('/');
        if (pathSplit.length !== currentSplit.length) {
            return false;
        }

        for (let index = 0; index < pathSplit.length; index++) {
            if (!pathSplit[index].startsWith(':') && pathSplit[index] !== currentSplit[index]) {
                return false;
            }
        }

        return true;
    };

    return (
        <Routes>
            {props.routes.map(
                (x): JSX.Element => (
                    <Route
                        key={`route_${x.path}`}
                        path={x.path}
                        element={
                            <PageWrapper
                                breadcrumbStepName={x.breadcrumbStepName}
                                showSaveStateButton={x.showSaveStateButton}
                                showApplicationNumber={x.showApplicationNumber}
                                showPartnerLogo={x.showPartnerLogo}
                                showHeader={x.showHeader}
                                showFooter={x.showFooter}
                                Child={x.element}
                            />
                        }
                    />
                )
            )}
        </Routes>
    );
}
