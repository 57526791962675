import Grid from '@mui/material/Grid';
import Address from '../../models/Address';
import { WebPage } from '../../routing/Routing';
import { HorizontalLine } from './DataConsistency.css';
import ValueDescriptor from '../../components/ValueDescriptor/ValueDescriptor';
import { gridFullWidth, gridHalfWidth } from '../../theme/grid/GridConstants';
import SectionHeader from './SectionHeader';

export interface AddressDescriptionProps {
    title: string;
    address: Address;
    editPage: WebPage;
    setRenderPage: React.Dispatch<React.SetStateAction<WebPage | undefined>>;
}
export default function AddressDescription(props: Readonly<AddressDescriptionProps>): JSX.Element {

    return (
        <>
            <Grid item xs={gridFullWidth} sx={{ marginTop: '16px', marginBottom: '-8px' }}>
                <SectionHeader title={props.title} page={props.editPage} setRenderPage={props.setRenderPage} />
                <HorizontalLine />
            </Grid>
            <Grid item xs={gridFullWidth} md={gridHalfWidth}>
                <ValueDescriptor
                    title='Ulica'
                    value={props.address.street}
                />
            </Grid>
            <Grid item xs={gridFullWidth} md={gridHalfWidth}>
                <ValueDescriptor
                    title='Numer domu'
                    value={props.address.houseNumber}
                />
            </Grid>
            <Grid item xs={gridFullWidth} md={gridHalfWidth}>
                <ValueDescriptor
                    title='Numer mieszkania'
                    value={props.address.apartmentNumber}
                />
            </Grid>
            <Grid item xs={gridFullWidth} md={gridHalfWidth}>
                <ValueDescriptor
                    title='Kod pocztowy'
                    value={props.address.postalCode}
                />
            </Grid>
            <Grid item xs={gridFullWidth} md={gridHalfWidth}>
                <ValueDescriptor
                    title='Miejscowość'
                    value={props.address.city}
                />
            </Grid>
        </>
    );
}