/* eslint-disable @typescript-eslint/no-unsafe-member-access */
import { Theme } from '@mui/system/createTheme';
import styled from '@mui/system/styled';
import { CSSProperties } from '@mui/material/styles/createMixins';

const breadCrumbBreakpoint = 370;

export const BreadcrumbsContainer = styled('div')<BreadcrumbProps>(
	(props: BreadcrumbProps): CSSProperties => ({
		display: 'flex',
		justifyContent: 'space-between',
		[props.theme.breakpoints.up(460)]: {
			justifyContent: 'center',
		},
	})
);

interface BreadcrumbProps {
	theme: Theme;
	current?: boolean;
	previous?: boolean;
	step?: number;
}

export const Breadcrumb = styled(
	'div',
	{}
)<BreadcrumbProps>(
	(props: BreadcrumbProps): CSSProperties => ({
		border: `${
			props.current
				? `2px solid ${String(props.theme.palette.primary.main)}`
				: 'none'
		}`,
		background: `${
			props.previous
				? String(props.theme.palette.primary.main)
				: String(props.theme.palette.nonary.main)
		}`,
		[props.theme.breakpoints.down('md')]: {
			'&::before': {
				content: props.step === 1 ? 'none' : '""',
				height: '20px',
				transform: 'rotate(90deg)',
				border: '1px solid #E4E4ED',
				position: 'absolute',
				margin: '0 0 0 -23px',
			},
		},
		[props.theme.breakpoints.down('sm')]: {
			'&::before': {
				content: props.step === 1 ? 'none' : '""',
				margin: '3px 0px 0px -15px',
				height: '15px',
				transform: 'rotate(90deg)',
				border: '1px solid #E4E4ED',
				position: 'absolute',
			},
		},
		[props.theme.breakpoints.down(breadCrumbBreakpoint)]: {
			'&::before': {
				content: props.step === 1 ? 'none' : '""',
				margin: '5px 0px 0px -11px',
				height: '10px',
				transform: 'rotate(90deg)',
				border: '1px solid #E4E4ED',
				position: 'absolute',
			},
		},
	})
);

export const BreadcrumbContainer = styled(
	'div',
	{}
)<BreadcrumbProps>(
	(props: BreadcrumbProps): CSSProperties => ({
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
		height: '35px',
		[props.theme.breakpoints.up('xs')]: {
			margin: '0 10px',
		},
		[props.theme.breakpoints.up(breadCrumbBreakpoint)]: {
			margin: '0 13px',
		},
		[props.theme.breakpoints.up('sm')]: {
			margin: '0 22px',
		},
		[props.theme.breakpoints.up('md')]: {
			margin: '0 5px',
		},
		[props.theme.breakpoints.up('lg')]: {
			margin: '0 15px',
		},
	})
);
