import { ILightContactDataProperties } from '../../LightCreditInfoAndContactDataFormModels';

export const defaultValues: ILightContactDataProperties = {
	nip: '',
	email: '',
	phoneNumber: '',
	firstName: '',
	lastName: '',
	magicWords: '',
	brokerId: null
};
