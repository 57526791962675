export const months = [
	'Styczeń',
	'Luty',
	'Marzec',
	'Kwiecień',
	'Maj',
	'Czerwiec',
	'Lipiec',
	'Sierpień',
	'Wrzesień',
	'Październik',
	'Listopad',
	'Grudzień',
];

export const getYearsArray = (): number[] => {
	const currentYear = new Date().getFullYear();
	return Array.from({ length: 11 }, (v, k: number): number => currentYear - k);
};

export const daysOfWeekShort = [
	{ fullDayName: 'niedziela', shortDayName: 'Nd' },
	{ fullDayName: 'poniedziałek', shortDayName: 'Pn' },
	{ fullDayName: 'wtorek', shortDayName: 'Wt' },
	{ fullDayName: 'środa', shortDayName: 'Śr' },
	{ fullDayName: 'czwartek', shortDayName: 'Czw' },
	{ fullDayName: 'piątek', shortDayName: 'Pt' },
	{ fullDayName: 'sobota', shortDayName: 'Sob' },
];
