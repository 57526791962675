/* eslint-disable @typescript-eslint/no-misused-promises */
import Grid from '@mui/material/Grid';
import {
	FieldErrorsImpl,
	FormProvider,
	useForm,
	UseFormReturn,
} from 'react-hook-form';
import {
	convertedClientWealthDataToSend,
	parseClientWealthObj,
} from '../../../common/helpers/client-wealth-and-agreements-req-data-helpers';
import AddressForm from '../../../components/AddressForm/AddressForm';
import {
	ButtonsContainer,
	ButtonSubmitContainer,
	ButtonSubmitTextContainer,
	Form,
	PersonalDataMainContainer,
	PrevButton,
} from './PersonalData.css';
import {
	addressFormControlNames,
	correspondenceAddressFormControlNames,
	defaultValues,
	getErrorsAddressForm,
	getErrorsCorrespondenceAddressForm,
} from './PersonalDataConstants';
import {
	IClientWealth,
	IClientWealthSendData,
	IPersonalDataPropertiesToSend,
	PersonalDataProperties,
} from './PersonalDataModels';
import React, { useEffect, useState, useContext } from 'react';
import InfoAboutYouForm from './components/InfoAboutYouForm/InfoAboutYouForm';
import Typography from '@mui/material/Typography';
import ClientWealthForm from './components/ClientWealthForm/ClientWealthForm';
import {
	DraftPersonalDataConfig,
	GetPersonalDataConfig,
	PostPersonalDataConfig,
} from './api/PersonalDataApi';
import useFetch from '../../../common/api/UseFetch';
import { useNavigate } from 'react-router-dom';
import { OverrideProps } from '../../../routing/RouteSpec';
import { CompanyDataRoute, ContactDataRoute } from '../../../routing/Routing';
import { ContextSaveApplicationData } from '../../../context/ContextSaveApplicationData';
import CustomButton from '../../../components/CustomButton/CustomButton';
import { GetCountriesConfig } from '../../../common/api/DictionaryApi';
import Loader from '../../../components/Loader/Loader';
import { ScrollToTopOnMount } from '../../../common/helpers/scroll-to-top-onmount';
import { formatDateToSend, processDate } from '../../../common/helpers/date-helper';
import { IAddressFormProperties } from '../../../components/AddressForm/AddressFormModels';

export default function PersonalData(props?: OverrideProps): JSX.Element {
	const navigate = useNavigate();
	const [personalData, setPersonalData] = useState<
		undefined | { data: PersonalDataProperties }
	>(undefined);
	const [checkedAddress, setCheckedAddress] = useState(true);
	const [isOpenClientWealthForm, setIsOpenClientWealthForm] =
		useState<boolean>(false);

	const [isSaveApplicationData] = useContext(ContextSaveApplicationData);

	useEffect((): void => {
		if (isSaveApplicationData) {
			saveApplicationData();
		}
	}, [isSaveApplicationData]);

	const personalDataFetchResult = useFetch(
		GetPersonalDataConfig,
		true,
		(data): void => {
			setPersonalData({
				data: {
					firstName: data.firstName,
					lastName: data.lastName,
					socialSecurityNumber: data.socialSecurityNumber,
					idCard: data.idCardNumber,
					idCardIssuedDate: data.idCardIssuedDate,
					idCardExpirationDate: data.idCardExpirationDate,
					countryOfBirth: data.countryOfBirth,
					mothersMaidenName: data.mothersMaidenName,
					address: data.address,
					isCorrespondenceAddressSameAsAddress:
						data.isCorrespondenceAddressSameAsAddress,
					correspondenceAddress: data.isCorrespondenceAddressSameAsAddress
						? {
								street: '',
								houseNumber: '',
								apartmentNumber: '',
								postalCode: '',
								city: '',
						  }
						: ({ ...data.correspondenceAddress } as IAddressFormProperties),
					clientWealth: data.clientWealth,
				},
			});
		}
	);

	const sendPersonalData = useFetch(PostPersonalDataConfig, false, (): void => {
		if (!props?.ommitRouting) {
			navigate(CompanyDataRoute.path);
		}
		if (props?.close) {
			props.close();
		}
	});
	const draftPersonalData = useFetch(
		DraftPersonalDataConfig,
		false,
		(): void => {
			if (props?.close) {
				props.close();
			}
		}
	);
	const getCountries = useFetch(GetCountriesConfig, true);

	const methods = useForm<PersonalDataProperties>({
		defaultValues,
		mode: 'onSubmit',
		reValidateMode: 'onChange',
	});

	const watchClientWealth = methods.watch('clientWealth');
	const watchAddress = methods.watch('address');
	const watchCorrespondenceAddress = methods.watch('correspondenceAddress');

	useEffect((): void => {
		if (!personalData?.data) {
			return;
		}
		if (!getCountries.data) {
			return;
		}

		methods.reset({
			...personalData.data,
			idCardExpirationDate: processDate(personalData.data.idCardExpirationDate),
			idCardIssuedDate: processDate(personalData.data.idCardIssuedDate),
			clientWealth: parseClientWealthObj(
				personalData.data.clientWealth as IClientWealthSendData[]
			),
			countryOfBirth: getCountries.data.some(
				(country): boolean =>
					country.countryName === personalData.data.countryOfBirth
			)
				? personalData.data.countryOfBirth
				: '',
		});

		setCheckedAddress(
			personalData.data.isCorrespondenceAddressSameAsAddress ?? true
		);
		setIsOpenClientWealthForm(!!personalData.data.clientWealth.length);
	}, [personalData?.data, getCountries.data]);

	const onSubmit = (data: PersonalDataProperties): void => {
		const dataToSend: IPersonalDataPropertiesToSend = {
			firstName: data.firstName,
			lastName: data.lastName,
			socialSecurityNumber: data.socialSecurityNumber,
			idCardNumber: data.idCard,
			idCardIssuedDate: formatDateToSend(data.idCardIssuedDate),
			idCardExpirationDate: formatDateToSend(data.idCardExpirationDate),
			countryOfBirth: data.countryOfBirth,
			mothersMaidenName: data.mothersMaidenName,
			address: data.address,
			isCorrespondenceAddressSameAsAddress: checkedAddress,
			correspondenceAddress: checkedAddress
				? data.address
				: data.correspondenceAddress,
			clientWealth: convertedClientWealthDataToSend(
				isOpenClientWealthForm,
				watchClientWealth
			),
		};
		sendPersonalData.mutate(dataToSend as PersonalDataProperties);
	};

	const handleChecked = (event: React.ChangeEvent<HTMLInputElement>): void => {
		setCheckedAddress(event.target.checked);
		methods.setValue(
			'isCorrespondenceAddressSameAsAddress',
			event.target.checked
		);
	};

	const saveAndBackToPreviousPage = (): void => {
		methods.clearErrors();
		props?.close && props.close();
		if (!props?.ommitRouting) {
			navigate(ContactDataRoute.path);
			saveApplicationData();
		} else {
			return;
		}
	};

	const saveApplicationData = (): void => {
		const dataToSend: IPersonalDataPropertiesToSend = {
			firstName: methods.watch('firstName'),
			lastName: methods.watch('lastName'),
			socialSecurityNumber: methods.watch('socialSecurityNumber'),
			idCardIssuedDate: methods.watch('idCardIssuedDate'),
			idCardExpirationDate: methods.watch('idCardExpirationDate'),
			countryOfBirth: methods.watch('countryOfBirth'),
			mothersMaidenName: methods.watch('mothersMaidenName'),
			address: methods.watch('address'),
			clientWealth: convertedClientWealthDataToSend(
				isOpenClientWealthForm,
				watchClientWealth
			) as unknown as IClientWealthSendData[],
			isCorrespondenceAddressSameAsAddress: checkedAddress,
			idCardNumber: methods.watch('idCard')?.toUpperCase(),
			correspondenceAddress: checkedAddress
				? watchAddress
				: watchCorrespondenceAddress,
		};
		draftPersonalData.mutate(dataToSend);
	};

	if (personalDataFetchResult.isPending || getCountries.isPending) {
		return <Loader />;
	}

	return (
		<PersonalDataMainContainer>
			<ScrollToTopOnMount />
			<FormProvider {...methods}>
				<Form
					onSubmit={methods.handleSubmit(onSubmit)}
					sx={{
						justifyContent: { xs: 'center', md: 'space-between' },
						flexDirection: { xs: 'column', md: 'row' },
					}}
				>
					<Typography
						variant='h2'
						sx={{
							width: '100%',
							marginBottom: '24px',
							fontSize: { xs: '28px', md: '34px' },
							lineHeight: { xs: '33px', md: '40px' },
						}}
					>
						Informacje o Tobie
					</Typography>
					<InfoAboutYouForm
						methods={
							methods as unknown as UseFormReturn<Record<string, unknown>>
						}
						countries={getCountries.data ?? []}
					/>
					<ClientWealthForm
						methods={
							methods as unknown as UseFormReturn<Record<string, unknown>>
						}
						watch={watchClientWealth as IClientWealth[]}
						setIsOpenClientWealthForm={setIsOpenClientWealthForm}
						isOpenClientWealthForm={isOpenClientWealthForm}
					/>

					<Typography variant='h5' sx={{ width: '100%', marginBottom: '24px' }}>
						Adres zamieszkania
					</Typography>
					<Grid container rowSpacing={3} columnSpacing={9}>
						<AddressForm
							methods={methods}
							formControlNames={addressFormControlNames}
							checkboxLabel='Taki sam jak adres zamieszkania'
							checked={checkedAddress}
							handleChecked={handleChecked}
							errors={
								getErrorsAddressForm(methods.formState.errors) as Partial<
									FieldErrorsImpl<Record<string, unknown>>
								>
							}
						/>
					</Grid>
					{!checkedAddress && (
						<Grid
							sx={{ marginBottom: '24px' }}
							container
							rowSpacing={3}
							columnSpacing={9}
						>
							<AddressForm
								methods={methods}
								formControlNames={correspondenceAddressFormControlNames}
								errors={
									getErrorsCorrespondenceAddressForm(
										methods.formState.errors
									) as Partial<FieldErrorsImpl<Record<string, unknown>>>
								}
							/>
						</Grid>
					)}
					<ButtonSubmitTextContainer>
						<Typography
							variant='p3'
							sx={{
								maxWidth: '230px',
								textAlign: 'center',
								marginRight: { xs: '0px', sm: '25px' },
							}}
						>
							W kolejnym kroku zadamy Ci kilka pytań o Twoją firmę.
						</Typography>
					</ButtonSubmitTextContainer>

					<ButtonsContainer>
						<PrevButton variant='outlined' onClick={saveAndBackToPreviousPage}>
							{props?.cancelText ?? 'Cofnij'}
						</PrevButton>
						<ButtonSubmitContainer>
							<CustomButton
								isLoading={sendPersonalData.isPending}
								variant='contained'
								type='submit'
							>
								{props?.submitText ?? 'Dalej'}
							</CustomButton>
						</ButtonSubmitContainer>
					</ButtonsContainer>
				</Form>
			</FormProvider>
		</PersonalDataMainContainer>
	);
}
