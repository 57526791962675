import { styled } from '@mui/system';
import { SxProps } from '@mui/system/styleFunctionSx';
import { mainTheme } from '../../../../../theme/mainTheme';

export const CreditDescriptionBackground = styled('div')({
	width: '100%,',
	background: mainTheme.palette.primary.main,
});

export const CreditDescriptionItem: SxProps = {
	display: 'flex',
	flexDirection: 'column',
	marginBottom: {xs:'60px', md:'0'},
	'&:last-child': {
		marginBottom: 0,
	},
};
export const CreditDescriptionItemsContainer: SxProps = {
	flexDirection:{ xs: 'column', md: 'row'},
};

export const CreditDescriptionItemImg = styled('img')({
	marginBottom: '32px',
	width: '96px',
	height: '96px',
	
});
