import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import React from 'react';
import { Control, FieldErrorsImpl, UseFormReturn } from 'react-hook-form';
import { onlyNumbers } from '../../../../../common/helpers/block-writing-keys-helper';
import {
	phoneNumberMask,
	simpleDigitsMask,
} from '../../../../../common/helpers/mask-helper';
import { emailValidator } from '../../../../../common/validators/emailValidator';
import { nipValidator } from '../../../../../common/validators/nipValidator';
import { phoneNumberValidatorOnlyLength } from '../../../../../common/validators/phoneNumberValidator';
import AddressForm from '../../../../../components/AddressForm/AddressForm';
import NestInput from '../../../../../components/FormItems/NestInput/NestInput';
import NestSelect from '../../../../../components/FormItems/NestSelect/NestSelect';
import {
	getTypeOfTaxOptions,
	TaxationForm,
	TaxationFormDescription,
} from '../../../../../enums/TaxationForm';
import {
	gridFullWidth,
	gridHalfWidth,
} from '../../../../../theme/grid/GridConstants';
import {
	getErrorsLightAddressForm,
	getErrorsLightCorrespondenceAddressForm,
	lightAddressFormControlNames,
	lightCorrespondenceAddressFormControlNames,
} from '../consts/LightCompanyDataConstants';
import {
	ILightAddressFormErrors,
	ILightCorrespondenceAddressFormErrors,
} from '../models/LightCompanyData.models';
interface ILightCompanyDataInfoSection {
	methods: UseFormReturn<Record<string, unknown>>;
}

const LightCompanyDataInfoSection = (
	props: ILightCompanyDataInfoSection
): JSX.Element => {
	const watchTaxationForm = props.methods.watch('taxationForm') as TaxationForm;
	const watchIsCompanyCorrespondenceAddressSameAsCompanyAddress =
		props.methods.watch('isCompanyCorrespondenceAddressSameAsCompanyAddress');

	const handleChecked = (event: React.ChangeEvent<HTMLInputElement>): void => {
		props.methods.setValue(
			'isCompanyCorrespondenceAddressSameAsCompanyAddress',
			event.target.checked
		);
	};

	return (
		<>
			<Grid container rowSpacing={3} columnSpacing={9}>
				<Grid item xs={gridFullWidth} md={gridHalfWidth}>
					<NestInput
						label='NIP'
						control={props.methods.control as unknown as Control}
						name='nip'
						rules={{
							validate: nipValidator,
							required: 'Pole wymagane',
						}}
						mask='### ### ## ##'
						maskFunc={simpleDigitsMask}
						disabled
					/>
				</Grid>
				<Grid item xs={gridFullWidth} md={gridHalfWidth}>
					<NestInput
						label='Liczba pracowników wraz z właścicielem'
						control={props.methods.control as unknown as Control}
						name='numberOfEmployees'
						rules={{
							pattern: {
								value: /^\d+$/,
								message: 'Niepoprawna wartość',
							},
							required: 'Pole wymagane',
							min: { value: 1, message: 'Niepoprawna wartość' },
							max: { value: 100000000, message: 'Niepoprawna wartość' },
						}}
						blockWritingSomeValueFunc={onlyNumbers}
						blockPasteEvent
					/>
				</Grid>
				<Grid item xs={gridFullWidth} md={gridHalfWidth}>
					<NestSelect
						rules={{
							min: { value: 1, message: 'Pole wymagane' },
						}}
						control={props.methods.control as unknown as Control}
						name='taxationForm'
						label='Forma opodatkowania'
						options={[
							[
								TaxationFormDescription(TaxationForm.IncomeAndExpenseBook),
								TaxationForm.IncomeAndExpenseBook,
							],
							[
								TaxationFormDescription(TaxationForm.LumpSum),
								TaxationForm.LumpSum,
							],
							[
								TaxationFormDescription(TaxationForm.TaxCard),
								TaxationForm.TaxCard,
							],
						]}
						errors={
							props.methods?.formState?.errors?.taxationForm as
								| { type: string; message: string }
								| undefined
						}
						placeholderOption={[
							'Wybierz formę opodatkowania',
							TaxationForm.None,
						]}
						zIndex={2}
					/>
				</Grid>
				<Grid item xs={gridFullWidth} md={gridHalfWidth}>
					<NestSelect
						rules={{
							min: { value: 1, message: 'Pole wymagane' },
						}}
						control={props.methods.control as unknown as Control}
						name='declarationType'
						label='Rodzaj deklaracji'
						options={getTypeOfTaxOptions(watchTaxationForm)}
						errors={
							props.methods?.formState?.errors?.declarationType as
								| { type: string; message: string }
								| undefined
						}
						disabled={!watchTaxationForm}
						placeholderOption={['Wybierz rodzaj deklaracji', TaxationForm.None]}
					/>
				</Grid>

				<Grid item xs={gridFullWidth} sx={{ marginTop: '24px' }}>
					<Typography variant='h5'>Dane kontaktowe</Typography>
				</Grid>
				<Grid item xs={gridFullWidth} md={gridHalfWidth}>
					<NestInput
						label='Adres e-mail'
						control={props.methods.control as unknown as Control}
						name='email'
						rules={emailValidator}
						wrapperSx={{ marginBottom: { md: '24px', xs: '0' } }}
					/>
				</Grid>
				<Grid item xs={gridFullWidth} md={gridHalfWidth}>
					<NestInput
						label='Numer telefonu komórkowego'
						control={props.methods.control as unknown as Control}
						rules={phoneNumberValidatorOnlyLength}
						name='phoneNumber'
						wrapperSx={{ marginBottom: '22px' }}
						maskFunc={phoneNumberMask}
						mask=''
						prefix='+48&nbsp;'
					/>
				</Grid>

				<Grid item xs={gridFullWidth}>
					<Typography variant='h5'>Adres firmy</Typography>
				</Grid>
				<AddressForm
					methods={props.methods}
					formControlNames={lightAddressFormControlNames}
					checkboxLabel='Taki sam jak adres firmy'
					checked={
						watchIsCompanyCorrespondenceAddressSameAsCompanyAddress as boolean
					}
					handleChecked={handleChecked}
					errors={
						getErrorsLightAddressForm(
							props.methods?.formState?.errors as ILightAddressFormErrors
						) as Partial<FieldErrorsImpl<Record<string, unknown>>>
					}
				/>
			</Grid>
			{!watchIsCompanyCorrespondenceAddressSameAsCompanyAddress && (
				<Grid
					sx={{ marginBottom: '24px' }}
					container
					rowSpacing={3}
					columnSpacing={9}
				>
					<AddressForm
						methods={props.methods}
						formControlNames={lightCorrespondenceAddressFormControlNames}
						errors={
							getErrorsLightCorrespondenceAddressForm(
								props.methods?.formState
									?.errors as ILightCorrespondenceAddressFormErrors
							) as Partial<FieldErrorsImpl<Record<string, unknown>>>
						}
					/>
				</Grid>
			)}
		</>
	);
};

export default LightCompanyDataInfoSection;
