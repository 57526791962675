import { FileType } from '../../enums/FileType';
import BaseFetch from './BaseFetch';
import { ApiConfig } from './FetchTypes';

const downloadFileKey = 'downloadFile';

function downloadFile(
	fileType: FileType,
	isLandingPage: boolean,
	renameTo: string | undefined
): Promise<void> {
	return BaseFetch({
		url: `File${isLandingPage ? '/LandingPage' : ''}/${FileType[fileType]}${
			renameTo ? '?renameTo=' + renameTo : ''
		}`,
		method: 'GET',
	});
}

export const GetDownloadFileConfig = (
	fileType: FileType,
	isLandingPage = false,
	renameTo: string | undefined = undefined
): ApiConfig<void, never> => {
	return {
		key: `${downloadFileKey}_${fileType}`,
		fn: (): Promise<void> => downloadFile(fileType, isLandingPage, renameTo),
	};
};
