import { useEffect, useState } from 'react';
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';
import { LimitConfig, getLimitDataConfig } from './OvdCalculatorApi';
import { ScrollToTopOnMount } from 'src/common/helpers/scroll-to-top-onmount';
import { mainTheme } from '../../../../theme/mainTheme';
import { formatAmount } from 'src/common/helpers/number-helper';
import { Container } from '../../../../common/styles/Layout.css';
import { HorizontalLine } from './OvdCalculatorPage.css';
import arrowDownUnderscore from '../../../../common/images/ArrowDownUnderscore.svg';
import OvdCalculator from './components/OvdCalculator';
import OvdCalculatorCreditInfo from './components/OvdCalculatorCreditInfo';
import { OvdLimitSimulatorCalculator } from './components/OvdLimitSimulatorCalculator';
import { CalculatedLimit } from '../../enums/ApplicationFileType';
import useFetch from '../../../../common/api/UseFetch';
import CustomButton from 'src/components/CustomButton/CustomButton';
import { saveCalculationConfig } from './OvdCalculatorApi';
import NewTabFileHandler from 'src/components/NewTabFileHandler/NewTabFileHandler';
import { FileType } from 'src/enums/FileType';
import { grayMainColor, secondaryMainColor, textColorPrimary, violetMainColor } from 'src/theme/palette/palette';
import { typography } from 'src/theme/typography/typography';
import OvdCalculatorBottomInfoClient from './components/OvdCalculatorBottomInfoClient';
import OvdCalculatorBottomInfoNOClient from './components/OvdCalculatorBottomInfoNOClient';
import { OperatingSystem, getOperatingSystem } from 'src/common/helpers/get-operating-system-helper';
import { LightOvdSmsContractSigningRoute, LightOvdSummaryPageRoute } from 'src/routing/Routing';
import { useNavigate } from 'react-router-dom';

const defaultCalcConfig: LimitConfig = {
	commission: 0,
	markUp: 0,
	minLimitAmount: 0,
	maxLimitAmount: 0,
	agreementPeriod: 0,
	requiredPercentageOfLimitAmount: 0,
	standbyCommission: 0,
	limitPaymentMinimumDaysNumber: 0,
	limitPaymentMaximumDaysNumber: 0,
	wibor: 0,
	bankAccountNumber: '',
	isNestClient: false,
};

const submitButtonFileStyles = {
	color: secondaryMainColor,
	...typography.h7,
	fontFamily: 'Athletics, sans-serif',
	border: 0,
	padding: 0,
	background: 'unset',
	width: 'unset',
	height: '100%',
};

const openAppStoreAccOperationSystem = (operatingSystem: OperatingSystem): void => {
	switch (operatingSystem) {
		case OperatingSystem.android:
			window.open('https://play.google.com/store/apps/details?id=pl.nestbank.nestbank', '_blank');
			break;
		case OperatingSystem.iOS:
			window.open('https://apps.apple.com/pl/app/nest-bank/id1386204117', '_blank');
			break;
		case OperatingSystem.Unknown:
			window.open(
				'https://wnioski.nestbank.pl/FMBewnioseknew/WizardWfB.aspx?b=1&p=2&s=181&l=nested&bankId=1&profileId=2&WebSite=Partnership&Field10=RBFOVD&FieldString3=0 ',
				'_blank'
			);
			break;
		default:
			break;
	}
};

let isAppChosenToCreateAccount = false;

export type ICalculatedLimit = { [key in CalculatedLimit]: number };

const OvdCalculatorPage = (): JSX.Element => {
	const navigate = useNavigate();
	const [calcConfig, setCalcConfig] = useState(defaultCalcConfig);

	const [calculatedLimit, setCalculatedLimit] = useState<ICalculatedLimit>({
		[CalculatedLimit.CurrencyValue]: 10000,
		[CalculatedLimit.PercentValue]: 10000,
		[CalculatedLimit.DaysValue]: 30,
	});

	const getLimitConfig = useFetch(getLimitDataConfig, true);

	const saveLimitConfig = useFetch(saveCalculationConfig, false, (): void => {
		if (calcConfig.isNestClient) {
			navigate(LightOvdSmsContractSigningRoute.path);
		} else {
			openAppStoreAccOperationSystem(isAppChosenToCreateAccount ? getOperatingSystem() : OperatingSystem.Unknown);
			navigate(LightOvdSummaryPageRoute.path);
		}
	});
	useEffect((): void => {
		if (getLimitConfig.data) {
			setCalcConfig(getLimitConfig.data);
			setCalculatedLimit(
				(currentCurrencyValue): ICalculatedLimit => ({
					...currentCurrencyValue,
					currencyValue: getLimitConfig.data.maxLimitAmount,
				})
			);
		}
	}, [getLimitConfig.data]);

	useEffect((): void => {
		setCalculatedLimit({
			[CalculatedLimit.CurrencyValue]: calculatedLimit.currencyValue,
			[CalculatedLimit.PercentValue]:
				calculatedLimit.percentValue > calculatedLimit.currencyValue ? calculatedLimit.currencyValue : calculatedLimit.percentValue,
			[CalculatedLimit.DaysValue]: calculatedLimit.daysValue,
		});
	}, [calculatedLimit.currencyValue]);

	return (
		<>
			<Container>
				<ScrollToTopOnMount />

				<Typography
					variant='h2'
					sx={{
						color: mainTheme.palette.text.primary,
						marginBottom: { xs: '24px', md: '48px' },
						fontSize: { xs: '28px', md: '34px' },
					}}
				>
					Dopasuj ofertę do swoich potrzeb
				</Typography>
				<Typography
					variant='h8'
					sx={{
						color: mainTheme.palette.text.secondary,
						fontWeight: 600,
						lineHeight: '19px',
					}}
				>
					Po analizie Twojej historii kredytowej możemy zaproponować Ci:
				</Typography>
				<Typography variant='h4' sx={{ marginTop: '8px' }}>
					{formatAmount(calcConfig.maxLimitAmount)}{' '}
					<Typography variant='h7' sx={{ fontFamily: 'Athletics, sans-serif' }}>
						PLN{' '}
						<Typography variant='h7' component='span' sx={{ fontFamily: 'Athletics, sans-serif' }}>
							limitu w koncie
						</Typography>
					</Typography>
				</Typography>

				<HorizontalLine />

				<Typography
					variant='h2'
					sx={{
						color: mainTheme.palette.text.primary,
						marginBottom: { xs: '0px', md: '16px' },
						fontSize: { xs: '28px', md: '34px' },
					}}
				>
					<span>Ustaw kwotę limitu</span>
				</Typography>

				<OvdCalculator
					isLoading={getLimitConfig.isPending || saveLimitConfig.isPending}
					startLimit={calcConfig.maxLimitAmount}
					max={calcConfig.maxLimitAmount}
					min={calcConfig.minLimitAmount}
					unit={[' PLN', ' PLN']}
					step={1000}
					text='Wpisz lub ustaw suwakiem kwotę limitu w koncie'
					setCalculatedLimit={setCalculatedLimit}
					calculatorType={CalculatedLimit.CurrencyValue}
				/>
				<OvdCalculatorCreditInfo
					agreementPeriod={calcConfig.agreementPeriod}
					calculatedLimit={calculatedLimit}
					commission={calcConfig.commission}
					markUp={calcConfig.markUp}
					wibor={calcConfig.wibor}
				/>
				<HorizontalLine sx={{ marginTop: { xs: '24px', md: '33px' }, marginBottom: { xs: '32px', md: '24px' } }} />

				<Typography variant='h2' sx={{ fontSize: { xs: '28px', md: '34px' }, lineHeight: { xs: '33.6px', md: '40px' } }}>
					Sprawdź, ile odsetek zapłacisz w przypadku wykorzystania limitu
				</Typography>

				<OvdCalculator
					isLoading={saveLimitConfig.isPending}
					startLimit={calculatedLimit.percentValue}
					max={100}
					min={0}
					unit={['%', '%']}
					isUnitPercentage={true}
					step={1}
					text='Wpisz lub ustaw suwakiem jaką kwotę z przeznaczonego limitu zamierzasz wykorzystać'
					calculatedLimit={calculatedLimit}
					setCalculatedLimit={setCalculatedLimit}
					calculatorType={CalculatedLimit.PercentValue}
				/>
				<OvdCalculator
					isLoading={saveLimitConfig.isPending}
					startLimit={calculatedLimit.daysValue}
					max={calcConfig.limitPaymentMaximumDaysNumber}
					min={calcConfig.limitPaymentMinimumDaysNumber}
					unit={[' dzień', ' dni']}
					step={1}
					text='Wpisz lub ustaw suwakiem liczbę dni, po upływie których spłacisz wykorzystany limit'
					setCalculatedLimit={setCalculatedLimit}
					calculatorType={CalculatedLimit.DaysValue}
				/>
				<OvdLimitSimulatorCalculator markUp={calcConfig.markUp} wibor={calcConfig.wibor} calculatedLimit={calculatedLimit} />
				<Stack>
					<NewTabFileHandler
						fileName={'Umowa o limit odnawialny - wzór.pdf'}
						fileType={FileType.ContractTemplate}
						buttonStyles={submitButtonFileStyles}
						buttonSubmitText={'Pobierz wzór umowy'}
						buttonContainerMargin={'0px'}
					>
						<Stack
							sx={{
								cursor: 'pointer',
								flexDirection: 'row',
								alignItems: 'center',
							}}
						>
							<img src={arrowDownUnderscore} style={{ marginRight: '9px' }} alt='icon' />
						</Stack>
					</NewTabFileHandler>
				</Stack>

				{calcConfig.isNestClient && (
					<Stack
						sx={{
							alignItems: { md: 'flex-end', xs: 'center' },
							margin: { xs: '94px 0 48px 0', md: '10px 0 41px 0' },
						}}
					>
						<CustomButton
							isLoading={saveLimitConfig.isPending}
							variant='contained'
							onClick={(): void =>
								saveLimitConfig.mutate({
									limitAmount: calculatedLimit.currencyValue,
								})
							}
						>
							Dalej
						</CustomButton>
					</Stack>
				)}
			</Container>

			{!calcConfig.isNestClient && (
				<Stack
					sx={{
						width: '100%',
						backgroundColor: violetMainColor,
						padding: { xs: '0 0 0 0', md: '0 0 0 0' },
						margin: '40px 0 0 0',
					}}
				>
					<Stack
						sx={{
							minHeight: 'inherit',
							flexDirection: { xs: 'column', md: 'row' },
							alignItems: 'center',
							width: { xs: 'unset', md: '833px' },
							margin: { xs: '0 24px', md: 'auto' },
						}}
					>
						<Stack>
							<Typography variant='h4' sx={{ color: textColorPrimary, padding: '32px 0 16px 0' }}>
								W kolejnym kroku otworzysz BIZnest konto, przygotuj dowód osobisty.
							</Typography>
							<Typography variant='p1' sx={{ color: grayMainColor, padding: '0 0 30px 0' }}>
								Założenie BIZnest konta jest niezbędne do korzystania z Limitu w koncie.
							</Typography>
						</Stack>
						<Stack
							sx={{
								alignItems: { md: 'flex-end', xs: 'center' },
								margin: { xs: '18px 0 32px 0', md: '0 0 0 32px' },
							}}
						>
							<CustomButton
								isLoading={!isAppChosenToCreateAccount && saveLimitConfig.isPending}
								variant='contained'
								onClick={(): void => {
									isAppChosenToCreateAccount = false;
									saveLimitConfig.mutate({ limitAmount: calculatedLimit.currencyValue });
								}}
							>
								Załóż konto
							</CustomButton>
							{(getOperatingSystem() === OperatingSystem.android || getOperatingSystem() === OperatingSystem.iOS) && (
								<>
									<Typography variant='p3' sx={{ color: textColorPrimary, padding: '12px 0 0 0', textAlign: 'center' }}>
										Kontynuuj składanie wniosku w przeglądarce
									</Typography>
									<CustomButton
										sx={{ margin: '40px 0 0 0' }}
										isLoading={isAppChosenToCreateAccount && saveLimitConfig.isPending}
										variant='outlined'
										onClick={(): void => {
											isAppChosenToCreateAccount = true;
											saveLimitConfig.mutate({ limitAmount: calculatedLimit.currencyValue });
										}}
									>
										Załóż konto przez aplikację
									</CustomButton>
									<Typography variant='p3' sx={{ color: textColorPrimary, padding: '12px 0 0 0', textAlign: 'center' }}>
										Pobierz aplikację i załóż konto w 5 minut
									</Typography>
								</>
							)}
						</Stack>
					</Stack>
				</Stack>
			)}

			{calcConfig.isNestClient ? <OvdCalculatorBottomInfoClient /> : <OvdCalculatorBottomInfoNOClient />}
		</>
	);
};

export default OvdCalculatorPage;
