import BaseFetch from './BaseFetch';
import { ApiConfig } from './FetchTypes';

export interface CountryDto {
	countryCode: string;
	countryName: string;
}

const getCountriesKey = 'getCountries';

function getCountries(): Promise<CountryDto[]> {
	return BaseFetch({ url: 'Dictionary/Countries', method: 'GET' });
}

export const GetCountriesConfig: ApiConfig<CountryDto[], never> = {
	key: getCountriesKey,
	fn: getCountries,
};
