import Alert from '@mui/material/Alert/Alert';
import Box from '@mui/material/Box';
import { useContext, useEffect, useState } from 'react';
import {
	getSessionStorageObj,
	locationKey,
	removeSessionStorageValue,
	tokenExpirationKey
} from '../../common/sessionStorage/SessionStorageService';
import { SessionContext } from '../../context/SessionContext';
import AlertErrorIcon from '../../common/images/AlertError.svg';
import Fade from '@mui/material/Fade/Fade';
import Typography from '@mui/material/Typography';
import { navigateToRoute } from '../../pages/common/helpers/navigateToRoute';
import { mainTheme } from '../../theme/mainTheme';
import { useNavigate } from 'react-router-dom';
import {
	SessionExpiredRoute,
	LightOvdSessionExpiredRoute, TechnicalErrorRoute
} from '../../routing/Routing';
import useFetch from '../../common/api/UseFetch';
import { refreshSessionConfig } from './SessionExpirationApi';
import { ButtonExpandSession, SnackbarEl } from './SessionExpiration.css';

const minutesBeforeSessionExpiration = 2;

export default function SessionExpiration(): JSX.Element {
	const [countDown, setCountDown] = useState<number | undefined>(undefined);
	const { sessionExpiresAt, updateSessionExpiration, allowGetInToPageWithoutSession } = useContext(SessionContext);
	const navigate = useNavigate();
	const { mutate: refreshSession, isPending } = useFetch(refreshSessionConfig, false, (data): void => {
		updateSessionExpiration(new Date(data.sessionExpiresAt));
	});

	useEffect((): (() => void) => {
		if (!sessionExpiresAt) {
			setCountDown(undefined);

			return (): void => {};
		}

		const interval = setInterval((): void => {
			const leftMs = new Date(sessionExpiresAt).getTime() - new Date().getTime();
			const location = getSessionStorageObj(locationKey) as Location;
			if(location.pathname === TechnicalErrorRoute.path) {
				setCountDown(0);
				clearInterval(interval);
				updateSessionExpiration(undefined);
				removeSessionStorageValue(tokenExpirationKey);
			}
			if (leftMs <= 0) {
				removeSessionStorageValue(tokenExpirationKey);
				setCountDown(undefined);
				clearInterval(interval);
				if (allowGetInToPageWithoutSession) {
					return;
				}
				navigate(navigateToRoute(SessionExpiredRoute.path, LightOvdSessionExpiredRoute.path) as string);
			}
			setCountDown(leftMs);
		}, 1000);

		return (): void => {
			clearInterval(interval);
		};
	}, [sessionExpiresAt]);

	const extendSession = (): void => {
		refreshSession({} as never);
	};

	if (sessionExpiresAt !== undefined && countDown !== undefined && countDown <= minutesBeforeSessionExpiration * 1000 * 60 && countDown > 0) {
		return (
			<Box
				sx={{
					position: 'fixed',
					bottom: 'calc(140px - 24px)',
					right: 'calc(50% + 24px)',
					translate: '50%',
					zIndex: 1400,
				}}
			>
				<SnackbarEl open={true} TransitionComponent={Fade}>
					<Alert
						sx={{ width: '381px', translate: '-50%' }}
						severity='error'
						icon={<img width='16px' height='16px' src={AlertErrorIcon} alt='!' />}
						action={
							<ButtonExpandSession disabled={isPending} onClick={extendSession}>
								<Typography variant='h9' sx={{ color: mainTheme.palette.secondary.main }}>
									Przedłuż sesję
								</Typography>
							</ButtonExpandSession>
						}
					>
						Sesja zakończy się za {Math.floor(countDown / (1000 * 60))} min {Math.floor((countDown % (1000 * 60)) / 1000)} s.
					</Alert>
				</SnackbarEl>
			</Box>
		);
	}

	return <></>;
}
