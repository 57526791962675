import {
	Control,
	Controller,
	ControllerRenderProps,
	FieldValues,
	UseControllerReturn,
} from 'react-hook-form';
import Typography from '@mui/material/Typography';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import { TextFieldProps } from '@mui/material/TextField/TextField';
import { FieldWrapper } from '../FormItems.css';
import selectIcon from '../../../common/images/selectIcon.svg';
import { makeStyles } from '@mui/styles';
import { useEffect } from 'react';

type NestSelectProps = Omit<TextFieldProps, 'name'> & {
	control: Control | undefined;
	name: string;
	rules?: Omit<string | boolean, string>;
	disabled?: boolean;
	options: [string, string | number][];
	placeholderOption?: [string, string | number];
	errors?: { type: string; message: string };
	defaultValue?: string | number;
	setValue?: (name: string, value: string | number) => void;
	zIndex?: number;
	tabIndex?: number;
};

const useStyles = makeStyles(
	(): Record<string, Record<string, string>> => ({
		menu: {
			maxHeight: '300px !important',
			position: 'absolute !important',
		},
	})
);

const NestSelect = (props: NestSelectProps): JSX.Element => {
	const classes = useStyles();

	useEffect((): void => {
		if (props.defaultValue && !props.control?._formValues[props.name]) {
			props.setValue?.(props.name, props.defaultValue);
		}
	}, []);

	const onChange = (
		e: SelectChangeEvent<HTMLSelectElement>,
		field: ControllerRenderProps<FieldValues, string>
	): void => {
		field.onChange(e);
	};

	return (
		<FieldWrapper>
			<Typography
				variant='h9'
				sx={{ color: 'text.secondary', marginBottom: '8px', fontWeight: 600 }}
			>
				{props.label}
			</Typography>

			<Controller
				control={props.control}
				name={props.name}
				rules={props.rules}
				render={({
					field,
				}: UseControllerReturn<FieldValues, string>): JSX.Element => (
					<FormControl fullWidth>
						<Select
							tabIndex={props?.tabIndex}
							id={props.name}
							error={!!props.errors}
							disabled={props.disabled}
							{...field}
							MenuProps={{
								classes: {
									paper: classes.menu,
								},
								disablePortal: true,
							}}
							sx={{
								zIndex: props.zIndex ?? 'unset',
								position: 'relative',
								paddingRight: '5px',
								"&& .Mui-selected": {
									backgroundColor: "unset"
								},
								"&& .MenuItem-root:hover": {
									backgroundColor: "rgba(0, 0, 0, 0.04)"
								},
								"&& .Mui-selected:hover": {
									backgroundColor: "rgba(0, 0, 0, 0.04)"
								}
							}}
							value={(field.value as '' | HTMLSelectElement | undefined) ?? ''}
							onChange={(e: SelectChangeEvent<HTMLSelectElement>): void =>
								onChange(e, field)
							}
							className='select'
							IconComponent={(_props: { className: string }): JSX.Element => {
								const rotate = _props.className.toString().includes('iconOpen');
								return (
									<img
										src={selectIcon}
										style={{
											transform: rotate ? 'rotate(180deg)' : 'none',
											position: 'absolute',
											right: '20px',
											zIndex: '-1',
										}}
									/>
								);
							}}
						>
							{props.placeholderOption && (
								<MenuItem disabled value={props.placeholderOption[1]} sx={{textWrap: 'wrap', whiteSpace: 'unset'}}>
									{props.placeholderOption[0]}
								</MenuItem>
							)}
							{props.options.map(
								(x): JSX.Element => (
									<MenuItem key={`opt_${x[1]}`} value={x[1]} sx={{textWrap: 'wrap', whiteSpace: 'unset'}}>
										{x[0]}
									</MenuItem>
								)
							)}
						</Select>
					</FormControl>
				)}
			/>
			{props.errors && (
				<Typography
					variant='h9'
					sx={{
						color: 'error.main',
						position: 'absolute',
						marginTop: '84px',
					}}
				>
					{props.errors.message}
				</Typography>
			)}
		</FieldWrapper>
	);
};

export default NestSelect;
