import React, { useCallback, useMemo } from 'react';
import { FileWithPath, useDropzone } from 'react-dropzone';
import Typography from '@mui/material/Typography';
import documentIcon from '../../common/images/documentIcon.svg';
import { CSSProperties } from '@mui/styles';
import {
	acceptStyle,
	AttachDocumentInsideBoxTextContainer,
	FileUploadAttachDocument,
	fileUploadBoxStyles,
	FileUploadedContainer,
	FileUploadedMainContainer,
	FileUploadMainContainer,
	focusedStyle,
	rejectStyle,
} from './FileUploader.css';
import {
	ButtonContainer,
	CircleContainer,
} from '../../pages/rbf/rbf-personal-data/components/ClientWealthForm/ClientWealthAddDeleteButtons/ClientWealthAddDeleteButtons.css';
import deleteIcon from '../../common/images/deleteIcon.svg';
import buttonTick from '../../common/images/button_tick.svg';
import plusViolet from '../../common/images/plus-violet.svg';
import { textColorPrimary } from '../../theme/palette/palette';
import { SnackbarType } from '../../context/SnackbarContext';
import useSnackbar from '../../common/snackbar/useSnackbar';
import Loader from '../Loader/Loader';
import { IDocuments } from '../../models/AttachDocumentsModels';

interface ImageUploaderProps {
	documents?: IDocuments[];
	addFiles: (file: FormData) => void;
	deleteDocumentById: (id: string) => void;
	setFiles: React.Dispatch<React.SetStateAction<IDocuments[]>>;
	isUploading: boolean;
	isMoseOverById?: boolean;
}

const NestFileUploader = (props: ImageUploaderProps): JSX.Element => {
	const maxSize = 30 * 1024 * 1024;
	const snackbar = useSnackbar();
	const limitFileNameLength = 35;

	const onDrop = useCallback(
		(acceptedFiles: FileWithPath[]): void => {
			const formData = new FormData();
			let totalSize = 0;
			for (const file of acceptedFiles) {
				formData.append('files', file);
				totalSize += file.size;
			}
			if (totalSize > maxSize) {
				snackbar(
					`Łączna porcja plików przekracza dopuszczalny rozmiar 30MB`,
					SnackbarType.Error
				);
				return;
			}
			props.addFiles(formData);
		},
		[props.documents]
	);

	const {
		getRootProps,
		getInputProps,
		isDragAccept,
		isDragReject,
		isFocused,
		open,
	} = useDropzone({
		noClick: true,
		noKeyboard: true,
		onDrop,
		useFsAccessApi: false,
	});

	const style = useMemo(
		(): unknown => ({
			...fileUploadBoxStyles,
			...(isFocused ? focusedStyle : {}),
			...(isDragAccept ? acceptStyle : {}),
			...(isDragReject ? rejectStyle : {}),
		}),
		[isFocused, isDragAccept, isDragReject]
	);

	const removeFile = (id: string): void => {
		props.deleteDocumentById(id);
	};

	const handleMouseEnterOrLeave = (
		isEnter: boolean,
		index: number,
		id: string
	): void => {
		props.setFiles((prevState: IDocuments[]): IDocuments[] =>
			prevState.map((document: IDocuments, i: number): IDocuments => {
				if (props.isMoseOverById) {
					return document.id === id ? { ...document, isEnter } : document;
				} else {
					return i === index ? { ...document, isEnter } : document;
				}
			})
		);
	};

	const truncateText = (text: string, limit: number): string => {
		if (text.length > limit) {
			return text.substring(0, limit) + '...';
		}
		return text;
	};

	return (
		<FileUploadMainContainer>
			{props.documents?.map(
				(file: IDocuments, index: number): JSX.Element => (
					<FileUploadedMainContainer key={file.id}>
						<FileUploadedContainer
							onMouseLeave={(): void =>
								handleMouseEnterOrLeave(false, index, file.id)
							}
							onMouseEnter={(): void =>
								handleMouseEnterOrLeave(true, index, file.id)
							}
							border={index > 0}
							sx={{ margin: { xs: '0 auto', md: 'unset' } }}
						>
							<div
								style={{
									display: 'flex',
									justifyContent: 'space-between',
									width: '100%',
									alignItems: 'center',
									minHeight: '50px',
								}}
							>
								<Typography sx={{ display: 'flex', overflow: 'hidden' }}>
									<img
										src={documentIcon}
										alt='document'
										style={{ marginRight: '18px' }}
									/>
									<span
										style={{
											maxWidth: '326px',
											overflowWrap: 'break-word',
											overflow: 'hidden',
										}}
									>
										{truncateText(file.fileName, limitFileNameLength)}
									</span>
								</Typography>
								<ButtonContainer
									onClick={(): void => removeFile(file.id)}
									sx={{
										marginTop: 0,
										display: { md: 'none', xs: 'flex' },
										marginLeft: '10px',
									}}
								>
									<CircleContainer>
										<img src={deleteIcon} alt='delete icon' />
									</CircleContainer>
								</ButtonContainer>
								{file.isEnter ? (
									<ButtonContainer
										onClick={(): void => removeFile(file.id)}
										sx={{ marginTop: 0, display: { xs: 'none', md: 'flex' } }}
									>
										<CircleContainer>
											<img src={deleteIcon} alt='delete icon' />
										</CircleContainer>
									</ButtonContainer>
								) : (
									<ButtonContainer
										sx={{ marginTop: 0, display: { xs: 'none', md: 'flex' } }}
									>
										<CircleContainer>
											<img src={buttonTick} alt='button tick' />
										</CircleContainer>
									</ButtonContainer>
								)}
							</div>
						</FileUploadedContainer>
					</FileUploadedMainContainer>
				)
			)}
			<FileUploadAttachDocument
				sx={{ alignItems: { xs: 'center', md: 'flex-start' } }}
			>
				<div {...getRootProps({ style: style as CSSProperties })}>
					<input {...getInputProps()} />
					{props.isUploading ? (
						<Loader />
					) : (
						<AttachDocumentInsideBoxTextContainer onClick={open}>
							<img src={plusViolet} alt='plus'/>
							<div
								style={{
									display: 'flex',
									flexDirection: 'column',
									marginLeft: '7px',
									marginTop: '7px',
								}}
							>
								<span>
									{props.documents?.length
										? 'Załącz kolejny dokument'
										: 'Załącz dokument'}
								</span>
								<Typography
									variant='h10'
									sx={{
										fontWeight: 500,
										marginTop: '10px',
										color: textColorPrimary,
									}}
								>
									lub przeciągnij i upuść tutaj
								</Typography>
							</div>
						</AttachDocumentInsideBoxTextContainer>
					)}
				</div>
			</FileUploadAttachDocument>
		</FileUploadMainContainer>
	);
};

export default NestFileUploader;
