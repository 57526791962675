import { BreadcrumbStep } from '../../../routing/RouteSpec';
import { IBreadcrumbData } from './Breadcrumbs';

export const breadcrumbsData: IBreadcrumbData[] = [
    {step: 1, name: BreadcrumbStep.LETS_GET_TO_KNOW},
    {step: 2, name: BreadcrumbStep.CONFIRM_DATA},
    {step: 3, name: BreadcrumbStep.MATCH_THE_OFFER},
    {step: 4, name: BreadcrumbStep.ASSIGN_AGREEMENT},
    {step: 5, name: BreadcrumbStep.ATTACH_DOCUMENTS},
    {step: 6, name: BreadcrumbStep.COMPLETED}
]
