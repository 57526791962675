export enum FileType {
	ContractTemplate = 1,
	ZusInstruction = 2,
	AvailableBanks = 3,
	ContractDraft = 4,
	ContractRegulations = 5,
	InterestRateTable = 6,
	TableOfFeesAndCommissions = 7,
	InformationClause = 8,
}
