import { AgreementModel, AgreementSectionModel } from '../../components/AgreementCollection/AgreementsModels';

export const groupAgreementsBySection = (agreements: AgreementModel[]): AgreementSectionModel[] => {
	const groupedAgreements = agreements
		.map((item: AgreementModel): string => item.sectionName)
		.filter((item: string, pos: number, self: string[]): boolean => self.indexOf(item) == pos)
		.map((sectionName: string): AgreementSectionModel => {
			return {
				section: sectionName,
				isExpand: false,
				isSelected: false,
				consentGroup: agreements.filter((agreement: AgreementModel): boolean => agreement.sectionName === sectionName),
			};
		});
	groupedAgreements.forEach((section: AgreementSectionModel): void => {
		section.isSelected = section.consentGroup.every((x: AgreementModel): boolean => x.isSelected);
	});
	return groupedAgreements;
};
