import Grid from '@mui/material/Grid';
import ContactData from '../../models/ContactData';
import { WebPage } from '../../routing/Routing';
import { HorizontalLine } from './DataConsistency.css';
import ValueDescriptor from '../../components/ValueDescriptor/ValueDescriptor';
import { gridFullWidth, gridHalfWidth } from '../../theme/grid/GridConstants';
import SectionHeader from './SectionHeader';
import { phoneNumberMask } from '../../common/helpers/mask-helper';

export interface ContactDataInfoProps {
	title: string;
	contactData: ContactData;
	editPage: WebPage;
	setRenderPage: React.Dispatch<React.SetStateAction<WebPage | undefined>>;
}

export default function ContactDataInfo(
	props: Readonly<ContactDataInfoProps>
): JSX.Element {
	return (
		<>
			<Grid
				item
				xs={gridFullWidth}
				sx={{ marginTop: '16px', marginBottom: '-8px' }}
			>
				<SectionHeader
					title={props.title}
					page={props.editPage}
					setRenderPage={props.setRenderPage}
				/>
				<HorizontalLine />
			</Grid>
			<Grid item xs={gridFullWidth} md={gridHalfWidth}>
				<ValueDescriptor title='Adres e-mail' value={props.contactData.email} />
			</Grid>
			<Grid item xs={gridFullWidth} md={gridHalfWidth}>
				<ValueDescriptor
					title='Numer komórkowy'
					value={
						'+48 ' +
						phoneNumberMask('', props.contactData.phoneNumber, '', null)
							.maskedValue
					}
				/>
			</Grid>
		</>
	);
}
