import { TypographyOptions } from '@mui/material/styles/createTypography';

export const typography: TypographyOptions = {
	fontFamily: 'Athletics, sans-serif',
	h1: {
		fontFamily: 'Athletics, sans-serif',
		fontStyle: 'normal',
		fontWeight: 'bold',
		fontSize: '48px',
		lineHeight: '57px',
	},
	h2: {
		fontFamily: 'Athletics, sans-serif',
		fontStyle: 'normal',
		fontWeight: 'bold',
		fontSize: '34px',
		lineHeight: '40px',
	},
	h3: {
		fontFamily: 'Athletics, sans-serif',
		fontStyle: 'normal',
		fontWeight: 'bold',
		fontSize: '28px',
		lineHeight: '33px',
	},
	h4: {
		fontFamily: 'Athletics, sans-serif',
		fontStyle: 'normal',
		fontWeight: 'bold',
		fontSize: '24px',
		lineHeight: '28px',
	},
	h5: {
		fontFamily: 'Athletics, sans-serif',
		fontStyle: 'normal',
		fontWeight: 'bold',
		fontSize: '20px',
		lineHeight: '24px',
	},
	h6: {
		fontFamily: 'Lato, sans-serif',
		fontStyle: 'normal',
		fontWeight: 'bold',
		fontSize: '18px',
		lineHeight: '22px',
	},
	h7: {
		fontFamily: 'Inter, sans-serif',
		fontStyle: 'normal',
		fontWeight: 'bold',
		fontSize: '16px',
		lineHeight: '19px',
	},
	h8: {
		fontFamily: 'Inter, sans-serif',
		fontStyle: 'normal',
		fontWeight: 'bold',
		fontSize: '14px',
		lineHeight: '16px',
	},
	h9: {
		fontFamily: 'Inter, sans-serif',
		fontStyle: 'normal',
		fontWeight: 'bold',
		fontSize: '12px',
		lineHeight: '16px',
	},
	h10: {
		fontFamily: 'Inter, sans-serif',
		fontSize: '10px',
		lineHeight: '14px',
		fontWeight: '500',
	},
	h11: {
		fontFamily: 'Inter, sans-serif',
		fontSize: '28px',
		fontWeight: '600',
		lineHeight: '39.2px',
	},
	p1: {
		fontFamily: 'Inter, sans-serif',
		fontStyle: 'normal',
		fontWeight: '400',
		fontSize: '16px',
		lineHeight: '22px',
	},
	p2: {
		fontFamily: 'Inter, sans-serif',
		fontStyle: 'normal',
		fontWeight: '400',
		fontSize: '14px',
		lineHeight: '19px',
	},
	p3: {
		fontFamily: 'Inter, sans-serif',
		fontStyle: 'normal',
		fontWeight: '400',
		fontSize: '12px',
		lineHeight: '16px',
	},
	p4: {
		fontFamily: 'Athletics, sans-serif',
		fontStyle: 'normal',
		fontWeight: '400',
		fontSize: '16px',
		lineHeight: '19.2px',
	},
	p5: {
		fontFamily: 'Inter, sans-serif',
		fontStyle: 'normal',
		fontWeight: '600',
		fontSize: '12px',
		lineHeight: '16.8px',
	},
};
