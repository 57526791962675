import { createContext, useState } from 'react';

export interface SnackbarModel {
	id: number;
	message: string;
	showTimeInSeconds: number;
	type: SnackbarType;
	isOpen: boolean;
}

export enum SnackbarType {
	Info = 0,
	Success = 1,
	Warning = 2,
	Error = 3,
}

export type pushFuncType = (
	message: string,
	type: SnackbarType,
	showTimeInSeconds?: number,
	width?: Record<string, string>,
) => void;

interface SnackbarContextType {
	messages: SnackbarModel[];
	push: pushFuncType;
	close: (id: number) => void;
	width?: Record<string, string>;
}
export const SnackbarContext = createContext<SnackbarContextType>({
	messages: [],
	push: (_message, _type, _showTimeInSeconds, _width): void => {
		console.error('Not implemented SnackbarContext');
	},
	close: (_id): void => {
		console.error('Not implemented SnackbarContext');
	},
});

interface SnakbarContextProviderProps {
	children: JSX.Element | JSX.Element[];
}

export const SnackbarContextProvider = (
	props: SnakbarContextProviderProps
): JSX.Element => {
	const [messages, setMessages] = useState<SnackbarModel[]>([]);
	const [width, setWidth] = useState<Record<string, string>>({xs: '300px', sm: 'unset'});

	const push = (
		message: string,
		type: SnackbarType,
		showTimeInSeconds?: number,
		width?: Record<string, string>
	): void => {
		if(width){
			setWidth(width)
		}
		setMessages((prevState): SnackbarModel[] => {
			const id =
				prevState.length > 0
					? [...prevState].sort((a, b): number => b.id - a.id)[0].id + 1
					: 1;
			showTimeInSeconds = showTimeInSeconds ?? defaultShowTimeInSeconds;
			setTimeout((): void => {
				close(id);
			}, showTimeInSeconds * 1000);
			return [
				...prevState,
				{
					id: id,
					message: message,
					type: type,
					showTimeInSeconds: showTimeInSeconds,
					isOpen: true,
				},
			];
		});
	};

	const close = (id: number): void => {
		setMessages((prevState): SnackbarModel[] => {
			const newState = [...prevState];
			for (const el of newState) {
				if (el.id === id) {
					el.isOpen = false;
				}
			}
			return newState;
		});
		setTimeout((): void => {
			setMessages((prevState): SnackbarModel[] =>
				prevState.filter((x): boolean => x.id !== id)
			);
		}, 1000);
	};

	const defaultShowTimeInSeconds = 10;

	return (
		<SnackbarContext.Provider
			value={{ messages: messages, push: push, close: close, width: width }}
		>
			{props.children}
		</SnackbarContext.Provider>
	);
};
