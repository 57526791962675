import OnePageRejection from '../../../components/OnePageRejection/OnePageRejection';
import { useNavigate } from 'react-router-dom';
import { ReturnProposalRoute } from '../../../routing/Routing';

export default function ApplicationInProgress(): JSX.Element {

	const navigate = useNavigate();

	return (
		<OnePageRejection
			descriptionText='Niestety nie możesz kontynuować wypełniania wniosku. Złożyłeś już jeden wniosek, który procesujemy. Prosimy o cierpliwość.'
			onClickHandler={(): void => { navigate(ReturnProposalRoute.path) }}
			buttonText='Powrót do wniosku'
			descriptionMaxWidth={557}
		/>
	);
}
