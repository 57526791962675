import BaseFetch from '../../../../common/api/BaseFetch';
import { ApiConfig, ApiVersion } from '../../../../common/api/FetchTypes';

export interface LoanConfig {
	minLoanAmount: number;
	maxLoanAmount: number;
	periods: number[];
	selectedAmount: number;
	selectedPeriod: number;
	firstInstallmentPaymentDate: Date;
}

const getLoanConfigKey = 'getLoanConfig';

const getLoanConfig = (_prop: never): Promise<LoanConfig> => {
	return BaseFetch({ url: 'Offer/LoanConfig', method: 'GET' });
};

export const GetLoanConfig: ApiConfig<LoanConfig, never> = {
	fn: getLoanConfig,
	key: getLoanConfigKey,
};

export interface CalculateLoanQuery {
	value: number;
	period: number;
}

export interface CalculatedLoanInfo {
	nettValue: number;
	installment: number;
	interest: number;
	commission: number;
}

const calculateLoanKey = 'calculateLoanInfo';

const calculateLoanInfo = (
	props: CalculateLoanQuery
): Promise<CalculatedLoanInfo> => {
	return BaseFetch({
		url: 'Offer/CalculateLoanInfo',
		method: 'GET',
		queryParams: { ...props },
	});
};

export const calculateLoanInfoConfig: ApiConfig<
	CalculatedLoanInfo,
	CalculateLoanQuery
> = {
	fn: calculateLoanInfo,
	key: calculateLoanKey,
};

const saveCalculationKey = 'saveCalcualtion';

const saveCalculation = (props: CalculateLoanQuery): Promise<never> => {
	return BaseFetch({
		url: 'Offer/SaveLoanInfo',
		method: 'POST',
		body: props,
		version: ApiVersion.LightCL,
	});
};

export const saveCalculationConfig: ApiConfig<never, CalculateLoanQuery> = {
	fn: saveCalculation,
	key: saveCalculationKey,
};

const saveCalculationDraftKey = 'saveCalcualtionDraft';

const saveCalculationDraft = (props: CalculateLoanQuery): Promise<never> => {
	return BaseFetch({
		url: 'Offer/SaveLoanInfo/Draft',
		method: 'POST',
		body: props,
		version: ApiVersion.LightCL,
	});
};

export const saveCalculationDraftConfig: ApiConfig<never, CalculateLoanQuery> =
	{
		fn: saveCalculationDraft,
		key: saveCalculationDraftKey,
	};
