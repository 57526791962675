import { Dispatch, SetStateAction, useContext, useEffect, useState } from 'react';
import { useForm, UseFormReturn } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import useFetch from '../../../../../common/api/UseFetch';
import { convertAgreementsToAgreementModelToSend } from '../../../../../common/helpers/client-wealth-and-agreements-req-data-helpers';
import { groupAgreementsBySection } from '../../../../../common/helpers/group-agreements-by-section-helper';
import { formatAmountWithDecimalValues } from '../../../../../common/helpers/number-helper';
import { AgreementModel, AgreementSectionModel } from '../../../../../components/AgreementCollection/AgreementsModels';
import { ContextSaveApplicationData } from '../../../../../context/ContextSaveApplicationData';
import { CreateComponentContext, notImplementedFunction } from '../../../../../context/CreateComponentContext';
import { OverrideProps } from '../../../../../routing/RouteSpec';
import {
	LightCompanyDataVerifiedRoute,
	LightOvdCompanyDataVerifiedRoute,
	LightOvdPersonalDataRoute,
	LightPersonalDataRoute, WebPage
} from '../../../../../routing/Routing';
import { IAgreementsStateSlice } from '../../../../../store/slices/agreementsSlice/AgreementSliceModels';
import { saveAgreements } from '../../../../../store/slices/agreementsSlice/AgreementsSlice';
import { RootState } from '../../../../../store/State';
import { navigateToRoute } from '../../../../common/helpers/navigateToRoute';
import { LightCompanyDataDefaultValues } from '../consts/LightCompanyDataConstants';
import {
	LightCompanyDataFormFieldsModel,
	LightCompanyDataModel,
	LightCompanyDataToSendModel,
} from '../models/LightCompanyData.models';
import {
	GetCompanyDataAgreementsConfig,
	GetLightCompanyDataConfig,
	PostDraftLightCompanyDataConfig,
	PostLightCompanyDataConfig
} from '../api/LightCompanyDataApi';

const defaultLightCompanyDataVerifiedContext = {
	onSubmit: notImplementedFunction,
	agreementState: {},
	postLightCompanyDataIsLoading: false,
	setOverrideProps: notImplementedFunction,
	overrideProps: {},
	cancelEditingPage: notImplementedFunction,
};

interface LightCompanyDataVerifiedState {
	methods: UseFormReturn<Record<string, unknown>>;
	onSubmit: (data: LightCompanyDataFormFieldsModel) => void;
	agreementState: IAgreementsStateSlice;
	postLightCompanyDataIsLoading: boolean;
	setOverrideProps: Dispatch<SetStateAction<OverrideProps | undefined>>;
	overrideProps: OverrideProps;
	cancelEditingPage: () => void;
}

export const lightCompanyDataVerifiedContextHandler = (): LightCompanyDataVerifiedState => {
	const dispatch = useDispatch();
	const [overrideProps, setOverrideProps] = useState<OverrideProps | undefined>();

	const agreementState = useSelector((state: RootState): IAgreementsStateSlice => state.agreements);

	const methods = useForm({
		defaultValues: LightCompanyDataDefaultValues,
		mode: 'onSubmit',
		reValidateMode: 'onChange',
	});

	const postLightCompanyData = useFetch(PostLightCompanyDataConfig, false, (): void => {
		if (!overrideProps?.ommitRouting) {
			navigate(navigateToRoute(LightPersonalDataRoute.path, LightOvdPersonalDataRoute.path) as string);
		}
		if (overrideProps?.close) {
			overrideProps.close();
		}
	});

	const _getAgreements = useFetch(
		GetCompanyDataAgreementsConfig(WebPage.LightClCompanyDataVerified),
		true,
		(data): void => {
			const textToReplace: string = '<%przychód>';
			const companyAverageMonthlyIncome: string = methods.watch('companyAverageMonthlyIncome');
			let newDataAgreements: AgreementModel[] = [];
				newDataAgreements = data.map((agreementModel: AgreementModel): AgreementModel => {
					if(agreementModel.abbreviatedContent.includes(textToReplace)){
						const replacedText = agreementModel.abbreviatedContent.replace(textToReplace, !companyAverageMonthlyIncome ? '0' : formatAmountWithDecimalValues(companyAverageMonthlyIncome));
						return {
							...agreementModel,
							contentHtml: replacedText
						}
					}
					return agreementModel;
				})

			dispatch(saveAgreements(groupAgreementsBySection(newDataAgreements)));
		}
	);

	const onSubmit = (data: LightCompanyDataModel): void => {
		if (!agreementState.isValid) {
			return;
		}
		postLightCompanyData.mutate(convertLightCompanyData(data) as never);
	};

	const getLightCompanyData = useFetch(GetLightCompanyDataConfig, true);

	const postDraftLightCompanyData = useFetch(PostDraftLightCompanyDataConfig, false);

	const [isSaveApplicationData] = useContext(ContextSaveApplicationData);

	const navigate = useNavigate();

	useEffect((): void => {
		methods.reset(getLightCompanyData.data);
		if ((getLightCompanyData.data?.isCompanyCorrespondenceAddressSameAsCompanyAddress as null | boolean) === null) {
			methods.setValue('isCompanyCorrespondenceAddressSameAsCompanyAddress', true);
		}
		if (getLightCompanyData.data?.companyAverageMonthlyIncome || getLightCompanyData.data?.companyAverageMonthlyIncome == '0') {
			methods.setValue('companyAverageMonthlyIncome', getLightCompanyData.data.companyAverageMonthlyIncome.toString().replace('.', ','));
		}
	}, [getLightCompanyData.data]);

	useEffect((): void => {
		if (isSaveApplicationData) {
			saveDraftLightCompanyData();
		}
	}, [isSaveApplicationData]);

	useEffect((): void => {
		const textToReplace: string = '<%przychód>';
		const companyAverageMonthlyIncome: string = methods.watch('companyAverageMonthlyIncome');
		let newDataAgreements: AgreementModel[] = [];
		agreementState.agreements.forEach((agreementSection: AgreementSectionModel): void => {
			newDataAgreements = agreementSection.consentGroup.map((agreementModel: AgreementModel): AgreementModel => {
				if(agreementModel.abbreviatedContent.includes(textToReplace)){
					const replacedText = agreementModel.abbreviatedContent.replace(textToReplace, !companyAverageMonthlyIncome ? '0' : companyAverageMonthlyIncome);
					return {
						...agreementModel,
						contentHtml: replacedText
					}
				}
				return agreementModel;
			})
		})

		dispatch(saveAgreements(groupAgreementsBySection(newDataAgreements)));
	}, [methods.watch('companyAverageMonthlyIncome')]);

	const saveDraftLightCompanyData = (): void => {
		methods.clearErrors();
		postDraftLightCompanyData.mutate(convertLightCompanyData({ ...methods.watch() }));
	};

	const cancelEditingPage = (): void => {
		methods.clearErrors();
		overrideProps?.close && overrideProps.close();
		if (!overrideProps?.ommitRouting) {
			navigate(navigateToRoute(LightCompanyDataVerifiedRoute.path, LightOvdCompanyDataVerifiedRoute.path) as string);
		} else {
			return;
		}
	};

	const convertLightCompanyData = (data: LightCompanyDataModel): LightCompanyDataToSendModel => {
		const companyAverageMonthlyIncomeConvertedToSendModel = data.companyAverageMonthlyIncome.replace(',', '.').replace(/\s+/g, '');

		return {
			firstName: data.firstName,
			lastName: data.lastName,
			socialSecurityNumber: data.socialSecurityNumber,
			numberOfEmployees: data.numberOfEmployees,
			taxationForm: data.taxationForm,
			declarationType: data.declarationType,
			email: data.email,
			phoneNumber: data.phoneNumber,
			companyAverageMonthlyIncome: Number(companyAverageMonthlyIncomeConvertedToSendModel),
			isCompanyCorrespondenceAddressSameAsCompanyAddress: data.isCompanyCorrespondenceAddressSameAsCompanyAddress,
			companyAddress: data.companyAddress,
			companyCorrespondenceAddress: data.isCompanyCorrespondenceAddressSameAsCompanyAddress ? data.companyAddress : data.companyCorrespondenceAddress,
			agreements: convertAgreementsToAgreementModelToSend(agreementState.agreements),
		};
	};

	return {
		methods,
		onSubmit: (data: LightCompanyDataFormFieldsModel): void => onSubmit(data),
		agreementState,
		postLightCompanyDataIsLoading: postLightCompanyData.isPending,
		setOverrideProps,
		overrideProps,
		cancelEditingPage,
	} as unknown as LightCompanyDataVerifiedState;
};

export const [LightCompanyDataVerifiedContext, LightCompanyDataVerifiedContextProvider] = CreateComponentContext<LightCompanyDataVerifiedState>(
	defaultLightCompanyDataVerifiedContext as unknown as LightCompanyDataVerifiedState,
	lightCompanyDataVerifiedContextHandler
);
