/* eslint-disable @typescript-eslint/no-misused-promises */
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import React, { useContext, useEffect, useState } from 'react';
import { Control, FieldErrors } from 'react-hook-form';
import { firstNameAndLastNameMaskFunc, phoneNumberMask, simpleDigitsMask } from '../../../../../../../common/helpers/mask-helper';
import { emailValidator } from '../../../../../../../common/validators/emailValidator';
import { phoneNumberValidator } from '../../../../../../../common/validators/phoneNumberValidator';
import CustomButton from '../../../../../../../components/CustomButton/CustomButton';
import NestInput from '../../../../../../../components/FormItems/NestInput/NestInput';
import { IAgreementsStateSlice } from '../../../../../../../store/slices/agreementsSlice/AgreementSliceModels';
import { gridFullWidth, gridHalfWidth } from '../../../../../../../theme/grid/GridConstants';
import { LightLandingPageContext } from '../../../state/LightLandingPageContext';
import { ButtonTextContainer } from './LightContactDataForm.css';
import Stack from '@mui/material/Stack';
import { nipValidator } from '../../../../../../../common/validators/nipValidator';
import { nameValidator, onlyWordsAndNumbers } from 'src/common/validators/polishWordsValidator';
import { mainTheme } from '../../../../../../../theme/mainTheme';
import Loader from 'src/components/Loader/Loader';
import MediaAgreementCollection from 'src/components/AgreementCollection/MediaAgreementCollection';
import { NestFormInfo } from 'src/components/FormItems/NestFormInfo/NestFormInfo';
import { useDispatch } from 'react-redux';
import { checkErrors } from 'src/store/slices/agreementsSlice/AgreementsSlice';
import { ILightContactDataProperties } from '../../LightCreditInfoAndContactDataFormModels';

const LightContactDataForm = (): JSX.Element => {
	const lightLandingPageContext = useContext(LightLandingPageContext);
	const dispatch = useDispatch();
	const [validAgreements, setValidAgreements] = useState<boolean>(true);
	const [clickedSubmit, setClickedSubmit] = useState<boolean>(false);
	const agreementState = lightLandingPageContext.agreementState as IAgreementsStateSlice;

	const checkInputsValidation = (): boolean =>
		Object.keys(lightLandingPageContext.methods ? lightLandingPageContext.methods.formState.errors : {}).length === 0;

	const checkAgreements = (): boolean =>
		agreementState.agreements.every((agreement): boolean | undefined => agreement.isSelected) || !agreementState.isValid;

	const updateAgreementsStatus = (): void => {
		if (checkInputsValidation() && checkAgreements()) {
			dispatch(checkErrors());
			setValidAgreements(agreementState.isValid);
		}
	};

	useEffect((): void => {
		updateAgreementsStatus();
	}, [JSON.stringify(agreementState.agreements), agreementState.isValid, JSON.stringify(lightLandingPageContext.methods?.formState.errors)]);

	const scrollToErrors = (errors: FieldErrors<ILightContactDataProperties>): void => {
		const firstErrorField = Object.keys(errors)[0];

		if (clickedSubmit && firstErrorField) {
			document.querySelector(`[name='${firstErrorField}']`)?.scrollIntoView({ block: 'center', behavior: 'smooth' });
			setClickedSubmit(false);
		}
	};

	useEffect((): void => {
		if (lightLandingPageContext.methods) scrollToErrors(lightLandingPageContext.methods.formState.errors);
	}, [JSON.stringify(lightLandingPageContext.methods?.formState.errors), clickedSubmit]);

	return (
		<>
			<Grid container rowSpacing={3} columnSpacing={9} sx={{justifyContent: {md: 'space-between', xs: 'center'}}}>
				<Grid item xs={gridFullWidth} md={gridHalfWidth} sx={{maxWidth: '452px !important', width: '100%'}}>
					<NestInput
						label='NIP'
						control={lightLandingPageContext.methods?.control as unknown as Control}
						name='nip'
						rules={{
							validate: nipValidator,
							required: 'Pole wymagane',
						}}
						mask='### ### ## ##'
						maskFunc={simpleDigitsMask}
					/>
				</Grid>
				<Grid item xs={gridFullWidth} md={gridHalfWidth} sx={{display: {xs: 'none', md: 'flex'}}}>
				</Grid>
			
				<Grid item xs={gridFullWidth} md={gridHalfWidth} sx={{maxWidth: '452px !important', width: '100%'}}>
					<NestInput
						label='Imię'
						control={lightLandingPageContext.methods?.control as unknown as Control}
						name='firstName'
						rules={{
							minLength: { value: 2, message: 'Niepoprawna wartość' },
							required: 'Pole wymagane',
							pattern: {
								value: nameValidator,
								message: 'Niepoprawna wartość',
							},
						}}
						maskFunc={firstNameAndLastNameMaskFunc}
						useMaskAsValue
						mask='######'
					/>
				</Grid>
				<Grid item xs={gridFullWidth} md={gridHalfWidth} sx={{maxWidth: '452px !important', width: '100%'}}>
					<NestInput
						label='Nazwisko'
						control={lightLandingPageContext.methods?.control as unknown as Control}
						name='lastName'
						rules={{
							minLength: { value: 2, message: 'Niepoprawna wartość' },
							required: 'Pole wymagane',
							pattern: {
								value: nameValidator,
								message: 'Niepoprawna wartość',
							},
						}}
						maskFunc={firstNameAndLastNameMaskFunc}
						useMaskAsValue
						mask='######'
					/>
				</Grid>
				<Grid item xs={gridFullWidth} md={gridHalfWidth} sx={{maxWidth: '452px !important', width: '100%'}}>
					<NestInput
						label='Numer telefonu komórkowego'
						control={lightLandingPageContext.methods?.control as unknown as Control}
						rules={phoneNumberValidator}
						name='phoneNumber'
						maskFunc={phoneNumberMask}
						mask=''
						prefix='+48&nbsp;'
					/>
				</Grid>
				<Grid item xs={gridFullWidth} md={gridHalfWidth} sx={{maxWidth: '452px !important', width: '100%'}}>
					<NestInput
						label='Adres e-mail'
						control={lightLandingPageContext.methods?.control as unknown as Control}
						name='email'
						rules={emailValidator}
					/>
				</Grid>

			{lightLandingPageContext.initialData?.enableBrokerIdField && (
				<Grid item xs={gridFullWidth} md={gridHalfWidth} sx={{maxWidth: '452px !important', width: '100%'}}>
					<NestInput
						label='Kod polecenia (jeśli posiadasz)'
						control={lightLandingPageContext.methods?.control as unknown as Control}
						name='brokerId'
						rules={{
							minLength: { value: 2, message: 'Niepoprawna wartość' },
							maxLength: { value: 50, message: 'Niepoprawna wartość' },
							pattern: {
								value: onlyWordsAndNumbers,
								message: 'Niepoprawna wartość',
							},
						}}
						wrapperSx={{marginBottom: '24px'}}
					/>
				</Grid>
			)}
			</Grid>
			<Stack
				sx={{
					width: '100%',
					margin: '42px 0 0 0',
				}}
			>
				{Object.keys((lightLandingPageContext.agreementState as IAgreementsStateSlice)?.agreements)?.length > 0 && (
					<>
						<MediaAgreementCollection />
						<Stack
							sx={{
								marginBottom: '32px',
								maxWidth: { xs: '100%', md: '70%' },
							}}
						>
							Zgoda ta może być odwołana w każdej chwili bez podania przyczyny, bez wpływu na zgodność z prawem działań, które wykonano na podstawie
							zgody przed jej odwołaniem.
						</Stack>
					</>
				)}
				{lightLandingPageContext.getAgreementsIsLoading && <Loader />}
			</Stack>
			<Typography
				variant='p1'
				sx={{
					maxWidth: '516px',
					marginBottom: '48px',
					fontWeight: '400',
					lineHeight: '140%',
				}}
			>
				Administratorem danych osobowych jest Nest Bank S.A. z siedzibą w 02-675 Warszawa ul. Wołoska 24. Dane będą przetwarzane m.in. w celach
				zawarcia umowy oraz celach marketingowych w tym w celu przedstawienia oferty.
				<Typography
					onClick={lightLandingPageContext.handleClickShowMore}
					sx={{
						display: 'block',
						color: mainTheme.palette.secondary.main,
						fontWeight: '700',
						cursor: 'pointer',
						fontFamily: 'Athletics, sans-serif',
					}}
					variant='p2'
				>
					Zobacz więcej
				</Typography>
			</Typography>

			<ButtonTextContainer
				sx={{
					alignItems: { md: 'flex-end', xs: 'center' },
					margin: { xs: '0 0 46px 0', md: '0 0 80px 0' },
				}}
			>
				<Stack
					sx={{
						flexDirection: 'column',
					}}
				>
					{(!checkInputsValidation() || !validAgreements) && <NestFormInfo />}
					<CustomButton
						variant='contained'
						type='submit'
						isLoading={lightLandingPageContext.createApplicationIsLoading}
						onClick={(): void => setClickedSubmit(true)}
					>
						Złóż wniosek
					</CustomButton>
				</Stack>
			</ButtonTextContainer>
		</>
	);
};

export default LightContactDataForm;
