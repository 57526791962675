import OnePageRejection from 'src/components/OnePageRejection/OnePageRejection';

const LightRejection = (): JSX.Element => {
	return (
		<OnePageRejection
			descriptionText='Przekroczono dopuszczalną liczbę nieudanych prób podpisania umowy.'
			descriptionMaxWidth={616}
			buttonText='Zakończ'
			onClickHandler={(): void => {
				window.location.href = 'https://nestbank.pl';
			}}
		/>
	);
};

export default LightRejection;
