import { useEffect } from 'react';
import { useForm, UseFormReturn } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { Location, useNavigate } from 'react-router-dom';
import { GetDownloadFileConfig } from '../../../../../common/api/FileApi';
import useFetch from '../../../../../common/api/UseFetch';
import { convertMediaAgreementsToAgreementModelToSend } from '../../../../../common/helpers/client-wealth-and-agreements-req-data-helpers';
import { groupAgreementsBySection } from '../../../../../common/helpers/group-agreements-by-section-helper';
import {
	applicationIdKey,
	applicationNumberKey,
	authTokenKey,
	getSessionStorageObj,
	locationKey,
	marketingPartnerIdKey,
	setSessionStorageValue,
	userIdKey,
} from '../../../../../common/sessionStorage/SessionStorageService';
import { CreateComponentContext, notImplementedFunction } from '../../../../../context/CreateComponentContext';
import { FileType } from '../../../../../enums/FileType';
import { ApplicationInProgressRoute, LightOvdCompanyDataRoute, RejectionReasonRoute, TechnicalErrorRoute } from '../../../../../routing/Routing';
import { IAgreementsStateSlice } from '../../../../../store/slices/agreementsSlice/AgreementSliceModels';
import { saveAgreements } from '../../../../../store/slices/agreementsSlice/AgreementsSlice';
import { RootState } from '../../../../../store/State';
import { ILightContactDataProperties } from '../../../cl/cl-landing-page/light-credit-info-and-contact-data-form/LightCreditInfoAndContactDataFormModels';
import { InitialLightData } from '../../../cl/cl-new-application/api/LightNewApplicationApi';
import { defaultValues } from '../light-ovd-credit-info-and-contact-data-form/components/LightOvdContactDataForm/LightOvdContactDataFormConstants';
import { ILightOvdContactDataProperties } from '../light-ovd-credit-info-and-contact-data-form/LightOvdCreditInfoAndContactDataFormModels';
import { GetAgreement, LightOvdCreateApplicationConfig } from '../LightOvdLandingPageApi';

const defaultLightOvdLandingPageContext: LightOvdLandingPageState = {
	handleClickShowMore: notImplementedFunction,
	agreementState: [],
	createApplicationIsLoading: false,
	handleSubmit: notImplementedFunction,
	initialData: undefined,
	getAgreementsIsLoading: false,
};

interface LightOvdLandingPageState {
	handleClickShowMore: () => void;
	agreementState: IAgreementsStateSlice | [];
	createApplicationIsLoading: boolean;
	methods?: UseFormReturn<ILightOvdContactDataProperties> | undefined;
	handleSubmit: (data: ILightContactDataProperties) => void;
	initialData: InitialLightData | undefined;
	getAgreementsIsLoading: boolean;
}

const lightOvdLandingPageContextHandler = (): LightOvdLandingPageState => {
	const dispatch = useDispatch();
	const initialData = getSessionStorageObj<Location>(locationKey)?.state as InitialLightData | undefined;

	const navigate = useNavigate();

	const methods = useForm<ILightOvdContactDataProperties>({
		mode: 'all',
		defaultValues,
	});

	const agreementState = useSelector((state: RootState): IAgreementsStateSlice => state.agreements);

	const getAgreements = useFetch(GetAgreement, false, (result): void => {
		dispatch(saveAgreements(groupAgreementsBySection(result)));
	});

	const getInformationClauseMutation = useFetch(GetDownloadFileConfig(FileType.InformationClause, true), false);

	const createApplication = useFetch(
		LightOvdCreateApplicationConfig,
		false,
		(result): void => {
			if (!result.canProceed) {
				navigate(ApplicationInProgressRoute.path);
				return;
			}
			setSessionStorageValue(authTokenKey, result.token);
			setSessionStorageValue(applicationIdKey, result.applicationId);
			setSessionStorageValue(applicationNumberKey, result.applicationNumber);
			setSessionStorageValue(marketingPartnerIdKey, result.marketingPartnerId);
			setSessionStorageValue(userIdKey, result.userId);
			if (result.isRejected) {
				navigate(RejectionReasonRoute.path);
				return;
			}

			navigate(LightOvdCompanyDataRoute.path);
		},
		undefined,
		undefined,
		(): void => {
			navigate(TechnicalErrorRoute.path, {
				state: { clientId: initialData?.processId },
			});
		},
		false
	);

	useEffect((): void => {
		// eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
		if (initialData?.processId) {
			getAgreements.mutate(initialData.processId);
		}
	}, [initialData?.processId]);
	const handleSubmit = (data: ILightOvdContactDataProperties): void => {
		if (initialData) {
			createApplication.mutate({
				magicWords: data.magicWords,
				agreements: convertMediaAgreementsToAgreementModelToSend(agreementState.agreements),
				process: initialData.process,
				processId: initialData.processId,
				nip: data.nip,
				email: data.email,
				firstName: data.firstName,
				lastName: data.lastName,
				phoneNumber: data.phoneNumber,
				brokerId: data.brokerId,
				query: initialData.query,
			});
		}
	};

	const handleClickShowMore = (): void => {
		getInformationClauseMutation.mutate({} as never);
	};

	return {
		handleClickShowMore: (): void => handleClickShowMore(),
		handleSubmit: (data: ILightContactDataProperties): void => handleSubmit(data),
		agreementState,
		createApplicationIsLoading: createApplication.isPending,
		methods: methods,
		initialData,
		getAgreementsIsLoading: getAgreements.isPending,
	};
};

export const [LightOvdLandingPageContext, LightOvdLandingPageContextProvider] = CreateComponentContext<LightOvdLandingPageState>(
	defaultLightOvdLandingPageContext,
	lightOvdLandingPageContextHandler
);
