import React from 'react';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import { mainTheme } from 'src/theme/mainTheme';
import monthlyInterestNoCapitalImg from 'src/common/images/monthly_interest_no_capital.svg';
import easyRulesImg from 'src/common/images/easy_rules.svg';
import easyRepaymentImg from 'src/common/images/easy_repayment.svg';

const OvdCalculatorBottomInfoClient = (): JSX.Element => {
	const getTile = (img: string, title: string, minWidth: string): JSX.Element => {
		return (
			<Box
				sx={{
					minWidth: { xs: 'auto', md: minWidth },
					marginBottom: { xs: '62px ', md: '0' },
				}}
			>
				<img src={img} />
				<Typography
					variant='h4'
					sx={{
						color: mainTheme.palette.text.primary,
						marginTop: { xs: '35px', md: '32px' },
					}}
				>
					{title}
				</Typography>
			</Box>
		);
	};

	return (
		<Stack
			sx={{
				width: '100%',
				backgroundColor: '#F7FBFF',
				padding: { xs: '60px 0 0 0', md: '60px 0 70px 0' },
			}}
		>
			<Stack
				sx={{
					minHeight: 'inherit',
					flexDirection: 'column',
					width: { xs: 'unset', md: '833px' },
					margin: { xs: '0 24px', md: 'auto' },
				}}
			>
				<Typography variant='h3' sx={{ color: mainTheme.palette.text.primary, marginBottom: '60px' }}>
					Dodatkowe pieniądze na Twoim koncie.
					<br />
					Nie korzystasz, <span style={{ color: mainTheme.palette.secondary.main }}>nie płacisz.</span>
				</Typography>
				<Stack
					sx={{
						justifyContent: 'space-between',
						flexDirection: { xs: 'column', md: 'row' },
					}}
				>
					{getTile(easyRepaymentImg, 'Wygodny sposób spłaty - każdy wpływ na rachunek automatycznie zmniejsza zadłużenie', '269px')}
					{getTile(monthlyInterestNoCapitalImg, 'Nie musisz co miesiąc spłacać kapitału. Miesięcznie płatne są tylko odsetki', '229px')}
					{getTile(easyRulesImg, 'Proste zasady utrzymania limitu', '189px')}
				</Stack>
			</Stack>
		</Stack>
	);
};

export default OvdCalculatorBottomInfoClient;
