import AppBar from '@mui/material/AppBar';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import nestLogo from '../../common/images/NestPurpleLogo.svg';
import { LogoFooter } from '../Header/Header.css';

const Footer = (): JSX.Element => {
	return (
		<AppBar
			position='static'
			sx={{ backgroundColor: 'text.primary', height: '115px' }}
		>
			<Grid
				container
				alignItems='center'
				direction='row'
				height='100%'
				justifyContent='space-between'
				sx={{
					height: { sm: '75px', xs: '110px' },
					width: '100%',
					maxWidth: '1200px',
					margin: 'auto',
					background: 'inherit',
					position: 'relative',
					justifyContent: 'space-between',
					padding: { lg: '0 0', xs: '0 24px' },
				}}
			>
				<Grid item>
					<LogoFooter sx={{width: {xs: '63px', md: '109px'}, height: {xs: '24px', md: '43px'}}} src={nestLogo} alt='Nest Bank Logo' />
				</Grid>
				<Grid
					item
					sx={{
						flexDirection: { xs: 'column', md: 'row' },
						justifyContent: { xs: 'center', md: 'flex-start' },
						display: 'flex',
						textAlign: { xs: 'right', md: 'left' },
					}}
				>
					<Typography variant='h9' sx={{ color: '#FFF' }}>
						Potrzebujesz pomocy?
					</Typography>
					<Typography
						variant='h9'
						sx={{
							color: 'primary.main',
							margin: { md: '0 40px', xs: '12px 0' },
							fontFamily: 'Athletics, sans-serif',
						}}
					>
						22 438 46 64
					</Typography>
					<Typography variant='h9' sx={{ color: '#FFF' }}>
						Pn. - Pt. 9:00 - 17:00
					</Typography>
				</Grid>
			</Grid>
		</AppBar>
	);
};

export default Footer;
