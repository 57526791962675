import { configureStore } from '@reduxjs/toolkit';
import { agreementSlice } from './slices/agreementsSlice/AgreementsSlice';
import { creditInfoDataSlice } from './slices/creditInfoDataSlice/CreditInfoDataSlice';
import { leadToLightClDataSlice } from './slices/leadToLightClDataSlice/LeadToLightClDataSlice';

export const store = configureStore({
	reducer: {
		agreements: agreementSlice.reducer,
		creditInfoData: creditInfoDataSlice.reducer,
		personalData: leadToLightClDataSlice.reducer
	},
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
