import { SxProps } from '@mui/material';
import Button from '@mui/material/Button';
import React from 'react';
import { FileType } from '../../enums/FileType';

interface NewTabFileHandlerProps {
	fileType: FileType;
	fileName: string;
	buttonStyles: SxProps;
	children?: React.ReactNode;
	buttonSubmitText: string;
	buttonContainerMargin?: string;
}

const NewTabFileHandler = (props: NewTabFileHandlerProps): JSX.Element => {
	return (
		<form action={`${window.CONFIG.API_ROOT}/File/${props.fileType}`} method='post' target='_blank'>
			<input type={'hidden'} value={sessionStorage.getItem('auth-token') ?? ''} name='token' />
			<input type={'hidden'} value={sessionStorage.getItem('application-id') ?? ''} name='applicationId' />
			<input type={'hidden'} value={props.fileName} name='renameTo' />
			<div
				style={{
					display: 'flex',
					height: '100%',
					alignItems: 'center',
					marginTop: props.buttonContainerMargin ?? '16px',
				}}
			>
				{props.children}
				<Button type='submit' sx={{ ...props.buttonStyles, width: 'unset' }}>
					{props.buttonSubmitText}
				</Button>
			</div>
		</form>
	);
};

export default NewTabFileHandler;
