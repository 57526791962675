import {
	CreditGoodToKnowBackground,
	CreditGoodToKnowContainer,
	CreditGoodToKnowSection,
	HexListItem,
	HexListItemNumber,
	DocumentDownload,
	HexListItemNumberContainer,
	HexListDescContainer,
} from './CreditGoodToKnow.css';
import Typography from '@mui/material/Typography';
import { mainTheme } from '../../../../theme/mainTheme';
import hexIcon from '../../../../common/images/hexIcon.svg';
import { WiderContainer } from '../../../../common/styles/Layout.css';
import useFetch from '../../../../common/api/UseFetch';
import { GetDownloadFileConfig } from '../../../../common/api/FileApi';
import { FileType } from '../../../../enums/FileType';
import { requiredDocumentsCollapseContent } from '../credit-info-section/CreditInfoSectionConstats';
import NestCollapse from '../../../../components/NestCollapse/NestCollapse';

export default function CreditGoodToKnow(): JSX.Element {
	const socialInsuranceDocumentDownload = useFetch(
		GetDownloadFileConfig(FileType.ZusInstruction, true),
		false
	);
	const getAvailableBankList = useFetch(
		GetDownloadFileConfig(FileType.AvailableBanks, true),
		false
	);

	return (
		<CreditGoodToKnowBackground>
			<WiderContainer>
				<CreditGoodToKnowContainer>
					<CreditGoodToKnowSection>
						<Typography
							sx={{
								color: mainTheme.palette.denary.main,
								maxWidth: '298px',
								marginBottom: '26px',
							}}
							variant='h4'
						>
							Jakich dokumentów potrzebujesz?
						</Typography>
						<ul style={{ color: '#FFFFFF', marginLeft: '-18px' }}>
							<li style={{ marginBottom: '16px' }}>
								<Typography variant={'p1'}>Polski dowód osobisty</Typography>
							</li>
							<li>
								<Typography variant={'p1'}>
									Zaświadczenie o niezaleganiu w ZUS lub potwierdzenie danych w
									ZUS. Jeden z tych dokumentów jest potrzebny do wypłacenia
									środków. Załączysz je wypełniając wniosek.{' '}
									<DocumentDownload
										style={{ color: mainTheme.palette.primary.main }}
										onClick={(): void => {
											if (!socialInsuranceDocumentDownload.isPending) {
												socialInsuranceDocumentDownload.mutate({} as never);
											}
										}}
									>
										Sprawdź, jak uzyskać potwierdzenie danych w ZUS w kilka
										minut.
									</DocumentDownload>
								</Typography>
								<Typography
									component={'p'}
									variant={'p1'}
									sx={{ marginTop: '16px' }}
								>
									Dokument musi być ważny 30 dni od daty jego wystawienia, chyba
									że z jego treści wynika inaczej.
								</Typography>
								<NestCollapse
									title={'Rozwiń'}
									content={requiredDocumentsCollapseContent}
									sxTitle={{ marginTop: '14px' }}
									sxContent={{ marginTop: { md: '-40px' } }}
								/>
							</li>
						</ul>
					</CreditGoodToKnowSection>
					<CreditGoodToKnowSection>
						<Typography
							sx={{
								color: mainTheme.palette.denary.main,
								maxWidth: '298px',
								marginBottom: '26px',
							}}
							variant='h4'
						>
							Kredyt na klik w paru krokach:
						</Typography>
						<HexListItem>
							<HexListItemNumberContainer>
								<HexListItemNumber>1</HexListItemNumber>
								<img src={hexIcon} alt='icon' />
							</HexListItemNumberContainer>
							<HexListDescContainer>
								<Typography color='#FFFFFF' variant='p2'>
									Wypełnij wniosek <strong>w kilka minut</strong>
								</Typography>
							</HexListDescContainer>
						</HexListItem>
						<HexListItem>
							<HexListItemNumberContainer>
								<HexListItemNumber>2</HexListItemNumber>
								<img src={hexIcon} alt='icon' />
							</HexListItemNumberContainer>
							<HexListDescContainer>
								<Typography
									component={'div'}
									color='#FFFFFF'
									variant='p2'
									sx={{
										marginTop: {
											xs: '8px',
											sm: '0',
										},
									}}
								>
									Zapoznaj się z ofertą i{' '}
									<strong>dopasuj ją do swoich potrzeb</strong>{' '}
								</Typography>
							</HexListDescContainer>
						</HexListItem>
						<HexListItem>
							<HexListItemNumberContainer>
								<HexListItemNumber>3</HexListItemNumber>
								<img src={hexIcon} alt='icon' />
							</HexListItemNumberContainer>
							<HexListDescContainer>
								<Typography
									color='#FFFFFF'
									variant='p2'
									sx={{
										marginTop: {
											xs: '8px',
											sm: '0',
										},
									}}
								>
									Potwierdź swoją tożsamość za{' '}
									<strong>pomocą konta osobistego</strong>. Listę obsługiwanych
									banków{' '}
									<span
										style={{
											color: mainTheme.palette.primary.main,
											cursor: 'pointer',
										}}
										onClick={(): void => {
											if (!getAvailableBankList.isPending) {
												getAvailableBankList.mutate({} as never);
											}
										}}
									>
										znajdziesz tutaj
									</span>
								</Typography>
							</HexListDescContainer>
						</HexListItem>
						<HexListItem>
							<HexListItemNumberContainer>
								<HexListItemNumber>4</HexListItemNumber>
								<img src={hexIcon} alt='icon' />
							</HexListItemNumberContainer>
							<Typography
								component={'div'}
								color='#FFFFFF'
								variant='p2'
								sx={{
									marginTop: {
										xs: '8px',
										sm: '0',
									},
								}}
							>
								Zapoznaj się z treścią umowy i podpisz ją. Dołącz zaświadczenie
								o niezaleganiu w ZUS lub potwierdzenie danych z ZUS
							</Typography>
						</HexListItem>
						<HexListItem>
							<HexListItemNumberContainer>
								<HexListItemNumber>5</HexListItemNumber>
								<img src={hexIcon} alt='icon' />
							</HexListItemNumberContainer>
							<HexListDescContainer>
								<Typography
									component={'div'}
									color='#FFFFFF'
									variant='p2'
									sx={{
										marginTop: {
											xs: '8px',
											sm: '0',
										},
									}}
								>
									Środki otrzymasz na konto nawet do 24h od podpisania umowy
								</Typography>
							</HexListDescContainer>
						</HexListItem>
					</CreditGoodToKnowSection>
				</CreditGoodToKnowContainer>
			</WiderContainer>
		</CreditGoodToKnowBackground>
	);
}
