import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { Control, FormProvider, useForm } from 'react-hook-form';
import { phoneNumberMask, simpleDigitsMask } from '../../../common/helpers/mask-helper';
import { ScrollToTopOnMount } from '../../../common/helpers/scroll-to-top-onmount';
import { nipValidator } from '../../../common/validators/nipValidator';
import { nameValidator } from '../../../common/validators/polishWordsValidator';
import Loader from '../../../components/Loader/Loader';
import { secondaryMainColor } from '../../../theme/palette/palette';
import NestInput from '../../../components/FormItems/NestInput/NestInput';
import { SubmitButtonWrapper } from './LeadForm.css';
import { defaultValues, ILeadForm } from './LeadFormConstants';
import CustomButton from 'src/components/CustomButton/CustomButton';

const phoneNumberField = 'phoneNumber';
// const emailField = 'email';
const LeadIndexForm = (): JSX.Element => {
	const methods = useForm<ILeadForm>({
		mode: 'all',
		defaultValues,
	});

	return (
		<Stack
			sx={{
				width: { xs: 'unset', md: '50%' },
				padding: { xs: '48px 16px 0 16px', md: '32px 24px 0 24px' },
				background: '#F8F9FF',
				alignItems: { xs: 'center', md: 'start' },
			}}
		>
			<Stack sx={{ maxWidth: '750px' }}>
				<Typography sx={{ wordWrap: 'break-word' }}>
					<Typography component='span' variant='h4' sx={{ padding: '0 4px 0 0  ' }}>
						Wypełnij poniższy formularz,
					</Typography>
					<Typography component='span' variant='h4' sx={{ color: '#FC7D5D', padding: '0 4px 0 0', display: 'inline-block', flexWrap: 'nowrap' }}>
						a my
					</Typography>
					<Typography component='span' variant='h4' sx={{ color: '#FC7D5D', padding: '0 4px 0 0' }}>
						oddzwonimy ze szczegółami
					</Typography>
				</Typography>

				<FormProvider {...methods}>
					<form>
						<Stack>
							<ScrollToTopOnMount />

							<Stack sx={{ flexDirection: 'column', gap: '16px', margin: '32px 0 48px 0' }}>
								<Stack sx={{ flexDirection: { xs: 'column', sm: 'row' }, gap: '16px' }}>
									<NestInput
										label='Imię'
										control={methods.control as unknown as Control}
										name='firstName'
										wrapperSx={{ width: '100%' }}
										rules={{
											minLength: {
												value: 2,
												message: 'Niepoprawna wartość',
											},
											required: 'Pole wymagane',
											pattern: {
												value: nameValidator,
												message: 'Niepoprawna wartość',
											},
										}}
									/>
									<NestInput
										label='Nazwisko'
										control={methods.control as unknown as Control}
										name='lastName'
										wrapperSx={{ width: '100%' }}
										rules={{
											minLength: {
												value: 2,
												message: 'Niepoprawna wartość',
											},
											required: 'Pole wymagane',
											pattern: {
												value: nameValidator,
												message: 'Niepoprawna wartość',
											},
										}}
									/>
								</Stack>

								<Stack sx={{ flexDirection: { xs: 'column', sm: 'row' }, gap: '16px' }}>
									<NestInput
										label='NIP'
										control={methods.control as unknown as Control}
										name='nip'
										wrapperSx={{ width: '100%' }}
										maskFunc={simpleDigitsMask}
										mask='### ### ## ##'
										rules={{
											validate: nipValidator,
											required: 'Pole wymagane',
										}}
									/>
									<NestInput
										label='Numer telefonu komórkowego'
										control={methods.control as unknown as Control}
										name={phoneNumberField}
										wrapperSx={{ width: '100%' }}
										maskFunc={phoneNumberMask}
										mask=''
										prefix='+48&nbsp;'
									/>
								</Stack>
							</Stack>
							<Loader/>
							<Typography variant='p2' sx={{ margin: '0 0 24px 0', textAlign: 'justify' }}>
								Zgoda ta może być odwołana w każdej chwili bez podania przyczyny, bez wpływu na zgodność z prawem działań, które wykonano na podstawie
								zgody przed jej odwołaniem.
							</Typography>
							<Typography variant='p2' sx={{ marginBottom: '8px', textAlign: 'justify' }}>
								Administratorem danych osobowych jest Nest Bank S.A. z siedzibą w 02-675 Warszawa ul. Wołoska 24. Dane będą przetwarzane m.in. w
								celach marketingowych w tym celu przedstawienia oferty.
							</Typography>
							<Typography
								variant='h8'
								sx={{ color: secondaryMainColor, cursor: 'pointer', fontFamily: 'Athletics, sans-serif' }}
							>
								Zobacz więcej
							</Typography>
						</Stack>
						<SubmitButtonWrapper sx={{ justifyContent: { md: 'flex-end', xs: 'center' } }}>
							<CustomButton variant='contained' type='submit' isLoading={true}>
								Zamawiam kontakt
							</CustomButton>
						</SubmitButtonWrapper>
					</form>
				</FormProvider>
			</Stack>
		</Stack>
	);
};

export default LeadIndexForm;
