import { useEffect } from 'react'


interface IButtonGroupCarousel {
    carouselColor: string;
    startItemIndex: number;
    next: () => void;
    previous: () => void;
    goToSlide: (index: number, skipCallbacks?: boolean) => void;
    carouselState: {
        totalItems: number;
        slidesToShow: number;
        currentSlide: number;
    };
}

const ButtonGroupCarousel = ({ carouselColor, startItemIndex, next, previous, goToSlide, carouselState }: IButtonGroupCarousel): JSX.Element => {
    useEffect((): void => {
        if (carouselState.totalItems > carouselState.slidesToShow && startItemIndex >= carouselState.slidesToShow) {
            goToSlide(startItemIndex + 1 - carouselState.slidesToShow, true)
        }
    }, [carouselState])

    return (
        <div
            style={{
                position: 'absolute',
                width: '100%',
                height: '0',
                left: '0',
                top: '0',
                display: 'flex',
                justifyContent: 'space-between',
            }}
        >
            <figure
                style={{
                    opacity: carouselState.currentSlide === 0 ? '0' : '1',
                    width: '16px',
                    height: '45px',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    margin: '0',
                    cursor: 'pointer',
                }}
                onClick={(): void => previous()}
            >
                <svg width="8" height="13" viewBox="0 0 8 13" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M6.2887 11.3333L1.37074 6.17714L6.33332 1.05881" stroke={carouselColor} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                </svg>
            </figure>
            <figure
                style={{
                    opacity: carouselState.totalItems - carouselState.slidesToShow <= carouselState.currentSlide ? '0' : '1',
                    width: '16px',
                    height: '45px',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    margin: '0',
                    cursor: 'pointer'
                }}
                onClick={(): void => next()}
            >
                <svg width="8" height="13" viewBox="0 0 8 13" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M1.71124 1.66675L6.6292 6.82286L1.66662 11.9412" stroke={carouselColor} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                </svg>
            </figure>
        </div>
    );
};

export default ButtonGroupCarousel