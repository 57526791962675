import { useEffect } from 'react';
import { useMutation, UseMutationResult } from '@tanstack/react-query';
import { useNavigate } from 'react-router-dom';
import { SnackbarType } from '../../context/SnackbarContext';
import { SessionExpiredRoute, TechnicalErrorRoute } from '../../routing/Routing';
import getErrorMessage from '../helpers/error-code-helper';
import useSnackbar from '../snackbar/useSnackbar';
import FetchError from './FetchError';
import { ApiConfig } from './FetchTypes';

export default function useFetch<TResult, TProp>(
	props: ApiConfig<TResult, TProp>,
	instantFetch = false,
	successCallback: ((result: TResult) => void) | undefined = undefined,
	showErrorMessage = true,
	onBusinessError: ((error: FetchError) => void) | undefined = undefined,
	onFatalError: (() => void) | undefined = undefined,
	onFatalErrorNavigate: boolean | undefined = undefined
): UseMutationResult<TResult, unknown, TProp> {
	const navigate = useNavigate();
	const snackbar = useSnackbar();

	const result = useMutation({mutationFn: props.fn,
		mutationKey: [props.key],
		onSuccess: (data: TResult): void => {
			successCallback?.(data);
		},
		onError: (err): void => {
			if (err instanceof FetchError) {
				if (err.options.status === 401 || err.options.status === 403) {
					navigate(SessionExpiredRoute.path);
					return;
				}
				const message = getErrorMessage(err.message, err.options.params);

				if (showErrorMessage && message) {
					snackbar(message, SnackbarType.Error);
				}

				if (!message && onFatalErrorNavigate !== false) {
					navigate(TechnicalErrorRoute.path);
				}
				onBusinessError?.(err);
			} else {
				onFatalError?.();
				if (onFatalErrorNavigate !== false) {
					navigate(TechnicalErrorRoute.path);
				}
			}
		},
	});

	useEffect((): void => {
		if (instantFetch) {
			result.mutate({} as never);
		}
	}, []);

	return result;
}
