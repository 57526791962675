import { Typography } from '@mui/material';
import React from 'react';
import NestModal from '../../NestModal/NestModal';
import { ModalName } from '../HeaderConstants';
import {
	confirmationOfSendingModalTexts,
	textInBodyModalStyles,
} from './ConfirmationOfSendingModalConstants';
import modalSuccessIcon from '../../../common/images/modalSucessIcon.svg';

export interface ConfirmationOfSendingModalProps {
	open: ModalName;
	closeModal: () => void;
}

const ConfirmationOfSendingModal = (
	props: ConfirmationOfSendingModalProps
): JSX.Element => {
	const title = <img style={{ width: '149px' }} src={modalSuccessIcon} />;

	const content = (
		<Typography
			component={'div'}
			variant='h4'
			sx={{ color: 'rgb(22, 22, 30, 1)', marginBottom: '16px' }}
		>
			{confirmationOfSendingModalTexts.content}
		</Typography>
	);

	const footer = (
		<Typography component={'span'} variant='p2' sx={textInBodyModalStyles}>
			{confirmationOfSendingModalTexts.footer}
		</Typography>
	);

	return (
		<NestModal
			open={props.open === ModalName.ConfirmationOfSendingModal}
			closeModal={props.closeModal}
			title={title}
			content={content}
			footer={footer}
		/>
	);
};

export default ConfirmationOfSendingModal;
