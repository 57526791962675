import BaseFetch from '../../../../common/api/BaseFetch';
import { ApiConfig, ApiVersion } from '../../../../common/api/FetchTypes';
import { FullClientData } from './LightDataConsistencyModels';

const clientDataKey = 'getClientData';

function getClientData(_props: never): Promise<FullClientData> {
	return BaseFetch({ url: 'ClientData', method: 'GET', version: ApiVersion.Light });
}

const submitApplicationKey = 'submitApplication';

function submitApplication(): Promise<never> {
	return BaseFetch({
		url: 'ClientData/Submit',
		method: 'POST',
		version: ApiVersion.Light,
	});
}

export const GetClientDataConfig: ApiConfig<FullClientData, never> = {
	key: clientDataKey,
	fn: getClientData,
};

export const SubmitDataConfig: ApiConfig<never, never> = {
	key: submitApplicationKey,
	fn: submitApplication,
};
