import { styled } from '@mui/material/styles';
import Snackbar from '@mui/material/Snackbar/Snackbar';
import { mainTheme } from '../../theme/mainTheme';

export const ButtonExpandSession = styled('button')({
	height: '100%',
	width: '99px',
	border: '0',
	cursor: 'pointer',
	background: 'transparent',
});

export const SnackbarEl = styled(Snackbar)({
	[mainTheme.breakpoints.up('xs')]: {
		width: '290px',
		marginLeft: '16px',
	},
	[mainTheme.breakpoints.up(380)]: {
		width: '340px',
		marginLeft: '16px',
	},
	[mainTheme.breakpoints.up('sm')]: {
		width: 'unset',
		marginLeft: 'unset',
	},
});
