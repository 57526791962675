import BaseFetch from '../../../../../common/api/BaseFetch';
import { ApiConfig, ApiVersion } from '../../../../../common/api/FetchTypes';
import { ApplicationProcess } from '../../../../../common/constants/application-process';

export interface InitialLightData {
	maxCreditAmount: number;
	partnerLogoName: string | undefined;
	initInstallment: number;
	periods: number[];
	selectedPeriod: number;
	partnerName: string;
	process: ApplicationProcess;
	processId: string;
	query: string;
	creditPeriod: number;
	enableBrokerIdField: boolean;
}

const getLightInitialDataKey = 'getLightInitialData';

function getLightInitialData(props: string): Promise<InitialLightData> {
	return BaseFetch({
		url: `LandingPage/InitialData/${props}`,
		method: 'GET',
		version: ApiVersion.Light,
	});
}

export const GetLightInitialDataConfig: ApiConfig<InitialLightData, string> = {
	key: getLightInitialDataKey,
	fn: getLightInitialData,
};
