import { WebPage } from 'src/routing/Routing';
import BaseFetch from '../../../../common/api/BaseFetch';
import { ApiConfig } from '../../../../common/api/FetchTypes';
import { AgreementModelSend } from '../../../../components/AgreementCollection/AgreementsModels';
import Agreement from '../../../../models/Agreement';

const postSendSms = 'postSendSmsDataKey';
const getVerifySms = 'postVerifySmsCode';
const getAgreementKey = 'getAgreement';

function sendSms(agreements: AgreementModelSend[]): Promise<never> {
	return BaseFetch({
		url: 'SigningContract/sendSmsCode',
		method: 'POST',
		body: { agreements },
	});
}

function getVerifySmsCode(smsCode: string): Promise<string> {
	return BaseFetch({
		url: 'SigningContract/verifySmsCode/' + smsCode,
		method: 'GET',
	});
}

function getAgreement(): Promise<Agreement[]> {
	const page = WebPage.LightClSmsContractSigning;
	return BaseFetch({
		url: 'Agreement/ForApplication/' + page,
		method: 'GET',
	});
}

export const PostSendSms: ApiConfig<never, AgreementModelSend[]> = {
	key: postSendSms,
	fn: sendSms,
};

export const GetVerifySmsCode: ApiConfig<string, string> = {
	key: getVerifySms,
	fn: getVerifySmsCode,
};

export const GetAgreement: ApiConfig<Agreement[], never> = {
	key: getAgreementKey,
	fn: getAgreement,
};
