import React from 'react';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import {
	grayMainColor,
	secondaryMainColor,
	septenaryMainColor,
	textColorPrimary,
} from '../../../../theme/palette/palette';
import NestFileUploader from 'src/components/FileUploader/FileUploader';
import { IDocuments } from 'src/models/AttachDocumentsModels';
import NewTabFileHandler from 'src/components/NewTabFileHandler/NewTabFileHandler';
import { FileType } from 'src/enums/FileType';
import { submitButtonFileStyles } from './LightAttachDocumentsConstants';

interface ILightAttachDocumentsItem {
	title: string;
	subTitle: string;
	docsTitle: string;
	docsDescriptions: string[];
	showInstruction: boolean;
	setFiles: React.Dispatch<React.SetStateAction<IDocuments[]>>;
	files: IDocuments[] | undefined;
	addFiles: (files: FormData) => void;
	deleteDocumentById: (id: string) => void;
	isUploading: boolean;
}

const LightAttachDocumentsItem = (
	props: ILightAttachDocumentsItem
): JSX.Element => {
	return (
		<>
			<Stack>
				<Typography variant='h5' sx={{ color: secondaryMainColor }}>
					{props.title}
				</Typography>
				<Typography variant='p1' sx={{ color: grayMainColor }}>
					{props.subTitle}
				</Typography>
			</Stack>

			<Stack
				sx={{
					flexDirection: { xs: 'column', md: 'row' },
					padding: '16px 0 0 0',
				}}
			>
				<Stack sx={{ flex: 1, padding: '0' }}>
					<NestFileUploader
						documents={props.files}
						setFiles={props.setFiles}
						addFiles={props.addFiles}
						deleteDocumentById={props.deleteDocumentById}
						isUploading={props.isUploading}
					/>

					<Typography variant='h10' sx={{ color: septenaryMainColor }}>
						<span style={{ fontWeight: '600' }}>
							Dopuszczalne formaty plików:
						</span>
						<br /> JPG, PDF, doc, docx, dotx, odt.
						<br /> Maksymalny rozmiar plików to 30MB.
					</Typography>
				</Stack>

				{props.subTitle && (
					<Stack
						sx={{
							flex: 1,
							padding: { xs: '26px 0 0 0', md: '10px 0 0 16px' },
						}}
					>
						<Stack sx={{ flexDirection: 'row' }}>
							<Typography
								variant='p2'
								sx={{
									display: 'inline-block',
									color: textColorPrimary,
									fontWeight: '500',
									lineHeight: '19.6px',
								}}
							>
								<Typography
									variant='p2'
									sx={{
										display: 'inline-block',
										color: secondaryMainColor,
										fontWeight: '500',
										lineHeight: '19.6px',
									}}
								>
									Pamiętaj,
								</Typography>
								{' '}<span style={{fontWeight: '400'}} dangerouslySetInnerHTML={{
									__html: props.docsTitle,
								}}></span>
							</Typography>
						</Stack>

						<List sx={{ listStyleType: 'disc', margin: '0', padding: '0' }}>
							{props.docsDescriptions.map((item): JSX.Element => {
								return (
									<ListItem
										key={item}
										sx={{
											display: 'list-item',
											margin: '0 0 0 24px',
											padding: '2px 0',
											width: 'initial',
											fontFamily: 'Inter, sans-serif',
											fontStyle: 'normal',
											fontWeight: '400',
											fontSize: '14px',
											lineHeight: '19.6px',
											color: textColorPrimary,
										}}
									>
										{item}
									</ListItem>
								);
							})}
						</List>

						{props.showInstruction && (
							<NewTabFileHandler
								fileType={FileType.ZusInstruction}
								fileName='Jak_samodzielnie_utworzyć_potwierdzenie_danych_z_ZUS_na_portalu_PUE_ZUS.pdf'
								buttonStyles={{
									...submitButtonFileStyles,
									textAlign: 'start',
									fontFamily: 'Athletics, sans-serif',
									fontSize: '16px',
									lineHeight: '22.4px',
								}}
								buttonContainerMargin='0'
								buttonSubmitText='Zobacz instrukcję, jak uzyskać potwierdzenie danych z ZUS-u w kilka minut.'
							/>
						)}
					</Stack>
				)}
			</Stack>
		</>
	);
};

export default LightAttachDocumentsItem;
