export function isIdCardNumberValidator(
	str: string | undefined
): boolean | string {
	if (!str || str.length != 9) return 'Niepoprawna wartość';

	str = str.toUpperCase();
	const letterValues = [
		'0',
		'1',
		'2',
		'3',
		'4',
		'5',
		'6',
		'7',
		'8',
		'9',
		'A',
		'B',
		'C',
		'D',
		'E',
		'F',
		'G',
		'H',
		'I',
		'J',
		'K',
		'L',
		'M',
		'N',
		'O',
		'P',
		'Q',
		'R',
		'S',
		'T',
		'U',
		'V',
		'W',
		'X',
		'Y',
		'Z',
	];
	function getLetterValue(letter: string): number {
		return letterValues.indexOf(letter);
	}

	for (let i = 0; i < 3; i++)
		if (getLetterValue(str[i]) < 10) return 'Niepoprawna wartość';

	for (let i = 3; i < 9; i++)
		if (getLetterValue(str[i]) < 0 || getLetterValue(str[i]) > 9)
			return 'Niepoprawna wartość';

	let sum =
		7 * getLetterValue(str[0]) +
		3 * getLetterValue(str[1]) +
		getLetterValue(str[2]) +
		7 * getLetterValue(str[4]) +
		3 * getLetterValue(str[5]) +
		getLetterValue(str[6]) +
		7 * getLetterValue(str[7]) +
		3 * getLetterValue(str[8]);
	sum %= 10;
	if (sum != getLetterValue(str[3])) return 'Niepoprawna wartość';

	return true;
}
