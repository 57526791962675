import { styled } from '@mui/system';
import arrowDown from '../../../../../common/images/arrow-down-purple.svg';
import { typography } from '../../../../../theme/typography/typography';

export const DatePickerHeader = styled('div')({
	display: 'flex',
	justifyContent: 'space-between',
	alignItems: 'center',
	height: '50px',
	margin: '0 6px 0 12px',
});

export const DatePickerSelect = styled('div')({});

export const Select = styled('select')({
	border: 'unset',
	WebkitAppearance: 'none',
	MozAppearance: 'none',
	backgroundImage: `url(${arrowDown})`,
	backgroundRepeat: 'no-repeat',
	backgroundPositionX: '95%',
	backgroundPositionY: '4px',
	marginLeft: '10px',
	width: '72px',
	...typography.p1,
	cursor: 'pointer',
	backgroundColor: '#fff',
	position: 'relative',
});

export const Image = styled('img')({
	margin: '0 20px',
	cursor: 'pointer',
});
