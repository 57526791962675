import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import SectionHeader from '../../../../../components/DataConsistency/SectionHeader';
import { WebPage } from '../../../../../routing/Routing';
import ValueDescriptor from '../../../../../components/ValueDescriptor/ValueDescriptor';
import { gridFullWidth, gridHalfWidth } from '../../../../../theme/grid/GridConstants';
import { formatDate } from 'src/common/helpers/date-helper';
import { HorizontalLine } from '../../../../rbf/rbf-data-consistency/DataConsistency.css';
import { formatAmountToFixed2 } from '../../../../../common/helpers/number-helper';
import {
	maritalStatusOptions, unemployedDependantPeopleLabel,
	unemployedDependantPeopleWithoutSpouseLabel
} from '../../../components/light-personal-data/consts/LightPersonalDataConstants';
import { ClientWealthLight, IEconomicLiabilities, LightClientData } from '../LightDataConsistencyModels';
import { clientWealthType, economicLiabilityType } from '../LightDataConsistencyConst';
import { staticMask } from 'src/common/helpers/mask-helper';

export interface LightInfoAboutYouProps {
	title: string;
	clientData: LightClientData;
	clientWealth: ClientWealthLight[];
	editPage: WebPage;
	setRenderPage: React.Dispatch<React.SetStateAction<WebPage | undefined>>;
}

export default function LightInfoAboutYou(props: LightInfoAboutYouProps): JSX.Element {
	const unemployedDependantPeopleAmount: string = props.clientData.maritalStatus === 2 ? unemployedDependantPeopleWithoutSpouseLabel : unemployedDependantPeopleLabel
	return (
		<>
			<Grid item xs={gridFullWidth} sx={{ marginTop: '16px', marginBottom: '-8px' }}>
				<SectionHeader title={props.title} page={props.editPage} setRenderPage={props.setRenderPage} />
				<HorizontalLine />
			</Grid>
			<Grid item xs={gridFullWidth} md={gridHalfWidth}>
				<ValueDescriptor title='Imię' value={props.clientData?.firstName} />
			</Grid>
			<Grid item xs={gridFullWidth} md={gridHalfWidth}>
				<ValueDescriptor title='Nazwisko' value={props.clientData?.lastName} />
			</Grid>
			<Grid item xs={gridFullWidth} md={gridHalfWidth}>
				<ValueDescriptor title='Seria i numer dowodu osobistego' value={props.clientData?.idCardNumber} />
			</Grid>
			<Grid item xs={gridFullWidth} md={gridHalfWidth}>
				<ValueDescriptor title='Data wydania dowodu osobistego' value={formatDate(props.clientData?.idCardIssuedDate)} />
			</Grid>
			<Grid item xs={gridFullWidth} md={gridHalfWidth}>
				<ValueDescriptor title='Data ważności dowodu osobistego' value={formatDate(props.clientData?.idCardExpirationDate)} />
			</Grid>
			<Grid item xs={gridFullWidth} md={gridHalfWidth}>
				<ValueDescriptor title='Kraj urodzenia' value={props.clientData?.countryOfBirth} />
			</Grid>
			<Grid item xs={gridFullWidth} md={gridHalfWidth}>
				<ValueDescriptor title='Miejscowość urodzenia' value={props.clientData?.cityOfBirth} />
			</Grid>
			<Grid item xs={gridFullWidth} md={gridHalfWidth}>
				<ValueDescriptor title='Nazwisko panieńskie matki' value={props.clientData?.mothersMaidenName} />
			</Grid>
			<Grid item xs={gridFullWidth} md={gridHalfWidth}>
				<ValueDescriptor title='PESEL' value={props.clientData?.socialSecurityNumber} />
			</Grid>
			<Grid item xs={gridFullWidth} md={gridHalfWidth}>
				<ValueDescriptor title='Stan cywilny' value={maritalStatusOptions[props.clientData?.maritalStatus - 1][0]} />
			</Grid>
			<Grid item xs={gridFullWidth} md={gridHalfWidth}>
				<ValueDescriptor title={unemployedDependantPeopleAmount} value={props.clientData?.unemployedDependantPeopleAmount} />
			</Grid>
			<Grid item xs={gridFullWidth} md={gridHalfWidth}>
				<ValueDescriptor title='Miesięczne koszty utrzymania' value={`${formatAmountToFixed2(props.clientData?.monthlyLivingCosts)} PLN`} />
			</Grid>
			<Grid item xs={gridFullWidth} md={gridHalfWidth}>
				<ValueDescriptor title='Miesięczna wysokość innych obciążeń' value={`${formatAmountToFixed2(props.clientData?.otherMonthlyCosts)} PLN`} />
			</Grid>

			
				<>
					{props.clientWealth?.length > 0 && (
						<>
							<HorizontalLine />

							<Typography
								variant='h9'
								sx={{ color: 'text.secondary', fontWeight: '600', width: '100%', padding: '32px 0 0 0', margin: '0 0 -16px 0' }}
							>
								Majątek osobisty i firmowy
							</Typography>

							{props.clientWealth?.map((wealth: ClientWealthLight): JSX.Element => {
								return (
									<Grid key={wealth.type} item xs={gridFullWidth} md={gridHalfWidth}>
										<ValueDescriptor
											title={`${clientWealthType[wealth.type]}`}
											desc={'Łączna wartość'}
											value={`${formatAmountToFixed2(wealth.worth)} PLN`}
										/>
									</Grid>
								);
							})}
						</>
					)}

					{props.clientData?.economicLiabilities.length > 0 && (
						<>
							<HorizontalLine />

							<Typography
								variant='h9'
								sx={{ color: 'text.secondary', fontWeight: '600', width: '100%', padding: '32px 0 0 0', margin: '0 0 16px 0' }}
							>
								Powiązania gospodarcze, ekonomiczno/kapitałowe lub osobowe
							</Typography>

							{props.clientData?.economicLiabilities.map((liability: IEconomicLiabilities, index: number): JSX.Element => {
								return (
									<Grid
										container
										rowSpacing={4}
										key={index}
										sx={index !== props.clientData.economicLiabilities.length - 1 ? { padding: '0 0 32px 0' } : null}
									>
										<Grid item xs={gridFullWidth} md={gridHalfWidth}>
											<ValueDescriptor title='Nazwa podmiotu' value={`${liability.name}`} />
										</Grid>
										{liability.nip && (
											<Grid item xs={gridFullWidth} md={gridHalfWidth}>
												<ValueDescriptor title='NIP' value={staticMask('', liability.nip, '### ### ## ##', null).maskedValue} />
											</Grid>
										)}
										{liability.socialSecurityNumber && (
											<Grid item xs={gridFullWidth} md={gridHalfWidth}>
												<ValueDescriptor title='PESEL' value={`${liability.socialSecurityNumber}`} />
											</Grid>
										)}
										<Grid item xs={gridFullWidth} md={gridHalfWidth}>
											<ValueDescriptor title='Rodzaj powiązania' value={`${economicLiabilityType[liability.type]}`} />
										</Grid>
									</Grid>
								);
							})}
						</>
					)}

					{(Object.keys(props.clientData?.creditOrLeasingLiabilities.cashLoanLiabilities).length > 0 ||
						Object.keys(props.clientData?.creditOrLeasingLiabilities.overdraftLiabilities).length > 0 ||
						Object.keys(props.clientData?.creditOrLeasingLiabilities.leasingLiabilities).length > 0) && (
						<>
							<HorizontalLine />

							<Typography
								variant='h9'
								sx={{ color: 'text.secondary', fontWeight: '600', width: '100%', padding: '32px 0 0 0', margin: '0 0 -16px 0' }}
							>
								Zobowiązania kredytowe lub leasingowe w innych bankach (poza Nest Bank S.A.) lub instytucjach pożyczkowych
							</Typography>

							{Object.entries(props.clientData?.creditOrLeasingLiabilities.cashLoanLiabilities).map(
								(entry: [string, number]): JSX.Element => (
									<Grid key={entry[0]} item xs={gridFullWidth} md={gridHalfWidth}>
										<ValueDescriptor
											title={`Kredyty spłacane w ratach w ${entry[0]}`}
											desc='Suma miesięcznych rat'
											value={`${formatAmountToFixed2(entry[1])} ${entry[0]}`}
										/>
									</Grid>
								)
							)}

							{Object.entries(props.clientData?.creditOrLeasingLiabilities.overdraftLiabilities).map(
								(entry: [string, number]): JSX.Element => (
									<Grid key={entry[0]} item xs={gridFullWidth} md={gridHalfWidth}>
										<ValueDescriptor
											title={`Limity odnawialne w ${entry[0]}`}
											desc='Suma przyznanych limitów'
											value={`${formatAmountToFixed2(entry[1])} ${entry[0]}`}
										/>
									</Grid>
								)
							)}

							{Object.entries(props.clientData?.creditOrLeasingLiabilities.leasingLiabilities).map(
								(entry: [string, number]): JSX.Element => (
									<Grid key={entry[0]} item xs={gridFullWidth} md={gridHalfWidth}>
										<ValueDescriptor
											title={`Leasing w ${entry[0]}`}
											desc='Suma miesięcznych rat'
											value={`${formatAmountToFixed2(entry[1])} ${entry[0]}`}
										/>
									</Grid>
								)
							)}
						</>
					)}
				</>
		</>
	);
}
