import { SxProps } from '@mui/system/styleFunctionSx';
import { useEffect, useState } from 'react';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import '../ReactMultiCarousel/assets/styles.css';
import Carousel from '../ReactMultiCarousel/Carousel';
import ButtonGroupCarousel from './ButtonGroup';

interface IMultiCarousel {
	carouselColor: string;
	carouselColorText: string;
	carouselColorBackground: string;
	numberOfItemsOnDesktopView: number;
	numberOfItemsOnTabletView: number;
	numberOfItemsOnMobileView: number;
	startItemIndex: number;
	periods: number[];
	setSelectedPeriod?: (selectedPeriod: number) => void;
	sx?: SxProps;
	width100?: boolean;
}

const MultiCarousel = ({
	carouselColor,
	carouselColorText,
	carouselColorBackground,
	startItemIndex,
	periods,
	sx,
	width100,
	setSelectedPeriod,
	...props
}: IMultiCarousel): JSX.Element => {
	const [selectedPeriodCarousel, setSelectedPeriodCarousel] = useState<number>(0);

	useEffect((): void => {
		setSelectedPeriodCarousel(periods[startItemIndex]);
	}, [startItemIndex]);
	useEffect((): void => {
		setSelectedPeriod?.(selectedPeriodCarousel);
	}, [selectedPeriodCarousel]);

	return (
		<Stack sx={{ position: 'relative', padding: '0 16px', width: 'calc(100% - 32px)' }}>
			<Carousel
				responsive={{
					desktop: { breakpoint: { max: 10000, min: 857 }, items: props.numberOfItemsOnDesktopView },
					tablet: { breakpoint: { max: 857, min: 460 }, items: props.numberOfItemsOnTabletView },
					mobile: { breakpoint: { max: 460, min: 0 }, items: props.numberOfItemsOnMobileView },
				}}
				containerClass={width100 ? 'w-100' : ''}
				sliderClass={width100 ? 'w-100' : ''}
				arrows={false}
				// eslint-disable-next-line @typescript-eslint/prefer-ts-expect-error, @typescript-eslint/ban-ts-comment
				// @ts-ignore
				customButtonGroup={<ButtonGroupCarousel startItemIndex={startItemIndex} carouselColor={carouselColor} />}
				renderButtonGroupOutside={true}
			>
				{periods
					.sort((a, b): number => a - b)
					.map(
						(period, index): JSX.Element => (
							<Stack key={index} sx={{ padding: '0 12px' }}>
								<Button
									onClick={(): void => setSelectedPeriodCarousel(period)}
									sx={{
										width: '100%',
										minWidth: '30px',
										height: '45px',
										borderRadius: '4px',
										fontFamily: 'Athletics, sans-serif !important',
										fontSize: '16px',
										fontWeight: '700',
										lineHeight: '19.2px',
										backgroundColor: selectedPeriodCarousel === period ? carouselColor : carouselColorBackground,
										color: selectedPeriodCarousel === period ? carouselColorText : carouselColor,
										'&:hover': {
											backgroundColor: selectedPeriodCarousel === period ? carouselColor : carouselColorBackground,
											color: selectedPeriodCarousel === period ? carouselColorText : carouselColor,
										},
										...sx,
									}}
								>
									{period}
								</Button>
							</Stack>
						)
					)}
			</Carousel>
		</Stack>
	);
};

export default MultiCarousel;
