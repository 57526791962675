import {
	Dialog,
	DialogActions,
	DialogContent,
	DialogContentText,
	DialogTitle,
	IconButton,
	SxProps,
	useMediaQuery,
	useTheme,
} from '@mui/material';
import { Theme } from '@mui/material/styles/createTheme';
import React from 'react';
import closeIcon from '../../common/images/closeIcon.svg';

export interface NestModalProps {
	open: boolean;
	title?: JSX.Element | string;
	content?: JSX.Element | string;
	footer?: JSX.Element | string;
	closeModal: () => void;
	maxWidth?: string;
	dialogContentSx?: SxProps;
	dialogHeaderSx?: SxProps;
}

const NestModal = (props: NestModalProps): JSX.Element => {
	const theme = useTheme();
	const fullScreen = useMediaQuery(theme.breakpoints.down('md'));

	return (
		<Dialog
			fullScreen={fullScreen}
			open={props.open}
			onClose={props.closeModal}
			aria-labelledby='responsive-dialog-title'
			PaperProps={{
				sx: {
					margin: '32px',
					maxWidth: props.maxWidth ?? 624,
					height: 'auto',
				} as SxProps<Theme>,
			}}
		>
			{props.title ? (
				<DialogTitle
					sx={{
						margin: '65px 0 37px 0',
						textAlign: 'center',
						padding: '0 16px',
						...props.dialogHeaderSx,
					}}
				>
					<IconButton
						aria-label='close'
						onClick={props.closeModal}
						sx={{ position: 'absolute', right: 8, top: 8 }}
					>
						<img style={{ width: '24px' }} src={closeIcon} alt='close-icon' />
					</IconButton>
					{props.title}
				</DialogTitle>
			) : null}
			{props.content ? (
				<DialogContent
					sx={{
						textAlign: 'center',
						padding: '0 37px',
						...props.dialogContentSx,
					}}
				>
					<DialogContentText component='div'>{props.content}</DialogContentText>
				</DialogContent>
			) : null}
			{props.footer ? (
				<DialogActions sx={{ marginBottom: '48px', textAlign: 'center' }}>
					{props.footer}
				</DialogActions>
			) : null}
		</Dialog>
	);
};

export default NestModal;
