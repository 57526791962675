import styled from '@mui/system/styled';
import { CSSProperties } from '@mui/material/styles/createMixins';
import { FileUploadedContainerProps } from 'src/models/AttachDocumentsModels';
import { primaryMainColor } from '../../theme/palette/palette';
import { typography } from '../../theme/typography/typography';

export const focusedStyle = {
	borderColor: '#2196f3',
};

export const acceptStyle = {
	borderColor: '#00e676',
};

export const rejectStyle = {
	borderColor: '#ff1744',
};

export const FileUploadMainContainer = styled('div')({
	display: 'flex',
	flexDirection: 'column',
	marginBottom: '16px',
});

export const FileUploadAttachDocument = styled('div')({
	display: 'flex',
	flexDirection: 'column',
	marginTop: '10px',
});

export const fileUploadBoxStyles: React.CSSProperties = {
	marginTop: '16px',
	maxWidth: 361,
	width: '100%',
	height: 111,
	border: '2px dashed',
	borderColor: primaryMainColor,
	borderRadius: '16px',
	display: 'flex',
	justifyContent: 'center',
	alignItems: 'center',
	flexDirection: 'column',
	margin: 0,
	background: '#F8F9FF',
};

export const FileUploadedContainer = styled(
	'div',
	{}
)<FileUploadedContainerProps>(
	(props: FileUploadedContainerProps): CSSProperties => ({
		maxWidth: 390,
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
		flexDirection: 'column',
		marginBottom: 8,
		paddingTop: 8,
		cursor: 'pointer',
		borderTop: `${props.border ? '1px solid #E4E4ED' : ''}`,
	})
);

export const FileUploadedMainContainer = styled('div')({
	width: '100%',
});

export const AttachDocumentInsideBoxTextContainer = styled('div')({
	cursor: 'pointer',
	...typography.h7,
	fontFamily: 'Athletics, sans-serif',
	display: 'flex',
	alignItems: 'flex-start',
});
