import { AgreementModel, AgreementSectionModel } from '../../components/AgreementCollection/AgreementsModels';

interface GroupAllOptions {
	hideExpandGroup?: boolean;
	hideExpandElement?: boolean;
	isExpandGroup?: boolean;
	isExpandElement?: boolean;
	switchDisplayContent?: boolean;
	agreementFilter?: string;
	headers?: {
		reguiredSectionHeader: string;
		additionalSectionHeader: string;
	};
}

export const groupAllAgreements = (agreements: AgreementModel[], options: GroupAllOptions = {}): AgreementSectionModel[] => {
	return [
		{
			consentGroup: agreements
				.filter((agreement): boolean | undefined => agreement.section !== options.agreementFilter || agreement.isRequired)
				.map((agreement): AgreementModel => {
					let returnAgreement = {
						...agreement,
						isExpand: options?.hideExpandElement,
						hideExpand: options?.hideExpandElement,
						isSelected: agreement.isSelected,
					};

					if (options?.switchDisplayContent) {
						returnAgreement = {
							...returnAgreement,
							abbreviatedContent: agreement.contentHtml,
							content: '',
						};
					}

					return returnAgreement;
				}),
			hideExpand: options?.hideExpandGroup,
			isExpand: options?.isExpandGroup,
			groupHeader: options?.headers?.reguiredSectionHeader,
		},
		{
			consentGroup: agreements
				.filter((agreement): boolean => agreement.section === options.agreementFilter && !agreement.isRequired)
				.map((agreement): AgreementModel => {
					let returnAgreement = {
						...agreement,
						isExpand: options?.hideExpandElement,
						hideExpand: options?.hideExpandElement,
						isSelected: agreement.isSelected,
					};

					if (options?.switchDisplayContent) {
						returnAgreement = {
							...returnAgreement,
							abbreviatedContent: agreement.contentHtml,
							content: '',
						};
					}

					return returnAgreement;
				}),
			hideExpand: options?.hideExpandGroup,
			isExpand: options?.isExpandGroup,
			groupHeader: options?.headers?.additionalSectionHeader,
		},
	];
};
