import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import styled from '@mui/system/styled';
import checkIcon from '../../../common/images/checkIcon.svg';
import doctor_image from '../../../common/images/doctor-image.svg'
import lead_hero_round from '../../../common/images/lead_hero_round.svg';
import { getSessionStorageValue, guidKey } from '../../../common/sessionStorage/SessionStorageService';
import Loader from '../../../components/Loader/Loader';
import { CheckIcon, CheckLogoItem } from '../../../components/NestCollapse/NestCollapse.css';
import { denaryMainColor } from '../../../theme/palette/palette';
import { typography } from '../../../theme/typography/typography';
import { resolveIndexTokenByType } from '../../common/index/IndexTokenResolver';
import { LeadProductType } from '../../components/leadTypes';
import { SemiStrongBold } from '../../rbf/rbf-landing-page/credit-info-section/CreditInfoSection.css';

interface LeadClVariantProps {
    amount: string | undefined;
}

const Span = styled('span')({})
const Br = styled('br')({})
export const LeadOvdVariantHeader = (props: LeadClVariantProps): JSX.Element => {
    return (
        <Stack
            sx={{ padding: '16px', opacity: props.amount ? '1' : '0', color: '#FFF' }}
        >
            <Typography
                variant="h11"
                sx={{
                    fontSize: '40px',
                    paddingBottom: '7px',
                }}
            >
                Rozwiń swój biznes!
            </Typography>

            <Stack
                sx={{
                    flexDirection: 'row',
                    alignItems: 'center',
                    flexWrap: 'wrap',
                }}
            >
                <Typography
                    variant="h11"
                    sx={{
                        padding: '0 6px 0 0',
                        fontSize: '40px',
                    }}
                >
                    Limit w koncie nawet
                </Typography>
                <Stack sx={{ flexDirection: 'row', alignItems: 'center' }}>
                    <Typography
                        variant="h11"
                        sx={{
                            padding: '0 6px 0 0',
                            fontSize: '40px',
                        }}
                    >
                        do
                    </Typography>

                    <Typography
                        variant="h11"
                        sx={{
                            color: denaryMainColor,
                            padding: ' 0 6px 0 0',
                            fontSize: '40px',
                            lineHeight: '48px',
                        }}
                    >
                        {props.amount ? props.amount : (<Loader/>)}
                    </Typography>
                    <Typography
                        variant="h11"
                        sx={{
                            color: denaryMainColor,
                            fontSize: '24px',
                            marginTop: { xs: '4px', md: '11px' },
                        }}
                    >
                        PLN
                    </Typography>
                </Stack>
            </Stack>
            <Typography
                variant="h11"
                sx={{ fontSize: '40px', }}
            >
                bez wychodzenia z domu
            </Typography>
        </Stack>
    )
}

export const LeadClVariantHeader1 = (props: LeadClVariantProps): JSX.Element => {
    return (
        <Stack
            sx={{ padding: '16px', opacity: props.amount ? '1' : '0', color: '#FFF' }}
        >
            <Typography
                variant="h11"
                sx={{
                    fontSize: '40px',
                    lineHeight: '48px',
                    display: {xs: 'block', sm: 'none'}
                }}
            >
                Rozwiń swój biznes! Nawet do
            </Typography>
            <Typography
                variant="h11"
                sx={{
                    fontSize: '40px',
                    lineHeight: '48px',
                    display: {xs: 'none', sm: 'block'}
                }}
            >
                Rozwiń swój biznes!
            </Typography>

            <Stack
                sx={{
                    flexDirection: 'row',
                    alignItems: 'center',
                    flexWrap: 'wrap',
                }}
            >
                <Typography
                    variant="h11"
                    sx={{
                        padding: '0 6px 0 0',
                        fontSize: '40px',
                        lineHeight: '48px',
                        display: {xs: 'none', sm: 'block'}
                    }}
                >
                    Nawet
                </Typography>
                <Stack sx={{ flexDirection: 'row', alignItems: 'center' }}>
                    <Typography
                        variant="h11"
                        sx={{
                            padding: '0 6px 0 0',
                            fontSize: '40px',
                            lineHeight: '48px',
                            display: {xs: 'none', sm: 'block'}
                        }}
                    >
                        do
                    </Typography>

                    <Typography
                        variant="h11"
                        sx={{
                            color: denaryMainColor,
                            padding: '0 6px 0px 0',
                            fontSize: '40px',
                            lineHeight: '48px',
                        }}
                    >
                        {props.amount ? props.amount : (<Loader/>)}
                    </Typography>
                    <Typography
                        variant="h11"
                        sx={{
                            color: denaryMainColor,
                            fontSize: '24px',
                            lineHeight: '48px',
                            marginTop: { xs: '4px', md: '11px' },
                        }}
                    >
                        zł
                    </Typography>
                </Stack>
            </Stack>
            <Typography
                variant="h11"
                sx={{ fontSize: '40px', lineHeight: '48px' }}
            >
                bez wychodzenia z domu
            </Typography>
        </Stack>
    )
}

export const LeadClVariantHeader2 = (props: LeadClVariantProps): JSX.Element => {
    return (
        <Stack
            sx={{ padding: '16px', opacity: '1', color: '#FFF', maxWidth: '440px' }}
        >
            <Typography
                variant="h11"
                sx={{
                    fontSize: '40px',
                    lineHeight: '50.4px',
                    padding: '0 6px 0px 0',
                }}
            >
                Kredyt firmowy nawet
                <Span sx={{
                    padding: '0 6px 0 0',
                    fontSize: '40px',
                    lineHeight: '48px'
                }}
                > do </Span>
                <Br sx={{display: {xs: 'block', sm: 'none'}}}></Br>
                <Span>
                    <Span sx={{
                        color: denaryMainColor,
                        padding: '0 6px 0px 0',
                        fontSize: '40px',
                        lineHeight: '48px'
                    }}>{props.amount ? props.amount : (<Loader/>)}</Span>
                    <Span sx={{
                        padding: '0 6px 0px 0',
                        color: denaryMainColor,
                        fontSize: '24px',
                        lineHeight: '48px'
                    }}>zł</Span>
                </Span>
                <Span sx={{fontSize: '40px', lineHeight: '48px'}}> na</Span>
            </Typography>
            <Typography
                variant="h11"
                sx={{ fontSize: '40px', lineHeight: '48px', }}
            >
                dowolny cel
            </Typography>
        </Stack>
    )
}

export const LeadClVariantHeader3 = (): JSX.Element => {
    return (
        <>
        <Stack
            sx={{ padding: '16px', opacity: '1', color: '#FFF' }}
        >
            <Typography
                variant="h11"
                sx={{
                    fontSize: '40px',
                    paddingBottom: '7px',
                }}
            >
                Kredyt firmowy z
            </Typography>

            <Stack
                sx={{
                    flexDirection: 'row',
                    alignItems: 'center',
                    flexWrap: 'wrap',
                }}
            >
                <Stack sx={{ flexDirection: 'row', alignItems: 'center' }}>
                    <Typography
                        variant="h11"
                        sx={{
                            color: denaryMainColor,
                            padding: { xs: '0 6px 8px 0', md: '0 6px 0px 0' },
                            fontSize: '40px',
                            lineHeight: '50.4px',
                        }}
                    >
                        gwarancją niższej marży
                    </Typography>
                </Stack>
            </Stack>
            <CheckLogoItem sx={{marginTop: '25px'}}>
                <CheckIcon src={checkIcon} alt='check icon' />
                <Typography
                    variant={'p1'}
                    sx={{ color: '#FFFFFF', width: '455px', marginBottom: '10px' }}
                >
                    Jeśli znajdziesz tańszy kredyt to masz gwarancję, że {' '}
                    <SemiStrongBold>obniżymy Twoją marżę.</SemiStrongBold>
                </Typography>
            </CheckLogoItem>
            <CheckLogoItem>
                <CheckIcon src={checkIcon} alt='check icon' />
                <Typography
                    variant={'p1'}
                    sx={{ color: '#FFFFFF', width: '455px', marginBottom: '10px' }}
                >
                    Pożycz aż do
                    <SemiStrongBold> 1 000 000 zł na dowolny cel.</SemiStrongBold>
                </Typography>
            </CheckLogoItem>
            <CheckLogoItem>
                <CheckIcon src={checkIcon} alt='check icon' />
                <Typography variant='p1' sx={{ color: '#FFFFFF', width: '455px' }}>
                    <SemiStrongBold>Pieniądze otrzymasz na konto do 24h</SemiStrongBold> od podpisania umowy.
                </Typography>
            </CheckLogoItem>
        </Stack>
        </>
    )
}

export const LeadClVariantHeader4 = (): JSX.Element => {
  return (
    <>
      <Stack
        sx={{ padding: '16px', opacity: '1', color: '#FFF' }}
      >
        <Typography
          variant="h11"
          sx={{
            fontSize: '40px',
            paddingBottom: '7px',
          }}
        >
          Solidne wsparcie dla lekarzy z {' '}
          <Span sx={{...typography.h11,
            color: denaryMainColor,
            padding: { xs: '0 6px 8px 0', md: '0 6px 0px 0' },
            fontSize: '40px',
            lineHeight: '50.4px',}}>
            gwarancją niższej marży
          </Span>
        </Typography>
        <CheckLogoItem sx={{marginTop: '25px'}}>
          <CheckIcon src={checkIcon} alt='check icon' />
          <Typography
            variant={'p1'}
            sx={{ color: '#FFFFFF', width: '455px', marginBottom: '10px' }}
          >
            Jeśli znajdziesz tańszy kredyt, to masz<br/> gwarancję, że {' '}
            <SemiStrongBold>obniżymy Twoją marżę.</SemiStrongBold>
          </Typography>
        </CheckLogoItem>
        <CheckLogoItem>
          <CheckIcon src={checkIcon} alt='check icon' />
          <Typography
            variant={'p1'}
            sx={{ color: '#FFFFFF', width: '455px', marginBottom: '10px' }}
          >
            Pożycz aż do
            <SemiStrongBold> 1 000 000 zł na dowolny cel</SemiStrongBold><br/> związany z prowadzoną działalnością gospodarczą.
          </Typography>
        </CheckLogoItem>
        <CheckLogoItem>
          <CheckIcon src={checkIcon} alt='check icon' />
          <Typography variant='p1' sx={{ color: '#FFFFFF', width: '455px' }}>
            <SemiStrongBold>Decyzja kredytowa nawet w 5 minut,</SemiStrongBold><br/> a z umową możemy przyjechać do Ciebie.
          </Typography>
        </CheckLogoItem>
      </Stack>
    </>
  )
}

export const LeadClVariantHeader5 = (): JSX.Element => {
  return (
    <>
      <Stack
        sx={{ padding: '16px', opacity: '1', color: '#FFF' }}
      >
        <Typography
          variant="h11"
          sx={{
            fontSize: '40px',
            paddingBottom: '7px',
          }}
        >
          Solidne wsparcie dla lekarzy z {' '}
          <Span sx={{...typography.h11,
            color: denaryMainColor,
            fontSize: '40px',
            lineHeight: '50.4px',}}>
            gwarancją niższej marży
          </Span>
        </Typography>
        <Typography sx={{
          fontFamily: 'Inter, sans-serif',
          fontStyle: 'normal',
          fontWeight: 'bold',
          fontSize: '40px',
          lineHeight: '48px',
          marginTop: '39px',
          color: '#AFFFD1'
        }}>
          do 1 000 000 zł
        </Typography>
        <Typography variant="p1" sx={{marginTop: '10px'}}>
          Pożycz aż do <b>1 000 000 zł na dowolny cel</b><br/> związany z prowadzoną działalnością gospodarczą.
        </Typography>
        <Typography sx={{
          fontFamily: 'Inter, sans-serif',
          fontStyle: 'normal',
          fontWeight: 'bold',
          fontSize: '40px',
          lineHeight: '48px',
          marginTop: '15px',
          color: '#AFFFD1'
        }}>
          nawet w 5 minut
        </Typography>
        <Typography variant="p1" sx={{marginTop: '10px'}}>
          <b>Decyzja kredytowa nawet w 5 minut</b>,<br/> a z umową możemy przyjechać do Ciebie.
        </Typography>
        <Typography sx={{
          fontFamily: 'Inter, sans-serif',
          fontStyle: 'normal',
          fontWeight: 'bold',
          fontSize: '40px',
          lineHeight: '48px',
          marginTop: '15px',
          color: '#AFFFD1'
        }}>
          niższa marża
        </Typography>
        <Typography variant="p1" sx={{marginTop: '10px',}}>
          Jeśli znajdziesz tańszy kredyt, to masz<br/> gwarancję, że <b>obniżymy Twoją marżę</b>.
        </Typography>
      </Stack>
    </>
  )
}

const DefaultImageComponent = (): JSX.Element => (
  <Stack sx={{width: '100%', display: {md: 'flex', xs: 'none'}, justifyContent: 'center'}}>
    <Stack
      sx={{
        background: '#202131',
        borderRadius: '50%',
        width: '385px',
        height: '385px',
        marginLeft: '70px'
      }}
    >
    <img src={lead_hero_round} alt='lead hero round' />
    </Stack>
  </Stack>
)

const DoctorImageComponent = (): JSX.Element => (
  <Stack sx={{width: '100%', display: {md: 'flex', xs: 'none'}, justifyContent: 'center'}}>
    <Stack
      sx={{
        background: '#202131',
        borderRadius: '50%',
        width: '385px',
        height: '385px',
        marginLeft: '70px'
      }}
    >
    <img src={doctor_image} alt='lead hero doctor' />
    </Stack>
  </Stack>
)

export const leadVariantOfHeaderText = (amount: string | undefined, productType?: LeadProductType): JSX.Element | undefined => {
    const guid: string | undefined = getSessionStorageValue(guidKey);
    const variantsOfText = [
        { header: <LeadOvdVariantHeader amount={amount}/>, token: window.CONFIG.LEAD_OVD_TOKEN_1 },
        { header: <LeadClVariantHeader1 amount={amount}/>, token: window.CONFIG.LEAD_CL_TOKEN },
        { header: <LeadClVariantHeader3/>, token: window.CONFIG.LEAD_CL_TOKEN_1 },
        { header: <LeadClVariantHeader2 amount={amount}/>, token: window.CONFIG.LEAD_CL_TOKEN_2 },
        { header: <LeadClVariantHeader3/>, token: window.CONFIG.LEAD_CL_TOKEN_3 },
        { header: <LeadClVariantHeader4/>, token: window.CONFIG.LEAD_CL_TOKEN_4 },
        { header: <LeadClVariantHeader5/>, token: window.CONFIG.LEAD_CL_TOKEN_5 },
    ];

    const header = variantsOfText.find(
        (x): string | undefined => {
            return x.token.find((t): boolean => t.toUpperCase() === (guid?.toUpperCase() || resolveIndexTokenByType()?.token?.toUpperCase()))
        }
    )?.header;
    if(!header){
        if(productType === LeadProductType.CashLoan){
            return <LeadClVariantHeader1 amount={amount}/>
        }
        if(productType === LeadProductType.Overdraft){
            return <LeadOvdVariantHeader amount={amount}/>
        }
    }
    return header;
}

export const leadVariantOfImage = (): JSX.Element | undefined => {
    const guid: string | undefined = getSessionStorageValue(guidKey);
    const variantsOfText = [
        { header: <DefaultImageComponent/>, token: window.CONFIG.LEAD_OVD_TOKEN_1 },
        { header: <DefaultImageComponent/>, token: window.CONFIG.LEAD_CL_TOKEN },
        { header: <DefaultImageComponent/>, token: window.CONFIG.LEAD_CL_TOKEN_1 },
        { header: <DefaultImageComponent/>, token: window.CONFIG.LEAD_CL_TOKEN_2 },
        { header: <DefaultImageComponent/>, token: window.CONFIG.LEAD_CL_TOKEN_3 },
        { header: <DoctorImageComponent/>, token: window.CONFIG.LEAD_CL_TOKEN_4 },
        { header: <Stack sx={{marginBottom: {md: '300px', xs: '0px'}}}></Stack>, token: window.CONFIG.LEAD_CL_TOKEN_5 },
    ];
    
    const header = variantsOfText.find(
        (x): string | undefined => {
            return x.token.find((t): boolean => t.toUpperCase() === (guid?.toUpperCase() || resolveIndexTokenByType()?.token?.toUpperCase()))
        }
    )?.header;
    if(!header){
        return <DefaultImageComponent/>
    }
    return header;
}
