import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { useState, useContext, useEffect } from 'react';
import useFetch from '../../../common/api/UseFetch';
import { formatAmount } from '../../../common/helpers/number-helper';
import { Container } from '../../../common/styles/Layout.css';
import Loader from '../../../components/Loader/Loader';
import { mainTheme } from '../../../theme/mainTheme';
import {
	ButtonTextContainer,
	CreditInfo,
	DocumentSectionElement,
	DownloadExampleDocument,
	HorizontalLine,
} from './CalculatorPage.css';
import {
	CalculatedLoanInfo,
	calculateLoanInfoConfig,
	getLoanConfigConfig,
	saveCalcualtionConfig,
	CalculateLoanQuery,
} from './CalculatorApi';
import CalculatorCreditInfo from './CalculatorCreditInfo';
import Calculator from './Calculator';
import { gridFullWidth } from '../../../theme/grid/GridConstants';
import NestTooltip from '../../../components/NestTooltip/NestTooltip';
import { useNavigate } from 'react-router-dom';
import { IdentityVerificationRoute } from '../../../routing/Routing';
import { ContextSaveApplicationData } from '../../../context/ContextSaveApplicationData';
import arrowDownUnderscore from '../../../common/images/ArrowDownUnderscore.svg';
import CustomButton from '../../../components/CustomButton/CustomButton';
import { GetDownloadFileConfig } from '../../../common/api/FileApi';
import { FileType } from '../../../enums/FileType';
import { ScrollToTopOnMount } from '../../../common/helpers/scroll-to-top-onmount';
import CalculatorBottomInfo from '../../../components/CalculatorBottomInfo/CalculatorBottomInfo';

export default function CalculatorPage(): JSX.Element {
	const [calcConfig, setCalcConfig] = useState<{
		selectedAmount: number;
		selectedPeriod: number;
	}>({
		selectedAmount: 0,
		selectedPeriod: 0,
	});
	const [calculatedLoan, setCalcualtedLoan] = useState<
		CalculatedLoanInfo | undefined
	>(undefined);

	const navigate = useNavigate();

	const calculateLoan = useFetch(
		calculateLoanInfoConfig,
		false,
		(result): void => {
			setCalcualtedLoan(result);
		}
	);
	const getLoanConfig = useFetch(getLoanConfigConfig, true, (result): void => {
		setCalcConfig({
			selectedAmount: result.selectedAmount,
			selectedPeriod: result.selectedPeriod,
		});
		calculateLoan.mutate({
			value: result.selectedAmount,
			period: result.selectedPeriod,
		});
	});
	const contractTemplate = useFetch(
		GetDownloadFileConfig(FileType.ContractTemplate),
		false
	);
	const saveLoanConfig = useFetch(saveCalcualtionConfig, false, (): void => {
		navigate(IdentityVerificationRoute.path);
	});
	const saveLoanConfigDraft = useFetch(saveCalcualtionConfig, false);

	const [isSaveApplicationData] = useContext(ContextSaveApplicationData);

	useEffect((): void => {
		if (isSaveApplicationData) {
			saveDraftLoanConfig();
		}
	}, [isSaveApplicationData]);

	const saveDraftLoanConfig = (): void => {
		const dataToSend: CalculateLoanQuery = {
			value: calcConfig.selectedAmount,
			period: calcConfig.selectedPeriod,
		};
		saveLoanConfigDraft.mutate(dataToSend);
	};

	const onCalculatorChange = (
		creditAmount: number,
		creditPeriod: number
	): void => {
		setCalcConfig({
			selectedAmount: creditAmount,
			selectedPeriod: creditPeriod,
		});
		calculateLoan.mutate({ value: creditAmount, period: creditPeriod });
	};

	if (!calculatedLoan || !getLoanConfig.data) {
		return (
			<Container>
				<Loader />
			</Container>
		);
	}

	return (
		<>
			<Container>
				<ScrollToTopOnMount />
				<Typography
					variant='h2'
					sx={{
						color: mainTheme.palette.text.primary,
						marginBottom: { xs: '20px', md: '48px' },
					}}
				>
					Dopasuj ofertę do swoich potrzeb
				</Typography>
				<Typography
					variant='h8'
					sx={{
						color: mainTheme.palette.text.secondary,
						fontWeight: 600,
					}}
				>
					Po analizie Twojej historii kredytowej możemy zaproponować Ci:
				</Typography>
				<Typography
					variant='h4'
					sx={{ color: mainTheme.palette.text.primary, marginTop: '9px' }}
				>
					{formatAmount(getLoanConfig.data.maxLoanAmount)}{' '}
					<Typography variant='h7'>
						PLN <CreditInfo>brutto kredytu</CreditInfo>{' '}
						<NestTooltip text='Kwota uwzględnia kredytowaną prowizję za udzielenie kredytu' />
					</Typography>
				</Typography>
				<HorizontalLine />
				<Grid container>
					<Grid item xs={gridFullWidth} md={8} sx={{ marginTop: '79px' }}>
						<Calculator
							isLoading={calculateLoan.isPending}
							min={getLoanConfig.data.minLoanAmount}
							max={getLoanConfig.data.maxLoanAmount}
							defaultAmount={getLoanConfig.data.selectedAmount}
							defaultPeriod={getLoanConfig.data.selectedPeriod}
							periods={getLoanConfig.data.periods}
							onChange={([amount, period]): void =>
								onCalculatorChange(amount, period)
							}
							text='Wpisz lub ustaw suwakiem kwotę kredytu'
						/>
					</Grid>
					<Grid item xs={gridFullWidth} md={4}>
						<CalculatorCreditInfo
							data={calculatedLoan}
							firstInstallmentPaymentDate={
								getLoanConfig.data.firstInstallmentPaymentDate
							}
						/>
					</Grid>
				</Grid>
				<Grid
					item
					xs={gridFullWidth}
					sx={{
						marginTop: { xs: '32px', md: '-70px' },
					}}
				>
					<DocumentSectionElement
						onClick={(): void => {
							if (!contractTemplate.isPending) {
								contractTemplate.mutate({} as never);
							}
						}}
					>
						<img
							src={arrowDownUnderscore}
							style={{ marginRight: '9px' }}
							alt='icon'
						/>
						<DownloadExampleDocument>
							Pobierz wzór umowy
						</DownloadExampleDocument>
					</DocumentSectionElement>
				</Grid>
				<ButtonTextContainer
					sx={{
						alignItems: { md: 'flex-end', xs: 'center' },
						margin: { xs: '32px 0 46px 0', md: '117px 0 80px 0' },
					}}
				>
					<Typography
						variant='p3'
						align='center'
						sx={{
							color: 'text.secondary',
							maxWidth: '306px',
							margin: { md: '0 0 24px 0', xs: '0 0 24px 0' },
						}}
					>
						W kolejnym kroku zweryfikujemy Twoją tożsamość.
						<br />
						Przygotuj dane do logowania do konta osobistego.
					</Typography>
					<CustomButton
						isLoading={saveLoanConfig.isPending}
						variant='contained'
						onClick={(): void =>
							saveLoanConfig.mutate({
								value: calcConfig.selectedAmount,
								period: calcConfig.selectedPeriod,
							})
						}
					>
						Dalej
					</CustomButton>
				</ButtonTextContainer>
			</Container>
			<CalculatorBottomInfo />
		</>
	);
}
