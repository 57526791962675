export const FieldNames: {
	formFieldNames: string[];
	messageFieldName: string;
}[] = [
	{ formFieldNames: ['Email'], messageFieldName: 'Adres e-mail' },
	{
		formFieldNames: ['PhoneNumber'],
		messageFieldName: 'Numer telefonu komórkowego',
	},
	{
		formFieldNames: ['Nip'],
		messageFieldName: 'NIP',
	},
	{
		formFieldNames: ['FirstName'],
		messageFieldName: 'Imię',
	},
	{ formFieldNames: ['LastName'], messageFieldName: 'Nazwisko' },
	{ formFieldNames: ['SocialSecurityNumber'], messageFieldName: 'PESEL' },
	{
		formFieldNames: ['IdCardNumber'],
		messageFieldName: 'Seria i nr dowodu osobistego',
	},
	{
		formFieldNames: ['IdCardIssuedDate'],
		messageFieldName: 'Data wydania dowodu osobistego',
	},
	{
		formFieldNames: ['IdCardExpirationDate'],
		messageFieldName: 'Data ważności dowodu osobistego',
	},
	{ formFieldNames: ['CountryOfBirth'], messageFieldName: 'Kraj urodzenia' },
	{
		formFieldNames: ['MothersMaidenName'],
		messageFieldName: 'Nazwisko panieńskie matki',
	},
	{ formFieldNames: ['Address'], messageFieldName: 'Adres zamieszkania' },
	{ formFieldNames: ['CompanyAddress'], messageFieldName: 'Adres firmy' },
	{
		formFieldNames: ['IsCorrespondenceAddressSameAsAddress'],
		messageFieldName: 'Adres korespondencyjny taki sam jak adres zamieszkania',
	},
	{
		formFieldNames: ['IsCompanyCorrespondenceAddressSameAsCompanyAddress'],
		messageFieldName: 'Adres korespondencyjny taki sam jak adres firmy',
	},
	{
		formFieldNames: ['CorrespondenceAddress', 'CompanyCorrespondenceAddress'],
		messageFieldName: 'Adres korespondencyjny',
	},
	{ formFieldNames: ['Street'], messageFieldName: 'Ulica' },
	{ formFieldNames: ['HouseNumber'], messageFieldName: 'Numer domu' },
	{ formFieldNames: ['ApartmentNumber'], messageFieldName: 'Numer mieszkania' },
	{ formFieldNames: ['PostalCode'], messageFieldName: 'Kod pocztowy' },
	{ formFieldNames: ['City'], messageFieldName: 'Miejscowość' },
	{ formFieldNames: ['TaxationForm'], messageFieldName: 'Forma opodatkowania' },
	{
		formFieldNames: ['NumberOfEmployees'],
		messageFieldName: 'Liczba pracowników wraz z właścicielem',
	},
	{ formFieldNames: ['Agreements'], messageFieldName: 'Zgody' },
	{ formFieldNames: ['SmsCode'], messageFieldName: 'Kod SMS' },
];
