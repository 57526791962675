import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import Box from '@mui/material/Box';
import checkImage from '../../../../common/images/check.svg';
import { DescriptionItem } from './OvdEnd.css';
import { grayMainColor, textColorPrimary } from 'src/theme/palette/palette';

export default function OvdEnd(): JSX.Element {
	return (
		<Stack
			sx={{
				minHeight: 'inherit',
				maxWidth: '830px',
				margin: 'auto',
				justifyContent: { xs: 'center', md: 'flex-start' },
			}}
		>
			<Stack
				sx={{
					flex: 0,
					margin: { xs: '0 24px', lg: '0 0' },
				}}
			>
				<Stack
					sx={{
						flexDirection: 'row',
						alignItems: 'center',
						marginBottom: { xs: '32px', md: '53px' },
						marginTop: { xs: '5px', md: '34px' },
					}}
				>
					<Box>
						<img
							src={checkImage}
							alt='Check Image'
							style={{
								alignSelf: 'center',
								marginRight: '16px',
								maxWidth: '48px',
								maxHeight: '48px',
							}}
						/>
					</Box>

					<Typography
						variant='h2'
						sx={{
							color: textColorPrimary,
							fontSize: { xs: '28px', md: '34px' },
							fontWeight: '700',
							lineHeight: { xs: '33px', md: '40px' },
						}}
					>
						Dziękujemy
					</Typography>
				</Stack>
				<Box sx={{ color: grayMainColor }}>
					<Typography
						variant='h7'
						sx={{
							lineHeight: '22px',
							fontWeight: '600',
						}}
					>
						Jeśli załączone dokumenty:
					</Typography>
					<DescriptionItem>- są poprawne otrzymasz maila z potwierdzeniem uruchomienia limitu w koncie,</DescriptionItem>
					<DescriptionItem>- są niepoprawne otrzymasz maila z prośbą o dostarczenie właściwych dokumentów.</DescriptionItem>
				</Box>
			</Stack>
			<Box
				sx={{
					justifyContent: { xs: 'center', sm: 'end' },
					flex: 1,
					display: 'flex',
					margin: { xs: '32px 24px 32px 24px', md: '32px 24px 60px 24px' },
					alignItems: 'end',
				}}
			>
				<Button
					variant='contained'
					onClick={(): void => {
						window.location.href = 'https://nestbank.pl';
					}}
				>
					Zakończ
				</Button>
			</Box>
		</Stack>
	);
}
