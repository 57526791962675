import { useContext } from 'react';
import { formatAmount } from '../../../../../../../common/helpers/number-helper';
import NestCollapse from '../../../../../../../components/NestCollapse/NestCollapse';
import NestTooltip from '../../../../../../../components/NestTooltip/NestTooltip';
import { LightOvdLandingPageContext } from '../../../state/LightOvdLandingPageContext';
import {
	CreditInfoSectionBackgroundImage,
	CreditInfoSectionContainer,
	CreditInfoSectionContainerBackground,
	PersonBackground,
	SpanPln,
} from './LightOvdCreditInfoSection.css';
import Typography from '@mui/material/Typography';
import { collapseContentLandingPageOVD } from './LightOvdCreditInfoSectionConstats';
import PersonBackgroundImgCut from '../../../../../../../common/images/PersonHalfCutBackground.svg';
import { primaryMainColor } from 'src/theme/palette/palette';
import { WiderContainer } from '../../../../../../../common/styles/Layout.css';

const LightOvdCreditInfoSection = (): JSX.Element => {
	const lightOvdLandingPageContext = useContext(LightOvdLandingPageContext);

	return (
		<CreditInfoSectionContainerBackground>
			<CreditInfoSectionContainer>
				<CreditInfoSectionBackgroundImage>
					<PersonBackground src={PersonBackgroundImgCut} alt='peron image' />
				</CreditInfoSectionBackgroundImage>
				<WiderContainer>
					<Typography
						variant='h2'
						sx={{
							fontSize: '14px',
							fontWeight: 600,
							color: primaryMainColor,
							marginBottom: {xs: '7px', md:'24px'},
							lineHeight: '19px',
							marginTop: {xs: '0',md:'15px'}
						}}
					>
						Limit dla jednoosobowej działalności gospodarczej
					</Typography>
					<Typography
						variant='h1'
						sx={{
							color: '#FFFFFF',
							maxWidth: '690px',
							fontSize: { xs: '34px', sm: '48px' },
							lineHeight: { xs: '48px', sm: '57px' },
						}}
					>
						Rozwiń swój biznes!
						<br /> Limit w koncie do{' '}
						<span style={{ whiteSpace: 'nowrap' }}>
							<span
								style={{
									color: '#FC6A46',
								}}
							>
								{' '}
								{formatAmount(lightOvdLandingPageContext.initialData?.maxCreditAmount)}{' '}
								<SpanPln sx={{ fontSize: { md: '40px', xs: '30px' }, lineHeight: '28px' }}>PLN</SpanPln>
							</span>
							<NestTooltip
								text={'Maksymalna kwota limitu. Wysokość finansowania zależy od indywidualnej oceny zdolności kredytowej' + ' wnioskodawcy.'}
								sx={{
									marginLeft: '8px',
									marginBottom: '10px',
									zIndex: '100',
									width: '24px',
									height: '24px',
								}}
								sxTooltipContent={{
									'& .MuiTooltip-tooltip': {
										maxWidth: '396px',
										color: 'black',
										textAlign: 'center',
									},
								}}
							/>
						</span>
						<br /> bez wychodzenia z domu.
					</Typography>
					<NestCollapse
						sx={{ marginBottom: '32px', marginTop: '40px' }}
						title={'Nota prawna'}
						content={collapseContentLandingPageOVD}
						contentTitle={'Nota prawna'}
						sxContent={{ maxWidth: '550px' }}
					/>
				</WiderContainer>
			</CreditInfoSectionContainer>
		</CreditInfoSectionContainerBackground>
	);
};

export default LightOvdCreditInfoSection;
