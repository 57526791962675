/* eslint-disable @typescript-eslint/no-misused-promises */
import Typography from '@mui/material/Typography';
import checkImage from '../../../common/images/check.svg';
import { Container } from '../../../common/styles/Layout.css';
import arrowDownUnderscore from '../../../common/images/ArrowDownUnderscore.svg';
import NewTabFileHandler from '../../../components/NewTabFileHandler/NewTabFileHandler';
import {
	Image,
	LogoWithTitle,
	DocumentSection,
	SmsSigningSecition,
	SmsInputContainer,
	SendButtonContainer,
	DocumentSectionElement,
	SectionSeparation,
	SectionSeparationContaier,
} from './SmsContractSigning.css';
import { ReactNode, useState } from 'react';
import {
	secondaryMainColor,
	textColorSecondary,
} from '../../../theme/palette/palette';
import NestInput from '../../../components/FormItems/NestInput/NestInput';
import { Control, useForm, FormProvider } from 'react-hook-form';
import {
	defaultValues,
	documents,
	submitButtonFileStyles,
} from './SmsContractSigningConstants';
import {
	IDocuments,
	SmsContractDataModel,
} from './SmsContractSigningDataModels';
import {
	GetAgreement,
	GetVerifySmsCode,
	PostSendSms,
} from './SmsContractSigningApi';
import useFetch from '../../../common/api/UseFetch';
import AgreementCheckbox from '../../../components/AgreementCheckbox/AgreementCheckbox';
import Agreement from '../../../models/Agreement';
import { AttachDocumentsRoute } from '../../../routing/Routing';
import { useNavigate } from 'react-router-dom';
import CustomButton from '../../../components/CustomButton/CustomButton';
import { ScrollToTopOnMount } from '../../../common/helpers/scroll-to-top-onmount';

export default function SmsContractSigning(): JSX.Element {
	const navigate = useNavigate();
	const [agreementChecked, setAgreementChecked] = useState(false);
	const [checkboxControl, setCheckboxControl] = useState<boolean[]>([]);
	const [smsSent, setSmsSent] = useState(false);

	const postSendSms = useFetch(PostSendSms, false, (): void =>
		setSmsSent(true)
	);
	const getVerifySmsCode = useFetch(GetVerifySmsCode, false, (): void => {
		navigate(AttachDocumentsRoute.path);
	});
	const agreements = useFetch(GetAgreement, true, (data): void => {
		setCheckboxControl(data.map((): boolean => false));
	});

	const agreementsChange = (checked: boolean, index: number): void => {
		const checkboxControlTemp = [...checkboxControl];
		if (checkboxControlTemp.length) {
			checkboxControlTemp[index] = checked;
			setAgreementChecked(
				checkboxControlTemp.every((element: boolean): boolean => element)
			);
		}
		setCheckboxControl(checkboxControlTemp);
	};

	const methods = useForm<SmsContractDataModel>({
		mode: 'onSubmit',
		reValidateMode: 'onChange',
		defaultValues,
	});

	const sendSms = (): void => {
		postSendSms.mutate(agreements.data ?? []);
	};

	const onSubmit = (data: SmsContractDataModel): void => {
		getVerifySmsCode.mutate(data.smsConfirmationCode);
	};

	return (
		<Container>
			<ScrollToTopOnMount />
			<LogoWithTitle>
				<Image src={checkImage} alt='Check Image' />
				<Typography
					variant='h2'
					sx={{
						fontSize: { xs: '28px', sm: '34px' },
						lineHeight: { xs: '34px', sm: '40px' },
					}}
				>
					Twoja tożsamość została potwierdzona
				</Typography>
			</LogoWithTitle>
			<Typography
				variant='h2'
				sx={{
					fontSize: { xs: '28px', sm: '34px' },
					lineHeight: { xs: '34px', sm: '40px' },
					maxWidth: '675px',
				}}
			>
				Zapoznaj się z treścią umowy kredytowej i podpisz ją kodem SMS
			</Typography>
			<DocumentSection>
				{documents.map(
					(element: IDocuments): ReactNode => (
						<NewTabFileHandler
							fileName={element.fileName}
							fileType={element.fileType}
							buttonStyles={submitButtonFileStyles}
							buttonSubmitText={element.fileName}
						>
							<DocumentSectionElement>
								<img
									src={arrowDownUnderscore}
									style={{ marginRight: '9px' }}
									alt='icon'
								/>
							</DocumentSectionElement>
						</NewTabFileHandler>
					)
				)}
			</DocumentSection>
			{agreements.data?.map(
				(agreement: Agreement, index: number): ReactNode => (
					<AgreementCheckbox
						key={agreement.symbol}
						agreement={agreement}
						onCheckboxChange={(checked: boolean): void => {
							agreementsChange(checked, index);
						}}
						disabled={postSendSms.isPending || getVerifySmsCode.isPending}
					/>
				)
			)}
			<SectionSeparationContaier>
				{agreementChecked && <SectionSeparation />}
			</SectionSeparationContaier>
			<form onSubmit={methods.handleSubmit(onSubmit)}>
				<SmsSigningSecition>
					{!smsSent && (
						<>
							<div></div>
							<SendButtonContainer>
								<Typography
									variant='p3'
									component={'div'}
									sx={{ color: textColorSecondary }}
								>
									Po kliknięciu na przycisk otrzymasz kod SMS, za pomocą którego
									podpiszesz umowę.
								</Typography>
								<CustomButton
									isLoading={postSendSms.isPending}
									sx={{ marginTop: '24px', width: '280px', cursor: 'pointer' }}
									variant='contained'
									onClick={sendSms}
									disabled={!agreementChecked}
								>
									Wyślij kod
								</CustomButton>
							</SendButtonContainer>
						</>
					)}
					{smsSent && agreementChecked && (
						<>
							<SmsInputContainer>
								<div>
									<FormProvider {...methods}>
										<NestInput
											type={'text'}
											label='Kod SMS'
											control={methods.control as unknown as Control}
											name='smsConfirmationCode'
											rules={{
												required: 'Pole wymagane',
											}}
											style={{ maxWidth: '312px' }}
											disabled={
												postSendSms.isPending || getVerifySmsCode.isPending
											}
										/>
									</FormProvider>
									<Typography
										component={'div'}
										sx={{
											color: secondaryMainColor,
											marginTop: '24px',
											cursor: 'pointer',
											fontFamily: 'Athletics, sans-serif',
										}}
										variant='h7'
										onClick={!postSendSms.isPending ? sendSms : undefined}
									>
										Wyślij kod ponownie
									</Typography>
								</div>
							</SmsInputContainer>
							<SendButtonContainer>
								<div></div>
								<CustomButton
									isLoading={getVerifySmsCode.isPending}
									sx={{ marginTop: '24px', width: '280px' }}
									variant='contained'
									type='submit'
									disabled={methods.watch('smsConfirmationCode').length < 4}
								>
									Zawieram umowę
								</CustomButton>
							</SendButtonContainer>
						</>
					)}
				</SmsSigningSecition>
			</form>
		</Container>
	);
}
