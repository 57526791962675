export const collapseContentLandingPageOVD = `<div style='font-size: 12px; text-align: justify;'>
    <p>
        Przedstawiona oferta limitu ma charakter wstępny i nie ma charakteru oferty w rozumieniu art. 66 Kodeksu cywilnego. 
        O BIZnest Limit w Koncie oferowany przez Nest Bank S.A. („Bank”), wnioskować może:
    </p>

    <p>
        - przedsiębiorca, będący osobą fizyczną prowadzącą indywidualną działalność gospodarczą (który prowadzi swoją działalność 
        przez minimum 24 miesiące poprzedzające dzień złożenia wniosku o udzielenie limitu) lub
    </p>

    <p>
        - profesjonalista, będący osobą wykonującą wolny zawód taki jak: lekarz, stomatolog, lekarz weterynarii, adwokat, radca 
        prawny, notariusz lub fizjoterapeuta (aby móc starać się o BIZNest Limit w Koncie, profesjonalista powinien wykonywać wolny 
        zawód przez okres co najmniej 24 miesięcy poprzedzający dzień złożenia wniosku o udzielenie Limitu, przy czym w okresie 
        minimum 12 miesięcy przed datą złożenia wniosku, niezbędnym jest uzyskiwanie dochodu w ramach indywidualnej działalności 
        gospodarczej).
    </p>

    <p>
        Udzielenie limitu oraz jego wysokość uzależnione są od pozytywnej oceny zdolności kredytowej przedsiębiorcy. Maksymalna 
        kwota limitu to 50 tys. PLN, która to kwota uwzględnia kredytowane opłaty i prowizje, o które zostaną pomniejszone środki 
        udostępnione klientowi w ramach rachunku bieżącego (firmowego) w wykonaniu umowy o BIZnest Limit w Koncie. Do udzielenia 
        limitu wymagane jest posiadanie przez klienta rachunku firmowego w Nest Bank S.A.
    </p>

    <p>
        Szczegóły oferty, w tym „Regulamin kredytowania działalności gospodarczej Nest Banku, linia mikro i małych przedsiębiorstw”, 
        „Tabela Opłat i Prowizji dla Klienta biznesowego – oferta kredytowa” oraz „Tabela Oprocentowania dla Klienta biznesowego 
        – oferta kredytowa” dostępne są na <a href="https://www.nestbank.pl" target="_blank">www.nestbank.pl</a>.
    </p>
    
</div>`;
