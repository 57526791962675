import { styled } from '@mui/system';
import { mainTheme } from '../../../../../../../theme/mainTheme';
import { textColorPrimary } from '../../../../../../../theme/palette/palette';
import creditInfoSectionBg from '../../../../../../../common/images/creditInfoSectionBg.svg';

export const CreditInfoSectionContainerBackground = styled('div')({
	width: '100%',
	position: 'relative',
	minHeight: '400px',
	marginTop: '-75px',
	paddingTop: '75px',

	backgroundImage: `url(${creditInfoSectionBg})`,
	backgroundSize: 'cover',
	backgroundPosition: 'left',
	backgroundPositionX: '-350px',
	backgroundRepeat: 'no-repeat',
	
	[mainTheme.breakpoints.down('lg')]: {
		background: textColorPrimary,
		marginTop: '-110px',
		paddingTop: '110px',
	},

	[mainTheme.breakpoints.down('md')]: {
		background: textColorPrimary,
		marginTop: '-124px',
		paddingTop: '110px',
	},

	[mainTheme.breakpoints.up(1600)]: {
		backgroundPositionX: '-450px',
	},
});

export const CreditInfoSectionContainer = styled('div')({
	width: '100%',
});

export const CreditInfoSectionBackgroundImage = styled('div')({
	display: 'none',
	position: 'absolute',
	zIndex: '1',

	[mainTheme.breakpoints.up('lg')]: {
		display: 'block',
		right: '6%',
		bottom: '0',
	},
	[mainTheme.breakpoints.up(1300)]: {
		right: '7%',
	},
	[mainTheme.breakpoints.up(1500)]: {
		right: '8%',
	},
	[mainTheme.breakpoints.up(1600)]: {
		right: '16%',
	},
	[mainTheme.breakpoints.up('xl')]: {
		right: '15%',
	},
	[mainTheme.breakpoints.up(2200)]: {
		right: '18%',
	},
	[mainTheme.breakpoints.up(2400)]: {
		right: '19%',
	},
});

export const CreditPeriodsContainer = styled('div')({
	overflowX: 'auto',
});

export const ButtonContainer = styled('div')({
	display: 'flex',
	justifyContent: 'center',
	marginBottom: '60px',
	[mainTheme.breakpoints.up('sm')]: {
		justifyContent: 'flex-start',
	},
});

export const MagicWordsContainer = styled('div')({
	maxWidth: '390px',
	margin: '0 auto'
});

export const PersonBackground = styled('img')({
	position: 'absolute',
	right: 0,
	bottom: 0,
	zIndex: '-1',
	height: '938px'
})
