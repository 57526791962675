import React from 'react';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { formatAmount } from '../../../../../common/helpers/number-helper';
import { mainTheme } from '../../../../../theme/mainTheme';
import { typography } from '../../../../../theme/typography/typography';
import NestTooltip from 'src/components/NestTooltip/NestTooltip';
import { ICalculatedLimit } from '../OvdCalculatorPage';
import { formatMonthLabel } from 'src/common/helpers/month-label-helper';

interface IOvdCalculatorCreditInfoProps {
	agreementPeriod: number;
	calculatedLimit: ICalculatedLimit;
	commission: number;
	markUp: number;
	wibor: number;
}

const OvdCalculatorCreditInfo = (props: IOvdCalculatorCreditInfoProps): JSX.Element => {
	return (
		<Stack
			sx={{
				backgroundColor: '#F8F9FF',
				marginTop: { xs: '32px', md: '48px' },
				minHeight: '70px',
				flexDirection: { sx: 'column', md: 'row' },
				justifyContent: 'space-evenly', padding: '19px 0'
			}}
		>
			<Stack sx={{width: {xs:'50%',md:'20%'}, marginBottom: {xs:'16px',md:'0'}, marginLeft: {xs:'38px', md:'0'},display: 'flex',justifyContent: 'flex-end'}}>
				<Stack
					sx={{
						flex: '1',
						marginTop: { xs: '16px', md: '0px' },
						height: '100%',
						display: 'flex',
						justifyContent: 'space-between'
					}}
				>
					
					<Typography
						variant='h9'
						sx={{
							color: mainTheme.palette.text.secondary,
							fontWeight: 600,
						}}
					>
						Umowa na
					</Typography>
					<Typography variant='h5'>
						{props.agreementPeriod} {formatMonthLabel(props.agreementPeriod)}
					</Typography>
				</Stack>
			</Stack>
			<Stack sx={{width: {md:'20%'}, marginBottom: {xs:'16px',md:'0'},marginLeft: {xs:'38px', md:'0'} ,display: 'flex',justifyContent: 'flex-end'}}>
				<Stack
					sx={{
						flex: '1',
						maxWidth: '170px',
						height: '100%',
						display: 'flex',
						justifyContent: 'space-between'
					}}
				>
					<Typography
						variant='h9'
						sx={{
							color: mainTheme.palette.text.secondary,
							fontWeight: 600,
						}}
					>
						Wymagane minimalne miesięczne wpływy na konto
					</Typography>
					<Typography variant='h5' >
						{formatAmount(props.calculatedLimit.currencyValue * 0.25)}{' '}
						<span style={{ ...typography.h7, fontFamily: 'Athletics, sans-serif' }}>PLN</span>{' '}
						<NestTooltip
							text='W przypadku limitu w koncie wymagane są średniomiesięczne wpływy na poziomie co najmniej 25% udzielonej kwoty limitu.'
							sxTooltipContent={{ maxWidth: '434px' }}
						/>
					</Typography>
				</Stack>
			</Stack>
			<Stack sx={{minWidth: '180px',width: '20%',alignItems: {xs:'flex-start', md:'flex-end'}, marginBottom: {xs:'16px',md:'0'},marginLeft: {xs:'38px', md:'0'}, display: 'flex',justifyContent: 'flex-end'}}>
				<Stack
					sx={{
						flex: '1',
					}}
				>
					<Typography
						variant='h9'
						sx={{
							color: mainTheme.palette.text.secondary,
							fontWeight: 600,
							maxWidth: '170px',
							height: '100%',
							display: 'flex',
							justifyContent: 'space-between',
						}}
					>
						Prowizja za udzielenie limitu
					</Typography>
					<Typography variant='h5' >{formatAmount(props.commission)}%</Typography>
				</Stack>
			</Stack>
			<Stack sx={{minWidth: '180px', alignItems: {xs:'flex-start', md:'flex-end'}, marginBottom: {xs:'16px',md:'0'}, marginLeft: {xs:'38px', md:'0'},width: '20%', display: 'flex',justifyContent: 'flex-end'}}>
				<Stack
					sx={{
						flex: '1',
						maxWidth: '170px',
						height: '100%',
						display: 'flex',
						justifyContent: 'space-between'
					}}
				>
					<Typography
						variant='h9'
						sx={{
							color: mainTheme.palette.text.secondary,
							fontWeight: 600,
						}}
					>
						Brak prowizji za gotowość
					</Typography>
					<Typography variant='h5'>zawsze 0%</Typography>
				</Stack>
			</Stack>
		</Stack>
	);
};

export default OvdCalculatorCreditInfo;
