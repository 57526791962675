import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { ApplicationProcess } from '../../../../common/constants/application-process';
import { applicationProcessKey, getSessionStorageObj } from '../../../../common/sessionStorage/SessionStorageService';
import { LightIdentityVerificationInProgressRoute, LightOvdIdentityVerificationInProgressRoute } from '../../../../routing/Routing';

const LightIdentityVerificationInProgressNav = (): JSX.Element => {
    const navigate = useNavigate();
    const appProcess: ApplicationProcess | undefined = getSessionStorageObj<ApplicationProcess>(
        applicationProcessKey
    );
    const orderUuid =
              new URLSearchParams(location.search).get('orderUuid') ?? '';

    useEffect((): void => {
        if(appProcess === ApplicationProcess.LightCl){
            navigate(LightIdentityVerificationInProgressRoute.path, {
                state: {orderUuid}
            });
            return;
        }
        if(appProcess === ApplicationProcess.LightOvd){
            navigate(LightOvdIdentityVerificationInProgressRoute.path, {
                state: {orderUuid}
            });
            return;
        }
    }, [appProcess, orderUuid]);
    return (
        <div></div>
    )
}

export default LightIdentityVerificationInProgressNav;
