import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { primaryMainColor } from '../../theme/palette/palette';
import { IdentityVerificationBanksListPropsBankInfo } from 'src/pages/rbf/rbf-identity-verification/IdentityVerificationApi';

interface IdentityVerificationBanksListProps {
	banksList: IdentityVerificationBanksListPropsBankInfo[];
	setBankId: React.Dispatch<React.SetStateAction<string>>;
}
const IdentityVerificationBanksList = (props: IdentityVerificationBanksListProps): JSX.Element => {
	return (
		<Stack sx={{ width: '100%' }}>
			<Stack
				sx={{
					alignItems: 'center',
					display: 'flex',
					flexDirection: 'row',
					flexWrap: 'wrap',
					justifyContent: { xs: 'space-between', sm: 'unset' },
				}}
			>
				{props.banksList.map(
					(x): JSX.Element => (
						<Box
							key={x.id}
							sx={{
								width: { xs: '50%', sm: 'unset' },
								display: 'flex',
								justifyContent: { xs: 'center', sm: 'unset' },
							}}
						>
							<Box
								component='label'
								onClick={(): void => props.setBankId(x.id)}
								sx={{
									display: 'block',
									marginRight: { xs: 'unset', sm: '18px' },
									width: '123px',
									height: '67px',
									marginBottom: '20px',
								}}
							>
								<Box
									component='input'
									type='radio'
									name='bank'
									value={x.id}
									sx={{
										position: 'absolute',
										opacity: '0',
										width: '0',
										height: '0',
										':checked + div': {
											border: `2px solid ${primaryMainColor}`,
										},
									}}
								/>
								<Box
									sx={{
										width: '100%',
										height: '100%',
										border: '1px solid #e4e4ed',
										borderRadius: '8px',
										display: 'inline-flex',
										position: 'relative',
										justifyContent: 'center',
										alignItems: 'center',
										textAlign: 'center',
										cursor: 'pointer',
									}}
								>
									<Typography
										variant='h8'
										sx={{
											fontFamily: 'Athletics, sans-serif',
											padding: '10px',
										}}
									>
										{x.name}
									</Typography>
								</Box>
							</Box>
						</Box>
					)
				)}
			</Stack>
		</Stack>
	);
};

export default IdentityVerificationBanksList;
