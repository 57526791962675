import { styled } from '@mui/system';
import { mainTheme } from '../../../theme/mainTheme';

export const FormMainContainer = styled('div')({
	display: 'flex',
	width: '100%',
	flexDirection: 'column',
	marginBottom: '30px',
});

export const InputsWrapper = styled('div')({
	display: 'flex',
	justifyContent: 'space-between',
	width: '100%',
});

export const InputWrapper = styled('div')({
	width: '100%',
	maxWidth: '380px',
});

export const AgreementContainer = styled('div')({
	maxWidth: '70%',
	[mainTheme.breakpoints.down('md')]: {
		maxWidth: '90%',
	},
	[mainTheme.breakpoints.down('sm')]: {
		maxWidth: '100%',
	},
});

export const SubmitButtonWrapper = styled('div')({
	display: 'flex',
	width: '100%',
	justifyContent: 'flex-end',
	maxWidth: '880px',
	flex: '0',
	margin: '40px 0 60px 0',
});
