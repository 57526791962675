import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { ApplicationProcess } from '../../../common/constants/application-process';
import {
	applicationIdKey,
	applicationProcessKey,
	authTokenKey,
	clientIdKey,
	partnerLogoKey,
	partnerNameKey,
	processIdKey,
	removeSessionStorageValue,
	setSessionStorageValue,
	tokenExpirationKey,
} from '../../../common/sessionStorage/SessionStorageService';
import { Container } from '../../../common/styles/Layout.css';
import Loader from '../../../components/Loader/Loader';
import {
	LandingPageRoute,
	TechnicalErrorRoute,
} from '../../../routing/Routing';
import { GetInitialDataConfig } from './NewApplicationApi';
import useFetch from '../../../common/api/UseFetch';
import { getPartnerLogo } from '../../../common/helpers/get-partner-logo-helper';
import { TechnicalErrorState } from 'src/pages/common/technical-error/TechnicalErrorTypes';

export default function NewApplication(): JSX.Element {
	const navigate = useNavigate();
	let guidNumber: string;

	const navigateToTechnicalError = (): void => {
		const state: TechnicalErrorState = {
			tryAgainPath: window.location.pathname,
		};
		navigate(TechnicalErrorRoute.path, { state });
	};

	const initialDataMutation = useFetch(
		GetInitialDataConfig,
		false,
		(result): void => {
			setSessionStorageValue(applicationIdKey, '');
			setSessionStorageValue(authTokenKey, '');
			result.partnerLogoName && setSessionStorageValue(
				partnerLogoKey,
				getPartnerLogo(result.partnerLogoName)
			);
			setSessionStorageValue(partnerNameKey, result.partnerName);
			setSessionStorageValue(
				applicationProcessKey,
				result.process?.toString() ?? ApplicationProcess.Unknown.toString()
			);
			setSessionStorageValue(processIdKey, result.processId);
			setSessionStorageValue(clientIdKey, result.clientId);
			navigate(LandingPageRoute.path, {
				state: { ...result, internalClientId: guidNumber },
			});
		},
		undefined,
		undefined,
		navigateToTechnicalError,
		false
	);

	useEffect((): void => {
		removeSessionStorageValue(tokenExpirationKey);
		removeSessionStorageValue(applicationIdKey);
		removeSessionStorageValue(authTokenKey);
		removeSessionStorageValue(processIdKey);
		guidNumber = location.hash.slice(1);
		guidNumber
			? initialDataMutation.mutate(guidNumber)
			: navigateToTechnicalError();
	}, []);

	return (
		<Container>
			<Loader />
		</Container>
	);
}
