import { useMediaQuery } from '@mui/material';
import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { EffectCallback, useContext, useEffect, useRef, useState } from 'react';
import ReCAPTCHA from 'react-google-recaptcha-enterprise';
import { Control, FormProvider, useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import useFetch from 'src/common/api/UseFetch';
import { optionalPhoneNumberValidator, phoneNumberValidator } from 'src/common/validators/phoneNumberValidator';
import { AgreementModelToSendLead } from 'src/pages/lead/common/LeadApi';
import { phoneNumberMask, simpleDigitsMask } from '../../../common/helpers/mask-helper';
import { ScrollToTopOnMount } from '../../../common/helpers/scroll-to-top-onmount';
import useSnackbar from '../../../common/snackbar/useSnackbar';
import { nipValidator } from '../../../common/validators/nipValidator';
import { nameValidator } from '../../../common/validators/polishWordsValidator';
import { FeatureFlagContext } from '../../../context/FeatureFlagContext/FeatureFlagContext';
import { FeatureFlagsEnum } from '../../../context/FeatureFlagContext/models/FeatureFlagContextModels';
import { SnackbarType } from '../../../context/SnackbarContext';
import { saveWrittenValues } from '../../../store/slices/leadToLightClDataSlice/LeadToLightClDataSlice';
import { ILeadToLightClDataProperties } from '../../../store/slices/leadToLightClDataSlice/LeadToLightClDataSliceConstants';
import { gridFullWidth, gridHalfWidth } from '../../../theme/grid/GridConstants';
import { secondaryMainColor, violetMainColor } from '../../../theme/palette/palette';
import NestInput from '../../../components/FormItems/NestInput/NestInput';
import { typography } from '../../../theme/typography/typography';
import { resolveIndexTokenByType } from '../../common/index/IndexTokenResolver';
import { LeadAgreements, MediaConsentFormModel } from './LeadAgreements';
import { getLeadAgreementsConfig } from './LeadAgreementsApi';
import { SubmitButtonWrapper } from './LeadForm.css';
import { defaultValues, ILeadForm } from './LeadFormConstants';
import { GetInformationClause } from './LeadFormApi';
import { useNavigate, useParams } from 'react-router-dom';
import { interpolatePath } from 'src/routing/RoutingService';
import { LeadTechnicalErrorRoute, LightNewApplictionRoute } from 'src/routing/Routing';
import CustomButton from 'src/components/CustomButton/CustomButton';
import { NestFormInfo } from 'src/components/FormItems/NestFormInfo/NestFormInfo';

interface ILeadFormProps {
	onSubmit: (data: ILeadForm, agreements: AgreementModelToSendLead[], token: string) => void;
	isLoading: boolean;
	showCaptcha: boolean;
}

const phoneNumberField = 'phoneNumber';
const fieldSymbolMap: { field: keyof ILeadForm; symbols: string[] }[] = [
	{
		field: phoneNumberField,
		symbols: ['ZGO_KOM_MARK_TEL'],
	},
];
const symbolFieldMap: { symbol: string; fields: (keyof ILeadForm)[] }[] = [{ symbol: 'ZGO_KOM_MARK_TEL', fields: [phoneNumberField] }];

const defaultFieldRequried = phoneNumberField;
const displayedSymbols = Array.from(new Set([...fieldSymbolMap.flatMap((x): string[] => x.symbols), ...symbolFieldMap.map((x): string => x.symbol)]));

const contactAgreementSymbol = 'ZGO_KOM_MARK';

const LeadForm = (props: ILeadFormProps): JSX.Element => {
	const featureFlag = useContext(FeatureFlagContext);
	const navigate = useNavigate();
	const recaptchaRef = useRef<ReCAPTCHA | null | string>(null);
	const [token, setToken] = useState('');
	const [validChecks, setValidChecks] = useState<boolean>(true)
	const snackbar = useSnackbar();
	const dispatch = useDispatch();
	const params = useParams();
	const isLargeScreen = useMediaQuery('(min-width:1250px)');
	const methods = useForm<ILeadForm>({
		mode: 'all',
		defaultValues,
	});

	const [mediaAgreements, setMediaAgreements] = useState([] as MediaConsentFormModel[]);
	const [agreementTitle, setAgreementTitle] = useState('');
	const [requiredFields, setRequiredFields] = useState([] as string[]);
	const formValues = methods.watch();

	const getInformationClauseMutation = useFetch(
		GetInformationClause,
		false,
		undefined,
		undefined,
		(): void => {
			navigate(
				interpolatePath(LeadTechnicalErrorRoute.path, {
					guid: params.guid,
				})
			);
		},
		(): void => {
			navigate(
				interpolatePath(LeadTechnicalErrorRoute.path, {
					guid: params.guid,
				})
			);
		},
		false
	);

	const leadAgreementsRequest = useFetch(
		getLeadAgreementsConfig,
		false,
		(res): void => {
			const contactAgreement = res.find((x): boolean => x.symbol === contactAgreementSymbol);

			updateRequired(
				formValues,
				contactAgreement?.mediaConsents
					?.filter((x): boolean => displayedSymbols.includes(x.symbol))
					.map(
						(x): MediaConsentFormModel => ({
							...x,
							isTouched: false,
							isRequired: false,
						})
					) ?? []
			);
			setAgreementTitle(contactAgreement?.content ?? '');
		},
		undefined,
		(): void => {
			navigate(
				interpolatePath(LeadTechnicalErrorRoute.path, {
					guid: params.guid,
				})
			);
		},
		(): void => {
			navigate(
				interpolatePath(LeadTechnicalErrorRoute.path, {
					guid: params.guid,
				})
			);
		},
		false
	);

	useEffect((): void => {
		leadAgreementsRequest.mutate(window.CONFIG.SOURCE_ID as never);
	}, []);

	useEffect((): ReturnType<EffectCallback> => {
		if (props.showCaptcha && featureFlag?.getFeatureFlag(FeatureFlagsEnum.ShowRecaptcha)) {
			const script = document.createElement('script');
			script.src = `https://www.google.com/recaptcha/enterprise.js?render=${window.CONFIG.CAPTCHA_KEY}`;
			script.async = true;
			document.body.appendChild(script);

			const executeRecaptcha = (): void => {
				(window as Window).grecaptcha.enterprise.ready((): void => {
					window.grecaptcha.enterprise.execute(window.CONFIG.CAPTCHA_KEY, { action: 'contact_form' }).then(
						(token: string): void | PromiseLike<void> => {
							recaptchaRef.current = token;
							setToken(recaptchaRef.current);
						},
						(): void => {}
					);
				});
			};

			script.addEventListener('load', executeRecaptcha);

			return (): void => {
				document.body.removeChild(script);
			};
		}
	}, [validChecks]);

	const onSubmit = (data: ILeadForm): void => {
		setAgreementsTouched();
		if (mediaAgreements.find((x): boolean => x.isRequired && !x.isSelected)) {
			setValidChecks(false);
			return;
		} else {
			setValidChecks(true);
		}

		const agreementsToSend = mediaAgreements.map(
			(x): AgreementModelToSendLead => ({
				symbol: x.symbol,
				isSelected: x.isSelected ?? false,
			})
		);

		return props.onSubmit(data, agreementsToSend, token);
	};

	const updateRequired = (formValues: ILeadForm, mediaAgreements: MediaConsentFormModel[]): void => {
		let symbols = [] as string[];
		let fields = [] as string[];
		const selectedSymbols = mediaAgreements.filter((x): boolean => x.isSelected).map((x): string => x.symbol);

		if (!fieldSymbolMap.find((x): boolean => !!formValues[x.field]) && selectedSymbols.length === 0) {
			symbols = [...symbols, ...(fieldSymbolMap.find((x): boolean => x.field === defaultFieldRequried)?.symbols ?? [])];
			fields = [...fields, defaultFieldRequried];
		} else {
			fieldSymbolMap.forEach((element): void => {
				if (formValues[element.field]) {
					symbols = [...symbols, ...element.symbols];
				}
			});

			symbolFieldMap.forEach((element): void => {
				if (selectedSymbols.includes(element.symbol)) {
					fields = [...fields, ...element.fields];
				}
			});
		}

		setTimeout((): void => {
			symbolFieldMap.forEach((element): void => {
				element.fields.forEach((x): void => {
					if (!fields.includes(x) && methods.getFieldState(x).error?.type === 'required') {
						methods.clearErrors(x);
					}
				});
			});

			const mediaAgreementsUpdate = [...mediaAgreements];
			mediaAgreementsUpdate.forEach((element): void => {
				element.isRequired = symbols.includes(element.symbol);
			});
			setMediaAgreements(mediaAgreementsUpdate);
			setRequiredFields(Array.from(new Set(fields)));
		}, 1);
	};

	const onAgreementSelectionChange = (index: number): void => {
		if (mediaAgreements.length - 1 < index) return;

		const updatedAgr = [...mediaAgreements];
		updatedAgr[index].isSelected = !updatedAgr[index].isSelected;
		updatedAgr[index].isTouched = true;

		updateRequired(formValues, updatedAgr);
	};

	useEffect((): void => {
		updateRequired(formValues, mediaAgreements);
	}, [formValues.phoneNumber]);

	const setAgreementsTouched = (): void => {
		const mediaAgreementsUpdate = [...mediaAgreements];
		mediaAgreementsUpdate.forEach((element): void => {
			element.isTouched = true;
		});
		setMediaAgreements(mediaAgreementsUpdate);
		showSnackbar(mediaAgreementsUpdate);
	};

	const showSnackbar = (agreements: MediaConsentFormModel[]): void => {
		const foundNonSelectedAgreement = agreements.find((x): boolean => x.isRequired && !x.isSelected);
		const conditionToShowSnackbar = foundNonSelectedAgreement && !methods.getFieldState(phoneNumberField).invalid;

		if (conditionToShowSnackbar) {
			snackbar(
				'Wyrażenie zgody na dostarczenie informacji promujących usługi lub produkty Banku w rozmowie telefonicznej na podany numer telefonu jest dobrowolne, jednak w celu przyjęcia formularza kontaktu do realizacji wyrażenie tej zgody jest niezbędne. W przypadku niewyrażania zgody na dostarczenie informacji promujących usługi lub produkty Banku za pomocą ww. kanału kontaktu formularz kontaktu nie będzie przyjęty do realizacji.',
				SnackbarType.Error,
				25,
				{ xs: '300px', sm: '400px', md: '700px' }
			);
		}
	};

	const handleClickShowMore = (): void => {
		getInformationClauseMutation.mutate(window.CONFIG.SOURCE_ID);
	};

	const shouldShowRedirectionToLight = (): boolean => {
		return window.CONFIG.LEAD_CL_TOKEN_3[0].toUpperCase() === (params.guid?.toUpperCase() || resolveIndexTokenByType()?.token?.toUpperCase())
	};

	function navigateToLightCl(): void {
		const writtenValues: ILeadToLightClDataProperties = {
			phoneNumber: methods.getValues('phoneNumber'),
			nip: methods.getValues('nip') as string,
			firstName: methods.getValues('firstName') as string,
			lastName: methods.getValues('lastName') as string,
		}
		dispatch(saveWrittenValues(writtenValues));
		navigate(interpolatePath(LightNewApplictionRoute.path, { processId: window.CONFIG.LCL_TOKEN[0] }));
	}

	return (
		<Stack
			sx={{
				width: { xs: 'unset', md: '50%' },
				padding: { xs: '48px 16px 0 16px', md: '32px 24px 0 24px' },
				background: '#F8F9FF',
				alignItems: { xs: 'center', md: 'start' },
			}}
		>
			<Stack sx={{ maxWidth: '800px' }}>
				<Typography sx={{ wordWrap: 'break-word' }}>
					<Typography component='span' variant='h4' sx={{ padding: '0 4px 0 0  ' }}>
						Wypełnij poniższy formularz,
					</Typography>
					<Typography component='span' variant='h4' sx={{ color: '#FC7D5D', padding: '0 4px 0 0', display: 'inline-block', flexWrap: 'nowrap' }}>
						a my
					</Typography>
					<Typography component='span' variant='h4' sx={{ color: '#FC7D5D', padding: '0 4px 0 0' }}>
						oddzwonimy ze szczegółami
					</Typography>
				</Typography>

				<FormProvider {...methods}>
					<form
						onSubmit={methods.handleSubmit(onSubmit, (): void => {
							setAgreementsTouched();
						})}
					>
						<Stack>
							<ScrollToTopOnMount />

							<Grid container rowSpacing={2} columnSpacing={2} 
							  	sx={{
									justifyContent: {md: 'space-between', xs: 'center'},
									marginTop: '16px', marginBottom: '48px',
								}}
							>
								<Grid item xs={gridFullWidth} md={gridHalfWidth} sx={{width: '100%'}}>
									<NestInput
										label='Imię'
										control={methods.control as unknown as Control}
										name='firstName'
										wrapperSx={{ width: '100%' }}
										rules={{
											minLength: {
												value: 2,
												message: 'Niepoprawna wartość',
											},
											required: 'Pole wymagane',
											pattern: {
												value: nameValidator,
												message: 'Niepoprawna wartość',
											},
										}}
									/>
								</Grid>
								<Grid item xs={gridFullWidth} md={gridHalfWidth} sx={{ width: '100%'}}>
									<NestInput
										label='Nazwisko'
										control={methods.control as unknown as Control}
										name='lastName'
										wrapperSx={{ width: '100%' }}
										rules={{
											minLength: {
												value: 2,
												message: 'Niepoprawna wartość',
											},
											required: 'Pole wymagane',
											pattern: {
												value: nameValidator,
												message: 'Niepoprawna wartość',
											},
										}}
									/>
								</Grid>
								<Grid item xs={gridFullWidth} md={gridHalfWidth} sx={{ width: '100%'}}>
									<NestInput
										label='NIP'
										control={methods.control as unknown as Control}
										name='nip'
										wrapperSx={{ width: '100%' }}
										maskFunc={simpleDigitsMask}
										mask='### ### ## ##'
										rules={{
											validate: nipValidator,
											required: 'Pole wymagane',
										}}
									/>
								</Grid>
								<Grid item xs={gridFullWidth} md={gridHalfWidth} sx={{ width: '100%'}}>
									<NestInput
										label='Numer telefonu komórkowego'
										control={methods.control as unknown as Control}
										rules={requiredFields.includes(phoneNumberField) ? phoneNumberValidator : optionalPhoneNumberValidator}
										name={phoneNumberField}
										wrapperSx={{ width: '100%' }}
										maskFunc={phoneNumberMask}
										mask=''
										prefix='+48&nbsp;'
									/>
								</Grid>
							</Grid>

							<LeadAgreements agreements={mediaAgreements} title={agreementTitle} onAgreementSelectionChange={onAgreementSelectionChange} />

							<Typography variant='p2' sx={{ margin: '0 0 24px 0', textAlign: 'justify' }}>
								Zgoda ta może być odwołana w każdej chwili bez podania przyczyny, bez wpływu na zgodność z prawem działań, które wykonano na podstawie
								zgody przed jej odwołaniem.
							</Typography>
							<Typography variant='p2' sx={{ marginBottom: '8px', textAlign: 'justify' }}>
								Administratorem danych osobowych jest Nest Bank S.A. z siedzibą w 02-675 Warszawa ul. Wołoska 24. Dane będą przetwarzane w
								celach marketingowych w tym celu przedstawienia oferty.
							</Typography>
							<Typography
								variant='h8'
								onClick={handleClickShowMore}
								sx={{ color: secondaryMainColor, cursor: 'pointer', fontFamily: 'Athletics, sans-serif' }}
							>
								Zobacz więcej
							</Typography>
						</Stack>
						<SubmitButtonWrapper sx={{ justifyContent: { md: 'flex-end', xs: 'center' } }}>
							<Stack
								sx={{
									flexDirection: 'column',
									marginRight: {xs: '0',md: '16px'}
								}}
							>
								{(Object.keys(methods.formState.errors).length > 0 || !validChecks) && (
									<NestFormInfo />
								)}
								<CustomButton variant='contained' type='submit' isLoading={props.isLoading}>
									Zamawiam kontakt
								</CustomButton>
							</Stack>
						</SubmitButtonWrapper>
					</form>
				</FormProvider>
				{shouldShowRedirectionToLight() && (
					<Stack sx={{
						height: isLargeScreen ? '138px' : 'auto',
						padding: isLargeScreen ? '0 16px' : '16px',
						width: '100%',
						borderRadius: '16px',
						maxWidth: '-webkit-fill-available',
						display: 'flex',
						flexDirection: isLargeScreen ? 'row' : 'column',
						justifyContent: 'space-between',
						alignItems: 'center',
						background: violetMainColor,
						marginBottom: '48px'
					}}>
						<Stack>
							<Typography variant="h4" sx={{margin: isLargeScreen ? '0' : '0px 0px 24px' }}>
								Chcesz złożyć wniosek<br/>
								do 50 000 zł?<br/>
								<span style={{color: secondaryMainColor}}>Możesz to zrobić samodzielnie.</span>
							</Typography>
						</Stack>
						<Stack>
							<CustomButton
								variant="primaryButton"
								style={{...typography.p1, fontWeight: '700', fontFamily: 'Athletics, sans-serif'}}
								onClick={(): void => navigateToLightCl()
							}>
								Składam wniosek
							</CustomButton>
						</Stack>
					</Stack>
				)}
			</Stack>
		</Stack>
	);
};

export default LeadForm;
