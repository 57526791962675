import { ApiConfig } from '../../../../common/api/FetchTypes';
import Agreement from '../../../../models/Agreement';
import BaseFetch from '../../../../common/api/BaseFetch';

const getAgreementKey = 'getAgreement';

function getAgreement(processId: string): Promise<Agreement[]> {
	return BaseFetch({
		url: 'Agreement/' + processId,
		method: 'GET',
	});
}

export const GetAgreement: ApiConfig<Agreement[], string> = {
	key: getAgreementKey,
	fn: getAgreement,
};
