/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
import Grid from '@mui/material/Grid';
import { blockWritingCurrencyMoreThanOneBilliard } from '../../../../../../common/helpers/block-writing-keys-helper';
import NestInput from '../../../../../../components/FormItems/NestInput/NestInput';
import {
	gridFullWidth,
	gridHalfWidth,
} from '../../../../../../theme/grid/GridConstants';
import { Control, UseFormReturn } from 'react-hook-form';
import React from 'react';
import { otherInitObj, TypeOfProperty } from '../ClientWealthFormConstants';
import Typography from '@mui/material/Typography';
import ClientWealthAddDeleteButtons from '../ClientWealthAddDeleteButtons/ClientWealthAddDeleteButtons';
import {
	ClientWealthAppend,
	ClientWealthRemove,
	IClientWealthFormErrors,
	IClientWealthOther,
} from '../../../PersonalDataModels';
import { polishWordsAndDashValidator } from '../../../../../../common/validators/polishWordsValidator';
import { amountDynamicMask } from 'src/common/helpers/mask-helper';

interface ClientWealthOtherProps {
	methods: UseFormReturn<Record<string, unknown>>;
	index: number;
	append: ClientWealthAppend<IClientWealthOther>;
	remove: ClientWealthRemove<IClientWealthOther>;
}

const ClientWealthOther = (props: ClientWealthOtherProps): JSX.Element => {
	const watchClientWealth: IClientWealthOther[] = props.methods.watch(
		`clientWealth[${props.index}].name`
	);

	const clientWealthErrors = (
		props.methods.formState.errors.clientWealth as unknown as
			| IClientWealthFormErrors[]
			| undefined
	)?.[props.index]?.name;

	return (
		<>
			<Typography variant='h5' sx={{ width: '100%', marginBottom: '24px' }}>
				Inne
			</Typography>
			{watchClientWealth.map(
				(item: IClientWealthOther, index: number): JSX.Element => {
					return (
						<div style={{ width: '100%', marginBottom: '24px' }} key={item.id}>
							<Grid container rowSpacing={4} columnSpacing={9}>
								<Grid item xs={gridFullWidth} md={gridHalfWidth}>
									<NestInput
										label='Nazwa'
										control={props.methods.control as unknown as Control}
										name={`clientWealth[${props.index}].name[${index}].name`}
										errors={clientWealthErrors?.[index]?.name as {type: string; message: string}}
										rules={{
											required: 'Pole wymagane',
											pattern: {
												value: polishWordsAndDashValidator,
												message: 'Niepoprawna wartość',
											},
										}}
									/>
								</Grid>
								<Grid item xs={gridFullWidth} md={gridHalfWidth}>
									<NestInput
										label='Wartość'
										control={props.methods.control as unknown as Control}
										name={`clientWealth[${props.index}].name[${index}].worth`}
										errors={clientWealthErrors?.[index]?.worth as {type: string; message: string}}
										rules={{
											required: 'Pole wymagane',
											min: {value: 1, message: 'Wartość powinna być większa niż 0'}
										}}
										maskFunc={amountDynamicMask}
										mask=''
										blockWritingSomeValueFunc={blockWritingCurrencyMoreThanOneBilliard}
										type='text'
									/>
								</Grid>
							</Grid>
							<ClientWealthAddDeleteButtons
								append={(): void =>
									props.append(
										`clientWealth[${props.index}].name`,
										otherInitObj,
										watchClientWealth
									)
								}
								remove={(): void =>
									props.remove(
										`clientWealth[${props.index}].name`,
										item.id,
										TypeOfProperty.Other,
										watchClientWealth
									)
								}
							/>
						</div>
					);
				}
			)}
		</>
	);
};

export default ClientWealthOther;
