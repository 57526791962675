import { styled } from '@mui/system';

export const ButtonContainer = styled('div')({
	display: 'flex',
	alignItems: 'center',
	cursor: 'pointer',
	marginTop: '28px',
});

export const CircleContainer = styled('div')({
	marginRight: '10px',
	display: 'flex',
	alignItems: 'center',
});

export const ButtonsContainer = styled('div')({
	display: 'flex',
	alignItems: 'center',
	justifyContent: 'space-between',
});
