import BaseFetch from '../../../common/api/BaseFetch';
import { ApiConfig } from '../../../common/api/FetchTypes';

const getInformationClauseKey = 'getInformationClause';

function getInformationClause(sourceId: string): Promise<void> {
	return BaseFetch({
		url: `Lead/InformationClauseFile/${sourceId}`,
		method: 'GET',
		customHeaders: {
			Origin: window.location.hostname,
		},
		downloadFile: true,
	});
}

export const GetInformationClause: ApiConfig<void, string> = {
	key: getInformationClauseKey,
	fn: getInformationClause,
};
