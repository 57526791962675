import BaseFetch from 'src/common/api/BaseFetch';
import { ApiConfig } from 'src/common/api/FetchTypes';
import { AgreementModel } from 'src/components/AgreementCollection/AgreementsModels';

const getLeadAgreementsKey = 'leadAgreements';

function getLeadAgreements(sourceId: string): Promise<AgreementModel[]> {
	return BaseFetch({
		url: `Consents/${sourceId}`,
		method: 'GET',
		apiRoot: window.CONFIG.API_ROOT_CLIENT_FORMS
	});
}

export const getLeadAgreementsConfig: ApiConfig<AgreementModel[], never> = {
	key: getLeadAgreementsKey,
	fn: getLeadAgreements,
};
