import Typography from '@mui/material/Typography';

export const NestFormInfo = (): JSX.Element => {
	return (
		<Typography
			variant='h8'
			sx={{
				color: 'error.main',
				marginBottom: '16px',
				textAlign: 'center',
			}}
		>
			Uzupełnij wymagane dane
		</Typography>
	);
};
