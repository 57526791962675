export interface ILeadForm {
	phoneNumber: string;
	firstName?: string;
	nip?: string;
	lastName?: string;
}

export const defaultValues: ILeadForm = {
	phoneNumber: '',
	nip: '',
	firstName: '',
	lastName: '',
};

export const agreementsArr = [
	{
		isSelected: false,
		mediaConsents: [],
		symbol: 'ZGO_ODPOWIEDZIALNOSC_KARNA_BEZMALZ',
		section: 'L1_KLIENT',
		sectionName: 'Akceptuję zgody i oświadczenia niezbędne do złożenia wniosku.',
		abbreviatedContent: 'Mam świadomość odpowiedzialności karnej za składanie fałszywych oświadczeń',
		content:
			'Jestem świadomy/a odpowiedzialności karnej za składanie dokumentów poświadczających nieprawdę lub nierzetelnych pisemnych oświadczeń. Wszystkie dane, które przekazuję, są prawdziwe i zostały przeze mnie podane dobrowolnie. \nUpoważniam Bank do sprawdzenia podanych przeze mnie informacji, w tym w podmiotach, które ich udzieliły na piśmie, a także do ujawnienia informacji, które stanowią tajemnicę bankową w zakresie niezbędnym do tej weryfikacji. Weryfikacja może się odbywać przy pomocy podmiotów współpracujących z Bankiem na podstawie zawartej umowy. \nZostałem/-am poinformowany/-a, że Bank zastrzega sobie prawo do żądania dodatkowych dotyczących mnie lub mojej firmy dokumentów i/lub informacji. Bank jest uprawniony do zatrzymania przedłożonych przeze mnie dokumentów w związku z ubieganiem się o kredyt i przeprowadzeniem analizy kredytowej oraz do odmówienia udzielenia kredytu.',
		contentHtml: '',
		isRequired: true,
		order: 1,
	},
	{
		isSelected: false,
		mediaConsents: [
			{
				isSelected: false,
				symbol: 'ZGO_KOM_MARK_SMS_MMS',
				content: 'za pośrednictwem wiadomości SMS/MMS na podany przeze mnie numer telefonu',
				isRequired: false,
				order: 1,
			},
			{
				isSelected: false,
				symbol: 'ZGO_KOM_MARK_TEL',
				content: 'w  rozmowie telefonicznej na podany przeze mnie numer telefonu',
				isRequired: false,
				order: 2,
			},
			{
				isSelected: false,
				symbol: 'ZGO_KOM_MARK_EMAIL',
				content: 'za pośrednictwem poczty elektronicznej na podany przeze mnie adres e-mail',
				isRequired: false,
				order: 3,
			},
			{
				isSelected: false,
				symbol: 'ZGO_KOM_MARK_SERWIS_APKA',
				content: 'za pośrednictwem bankowości internetowej i aplikacji mobilnej',
				isRequired: false,
				order: 4,
			},
		],
		symbol: 'ZGO_KOM_MARK',
		section: 'L1_KONTAKT',
		sectionName: 'Wyrażam zgodę na to, aby Nest Bank kontaktował się ze mną.',
		abbreviatedContent: 'Zgadzam się, aby Nest Bank S.A. dostarczał mi informacje promujące usługi lub produkty banku wszystkimi kanałami kontaktu.',
		content: 'Zgadzam się, aby Nest Bank S.A. dostarczał mi informacje promujące usługi lub produkty Banku:',
		contentHtml: '',
		isRequired: false,
		order: 1,
	},
	{
		isSelected: false,
		mediaConsents: [
			{
				isSelected: false,
				symbol: 'ZGO_KOM_MARK_FAKT_SMS_MMS',
				content: 'za pośrednictwem wiadomości SMS/MMS na podany przeze mnie numer telefonu',
				isRequired: false,
				order: 1,
			},
			{
				isSelected: false,
				symbol: 'ZGO_KOM_MARK_FAKT_TEL',
				content: 'w  rozmowie telefonicznej na podany przeze mnie numer telefonu',
				isRequired: false,
				order: 2,
			},
			{
				isSelected: false,
				symbol: 'ZGO_KOM_MARK_FAKT_EMAIL',
				content: 'za pośrednictwem poczty elektronicznej na podany przeze mnie adres e-mail',
				isRequired: false,
				order: 3,
			},
			{
				isSelected: false,
				symbol: 'ZGO_KOM_MARK_FAKT_SERWIS_APKA',
				content: 'za pośrednictwem bankowości internetowej i aplikacji mobilnej',
				isRequired: false,
				order: 4,
			},
		],
		symbol: 'ZGO_KOM_MARK_FAKT',
		section: 'L1_KONTAKT',
		sectionName: 'Wyrażam zgodę na to, aby Nest Bank kontaktował się ze mną.',
		abbreviatedContent:
			'Zgadzam się, aby Nest Bank S.A. dostarczał mi informacje promujące usługi lub produkty Faktoria Sp. z o.o. wszystkimi kanałami kontaktu.',
		content: 'Zgadzam się, aby Nest Bank S.A. dostarczał mi informacje promujące usługi lub produkty Faktoria Sp. z o.o.:',
		contentHtml: '',
		isRequired: false,
		order: 1,
	},
	{
		isSelected: false,
		mediaConsents: [],
		symbol: 'ZGO_REJ_DLUGOW',
		section: 'L1_FIRMA',
		sectionName: 'Wyrażam zgodę na to, aby Nest Bank mógł poznać sytuację mojej firmy, żeby określić kwotę dostępnego kredytu.',
		abbreviatedContent: 'Zgadzam się na ujawnienie informacji z biur informacji gospodarczej dotyczących moich zobowiązań',
		content:
			'Upoważniam Nest Bank S.A. z siedzibą w Warszawie do wystąpienia za pośrednictwem Biura Informacji Kredytowej S.A. o ujawnienie informacji gospodarczych dotyczących moich zobowiązań: \r\n- do biur informacji gospodarczej, lub bezpośrednio \r\n- do Biura Informacji Gospodarczej InfoMonitor S.A oraz do Krajowego Rejestru Długów Biura Informacji Gospodarczej S.A. \r\nna podstawie zawartych przez Bank umów z tymi podmiotami. Zgodę mogę odwołać w każdej chwili, bez podania przyczyny.',
		contentHtml: '',
		isRequired: true,
		order: 1,
	},
	{
		isSelected: false,
		mediaConsents: [],
		symbol: 'ZGO_KOM_MARK_FAKT_NEW',
		section: 'L1_KONTAKT',
		sectionName: 'Wyrażam zgodę na to, aby Nest Bank kontaktował się ze mną.',
		abbreviatedContent:
			'[REJESTR] Zgadzam się, aby Nest Bank S.A. dostarczał mi informacje promujące usługi lub produkty banku wszystkimi kanałami kontaktu.',
		content:
			'[REJESTR] Zgadzam się, aby Nest Bank S.A. udostępnił moje dane (imię i nazwisko, numer telefonu lub adres e-mail) Faktorii Sp. z o.o. w celu przedstawienia mi przez Faktorię sp. z o.o. informacji promujących swoje usługi lub produkty:',
		contentHtml: '',
		isRequired: false,
		order: 1,
	},
	{
		isSelected: false,
		mediaConsents: [],
		symbol: 'ZGO_ZGODA_ODPYTANIE_FAKTORING',
		section: 'L1_FIRMA',
		sectionName: 'Wyrażam zgodę na to, aby Nest Bank mógł poznać sytuację mojej firmy, żeby określić kwotę dostępnego kredytu.',
		abbreviatedContent: 'Wnioski i umowy zawarte z Faktoria Sp. z o.o.',
		content:
			'Wyrażam zgodę na to, aby Bank uzyskał odpowiedź od spółki Faktoria Sp. z o.o., czy byłem/jestem klientem lub wnioskowałem o produkty Faktorii. Jeśli ta okoliczność zostanie potwierdzona, to wyrażam zgodę na udostępnienie Bankowi przez Spółkę moich danych, w tym:\r\n- danych osobowych, \r\n- danych, które dotyczą złożonych przeze mnie wniosków produktowych oraz\r\n- umów produktowych zawartych z Faktorią, danych nabywanych wierzytelności oraz historii ich spłat.\r\nCelem odpytania Faktorii jest ocena mojej zdolności kredytowej i ryzyka kredytowego dla produktów Banku. Mogę odwołać tę zgodę w każdym czasie bez podania przyczyny, co nie wpływa na zgodność z prawem przetwarzania danych przed odwołaniem zgody.',
		contentHtml: '',
		isRequired: true,
		order: 2,
	},
	{
		isSelected: false,
		mediaConsents: [],
		symbol: 'ZGO_UTRZYMANIE_OBROTOW',
		section: 'L1_KLIENT',
		sectionName: 'Akceptuję zgody i oświadczenia niezbędne do złożenia wniosku.',
		abbreviatedContent: 'Utrzymanie poziomu obrotów w okresie kredytowania',
		content:
			'Oświadczam, że w okresie ostatnich 12 miesięcy kalendarzowych osiągnąłem/am średniomiesięczny przychód w wysokości co najmniej\r\n%kwotazsilnika złotych oraz oświadczam, że w całym okresie kredytowania nie prognozuje pogorszenia sytuacji\r\nfinansowej mojej firmy.',
		contentHtml: '',
		isRequired: true,
		order: 2,
	},
	{
		isSelected: false,
		mediaConsents: [],
		symbol: 'ZGO_ZGODA_OFERTOWANIE_FAKTORING',
		section: 'L1_KONTAKT',
		sectionName: 'Wyrażam zgodę na to, aby Nest Bank kontaktował się ze mną.',
		abbreviatedContent: 'Zgoda na przekazanie do Faktoria Sp. z o.o. informacji objętych tajemnicą bankową',
		content:
			'Upoważniam Bank do przekazania spółce Faktoria Sp. z o.o. z siedzibą w Warszawie, nr KRS 0000656462, wszelkich informacji, które są objęte tajemnicą bankową i które dotyczą:\n- zawartych z Bankiem umów;\n- wynikających z tych umów zobowiązań i historii ich spłat; \n-  oceny zdolności kredytowej.\nUdzielam tego upoważnienia na czas nieokreślony i mogę je w każdej chwili odwołać.',
		contentHtml: '',
		isRequired: false,
		order: 2,
	},
	{
		isSelected: false,
		mediaConsents: [],
		symbol: 'ZGO_BM_TOZSAMOSC',
		section: 'L1_KLIENT',
		sectionName: 'Akceptuję zgody i oświadczenia niezbędne do złożenia wniosku.',
		abbreviatedContent: ' Weryfikacja tożsamości przez Blue Media',
		content:
			'Wyrażam zgodę na udostępnienie przez Blue Media S.A. do Nest Bank S.A. z siedzibą w Warszawie (dalej: Bank) danych objętych historią transakcji z rachunku posiadanego przeze mnie w innym banku (w tym danych objętych tajemnicą płatniczą oraz danych osobowych dotyczących ewentualnego współposiadacza rachunku płatniczego) w celu potwierdzenia mojej tożsamości.',
		contentHtml: '',
		isRequired: true,
		order: 3,
	},
	{
		isSelected: false,
		mediaConsents: [],
		symbol: 'ZGO_KRUS_ZUS_US',
		section: 'L1_FIRMA',
		sectionName: 'Wyrażam zgodę na to, aby Nest Bank mógł poznać sytuację mojej firmy, żeby określić kwotę dostępnego kredytu.',
		abbreviatedContent: 'Brak zaległości w płatnościach wobec ZUS/KRUS lub US',
		content: 'Nie zalegam i moja firma nie zalega w płatnościach wobec ZUS/KRUS lub US oraz jednostek samorządu terytorialnego.',
		contentHtml: '',
		isRequired: true,
		order: 3,
	},
	{
		isSelected: false,
		mediaConsents: [],
		symbol: 'ZGO_SYTUACJA_PRAWNA',
		section: 'L1_FIRMA',
		sectionName: 'Wyrażam zgodę na to, aby Nest Bank mógł poznać sytuację mojej firmy, żeby określić kwotę dostępnego kredytu.',
		abbreviatedContent: 'Sytuacja prawna dotycząca mnie i mojej firmy',
		content:
			'Na dzień złożenia wniosku w stosunku do mnie lub mojej firmy nie złożono wniosku oraz nie wszczęto postępowania upadłościowego, restrukturyzacyjnego, naprawczego, likwidacyjnego i układowego oraz nie toczy się postępowanie sądowe, administracyjne lub skarbowe. Oświadczam też, że nie zostały podjęte czynności zabezpieczające roszczenia oraz nie zostały wydane tytuły egzekucyjne, a do mojego majątku lub majątku mojej firmy nie została skierowana egzekucja.',
		contentHtml: '',
		isRequired: true,
		order: 4,
	},
	{
		isSelected: false,
		mediaConsents: [],
		symbol: 'ZGO_BM_REGULAMIN',
		section: 'L1_KLIENT',
		sectionName: 'Akceptuję zgody i oświadczenia niezbędne do złożenia wniosku.',
		abbreviatedContent: ' Akceptacja regulaminu Blue Media',
		content:
			'Oświadczam, że akceptuję Regulamin usługi dostępu do skonsolidowanych informacji o rachunku oraz Politykę prywatności Blue Media S.A. Zgadzam się na świadczenie przez Blue Media S.A. usługi dostępu do skonsolidowanych informacji o rachunku posiadanym przeze mnie w innym banku oraz pobranie historii transakcji z rachunku za okres ostatnich 365 dni.',
		contentHtml: '',
		isRequired: true,
		order: 4,
	},
	{
		isSelected: false,
		mediaConsents: [],
		symbol: 'ZGO_PRZETW_MARK_FAKT',
		section: 'L1_KONTAKT',
		sectionName: 'Wyrażam zgodę na to, aby Nest Bank kontaktował się ze mną.',
		abbreviatedContent:
			'Zgadzam się na przetwarzanie danych osobowych przez Faktoria Sp. z o.o. (grupa kapitałowa Nest Bank S.A.) w celach marketingu produktów i usług własnych ',
		content:
			'Zgadzam się na przetwarzanie moich danych osobowych przez Faktoria Sp. z o.o. z siedzibą w Warszawie („Faktoria”) w celach marketingu produktów i usług własnych Faktorii. Działania marketingowe będzie prowadziła Faktoria lub w imieniu Faktorii będzie prowadził je Nest Bank S.A. Zgoda ta może zostać odwołana w każdej chwili bez podania przyczyny, bez wpływu na zgodność z prawem przetwarzania, którego dokonano na podstawie zgody przed jej odwołaniem. Administratorem danych jest Faktoria Sp. z o.o., szczegóły dotyczące zasad przetwarzania danych znajdują się w klauzuli informacyjnej https://nestbank.pl/assets/gen/klauzula-informacyjna-faktoria-sp-z-o-o.pdf.',
		contentHtml: '',
		isRequired: false,
		order: 4,
	},
	{
		isSelected: false,
		mediaConsents: [],
		symbol: 'ZGO_BM_MARKETING',
		section: 'L1_KONTAKT',
		sectionName: 'Wyrażam zgodę na to, aby Nest Bank kontaktował się ze mną.',
		abbreviatedContent: ' Marketing usług i produktów własnych Banku na podstawie danych z Blue Media',
		content:
			'Wyrażam zgodę na udostępnienie przez Blue Media S.A. do Nest Bank S.A. z siedzibą w Warszawie (dalej: Bank) danych objętych historią transakcji z rachunku posiadanego przeze mnie w innym banku (w tym danych objętych tajemnicą płatniczą oraz danych osobowych dotyczących ewentualnego współposiadacza rachunku płatniczego) w celu marketingu usług i produktów własnych Banku, w tym z wykorzystaniem profilowania. Zgoda może być w każdym czasie odwołana bez podania przyczyny, co nie wpływa na zgodność z prawem przetwarzania danych przed odwołaniem zgody.',
		contentHtml: '',
		isRequired: false,
		order: 5,
	},
	{
		isSelected: false,
		mediaConsents: [],
		symbol: 'ZGO_POWIAZANIA_NEST_BANK',
		section: 'L1_FIRMA',
		sectionName: 'Wyrażam zgodę na to, aby Nest Bank mógł poznać sytuację mojej firmy, żeby określić kwotę dostępnego kredytu.',
		abbreviatedContent: 'Powiązania z akcjonariuszami lub pracownikami banku',
		content: 'Nie jestem powiązany osobowo, kapitałowo, organizacyjnie z akcjonariuszami lub pracownikami Banku.',
		contentHtml: '',
		isRequired: true,
		order: 8,
	},
	{
		isSelected: false,
		mediaConsents: [],
		symbol: 'ZGO_BENEFICJENCI',
		section: 'L1_FIRMA',
		sectionName: 'Wyrażam zgodę na to, aby Nest Bank mógł poznać sytuację mojej firmy, żeby określić kwotę dostępnego kredytu.',
		abbreviatedContent: 'Osobiście sprawuję kontrolę nad moją firmą (tzn. jestem jedynym beneficjentem rzeczywistym)',
		content: 'Osobiście sprawuję kontrolę nad moją firmą (tzn. jestem jedynym beneficjentem rzeczywistym*).',
		contentHtml: '',
		isRequired: false,
		order: 9,
	},
	{
		isSelected: false,
		mediaConsents: [],
		symbol: 'ZGO_STANOWISKO_POLITYCZNE',
		section: 'L1_FIRMA',
		sectionName: 'Wyrażam zgodę na to, aby Nest Bank mógł poznać sytuację mojej firmy, żeby określić kwotę dostępnego kredytu.',
		abbreviatedContent: 'Zajmowane eksponowane stanowiska polityczne',
		content: 'Nie jestem osobą zajmującą eksponowane stanowisko polityczne lub członkiem jej rodziny, lub jej bliskim współpracownikiem.',
		contentHtml: '',
		isRequired: true,
		order: 10,
	},
];
