import { styled } from '@mui/system';
import { mainTheme } from 'src/theme/mainTheme';
import { CSSProperties } from '@mui/styles';

export const Container = styled('div')<{ customBackground?: boolean }>(
	(props: { customBackground?: boolean }): CSSProperties => ({
		maxWidth: '100%',
		backgroundColor: props.customBackground ? '#f8f9ff' : 'inherit',
		borderRadius: '8px',
		padding: props.customBackground ? '24px 24px 0' : '0',
		marginTop: '12px',
	})
);

export const HeaderContainer = styled('div')`
	display: flex;
	flex-direction: row;
	font-family: Athletics, sans-serif;
	justify-content: space-between;
	gap: 12px;
	align-items: start;
	width: 100%;
	font-size: 20px;
`;

export const ScrollAndAccept = styled('a')`
	width: clamp(132px, 30%, 30%);
	color: ${mainTheme.palette.secondary.main};
	margin: 0;
	font-size: 16px;
	line-height: 22px;
	text-align: right;
	cursor: pointer;
	font-weight: 600;
`;

export const AcceptAllContainer = styled('div')`
	align-items: start;
	background-color: #E6DFFD;
	border-bottom-left-radius: 8px;
	border-bottom-right-radius: 8px;
	color: #16161E;
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	line-height: 24px;
	margin-left: -24px;
	margin-right: -12px;
	padding: 24px 12px;
	padding-right: 24px;
	width: calc(100% + 12px);
`;
