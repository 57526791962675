import { BreakpointsOptions } from '@mui/system/createTheme/createBreakpoints';

export const breakpoints: BreakpointsOptions = {
	values: {
		xs: 0,
		sm: 460,
		md: 857,
		lg: 1200,
		xl: 1920,
	},
};

export const buttonsBreakpoint = 650;
