/*eslint-disable-next-line @typescript-eslint/no-unsafe-assignment*/
import { GlobalStyles } from '@mui/material';
import React from 'react';
import { nestDatePickerStyles } from './components/components';

export const InitGlobalStyles = (): JSX.Element => {
	return (
		<>
			<GlobalStyles
				styles={{
					html: {
						height: '100%',
						width: '100%',
						margin: 0,
					},
					body: {
						padding: 0,
						margin: 0,
						boxSizing: 'border-box',
						height: '100%',
						width: '100%',
					},
					'#root': {
						height: '100%',
					},
					...(nestDatePickerStyles as Record<string, unknown>),
				}}
			/>
		</>
	);
};
