import styled from '@mui/system/styled';

export const AgreementContainer = styled('div')({
	width: '100%',
	borderTop: '2px solid #656572',
	paddingTop: '48px',
});

export const AgreementsHeaderSectionContainer = styled('div')({
	display: 'flex',
	justifyContent: 'space-between',
	alignItems: 'center',
});
