import { styled } from '@mui/material/styles';
import { CSSProperties } from '@mui/styles';
import { textColorSecondary } from '../../../../../theme/palette/palette';
import {
	FieldProps,
	getFieldStyle,
} from '../../../../../components/FormItems/FormItems.css';

export const MultiSelectContainer = styled('div')({
	display: 'flex',
	width: '100%',
	height: '100%',
	flexDirection: 'column',
});

export const MultiSelect = styled(
	'div',
	{}
)<FieldProps>(
	(props: FieldProps): CSSProperties => ({
		...getFieldStyle(props),
		display: 'flex',
		alignItems: 'center',
	})
);

interface MultiSelectListProps {
	show: boolean;
}

interface MultiSelectItemProps {
	active: boolean;
}

interface TriangleProps {
	open: boolean;
}

export const MultiSelectList = styled(
	'div',
	{}
)<MultiSelectListProps>(
	(props: MultiSelectListProps): CSSProperties => ({
		display: props.show ? 'flex' : 'none',
		width: '377px',
		flexDirection: 'column',
		background: 'white',
		borderRadius: '4px',
		paddingTop: '8px',
		paddingBottom: '8px',
		position: 'absolute',
		margin: '82px 0px 0px 1px',
		zIndex: 2,
		boxShadow:
			'0px 5px 5px -3px rgb(0 0 0 / 20%), 0px 8px 10px 1px rgb(0 0 0 / 14%), 0px 3px 14px 2px rgb(0 0 0 / 12%)',
	})
);

export const MultiSelectItem = styled(
	'div',
	{}
)<MultiSelectItemProps>(
	(props: MultiSelectItemProps): CSSProperties => ({
		width: '100%',
		minHeight: 'auto',
		'&:hover': {
			background: 'rgba(0, 0, 0, 0.04)',
		},
		background: props.active ? 'rgba(188, 168, 255, 0.12)' : 'none',
		color: textColorSecondary,
		paddingTop: '6px',
		paddingBottom: '6px',
		boxSizing: 'border-box',
		whiteSpace: 'nowrap',
		paddingLeft: '16px',
		paddingRight: '16px',
		cursor: 'pointer',
	})
);

export const TriangleAndElsContainer = styled('div')({
	display: 'flex',
	alignItems: 'center',
	justifyContent: 'space-between',
	width: '100%',
});

export const DropdownTextContainer = styled('div')({
	display: 'flex',
	alignItems: 'center',
	width: '100%',
});

export const Triangle = styled(
	'img',
	{}
)<TriangleProps>(
	(props: TriangleProps): CSSProperties => ({
		transform: props.open ? 'rotate(180deg)' : 'rotate(0deg)',
		margin: '5px',
	})
);
