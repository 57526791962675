export enum LeadProductType {
    None = 0,
    CashLoan = 1,
    Overdraft = 2,
}

export interface respLead {
    brokerId: string;
    brokerDescription: string;
    showCaptcha:boolean;
    sourceId:string;
    campaignNumber: string;
    leadProduct: number;
    parameters?: {
        minAmount?: number;
        maxAmount?: number;
        periods?: number[];
        commission?: number;
        markUp?: number;
    };
    wibor: number;
    logoUrl: string | null;
    legalNote: string;
}
