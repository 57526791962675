import { IAddressPropertiesError } from '../../../common/models/addressAndErrorsModels';
import {
	CompanyDataModel,
	ICompanyAddressFormErrors,
	ICompanyCorrespondenceAddressFormErrors,
} from './CompanyDataModels';

export const defaultValues: CompanyDataModel = {
	taxationForm: undefined,
	numberOfEmployees: 1,
	companyIncome: 0,
	isCompanyCorrespondenceAddressSameAsCompanyAddress: true,
	companyAddress: {
		street: '',
		houseNumber: '',
		apartmentNumber: '',
		postalCode: '',
		city: '',
	},
	companyCorrespondenceAddress: {
		street: '',
		houseNumber: '',
		apartmentNumber: '',
		postalCode: '',
		city: '',
	},
	agreements: [],
};

export const addressFormControlNames = {
	street: 'companyAddress.street',
	houseNumber: 'companyAddress.houseNumber',
	apartmentNumber: 'companyAddress.apartmentNumber',
	postalCode: 'companyAddress.postalCode',
	city: 'companyAddress.city',
};

export const correspondenceAddressFormControlNames = {
	street: 'companyCorrespondenceAddress.street',
	houseNumber: 'companyCorrespondenceAddress.houseNumber',
	apartmentNumber: 'companyCorrespondenceAddress.apartmentNumber',
	postalCode: 'companyCorrespondenceAddress.postalCode',
	city: 'companyCorrespondenceAddress.city',
};

export const getErrorsCompanyAddressForm = (
	errors: ICompanyAddressFormErrors
): IAddressPropertiesError => {
	return {
		street: errors.companyAddress?.street,
		houseNumber: errors.companyAddress?.houseNumber,
		apartmentNumber: errors.companyAddress?.apartmentNumber,
		postalCode: errors.companyAddress?.postalCode,
		city: errors.companyAddress?.city,
	};
};

export const getErrorsCorrespondenceAddressForm = (
	errors: ICompanyCorrespondenceAddressFormErrors
): IAddressPropertiesError => {
	return {
		street: errors.companyCorrespondenceAddress?.street,
		houseNumber: errors.companyCorrespondenceAddress?.houseNumber,
		apartmentNumber: errors.companyCorrespondenceAddress?.apartmentNumber,
		postalCode: errors.companyCorrespondenceAddress?.postalCode,
		city: errors.companyCorrespondenceAddress?.city,
	};
};
