import BaseFetch from '../../../common/api/BaseFetch';
import { ApiConfig } from '../../../common/api/FetchTypes';
import { CompanyDataModel } from './CompanyDataModels';
import { AgreementModel } from '../../../components/AgreementCollection/AgreementsModels';

const getCompanyDataKey = 'getCompanyData';

function getCompanyData(_props: never): Promise<CompanyDataModel> {
	return BaseFetch({ url: 'CompanyData', method: 'GET' });
}

export const GetCompanyDataConfig: ApiConfig<CompanyDataModel, never> = {
	key: getCompanyDataKey,
	fn: getCompanyData,
};

const getCompanyAgreements = 'getCompanyAgreements';

function getAgreements(): Promise<AgreementModel[]> {
	return BaseFetch({
		url: `Agreement/ForApplication/CompanyData`,
		method: 'GET',
	});
}

export const GetCompanyAgreements: ApiConfig<AgreementModel[], never> = {
	key: getCompanyAgreements,
	fn: getAgreements,
};

const addCompanyDataKey = 'addCompanyData';

function addCompanyData(props: CompanyDataModel): Promise<never> {
	return BaseFetch({ url: 'CompanyData', method: 'POST', body: props });
}

export const AddCompanyDataConfig: ApiConfig<never, CompanyDataModel> = {
	key: addCompanyDataKey,
	fn: addCompanyData,
};

const draftCompanyDataKey = 'draftCompanyDataKey';

function draftCompanyData(data: CompanyDataModel): Promise<never> {
	return BaseFetch({
		url: 'CompanyData/draft',
		method: 'POST',
		body: data,
	});
}

export const DraftCompanyDataConfig: ApiConfig<never, CompanyDataModel> = {
	key: draftCompanyDataKey,
	fn: draftCompanyData,
};
