import Typography from '@mui/material/Typography';
import Stack from '@mui/system/Stack';
import { Container } from '../../../common/styles/Layout.css';
import NewTabFileHandler from '../../../components/NewTabFileHandler/NewTabFileHandler';
import {
	ButtonSubmitSection,
	FileUploaderAndTextContainer,
	SaveApplicationSpan,
	SectionAttachDocumentBellow,
} from './AttachDocuments.css';
import React, { useState, useEffect, SetStateAction } from 'react';
import { submitButtonFileStyles } from './AttachDocumentsConstants';
import useFetch from '../../../common/api/UseFetch';
import {
	AddFileToBlobConfig,
	DeleteDocumentConfig,
	GetDocumentsConfig,
	VerifyDocumentsAndSendEmailToAnalystsConfig,
} from './AttachDocumentsApi';
import { OverrideProps } from '../../../routing/RouteSpec';
import { useNavigate } from 'react-router-dom';
import { EndRoute } from '../../../routing/Routing';
import CustomButton from '../../../components/CustomButton/CustomButton';
import { FileType } from '../../../enums/FileType';
import {
	grayMainColor,
	septenaryMainColor,
} from '../../../theme/palette/palette';
import { ScrollToTopOnMount } from '../../../common/helpers/scroll-to-top-onmount';
import infoIconBlue from '../../../common/images/infoIconBlue.svg';
import arrowDown from '../../../common/images/ArrowDown.svg';
import { List, ListItem } from '@mui/material';
import NestFileUploader from '../../../components/FileUploader/FileUploader';
import { IDocuments } from '../../../models/AttachDocumentsModels';

export default function AttachDocuments(props?: OverrideProps): JSX.Element {
	const [files, setFiles] = useState<IDocuments[] | undefined>([]);
	const [isUploading, setIsUploading] = useState(false);
	const [isExpand, setIsExpand] = useState(false);

	const navigate = useNavigate();

	const allAddedDocuments = useFetch(GetDocumentsConfig, false);

	const addFileToBlob = useFetch(
		AddFileToBlobConfig,
		false,
		(): void => {
			allAddedDocuments.mutate({} as never);
			setIsUploading(false);
		},
		true,
		(): void => {
			allAddedDocuments.mutate({} as never);
			setIsUploading(false);
		}
	);
	const deleteDocument = useFetch(DeleteDocumentConfig, false, (): void => {
		allAddedDocuments.mutate({} as never);
	});

	const verifyDocumentsAndSendEmailToAnalysts = useFetch(
		VerifyDocumentsAndSendEmailToAnalystsConfig,
		false,
		(data): void => {
			if (!props?.ommitRouting) {
				sessionStorage.setItem('bankAccountNumber', data.bankAccountNumber);
				navigate(EndRoute.path);
			}
			props?.close && props.close();
		}
	);

	useEffect((): void => {
		allAddedDocuments.mutate({} as IDocuments[]);
	}, []);

	useEffect((): void => {
		const convertedToIDocumentsFilesWithSection = (
			allAddedDocuments.data as unknown as IDocuments[] | undefined
		)?.map((file: IDocuments): IDocuments => {
			return { ...file, isEnter: false };
		});

		setFiles(
			convertedToIDocumentsFilesWithSection as SetStateAction<
				IDocuments[] | undefined
			>
		);
	}, [allAddedDocuments.data]);

	const addFiles = (files: FormData): void => {
		setIsUploading(true);
		addFileToBlob.mutate(files);
	};

	const deleteDocumentById = (id: string): void => {
		deleteDocument.mutate(id);
	};

	return (
		<Container>
			<ScrollToTopOnMount />
			<Typography variant='h2' sx={{ marginBottom: '32px', color: '#000000' }}>
				Gratulacje! Umowa została przez Ciebie podpisana
			</Typography>
			<Typography variant='p1' sx={{ color: grayMainColor }}>
				Pozostał tylko 1 krok. Załącz zaświadczenie o niezaleganiu w ZUS-ie lub
				potwierdzenie danych z ZUS-u. Po pozytywnej weryfikacji tych dokumentów
				wyślemy do Ciebie potwierdzenie zawarcia Umowy oraz uruchomimy kredyt.
			</Typography>
			<Typography
				variant='p1'
				sx={{ color: grayMainColor, paddingTop: '1rem' }}
			>
				Dokument musi być ważny 30 dni od daty jego wystawienia, chyba że z jego
				treści wynika inaczej.
			</Typography>
			<Typography
				variant='h7'
				sx={{
					fontSize: '14px',
					margin: '4px 0 4px 0',
					color: 'secondary.main',
					cursor: 'pointer',
					fontFamily: 'Athletics, sans-serif',
				}}
				onClick={(): void => setIsExpand((prevState): boolean => !prevState)}
			>
				{isExpand ? 'Zwiń' : 'Rozwiń'}{' '}
				<img
					src={arrowDown}
					style={{ transform: isExpand ? 'rotate(180deg)' : '' }}
				/>
			</Typography>
			{isExpand && (
				<Stack sx={{ padding: '8px 16px 0 0' }}>
					<Typography variant='p1' sx={{ color: grayMainColor }}>
						Na dokumencie wymagane są następujące dane:
					</Typography>
					<Typography
						variant='h7'
						sx={{ padding: '8px 0 0 0', color: grayMainColor }}
					>
						Zaświadczenie o niezaleganiu w ZUS
					</Typography>
					<List sx={{ margin: '0', padding: '0' }}>
						<ListItem
							sx={{
								margin: '4px 16px',
								padding: '0',
								color: grayMainColor,
								fontFamily: 'Inter, sans-serif',
							}}
						>
							a) dane umożliwiające jednoznaczną identyfikację klienta,
						</ListItem>
						<ListItem
							sx={{
								margin: '4px 16px',
								padding: '0',
								color: grayMainColor,
								fontFamily: 'Inter, sans-serif',
							}}
						>
							b) pieczęć ZUS oraz podpis osoby upoważnionej.
						</ListItem>
					</List>
					<Typography variant='p1' sx={{ color: grayMainColor }}>
						Z treści dokumentu powinien wynikać brak układów ratalnych oraz
						postępowań egzekucyjnych, naprawczych, reklamacyjnych i
						odwoławczych.
					</Typography>

					<Typography
						variant='h7'
						sx={{ margin: '8px 0 0 0', color: grayMainColor }}
					>
						Potwierdzenia salda wygenerowanego z portalu ZUS PUE
					</Typography>
					<List sx={{ margin: '0', padding: '0' }}>
						<ListItem
							sx={{
								margin: '4px 16px',
								padding: '0',
								color: grayMainColor,
								fontFamily: 'Inter, sans-serif',
							}}
						>
							a) dane umożliwiające jednoznaczną identyfikację klienta,
						</ListItem>
						<ListItem
							sx={{
								margin: '4px 16px',
								padding: '0',
								color: grayMainColor,
								fontFamily: 'Inter, sans-serif',
							}}
						>
							b) informacje o saldzie płatnika,
						</ListItem>
						<ListItem
							sx={{
								margin: '4px 16px',
								padding: '0',
								color: grayMainColor,
								fontFamily: 'Inter, sans-serif',
							}}
						>
							c) kwalifikowaną pieczęć elektroniczną Zakładu Ubezpieczeń
							Społecznych.
						</ListItem>
					</List>
				</Stack>
			)}
			<NewTabFileHandler
				fileType={FileType.ZusInstruction}
				fileName='Jak_samodzielnie_utworzyć_potwierdzenie_danych_z_ZUS_na_portalu_PUE_ZUS.pdf'
				buttonStyles={submitButtonFileStyles}
				buttonSubmitText='Zobacz instrukcję, jak uzyskać potwierdzenie danych z ZUS-u w kilka minut.'
			/>
			<FileUploaderAndTextContainer>
				<NestFileUploader
					documents={files}
					setFiles={
						setFiles as React.Dispatch<React.SetStateAction<IDocuments[]>>
					}
					addFiles={addFiles}
					deleteDocumentById={deleteDocumentById}
					isUploading={isUploading}
				/>
				<Typography variant='h10' sx={{ color: septenaryMainColor }}>
					<span style={{ fontWeight: '600' }}>
						Dopuszczalne formaty plików:
					</span>
					<br /> JPG, PDF, doc, docx, dotx, odt.
					<br />
					Maksymalny rozmiar plików to 30MB.
				</Typography>
				{files?.length === 0 && (
					<SectionAttachDocumentBellow>
						<img src={infoIconBlue} alt='alertIcon' />
						<Typography variant='p2' sx={{ color: '#656572' }}>
							Jeżeli potrzebujesz więcej czasu na wygenerowanie potrzebnych
							dokumentów{' '}
							<SaveApplicationSpan>zapisz wniosek</SaveApplicationSpan> i wróć
							do niego w dowolnym momencie.
						</Typography>
					</SectionAttachDocumentBellow>
				)}
			</FileUploaderAndTextContainer>
			<ButtonSubmitSection
				sx={{ justifyContent: { xs: 'center', md: 'flex-end' } }}
			>
				<CustomButton
					isLoading={verifyDocumentsAndSendEmailToAnalysts.isPending}
					disabled={!files?.length}
					onClick={(): void =>
						verifyDocumentsAndSendEmailToAnalysts.mutate({} as never)
					}
					variant='contained'
				>
					Wyślij
				</CustomButton>
			</ButtonSubmitSection>
		</Container>
	);
}
