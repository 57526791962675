import { styled } from '@mui/system';
import { mainTheme } from '../../theme/mainTheme';

export const BankAccount = styled('span')`
	color: ${mainTheme.palette.secondary.main};
	font-weight: 600;
	line-height: 19.2px;
`;

export const MainContainer = styled('div')`
	min-height: inherit;
	display: flex;
	justify-content: center;
	flex-direction: column;
	max-width: 830px;
	margin: auto;
`;

export const InnerContainer = styled('div')`
	flex: 0;
	display: flex;
	flex-direction: column;
	margin: 0 24px;
`;

export const Image = styled('img')`
	align-self: center;
	margin-right: 16px;
	max-width: 48px;
	max-height: 48px;
`;

export const LogoWithTitle = styled('div')`
	display: flex;
	align-items: center;
	margin-bottom: 36px;
`;

export const ButtonContainer = styled('div')`
	display: flex;
	margin: 32px 24px;
	align-items: end;
`;
