import { ApplicationProcess } from 'src/common/constants/application-process';
import { AgreementModel, AgreementModelSend } from 'src/components/AgreementCollection/AgreementsModels';
import BaseFetch from '../../../../common/api/BaseFetch';
import { ApiConfig, ApiVersion } from '../../../../common/api/FetchTypes';

const calculateLoanKey = 'calculateLoanInfo';

export interface CalculateLoanQuery {
	value: number;
	period: number;
	processId: string;
}

export interface CalculatedLoanInfo {
	nettValue: number;
	installment: number;
	interest: number;
	commission: number;
}

const calculateLoanInfo = (props: CalculateLoanQuery): Promise<CalculatedLoanInfo> => {
	return BaseFetch({
		url: 'Offer/CalculateLoanInfoByProcessId',
		method: 'GET',
		queryParams: { ...props },
	});
};

export const calculateLoanInfoConfig: ApiConfig<CalculatedLoanInfo, CalculateLoanQuery> = {
	fn: calculateLoanInfo,
	key: calculateLoanKey,
};

export interface LightCreateApplicationResult {
	applicationId: string;
	applicationNumber: string;
	canProceed: boolean;
	isRejected: string;
	token: string;
	marketingPartnerId: string;
	userId: string;
}

const lightCreateApplicaitonKey = 'lightCreateApplication';

function lightCreateApplication(props: LightCreateApplicationPayload): Promise<LightCreateApplicationResult> {
	return BaseFetch({
		url: `LandingPage/CreateApplication${props.query ? '?' + props.query : ''}`,
		method: 'POST',
		body: { ...props, query: undefined },
		version: ApiVersion.Light,
	});
}

export const LightCreateApplicationConfig: ApiConfig<LightCreateApplicationResult, LightCreateApplicationPayload> = {
	key: lightCreateApplicaitonKey,
	fn: lightCreateApplication,
};

export interface LightCreateApplicationPayload {
	period: number;
	agreements: AgreementModelSend[];
	magicWords?: string;
	process: ApplicationProcess;
	processId: string;
	nip: string;
	email: string;
	firstName: string;
	lastName: string;
	phoneNumber: string;
	query: string;
	brokerId: string | null;
}

const getAgreementKey = 'lightGetAgreement';

function getAgreement(processId: string): Promise<AgreementModel[]> {
	return BaseFetch({
		url: 'Agreement/' + processId,
		method: 'GET',
	});
}

export const GetAgreement: ApiConfig<AgreementModel[], string> = {
	key: getAgreementKey,
	fn: getAgreement,
};
