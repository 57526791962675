export enum FeatureFlagsEnum {
	ShowRecaptcha = 'ShowRecaptcha',
}

export interface IFeatureFlagContextState {
	getFeatureFlag: (name: FeatureFlagsEnum) => boolean | undefined;
}

export interface FeatureFlagProperties {
	ShowRecaptcha: boolean;
}
