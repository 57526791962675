import { Typography } from '@mui/material';
import { TextFieldProps } from '@mui/material/TextField/TextField';
import React, { useEffect, useRef, useState } from 'react';
import { UseFormReturn } from 'react-hook-form';
import selectIcon from '../../../common/images/selectIcon.svg';
import {
    MultiSelect,
    MultiSelectContainer, MultiSelectItem, MultiSelectList, Triangle, TriangleAndElsContainer
} from '../../../pages/rbf/rbf-personal-data/components/ClientWealthMultiSelect/ClientWealthMultiSelect.css';
import { DropdownFieldContainer } from './NestMultiSelect.css';
import { IMultiSelectOptions } from './NestMultiSelectModels';

type NestSelectProps = Omit<TextFieldProps, 'name'> & {
    options: IMultiSelectOptions[];
    value: IMultiSelectOptions[];
    label: string;
    formControlName: string;
    methods: UseFormReturn<Record<string, unknown>>;
};
const NestMultiSelect = (props: NestSelectProps): JSX.Element => {
    const [open, setOpen] = useState(false);
    const multiSelectRef = useRef<HTMLDivElement>(null);

    useEffect((): (() => void) => {
        document.addEventListener('click', handleClickOutside, false);
        return (): void => {
            document.removeEventListener('click', handleClickOutside, false);
        };
    }, []);

    const handleClickOutside = (event: Event): void => {
        if (
            multiSelectRef.current &&
            !multiSelectRef.current.contains(event.target as Node)
        ) {
            setOpen(false);
        }
    };
    
    const handleChange = (id: number): void => {
        props.options[id].active = !props.options[id].active
        props.methods.setValue(props.formControlName, props.options.filter((x: IMultiSelectOptions): boolean => x.active))
    }
    
    const convertedChosenOptionsToText = (): string => {
        const text = props.value.map(
            (item: IMultiSelectOptions, index: number): string => {
                if(index === 0 && props.value.length === 1 || index === props.options.length - 1 || index === props.value.length - 1) {
                    return String(item.placeholder)
                }  else {
                    return String(item.placeholder) + ', '
                }
            })
        return text.join('')
    }
    
    return (
        <MultiSelectContainer>
            <Typography
                variant='h9'
                sx={{ color: 'text.secondary', marginBottom: '8px' }}
            >
                {props.label}
            </Typography>
            <MultiSelect
                onClick={(): void => setOpen(!open)}
                ref={multiSelectRef}
                error={false}
                id='multi-select'
                sx={{ whiteSpace: 'break-spaces' }}
            >
                <TriangleAndElsContainer>
                    <DropdownFieldContainer>
                        <Typography variant='p1' 
                                    component={'div'}
                                    sx={{overflow: 'hidden', whiteSpace: 'nowrap', textOverflow: 'ellipsis'}}>
                            {convertedChosenOptionsToText()}
                        </Typography>
                    </DropdownFieldContainer>
                    <Triangle src={selectIcon} open={open} />
                </TriangleAndElsContainer>
            </MultiSelect>
            <MultiSelectList show={open}>
                {props.options.map(
                    (item: IMultiSelectOptions): JSX.Element => (
                        <MultiSelectItem
                            onClick={(): void => handleChange(item.id)}
                            key={item.id}
                            active={item.active}
                        >
                            <Typography variant='p1'>{item.placeholder}</Typography>
                        </MultiSelectItem>
                    )
                )}
            </MultiSelectList>
        </MultiSelectContainer>
    );
};
export default NestMultiSelect;
