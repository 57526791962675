import Typography from '@mui/material/Typography';
import { Container } from '../../../common/styles/Layout.css';
import { Control, FormProvider, useForm } from 'react-hook-form';
import { ButtonContainer, InputContainer } from './NipVerification.css';
import Box from '@mui/material/Box';
import NestInput from '../../../components/FormItems/NestInput/NestInput';
import { NipVerificationProperties, defaultValues } from './NipVerificationConstans';
import { nipValidator } from '../../../common/validators/nipValidator';
import { useNavigate } from 'react-router-dom';
import { ContactDataRoute, RejectionReasonRoute, TechnicalErrorRoute, ApplicationInProgressRoute } from '../../../routing/Routing';
import useFetch from '../../../common/api/UseFetch';
import { simpleDigitsMask } from '../../../common/helpers/mask-helper';
import CustomButton from '../../../components/CustomButton/CustomButton';
import {
	applicationIdKey,
	authTokenKey,
	setSessionStorageValue,
	applicationNumberKey,
	marketingPartnerIdKey,
	userIdKey,
} from '../../../common/sessionStorage/SessionStorageService';
import { useSelector } from 'react-redux';
import { RootState } from '../../../store/State';
import { ICreditInfoDataState } from '../../../store/slices/creditInfoDataSlice/CreditInfoDataSliceConstants';
import { CreateApplicationConfig } from './NipVerificationApi';
import { useEffect } from 'react';

export default function NipVerification(): JSX.Element {
	const navigate = useNavigate();
	const creditInfoDataState = useSelector((state: RootState): ICreditInfoDataState => state.creditInfoData);

	useEffect((): void => {
		if (creditInfoDataState.creditInfoData.agreements.length === 0) {
			navigate(TechnicalErrorRoute.path);
		}
	}, []);

	const methods = useForm<NipVerificationProperties>({
		mode: 'all',
		defaultValues,
	});

	const createApplication = useFetch(
		CreateApplicationConfig,
		false,
		(result): void => {
			if (!result.canProceed) {
				navigate(ApplicationInProgressRoute.path);
				return;
			}
			setSessionStorageValue(authTokenKey, result.token);
			setSessionStorageValue(applicationIdKey, result.applicationId);
			setSessionStorageValue(applicationNumberKey, result.applicationNumber);
			setSessionStorageValue(marketingPartnerIdKey, result.marketingPartnerId);
			setSessionStorageValue(userIdKey, result.userId);
			if (result.isRejected) {
				navigate(RejectionReasonRoute.path);
				return;
			}
			navigate(ContactDataRoute.path);
		},
		undefined,
		undefined,
		(): void => {
			navigate(TechnicalErrorRoute.path, {
				state: { clientId: creditInfoDataState.creditInfoData.clientId },
			});
		},
		false
	);

	const onSubmit = (data: NipVerificationProperties): void => {
		createApplication.mutate({
			...creditInfoDataState.creditInfoData,
			nip: data.nip,
		});
	};

	return (
		<Container>
			<Typography
				variant='h2'
				sx={{
					flex: '0',
					margin: '0 0 32px 0',
					fontSize: { xs: '28px', md: '34px' },
					lineHeight: { xs: '33px', md: '40px' },
				}}
			>
				Podaj NIP
			</Typography>
			<FormProvider {...methods}>
				<Box
					component='form'
					onSubmit={methods.handleSubmit(onSubmit)}
					sx={{
						flex: '1',
						display: 'flex',
						flexDirection: 'column',
						justifyContent: 'space-between',
					}}
				>
					<InputContainer>
						<NestInput
							label='NIP'
							control={methods.control as unknown as Control}
							name='nip'
							rules={{
								validate: nipValidator,
								required: 'Pole wymagane',
							}}
							mask='### ### ## ##'
							maskFunc={simpleDigitsMask}
						/>
					</InputContainer>
					<ButtonContainer>
						<CustomButton isLoading={createApplication.isPending} type='submit' variant='contained' disabled={!methods.formState.isValid}>
							Dalej
						</CustomButton>
					</ButtonContainer>
				</Box>
			</FormProvider>
		</Container>
	);
}
