import { PaletteOptions } from '@mui/material/styles/createPalette';
export const primaryMainColor = '#BCA8FF';
export const secondaryMainColor = '#7256CE';
export const tertiaryMainColor = '#F3F3F6';
export const senaryMainColor = '#CDCDD6';
export const septenaryMainColor = '#9191A1';
export const errorMainColor = '#F54A4A';
export const octonaryMainColor = '#FCC0B0';
export const nonaryMainColor = '#F3F3F6';
export const denaryMainColor = '#FC7D5D';
export const orangeMainColor = '#FC6A46';
export const violetMainColor = '#E6DFFD';
export const grayMainColor = '#3D3D45';

export const textColorPrimary = '#16161E';
export const textColorSecondary = '#656572';

export const backgroundColor = '#E5E5E5';

export const palette: PaletteOptions = {
	primary: {
		main: primaryMainColor,
	},
	secondary: {
		main: secondaryMainColor,
	},
	tertiary: {
		main: tertiaryMainColor,
	},
	senary: {
		main: senaryMainColor,
	},
	septenary: {
		main: septenaryMainColor,
	},
	octonary: {
		main: octonaryMainColor,
	},
	nonary: {
		main: nonaryMainColor,
	},
	denary: {
		main: denaryMainColor,
	},
	text: {
		primary: textColorPrimary,
		secondary: textColorSecondary,
	},
	background: {
		default: backgroundColor,
	},
	error: {
		main: errorMainColor,
	},
};
