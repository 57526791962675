const cityNameRegex =
	/^([a-zA-ZąĄęĘóÓłŁćĆńŃśŚżŻŹź-]+\s)*[a-zA-ZąĄęĘóÓłŁćĆńŃśŚżŻŹź-]+$/;

export const cityNameValidator = {
	pattern: {
		value: cityNameRegex,
		message: 'Niepoprawna wartość',
	},
	minLength: { value: 2, message: 'Niepoprawna wartość' },
	required: 'Pole wymagane',
};
