import Grid from '@mui/material/Grid';
import React, { useEffect, useState } from 'react';
import { Control, UseFormReturn } from 'react-hook-form';
import NestMultiSelect from '../../../../../../components/FormItems/NestMultiSelect/NestMultiSelect';
import { IMultiSelectOptions } from '../../../../../../components/FormItems/NestMultiSelect/NestMultiSelectModels';
import NestSelect from '../../../../../../components/FormItems/NestSelect/NestSelect';
import {
	gridFullWidth,
	gridHalfWidth,
} from '../../../../../../theme/grid/GridConstants';
import {
	expectedMonthlyProceedsAmountOptions,
	expectedProceedsTypeOptions,
	plannedDepositsOnAccountAmountOptions,
	servicesPlannedToUseOptions,
} from './LightOvdInfoAboutYourCompanyFormConstants';

interface LightOvdInfoAboutYourCompanyFormProps {
	methods: UseFormReturn<Record<string, unknown>>;
}

const LightOvdInfoAboutYourCompanyForm = (
	props: LightOvdInfoAboutYourCompanyFormProps
): JSX.Element => {
	const [
		showPlannedDepositsOnAccountAmount,
		setShowPlannedDepositsOnAccountAmount,
	] = useState(false);
	const watchServicesPlannedToUse = props.methods.watch(
		'servicesPlannedToUse'
	) as IMultiSelectOptions[] | undefined;

	useEffect((): void => {
		const foundPlannedDepositCondition = watchServicesPlannedToUse?.find(
			(x: IMultiSelectOptions): boolean => x.id === 8
		);
		if (!foundPlannedDepositCondition) {
			setShowPlannedDepositsOnAccountAmount(false);
			return;
		}
		setShowPlannedDepositsOnAccountAmount(true);
	}, [watchServicesPlannedToUse]);

	return (
		<Grid
			container
			rowSpacing={3}
			columnSpacing={9}
			sx={{ marginBottom: { md: '40px', xs: '0' } }}
		>
			<Grid item xs={gridFullWidth} md={gridHalfWidth}>
				<NestSelect
					control={props.methods.control as unknown as Control}
					name='expectedProceedsType'
					label='Jakiego rodzaju wpływy będą najczęściej na koncie?'
					options={expectedProceedsTypeOptions}
					placeholderOption={['Wybierz rodzaj wpływu', '']}
					defaultValue='Polska'
					setValue={props.methods.setValue}
					errors={
						props.methods.formState.errors.countryOfBirth as
							| { type: string; message: string }
							| undefined
					}
					rules={{
						required: 'Pole wymagane',
					}}
				/>
			</Grid>
			<Grid
				item
				xs={gridFullWidth}
				md={gridHalfWidth}
				sx={{ paddingTop: { md: '8px !important', xs: 'unset' } }}
			>
				<NestSelect
					control={props.methods.control as unknown as Control}
					name='expectedMonthlyProceedsAmount'
					label='Jakiej wysokości wpływów firma spodziewa się miesięcznie na koncie (dotyczy najbliższych 6 miesięcy)?'
					options={expectedMonthlyProceedsAmountOptions}
					placeholderOption={['Wybierz rodzaj wpływu', '']}
					defaultValue='Polska'
					setValue={props.methods.setValue}
					errors={
						props.methods.formState.errors.countryOfBirth as
							| { type: string; message: string }
							| undefined
					}
					rules={{
						required: 'Pole wymagane',
					}}
				/>
			</Grid>
			<Grid item xs={gridFullWidth} md={gridHalfWidth}>
				<NestMultiSelect
					label='Z jakich usług poza kontem firma planuje korzystać w Nest Banku w przyszłości?'
					value={
						(props.methods.watch('servicesPlannedToUse') as
							| IMultiSelectOptions[]
							| undefined) ?? []
					}
					options={servicesPlannedToUseOptions}
					formControlName='servicesPlannedToUse'
					methods={props.methods}
				/>
			</Grid>
			{showPlannedDepositsOnAccountAmount && (
				<Grid item xs={gridFullWidth} md={gridHalfWidth}>
					<NestSelect
						control={props.methods.control as unknown as Control}
						name='plannedDepositsOnAccountAmount'
						label='Ile gotówki firma będzie wpłacać miesięcznie na konto (dotyczy wpłat w ciągu najbliższych 6 miesięcy)?'
						options={plannedDepositsOnAccountAmountOptions}
						placeholderOption={['Wybierz rodzaj wpływu', '']}
						defaultValue='Polska'
						setValue={props.methods.setValue}
						errors={
							props.methods.formState.errors.countryOfBirth as
								| { type: string; message: string }
								| undefined
						}
						rules={{
							required: 'Pole wymagane',
						}}
					/>
				</Grid>
			)}
		</Grid>
	);
};

export default LightOvdInfoAboutYourCompanyForm;
