import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import {
	MainContainer,
	BankAccount,
	InnerContainer,
	Image,
	LogoWithTitle,
	ButtonContainer,
} from './EndComponent.css';
import { useNavigate } from 'react-router-dom';
import { TechnicalErrorRoute } from '../../routing/Routing';
import checkImage from '../../common/images/check.svg';
import { simpleDigitsMask } from '../../common/helpers/mask-helper';
import { useEffect } from 'react';
import { grayMainColor } from 'src/theme/palette/palette';

export default function EndComponent(): JSX.Element {
	const navigate = useNavigate();
	useEffect((): void => {
		!sessionStorage.getItem('bankAccountNumber') &&
			navigate(TechnicalErrorRoute.path);
	}, []);

	return (
		<MainContainer>
			<InnerContainer>
				<LogoWithTitle>
					<Image src={checkImage} alt='Check Image' />
					<Typography variant='h2'>Dziękujemy</Typography>
				</LogoWithTitle>
				<Typography variant='p1' sx={{ color: grayMainColor }}>
					<span style={{ fontWeight: '600' }}>Jeśli załączone dokumenty:</span>
					<br />
					<br />
					- są poprawne otrzymasz maila z potwierdzeniem uruchomienia kredytu.
					<br /> Środki otrzymasz na konto:{' '}
					<BankAccount>
						{
							simpleDigitsMask(
								'',
								sessionStorage.getItem('bankAccountNumber') ??
									'00000000000000000000000000',
								'## #### #### #### #### #### ####',
								null
							).maskedValue
						}
					</BankAccount>
					<br />
					<br />- są niepoprawne otrzymasz maila z prośbą o dostarczenie
					właściwych dokumentów.
				</Typography>
			</InnerContainer>
			<ButtonContainer
				sx={{ flex: '1', justifyContent: { xs: 'center', sm: 'end' } }}
			>
				<Button
					variant='contained'
					onClick={(): void => {
						window.location.href = 'https://nestbank.pl';
					}}
				>
					Zakończ
				</Button>
			</ButtonContainer>
		</MainContainer>
	);
}
