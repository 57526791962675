export interface FetchErrorOptions {
	status?: number;
	params?: string[];
}

export default class FetchError extends Error {
	public readonly options: FetchErrorOptions;

	constructor(errorCode?: string, options?: FetchErrorOptions) {
		super(errorCode);
		this.options = options ?? {};
	}
}

export function getFetchErrorCode(error: unknown): string {
	if (error instanceof FetchError) {
		return error.message;
	}

	return '';
}
