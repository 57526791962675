export enum TaxationForm {
	None = 0,
	IncomeAndExpenseBook = 1, // Księga przychodów i rozchodów
	TaxCard = 2, // Karta podatkowa
	LumpSum = 3, // Ryczałt ewidencjonowany
	FullAccountancy = 4, // Pełna księgowość
}

export enum TypeOfTax {
	'PIT-36' = 1,
	'PIT-36L' = 2,
	'PIT-28' = 3,
	'PIT-16A' = 4,
}

export function TaxationFormDescription(taxationForm: TaxationForm): string {
	switch (taxationForm) {
		case TaxationForm.None:
			return 'None';
		case TaxationForm.IncomeAndExpenseBook:
			return 'Księga przychodów i rozchodów';
		case TaxationForm.TaxCard:
			return 'Karta podatkowa';
		case TaxationForm.LumpSum:
			return 'Ryczałt';
		case TaxationForm.FullAccountancy:
			return 'Pełna księgowość';
	}
}

export function getTypeOfTaxOptions(
	taxationForm: TaxationForm
): [string, string | number][] {
	switch (taxationForm) {
		case TaxationForm.IncomeAndExpenseBook:
			return [
				[TypeOfTax[TypeOfTax["PIT-36"]], TypeOfTax["PIT-36"]],
				[TypeOfTax[TypeOfTax["PIT-36L"]], TypeOfTax["PIT-36L"]],
			];
		case TaxationForm.TaxCard:
			return [[TypeOfTax[TypeOfTax["PIT-16A"]], TypeOfTax["PIT-16A"]]];
		case TaxationForm.LumpSum:
			return [[TypeOfTax[TypeOfTax["PIT-28"]], TypeOfTax["PIT-28"]]];
		default:
			return [];
	}
}
