import { ReactNode, useEffect, useState } from 'react';
import { WiderContainer } from '../../../../common/styles/Layout.css';
import {
	BackgroundImageSpecialSign,
	backgroundImageText,
	ButtonContainer,
	CreditInfoSectionBackgroundImage,
	CreditInfoSectionContainer,
	CreditInfoSectionContainerBackground,
	CreditPeriodsContainer,
	MagicWordsContainer,
	SemiStrongBold,
} from './CreditInfoSection.css';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import { gridFullWidth } from '../../../../theme/grid/GridConstants';
import { mainTheme } from '../../../../theme/mainTheme';
import Button from '@mui/material/Button';
import NestTooltip from '../../../../components/NestTooltip/NestTooltip';
import { collapseContent, defaultValues, ILandingPageForm } from './CreditInfoSectionConstats';
import checkIcon from '../../../../common/images/checkIcon.svg';
import creditInfoSectionBgImg from '../../../../common/images/creditInfoSectionBgImg.svg';
import NestCollapse from '../../../../components/NestCollapse/NestCollapse';
import { CheckIcon, CheckLogoItem } from '../../../../components/NestCollapse/NestCollapse.css';
import Agreement from '../../../../models/Agreement';
import AgreementCheckbox from '../../../../components/AgreementCheckbox/AgreementCheckbox';
import useFetch from '../../../../common/api/UseFetch';
import { GetAgreement } from './CreditInfoSectionApi';
import { useNavigate } from 'react-router-dom';
import { NipVerificationRoute, TechnicalErrorRoute } from '../../../../routing/Routing';
import { calculateLoanInfoConfig } from '../LandingPageApi';
import { InitialData } from '../../rbf-new-application/NewApplicationApi';
import { getSessionStorageValue, partnerNameKey } from '../../../../common/sessionStorage/SessionStorageService';
import CustomButton from '../../../../components/CustomButton/CustomButton';
import { formatAmount } from '../../../../common/helpers/number-helper';
import { GetDownloadFileConfig } from '../../../../common/api/FileApi';
import { FileType } from '../../../../enums/FileType';
import NestInput from '../../../../components/FormItems/NestInput/NestInput';
import { Control, FormProvider, useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { saveCreditInfoDataState } from '../../../../store/slices/creditInfoDataSlice/CreditInfoDataSlice';
import { typography } from '../../../../theme/typography/typography';
import { ApplicationProcess } from 'src/common/constants/application-process';
import { magicWordsValidator } from 'src/common/validators/magicWordsValidator';

interface CreditInfoSectionProps {
	initialData: InitialData;
	internalClientId: string;
}

const CreditInfoSection = (props: CreditInfoSectionProps): JSX.Element => {
	const methods = useForm<ILandingPageForm>({
		mode: 'all',
		defaultValues,
	});
	const dispatch = useDispatch();

	const watchMagicWords = methods.watch('magicWords');

	const [agreementChecked, setAgreementChecked] = useState(false);
	const [creditPeriod, setCreditPeriod] = useState(props.initialData.selectedPeriod);
	const [checkboxControl, setCheckboxControl] = useState<boolean[]>([]);
	const [installment, setInstallment] = useState<number>(props.initialData.initInstallment);

	const navigate = useNavigate();

	const agreements = useFetch(
		GetAgreement,
		false,
		(data): void => {
			setCheckboxControl(data.map((): boolean => false));
		},
		undefined,
		undefined,
		(): void => {
			navigate(TechnicalErrorRoute.path, {
				state: { clientId: props.internalClientId },
			});
		},
		false
	);
	const calculateLoan = useFetch(
		calculateLoanInfoConfig,
		false,
		(data): void => {
			setInstallment(data.installment);
		},
		undefined,
		undefined,
		(): void => {
			navigate(TechnicalErrorRoute.path, {
				state: { clientId: props.internalClientId },
			});
		},
		false
	);

	useEffect((): void => {
		agreements.mutate(props.initialData.processId);
	}, []);

	const onCreditPeriodChange = (value: number): void => {
		if (!props.initialData.periods.includes(value) || creditPeriod === value) {
			return;
		}

		setCreditPeriod(value);
		calculateLoan.mutate({
			value: props.initialData.initCreditAmount,
			period: value,
			processId: props.initialData.processId,
			merchantId: props.initialData.clientId,
		});
	};

	const agreementsChange = (checked: boolean, index: number): void => {
		const checkboxControlTemp = [...checkboxControl];
		if (checkboxControlTemp.length) {
			checkboxControlTemp[index] = checked;
			setAgreementChecked(checkboxControlTemp.every((element: boolean): boolean => element));
		}
		setCheckboxControl(checkboxControlTemp);
	};

	const onSubmit = (): void => {
		dispatch(
			saveCreditInfoDataState({
				clientId: props.initialData.clientId,
				period: creditPeriod,
				agreements: agreements.data ?? [],
				magicWords: watchMagicWords,
				processId: props.initialData.processId,
				process: props.initialData.process ?? ApplicationProcess.RbfCl,
			})
		);
		navigate(NipVerificationRoute.path);
	};

	const downloadInformationClause = useFetch(GetDownloadFileConfig(FileType.InformationClause, true));

	return (
		<CreditInfoSectionContainerBackground>
			<CreditInfoSectionContainer>
				<CreditInfoSectionBackgroundImage>
					<img src={creditInfoSectionBgImg} alt='background image' />
					<BackgroundImageSpecialSign>“</BackgroundImageSpecialSign>
					<Typography variant='h3' sx={backgroundImageText}>
						<br />
						Szybki i wygodny wniosek.
						<br />W końcu nie muszę tracić czasu na formalności.
					</Typography>
				</CreditInfoSectionBackgroundImage>
				<WiderContainer>
					<Typography
						variant='h1'
						sx={{
							color: '#FFFFFF',
							maxWidth: '600px',
							fontSize: { xs: '40px', sm: '48px' },
							lineHeight: { xs: '48px', sm: '57px' },
						}}
					>
						Rozwiń swój biznes!
						<br /> Nawet{' '}
						<span style={{ whiteSpace: 'nowrap' }}>
							<span style={{ color: '#FC6A46' }}> {formatAmount(props.initialData.initCreditAmount)} PLN</span>
							<NestTooltip
								text={`Maksymalna kwota kredytu wyliczona na bazie obrotów udostępnionych z ${
									getSessionStorageValue(partnerNameKey) as unknown as string
								}. Wysokość finansowania zależy od indywidualnej oceny zdolności kredytowej wnioskodawcy.`}
								sx={{
									marginLeft: '8px',
									marginBottom: '10px',
									zIndex: '100',
								}}
								sxTooltipContent={{
									'& .MuiTooltip-tooltip': {
										maxWidth: '396px',
										color: 'black',
										textAlign: 'center',
									},
								}}
							/>
						</span>
						<br /> bez wychodzenia z domu.
					</Typography>
					{window.CONFIG.MAGIC_WORDS && (
						<MagicWordsContainer>
							<FormProvider {...methods}>
								<NestInput
									control={methods.control as unknown as Control}
									name='magicWords'
									label='Magic Words'
									rules={{
										pattern: {
											value: magicWordsValidator,
											message: 'Niepoprawna wartość',
										},
									}}
								/>
							</FormProvider>
						</MagicWordsContainer>
					)}
					<Grid item xs={gridFullWidth} sx={{ marginTop: { xs: '32px', md: '57px' }, marginBottom: '12px' }}>
						<Typography variant='p3' sx={{ color: '#FFFFFF', fontWeight: '600' }}>
							Wybierz okres spłaty
						</Typography>
					</Grid>
					<CreditPeriodsContainer>
						<Grid
							sx={{
								width: '100%',
								maxWidth: '368px',
								display: 'flex',
								flexDirection: 'row',
							}}
							key='period_container'
							item
							xs={gridFullWidth}
						>
							{props.initialData.periods
								.sort((a, b): number => a - b)
								.map(
									(period, index): JSX.Element => (
										<Button
											key={`period_${period}`}
											variant='primaryButton'
											onClick={(): void => onCreditPeriodChange(period)}
											disabled={calculateLoan.isPending}
											sx={{
												width: '100%',
												minWidth: '36px',
												borderRadius: '4px',
												marginRight: props.initialData.periods.length && index < props.initialData.periods.length - 1 ? '24px' : '0',
												height: '53px',
												...(period !== creditPeriod
													? {
															backgroundColor: 'rgba(172, 211, 247, 0.15)' + ' !important',
															color: mainTheme.palette.primary.main,
													  }
													: {}),
											}}
										>
											{period}
										</Button>
									)
								)}
						</Grid>
					</CreditPeriodsContainer>

					<Grid
						item
						xs={gridFullWidth}
						sx={{
							marginTop: '32px',
							marginBottom: '12px',
							display: 'flex',
							flexWrap: 'wrap',
							alignItems: 'center',
						}}
					>
						<Typography
							variant='p1'
							sx={{
								color: '#FFFFFF',
								margin: '0 8px 0 0',
								whiteSpace: 'nowrap',
							}}
						>
							Szacowana rata miesięczna:
						</Typography>
						<Typography
							variant='p1'
							sx={{
								color: mainTheme.palette.primary.main,
								fontSize: '24px',
								whiteSpace: 'nowrap',
								margin: '4px 0',
							}}
						>
							{installment.toString().replace('.', ',')} PLN
						</Typography>
					</Grid>
					<NestCollapse title={'Nota prawna'} content={collapseContent} contentTitle={'Nota prawna'} sxContent={{ maxWidth: '550px' }} />

					<Typography
						variant='h2'
						sx={{
							color: '#FFFFFF',
							marginBottom: '24px',
							fontSize: { md: '34px', xs: '24px' },
							lineHeight: { md: '40px', xs: '28px' },
						}}
					>
						Co zyskasz?
					</Typography>
					<CheckLogoItem>
						<CheckIcon src={checkIcon} alt='check icon' />
						<Typography variant={'p1'} sx={{ color: '#FFFFFF', width: '455px', marginBottom: '10px' }}>
							<SemiStrongBold>Bezpieczny wniosek</SemiStrongBold> - kredyt weźmiesz u zaufanego partnera,{' '}
							<span style={{ color: mainTheme.palette.primary.main }}>Nest Bank S.A.</span>
						</Typography>
					</CheckLogoItem>
					<CheckLogoItem>
						<CheckIcon src={checkIcon} alt='check icon' />
						<Typography variant={'p1'} sx={{ color: '#FFFFFF', width: '455px', marginBottom: '10px' }}>
							<SemiStrongBold>Wygodę i czas</SemiStrongBold> - całość załatwisz bez wychodzenia z domu
						</Typography>
					</CheckLogoItem>
					<CheckLogoItem style={{ marginBottom: '32px' }}>
						<CheckIcon src={checkIcon} alt='check icon' />
						<Typography variant='p1' sx={{ color: '#FFFFFF', width: '455px' }}>
							<SemiStrongBold>Szybką wypłatę</SemiStrongBold> - pieniądze otrzymasz niezwłocznie po podpisaniu umowy
						</Typography>
					</CheckLogoItem>
					{agreements.data?.map(
						(agreement: Agreement, index: number): ReactNode => (
							<AgreementCheckbox
								key={agreement.symbol}
								agreement={agreement}
								onCheckboxChange={(checked: boolean): void => {
									agreementsChange(checked, index);
								}}
								agreementContentSx={{
									'& .MuiTypography-root': {
										maxWidth: '453px',
										color: '#FFFFFF',
										...typography.h9,
										fontWeight: '600',
									},
								}}
							/>
						)
					)}
					<Typography
						variant='h9'
						sx={{
							color: '#FFFFFF',
							paddingLeft: '34px',
							maxWidth: '453px',
							marginBottom: '26px',
							fontWeight: '600',
						}}
					>
						Administratorem danych osobowych jest Nest Bank S.A z siedzibą w 02-675 Warszawa ul. Wołoska 24.
						<Typography
							sx={{
								display: 'block',
								color: mainTheme.palette.primary.main,
								fontWeight: '600',
								cursor: 'pointer',
							}}
							onClick={(): void => downloadInformationClause.mutate({} as never)}
							variant='p3'
						>
							Zobacz więcej
						</Typography>
					</Typography>
					<ButtonContainer>
						<CustomButton
							variant='primaryButton'
							type='submit'
							disabled={!agreementChecked}
							onClick={onSubmit}
							sx={{
								'&.Mui-disabled': {
									backgroundColor: '#463B6B' + ' !important',
									color: '#8B7EBA' + ' !important',
								},
							}}
						>
							Przechodzę do wniosku
						</CustomButton>
					</ButtonContainer>
				</WiderContainer>
			</CreditInfoSectionContainer>
		</CreditInfoSectionContainerBackground>
	);
};

export default CreditInfoSection;
