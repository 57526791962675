import { ILightOvdContactDataProperties } from '../../LightOvdCreditInfoAndContactDataFormModels';

export const defaultValues: ILightOvdContactDataProperties = {
	nip: '',
	email: '',
	phoneNumber: '',
	firstName: '',
	lastName: '',
	magicWords: '',
	brokerId: null,
};
