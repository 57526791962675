import { secondaryMainColor } from '../../../theme/palette/palette';
import { typography } from '../../../theme/typography/typography';

export const defaultValues = {
	document: [],
};

export const submitButtonFileStyles = {
	color: secondaryMainColor,
	fontFamily: 'Athletics, sans-serif',
	...typography.h7,
	margin: '16px 0 16px 0',
	cursor: 'pointer',
};
