import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { useState } from 'react';
import useFetch from '../../../common/api/UseFetch';
import Loader from '../../../components/Loader/Loader';
import PageRenderer from '../../../components/PageRenderer/PageRenderer';
import { VerificationRoute, WebPage } from '../../../routing/Routing';
import { gridFullWidth } from '../../../theme/grid/GridConstants';
import { ButtonContainer } from './DataConsistency.css';
import {
	GetClientDataConfig,
	SubmitDataConfig,
} from './DataConsistencyApi';
import { Container } from '../../../common/styles/Layout.css';
import { useNavigate } from 'react-router-dom';
import CustomButton from '../../../components/CustomButton/CustomButton';
import { ScrollToTopOnMount } from '../../../common/helpers/scroll-to-top-onmount';
import AddressDescription from 'src/components/DataConsistency/AddressDescription';
import InfoAboutYou from 'src/components/DataConsistency/InfoAboutYou';
import InfoAboutCompany from 'src/components/DataConsistency/InfoAboutCompany';
import ContactDataInfo from 'src/components/DataConsistency/ContactDataInfo';

export default function DataConsistency(): JSX.Element {
	const [renderPage, setRenderPage] = useState<WebPage | undefined>(undefined);
	const navigate = useNavigate();
	const result = useFetch(GetClientDataConfig, true);
	const submitApplication = useFetch(
		SubmitDataConfig,
		false,
		(): void => {
			navigate(VerificationRoute.path);
		}
	);

	return (
		<>
			{renderPage && !result.isPending && (
				<PageRenderer
					page={renderPage}
					close={(): void => {
						setRenderPage(undefined);
						result.mutate({} as never);
					}}
				/>
			)}
			{result.data && !renderPage && (
				<Container>
					<ScrollToTopOnMount />
					<Typography variant='h2' sx={{ width: '100%', marginBottom: '24px' }}>
						Upewnij się, czy poniższe dane są prawidłowe
					</Typography>
					<Grid container rowSpacing={4}>
						<InfoAboutYou
							title='Informacje o Tobie'
							clientData={result.data.clientData}
							editPage={WebPage.PersonalData}
							setRenderPage={setRenderPage}
						/>

						<AddressDescription
							title='Adres zamieszkania'
							address={result.data.clientAddress}
							editPage={WebPage.PersonalData}
							setRenderPage={setRenderPage}
						/>

						<AddressDescription
							title='Adres korespondencyjny'
							address={result.data.clientCorrespondenceAddress}
							editPage={WebPage.PersonalData}
							setRenderPage={setRenderPage}
						/>

						<InfoAboutCompany
							title='Informacje o Twojej firmie'
							companyData={result.data.companyData}
							editPage={WebPage.CompanyData}
							setRenderPage={setRenderPage}
						/>

						<AddressDescription
							title='Adres firmy'
							address={result.data.companyAddress}
							editPage={WebPage.CompanyData}
							setRenderPage={setRenderPage}
						/>

						<AddressDescription
							title='Adres korespondencyjny firmy'
							address={result.data.companyCorrespondenceAddress}
							editPage={WebPage.CompanyData}
							setRenderPage={setRenderPage}
						/>

						<ContactDataInfo
							title='Dane kontaktowe'
							contactData={result.data.contactData}
							editPage={WebPage.ContactData}
							setRenderPage={setRenderPage}
						/>

						<Grid item xs={gridFullWidth}>
							<ButtonContainer>
								<CustomButton
									isLoading={submitApplication.isPending}
									onClick={(): void => {
										submitApplication.mutate({} as never);
									}}
									variant='contained'
								>
									Składam wniosek
								</CustomButton>
							</ButtonContainer>
						</Grid>
					</Grid>
				</Container>
			)}
			{result.isPending && (
				<Container>
					<Loader />
				</Container>
			)}
		</>
	);
}
