import { FieldErrors, UseFormReturn } from 'react-hook-form';
import { ChangeEvent } from 'react';
import checkbox from '../../common/images/checkbox.svg';
import NestInput from '../FormItems/NestInput/NestInput';
import { CheckboxMainContainer } from './AddressForm.css';
import { cityNameValidator } from '../../common/validators/cityNameValidator';
import { IAddressFormProperties } from './AddressFormModels';
import Typography from '@mui/material/Typography';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Grid from '@mui/material/Grid';
import { gridFullWidth, gridHalfWidth } from '../../theme/grid/GridConstants';
import { simpleDigitsMask } from '../../common/helpers/mask-helper';
import { onlyPolishWordsAndDigits, streetValidator } from '../../common/validators/polishWordsValidator';

interface AddressFormProps {
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	methods: UseFormReturn<any>;
	formControlNames: IAddressFormProperties;
	checkboxLabel?: string;
	checked?: boolean;
	handleChecked?: (e: ChangeEvent<HTMLInputElement>) => void;
	disabled?: boolean;
	errors: FieldErrors<Record<string, unknown>>;
}

const AddressForm = (props: AddressFormProps): JSX.Element => {
	return (
		<>
			<Grid item xs={gridFullWidth} md={gridHalfWidth}>
				<NestInput
					label='Ulica'
					name={props.formControlNames.street}
					disabled={props.disabled}
					rules={{
						...streetValidator,
						required: 'Pole wymagane',
					}}
					errors={props.errors.street as { type: string; message: string }}
					control={props.methods.control}
				/>
			</Grid>
			<Grid item xs={gridFullWidth} md={gridHalfWidth}>
				<NestInput
					label='Numer domu'
					control={props.methods.control}
					name={props.formControlNames.houseNumber}
					rules={{
						...onlyPolishWordsAndDigits,
						maxLength: { value: 10, message: 'Niepoprawna wartość' },
						required: 'Pole wymagane',
					}}
					errors={props.errors.houseNumber as { type: string; message: string }}
					disabled={props.disabled}
				/>
			</Grid>
			<Grid item xs={gridFullWidth} md={gridHalfWidth}>
				<NestInput
					label='Numer mieszkania'
					control={props.methods.control}
					name={props.formControlNames.apartmentNumber}
					errors={props.errors.apartmentNumber as { type: string; message: string }}
					rules={{
						...onlyPolishWordsAndDigits,
						maxLength: { value: 10, message: 'Niepoprawna wartość' },
					}}
					disabled={props.disabled}
				/>
			</Grid>
			<Grid item xs={gridFullWidth} md={gridHalfWidth}>
				<NestInput
					label='Kod pocztowy'
					control={props.methods.control}
					name={props.formControlNames.postalCode}
					errors={props.errors.postalCode as { type: string; message: string }}
					rules={{
						minLength: { value: 6, message: 'Niepoprawna wartość' },
						maxLength: { value: 6, message: 'Niepoprawna wartość' },
						required: 'Pole wymagane',
					}}
					maskFunc={simpleDigitsMask}
					mask='##-###'
					placeholder='__-___'
					useMaskAsValue
					disabled={props.disabled}
				/>
			</Grid>
			<Grid item xs={gridFullWidth} md={gridHalfWidth}>
				<NestInput
					label='Miejscowość'
					control={props.methods.control}
					name={props.formControlNames.city}
					errors={props.errors.city as { type: string; message: string }}
					rules={cityNameValidator}
					disabled={props.disabled}
				/>
			</Grid>

			{props.checkboxLabel && (
				<Grid item xs={gridFullWidth} sx={{ marginTop: '24px' }}>
					<CheckboxMainContainer>
						<Typography variant='h5'>Adres korespondencyjny</Typography>
						<FormControlLabel
							control={
								<Checkbox
									checked={props.checked}
									onChange={props.handleChecked}
									disableRipple
									checkedIcon={<img src={checkbox} alt='checkbox' style={{ padding: '2px' }} />}
									sx={{
										'& .MuiSvgIcon-root': { fontSize: 28 },
									}}
									disabled={props.disabled}
								/>
							}
							label={
								<span
									style={{
										fontFamily: 'Inter, sans-serif',
										fontSize: '14px',
										lineHeight: '19px',
									}}
								>
									{props.checkboxLabel}
								</span>
							}
							sx={{
								marginBottom: '24px',
							}}
						/>
					</CheckboxMainContainer>
				</Grid>
			)}
		</>
	);
};

export default AddressForm;
