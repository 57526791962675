import Grid from '@mui/material/Grid';
import ClientData from '../../models/ClientData';
import { WebPage } from '../../routing/Routing';
import { HorizontalLine } from './DataConsistency.css';
import ValueDescriptor from '../../components/ValueDescriptor/ValueDescriptor';
import { gridFullWidth, gridHalfWidth } from '../../theme/grid/GridConstants';
import SectionHeader from './SectionHeader';
import { formatDate } from 'src/common/helpers/date-helper';


export interface InfoAboutYouProps {
    title: string;
    clientData: ClientData;
    editPage: WebPage;
    setRenderPage: React.Dispatch<React.SetStateAction<WebPage | undefined>>;
}


export default function InfoAboutYou(props: Readonly<InfoAboutYouProps>): JSX.Element {

    return (
        <>
            <Grid item xs={gridFullWidth} sx={{ marginTop: '16px', marginBottom: '-8px' }}>
                <SectionHeader title={props.title} page={props.editPage} setRenderPage={props.setRenderPage} />
                <HorizontalLine />
            </Grid>
            <Grid item xs={gridFullWidth} md={gridHalfWidth}>
                <ValueDescriptor
                    title='Imię'
                    value={props.clientData.firstName}
                />
            </Grid>
            <Grid item xs={gridFullWidth} md={gridHalfWidth}>
                <ValueDescriptor
                    title='Nazwisko'
                    value={props.clientData.lastName}
                />
            </Grid>
            <Grid item xs={gridFullWidth} md={gridHalfWidth}>
                <ValueDescriptor
                    title='Seria i numer dowodu osobistego'
                    value={props.clientData.idCardNumber}
                />
            </Grid>
            <Grid item xs={gridFullWidth} md={gridHalfWidth}>
                <ValueDescriptor
                    title='Data wydania dowodu osobistego'
                    value={formatDate(props.clientData.idCardIssuedDate)}
                />
            </Grid>
            <Grid item xs={gridFullWidth} md={gridHalfWidth}>
                <ValueDescriptor
                    title='Data ważności dowodu osobistego'
                    value={formatDate(props.clientData.idCardExpirationDate)}
                />
            </Grid>
            <Grid item xs={gridFullWidth} md={gridHalfWidth}>
                <ValueDescriptor
                    title='Kraj urodzenia'
                    value={props.clientData.countryOfBirth}
                />
            </Grid>
            <Grid item xs={gridFullWidth} md={gridHalfWidth}>
                <ValueDescriptor
                    title='Nazwisko panieńskie matki'
                    value={props.clientData.mothersMaidenName}
                />
            </Grid>
            <Grid item xs={gridFullWidth} md={gridHalfWidth}>
                <ValueDescriptor
                    title='PESEL'
                    value={props.clientData.socialSecurityNumber}
                />
            </Grid>
        </>
    );
}