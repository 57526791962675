import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';
import lead_end from '../../../common/images/lead_end.svg';
import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { IGoogleAnalyticsData } from 'src/common/models/googleAnalyticsDataModels';
import { pushLeadEventGenerateNew, pushLeadEventVirtualpvOnSuccess } from '../../../common/googleAnalyticsEvents/googleAnalyticsEvents';

export default function LeadEnd(): JSX.Element {
	const location = useLocation();
	const locationState = location.state as IGoogleAnalyticsData;

	useEffect((): void => {
		pushLeadEventGenerateNew(locationState);
		pushLeadEventVirtualpvOnSuccess(locationState);
	}, []);

	return (
		<Stack
			sx={{
				padding: '0 16px',
				height: {
					xs: 'calc(100vh - 115px - 110px)',
					sm: 'calc(100vh - 115px - 75px)',
				},
				alignItems: 'center',
				justifyContent: 'center',
			}}
		>
			<img src={lead_end} width={149} height={147} alt='end' />
			<Typography
				sx={{
					padding: '80px 0 16px 0',
					fontSize: { xs: '24px', sm: '34px' },
					lineHeight: { xs: '28.8px', sm: '40.8px' },
					fontWeight: '700',
					fontFamily: 'Athletics, sans-serif',
					textAlign: 'center',
				}}
			>
				Dziękujemy za wypełnienie formularza
			</Typography>
			<Typography
				sx={{
					fontSize: '14px',
					lineHeight: '19.6px',
					fontWeight: { xs: '600', sm: '400' },
					fontFamily: 'Inter, sans-serif',
					textAlign: 'center',
				}}
			>
				Jesteśmy w kontakcie!
			</Typography>
		</Stack>
	);
}
