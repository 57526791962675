import OnePageRejection from '../../../components/OnePageRejection/OnePageRejection';
import useFetch from '../../../common/api/UseFetch';
import Loader from '../../../components/Loader/Loader';
import { getRejectionReasonConfig } from './RejectionReasonApi';
import { BUTTON_PATH } from './RejectionReasonConstans';
import Container from '@mui/material/Container';
import { pushEventVirtualpvRejectionReason } from 'src/common/googleAnalyticsEvents/googleAnalyticsEvents';
import { useEffect } from 'react';

export default function RejectionReason(): JSX.Element {
	const initiateResult = useFetch(getRejectionReasonConfig, true);

	useEffect((): void => {
		pushEventVirtualpvRejectionReason();
	}, []);

	return (
		<>
			{!initiateResult.isPending && !initiateResult.isIdle ? (
				<OnePageRejection
					descriptionText={initiateResult.data?.description ?? ''}
					onClickHandler={(): void => {
						window.location.href = BUTTON_PATH;
					}}
					buttonText='Zakończ'
					descriptionMaxWidth={557}
				/>
			) : (
				<Container>
					<Loader />
				</Container>
			)}
		</>
	);
}
