import { WebPage } from './Routing';

export interface OverrideProps {
	ommitRouting: boolean;
	close: () => void;
	submitText: string | undefined;
	cancelText: string | undefined;
}

export default interface RouteSpec {
	path: string;
	element: (props?: OverrideProps) => JSX.Element;
	doAfterManualPageChangeAction: boolean;
	showSaveStateButton: boolean;
	page: WebPage;
	breadcrumbStepName: BreadcrumbStep;
	showApplicationNumber: boolean;
	showPartnerLogo: boolean;
	clearSession: boolean;
	allowGetInToPageWithoutSession?: boolean;
	showHeader?: boolean;
	showFooter?: boolean;
}

export enum BreadcrumbStep {
	DOESNT_EXIST = '',
	LETS_GET_TO_KNOW = 'Poznajmy się',
	MATCH_THE_OFFER = 'Dopasuj ofertę',
	CONFIRM_DATA = 'Potwierdź dane',
	ASSIGN_AGREEMENT = 'Podpisz umowę',
	ATTACH_DOCUMENTS = 'Załącz dokumenty',
	COMPLETED = 'Completed',
}
