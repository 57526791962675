import BaseFetch from '../../../../../common/api/BaseFetch';
import { ApiConfig, ApiVersion } from '../../../../../common/api/FetchTypes';
import { AgreementModel } from '../../../../../components/AgreementCollection/AgreementsModels';
import { PersonalDataProperties } from '../../../../rbf/rbf-personal-data/PersonalDataModels';
import { LightPersonalDataProperties } from '../models/LightPersonalDataModels';

const getLightPersonalDataKey = 'getLightPersonalDataKey';
const getLightPersonalDataAgreementsKey = 'geLightPersonalDataAgreementsKey';
const postLightPersonalDataKey = 'postLightPersonalDataKey';
const draftLightPersonalDataKey = 'draftLightPersonalDataKey';

function getLightPersonalData(
	_props: never
): Promise<LightPersonalDataProperties> {
	return BaseFetch({
		url: 'ClientPersonalData',
		method: 'GET',
		version: ApiVersion.Light,
	});
}

function postLightCompanyData(
	data: LightPersonalDataProperties
): Promise<LightPersonalDataProperties> {
	return BaseFetch({
		url: 'ClientPersonalData',
		method: 'POST',
		version: ApiVersion.Light,
		body: data,
	});
}

function getLightPersonalDataAgreements(
	_props: never
): Promise<AgreementModel[]> {
	return BaseFetch({
		url: `Agreement/ForApplication/LightPersonalData`,
		method: 'GET',
	});
}

function draftLightPersonalData(data: PersonalDataProperties): Promise<never> {
	return BaseFetch({
		url: 'ClientPersonalData/draft',
		method: 'POST',
		version: ApiVersion.Light,
		body: data,
	});
}

export const GetLightPersonalDataConfig: ApiConfig<
	LightPersonalDataProperties,
	never
> = {
	key: getLightPersonalDataKey,
	fn: getLightPersonalData,
};

export const GetLightPersonalDataAgreementsConfig: ApiConfig<
	AgreementModel[],
	never
> = {
	key: getLightPersonalDataAgreementsKey,
	fn: getLightPersonalDataAgreements,
};

export const PostLightPersonalDataConfig: ApiConfig<
	LightPersonalDataProperties,
	never
> = {
	key: postLightPersonalDataKey,
	fn: postLightCompanyData,
};

export const DraftLightPersonalDataConfig: ApiConfig<
	never,
	LightPersonalDataProperties
> = {
	key: draftLightPersonalDataKey,
	fn: draftLightPersonalData,
};
