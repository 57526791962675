import Stack from '@mui/system/Stack/Stack';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import crossImage from '../../common/images/cross.svg';
import { textColorPrimary } from '../../theme/palette/palette';

interface IOnePageRejectionProps {
	descriptionText?: string;
	onClickHandler: () => void;
	buttonDisabled?: boolean;
	buttonText?: string;
	descriptionMaxWidth?: number;
}

const OnePageRejection = (props: IOnePageRejectionProps): JSX.Element => {
	return (
		<Stack
			sx={{
				alignItems: 'center',
				justifyContent: 'center',
				minHeight: 'inherit',
				padding: '0 16px',
			}}
		>
			<Stack
				sx={{
					alignItems: 'center',
					justifyContent: 'center',
					flex: { xs: '1', sm: '0' },
				}}
			>
				<Stack sx={{ width: '149px', height: '147px' }}>
					<img src={crossImage} alt='Check Image' />
				</Stack>
				<Typography
					variant='h3'
					sx={{
						margin: '80px 0 48px 0',
						fontSize: { xs: '24px', sm: '28px' },
						lineHeight: { xs: '28.8px', sm: '33.6px' },
						fontWeight: '700',
						textAlign: 'center',
						color: textColorPrimary,
						maxWidth: props.descriptionMaxWidth,
					}}
				>
					{props.descriptionText}
				</Typography>
			</Stack>
			<Box sx={{ flex: '0', margin: '0 0 32px 0' }}>
				<Button
					variant='contained'
					onClick={props.onClickHandler}
					disabled={props.buttonDisabled}
				>
					{props.buttonText}
				</Button>
			</Box>
		</Stack>
	);
};

export default OnePageRejection;
