import './Loader.css';

export interface LoaderProps {
	style?: React.CSSProperties;
}

export default function Loader(props : Readonly<LoaderProps>): JSX.Element {
	return (
		<div className='container' style={props.style}>
			<svg className='spinner' viewBox='0 0 50 50'>
				<circle
					className='path'
					cx='25'
					cy='25'
					r='20'
					fill='none'
					strokeWidth='5'
				></circle>
			</svg>
		</div>
	);
}
