import { Typography } from '@mui/material';
import { KeyValuePair } from './ValueDescriptor.css';

export interface ValueDescriptorProps {
	title: string;
	desc?: string;
	value: string | number;
}

export default function ValueDescriptor(
	props: ValueDescriptorProps
): JSX.Element {
	return (
		<KeyValuePair>
			<Typography variant='h9' sx={{ color: 'text.secondary', fontWeight: '600' }}>
				{props.title}
			</Typography>
			{ props.desc &&<Typography variant='h9' sx={{ color: 'text.secondary', fontWeight: '600', padding:' 8px 0 0 0' }}>
				{props.desc}
			</Typography>}
			<Typography variant='p1' sx={{ color: 'text.primary' }} id={props.title.split(' ').join('')}>
				{props.value}
			</Typography>
		</KeyValuePair>
	);
}
