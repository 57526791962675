/* eslint-disable @typescript-eslint/no-unsafe-member-access */
import { Theme } from '@mui/system/createTheme';
import styled from '@mui/system/styled';
import { CSSProperties } from '@mui/styles';

export const FieldWrapper = styled('div')({
	display: 'flex',
	flexDirection: 'column',
});

export interface FieldProps {
	theme?: Theme;
	error: boolean;
}

export const getFieldStyle = (props: FieldProps): CSSProperties => {
	return {
		height: '56px',
		borderRadius: '4px',
		padding: '0 16px',
		border: props.error
			? `1px solid ${props.theme?.palette.error?.main as string}`
			: `1px solid ${props.theme?.palette.senary?.main as string}`,
		'&:focus-visible': {
			outline: 'unset',
		},
		width: 'calc(100% - 32px)',
		fontSize: '16px',
		lineHeight: '22.4px',
		fontFamily: 'Inter, sans-serif',
	};
};
