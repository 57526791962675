import Typography from '@mui/material/Typography';
import React, { useContext } from 'react';
import { ScrollToTopOnMount } from '../../../../../common/helpers/scroll-to-top-onmount';
import NestInput from '../../../../../components/FormItems/NestInput/NestInput';
import { LightLandingPageContext } from '../state/LightLandingPageContext';
import LightContactDataForm from './components/LightContactDataForm/LightContactDataForm';
import { Container } from '../../../../../common/styles/Layout.css';
import { Control, FormProvider, UseFormReturn } from 'react-hook-form';
import { Form } from './components/LightContactDataForm/LightContactDataForm.css';
import LightCreditInfoSection from './components/lightCreditInfoSection/LightCreditInfoSection';
import { MagicWordsContainer } from './components/lightCreditInfoSection/LightCreditInfoSection.css';
import { ILightContactDataProperties } from './LightCreditInfoAndContactDataFormModels';
import { magicWordsValidator } from 'src/common/validators/magicWordsValidator';

const LightCreditInfoAndContactDataForm = (): JSX.Element => {
	const lightLandingPageContext = useContext(LightLandingPageContext);

	return (
		<FormProvider
			{...(lightLandingPageContext.methods as UseFormReturn<ILightContactDataProperties>)}
		>
			<ScrollToTopOnMount />
			<Form
				onSubmit={(
					lightLandingPageContext.methods as UseFormReturn<ILightContactDataProperties>
				).handleSubmit(lightLandingPageContext.handleSubmit)}
			>
				<LightCreditInfoSection />

				{window.CONFIG.MAGIC_WORDS && (
					<MagicWordsContainer>
						<NestInput
							control={
								lightLandingPageContext.methods?.control as unknown as Control
							}
							name='magicWords'
							label='Magic Words'
							rules={{
								pattern: {
									value: magicWordsValidator,
									message: 'Niepoprawna wartość'
								}
							}}
						/>
					</MagicWordsContainer>
				)}
				<Container sx={{width: '848px'}}>
					<Typography
						variant='h2'
						sx={{
							textAlign: 'left',
							width: '100%',
							maxWidth: { xs: '380px', md: 'unset' },
							fontSize: { xs: '28px', md: '34px' },
							lineHeight: { xs: '34px', md: '40px' },
							marginBottom: { md: '62px', xs: '32px' },
							marginTop: '60px',
						}}
					>
						Rozpocznij wniosek od podania poniższych danych. Jeśli napotkasz
						problem, skontaktujemy się z Tobą.
					</Typography>
					<LightContactDataForm />
				</Container>
			</Form>
		</FormProvider>
	);
};

export default LightCreditInfoAndContactDataForm;
