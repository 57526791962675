import { Switch, Typography } from '@mui/material';
import { MediaConsent } from 'src/components/AgreementCollection/AgreementsModels';
import { AgreementHeadingContainer, AgreementTextContainer } from 'src/components/AgreementCollection/Agreement/Agreement.css';

interface LeadAgreementsProps {
	agreements: MediaConsentFormModel[];
	onAgreementSelectionChange: (index: number) => void;
	title: string;
}

export interface MediaConsentFormModel extends MediaConsent {
	isTouched: boolean;
}

export const LeadAgreements = (props: LeadAgreementsProps): JSX.Element => {
	return (
		<>
			<Typography variant='p2' sx={{ marginBottom: '24px' }}>
				{props.title}
			</Typography>
			{props.agreements.map(
				(x: MediaConsentFormModel, index: number): JSX.Element => (
					<AgreementTextContainer key={x.symbol} sx={{ margin: '0 0 24px 0' }}>
						<AgreementHeadingContainer>
							<Typography variant='p2' sx={{ display: 'inline-block', textAlign: 'justify' }}>
								{x.content}
							</Typography>
							{x.isRequired && !x.isSelected && x.isTouched && (
								<Typography variant='h9' sx={{ color: 'error.main', margin: '8px 0 0 0' }}>
									Ta zgoda jest wymagana
								</Typography>
							)}
						</AgreementHeadingContainer>
						<Switch
							sx={{ marginLeft: { xs: '16px', md: '24px' } }}
							// eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
							checked={x.isSelected ?? false}
							onChange={(): void => props.onAgreementSelectionChange(index)}
						/>
					</AgreementTextContainer>
				)
			)}
		</>
	);
};
