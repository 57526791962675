import Stack from '@mui/material/Stack';
import { useEffect, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import useFetch from 'src/common/api/UseFetch';
import { IGoogleAnalyticsData } from 'src/common/models/googleAnalyticsDataModels';
import { interpolatePath } from 'src/routing/RoutingService';
import { allClientFormCodes } from '../../../common/api/ErrorCodes';
import { getPartnerLogoLead } from '../../../common/helpers/get-partner-logo-helper';
import { LeadEndRejectionRoute, LeadEndRoute, LeadTechnicalErrorRoute } from '../../../routing/Routing';
import { resolveIndexTokenByType } from '../../common/index/IndexTokenResolver';
import LeadForm from '../../components/LeadForm/LeadForm';
import { ILeadForm } from '../../components/LeadForm/LeadFormConstants';
import LeadStart from '../../components/LeadStart/LeadStart';
import { AgreementModelToSendLead, processLeadConfig } from '../common/LeadApi';
import { ILeadInitialData, LeadProductType } from '../lead-new-application/LeadNewApplicationApi';
import { CLLegalNote1, LeadVariantsOfNote } from './LeadCLConst';

interface LeadCLProps {
  initialData: ILeadInitialData;
}

export default function LeadCL({ initialData }: LeadCLProps): JSX.Element {
  const [creditAmount, setCreditAmount] = useState(0);
  const [partnerLogoLink, setPartnerLogoLink] = useState('');
  const params = useParams();
  const navigate = useNavigate();
  
  const urlParamA = new URLSearchParams(useLocation().search).get('a');
  
  const processLead = useFetch(
    processLeadConfig,
    false,
    (resp): void => {
      const googleAnalyticsData: IGoogleAnalyticsData = {
        applicationId: resp.applicationId,
        userId: resp.userId,
        partnerId: new URL(window.location.href).searchParams.get('Partner_ID'),
      };
      navigate(interpolatePath(LeadEndRoute.path, { guid: params.guid ?? resolveIndexTokenByType()?.token }), { state: googleAnalyticsData });
    },
    false,
    (error): void => {
      
      if (allClientFormCodes.includes(error.message)) {
        const googleAnalyticsData: IGoogleAnalyticsData = {
          applicationId: '',
          userId: '',
          partnerId: new URL(window.location.href).searchParams.get('Partner_ID'),
        };
        navigate(interpolatePath(LeadEndRejectionRoute.path, { guid: params.guid ?? resolveIndexTokenByType()?.token }), {
          state: googleAnalyticsData,
        });
      } else {
        navigate(interpolatePath(LeadTechnicalErrorRoute.path, { guid: params.guid ?? resolveIndexTokenByType()?.token }));
      }
    },
    (): void =>
      navigate(
        interpolatePath(LeadTechnicalErrorRoute.path, {
          guid: params.guid ?? resolveIndexTokenByType()?.token,
        })
      ),
    false
  );
  
  useEffect((): void => {
    if (initialData.logoUrl) {
      setPartnerLogoLink(getPartnerLogoLead(initialData.logoUrl));
    }
    
    if (!initialData.displayCalculator) {
      setCreditAmount(initialData.parameters.maxAmount);
    }
  }, []);
  
  const onSubmit = (data: ILeadForm, agreements: AgreementModelToSendLead[], recaptchaSecretId: string): void => {
    processLead.mutate({
      campaignNumber: initialData.campaignNumber,
      sourceId: initialData.sourceId,
      creditAmount: creditAmount,
      brokerId: initialData.brokerId,
      brokerDescription: initialData.brokerDescription,
      firstName: data.firstName ?? '',
      lastName: data.lastName ?? '',
      phoneNumber: data.phoneNumber ? data.phoneNumber : null,
      nip: data.nip ?? '',
      agreements: agreements,
      ReCaptcha: recaptchaSecretId,
      ...(urlParamA ? { customField: urlParamA } : {}),
    });
  };
  
  const legalNoteByItsType = (): string | undefined => {
    const note = LeadVariantsOfNote.find(
      (x): string | undefined => {
        return x.token.find((t): boolean => t.toUpperCase() === (params.guid?.toUpperCase() || resolveIndexTokenByType()?.token?.toUpperCase()))
      }
    )?.note;
    if (!note) {
      return CLLegalNote1;
    }
    return note;
  };
  
  const legalNote: string | undefined = legalNoteByItsType();
  
  return (
    <Stack sx={{ width: '100%', flexDirection: { xs: 'column', md: 'row' } }}>
      <LeadStart
        leadProductType={LeadProductType.CashLoan}
        minLoanAmount={initialData.parameters.minAmount}
        maxLoanAmount={initialData.parameters.maxAmount}
        periods={initialData.parameters.periods}
        commission={initialData.parameters.commission}
        markUp={initialData.parameters.markUp}
        wibor={initialData.wibor}
        legalNote={legalNote as string}
        displayCalculator={initialData.displayCalculator}
        logoUrl={partnerLogoLink}
        onCreditAmountChange={setCreditAmount}
      />
      
      <LeadForm onSubmit={onSubmit} isLoading={processLead.isPending} showCaptcha={initialData.showCaptcha}/>
    </Stack>
  );
}
