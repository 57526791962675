import BaseFetch from '../../../common/api/BaseFetch';
import { ApiConfig } from '../../../common/api/FetchTypes';
import Address from '../../../models/Address';
import ClientData from '../../../models/ClientData';
import ContactData from '../../../models/ContactData';
import { CompanyData } from "../../../models/CompanyData";

export interface FullClientData {
	clientData: ClientData;
	clientAddress: Address;
	clientCorrespondenceAddress: Address;
	companyData: CompanyData;
	companyAddress: Address;
	companyCorrespondenceAddress: Address;
	contactData: ContactData;
	isCompanyCorrespondenceAddressSameAsCompanyAddress: boolean;
	isCorrespondenceAddressSameAsAddress: boolean;
}

const clientDataKey = 'getClientData';

function getClientData(_props: never): Promise<FullClientData> {
	return BaseFetch({ url: 'ClientData', method: 'GET' });
}

const submitApplicationKey = 'submitApplication';

function submitApplication(_props: never): Promise<never> {
	return BaseFetch({ url: 'ClientData/Submit', method: 'POST' });
}

export const GetClientDataConfig: ApiConfig<FullClientData, never> = {
	key: clientDataKey,
	fn: getClientData,
};

export const SubmitDataConfig: ApiConfig<never, never> = {
	key: submitApplicationKey,
	fn: submitApplication,
};
