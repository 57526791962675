import { IdentityVerificationRoute } from '../../../../routing/Routing';
import { CustomizedState } from '../VerificationFailed';

export const getLocationState = (areAttemptsLeft: boolean): CustomizedState => {
	const locationState: CustomizedState = {
		message: areAttemptsLeft
			? 'Nie udało się potwierdzić Twojej tożsamości.'
			: 'Przekroczono dopuszczalną liczbę nieudanych prób weryfikacji tożsamości.',
		buttonText: areAttemptsLeft ? 'Spróbuj ponownie' : 'Zakończ',
		path: areAttemptsLeft
			? IdentityVerificationRoute.path
			: 'https://nestbank.pl/',
		messageMaxWidth: areAttemptsLeft ? 340 : 547,
	};
	return locationState;
};
