import { ErrorCodeMap, ValidationErrorCode } from '../api/ErrorCodes';
import { FieldNames } from '../constants/FieldNames';

interface ValidationParams {
	ComparisonValue?: number;
	MaxLength?: number;
	MinLength?: number;
}

function handleGenericParams(message: string, params: string[]): string {
	for (let i = 0; i < params.length; i++) {
		message = message.replace(`{${i}}`, params[i]);
	}
	return message;
}

function handleValidationParams(message: string, params: string[]): string {
	if (params.length !== 2) {
		console.warn('Validation error should have 2 parameters');
		return '';
	}
	const fieldName = FieldNames.find((x): boolean =>
		x.formFieldNames.includes(params[0])
	)?.messageFieldName;
	const validationParams = JSON.parse(params[1]) as ValidationParams;
	message = message.replace('{0}', `'${fieldName ?? params[0]}'`);
	for (const key in validationParams) {
		if (Object.prototype.hasOwnProperty.call(validationParams, key)) {
			const value = Object.getOwnPropertyDescriptor(validationParams, key)
				?.value as string;
			message = message.replace(`{${key}}`, value);
		}
	}
	return message;
}

export default function getErrorMessage(
	errorCode: string | undefined,
	params: string[] | undefined
): string {
	if (!errorCode) {
		console.warn(`Missing error code`);
		return '';
	}
	const codes = errorCode.split('_');
	if (codes.length !== 3 || codes[0] !== 'RBF') {
		console.warn(`Invalid error code ${errorCode}`);
		return '';
	}
	let message = ErrorCodeMap[codes[1]]?.[codes[2]];
	if (!message) {
		console.warn(`Message for code ${errorCode} not found`);
		return '';
	}
	if (codes[1] === ValidationErrorCode) {
		if (!params) {
			console.warn('Validation error should have 2 parameters');
			return '';
		}
		message = handleValidationParams(message, params);
	} else if (params) {
		message = handleGenericParams(message, params);
	}

	return `${message}`;
}
