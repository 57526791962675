import BaseFetch from '../../../../common/api/BaseFetch';
import { ApiConfig, ApiVersion } from '../../../../common/api/FetchTypes';
import { IRejectionResonsForApplicationResp } from './LightAttachDocumentsModels';

const rejectionResonsForApplicationKey = 'rejectionResonsForApplicationKey';

function rejectionResonsForApplication(): Promise<never> {
	return BaseFetch({
		url: 'AttachingDocuments/RejectionResonsForApplication',
		method: 'GET',
		version: ApiVersion.Light
	});
}

export const RejectionResonsForApplicationConfig: ApiConfig<IRejectionResonsForApplicationResp, never> = {
	key: rejectionResonsForApplicationKey,
	fn: rejectionResonsForApplication,
};
