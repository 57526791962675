import { CSSProperties } from '@mui/material/styles/createMixins';
import { styled } from '@mui/system';
import { senaryMainColor } from '../../../../theme/palette/palette';
import { mainTheme } from '../../../../theme/mainTheme';

export const Image = styled('img')`
	align-self: center;
	margin-right: 16px;
	height: 49px;
`;

export const DocumentSection = styled('div')({
	margin: '0 0 32px 0',
	[mainTheme.breakpoints.up('md')]: {
		margin: '0 0 26px 0'
	},
});

export const DocumentSectionElement = styled('div')`
	cursor: pointer;
	display: flex;
	align-items: center;
	height: 100%;
`;

export const SmsSigningSecition = styled('div')({
	display: 'flex',
	flexDirection: 'column',
	justifyContent: 'center',
	[mainTheme.breakpoints.up('md')]: {
		justifyContent: 'space-between',
		flexDirection: 'row',
	},
});

export const SmsInputContainer = styled('div')({
	maxWidth: '312px',
	height: '125px',
	marginTop: '32px',
});

export const SendButtonContainer = styled('div',	{}
)<{sentCode: boolean}>(
	(props: {sentCode: boolean} ): CSSProperties => ({
	display: 'flex',
	width: '100%',
	flexDirection: 'column',
	textAlign: 'center',
	alignItems: 'center',
	margin: props.sentCode ? '186px 0 57px 0':'21px 0 57px 0',
	justifyContent: 'space-between',
	[mainTheme.breakpoints.up('md')]: {
		width: '306px',
	},
		[mainTheme.breakpoints.down('md')]: {
			margin:'32px 0'
		},
		
}));

export const SectionSeparation = styled('hr')({
	borderTop: '1px solid ' + senaryMainColor,
	width: '100%',
});

export const SectionSeparationContaier = styled('div')({
	width: '100%',
	height: '5px',
});
