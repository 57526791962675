import Footer from '../../components/Footer/Footer';
import Header from '../../components/Header/Header';
import { BreadcrumbStep } from '../RouteSpec';
import { PropsChildContainer } from './PageWrapper.css';
import { FeatureFlagContextProvider } from '../../context/FeatureFlagContext/FeatureFlagContext';

const useBlockBackButton = (): void => {
	window.dispatchEvent(new CustomEvent('navigationhandler'));
};

interface PageWrapperProps {
	showSaveStateButton: boolean;
	Child: () => JSX.Element;
	breadcrumbStepName: BreadcrumbStep;
	showApplicationNumber: boolean;
	showPartnerLogo: boolean;
	showHeader: boolean | undefined;
	showFooter: boolean | undefined;
}

const PageWrapper = (props: PageWrapperProps): JSX.Element => {
	useBlockBackButton();
	const queryParams = new URLSearchParams(window.location.search);
	const type = queryParams.get('t');
	const typesWithHeaderAndFooter = ['LCLA', 'LOVD']
	return (
		<>
			{((typesWithHeaderAndFooter.includes(type as string) || (props.showHeader ?? true)) ?? true ) && (
				<Header
					showSaveStateButton={props.showSaveStateButton}
					breadcrumbStep={props.breadcrumbStepName}
					showApplicationNumber={props.showApplicationNumber}
					showPartnerLogo={props.showPartnerLogo}
				/>
			)}
			<PropsChildContainer currentStep={props.breadcrumbStepName}>
				<FeatureFlagContextProvider>
					<props.Child />
				</FeatureFlagContextProvider>
			</PropsChildContainer>
			{((typesWithHeaderAndFooter.includes(type as string) || (props.showFooter ?? true)) ?? true ) &&  <Footer />}
		</>
	);
};

export default PageWrapper;
