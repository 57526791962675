import { Location } from 'history';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import useFetch from '../../../../common/api/UseFetch';
import { getPartnerLogo } from '../../../../common/helpers/get-partner-logo-helper';
import {
	applicationIdKey,
	applicationNumberKey,
	applicationProcessKey,
	authTokenKey,
	getSessionStorageObj,
	locationKey,
	marketingPartnerIdKey,
	partnerLogoKey,
	partnerNameKey,
	processIdKey,
	setSessionStorageValue,
	userIdKey,
} from '../../../../common/sessionStorage/SessionStorageService';
import {
	getRegenerateApplicationSessionConfig,
	RegenerateApplicationSessionResult,
} from '../../../../components/IdentityVerificationInProgress/api/IdentityVerificationInProgressApi';
import Loader from '../../../../components/Loader/Loader';
import OnePageRejection from '../../../../components/OnePageRejection/OnePageRejection';
import { LightOvdVerificationFailedRoute, LightVerificationFailedRoute } from '../../../../routing/Routing';
import { navigateToRoute } from '../../../common/helpers/navigateToRoute';
import { CustomizedState } from '../../../rbf/rbf-verification-failed/VerificationFailed';
import { getLocationState } from './helpers/LightVerificationFailedHelpers';
import { pushLightEventVirtualpvVerificationFailed } from 'src/common/googleAnalyticsEvents/googleAnalyticsEvents';

const LightVerificationFailed = (): JSX.Element => {
	const [verificationData, setVerificationData] = useState<CustomizedState | null>(null);
	
	const navigate = useNavigate();
	const location: Location | undefined = getSessionStorageObj(locationKey);
	const state = location?.state as boolean | null;
	
	const regenerateApplicationSessionResult = useFetch(getRegenerateApplicationSessionConfig, false, (resp): void => {
		setSessionStorageData(resp);
	});

	useEffect((): void => {
		pushLightEventVirtualpvVerificationFailed();
	}, []);

	useEffect((): void => {
		setVerificationData(getLocationState(location?.state as boolean));
		const orderUuid: string = new URLSearchParams(location?.search).get('orderUuid') ?? '';
		
		if (orderUuid) {
			regenerateApplicationSessionResult.mutate({
				orderUuid: orderUuid,
				page: navigateToRoute(LightVerificationFailedRoute.page, LightOvdVerificationFailedRoute.page) as number,
			});
		}
	}, [state]);

	const setSessionStorageData = (data: RegenerateApplicationSessionResult): void => {
		setSessionStorageValue(applicationIdKey, data.applicationId);
		setSessionStorageValue(authTokenKey, data.token);
		setSessionStorageValue(applicationNumberKey, data.applicationNumber);
		setSessionStorageValue(marketingPartnerIdKey, data.marketingPartnerId);
		setSessionStorageValue(userIdKey, data.userId);
		data.partnerLogoName && setSessionStorageValue(partnerLogoKey, getPartnerLogo(data.partnerLogoName));
		setSessionStorageValue(partnerNameKey, data.partnerName);
		setSessionStorageValue(processIdKey, data.processId);
		setSessionStorageValue(applicationProcessKey, data.process.toString());
	};

	return (
		<>
			{verificationData ? (
				<OnePageRejection
					descriptionText={verificationData.message}
					onClickHandler={(): void => {
						verificationData.path.split('/')[0] === 'https:'
							? (window.location.href = verificationData.path)
							: verificationData.path && navigate(verificationData.path);
					}}
					buttonText={verificationData.buttonText}
					descriptionMaxWidth={verificationData.messageMaxWidth}
				/>
			) : (
				<Loader />
			)}
		</>
	);
};

export default LightVerificationFailed;
