import { useState } from 'react';
import { useForm, UseFormReturn } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { GetDownloadFileConfig } from '../../../../common/api/FileApi';
import useFetch from '../../../../common/api/UseFetch';
import { groupAgreementsBySection } from '../../../../common/helpers/group-agreements-by-section-helper';
import { CreateComponentContext, notImplementedFunction } from '../../../../context/CreateComponentContext';
import { FileType } from '../../../../enums/FileType';
import { IAgreementsStateSlice } from '../../../../store/slices/agreementsSlice/AgreementSliceModels';
import { saveAgreements } from '../../../../store/slices/agreementsSlice/AgreementsSlice';
import { RootState } from '../../../../store/State';
import { InitialLightData } from '../../../light/cl/cl-new-application/api/LightNewApplicationApi';
import { defaultValues } from '../light-index-credit-info-and-contact-data-form/components/LightIndexContactDataForm/LightIndexContactDataFormConstants';
import { ILightIndexContactDataProperties } from '../light-index-credit-info-and-contact-data-form/LightIndexCreditInfoAndContactDataFormModels';
import { GetAgreement } from '../../../light/cl/cl-landing-page/LightLandingPageApi';

const defaultLightIndexLandingPageContext: LightIndexLandingPageState = {
	creditPeriod: 0,
	onCreditPeriodChange: notImplementedFunction,
	handleClickShowMore: notImplementedFunction,
	agreementState: [],
	initialData: undefined,
	getAgreementsIsLoading: false,
};

interface LightIndexLandingPageState {
	creditPeriod: number;
	onCreditPeriodChange: (value: number) => void;
	handleClickShowMore: () => void;
	agreementState: IAgreementsStateSlice | [];
	methods?: UseFormReturn<ILightIndexContactDataProperties> | undefined;
	initialData: InitialLightData | undefined;
	getAgreementsIsLoading: boolean;
}

const lightIndexLandingPageContextHandler = (): LightIndexLandingPageState => {
	const [creditPeriod, setCreditPeriod] = useState(0);

	const dispatch = useDispatch();
	const initialData = { 
		enableBrokerIdField: true,
		initInstallment: 0, 
		maxCreditAmount: 0,
		partnerLogoName: "c96340e5-5ae1-4c82-a5a9-d73517fa6c03.png",
		partnerName: "Elzab",
		periods: [12, 24, 36, 48, 60],
		process: 4,
		selectedPeriod: 60, 
	} as  InitialLightData | undefined;

	const methods = useForm<ILightIndexContactDataProperties>({
		mode: 'all',
		defaultValues,
	});

	const agreementState = useSelector((state: RootState): IAgreementsStateSlice => state.agreements);

	const getAgreements = useFetch(GetAgreement, false, (result): void => {
		dispatch(saveAgreements(groupAgreementsBySection(result)));
	});

	const getInformationClauseMutation = useFetch(GetDownloadFileConfig(FileType.InformationClause, true), false);
	const onCreditPeriodChange = (value: number): void => {
		if (!initialData?.periods.includes(value) || creditPeriod === value) {
			return;
		}
		setCreditPeriod(value);
		handleCreditPeriod(value);
	};

	const handleCreditPeriod = (data: number): void => {
		setCreditPeriod(data);
	};

	const handleClickShowMore = (): void => {
		getInformationClauseMutation.mutate({} as never);
	};

	return {
		creditPeriod,
		onCreditPeriodChange: (value: number): void => onCreditPeriodChange(value),
		handleClickShowMore: (): void => handleClickShowMore(),
		agreementState,
		methods: methods,
		initialData,
		getAgreementsIsLoading: getAgreements.isPending,
	};
};

export const [LightIndexLandingPageContext, LightIndexLandingPageContextProvider] = CreateComponentContext<LightIndexLandingPageState>(
	defaultLightIndexLandingPageContext,
	lightIndexLandingPageContextHandler
);
