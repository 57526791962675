import BaseFetch from '../../../../common/api/BaseFetch';
import { ApiConfig, ApiVersion } from '../../../../common/api/FetchTypes';
import { IDocuments, IUploadInfoDocuments } from '../../../../models/AttachDocumentsModels';
import { ApplicationFileTypeString } from '../../enums/ApplicationFileType';

const getDocumentsKey = 'attachDocumentsKey';
const addFileToBlobKey = 'addFileToBlobKey';
const deleteDocumentKey = 'attachDocumentsKey';
const verifyDocumentsAndSendEmailToAnalystsKey = 'verifyDocumentsAndSendEmailToAnalystsKey';
const uploadInfoDocumentsKey = 'uploadInfoDocuments';

function verifyDocumentsAndSendEmailToAnalysts(): Promise<{
	bankAccountNumber: string;
}> {
	return BaseFetch({
		url: 'AttachingDocuments/Save',
		method: 'POST',
		version: ApiVersion.Light
	});
}

function addFileToBlob(props:{files:FormData; fileType: ApplicationFileTypeString}): Promise<never> {
	return BaseFetch({
		url: `AttachingDocuments/upload/${props.fileType}`,
		method: 'POST',
		body: props.files,
		version: ApiVersion.Light
	});
}

function getDocuments(): Promise<IDocuments[]> {
	return BaseFetch({
		url: 'AttachingDocuments',
		method: 'GET',
		version: ApiVersion.Light
	});
}

function deleteDocument(id: string): Promise<never> {
	return BaseFetch({
		url: `AttachingDocuments/${id}`,
		method: 'DELETE',
	});
}

function uploadInfoDocuments(): Promise<IUploadInfoDocuments> {
	return BaseFetch({
		url: 'AttachingDocuments/uploadInfo',
		method: 'GET',
		version: ApiVersion.Light
	});
}

export const VerifyDocumentsAndSendEmailToAnalystsConfig: ApiConfig<
	{ bankAccountNumber: string },
	FormData
> = {
	key: verifyDocumentsAndSendEmailToAnalystsKey,
	fn: verifyDocumentsAndSendEmailToAnalysts,
};

export const GetDocumentsConfig: ApiConfig<IDocuments[], IDocuments[]> = {
	key: getDocumentsKey,
	fn: getDocuments,
};

export const AddFileToBlobConfig: ApiConfig<never, {files:FormData; fileType: ApplicationFileTypeString}> = {
	key: addFileToBlobKey,
	fn: addFileToBlob,
};

export const DeleteDocumentConfig: ApiConfig<never, string> = {
	key: deleteDocumentKey,
	fn: deleteDocument,
};

export const UploadInfoDocumentsConfig: ApiConfig<IUploadInfoDocuments, string> = {
	key: uploadInfoDocumentsKey,
	fn: uploadInfoDocuments,
};
