import { QueryParamsType } from './FetchTypes';

export const defaultApiHeaders: HeadersInit = {
	'Content-Type': 'application/json',
};

export const defaultMode: RequestMode = 'cors';

export const AppIdTemplate = '{applicationId}';

export const getQueryParams = (paramsObj?: QueryParamsType): string => {
	let queryParams = '';
	if (paramsObj) {
		const queryParamsArr: string[] = [];
		for (const paramKey in paramsObj) {
			queryParamsArr.push(`${paramKey}=${paramsObj[paramKey].toString()}`);
		}
		queryParams =
			queryParamsArr.length > 0 ? '?' + queryParamsArr.join('&') : '';
	}
	return queryParams;
};

export const applyHeaders = (
	currentHeaders: HeadersInit,
	headers?: HeadersInit
): HeadersInit => {
	if (headers) {
		currentHeaders = { ...currentHeaders, ...headers };
	}
	return currentHeaders;
};

export const addAuthHeaders = (
	headers: HeadersInit,
	applicationId: string,
	token: string
): HeadersInit => {
	let authHeaders: HeadersInit;
	if (token && applicationId) {
		authHeaders = {
			Authorization: token,
			'x-token': token,
			'x-applicationid': applicationId,
		};
	} else {
		authHeaders = {
			Authorization: token,
		};
	}

	return { ...headers, ...authHeaders };
};

export const handleFile = async (response: Response): Promise<void> => {
	await response.blob();
	window.open(response.url, '_blank');
};

export const handleFileDownload = async (response: Response): Promise<void> => {
	const blob = await response.blob();
	const a = document.createElement('a');
	a.href = window.URL.createObjectURL(blob);
	a.download = getFileName(response.headers, 'plik.pdf');
	a.click();
	a.remove();
};

const getFileName = (headers: Headers, defaultValue: string): string => {
	let fileName = defaultValue;
	const contDisp = headers.get('content-disposition');
	if (contDisp) {
		const match = contDisp.match(/filename=([^;\n]+)/i);
		if (match && match.length > 1) {
			fileName = match[1].trim();
		}
	}
	return fileName;
};
