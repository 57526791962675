import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';
import Typography from '@mui/material/Typography';
import { useState } from 'react';
import checkbox from '../../../../../../common/images/checkbox.svg';
import { textColorPrimary } from '../../../../../../theme/palette/palette';
import { CheckboxContainer, SwitchContainer } from './LightOvdSwitchPartOfForm.css';
import { countriesOutsideOfPolandData } from './LightOvdSwitchPartOfFormConstants';

const LightOvdSwitchPartOfForm = (): JSX.Element => {
    const [doesCompanyBuyOrSellGoodsOrServicesOutsideOfPoland, setDoesCompanyBuyOrSellGoodsOrServicesOutsideOfPoland] = useState(false);
    const [countriesOutsideOfPoland, setCountriesOutsideOfPoland] = useState(countriesOutsideOfPolandData);
    const [isCompanyActivityRelatedToTradingOrStorageCryptocurrencies, setIsCompanyActivityRelatedToTradingOrStorageCryptocurrencies] = useState(false);
    const [isCompanyApplyingForOrHavePaymentInstitutionStatus, setIsCompanyApplyingForOrHavePaymentInstitutionStatus] = useState(false);
    const [isCompanyActivitySubjectFundsOrAssetsAcquisitionForInvestment, setIsCompanyActivitySubjectFundsOrAssetsAcquisitionForInvestment] = useState(false);
    
    const handleChangeCheckbox = (checked: boolean, id: number): void => {
        const copiedObj = [...countriesOutsideOfPoland];
        copiedObj[id].checked = checked;
        setCountriesOutsideOfPoland(copiedObj)
    }
    
    return (
        <>
            <SwitchContainer>
                <Typography>Czy firma kupuje lub sprzedaje towary/usługi poza granicami Polski?</Typography>
                <Switch
                    sx={{ marginLeft: { md: '40px', xs: '15px' } }}
                    checked={doesCompanyBuyOrSellGoodsOrServicesOutsideOfPoland}
                    onChange={(): void => setDoesCompanyBuyOrSellGoodsOrServicesOutsideOfPoland(!doesCompanyBuyOrSellGoodsOrServicesOutsideOfPoland)}
                />
            </SwitchContainer>
            {doesCompanyBuyOrSellGoodsOrServicesOutsideOfPoland && (
                <CheckboxContainer>
                    {countriesOutsideOfPoland.map((item): JSX.Element => {
                        return (
                            <FormControlLabel
                                key={item.id}
                                control={
                                    <Checkbox
                                        checked={item.checked}
                                        onChange={(event): void => {
                                            handleChangeCheckbox(event.target.checked, item.id)
                                        }}
                                        disableRipple
                                        checkedIcon={
                                            <img src={checkbox} alt='checkbox' style={{ padding: '2px' }} />
                                        }
                                        sx={{
                                            '& .MuiSvgIcon-root': { fontSize: 31, width: 31, height: 31 },
                                            '&.MuiCheckbox-root': { color: '#9191A1' },
                                        }}
                                    />
                                }
                                label={
                                    <Typography
                                        variant='p2'
                                        sx={{
                                            fontWeight: 400,
                                            color: textColorPrimary,
                                        }}
                                    >
                                        {item.content}
                                    </Typography>
                                }
                                sx={{
                                    marginBottom: '5px',
                                    '&.MuiFormControlLabel-root': { alignItems: 'center' },
                                }}
                            />
                        )
                    })}
                </CheckboxContainer>
            )}
            <SwitchContainer sx={{marginTop: doesCompanyBuyOrSellGoodsOrServicesOutsideOfPoland ? '21px' : '0'}}>
                <Typography>Czy działalność firmy jest związana z obrotem lub przechowywaniem kryptowalut?</Typography>
                <Switch
                    sx={{ marginLeft: { md: '40px', xs: '15px' } }}
                    checked={isCompanyActivityRelatedToTradingOrStorageCryptocurrencies}
                    onChange={(): void => setIsCompanyActivityRelatedToTradingOrStorageCryptocurrencies(!isCompanyActivityRelatedToTradingOrStorageCryptocurrencies)}
                />
            </SwitchContainer>
            <SwitchContainer>
                <Typography>Czy firma posiada lub ubiega się o status Krajowej Instytucji Płatniczej / Małej Instytucji Płatniczej / Biura Usług Płatniczych?</Typography>
                <Switch
                    sx={{ marginLeft: { md: '40px', xs: '15px' } }}
                    checked={isCompanyApplyingForOrHavePaymentInstitutionStatus}
                    onChange={(): void => setIsCompanyApplyingForOrHavePaymentInstitutionStatus(!isCompanyApplyingForOrHavePaymentInstitutionStatus)}
                />
            </SwitchContainer>
            <SwitchContainer>
                <Typography>Czy przedmiotem działalności firmy jest pozyskiwanie środków finansowych / aktywów od osób fizycznych w celach inwestycyjnych?</Typography>
                <Switch
                    sx={{ marginLeft: { md: '40px', xs: '15px' } }}
                    checked={isCompanyActivitySubjectFundsOrAssetsAcquisitionForInvestment}
                    onChange={(): void => setIsCompanyActivitySubjectFundsOrAssetsAcquisitionForInvestment(!isCompanyActivitySubjectFundsOrAssetsAcquisitionForInvestment)}
                />
            </SwitchContainer>
           
        </>
    )
}

export default LightOvdSwitchPartOfForm;
