import { createTheme, Theme } from '@mui/material';
import { breakpoints } from './breakpoints/breakpoints';
import { components } from './components/components';
import { palette } from './palette/palette';
import { typography } from './typography/typography';

export const mainTheme: Theme = createTheme({
    palette: palette,
    typography: typography,
    components: components,
    breakpoints: breakpoints,
})
