import { IAddressPropertiesError } from '../../../common/models/addressAndErrorsModels';
import {
	IAddressFormErrors,
	ICorrespondenceAddressFormErrors,
	PersonalDataProperties,
} from './PersonalDataModels';

export const defaultValues: PersonalDataProperties = {
	firstName: '',
	lastName: '',
	socialSecurityNumber: '',
	idCard: '',
	countryOfBirth: '',
	mothersMaidenName: '',
	address: {
		street: '',
		houseNumber: '',
		apartmentNumber: '',
		postalCode: '',
		city: '',
	},
	isCorrespondenceAddressSameAsAddress: true,
	correspondenceAddress: {
		street: '',
		houseNumber: '',
		apartmentNumber: '',
		postalCode: '',
		city: '',
	},
	clientWealth: [],
};

export const addressFormControlNames = {
	street: 'address.street',
	houseNumber: 'address.houseNumber',
	apartmentNumber: 'address.apartmentNumber',
	postalCode: 'address.postalCode',
	city: 'address.city',
};

export const correspondenceAddressFormControlNames = {
	street: 'correspondenceAddress.street',
	houseNumber: 'correspondenceAddress.houseNumber',
	apartmentNumber: 'correspondenceAddress.apartmentNumber',
	postalCode: 'correspondenceAddress.postalCode',
	city: 'correspondenceAddress.city',
};

export const getErrorsAddressForm = (
	errors: IAddressFormErrors
): IAddressPropertiesError => {
	return {
		street: errors.address?.street,
		houseNumber: errors.address?.houseNumber,
		apartmentNumber: errors.address?.apartmentNumber,
		postalCode: errors.address?.postalCode,
		city: errors.address?.city,
	};
};

export const getErrorsCorrespondenceAddressForm = (
	errors: ICorrespondenceAddressFormErrors
): IAddressPropertiesError => {
	return {
		street: errors.correspondenceAddress?.street,
		houseNumber: errors.correspondenceAddress?.houseNumber,
		apartmentNumber: errors.correspondenceAddress?.apartmentNumber,
		postalCode: errors.correspondenceAddress?.postalCode,
		city: errors.correspondenceAddress?.city,
	};
};
