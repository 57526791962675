import { SxProps } from '@mui/material';
import { Theme } from '@mui/material/styles/createTheme';

export const confirmationOfSendingModalTexts = {
    content: 'Twój wniosek został zapisany',
    footer: 'Sprawdź maila. Znajdziesz tam instrukcję powrotu do swojego wniosku.'
}

export const textInBodyModalStyles: SxProps<Theme> = {
    padding: {md: '0 161px', sm: '0 70px', xs: '0 10px'}
}
