import { styled } from '@mui/system';

const flexDirectionColumn = {
	display: 'flex',
	flexDirection: 'column',
};

export const Form = styled('form')({
	...(flexDirectionColumn as Record<string, string>),
	width: '100%',
});

export const FormSectionWrapper = styled('div')({
	display: 'flex',
	width: '100%',
	flexWrap: 'wrap',
});

export const ButtonTextContainer = styled('div')({
	width: '100%',
	...(flexDirectionColumn as Record<string, string>),
	justifyContent: 'center',
});

export const InputsContainer = styled('div')({
	...(flexDirectionColumn as Record<string, string>),
	maxWidth: '380px',
	width: '100%'
});

export const FieldsWrapper = styled('div')({
	...(flexDirectionColumn as Record<string, string>),
});
