import { styled } from '@mui/system';
import { mainTheme } from '../../../theme/mainTheme';
import { buttonsBreakpoint } from '../../../theme/breakpoints/breakpoints';
import hexIconViolet from '../../../common/images/hexIconViolet.svg';

export const HexListItem = styled('div')({
	display: 'flex',
	alignItems: 'center',
	position: 'relative',
	marginBottom: '28px',
});

export const HexListItemNumber = styled('div')({
	fontWeight: 'bold',
	paddingRight: '2px',
	paddingBottom: '1px',
});

export const InputContainer = styled('div')({
	maxWidth: '380px',
});

export const HexIconContainer = styled('div')({
	display: 'flex',
	minWidth: '30px',
	height: '30px',
	justifyContent: 'center',
	alignItems: 'center',

	backgroundImage: `url(${hexIconViolet})`,
	backgroundSize: 'contain',
	backgroundRepeat: 'no-repeat',
});

export const ButtonContainer = styled('div')({
	width: '100%',
	display: 'flex',
	justifyContent: 'flex-end',
	marginTop: '50px',
	marginBottom: '50px',

	[mainTheme.breakpoints.down(buttonsBreakpoint as number)]: {
		justifyContent: 'center',
	},
});

export const ContentContainer = styled('div')({
	minHeight: '380px',
});

export const InfoSmsSectionContainer = styled('div')({
	display: 'flex',
	padding: '20px 0',
});

export const InfoIcon = styled('img')({
	height: '30px',
	maxWidth: '30px',
	marginRight: '10px',
});

export const InfoTextSpan = styled('span')({
	display: 'flex',
	margin: '0 0 10px 0',
});
