import { styled } from '@mui/material/styles';
import { BreadcrumbStep } from '../RouteSpec';
import { CSSProperties } from '@mui/material/styles/createMixins';
import { mainTheme } from '../../theme/mainTheme';

interface ChildProps {
	currentStep: BreadcrumbStep;
}

export const PropsChildContainer = styled(
	'div',
	{}
)<ChildProps>(
	(props: ChildProps): CSSProperties => ({
		minHeight:
			props.currentStep === BreadcrumbStep.DOESNT_EXIST
				? 'calc(100vh - 115px - 75px);'
				: 'calc(100vh - 115px - 75px - 105px)', // 115px - footer, 75px - header, 105px - Breadcrumbs
		[mainTheme.breakpoints.down('sm')]: {
			minHeight:
				props.currentStep === BreadcrumbStep.DOESNT_EXIST
					? 'calc(100vh - 115px - 110px);'
					: 'calc(100vh - 115px - 110px - 67px)', // 115px - footer, 110px - header, 67px - Breadcrumbs
		},
	})
);
