import Typography from '@mui/material/Typography';
import React, { useContext } from 'react';
import { ScrollToTopOnMount } from '../../../../common/helpers/scroll-to-top-onmount';
import { LightIndexLandingPageContext } from '../state/LightIndexLandingPageContext';
import LightIndexContactDataForm from './components/LightIndexContactDataForm/LightIndexContactDataForm';
import { Container } from '../../../../common/styles/Layout.css';
import { FormProvider, UseFormReturn } from 'react-hook-form';
import LightIndexCreditInfoSection from './components/LightIndexCreditInfoSection/LightIndexCreditInfoSection';
import { ILightIndexContactDataProperties } from './LightIndexCreditInfoAndContactDataFormModels';

const LightIndexCreditInfoAndContactDataForm = (): JSX.Element => {
	const lightIndexLandingPageContext = useContext(LightIndexLandingPageContext);

	return (
		<FormProvider
			{...(lightIndexLandingPageContext.methods as UseFormReturn<ILightIndexContactDataProperties>)}
		>
		<ScrollToTopOnMount />
			<LightIndexCreditInfoSection />

			<Container>
				<Typography
					variant='h2'
					sx={{
						textAlign: 'left',
						width: '100%',
						maxWidth: { xs: '380px', md: 'unset' },
						fontSize: { xs: '28px', md: '34px' },
						lineHeight: { xs: '34px', md: '40px' },
						marginBottom: { md: '62px', xs: '32px' },
						marginTop: '60px',
					}}
				>
					Rozpocznij wniosek od podania poniższych danych. Jeśli napotkasz
					problem, skontaktujemy się z Tobą.
				</Typography>
				<LightIndexContactDataForm />
			</Container>
		</FormProvider>
	);
};

export default LightIndexCreditInfoAndContactDataForm;
