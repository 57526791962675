import { ApplicationProcess } from 'src/common/constants/application-process';
import BaseFetch from '../../../common/api/BaseFetch';
import { ApiConfig, ApiVersion } from '../../../common/api/FetchTypes';

export interface RegenerateApplicationSessionResult {
	applicationId: string;
	applicationNumber: string;
	partnerLogoName?: string;
	partnerName: string;
	process: ApplicationProcess;
	processId: string;
	token: string;
	marketingPartnerId: string;
	userId: string;
}

export interface RegenerateApplicationSessionProp {
	orderUuid: string;
	page: number;
}

export interface VerificationResult {
	isPositivelyVerified: boolean | null;
	areAttemptsLeft: boolean;
}

const getRegenerateApplicationSessionKey = 'getRegenerateApplicationSessionKey';

function getRegenerateApplicationSession(data: RegenerateApplicationSessionProp): Promise<RegenerateApplicationSessionResult> {
	return BaseFetch({
		url: `IdentityVerification/regenerateApplicationSession`,
		method: 'POST',
		body: data,
	});
} //od razu po przejsciu na link zwrocony z BE wykonuje ten endpoint jak sie wykona to przekierowuje na konkretną wersje apki

export const getRegenerateApplicationSessionConfig: ApiConfig<RegenerateApplicationSessionResult, RegenerateApplicationSessionProp> = {
	key: getRegenerateApplicationSessionKey,
	fn: getRegenerateApplicationSession,
};

const getVerificationResultKey = 'getVerificationResultKey';

function getVerificationResult(): Promise<VerificationResult> {
	return BaseFetch({
		url: 'IdentityVerification/result',
		method: 'GET',
	});
}

export const getVerificationResultConfig: ApiConfig<VerificationResult, never> = {
	key: getVerificationResultKey,
	fn: getVerificationResult,
};

const getLightVerificationResultKey = 'getLightVerificationResultKey';

function getLightVerificationResult(): Promise<VerificationResult> {
	return BaseFetch({
		url: 'IdentityVerification/incomeVerificationResult',
		method: 'GET',
		version: ApiVersion.Light,
	});
}

export const getLightVerificationResultConfig: ApiConfig<VerificationResult, never> = {
	key: getLightVerificationResultKey,
	fn: getLightVerificationResult,
};
