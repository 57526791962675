import {
	CreditDescriptionBackground,
	CreditDescriptionItem,
	CreditDescriptionItemImg,
	CreditDescriptionItemsContainer,
} from './CreditDescription.css';
import { Container } from '../../common/styles/Layout.css';
import lowerInstallment from '../../common/images/lower_installment.svg';
import creditHolidays from '../../common/images/credit_holidays.svg';
import extendLoanPeriod from '../../common/images/extend_loan_period.svg';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';

export default function CreditDescription(): JSX.Element {
	return (
		<CreditDescriptionBackground>
			<Container>
				<Typography variant='h3' sx={{ marginBottom: '60px' }}>
					Wiemy, że rynek jest zmienny.
					<br />U nas kredyt{' '}
					<span style={{ color: '#FFFFFF' }}>
						spłacasz tak, jak Ci wygodnie.
					</span>
				</Typography>
				<Grid
					container
					direction='row'
					alignItems='start'
					sx={CreditDescriptionItemsContainer}
				>
					<Grid xs={12} sm={4} md={3} item sx={CreditDescriptionItem}>
						<CreditDescriptionItemImg src={lowerInstallment} alt='icon' />
						<Typography sx={{ marginBottom: '16px' }} variant='h4'>
							Obniż ratę
						</Typography>
						<Typography variant='p2' sx={{ maxWidth: '150px' }}>
							Możesz obniżyć ratę na określony czas
						</Typography>
					</Grid>

					<Grid xs={12} sm={4} md={3} item sx={CreditDescriptionItem}>
						<CreditDescriptionItemImg src={creditHolidays} alt='icon' />
						<Typography sx={{ marginBottom: '16px' }} variant='h4'>
							Wakacje
							<br />
							kredytowe
						</Typography>
						<Typography variant='p2' sx={{ maxWidth: '150px' }}>
							Zawieś spłatę
							<br /> na 3 miesiące
						</Typography>
					</Grid>
					<Grid xs={12} sm={4} md={3} item sx={CreditDescriptionItem}>
						<CreditDescriptionItemImg src={extendLoanPeriod} alt='icon' />
						<Typography sx={{ marginBottom: '16px' }} variant='h4'>
							Wydłuż czas
							<br /> kredytowania
						</Typography>
						<Typography variant='p2' sx={{ maxWidth: '150px' }}>
							Nawet o 6 miesięcy
						</Typography>
					</Grid>
				</Grid>
			</Container>
		</CreditDescriptionBackground>
	);
}
