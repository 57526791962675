import { styled } from '@mui/material/styles';
import { CSSProperties } from '@mui/styles';
import DatePicker from 'react-datepicker';
import { FieldProps, getFieldStyle } from '../FormItems.css';

export const DatePickerField = styled(
	DatePicker,
	{}
)<FieldProps>(
	(props: FieldProps): CSSProperties => ({
		...getFieldStyle(props),
	})
);
