import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
	ICreditInfoDataProperties,
	ICreditInfoDataState,
	initialState,
} from './CreditInfoDataSliceConstants';

export const creditInfoDataSlice = createSlice({
	name: 'creditInfoDataSlice',
	initialState,
	reducers: {
		saveCreditInfoDataState: (
			state: ICreditInfoDataState,
			action: PayloadAction<ICreditInfoDataProperties>
		): void => {
			state.creditInfoData = action.payload;
		},
	},
});

export const { saveCreditInfoDataState } = creditInfoDataSlice.actions;

export default creditInfoDataSlice.reducer;
