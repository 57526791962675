import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import React from 'react';
import { Control, UseFormReturn } from 'react-hook-form';
import { blockWritingCurrencyMoreThanOneBilliard } from '../../../../../../common/helpers/block-writing-keys-helper';
import { amountDynamicMask } from '../../../../../../common/helpers/mask-helper';
import NestInput from '../../../../../../components/FormItems/NestInput/NestInput';
import NestSelect from '../../../../../../components/FormItems/NestSelect/NestSelect';
import { gridFullWidth, gridHalfWidth } from '../../../../../../theme/grid/GridConstants';
import { textColorSecondary } from '../../../../../../theme/palette/palette';
import { TaxPayingCountryEnum, taxPayingCountryOptions } from './LightOvdTaxResidencePartOfFormConstants';

interface LightOvdTaxResidencePartOfFormProps {
    methods: UseFormReturn<Record<string, unknown>>;
}

const LightOvdTaxResidencePartOfForm = (props: LightOvdTaxResidencePartOfFormProps): JSX.Element => {
    const watchTaxPayingCountry = props.methods.watch('taxPayingCountry');
    return (
        <>
            <Grid
                container
                rowSpacing={3}
                columnSpacing={9}
            >
                <Grid item xs={gridFullWidth} md={gridHalfWidth}>
                    <NestSelect
                        control={props.methods.control as unknown as Control}
                        name="taxPayingCountry"
                        label="Rozliczam się z podatków"
                        options={taxPayingCountryOptions}
                        placeholderOption={['Wybierz rodzaj wpływu', '']}
                        defaultValue="Polska"
                        setValue={props.methods.setValue}
                        errors={
                            props.methods.formState.errors.countryOfBirth as
                                | { type: string; message: string }
                                | undefined
                        }
                        rules={{
                            required: 'Pole wymagane',
                        }}
                    />
                    <Typography variant="p3" sx={{color: textColorSecondary, marginTop: '16px'}}>Przed udzieleniem odpowiedzi zapoznaj się z dokumentem „Informacja dodatkowa dotycząca FATCA i
                        CRS”</Typography>
                </Grid>
            </Grid>

            {watchTaxPayingCountry === TaxPayingCountryEnum.ANOTHER_COUNTRY && (
                <>
                    <Typography variant="p1" sx={{marginTop: '32px'}}>Rozliczam się z podatków w następujących krajach:</Typography>
                    <Typography variant="p1" sx={{marginTop: '32px'}}>Główny kraj rezydencji podatkowej</Typography>
                    <Grid
                        container
                        rowSpacing={3}
                        columnSpacing={9}
                        sx={{marginTop: '24px'}}
                    >
                        <Grid item xs={gridFullWidth} md={gridHalfWidth}>
                            <NestSelect
                                control={props.methods.control as unknown as Control}
                                name="taxResidenceMainCountry"
                                label="Państwo"
                                options={[]}
                                placeholderOption={['Wybierz rodzaj wpływu', '']}
                                setValue={props.methods.setValue}
                                errors={props.methods.formState.errors.countryOfBirth as { type: string; message: string } |
                                    undefined}
                                rules={{
                                    required: 'Pole wymagane',
                                }}/>
                        </Grid>
                        <Grid item xs={gridFullWidth} md={gridHalfWidth}>
                            <NestInput
                                label="Numer TIN"
                                control={props.methods.control as unknown as Control}
                                maskFunc={amountDynamicMask}
                                mask=""
                                name="taxResidenceMainTin"
                                rules={{
                                    required: 'Pole wymagane',
                                }}
                                currencyTextOrPlainText="PLN"
                                blockWritingSomeValueFunc={blockWritingCurrencyMoreThanOneBilliard}/>
                        </Grid>
                    </Grid>
                    <Typography variant="p1" sx={{marginTop: '24px'}}>Dodatkowy kraj rezydencji podatkowej</Typography>
                    <Grid
                        container
                        rowSpacing={3}
                        columnSpacing={9}
                        sx={{marginTop: '24px'}}
                    >
                        <Grid item xs={gridFullWidth} md={gridHalfWidth}>
                            <NestSelect
                                control={props.methods.control as unknown as Control}
                                name="taxResidenceAdditionalCountry"
                                label="Państwo"
                                options={[]}
                                placeholderOption={['Wybierz rodzaj wpływu', '']}
                                setValue={props.methods.setValue}
                                errors={props.methods.formState.errors.countryOfBirth as { type: string; message: string } |
                                    undefined}
                                rules={{
                                    required: 'Pole wymagane',
                                }}/>
                        </Grid>
                        <Grid item xs={gridFullWidth} md={gridHalfWidth}>
                            <NestInput
                                label="Numer TIN"
                                control={props.methods.control as unknown as Control}
                                maskFunc={amountDynamicMask}
                                mask=""
                                name="taxResidenceAdditionalTin"
                                rules={{
                                    required: 'Pole wymagane',
                                }}
                                currencyTextOrPlainText="PLN"
                                blockWritingSomeValueFunc={blockWritingCurrencyMoreThanOneBilliard}/>
                        </Grid>
                    </Grid>
                </>
            )}
        </>
    )
}

export default LightOvdTaxResidencePartOfForm;
