import BaseFetch from '../../../common/api/BaseFetch';
import { ApiConfig } from '../../../common/api/FetchTypes';

export interface LoanConfig {
	minLoanAmount: number;
	maxLoanAmount: number;
	periods: number[];
	selectedAmount: number;
	selectedPeriod: number;
	firstInstallmentPaymentDate: Date;
}

const getLoanConfigKey = 'getLoanConfig';

const getLoanConfig = (_prop: never): Promise<LoanConfig> => {
	return BaseFetch({ url: 'Offer/LoanConfig', method: 'GET' });
};

export const getLoanConfigConfig: ApiConfig<LoanConfig, never> = {
	fn: getLoanConfig,
	key: getLoanConfigKey,
};

export interface CalculateLoanQuery {
	value: number;
	period: number;
}

export interface CalculatedLoanInfo {
	nettValue: number;
	installment: number;
	interest: number;
	commission: number;
}

const calculateLoanKey = 'calculateLoanInfo';

const calculateLoanInfo = (
	props: CalculateLoanQuery
): Promise<CalculatedLoanInfo> => {
	return BaseFetch({
		url: 'Offer/CalculateLoanInfo',
		method: 'GET',
		queryParams: { ...props },
	});
};

export const calculateLoanInfoConfig: ApiConfig<
	CalculatedLoanInfo,
	CalculateLoanQuery
> = {
	fn: calculateLoanInfo,
	key: calculateLoanKey,
};

const saveCalcualtionKey = 'saveCalcualtion';

const saveCalcualtion = (props: CalculateLoanQuery): Promise<never> => {
	return BaseFetch({
		url: 'Offer/SaveLoanInfo',
		method: 'POST',
		body: props,
	});
};

export const saveCalcualtionConfig: ApiConfig<never, CalculateLoanQuery> = {
	fn: saveCalcualtion,
	key: saveCalcualtionKey,
};
