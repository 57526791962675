function isValidPesel(pesel: string): boolean | string {
	const weight: number[] = [1, 3, 7, 9, 1, 3, 7, 9, 1, 3];
	let sum = 0;
	const controlNumber: number = parseInt(pesel.substring(10, 11));

	for (let i = 0; i < weight.length; i++) {
		sum += parseInt(pesel.substring(i, i + 1)) * weight[i];
	}
	sum = sum % 10;
	if (pesel.length !== 11) {
		return 'Niepoprawna wartość';
	}
	return (10 - sum) % 10 === controlNumber || 'Niepoprawna wartość';
}

export const peselValidator = { validate: isValidPesel, required: 'Pole wymagane', pattern: { value: /^[0-9]*$/i, message: 'Niepoprawna wartość' } };
