import BaseFetch from 'src/common/api/BaseFetch';
import { ApiConfig } from 'src/common/api/FetchTypes';

export interface ProcessLeadProperties {
	campaignNumber: string;
	sourceId: string;
	firstName: string;
	lastName: string;
	phoneNumber: string | null;
	nip: string;
	creditAmount: number;
	brokerId: string;
	brokerDescription: string;
	agreements: AgreementModelToSendLead[];
	customField?: string;
	ReCaptcha?: string;
}

export interface ProcessLeadResp {
	applicationId: string;
	userId: string;
}

export interface AgreementModelToSendLead {
	symbol: string;
	isSelected: boolean;
}

const processLeadKey = 'processLead';

function processLead(request: ProcessLeadProperties): Promise<ProcessLeadResp> {
	return BaseFetch({ url: `ProcessLead`, method: 'POST', body: request, apiRoot: window.CONFIG.API_ROOT_CLIENT_FORMS });
}

export const processLeadConfig: ApiConfig<ProcessLeadResp, ProcessLeadProperties> = {
	key: processLeadKey,
	fn: processLead,
};
