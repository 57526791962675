import BaseFetch from '../../../common/api/BaseFetch';
import { ApiConfig, ApiVersion } from '../../../common/api/FetchTypes';
import { FeatureFlagProperties } from '../models/FeatureFlagContextModels';

const getFeatureFlagKey = 'getFeatureFlagKey';

function getFeatureFlag(_props: never): Promise<FeatureFlagProperties> {
	return BaseFetch({
		url: 'FeatureFlag',
		method: 'GET',
		version: ApiVersion.Default,
	});
}

export const GetFeatureFlagConfig: ApiConfig<FeatureFlagProperties, never> = {
	key: getFeatureFlagKey,
	fn: getFeatureFlag,
};
