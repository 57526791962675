import {
	CreditGoodToKnowBackground,
	CreditGoodToKnowContainer,
	CreditGoodToKnowSection,
	HexListItem,
	HexListItemNumber,
	HexListItemNumberContainer,
	HexListDescContainer,
} from './LightCreditGoodToKnow.css';
import Typography from '@mui/material/Typography';
import { mainTheme } from '../../../../../theme/mainTheme';
import hexIcon from '../../../../../common/images/hexIcon.svg';
import { WiderContainer } from '../../../../../common/styles/Layout.css';
import useFetch from '../../../../../common/api/UseFetch';
import { GetDownloadFileConfig } from '../../../../../common/api/FileApi';
import { FileType } from '../../../../../enums/FileType';
import NestCollapse from '../../../../../components/NestCollapse/NestCollapse';
import { requiredDocumentsList } from './LightCreditGoodToKnowConst';

export default function LightCreditGoodToKnow(): JSX.Element {
	const getAvailableBankList = useFetch(
		GetDownloadFileConfig(FileType.AvailableBanks, true),
		false
	);

	return (
		<CreditGoodToKnowBackground>
			<WiderContainer>
				<CreditGoodToKnowContainer>
					<CreditGoodToKnowSection>
						<Typography
							sx={{
								color: mainTheme.palette.denary.main,
								maxWidth: '298px',
								marginBottom: '26px',
							}}
							variant='h4'
						>
							Jakich dokumentów potrzebujesz?
						</Typography>
						<ul style={{ color: '#FFFFFF', marginLeft: '-18px' }}>
							{requiredDocumentsList.map(
								(element, index): JSX.Element => (
									<li style={{ marginBottom: '16px' }} key={index}>
										<Typography variant={'p1'}>
											{element.content}{' '}
											{element.collapse &&
												element.collapseTitle &&
												element.collapseContent && (
													<NestCollapse
														isStatic={true}
														title={element.collapseTitle}
														content={element.collapseContent}
														sx={{ display: 'inline-block', width: '95.5%' }}
														sxTitle={{ marginBottom: '0', fontWeight: 'bold' }}
													/>
												)}
										</Typography>
									</li>
								)
							)}
						</ul>
					</CreditGoodToKnowSection>
					<CreditGoodToKnowSection>
						<Typography
							sx={{
								color: mainTheme.palette.denary.main,
								marginBottom: '26px',
							}}
							variant='h4'
						>
							Kredyt na klik w paru krokach:
						</Typography>
						<HexListItem>
							<HexListItemNumberContainer>
								<HexListItemNumber>1</HexListItemNumber>
								<img src={hexIcon} alt='icon' />
							</HexListItemNumberContainer>
							<HexListDescContainer>
								<Typography color='#FFFFFF' variant='p1'>
									Wypełnij wniosek <strong>w kilka minut</strong>, potwierdź
									przychody za pomocą rachunku bankowego swojej firmy
									<br />
									<span
										style={{
											color: mainTheme.palette.primary.main,
											cursor: 'pointer',
											fontWeight: 'bold',
										}}
										onClick={(): void => {
											if (!getAvailableBankList.isPending) {
												getAvailableBankList.mutate({} as never);
											}
										}}
									>
										Lista obsługiwanych banków
									</span>
								</Typography>
							</HexListDescContainer>
						</HexListItem>
						<HexListItem>
							<HexListItemNumberContainer>
								<HexListItemNumber>2</HexListItemNumber>
								<img src={hexIcon} alt='icon' />
							</HexListItemNumberContainer>
							<HexListDescContainer>
								<Typography
									component={'div'}
									color='#FFFFFF'
									variant='p1'
									sx={{
										marginTop: {
											xs: '8px',
											sm: '0',
										},
									}}
								>
									Zapoznaj się z ofertą i{' '}
									<strong>dopasuj ją do swoich potrzeb</strong>{' '}
								</Typography>
							</HexListDescContainer>
						</HexListItem>
						<HexListItem>
							<HexListItemNumberContainer>
								<HexListItemNumber>3</HexListItemNumber>
								<img src={hexIcon} alt='icon' />
							</HexListItemNumberContainer>
							<Typography
								component={'div'}
								color='#FFFFFF'
								variant='p1'
								sx={{
									marginTop: {
										xs: '8px',
										sm: '0',
									},
								}}
							>
								Zapoznaj się z treścią umowy i podpisz ją. Dołącz wymagane
								dokumenty
							</Typography>
						</HexListItem>
						<HexListItem>
							<HexListItemNumberContainer>
								<HexListItemNumber>4</HexListItemNumber>
								<img src={hexIcon} alt='icon' />
							</HexListItemNumberContainer>
							<HexListDescContainer>
								<Typography
									component={'div'}
									color='#FFFFFF'
									variant='p1'
									sx={{
										marginTop: {
											xs: '8px',
											sm: '0',
										},
									}}
								>
									Pieniądze otrzymasz na konto nawet do 24h od podpisania umowy
								</Typography>
							</HexListDescContainer>
						</HexListItem>
					</CreditGoodToKnowSection>
				</CreditGoodToKnowContainer>
			</WiderContainer>
		</CreditGoodToKnowBackground>
	);
}
