import Typography from '@mui/material/Typography';
import notFoundImage from '../../../common/images/NotFoundImage.svg';
import {
	NotFoundMainContainer,
	StaticTextMainContainer,
	ImageContainer,
	Image,
} from './NotFound.css';

export default function NotFound(): JSX.Element {
	return (
		<NotFoundMainContainer
			sx={{
				flexDirection: { xs: 'column', md: 'row' },
				height: { xs: 'calc(100vh - 115px - 110px)', md: 'unset' },
			}}
		>
			<StaticTextMainContainer
				sx={{
					padding: { xs: '0 24px', md: '0 50px' },
					flex: { xs: '0', md: '1.5' },
				}}
			>
				<Typography
					variant='h1'
					sx={{
						margin: { xs: '32px 0', md: '48px 0' },
						fontSize: '62px',
						lineHeight: '74px',
					}}
				>
					404
				</Typography>
				<Typography
					variant='h2'
					sx={{
						fontSize: { md: '34px', xs: '28px' },
						lineHeight: { md: '40px', xs: '33px' },
					}}
				>
					Przepraszamy, ale strona o takim adresie nie została znaleziona.
				</Typography>
			</StaticTextMainContainer>
			<ImageContainer
				sx={{ justifyContent: 'flex-end', alignItems: 'flex-end' }}
			>
				<Image
					src={notFoundImage}
					alt='Not Found Image'
					sx={{
						margin: { xs: '0 -24px -32px 0', md: '0 0 -32px 0' },
						maxHeight: { xs: '100%', md: '640px' },
						maxWidth: { xs: '240px', md: 'unset' },
					}}
				/>
			</ImageContainer>
		</NotFoundMainContainer>
	);
}
