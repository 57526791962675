import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { initialState } from './AgreementsSliceConstants';
import {
	IAgreementsStateSlice,
	IChangeAgreementSectionPayload,
	IChangeConsentsPayload,
} from './AgreementSliceModels';
import {
	AgreementModel,
	AgreementSectionModel,
	MediaConsent,
} from '../../../components/AgreementCollection/AgreementsModels';

export const agreementSlice = createSlice({
	name: 'contactDataSlice',
	initialState,
	reducers: {
		saveAgreements: (
			state: IAgreementsStateSlice,
			action: PayloadAction<AgreementSectionModel[]>
		): void => {
			state.agreements = action.payload;
		},
		handleExpandAllAgreements: (
			state: IAgreementsStateSlice,
			{ payload }: PayloadAction<{ isExpanded: boolean }>
		): void => {
			state.agreements.forEach((x: AgreementSectionModel): void => {
				x.isExpand = payload.isExpanded;
				x.consentGroup.forEach(
					(x: AgreementModel): boolean => (x.isExpand = payload.isExpanded)
				);
			});
			state.allAgreementsExpanded = payload.isExpanded;
		},
		handleAllAgreementsChecked: (
			state: IAgreementsStateSlice,
			{ payload }: PayloadAction<{ isSelected: boolean }>
		): void => {
			state.allAgreementsChecked = payload.isSelected;
			state.agreements.forEach((section: AgreementSectionModel): void => {
				section.isSelected = payload.isSelected;
				section.consentGroup.forEach((consentGroup: AgreementModel): void => {
					if (payload.isSelected) {
						consentGroup.isError = false;
					}
					consentGroup.isSelected = payload.isSelected;
					if (consentGroup.mediaConsents?.length) {
						consentGroup.mediaConsents.forEach(
							(mediaConsent: MediaConsent): void => {
								if (payload.isSelected) {
									mediaConsent.isError = false;
								}
								mediaConsent.isSelected = payload.isSelected;
							}
						);
					}
				});
			});
		},
		checkIsAllAgreementsChecked: (state: IAgreementsStateSlice): void => {
			const checkIsCheckedArr: boolean[] = [];
			state.agreements.forEach((section: AgreementSectionModel): void => {
				if (section.isSelected) {
					checkIsCheckedArr.push(section.isSelected);
				}
				checkIsCheckedArr.push(
					section.consentGroup.every((x: AgreementModel): boolean => {
						return (
							x.isSelected &&
							x.mediaConsents?.every((y): boolean => y.isSelected) === true
						);
					})
				);
			});
			state.allAgreementsChecked =
				checkIsCheckedArr.length > 0
					? !checkIsCheckedArr.includes(false)
					: false;
		},
		handleAgreementSectionExpand: (
			state: IAgreementsStateSlice,
			{
				payload,
			}: PayloadAction<{
				isExpanded: boolean;
				sectionIndex: number;
				consentGroupId?: number;
			}>
		): void => {
			if (typeof payload.consentGroupId !== 'undefined') {
				state.agreements[payload.sectionIndex].consentGroup[
					payload.consentGroupId
				].isExpand = payload.isExpanded;
			}
			if (typeof payload.consentGroupId === 'undefined') {
				state.agreements[payload.sectionIndex].isExpand = payload.isExpanded;
			}
			const checkIsAllExpanded: boolean[] | undefined = [];
			state.agreements.forEach((x: AgreementSectionModel): void => {
				checkIsAllExpanded.push(x.isExpand ?? false);
			});
			state.allAgreementsExpanded = checkIsAllExpanded.includes(true);
		},
		changeAgreementSection: (
			state: IAgreementsStateSlice,
			{ payload }: PayloadAction<IChangeAgreementSectionPayload>
		): void => {
			if (!payload.isSelected) {
				state.allAgreementsChecked = payload.isSelected;
			}
			state.agreements[payload.sectionIndex].isSelected = payload.isSelected;
			state.agreements[payload.sectionIndex].consentGroup.forEach(
				(consentGroup: AgreementModel): void => {
					consentGroup.isError = !payload.isSelected && consentGroup.isRequired;
					consentGroup.isSelected = payload.isSelected;
					if (consentGroup.mediaConsents?.length) {
						consentGroup.mediaConsents.forEach(
							(mediaConsent: MediaConsent): void => {
								mediaConsent.isError =
									!payload.isSelected && mediaConsent.isRequired;
								mediaConsent.isSelected = payload.isSelected;
							}
						);
					}
				}
			);
			const foundEverySelectedSectionAgreements = state.agreements.every(
				(x): boolean | undefined => x.isSelected
			);
			if (foundEverySelectedSectionAgreements) {
				state.allAgreementsChecked = true;
			}
		},
		changeConsents: (
			state: IAgreementsStateSlice,
			{ payload }: PayloadAction<IChangeConsentsPayload>
		): void => {
			const agreement =
				state.agreements[payload.sectionIndex].consentGroup[
					payload.agreementIndex
				];
			if (!payload.isSelected) {
				state.allAgreementsChecked = payload.isSelected;
			}
			if (payload.mainConsent) {
				agreement.isSelected = payload.isSelected;
				agreement.isError = false;
				if (agreement.mediaConsents) {
					agreement.mediaConsents.forEach((x: MediaConsent): void => {
						x.isSelected = payload.isSelected;
						x.isError = false;
					});
				}
			}
			if (agreement.mediaConsents?.length) {
				const mediaConsent = agreement.mediaConsents[payload.mediaIndex ?? 0];
				mediaConsent.isSelected = payload.isSelected;
				mediaConsent.isError = false;
				const foundUnCheckedElement = agreement.mediaConsents.find(
					(x: MediaConsent): boolean => !x.isSelected
				);
				agreement.isSelected = !foundUnCheckedElement;
				if (!foundUnCheckedElement) {
					agreement.isError = false;
				}
			}
			const sectionAgreement = state.agreements[payload.sectionIndex];
			const foundUncheckedAgreement = sectionAgreement.consentGroup.find(
				(x: AgreementModel): boolean => !x.isSelected
			);
			sectionAgreement.isSelected = !foundUncheckedAgreement;

			const checkIsCheckedArr: boolean[] = [];
			state.agreements.forEach((section: AgreementSectionModel): void => {
				checkIsCheckedArr.push(
					section.consentGroup.every((x: AgreementModel): boolean => {
						return (
							x.isSelected &&
							x.mediaConsents?.every((y): boolean => y.isSelected) === true
						);
					})
				);
			});
			state.allAgreementsChecked = !checkIsCheckedArr.includes(false);
		},
		checkErrors: (state: IAgreementsStateSlice): void => {
			state.agreements.forEach((section: AgreementSectionModel): void => {
				section.consentGroup.forEach((x: AgreementModel): void => {
					if (x.isRequired && !x.isSelected) {
						x.isError = true;
						section.isExpand = true;
					}
					x.mediaConsents?.forEach((mediaConsent: MediaConsent): void => {
						if (mediaConsent.isRequired && !mediaConsent.isSelected) {
							mediaConsent.isError = true;
							section.isExpand = true;
						}
					});
				});
			});
			const checkValidArr: boolean[] = [];
			state.agreements.forEach((section: AgreementSectionModel): void => {
				checkValidArr.push(
					section.consentGroup.every((x: AgreementModel): boolean => {
						return (
							!x.isError &&
							x.mediaConsents?.every((y): boolean => !y.isError) === true
						);
					})
				);
			});
			state.allAgreementsExpanded = state.agreements.some(
				(section: AgreementSectionModel): boolean => !!section.isExpand
			);
			state.isValid = !checkValidArr.includes(false);
		},
	},
});

export const {
	saveAgreements,
	changeConsents,
	checkErrors,
	changeAgreementSection,
	handleAgreementSectionExpand,
	handleAllAgreementsChecked,
	handleExpandAllAgreements,
	checkIsAllAgreementsChecked,
} = agreementSlice.actions;
