import { LightCompanyDataRoute, LightOvdCompanyDataRoute } from '../../../../../routing/Routing';
import { CustomizedState } from '../../../../rbf/rbf-verification-failed/VerificationFailed';

export const getLocationState = (areAttemptsLeft: boolean): CustomizedState => {
	const pathOvdOrCl = window.location.href.includes('OVD') ? LightOvdCompanyDataRoute.path : LightCompanyDataRoute.path;
	return {
		message: areAttemptsLeft
			? 'Nie udało się potwierdzić przychodów Twojej firmy.'
			: 'Przekroczono dopuszczalną liczbę nieudanych prób potwierdzenia przychodu Twojej firmy.',
		buttonText: areAttemptsLeft ? 'Spróbuj ponownie' : 'Zakończ',
		path: areAttemptsLeft ? pathOvdOrCl : 'https://nestbank.pl/',
		messageMaxWidth: areAttemptsLeft ? 340 : 547,
	};
};
