import { useEffect, useState } from 'react';
import Stack from '@mui/material/Stack';
import Slider from '@mui/material/Slider';
import Input from '@mui/material/Input';
import { formatAmount } from 'src/common/helpers/number-helper';


interface IRangeSlider {
    sliderColor: string;
    startLoanAmount: number;
    minLoanAmount: number;
    maxLoanAmount: number;
    step: number;
    setSelectedAmount?: (selectedAmount: number) => void;
}

const RangeSlider = ({ sliderColor, startLoanAmount, minLoanAmount, maxLoanAmount, step, setSelectedAmount }: IRangeSlider): JSX.Element => {

    const [selectedAmountSlider, setSelectedAmountSlider] = useState<number>(0)

    useEffect((): void => {
        setSelectedAmountSlider(startLoanAmount)
    }, [startLoanAmount])

    useEffect((): void => {
        setSelectedAmountOnChange(selectedAmountSlider)
    }, [selectedAmountSlider])

    const setSelectedAmountHandler = (value: number | string): void => {
        const numValue = typeof value === 'string' ? +value.replaceAll(' ', '') : value
        if (isNaN(numValue)) return
        setSelectedAmountSlider(numValue)
    }

    const setSelectedAmountOnChange = (value: number): void => {
        if (!setSelectedAmount) return
        let numValueInRange = value
        if (numValueInRange < minLoanAmount) numValueInRange = minLoanAmount
        if (numValueInRange > maxLoanAmount) numValueInRange = maxLoanAmount
        setSelectedAmount(numValueInRange)
    }

    const inputOnBlurHandler = (): void => {
        if (selectedAmountSlider < minLoanAmount) {
            setSelectedAmountHandler(minLoanAmount);
        } else if (selectedAmountSlider > maxLoanAmount) {
            setSelectedAmountHandler(maxLoanAmount);
        } else {
            setSelectedAmountSlider(Math.round(selectedAmountSlider/1000) * 1000);
        }
    };

    return (
        <Stack sx={{ flexDirection: { xs: 'column', sm: 'row' }, width: '100%', alignItems: 'end' }}>
            <Slider
                sx={{
                    width: '100%',
                    color: sliderColor,
                    '.MuiSlider-markLabel': {
                        color: sliderColor,
                        transform: 'none',
                        fontFamily: 'Inter, sans-serif',
                        fontWeight: '400',
                        fontSize: '14px',
                        lineHeight: '19.6px',
                        padding: '8px 0 0 0'
                    },
                    '.MuiSlider-markLabel[data-index="0"]': {
                        transform: 'translate(-100%)',
                    },
                    '.MuiSlider-mark': {
                        color: 'transparent',
                        background: 'transparent',
                    },
                }}
                valueLabelDisplay="off"
                max={maxLoanAmount}
                min={minLoanAmount}
                step={step}
                defaultValue={selectedAmountSlider}
                value={selectedAmountSlider}
                onChange={(_e, value): void => setSelectedAmountHandler(value as number)}
                marks={
                    maxLoanAmount
                        ? [{ value: maxLoanAmount, label: `${formatAmount(maxLoanAmount)} PLN` }, { value: minLoanAmount, label: `${formatAmount(minLoanAmount)} PLN` }]
                        : false}
            />
            <Input
                value={formatAmount(selectedAmountSlider)}
                onChange={(e): void => setSelectedAmountHandler(e.target.value)}
                onBlur={inputOnBlurHandler}
                error={false}
                sx={{
                    background: '#202131',
                    maxWidth: { xs: '100%', sm: '140px' },
                    width: '100%',
                    margin: { xs: '24px 0 0 0', sm: '0 0 8px 24px' },
                    fontFamily: 'Inter, sans-serif',
                    fontWeight: '400',
                    fontSize: '16px',
                    lineHeight: '22.4px',
                    border: '1px solid #9191A1',
                    borderRadius: '4px',
                    outline: 'none',
                    color: '#FFFFFF',
                }}
                inputProps={{
                    step: step,
                    min: minLoanAmount,
                    max: maxLoanAmount,
                    type: 'text',
                    sx: { padding: '16px' },
                }}
            />
        </Stack>
    )
}

export default RangeSlider