import Collapse from '@mui/material/Collapse';
import Typography from '@mui/material/Typography';
import { ReactNode, useState } from 'react';
import { primaryMainColor } from '../../theme/palette/palette';
import { CollapseContainer, CloseIcon } from './NestCollapse.css';
import arrowDown from '../../common/images/ArrowDown.svg';
import closeIcon from '../../common/images/closeIconPrimaryColor.svg';
import { SxProps } from '@mui/system/styleFunctionSx';
import Box from '@mui/material/Box';

export interface CollapseProps {
	title?: string;
	content: string;
	dynamicTitle?: string[];
	contentTitle?: string;
	sxTitle?: SxProps;
	sxContent?: SxProps;
	sx?: SxProps;
	isStatic?: boolean;
	children?: ReactNode;
	hideCloseIcon?: boolean;
}

export default function NestCollapse(props: CollapseProps): JSX.Element {
	const [showCollapse, setShowCollapse] = useState(false);
	return (
		<Box sx={{ ...props.sx }}>
			<Typography
				component={'div'}
				variant='p2'
				onClick={(): void => setShowCollapse(!showCollapse)}
				sx={{
					color: primaryMainColor,
					marginBottom: '16px',
					...props.sxTitle,
					fontFamily: 'Athletics, sans-serif',
				}}
			>
				{props.title
					? props.title
					: props.dynamicTitle
					? showCollapse
						? props.dynamicTitle[0]
						: props.dynamicTitle[1]
					: null}
				<img
					src={arrowDown}
					alt='arrow down'
					style={{
						transition: '.3s ease',
						transform: showCollapse ? 'rotate(180deg)' : 'none',
						marginLeft: '8px',
					}}
				/>
			</Typography>
			<Collapse
				in={showCollapse}
				sx={{
					'&.MuiCollapse-root': {
						position: props.isStatic ? 'relative' : 'absolute',
						zIndex: '10',
						marginTop: props.isStatic ? '10px' : '0',
					},
					left: { xs: '0', sm: 'unset' },
				}}
			>
				<CollapseContainer sx={props.sxContent}>
					{props.hideCloseIcon ? null : (
						<CloseIcon
							src={closeIcon}
							alt='close icon'
							onClick={(): void => setShowCollapse(!showCollapse)}
						/>
					)}

					<Typography
						component={'div'}
						variant='p1'
						sx={{ marginBottom: '16px' }}
					>
						{props.contentTitle}
					</Typography>
					{props.children ? (
						props.children
					) : (
						<div
							dangerouslySetInnerHTML={{
								__html: props.content,
							}}
						/>
					)}
				</CollapseContainer>
			</Collapse>
		</Box>
	);
}
