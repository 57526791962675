import { CountryDto } from '../api/DictionaryApi';
import { customNormalize } from './string-helper';

export function alphabeticPolandOnTopSort(a: string, b: string): number {
	a = customNormalize(a.toLowerCase());
	b = customNormalize(b.toLowerCase());
	const polishLowercaseValue = 'polska';
	if (a === polishLowercaseValue) {
		return -1;
	}
	if (b === polishLowercaseValue) {
		return 1;
	}
	return a < b ? -1 : 1;
}

export const sortAndMapCountries = (countries: CountryDto[]): [string, string][] =>
	countries
		.toSorted((a, b): number =>
			alphabeticPolandOnTopSort(a.countryName, b.countryName)
		)
		.map((x): [string, string] => [x.countryName, x.countryName]);
