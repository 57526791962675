import { useState } from 'react';
import Typography from '@mui/material/Typography';
import Stack from '@mui/system/Stack';
import useFetch from '../../../../common/api/UseFetch';
import LightAttachDocumentsDescMoreTime from '../../components/light-company-data/components/LightAttachDocumentsDescMoreTime';
import { RejectionResonsForApplicationConfig } from './LightAttachDocumentsCorrectionApi';
import { OverrideProps } from '../../../../routing/RouteSpec';
import { useNavigate } from 'react-router-dom';
import { LightEndRoute, LightOvdEndRoute } from '../../../../routing/Routing';
import CustomButton from '../../../../components/CustomButton/CustomButton';
import { grayMainColor } from '../../../../theme/palette/palette';
import { ScrollToTopOnMount } from '../../../../common/helpers/scroll-to-top-onmount';
import { IDocuments } from '../../../../models/AttachDocumentsModels';
import LightAttachDocumentsCorrectionItem from './LightAttachDocumentsCorrectionItem';
import { ApplicationFileTypeNumber, ApplicationFileTypeString } from '../../enums/ApplicationFileType';
import { ILightAttachDocumentsCorrectionIitem, IRejectionResonsForApplicationResp } from './LightAttachDocumentsModels';
import Loader from 'src/components/Loader/Loader';
import {
	AddFileToBlobConfig,
	DeleteDocumentConfig,
	GetDocumentsConfig,
	VerifyDocumentsAndSendEmailToAnalystsConfig,
} from '../cl-attach-documents/LightAttachDocumentsApi';

export default function LightAttachDocumentsCorrection(props?: OverrideProps): JSX.Element {
	const [files, setFiles] = useState<IDocuments[] | undefined>([]);
	const [isUploading, setIsUploading] = useState<ApplicationFileTypeString | undefined>();
	const [rejectionResonsForApplication, setRejectionResonsForApplication] = useState<ILightAttachDocumentsCorrectionIitem[]>([]);

	const navigate = useNavigate();

	const allAddedDocuments = useFetch(GetDocumentsConfig, true, (data: IDocuments[]): void => {
		setAllAddedDocuments(data);
	});

	useFetch(RejectionResonsForApplicationConfig, true, (data): void => {
		setResponseToStateArray(data);
	});

	const addFileToBlob = useFetch(
		AddFileToBlobConfig,
		false,
		(): void => {
			allAddedDocuments.mutate({} as never);
			setIsUploading(undefined);
		},
		true,
		(): void => {
			allAddedDocuments.mutate({} as never);
			setIsUploading(undefined);
		}
	);
	const deleteDocument = useFetch(DeleteDocumentConfig, false, (): void => {
		allAddedDocuments.mutate({} as never);
	});

	const verifyDocumentsAndSendEmailToAnalysts = useFetch(VerifyDocumentsAndSendEmailToAnalystsConfig, false, (data): void => {
		if (!props?.ommitRouting) {
			sessionStorage.setItem('bankAccountNumber', data.bankAccountNumber);
			window.location.href.includes('OVD') ? navigate(LightOvdEndRoute.path) : navigate(LightEndRoute.path);
		}
		props?.close && props.close();
	});

	const setResponseToStateArray = (rejectionResonsForApplicationResponse: IRejectionResonsForApplicationResp): void => {
		const responseToStateArray: ILightAttachDocumentsCorrectionIitem[] = [
			{
				type: ApplicationFileTypeString.PitAttachment,
				typeNumber: ApplicationFileTypeNumber.PitAttachment,
				title: 'Dokumenty finansowe',
				desc: rejectionResonsForApplicationResponse.PitAttachment,
			},
			{
				type: ApplicationFileTypeString.ZusAttachment,
				typeNumber: ApplicationFileTypeNumber.ZusAttachment,
				title: 'Dokumenty ZUS',
				desc: rejectionResonsForApplicationResponse.ZusAttachment,
			},
			{
				type: ApplicationFileTypeString.UsAttachment,
				typeNumber: ApplicationFileTypeNumber.UsAttachment,
				title: 'Dokumenty US',
				desc: rejectionResonsForApplicationResponse.UsAttachment,
			},
		];
		setRejectionResonsForApplication(responseToStateArray.filter((item): boolean => !!item.desc));
	};

	const setAllAddedDocuments = (data: IDocuments[] | undefined): void => {
		if (Array.isArray(data)) {
			const convertedToIDocumentsFilesWithSection = data.map((file: IDocuments): IDocuments => {
				return { ...file, isEnter: false } as IDocuments;
			});
			setFiles(convertedToIDocumentsFilesWithSection);
		}
	};

	const addFiles = (files: FormData, fileType: ApplicationFileTypeString): void => {
		setIsUploading(fileType);
		addFileToBlob.mutate({ files, fileType });
	};

	const deleteDocumentById = (id: string): void => {
		deleteDocument.mutate(id);
	};

	const isAllDocumentsCheck = (rejectionResonsForApplication: ILightAttachDocumentsCorrectionIitem[], files: IDocuments[] | undefined): boolean => {
		const isAllDocuments = rejectionResonsForApplication.every(
			(rejectionReason: ILightAttachDocumentsCorrectionIitem): boolean =>
				!!files?.find((file: IDocuments): boolean => file.type === rejectionReason.typeNumber)
		);
		return isAllDocuments;
	};

	return (
		<Stack
			sx={{
				display: 'flex',
				minHeight: 'inherit',
				flexDirection: 'column',
				width: { xs: 'unset', md: '833px' },
				margin: { xs: '0 16px', md: 'auto' },
			}}
		>
			<ScrollToTopOnMount />
			<Typography variant='h2' sx={{ color: '#000000', fontSize: { xs: '28px', md: '34px' } }}>
				Przesłane przez Ciebie dokumenty wymagają poprawy
			</Typography>
			<Typography
				variant='p1'
				sx={{
					color: grayMainColor,
					padding: { xs: '8px 0 8px 0', md: '8px 0 26px 0' },
				}}
			>
				Załącz ponownie poniższe dokumenty:
			</Typography>

			{rejectionResonsForApplication.length > 0 ? (
				rejectionResonsForApplication.map(
					(item, index): JSX.Element => (
						<Stack key={item.type}>
							<LightAttachDocumentsCorrectionItem
								itemDesc={item}
								files={files?.filter((file): boolean => file.type === item.typeNumber)}
								setFiles={setFiles}
								addFiles={(formData): void => addFiles(formData, item.type)}
								deleteDocumentById={deleteDocumentById}
								isUploading={isUploading === item.type}
								isLastChild={index === rejectionResonsForApplication.length - 1}
							/>
						</Stack>
					)
				)
			) : (
				<Loader />
			)}

			<LightAttachDocumentsDescMoreTime />

			<Stack
				sx={{
					justifyContent: { xs: 'center', md: 'flex-end' },
					width: '100%',
					marginTop: { xs: '56px', md: '40px' },
					marginBottom: { xs: '32px', md: '80px' },
					flexDirection: 'row',
				}}
			>
				<CustomButton
					isLoading={verifyDocumentsAndSendEmailToAnalysts.isPending}
					disabled={!isAllDocumentsCheck(rejectionResonsForApplication, files)}
					onClick={(): void => verifyDocumentsAndSendEmailToAnalysts.mutate({} as never)}
					variant='contained'
				>
					Wyślij
				</CustomButton>
			</Stack>
		</Stack>
	);
}
