import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import {
	applicationIdKey,
	applicationNumberKey,
	authTokenKey,
	removeSessionStorageValue,
} from '../../../common/sessionStorage/SessionStorageService';
import { ReturnProposalRoute } from '../../../routing/Routing';
import OnePageInfo from '../../../components/OnePageInfo/OnePageInfo';

export default function SessionExpired(): JSX.Element {
	const navigate = useNavigate();

	useEffect((): void => {
		removeSessionStorageValue(applicationIdKey);
		removeSessionStorageValue(authTokenKey);
		removeSessionStorageValue(applicationNumberKey);
	}, []);

	const backToApplication = (): void => {
		navigate(ReturnProposalRoute.path);
	};

	return (
		<OnePageInfo
			titleText='Sesja wygasła'
			descriptionText='Wniosek zostanie zapisany w naszym systemie, a Ty będziesz mógł wrócić do niego w każdym momencie. Na wskazanego przez Ciebie maila wyślemy link, dzięki któremu wrócisz do wypełniania wniosku.'
			onClickHandler={backToApplication}
			buttonText='Powrót do wniosku'
		/>
	);
}
