import Typography from '@mui/material/Typography';
import { mainTheme } from '../../theme/mainTheme';
import {
	BottomInfo,
	BottomInfoContainer,
	TileContainer,
	TileImg,
} from './CalculatorBottomInfo.css';
import lowerInstallmentImg from '../../common/images/lower_installment.svg';
import creditHolidayImg from '../../common/images/credit_holidays.svg';
import extendLoanPeriodImg from '../../common/images/extend_loan_period.svg';
import Box from '@mui/material/Box';

export default function CalculatorBottomInfo(): JSX.Element {
	const getTile = (
		img: string,
		title: string,
		desc: string,
		width: string
	): JSX.Element => {
		return (
			<Box
				sx={{
					width: { xs: 'auto', md: width },
					marginBottom: { xs: '62px ', md: '0' },
				}}
			>
				<TileImg src={img} />
				<Typography
					variant='h4'
					sx={{
						color: mainTheme.palette.text.primary,
						marginTop: { xs: '32px', md: '0' },
					}}
				>
					{title}
				</Typography>
				<Typography
					variant='p2'
					sx={{
						color: mainTheme.palette.text.primary,
						marginTop: { xs: '16px', md: '0' },
					}}
				>
					{desc}
				</Typography>
			</Box>
		);
	};

	return (
		<BottomInfoContainer>
			<BottomInfo>
				<Typography
					variant='h3'
					sx={{ color: mainTheme.palette.text.primary, marginBottom: '60px' }}
				>
					Wiemy, że rynek jest zmienny.
					<br />U nas kredyt{' '}
					<span style={{ color: mainTheme.palette.secondary.main }}>
						spłacasz jak Ci wygodnie.
					</span>
				</Typography>
				<TileContainer>
					{getTile(
						lowerInstallmentImg,
						'Obniż ratę',
						'Możesz obniżyć ratę na określony czas',
						'140px'
					)}
					{getTile(
						creditHolidayImg,
						'Wakacje kredytowe',
						'Zawieś spłatę na 3 miesiące',
						'110px'
					)}
					{getTile(
						extendLoanPeriodImg,
						'Wydłuż czas kredytowania',
						'Nawet o 6 miesięcy',
						'148px'
					)}
				</TileContainer>
			</BottomInfo>
		</BottomInfoContainer>
	);
}
