import Typography from '@mui/material/Typography';
import plus from '../../../../../../common/images/plus.svg';
import minus from '../../../../../../common/images/minus.svg';
import {
	ButtonContainer,
	ButtonsContainer,
	CircleContainer,
} from './ClientWealthAddDeleteButtons.css';

interface ClientWealthAddDeleteButtonsProps {
	remove: () => void;
	append: () => void;
}

const ClientWealthAddDeleteButtons = (
	props: ClientWealthAddDeleteButtonsProps
): JSX.Element => {
	return (
		<ButtonsContainer>
			<ButtonContainer onClick={props.append}>
				<CircleContainer>
					<img src={plus} alt='plus' />
				</CircleContainer>

				<Typography variant='h7' sx={{ fontFamily: 'Athletics, sans-serif' }}>
					Dodaj kolejny
				</Typography>
			</ButtonContainer>
			<ButtonContainer onClick={props.remove}>
				<CircleContainer>
					<img src={minus} alt='minus' />
				</CircleContainer>
				<Typography variant='h7' sx={{ fontFamily: 'Athletics, sans-serif' }}>
					Usuń
				</Typography>
			</ButtonContainer>
		</ButtonsContainer>
	);
};

export default ClientWealthAddDeleteButtons;
