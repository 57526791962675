import { Components } from '@mui/material/styles/components';
import { Theme } from '@mui/material/styles/createTheme';
import {
	primaryMainColor,
	secondaryMainColor,
	senaryMainColor,
	septenaryMainColor,
	textColorPrimary,
	textColorSecondary,
} from '../palette/palette';
import { typography } from '../typography/typography';

export const nestDatePickerStyles = {
	'.react-datepicker-popper': {
		zIndex: 2,
		'@media (min-width: 0px)': {
			width: '85%',
		},
		'@media (min-width: 857px)': {
			width: '339px',
		},
	},
	'.react-datepicker__header--custom': {
		backgroundColor: 'white',
		border: 'unset',
	},
	'.react-datepicker': {
		width: '100%',
		'@media (min-width: 857px)': {
			padding: '0 20px',
		},
	},
	'.react-datepicker__month-container': {
		width: '100%',
	},
	'.react-datepicker__week': {
		display: 'flex',
		justifyContent: 'space-between',
	},
	'.react-datepicker__month': {
		height: '220px',
		display: 'flex',
		justifyContent: 'space-between',
		flexDirection: 'column',
	},
	'.react-datepicker__day-names': {
		display: 'flex',
		justifyContent: 'space-between',
		margin: '0 0.4rem 0 0.4rem',
	},
	'.react-datepicker__day-name': {
		...typography.h7,
		textTransform: 'uppercase',
	},
	'.react-datepicker__day': {
		...typography.p1,
		borderRadius: '50%',
		height: '30px',
		width: '30px',
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
		'&:hover': {
			borderRadius: '50%',
			height: '30px',
			width: '30px',
			display: 'flex',
			alignItems: 'center',
			justifyContent: 'center',
			background: secondaryMainColor,
			color: 'white',
			opacity: 0.7,
		},
	},
	'.react-datepicker__day--keyboard-selected': {
		background: secondaryMainColor,
		borderRadius: '50%',
		height: '30px',
		width: '30px',
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
	},
	'.react-datepicker__day--selected': {
		background: secondaryMainColor,
	},
	'.react-datepicker-wrapper': {
		width: '100%'
	}
};

export const components: Components<Omit<Theme, 'components'>> = {
	MuiButton: {
		defaultProps: {
			disableRipple: true,
			disableTouchRipple: true,
			disableElevation: true,
			disableFocusRipple: true,
		},
		styleOverrides: {
			root: {
				'&:hover': {
					backgroundColor: 'none',
					border: 'none',
				},
				padding: '0',
				textTransform: 'none',
				borderRadius: '32px',
				height: '56px',
				width: '280px',
				fontFamily: 'Athletics, sans-serif',
			},
		},
		variants: [
			{
				props: { variant: 'primaryButton' },
				style: {
					...typography.h8,
					backgroundColor: primaryMainColor + ' !important',
					color: textColorPrimary,
				},
			},
			{
				props: { variant: 'contained' },
				style: {
					...typography.h7,
					backgroundColor: textColorPrimary + ' !important',
					fontFamily: 'Athletics, sans-serif',
					color: '#FFFFFF',
					'&:hover': {
						backgroundColor: textColorSecondary + ' !important',
					},
					'&.Mui-disabled': {
						backgroundColor: senaryMainColor + ' !important',
						color: textColorSecondary + ' !important',
					},
				},
			},
			{
				props: { variant: 'outlined' },
				style: {
					...typography.h7,
					color: textColorPrimary,
					border: '2px solid ' + textColorPrimary,
					fontFamily: 'Athletics, sans-serif',
					'&:hover': {
						border: '2px solid ' + textColorPrimary,
					},
				},
			},
		],
	},
	MuiSlider: {
		styleOverrides: {
			root: {
				color: secondaryMainColor,
				'& .MuiSlider-markLabel': {
					color: secondaryMainColor,
					transform: 'translateX(-100%)',
					'&[data-index="0"]': {
						transform: 'none',
					},
				},
			},
		},
	},
	MuiCheckbox: {
		styleOverrides: {
			root: {
				color: primaryMainColor,
			},
		},
	},
	MuiStack: {
		// eslint-disable-next-line @typescript-eslint/prefer-ts-expect-error, @typescript-eslint/ban-ts-comment
		//@ts-ignore
		styleOverrides: {
			root: {
				'& .MuiFormControlLabel-asterisk': {
					display: 'none'
				}
			}
		}
	},
	MuiAlert: {
		styleOverrides: {
			root: {
				border: '3px solid white',
				boxShadow:
					'0px 2px 8px rgba(22, 22, 30, 0.08), 0px 2px 4px rgba(22, 22, 30, 0.03)',
				width: '300px',
				color: textColorPrimary,
				...typography.h9,
			},
			standardError: {
				backgroundColor: '#FFD6D7',
			},
			standardSuccess: {
				backgroundColor: '#E6DFFD',
			},
			standardInfo: {
				backgroundColor: '#DEEBF7',
			},
			standardWarning: {
				backgroundColor: '#FAE9C8',
			},
		},
	},
	MuiSwitch: {
		styleOverrides: {
			root: {
				width: '53px',
				height: '28px',
				borderRadius: '32px',
				padding: '0',
				'& .MuiSwitch-switchBase': {
					padding: '0',
				},
				'& .MuiSwitch-switchBase .MuiSwitch-thumb': {
					width: '20px',
					height: '20px',
					marginTop: '4px',
					marginLeft: '6px',
				},
				'& > .Mui-checked': {
					transform: 'translate(26px) !important',
				},
				'& > .MuiSwitch-thumb, & .Mui-checked .MuiSwitch-thumb': {
					boxShadow: 'none',
					marginLeft: '2px',
					backgroundColor: textColorPrimary + ' !important',
				},
				'& .Mui-disabled .MuiSwitch-thumb': {
					backgroundColor: textColorPrimary + ' !important',
				},
				'& .Mui-disabled.Mui-checked .MuiSwitch-thumb': {
					backgroundColor: textColorPrimary + ' !important',
				},
				'& .MuiSwitch-track': {
					backgroundColor: textColorSecondary + '!important',
					opacity: '1 !important',
				},
				'& > .Mui-checked + .MuiSwitch-track': {
					backgroundColor: primaryMainColor + '!important',
				},
				'& > .Mui-disabled + .MuiSwitch-track': {
					backgroundColor: septenaryMainColor + '!important',
				},
				'& > .Mui-checked.Mui-disabled + .MuiSwitch-track': {
					backgroundColor: primaryMainColor + '!important',
				},
			},
		},
	},
	MuiSelect: {
		styleOverrides: {
			select: {
				fontFamily: 'Inter, sans-serif',
			},
		},
	},
	MuiMenuItem: {
		styleOverrides: {
			root: {
				fontFamily: 'Inter, sans-serif',
			},
		},
	},
};
