import { createContext } from 'react';

interface ProviderProps {
    children: JSX.Element | JSX.Element[];
}

export function CreateComponentContext<TContext>(
    defaultContext: TContext,
    handlerFunc: () => TContext
): [React.Context<TContext>, (props: ProviderProps) => JSX.Element] {
    const context = createContext<TContext>(defaultContext);

    const provider = (props: ProviderProps): JSX.Element => {
        return (
            <context.Provider value={handlerFunc()}>
                {props.children}
            </context.Provider>
        );
    };

    return [context, provider];
}

export const notImplementedFunction = (): void => {
    throw new Error('Not implemented');
};
