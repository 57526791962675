import Typography from '@mui/material/Typography';
import Box from '@mui/system/Box/Box';
import Stack from '@mui/system/Stack/Stack';
import { WebPage } from '../../routing/Routing';
import editIcon from '../../common/images/EditIcon.svg';

export interface SectionHeaderProps {
    title: string;
    page: WebPage;
    setRenderPage: React.Dispatch<React.SetStateAction<WebPage | undefined>>;
}
export default function SectionHeader(props: Readonly<SectionHeaderProps>): JSX.Element {
    return (
        <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
            <Typography variant='h5' sx={{ color: 'secondary.main' }}>{props.title}</Typography>
            <Stack
                sx={{ flexDirection: 'row', alignItems: 'center', cursor: 'pointer' }}
                onClick={(): void => props.setRenderPage(props.page)}
                id={props.title.split(' ').join('')}>
                <Box component='img' src={editIcon} sx={{ marginRight: '8px' }}></Box>
                <Typography
                    variant='h9'
                    sx={{
                        color: 'secondary.main',
                        lineHeight: '14px',
                        fontFamily: 'Athletics, sans-serif',
                    }}
                >
                    Edytuj dane
                </Typography>
            </Stack>
        </Box>)
}