import Collapse from '@mui/material/Collapse';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { useState } from 'react';
import { formatAmount } from 'src/common/helpers/number-helper';
import closeIcon from '../../../common/images/closeIconPrimaryColor.svg';
import lead_arrow from '../../../common/images/lead_arrow.svg';

//svg
import lead_Nest_logo from '../../../common/images/lead_Nest_logo.svg';
import { leadVariantOfHeaderText, leadVariantOfImage } from '../../lead/lead-cl/LeadCLVariantComponents';
import { LeadProductType } from '../leadTypes';
import LeadStartCalculator from './LeadStartCalculator';

interface ILeadStart {
	leadProductType: LeadProductType;
	minLoanAmount?: number;
	maxLoanAmount?: number;
	periods?: number[];
	wibor?: number; // interest = wibor + commission
	commission?: number;
	legalNote: string;
	logoUrl: string | null;
	displayCalculator?: boolean;
	onCreditAmountChange?: (value: number) => void;
	markUp?: number;
}

const LeadStart = (props: ILeadStart): JSX.Element => {
	const [showCollapse, setShowCollapse] = useState(false);
	
	return (
		<Stack
			sx={{
				minHeight: { xs: 'unset', md: '100vh' },
				width: { xs: '100%', md: '50%' },
				background: '#16161E',
				alignItems: 'center',
			}}
		>
			<Stack sx={{ maxWidth: '530px', width: '100%' }}>
				<Stack sx={{ padding: { xs: '32px 16px 13px 16px', md: '32px 16px 40px 16px' }, flexDirection: 'row', alignItems: 'center' }}>
					<Stack sx={{ width: '207px', height: '97px' }}>
						<img src={lead_Nest_logo} alt='NestBank logo' height='100%' />
					</Stack>
					<Stack sx={{ width: '150px', height: '70px', padding: '0 0 0 16px' }}>
						{props.logoUrl && <img src={props.logoUrl} alt='logo partnera NestBank' height='100%' />}
					</Stack>
				</Stack>

				<Typography
					variant='p2'
					sx={{
						padding: '0 16px 4px 16px',
						color: '#BCA8FF',
						lineHeight: '19.6px',
						fontWeight: '600',
					}}
				>
					Kredyt dla jednoosobowej działalności gospodarczej
				</Typography>
				{leadVariantOfHeaderText(formatAmount(props.maxLoanAmount), props.leadProductType)}
				{props.displayCalculator && (
					<LeadStartCalculator
						minLoanAmount={props.minLoanAmount ?? 0}
						maxLoanAmount={props.maxLoanAmount ?? 0}
						periods={props.periods ?? []}
						interest={(props.markUp ?? 0) + (props.wibor ?? 0)}
						onCreditAmountChange={props.onCreditAmountChange}
						startLoanAmount={50000}
					/>
				)}

				<Stack
					sx={{
						position: 'relative',
						padding: '12px 0 28px 16px',
						cursor: 'pointer',
					}}
					onClick={(): void => setShowCollapse((prevState): boolean => !prevState)}
				>
					<Stack sx={{ flexDirection: 'row', alignItems: 'center' }}>
						<Typography
							sx={{
								fontFamily: 'Athletics, sans-serif',
								fontWeight: '700',
								fontSize: '14px',
								lineHeight: '16.8px',
								color: '#BCA8FF',
							}}
						>
							Nota prawna
						</Typography>
						<Stack
							sx={{
								padding: '8px',
								transform: showCollapse ? 'rotateX(180deg)' : 'rotateX(0deg)',
								transition: 'transform 0.6s ease-in-out ',
							}}
						>
							<img src={lead_arrow} alt='lead arrow' />
						</Stack>
					</Stack>
					<Collapse
						in={showCollapse}
						sx={{
							zIndex: '2',
							width: {
								md: 'calc(200% - 32px)',
								xs: 'calc(100%  - 32px)'
							},
							position: 'absolute',
							top: '45px',
							left: '14px',
						}}
					>
						<Stack
							sx={{
								background: '#2D333F',
								border: '1px solid #656572',
								borderRadius: '8px',
								color: '#FFFFFF',
								padding: '16px',
								position: 'relative',
							}}
						>
							<Stack
								sx={{
									flexDirection: 'row',
									alignItems: 'center',
									justifyContent: 'space-between',
								}}
							>
								<Typography variant='p5'>Nota prawna</Typography>
								<img src={closeIcon} alt='close icon' />
							</Stack>
							<Typography variant='p5' sx={{ textAlign: 'justify' }} dangerouslySetInnerHTML={{ __html: props.legalNote }} />
						</Stack>
					</Collapse>
				</Stack>
				{leadVariantOfImage()}
				<Collapse
					in={showCollapse}
					sx={{
						position: 'relative', 
						overflow: 'hidden',
						zIndex: '-1',
						width: '80vw',
					}}
				>
					<Typography variant='p5' sx={{ textAlign: 'justify' }} dangerouslySetInnerHTML={{ __html: props.legalNote?.substring(0, props.legalNote?.length / 2) }} />
				</Collapse>
			</Stack>
		</Stack>
	);
};

export default LeadStart;
