import { styled } from '@mui/material/styles';

export const SwitchContainer = styled('div')({
    display: 'flex',
    width: '69%',
    marginBottom: '15px',
    justifyContent: 'space-between'
})

export const CheckboxContainer = styled('div')({
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    width: '69%'
})
