export function formatAmountToFixed2(num: number | undefined): string {
	if (num === 0) {
		return '0';
	}
	if (typeof num === 'undefined' || isNaN(num)) {
		return '';
	}

	return num
		.toFixed(2)
		.replace('.', ',')
		.replace(/\B(?=(\d{3})+(?!\d))/g, ' ');
}

export function formatAmount(num: number | undefined): string {
	if(num === 0){
		return '0';
	}
	if (typeof num === 'undefined' || isNaN(num)) {
		return '';
	}

	if (num % 1 === 0) {
		return num
			.toString()
			.replace('.', ',')
			.replace(/\B(?=(\d{3})+(?!\d))/g, ' ');
	}
	return num
		.toFixed(2)
		.replace('.', ',')
		.replace(/\B(?=(\d{3})+(?!\d))/g, ' ');
}

export function formatAmountWithDecimalValues(num: string | undefined): string {
	const splitStringNum = num?.toString()?.split(",");
	if(!splitStringNum){
		return '';
	}
	if(splitStringNum.length > 2){
		return (splitStringNum[0] + ',' + splitStringNum[1]).replace(/\B(?=(\d{3})+(?!\d))/g, ' ');
	}
	if(splitStringNum[1]?.length > 2){
		return Number(splitStringNum.join(',').replace(',', '.'))
			.toFixed(2)
			.replace('.', ',')
			.replace(/\B(?=(\d{3})+(?!\d))/g, ' ');
	}
	return splitStringNum
		.toString()
		.replace('.', ',')
		.replace(/\B(?=(\d{3})+(?!\d))/g, ' ');
}

export const convertNumbersToString = (number?: number | string): string => number || number === 0 ? number.toString().replace('.', ',') : ''
