import { styled } from '@mui/material/styles';

export const NotFoundMainContainer = styled('div')({
	display: 'flex',
	minHeight: 'inherit',
	maxWidth: '1200px',
	margin: 'auto',
})

export const StaticTextMainContainer = styled('div')({
	display: 'flex',
	flexDirection: 'column',
})

export const ImageContainer = styled('div')({
	display: 'flex',
	flex: 1,
	overflow: 'hidden',
});

export const Image = styled('img')({
	display: 'flex',
	flex: 1,
	overflow: 'hidden',
});
