import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack/Stack';
import Box from '@mui/material/Box';
import { useContext, useEffect, useState } from 'react';
import nestBlackLogo from '../../common/images/nestBlackLogo.svg';
import nestPurpleLogo from '../../common/images/NestPurpleLogo.svg';
import {
	getSessionStorageValue,
	partnerLogoKey,
	applicationNumberKey,
} from '../../common/sessionStorage/SessionStorageService';
import { BreadcrumbStep } from '../../routing/RouteSpec';
import { septenaryMainColor } from '../../theme/palette/palette';
import Breadcrumbs from './Breadcrumbs/Breadcrumbs';
import ConfirmationOfSendingModal from './ConfirmationOfSendingModal/ConfirmationOfSendingModal';
import { ModalName } from './HeaderConstants';
import SendDataModal from './SendDataModal/SendDataModal';
import { useLocation } from 'react-router-dom';
import { ContextSaveApplicationData } from '../../context/ContextSaveApplicationData';
import { SaveApplicationDraftConfig } from '../../common/api/SaveApplicationDraftConfig';
import useFetch from '../../common/api/UseFetch';
import { IndexNewApplicationRoute, LandingPageRoute, LightLandingPageRoute, LightOvdLandingPageRoute } from '../../routing/Routing';
import { LogoHeader } from './Header.css';

interface HeaderProps {
	showSaveStateButton: boolean;
	breadcrumbStep: BreadcrumbStep;
	showApplicationNumber: boolean;
	showPartnerLogo: boolean;
}

const Header = (props: HeaderProps): JSX.Element => {
	const [open, setOpen] = useState<ModalName>(ModalName.None);
	const [partnerLogoLink, setPartnerLogoLink] = useState('');
	const [nestLogo, setNestLogo] = useState<string>('');
	const [applicationNumber, setApplicationNumber] = useState<string>('');

	const location = useLocation();

	const [, setIsSaveApplicationData] = useContext(ContextSaveApplicationData);

	const saveApplicationDraft = useFetch(
		SaveApplicationDraftConfig,
		false,
		(): void => {
			setIsSaveApplicationData(false);
		}
	);

	useEffect((): void => {
		location.pathname === LandingPageRoute.path ||
		location.pathname === LightLandingPageRoute.path ||
		location.pathname === LightOvdLandingPageRoute.path ||
		location.pathname === IndexNewApplicationRoute.path
			? setNestLogo(nestPurpleLogo)
			: setNestLogo(nestBlackLogo);

		const logoLink = getSessionStorageValue(partnerLogoKey) ?? '';
		setPartnerLogoLink(logoLink);

		const applicationNumber =
			getSessionStorageValue(applicationNumberKey) ?? '';
		setApplicationNumber(applicationNumber ? applicationNumber : '');
	});

	useEffect((): void => {
		if (ModalName.ConfirmationOfSendingModal === open) {
			callTriggerForComponentsToSaveAplicationData();
			saveApplicationData();
		}
	}, [open]);

	const callTriggerForComponentsToSaveAplicationData = (): void => {
		setIsSaveApplicationData(true);
	};

	const saveApplicationData = (): void => {
		saveApplicationDraft.mutate({} as never);
	};

	const openModal = (modalName: ModalName): void => {
		setOpen(modalName);
	};

	const closeModal = (): void => {
		setOpen(ModalName.None);
	};

	return (
		<>
			<Stack
				sx={{
					height: '75px',
					width: '100%',
					maxWidth: '1200px',
					margin: 'auto',
					background: 'inherit',
					position: 'relative',
					zIndex: 100,
					justifyContent: 'center',
				}}
			>
				<Stack
					sx={{
						width: '100%',
						flexDirection: 'row',
						alignItems: 'center',
						justifyContent: 'space-between',
					}}
				>
					<Stack
						sx={{
							flexDirection: 'row',
							padding: { xs: '0 8px 0 24px', lg: '0 8px 0 2px' },
							alignItems: 'center',
						}}
					>
						<Stack sx={{ marginRight: { xs: '16px', sm: '32px' } }}>
							<LogoHeader src={nestLogo} alt='NestBank' />
						</Stack>
						{props.showPartnerLogo && !!partnerLogoLink.length && (
							<Stack>
								<LogoHeader src={partnerLogoLink} alt='Partner' />
							</Stack>
						)}
					</Stack>

					<Stack
						sx={{
							flexDirection: 'row',
							padding: '0 16px 0 8px',
							alignItems: 'center',
						}}
					>
						{props.showApplicationNumber && applicationNumber && (
							<Box
								sx={{
									display: { xs: 'none', sm: 'block' },
									padding: '0 24px 0 0',
								}}
							>
								<Typography component='p' variant='p3'>
									Numer wniosku
								</Typography>
								<Typography component='p' variant='p3'>
									{applicationNumber}
								</Typography>
							</Box>
						)}
						{props.showSaveStateButton && (
							<Button
								onClick={(): void => openModal(ModalName.SendDataModal)}
								variant='primaryButton'
								sx={{ width: '137px', height: '36px' }}
							>
								Zapisz wniosek
							</Button>
						)}
					</Stack>
				</Stack>
				{props.showApplicationNumber && applicationNumber && (
					<Typography
						variant='p3'
						sx={{
							padding: '8px 0 0 24px',
							display: { xs: 'block', sm: 'none' },
							color: septenaryMainColor,
							fontSize: '10px',
						}}
					>
						Numer wniosku:{' '}
						<span style={{ fontSize: '12px' }}> {applicationNumber}</span>
					</Typography>
				)}
			</Stack>

			<Breadcrumbs breadcrumbStep={props.breadcrumbStep} />

			<SendDataModal
				open={open}
				closeModal={closeModal}
				toggleModal={(modalName: ModalName): void => openModal(modalName)}
			/>
			<ConfirmationOfSendingModal open={open} closeModal={closeModal} />
		</>
	);
};

export default Header;
