import { useState, useEffect } from 'react';
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';
import MultiCarousel from '../../../components/MultiCarousel/MultiCarousel';
import RangeSlider from '../../../components/RangeSlider/RangeSlider';
import { primaryMainColor, textColorPrimary } from '../../../theme/palette/palette';

interface ILeadStartcalculator {
	minLoanAmount: number;
	maxLoanAmount: number;
	periods: number[];
	interest: number;
	onCreditAmountChange?: (value: number) => void;
	startLoanAmount: number;
}

const LeadStartCalculator = (props: ILeadStartcalculator): JSX.Element => {
	const [selectedPeriod, setSelectedPeriod] = useState<number>(0);
	const [selectedAmountSliderValue, setSelectedAmountSliderValue] = useState<number>(0);
	const [monthlyInstalment, setMonthlyInstalment] = useState<string>('0.00');

	useEffect((): void => {
		if (!props.interest || !selectedPeriod || !selectedAmountSliderValue) {
			return;
		}
		const countMonthlyInstalment = getPMTInstalment(props.interest, selectedPeriod, selectedAmountSliderValue);
		setMonthlyInstalment(countMonthlyInstalment.toFixed(2));
		props.onCreditAmountChange?.(selectedAmountSliderValue);
	}, [selectedPeriod, selectedAmountSliderValue]);

	const getPMTInstalment = (interestRatePerYear: number, numberOfPeriodsInMonths: number, presentValue: number, futureValue = 0): number => {
		const interest = interestRatePerYear / 100 / 12;
		const presentValueInterestFactor = Math.pow(1 + interest, numberOfPeriodsInMonths);
		return (interest * presentValue * (presentValueInterestFactor + futureValue)) / (presentValueInterestFactor - 1);
	};

	return (
		<Stack sx={{ color: '#FFFFFF', padding: '0 16px 16px 16px' }}>
			<Typography variant='p5'>Wpisz lub ustaw suwakiem kwotę kredytu</Typography>

			<Stack sx={{ padding: '16px 0' }}>
				<RangeSlider
					sliderColor='#BCA8FF'
					startLoanAmount={props.startLoanAmount}
					minLoanAmount={props.minLoanAmount}
					maxLoanAmount={props.maxLoanAmount}
					step={5000}
					setSelectedAmount={(selectedAmountSliderValue): void => setSelectedAmountSliderValue(selectedAmountSliderValue)}
				/>
			</Stack>

			<Typography variant='p5' sx={{ padding: '16px 0 0 0' }}>
				Wybierz okres spłaty:
			</Typography>

			<Stack
				sx={{
					padding: '8px 0 24px 0',
					transform: { xs: 'translateX(0)', lg: 'translateX(-24px)' },
				}}
			>
				<MultiCarousel
					carouselColor={primaryMainColor}
					carouselColorText={textColorPrimary}
					carouselColorBackground='#202131'
					numberOfItemsOnDesktopView={5}
					numberOfItemsOnTabletView={5}
					numberOfItemsOnMobileView={3}
					startItemIndex={props.periods.length - 1}
					periods={props.periods}
					setSelectedPeriod={(selectedPeriod): void => setSelectedPeriod(selectedPeriod)}
				/>
			</Stack>
			<Stack sx={{ flexDirection: 'row', alignItems: 'end', flexWrap: 'wrap' }}>
				<Typography variant='p4' sx={{ fontWeight: '700', padding: '4px 6px 4px 0' }}>
					Szacowana rata miesięczna:
				</Typography>
				<Stack sx={{ flexDirection: 'row' }}>
					<Typography
						variant='h4'
						sx={{
							fontWeight: '700',
							lineHeight: '28.8px',
							color: '#BCA8FF',
							padding: '0 4px 0 0',
						}}
					>
						{monthlyInstalment.replace('.', ',')}
					</Typography>
					<Typography variant='h4' sx={{ fontWeight: '700', lineHeight: '28.8px', color: '#BCA8FF' }}>
						PLN
					</Typography>
				</Stack>
			</Stack>
		</Stack>
	);
};

export default LeadStartCalculator;
