import { Typography } from '@mui/material';
import ContactDataForm from './components/ContactDataForm/ContactDataForm';
import { ContactDataSubHeader, PartnerLogo } from './ContactData.css';
import lock from '../../../common/images/lock.svg';
import { OverrideProps } from '../../../routing/RouteSpec';
import { Container } from '../../../common/styles/Layout.css';
import {
	getSessionStorageValue,
	partnerLogoKey,
} from '../../../common/sessionStorage/SessionStorageService';
import { useState } from 'react';
import { textColorSecondary } from '../../../theme/palette/palette';

const ContactData = (props?: OverrideProps): JSX.Element => {
	const [partnerLogoLink] = useState(
		getSessionStorageValue(partnerLogoKey) ?? ''
	);

	return (
		<Container>
			<Typography
				variant='h2'
				sx={{
					textAlign: 'left',
					width: '100%',
					margin: { md: 'unset', xs: 'auto' },
					maxWidth: { xs: '380px', md: 'unset' },
					fontSize: { xs: '28px', md: '34px' },
					lineHeight: { xs: '34px', md: '40px' },
				}}
			>
				Dane kontaktowe
			</Typography>
			<ContactDataSubHeader
				sx={{
					justifyContent: { xs: 'start', md: 'unset' },
					maxWidth: { xs: '380px', md: 'unset' },
				}}
			>
				<img src={lock} alt='lock' />
				<Typography
					variant='h8'
					sx={{
						margin: '0 10px',
						fontFamily: 'Athletics, sans-serif',
						color: textColorSecondary,
					}}
				>
					Dane dostarczone przez
				</Typography>
				<PartnerLogo src={partnerLogoLink} alt='partner logo' />
			</ContactDataSubHeader>
			<ContactDataForm
				{...(props ? props : (undefined as unknown as OverrideProps))}
			/>
		</Container>
	);
};

export default ContactData;
