import Typography from '@mui/material/Typography';
import { formatAmount } from '../../../../common/helpers/number-helper';
import { mainTheme } from '../../../../theme/mainTheme';
import { typography } from '../../../../theme/typography/typography';
import { CreditInfoContainer } from './LightCalculatorPage.css';
import { CalculatedLoanInfo } from './LightCalculatorApi';
import { formatDate } from '../../../../common/helpers/date-helper';

export interface CalculatorCreditInfoProps {
	data: CalculatedLoanInfo | undefined;
	firstInstallmentPaymentDate: Date;
}

export default function CalculatorCreditInfo(
	props: CalculatorCreditInfoProps
): JSX.Element {
	return (
		<CreditInfoContainer>
			<Typography
				variant='h9'
				sx={{
					color: mainTheme.palette.text.secondary,
					fontWeight: 600,
				}}
			>
				Kwota kredytu, którą wypłacimy
				<br />
				na Twoje konto
			</Typography>
			<Typography
				variant='h3'
				sx={{
					color: mainTheme.palette.secondary.main,
					marginBottom: '16px',
				}}
			>
				{formatAmount(props.data?.nettValue)}{' '}
				<span style={{ ...typography.h7, fontFamily: 'Athletics, sans-serif' }}>
					PLN
				</span>
			</Typography>
			<Typography
				variant='h9'
				sx={{
					color: mainTheme.palette.text.secondary,
					fontWeight: 600,
				}}
			>
				Szacunkowa rata
			</Typography>
			<Typography
				variant='h3'
				sx={{
					color: mainTheme.palette.secondary.main,
					marginBottom: '60px',
				}}
			>
				{formatAmount(props.data?.installment)}{' '}
				<span style={{ ...typography.h7, fontFamily: 'Athletics, sans-serif' }}>
					PLN
				</span>
			</Typography>
			<Typography
				variant='h9'
				sx={{
					color: mainTheme.palette.text.secondary,
					fontWeight: 600,
				}}
			>
				Prowizja za udzielenie kredytu
			</Typography>
			<Typography
				variant='h5'
				sx={{
					color: mainTheme.palette.text.primary,
					marginBottom: '16px',
				}}
			>
				{formatAmount(props.data?.commission)}%
			</Typography>
			<Typography
				variant='h9'
				sx={{
					color: mainTheme.palette.text.secondary,
					fontWeight: 600,
				}}
			>
				Oprocentowanie
			</Typography>
			<Typography
				variant='h5'
				sx={{ color: mainTheme.palette.text.primary, marginBottom: '16px' }}
			>
				{formatAmount(props.data?.interest)}%
			</Typography>
			<Typography
				variant='h9'
				sx={{
					color: mainTheme.palette.text.secondary,
					fontWeight: 600,
				}}
			>
				Termin płatności pierwszej raty
			</Typography>
			<Typography variant='h5' sx={{ color: mainTheme.palette.text.primary }}>
				{formatDate(props.firstInstallmentPaymentDate)}
			</Typography>
		</CreditInfoContainer>
	);
}
