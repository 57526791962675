import { Container } from '../../../../common/styles/Layout.css';
import Loader from '../../../../components/Loader/Loader';
import {
	LightNewApplicationContextProvider,
} from './state/LightNewApplicationContext';

export default function LightNewApplication(): JSX.Element {
	return (
		<LightNewApplicationContextProvider>
			<Container>
				<Loader />
			</Container>
		</LightNewApplicationContextProvider>
	);
}
