import { AgreementModel } from 'src/components/AgreementCollection/AgreementsModels';
import BaseFetch from '../../../../../common/api/BaseFetch';
import { ApiConfig, ApiVersion } from '../../../../../common/api/FetchTypes';
import { InitiateResult } from '../../../../rbf/rbf-identity-verification/IdentityVerificationApi';
import {
	LightCompanyDataBeforeVerificationModel,
	LightCompanyDataFormFieldsModel,
	LightCompanyDataToSendModel,
} from '../models/LightCompanyData.models';
import { WebPage } from 'src/routing/Routing';

const getLightCompanyDataKey = 'getLightCompanyDataKey';
const postLightCompanyDataKey = 'postLightCompanyDataKey';
const postDraftLightCompanyDataKey = 'postDraftLightCompanyDataKey';
const initiateIncomeVerificationKey = 'initiateIncomeVerificationKey';
const companyDataGetAgreementsKey = 'companyDataGetAgreements';

function getLightCompanyData(
	_props: never
): Promise<LightCompanyDataFormFieldsModel> {
	return BaseFetch({
		url: 'CompanyData',
		method: 'GET',
		version: ApiVersion.Light,
	});
}

function postLightCompanyData(
	data: LightCompanyDataToSendModel
): Promise<LightCompanyDataToSendModel> {
	return BaseFetch({
		url: 'CompanyData',
		method: 'POST',
		version: ApiVersion.Light,
		body: data,
	});
}

function postDraftLightCompanyData(
	data: LightCompanyDataBeforeVerificationModel
): Promise<never> {
	return BaseFetch({
		url: 'CompanyData/draft',
		method: 'POST',
		version: ApiVersion.Light,
		body: data,
	});
}

function initiateIncomeVerification(bankId: string): Promise<InitiateResult> {
	return BaseFetch({
		url: `IdentityVerification/initiateIncomeVerification/${bankId}`,
		method: 'POST',
		version: ApiVersion.Light,
	});
}

function companyDataGetAgreements(
	page: WebPage
): () => Promise<AgreementModel[]> {
	return (): Promise<AgreementModel[]> =>
		BaseFetch({
			url: `Agreement/ForApplication/${page}`,
			method: 'GET',
		});
}

export const GetLightCompanyDataConfig: ApiConfig<
	LightCompanyDataFormFieldsModel,
	never
> = {
	key: getLightCompanyDataKey,
	fn: getLightCompanyData,
};

export const PostLightCompanyDataConfig: ApiConfig<
	LightCompanyDataToSendModel,
	never
> = {
	key: postLightCompanyDataKey,
	fn: postLightCompanyData,
};

export const InitiateIncomeVerificationLightCompanyDataConfig: ApiConfig<
	InitiateResult,
	never
> = {
	key: initiateIncomeVerificationKey,
	fn: initiateIncomeVerification,
};

export const PostDraftLightCompanyDataConfig: ApiConfig<
	never,
	LightCompanyDataBeforeVerificationModel
> = {
	key: postDraftLightCompanyDataKey,
	fn: postDraftLightCompanyData,
};

export function GetCompanyDataAgreementsConfig(
	page: WebPage
): ApiConfig<AgreementModel[], never> {
	return {
		key: companyDataGetAgreementsKey,
		fn: companyDataGetAgreements(page),
	};
}
