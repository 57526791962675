/* eslint-disable @typescript-eslint/no-misused-promises */
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { Control, FormProvider, useForm } from 'react-hook-form';
import {
	phoneNumberMask,
	staticMask,
} from '../../../../../../common/helpers/mask-helper';
import { ButtonContainer } from '../../../../../../common/styles/Layout.css';
import { emailValidator } from '../../../../../../common/validators/emailValidator';
import { phoneNumberValidator } from '../../../../../../common/validators/phoneNumberValidator';
import CustomButton from '../../../../../../components/CustomButton/CustomButton';
import NestInput from '../../../../../../components/FormItems/NestInput/NestInput';
import { OverrideProps } from '../../../../../../routing/RouteSpec';
import { IOvdContactDataProperties } from '../../../../../light/ovd/ovd-contact-data/OvdContactDataModels';
import {
	Form,
	FormSectionWrapper,
	FieldsWrapper,
	ButtonTextContainer,
	InputContainer,
} from './OvdContactDataForm.css';
import { defaultValues } from './OvdContactDataFormConstants';
import { ScrollToTopOnMount } from '../../../../../../common/helpers/scroll-to-top-onmount';

const ContactDataForm = (props?: OverrideProps): JSX.Element => {
	// const contactData = useFetch(GetContactDataConfig, true);
	// const sendContactData = useFetch(PostContactDataConfig, false, (): void => {
	// 	if (!props?.ommitRouting) {
	// 		navigate(PersonalDataRoute.path);
	// 	}
	// 	if (props?.close) {
	// 		props.close();
	// 	}
	// });

	const methods = useForm<IOvdContactDataProperties>({
		mode: 'all',
		defaultValues,
	});
	//
	// useEffect((): void => {
	// 	if (!contactData.data) {
	// 		return;
	// 	}
	// 	methods.reset({
	// 		...contactData.data,
	// 	});
	// }, [contactData.data]);

	const onSubmit = (): void => {
		// sendContactData.mutate({
		// 	phoneNumber: data.phoneNumber,
		// 	email: data.email,
		// });
	};

	return (
		<FormProvider {...methods}>
			<ScrollToTopOnMount />
			<Form onSubmit={methods.handleSubmit(onSubmit)}>
				<FormSectionWrapper sx={{ flexDirection: 'column' }}>
					<FieldsWrapper
						sx={{ justifyContent: { xs: 'center', md: 'flex-start' } }}
					>
						<InputContainer>
							<NestInput
								label='NIP'
								control={methods.control as unknown as Control}
								name='nip'
								disabled
								wrapperSx={{ marginBottom: '24px' }}
								maskFunc={staticMask}
								mask='### ### ## ##'
							/>
						</InputContainer>
					</FieldsWrapper>
					<FieldsWrapper
						sx={{
							alignItems: { md: 'flex-end', xs: 'center' },
							flexDirection: { xs: 'column', md: 'row' },
						}}
					>
						<InputContainer>
							<NestInput
								label='Adres e-mail'
								control={methods.control as unknown as Control}
								name='email'
								rules={emailValidator}
								wrapperSx={{ marginBottom: '24px' }}
							/>
						</InputContainer>
						<InputContainer>
							<NestInput
								label='Numer telefonu komórkowego'
								control={methods.control as unknown as Control}
								rules={phoneNumberValidator}
								name='phoneNumber'
								wrapperSx={{ marginBottom: '22px' }}
								maskFunc={phoneNumberMask}
								mask=''
							/>
						</InputContainer>
					</FieldsWrapper>
				</FormSectionWrapper>

				{props?.cancelText && props.submitText ? (
					<ButtonContainer>
						<Button
							variant='outlined'
							onClick={(): void => {
								props.close();
							}}
						>
							{props.cancelText}
						</Button>
						<CustomButton
							// isLoading={sendContactData.isLoading}
							variant='contained'
							type='submit'
						>
							{props.submitText}
						</CustomButton>
					</ButtonContainer>
				) : (
					<ButtonTextContainer
						sx={{
							alignItems: { md: 'flex-end', xs: 'center' },
							margin: { xs: '0 0 46px 0', md: '0 0 80px 0' },
						}}
					>
						<Typography
							variant='p3'
							align='center'
							sx={{
								color: 'text.secondary',
								maxWidth: '280px',
								margin: { md: '0 40px 24px 0', xs: '0 0 24px 0' },
								lineHeight: '15px',
							}}
						>
							Przygotuj dowód osobisty.
							<br />
							Będzie potrzebny w kolejnym kroku.
						</Typography>
						<CustomButton
							// isLoading={sendContactData.isLoading}
							variant='contained'
							type='submit'
							disabled={!methods.formState.isValid}
						>
							Potwierdzam dane
						</CustomButton>
					</ButtonTextContainer>
				)}
			</Form>
		</FormProvider>
	);
};

export default ContactDataForm;
