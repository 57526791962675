import { styled } from '@mui/material/styles';
import { mainTheme } from '../../../../theme/mainTheme';

export const HorizontalLine = styled('div')({
	width: '100%',
	borderBottom: `2px solid ${mainTheme.palette.senary.main}`,
	marginTop: '32px',
	marginBottom: '16px',
});

export const CreditInfoContainer = styled('div')({
	width: 'calc(100% - 39px - 38px)',
	backgroundColor: '#F8F9FF',
	padding: '35px 39px 28px 38px',
	[mainTheme.breakpoints.down('md')]: {
		marginTop: '32px',
	},
});

export const CalculatorContainer = styled('div')({
	width: 'calc(100% - 40px)',
	padding: '0 40px 0 0',
	[mainTheme.breakpoints.down('md')]: {
		padding: '16px 0 0 0',
		width: '100%',
	},
});

export const ButtonTextContainer = styled('div')({
	width: '100%',
	display: 'flex',
	flexDirection: 'column',
	justifyContent: 'center',
});

export const DocumentSectionElement = styled('div')({
	display: 'flex',
	alignItems: 'center',
	cursor: 'pointer',
	fontWeight: 600,
});

export const CreditInfo = styled('span')({
	fontFamily: 'Athletics, sans-serif',
});

export const DownloadExampleDocument = styled('div')({
	color: mainTheme.palette.secondary.main,
	fontFamily: 'Athletics, sans-serif',
	fontWeight: 'bold',
});

export const DownloadIconContainer = styled('div')`
	cursor: pointer;
	display: flex;
	align-items: center;
`;

export const TextContainer = styled('div')({});
