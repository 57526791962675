import Typography from '@mui/material/Typography';
import React, { useContext } from 'react';
import { ScrollToTopOnMount } from '../../../../../common/helpers/scroll-to-top-onmount';
import NestInput from '../../../../../components/FormItems/NestInput/NestInput';
import { LightOvdLandingPageContext } from '../state/LightOvdLandingPageContext';
import LightOvdContactDataForm from './components/LightOvdContactDataForm/LightOvdContactDataForm';
import { Container } from '../../../../../common/styles/Layout.css';
import { Control, FormProvider, UseFormReturn } from 'react-hook-form';
import { Form, MagicWordsContainer } from './components/LightOvdContactDataForm/LightOvdContactDataForm.css';
import LightOvdCreditInfoSection from './components/lightOvdCreditInfoSection/LightOvdCreditInfoSection';
import { ILightOvdContactDataProperties } from './LightOvdCreditInfoAndContactDataFormModels';
import { magicWordsValidator } from 'src/common/validators/magicWordsValidator';

const LightOvdCreditInfoAndContactDataForm = (): JSX.Element => {
	const lightOvdLandingPageContext = useContext(LightOvdLandingPageContext);

	return (
		<FormProvider {...(lightOvdLandingPageContext.methods as UseFormReturn<ILightOvdContactDataProperties>)}>
			<ScrollToTopOnMount />
			<Form
				onSubmit={(lightOvdLandingPageContext.methods as UseFormReturn<ILightOvdContactDataProperties>).handleSubmit(
					lightOvdLandingPageContext.handleSubmit
				)}
			>
				<LightOvdCreditInfoSection />
				{window.CONFIG.MAGIC_WORDS && (
					<MagicWordsContainer>
						<NestInput
							control={lightOvdLandingPageContext.methods?.control as unknown as Control}
							name='magicWords'
							label='Magic Words'
							rules={{
								pattern: {
									value: magicWordsValidator,
									message: 'Niepoprawna wartość'
								}
							}}
						/>
					</MagicWordsContainer>
				)}
				<Container>
					<Typography
						variant='h2'
						sx={{
							textAlign: 'left',
							width: '100%',
							maxWidth: { xs: '380px', md: 'unset' },
							fontSize: { xs: '28px', md: '34px' },
							lineHeight: { xs: '34px', md: '40px' },
							marginBottom: { md: '48px', xs: '32px' },
							marginTop: '60px',
						}}
					>
						Podaj dane i rozpocznij składanie wniosku. <br />
						Jeśli napotkasz problem, skontaktujemy się z Tobą.
					</Typography>
					<LightOvdContactDataForm />
				</Container>
			</Form>
		</FormProvider>
	);
};

export default LightOvdCreditInfoAndContactDataForm;
