import BaseFetch from '../../../common/api/BaseFetch';
import { ApiConfig } from '../../../common/api/FetchTypes';

export interface InitiateResult {
	redirectUrl: string;
}

interface InitiateProp {
	bankId: string;
}

export interface IdentityVerificationBanksListPropsBankInfo {
	id: string;
	name: string;
	iconUrl: string;
}

const getPsd2BanksKey = 'getPSD2Banks';

function getPsd2Banks(_props: never): Promise<IdentityVerificationBanksListPropsBankInfo[]> {
	return BaseFetch({
		url: 'IdentityVerification/IncomeVerificationBanks',
		method: 'GET',
	});
}

export const GetPsd2BanksConfig: ApiConfig<IdentityVerificationBanksListPropsBankInfo[], never> = {
	key: getPsd2BanksKey,
	fn: getPsd2Banks,
};

const getInitiateKey = 'getInitiateKey';

function getInitiate(props: InitiateProp): Promise<InitiateResult> {
	return BaseFetch({
		url: `IdentityVerification/initiate/${props.bankId}`,
		method: 'POST',
	});
}

export const getInitiateConfig: ApiConfig<InitiateResult, InitiateProp> = {
	key: getInitiateKey,
	fn: getInitiate,
};
