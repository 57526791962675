import { styled } from '@mui/system';

export const ContactDataMainContainer = styled('div')({
	maxWidth: '830px',
	minHeight: 'inherit',
	margin: '0 auto',
});

export const ContactDataSubHeader = styled('div')({
	display: 'flex',
	margin: '52px auto 36px auto',
	alignItems: 'center',
	width: '100%',
});

export const PartnerLogo = styled('img')({
	height: '14px',
});
