/* eslint-disable @typescript-eslint/no-misused-promises */
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { useEffect } from 'react';
import { Control, FormProvider, useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import useFetch from '../../../../../common/api/UseFetch';
import { phoneNumberMask, staticMask } from '../../../../../common/helpers/mask-helper';
import { ButtonContainer } from '../../../../../common/styles/Layout.css';
import { emailValidator } from '../../../../../common/validators/emailValidator';
import { phoneNumberValidator } from '../../../../../common/validators/phoneNumberValidator';
import CustomButton from '../../../../../components/CustomButton/CustomButton';
import NestInput from '../../../../../components/FormItems/NestInput/NestInput';
import { OverrideProps } from '../../../../../routing/RouteSpec';
import { PersonalDataRoute } from '../../../../../routing/Routing';
import { GetContactDataConfig, PostContactDataConfig } from '../../api/ContactDataApi';
import { IContactDataProperties } from '../../ContactDataModels';
import { InputsContainer, Form, FormSectionWrapper, FieldsWrapper, ButtonTextContainer } from './ContactDataForm.css';
import { defaultValues } from './ContactDataFormConstants';
import { ScrollToTopOnMount } from '../../../../../common/helpers/scroll-to-top-onmount';

const ContactDataForm = (props?: OverrideProps): JSX.Element => {
	const navigate = useNavigate();
	const contactData = useFetch(GetContactDataConfig, true);
	const sendContactData = useFetch(PostContactDataConfig, false, (): void => {
		if (!props?.ommitRouting) {
			navigate(PersonalDataRoute.path);
		}
		if (props?.close) {
			props.close();
		}
	});

	const methods = useForm<IContactDataProperties>({
		mode: 'all',
		defaultValues,
	});

	useEffect((): void => {
		if (!contactData.data) {
			return;
		}
		methods.reset({
			...contactData.data,
		});
	}, [contactData.data]);

	const onSubmit = (data: IContactDataProperties): void => {
		sendContactData.mutate({
			phoneNumber: data.phoneNumber,
			email: data.email,
		});
	};

	return (
		<FormProvider {...methods}>
			<ScrollToTopOnMount />
			<Form onSubmit={methods.handleSubmit(onSubmit)}>
				<FormSectionWrapper sx={{ flexDirection: { xs: 'column', md: 'unset' } }}>
					<FieldsWrapper sx={{ width: { md: '50%', xs: '100%' } }}>
						<InputsContainer sx={{ margin: { xs: '0 auto', md: 'unset' } }}>
							<NestInput
								label='NIP'
								control={methods.control as unknown as Control}
								name='nip'
								disabled
								wrapperSx={{ marginBottom: '24px' }}
								maskFunc={staticMask}
								mask='### ### ## ##'
							/>
							<NestInput
								label='Numer rachunku bankowego Twojej firmy'
								control={methods.control as unknown as Control}
								name='bankAccountNumber'
								disabled
								wrapperSx={{ marginBottom: '22px' }}
								maskFunc={staticMask}
								mask='## #### #### #### #### #### ####'
							/>
							<Typography variant='p3' sx={{ color: 'text.secondary' }}>
								Twój bank:{' '}
								{contactData.data?.bankName?.[contactData.data.bankName.length - 1] === '.'
									? contactData.data.bankName
									: `${contactData.data?.bankName ?? ''}.`}
							</Typography>
							<Typography
								variant='p3'
								sx={{
									color: 'text.secondary',
									maxWidth: '280px',
									display: 'block',
									margin: '4px 0 24px 0',
								}}
							>
								Na ten numer rachunku bankowego zostaną
								<br />
								przelane środki z kredytu.
							</Typography>
						</InputsContainer>
					</FieldsWrapper>
					<FieldsWrapper
						sx={{
							width: { md: '50%', xs: '100%' },
							alignItems: { md: 'flex-end', xs: 'unset' },
						}}
					>
						<InputsContainer sx={{ margin: { xs: '0 auto', md: 'unset' } }}>
							<NestInput
								label='Adres e-mail'
								control={methods.control as unknown as Control}
								name='email'
								rules={emailValidator}
								wrapperSx={{ marginBottom: '24px' }}
							/>
							<NestInput
								label='Numer telefonu komórkowego'
								control={methods.control as unknown as Control}
								rules={phoneNumberValidator}
								name='phoneNumber'
								wrapperSx={{ marginBottom: '22px' }}
								maskFunc={phoneNumberMask}
								mask=''
								prefix='+48&nbsp;'
							/>
						</InputsContainer>
					</FieldsWrapper>
				</FormSectionWrapper>

				{props?.cancelText && props.submitText ? (
					<ButtonContainer>
						<Button
							variant='outlined'
							onClick={(): void => {
								props.close();
							}}
						>
							{props.cancelText}
						</Button>
						<CustomButton isLoading={sendContactData.isPending} variant='contained' type='submit'>
							{props.submitText}
						</CustomButton>
					</ButtonContainer>
				) : (
					<ButtonTextContainer
						sx={{
							alignItems: { md: 'flex-end', xs: 'center' },
							margin: { xs: '0 0 46px 0', md: '0 0 80px 0' },
						}}
					>
						<Typography
							variant='p3'
							align='center'
							sx={{
								color: 'text.secondary',
								maxWidth: '280px',
								margin: { md: '0 40px 24px 0', xs: '0 0 24px 0' },
								lineHeight: '15px',
							}}
						>
							Przygotuj dowód osobisty.
							<br />
							Będzie potrzebny w kolejnym kroku.
						</Typography>
						<CustomButton isLoading={sendContactData.isPending} variant='contained' type='submit' disabled={!methods.formState.isValid}>
							Potwierdzam dane
						</CustomButton>
					</ButtonTextContainer>
				)}
			</Form>
		</FormProvider>
	);
};

export default ContactDataForm;
