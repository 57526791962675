import React from 'react';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import {
	grayMainColor,
	primaryMainColor,
	secondaryMainColor,
	textColorSecondary,
} from '../../../../theme/palette/palette';
import NestFileUploader from 'src/components/FileUploader/FileUploader';
import { IDocuments } from 'src/models/AttachDocumentsModels';
import { ILightAttachDocumentsCorrectionIitem } from './LightAttachDocumentsModels';

interface ILightAttachDocumentsCorrectionItem {
	itemDesc: ILightAttachDocumentsCorrectionIitem;
	setFiles: React.Dispatch<React.SetStateAction<IDocuments[] | undefined>>;
	files: IDocuments[] | undefined;
	addFiles: (files: FormData) => void;
	deleteDocumentById: (id: string) => void;
	isUploading: boolean;
	isLastChild: boolean;
}

const LightAttachDocumentsCorrectionItem = (
	props: ILightAttachDocumentsCorrectionItem
): JSX.Element => {
	return (
		<Stack
			sx={{
				margin: '16px 0 0 0',
				padding: {
					xs: props.isLastChild ? '0 0 11px 0' : '0 0 32px 0',
					md: '32px 48px',
				},
				border: `${primaryMainColor} solid`,
				borderRadius: { xs: '0', md: '8px' },
				borderWidth: { xs: props.isLastChild ? '0' : '0 0 1px 0', md: '1px' },
			}}
		>
			<Typography
				variant='h5'
				sx={{ color: secondaryMainColor, padding: '0 0 8px 0' }}
			>
				{props.itemDesc.title}
			</Typography>

			{props.itemDesc.desc?.map((item, index): JSX.Element => {
				return (
					<Stack
						key={index}
						sx={{
							flexDirection: { xs: 'column', md: 'row' },
							margin: '8px 0',
							padding: '0px',
							background: '#DEEBF7',
							borderRadius: '8px',
						}}
					>
						<Stack sx={{ flex: 1, padding: '16px' }}>
							<Typography variant='p5' sx={{ color: textColorSecondary }}>
								Dokument
							</Typography>
							<Typography variant='p1' sx={{ color: grayMainColor }}>
								{`${item.category} ${item.subcategory}`}
							</Typography>
						</Stack>
						<Stack
							sx={{
								flex: 1,
								padding: { xs: '0 16px 16px 16px', md: '16px 16px 16px 0' },
							}}
						>
							<Typography variant='p5' sx={{ color: textColorSecondary }}>
								Rodzaj błędu
							</Typography>
							<Stack>
								{item.refuseCodes.map(
									(code): JSX.Element => (
										<Typography
											key={code}
											variant='p1'
											sx={{ color: grayMainColor }}
										>
											{code}
										</Typography>
									)
								)}
							</Stack>
						</Stack>
					</Stack>
				);
			})}

			<Stack sx={{ padding: '0' }}>
				<NestFileUploader
					isMoseOverById={true}
					documents={props.files}
					setFiles={
						props.setFiles as React.Dispatch<React.SetStateAction<IDocuments[]>>
					}
					addFiles={props.addFiles}
					deleteDocumentById={props.deleteDocumentById}
					isUploading={props.isUploading}
				/>

				<Typography variant='h10' sx={{ color: textColorSecondary }}>
					<span style={{ fontWeight: '600' }}>
						Dopuszczalne formaty plików:
					</span>
					<br /> JPG, PDF, doc, docx, dotx, odt.
					<br /> Maksymalny rozmiar plików to 30MB.
				</Typography>
			</Stack>
		</Stack>
	);
};

export default LightAttachDocumentsCorrectionItem;
