import BaseFetch from '../../../common/api/BaseFetch';
import { ApiConfig } from '../../../common/api/FetchTypes';
import {
	ReturnProposalProperties,
	SmsVerificationResponse,
} from './ReturnProposalContants';

const verifyNipAndSendSmsKey = 'verifyNipAndSendSmsKey';
const verifySmsKey = 'verifySmsKey';

function verifyNipAndSendSms(nip: string): Promise<never> {
	return BaseFetch({
		url: `ReturnToApplication/sendSmsCode/${nip}`,
		method: 'POST',
	});
}

function verifySms(
	data: ReturnProposalProperties
): Promise<SmsVerificationResponse> {
	return BaseFetch({
		url: `ReturnToApplication/verifySmsCode/${data.nip}/${data.smsCode}`,
		method: 'GET',
	});
}

export const VerifyNipAndSendSms: ApiConfig<never, string> = {
	key: verifyNipAndSendSmsKey,
	fn: verifyNipAndSendSms,
};

export const VerifySms: ApiConfig<
	SmsVerificationResponse,
	ReturnProposalProperties
> = {
	key: verifySmsKey,
	fn: verifySms,
};
