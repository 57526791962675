import { IAddressPropertiesError } from '../../../../../common/models/addressAndErrorsModels';
import {
	ILightPersonalAddressFormErrors,
	ILightPersonalCorrespondenceAddressFormErrors,
	LightPersonalDataProperties,
} from '../models/LightPersonalDataModels';

// description of numberOfNonWorkingDependents depends on 2, 3, and 4
export const maritalStatusOptions: [string, string | number][] = [
	['panna/kawaler', 1],
	['żonaty/zamężna bez rozdzielności majątkowej', 2],
	['żonaty/zamężna z rozdzielnością majątkową', 3],
	['w separacji', 4],
	['rozwiedziony/rozwiedziona', 5],
	['wdowiec/wdowa', 6],
];

export const unemployedDependantPeopleLabel = 'Liczba niepracujących osób na utrzymaniu bez Wnioskodawcy';
export const unemployedDependantPeopleWithoutSpouseLabel = 'Liczba niepracujących osób na utrzymaniu bez Wnioskodawcy i małżonka Wnioskodawcy';

export const lightPersonalAddressFormControlNames = {
	street: 'address.street',
	houseNumber: 'address.houseNumber',
	apartmentNumber: 'address.apartmentNumber',
	postalCode: 'address.postalCode',
	city: 'address.city',
};

export const lightPersonalCorrespondenceAddressFormControlNames = {
	street: 'correspondenceAddress.street',
	houseNumber: 'correspondenceAddress.houseNumber',
	apartmentNumber: 'correspondenceAddress.apartmentNumber',
	postalCode: 'correspondenceAddress.postalCode',
	city: 'correspondenceAddress.city',
};

export const economicLiabilitiesData = {
	id: self.crypto.randomUUID(),
	name: '',
	socialSecurityNumber: '',
	nip: '',
	type: '',
};

export const creditOrLeasingLiabilitiesData = {
	id: self.crypto.randomUUID(),
	installment: '',
	currency: '',
};

export const LightPersonalDataDefaultValues: LightPersonalDataProperties = {
	firstName: '',
	lastName: '',
	socialSecurityNumber: '',
	idCardNumber: '',
	idCardIssuedDate: '',
	idCardExpirationDate: '',
	unemployedDependantPeopleAmount: '',
	maritalStatus: '',
	monthlyLivingCosts: '',
	otherMonthlyCosts: 0,
	countryOfBirth: '',
	cityOfBirth: '',
	mothersMaidenName: '',
	address: {
		street: '',
		houseNumber: '',
		apartmentNumber: '',
		postalCode: '',
		city: '',
	},
	isCorrespondenceAddressSameAsAddress: true,
	correspondenceAddress: {
		street: '',
		houseNumber: '',
		apartmentNumber: '',
		postalCode: '',
		city: '',
	},
	clientWealth: [],
	economicLiabilities: {
		data: [{ ...economicLiabilitiesData }],
		isGiven: false,
	},
	creditOrLeasingLiabilities: {
		cashLoanLiabilities: {
			data: [{ ...creditOrLeasingLiabilitiesData }],
			isGiven: false,
		},
		overdraftLiabilities: {
			data: [{ ...creditOrLeasingLiabilitiesData }],
			isGiven: false,
		},
		leasingLiabilities: {
			data: [{ ...creditOrLeasingLiabilitiesData }],
			isGiven: false,
		},
		anyLiabilitiesGiven: false,
	},
};

export const getErrorsLightAddressForm = (errors: ILightPersonalAddressFormErrors): IAddressPropertiesError => {
	return {
		street: errors.address?.street,
		houseNumber: errors.address?.houseNumber,
		apartmentNumber: errors.address?.apartmentNumber,
		postalCode: errors.address?.postalCode,
		city: errors.address?.city,
	};
};

export const getErrorsLightCorrespondenceAddressForm = (errors: ILightPersonalCorrespondenceAddressFormErrors): IAddressPropertiesError => {
	return {
		street: errors.correspondenceAddress?.street,
		houseNumber: errors.correspondenceAddress?.houseNumber,
		apartmentNumber: errors.correspondenceAddress?.apartmentNumber,
		postalCode: errors.correspondenceAddress?.postalCode,
		city: errors.correspondenceAddress?.city,
	};
};

export enum TypeOfIdentification {
	NIP = 1,
	PESEL = 2,
}

export enum EconomicLiabilityType {
	Economic = 1,
	EconomicCapital = 2,
	Personal = 3,
}

export const nipOrPeselOptions = [
	['NIP', TypeOfIdentification.NIP],
	['PESEL', TypeOfIdentification.PESEL],
];

export const economicLiabilityTypeOptions = [
	['Gospodarcze', EconomicLiabilityType.Economic],
	['Ekonomiczno/kapitałowe', EconomicLiabilityType.EconomicCapital],
	['Osobowe', EconomicLiabilityType.Personal],
];

const currencyMock = [
	{
		defCurrencyId: 1,
		symbol: 'PLN',
	},
	{
		defCurrencyId: 166,
		symbol: 'CAD',
	},
	{
		defCurrencyId: 111,
		symbol: 'GBP',
	},
	{
		defCurrencyId: 155,
		symbol: 'DKK',
	},
	{
		defCurrencyId: 159,
		symbol: 'NOK',
	},
	{
		defCurrencyId: 160,
		symbol: 'RON',
	},
	{
		defCurrencyId: 110,
		symbol: 'CHF',
	},
	{
		defCurrencyId: 162,
		symbol: 'SEK',
	},
	{
		defCurrencyId: 157,
		symbol: 'JPY',
	},
	{
		defCurrencyId: 109,
		symbol: 'USD',
	},
	{
		defCurrencyId: 154,
		symbol: 'CZK',
	},
	{
		defCurrencyId: 5,
		symbol: 'EUR',
	},
	{
		defCurrencyId: 156,
		symbol: 'HUF',
	},
];

export const currencyOptions = currencyMock.map((x): string[] => [x.symbol, x.symbol]);
