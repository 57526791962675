/* eslint-disable @typescript-eslint/no-unnecessary-condition */
import React, { ChangeEvent, useEffect, useState } from 'react';
import {
	Control,
	Controller,
	ControllerRenderProps,
	FieldError,
	FieldErrorsImpl,
	FieldValues,
	Merge,
	RegisterOptions,
	UseControllerReturn,
	ValidationRule,
	ValidationValueMessage,
	useFormContext,
} from 'react-hook-form';
import Typography from '@mui/material/Typography';
import { FieldWrapper } from '../FormItems.css';
import { CurrencyTextOrSomethingElseContainer, Input, InputContainer, InputWithPrefixOverride, Prefix } from './NestInput.css';
import { maskFuncType, setCaretPosition } from '../../../common/helpers/mask-helper';
import { SxProps } from '@mui/system/styleFunctionSx';
import { TextFieldProps } from '@mui/material/TextField';
import { blockPasteEvent } from '../../../common/helpers/block-writing-keys-helper';
import { blockSymbolsHashLessThanGreaterThanDoublePause } from 'src/common/validators/blockSymbolsHashLessThanGreaterThanDoublePause';
import { magicWordsValidator } from 'src/common/validators/magicWordsValidator';
import { emailValidator } from 'src/common/validators/emailValidator';
import { peselValidator } from 'src/common/validators/peselValidator';
import { companyNameValidator } from 'src/common/validators/polishWordsValidator';

type NestInputProps = Omit<TextFieldProps, 'name'> & {
	control?: Control;
	name: string;
	rules?: RegisterOptions;
	disabled?: boolean;
	type?: 'text' | 'number' | 'select';
	maskFunc?: maskFuncType;
	mask?: string;
	useMaskAsValue?: boolean;
	wrapperSx?: SxProps;
	blockWritingSomeValueFunc?: (event: React.KeyboardEvent, state: string) => void;
	blockPasteEvent?: boolean;
	min?: number;
	prefix?: string;
	placeholder?: string;
	currencyTextOrPlainText?: string;
	autoComplete?: 'off' | 'on';
	errors?: { type: string; message: string };
	tabIndex?: number;
};

const NestInput = (props: NestInputProps): JSX.Element => {
	const [state, setState] = useState<string | number | readonly string[] | undefined>('');

	const {
		formState: { errors },
	} = useFormContext();

	useEffect((): void => {
		const value = getProp(props.control?._formValues, props.name) as string | number | readonly string[] | undefined;
		if (props.mask != undefined && !props.useMaskAsValue && value && props.maskFunc) {
			const maskResult = props.maskFunc(state as string, value as unknown as string, props.mask, null);

			setState(maskResult.maskedValue);
			return;
		}
		if (value) {
			setState(value);
		}
	}, [props.control?._formValues]);

	const getError = (): FieldError | Merge<FieldError, FieldErrorsImpl<Record<string, unknown>>> | undefined => {
		if (!props.name || (!errors && !props.errors)) {
			return undefined;
		}
		return (
			props.errors ??
			(getProp(errors || props.errors, props.name) as FieldError | Merge<FieldError, FieldErrorsImpl<Record<string, unknown>>> | undefined)
		);
	};

	const getProp = (data: Record<string, unknown> | undefined, name: string): unknown => {
		let value = data;
		const nameParts = name.replace(/\[/g, '.').replace(/\]/g, '').split('.');
		for (const part of nameParts) {
			if (value === undefined) {
				return undefined;
			}
			if (/^\d+$/.test(part)) {
				value = (Array.isArray(value) ? (value[Number(part)] as number[]) : undefined) as Record<string, unknown> | undefined;
			} else {
				value = (typeof value === 'object' ? value[part] : undefined) as Record<string, unknown> | undefined;
			}
		}
		return value;
	};

	const onChange = (e: ChangeEvent<HTMLInputElement>, field: ControllerRenderProps<FieldValues, string>): void => {
		if (props.maskFunc && props.mask !== undefined) {
			const maskResult = props.maskFunc(state as string, e.target.value, props.mask, e.target.selectionStart);

			if (props.useMaskAsValue) {
				e.target.value = maskResult.maskedValue;
			} else {
				e.target.value = maskResult.rawValue;
			}
			if (maskResult.cursorPos !== null) {
				setCaretPosition(e.target, maskResult.cursorPos);
			}
			setState(maskResult.maskedValue);
			field.onChange(e);
		} else {
			setState(e.target.value);
			field.onChange(e);
		}
	};

	const setPattern = (pattern: ValidationValueMessage<RegExp>): ValidationRule<RegExp> => {
		if (pattern.value === magicWordsValidator || pattern.value === companyNameValidator) return { value: pattern.value, message: 'Niepoprawna wartość' };
		if (pattern === emailValidator.pattern || pattern === peselValidator.pattern) return pattern;
		const extendedPattern = new RegExp(
			`(${(pattern as { value: RegExp; message: string }).value})|(${blockSymbolsHashLessThanGreaterThanDoublePause.source})`,
			'gi'
		);
		return { value: extendedPattern, message: 'Niepoprawna wartość' };
	};

	return (
		<FieldWrapper sx={props.wrapperSx}>
			<Typography variant='h9' sx={{ color: 'text.secondary', marginBottom: '8px', fontWeight: '600' }}>
				{props.label}
			</Typography>

			<Controller
				control={props.control}
				name={props.name}
				rules={{
					...props.rules,
					pattern: props.rules?.pattern && setPattern(props.rules?.pattern as ValidationValueMessage<RegExp>),
				}}
				render={({ field }: UseControllerReturn<FieldValues, string>): JSX.Element => (
					<InputContainer>
						{props.prefix && <Prefix error={!!getError()}>{props.prefix}</Prefix>}
						<div style={{ width: '100%', position: 'relative' }}>
							<Input
								{...field}
								type={props.type ?? 'text'}
								id={props.name}
								error={!!getError()}
								disabled={props.disabled}
								onChange={(e): void => onChange(e, field)}
								ref={(input: HTMLInputElement | null): void => (props.blockPasteEvent ? blockPasteEvent(input) : undefined)}
								value={state}
								onKeyDown={(e: React.KeyboardEvent): void => props.blockWritingSomeValueFunc?.(e, state as string)}
								min={props.min ?? undefined}
								sx={props.prefix ? InputWithPrefixOverride : undefined}
								placeholder={props.placeholder}
								autoComplete={props?.autoComplete ?? 'on'}
								tabIndex={props?.tabIndex}
							/>
							{props.currencyTextOrPlainText && (
								<CurrencyTextOrSomethingElseContainer>{props.currencyTextOrPlainText}</CurrencyTextOrSomethingElseContainer>
							)}
						</div>
						{getError() && (
							<Typography
								variant='h9'
								sx={{
									color: 'error.main',
									position: 'absolute',
									marginTop: '62px',
								}}
							>
								{getError()?.message as string}
							</Typography>
						)}
					</InputContainer>
				)}
			/>
		</FieldWrapper>
	);
};

export default NestInput;
